import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';


const cardHeight = 300;
const imageHeight = 150;
const cardMargin = 5;

/**
 * ユーザ一覧などで表示するアカウントの情報をまとめたカード
 * @property { id,displayName, uid, thumbnail, text, status }
 * @returns 
 * 
 * 募集状況status　募集中/受付停止中
 * 
 * <要件>
 * アバターを出す
 * ヒーローイメージを背景に出す
 * クリックしたらプロフィールに遷移する
 * 環境に応じて幅を調整する
 * propで受け取ったユーザデータで構成する
 */
const AccountCard = ({ id, displayName, uid, thumbnail, text, status, heroImageView = "" }) => {

    const navigate = useNavigate();
    const classes = useStyles();



    const onClickCard = (id) => {
        console.log("カードがクリックされた")
        console.log(id);
        navigate('/User/' + id);
    }


    const createCard = () => {

        return (

            <Card key={id} className={classes.card} onClick={() => onClickCard(uid)}>
                {heroImageView === "" ? ""
                    : <CardMedia
                        component="img"
                        height={imageHeight}
                        image={heroImageView}
                        alt="イラスト"
                    />
                }
                <Box>

                    <Box >
                        <Avatar
                            sx={{ margin: "0 auto", width: 100, height: 100 }} src={thumbnail} />
                    </Box>
                    <Typography color="text.primary"
                        sx={{ margin: "0 auto", fontSize: 17, fontWeight: 'bold' }}
                        gutterBottom>
                        {displayName}
                    </Typography>
                    <Typography
                        sx={{ margin: "0 auto", fontSize: 14 }} color="text.secondary" gutterBottom>
                        {text}
                    </Typography>


                    {status ?
                        <Typography
                            color="info.main" sx={{ margin: "0 auto", fontSize: 14 }} gutterBottom>募集受付中</Typography>
                        :
                        <Typography
                            color="text.disabled" sx={{ margin: "0 auto", fontSize: 14 }} gutterBottom>募集停止中</Typography>
                    }
                </Box>

            </Card>

        )
    }
    return (
        <>
            {createCard()}
        </>
    )
}

export default AccountCard;


const useStyles = makeStyles((theme) => ({

    card: {
        maxWidth: "500px",
        margin: 3,
        padding: 10,
    },
}));
