import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IfProxy from './../IfProxy';
import Button from '@mui/material/Button';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';



import AccountCard from './AccountCard';
import RequestCard from './RequestCard';
import ProductCard from './ProductCard';
import PortforioCard from './PortforioCard';
import FollowCard from './FollowCard';
import LimidRequestCard from './LimidRequestCard';



const cardHeight = 400;
const cardMargin = 5;

/**
 * リクエスト一覧などで表示するリクエスト情報をまとめたカード
 * @property { listTitle,listDiscription,listData,type}
 * @returns 
 * listData [] 表示するオブジェクト配列 オブジェクトは、対応カードコンポーネントの属性を持つ
 * type request/account/Product/limitedRequest
 * listDiscription リストの意味についての説明
 * 
 * <要件>
 * 
 * 
 * 
 */
const CardListView = ({ listTitle, listDiscription, listData, type }) => {
    const ifProxy = new IfProxy();
    const [faidin, setFadein] = useState(false);
    useEffect(() => {
        let timeoutId = setTimeout(() => {
            setFadein(true)
        }, 1000)
        return () => {
            clearTimeout(timeoutId)
        }
    }, [])
    /**
     * リクエストカードの作成
     * @returns 
     */
    const renderingRequestList = () => {

        const cardList = listData.map((item, index) => {
            return (
                <RequestCard
                    id={item.id}
                    fromName={item.fromName}
                    toName={item.toName}
                    thema={item.thema}
                    tochu={item.tochu}
                    anserLimit={ifProxy.formatDateString(item.anserLimit)}
                    dereveryLimit={ifProxy.formatDateString(item.dereveryLimit)}
                    status={item.status}
                    agree={item.agree}
                    share={item.share}
                ></RequestCard>
            )

        })
        return cardList;
    }
    /**
     * アカウントカードの作成
     * @returns 
     */
    const renderingAccountList = () => {
        const cardList = listData.map((item, index) => {
            return (
                <AccountCard
                    key={index}
                    id={item.id}
                    displayName={item.displayName}
                    uid={item.uid}
                    thumbnail={item.icon}
                    text={item.profile}
                    status={item.status}
                    heroImageView={item?.heroImageView}
                ></AccountCard>
            )
        })
        return cardList;
    }
    /**
     * 作品カードの作成
     * @returns 
     */
    const renderingProductList = () => {
        const cardList = listData.map((item, index) => {
            return (
                <ProductCard
                    key={index}
                    id={item.id}
                    displayName={item.toName}
                    uid={item.toUid}
                    thumbnail={item.thumbnail}
                    portforio={item.isPortforio}
                    nsfw={item.nsfw}
                ></ProductCard>
            )
        })

        // リスト全体の描画を作成
        const resultList = productListView(cardList);


        return resultList;
    }
    const productListView = (cardList) => {
        return (
            <>
                <GridList cellHeight={200} cols={3}>
                    {cardList.map(card => (
                        <GridListTile key={card.id} cols={1}>
                            {card}
                        </GridListTile>
                    ))}
                </GridList>

            </>

        )
    }

    /**
 * ポートフォリオカードの作成
 * @returns 
 */
    const renderingPortforioList = () => {
        const cardList = listData.map((item, index) => {
            return (
                <PortforioCard
                    key={item.id}
                    id={item.id}
                    displayName={item.toName}
                    uid={item.toUid}
                    thumbnail={item.thumbnail}
                    nsfw={item.nsfw}

                ></PortforioCard>
            )
        })
        // リスト全体の描画を作成
        const resultList = portforilListView(cardList);

        return resultList;
    }
    const portforilListView = (cardList) => {
        return (
            <>
                <GridList cellHeight={200} cols={3}>
                    {cardList.map(card => (
                        <GridListTile key={card.id} cols={1}>
                            {card}
                        </GridListTile>
                    ))}
                </GridList>

            </>

        )
    }

    /**
* フォロワーカードの作成
* @returns 
*/
    const renderingFollowList = () => {
        const cardList = listData.map((item, index) => {
            return (
                <FollowCard
                    key={item.id}
                    id={item.id}
                    displayName={item.displayName}
                    uid={item.uid}
                    thumbnail={item.thumbnail}
                ></FollowCard>
            )
        })
        return cardList;
    }

    /**
     * 無料限定リクエスト
     * @returns 
     */
    const renderingLimitedRequestList = () => {
        const cardList = listData.map((item, index) => {
            return (
                <LimidRequestCard
                    key={item.id}
                    id={item.id}
                    displayName={item.thema}
                    uid={item.toUid}
                    thumbnail={item.sumb}
                ></LimidRequestCard>
            )
        })
        // リスト全体の描画を作成
        const resultList = limitedRequestListView(cardList);

        return resultList;
    }
    const limitedRequestListView = (cardList) => {
        return (
            <>
                <GridList cellHeight={200} cols={3}>
                    {cardList.map(card => (
                        <GridListTile key={card.id} cols={1}>
                            {card}
                        </GridListTile>
                    )
                    )}
                </GridList>

            </>

        )
    }




    const renderingList = () => {
        if (type == "request") {
            return renderingRequestList();
        } else if (type == "account") {
            return renderingAccountList();
        } else if (type == "product") {
            return renderingProductList();
        } else if (type == "portforio") {
            return renderingPortforioList();
        } else if (type == "follow") {
            return renderingFollowList();
        } else if (type == "limitedRequest") {
            return renderingLimitedRequestList();
        }

    }


    return (
        <>
            <Box
                sx={{
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    opacity: faidin ? 1 : 0,
                    transition: 'all 0.3s',

                }}
            >
                <Box
                    sx={{
                        maxWidth: 700,
                        //minWidth: 600
                    }}
                ><h1>{listTitle}</h1>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {listDiscription}
                    </Typography>

                    {renderingList()}

                </Box>
            </Box >

        </>
    )
}

export default CardListView;


