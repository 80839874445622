import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from './../../AuthContext';

// TODO: StandardPageはパスが異なるため注意
import IfProxy from './../../IfProxy';
import HeadFooter from './../HeadFooter';
import SnsUtil from './../../common/SnsUtil';
import AccountCard from './../../common/AccountCard';
import TagInput from "../../common/TagInput";
import AgreecheckSightShare from "../../common/AgreecheckSightShare";

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';

import { InputAdornment } from '@mui/material';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const CreateRequest = (props) => {
  const [toUid, setToUid] = useState("");
  const [toName, setToName] = useState("");
  const [stripeId, setStripeId] = useState("");

  const [thema, setThema] = useState("");
  const [amount, setAmount] = useState(0);
  const [tags, setTags] = useState([]);
  const [kikakuTags, setKikakuTags] = useState([]);
  const [tagsSag, setTagsSag] = useState([]);
  const [kikakuTagsSag, setKikakuTagsSag] = useState([]);
  const [tochu, setTochu] = useState(true);
  const [shareAmount, setShareAmount] = useState(false);
  const [shereAmoutText, setShereAmoutText] = useState("投稿された作品は私のSNSアカウントで拡散に協力します");

  const [agreecheckSightShareFlg, setAgreecheckSightShareFlg] = useState(1);


  const [toIcon, setToIcon] = useState(true);

  const [loading, setLoading] = useState(true);


  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const ifProxy = new IfProxy();

  const location = useLocation();

  useEffect((props) => {
    setLoading(true)
    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    // ------
    loadData(props);
  }, []);


  const loadData = (props) => {
    console.log(params.toUid)
    try {
      ifProxy.getUserData(params.toUid).then(({ uid, displayName, icon, stripeId }) => {

        setToUid(uid);
        setToName(displayName);
        setToIcon(icon);
        setStripeId(stripeId);



      })



      ifProxy.getTag("ワンドロ企画", 10).then((result) => {
        setKikakuTagsSag(() => result)
        console.log(result)
      })
      ifProxy.getTag("タグ", 10).then((result) => {
        setTagsSag(() => result)
        console.log(result)
        setLoading(false)

      })
    } catch (e) {
      console.log("CreateRequest：loadData失敗")
      console.log(e)
    }
  }

  // フォームの入力値をStateに設定
  const handleThema = (event) => {
    setThema(event.target.value)
  }
  const handleShareAmount = (event) => {
    setShereAmoutText(event.target.value)
  }
  const handleAmount = (event) => {
    setAmount(event.target.value)
  }
  const returnTags = (tag) => {
    // 作品名のタグ
    setTags(tag)
  }
  const returnKikakuTags = (tag) => {
    // 企画名のタグ
    setKikakuTags(tag)
  }

  const insert = async () => {

    const tagsString = tags.map((data, index) => {
      return data.text
    })
    const onedroTagString = kikakuTags.map((data, index) => {
      return data.text
    })
    //console.log("タグ確認")
    //console.log(tags)
    //console.log(kikakuTags)

    await ifProxy.insertTag(tags, "タグ");
    await ifProxy.insertTag(kikakuTags, "ワンドロ企画");

    const data = {
      thema: thema,
      tochu: tochu,
      amount: amount,
      shareAmountFlag: shareAmount,
      shareAmount: shereAmoutText,
      onedroTag: kikakuTags,
      tag: tags,
      tagsString: tagsString,
      onedroTagString: onedroTagString,
      fromUid: user.uid,
      fromName: user.displayName,
      fromIcon: user.photoURL,
      toUid: toUid,
      toName: toName,
      toIcon: toIcon,
      odaiId: "",
      agreecheckSightShareFlg
    }
    await ifProxy.createRequest(data).then((result) => {
      navigate('/Request/' + result.id);

    }).catch((e) => {
      console.log("createRequest に失敗")
      console.log(e)
    })


  }

  const commisionAmountView = () => {
    if (stripeId === "") return;
    if (shareAmount) return;
    return (
      <>
        <br></br>
        ¥{amount}
        <TextField fullWidth margin="nomal" id="outlined-adornment-amount"
          label="オファー金額"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          onChange={handleAmount} variant="outlined" type="number" value={amount} sx={styleDifinition.aInputForm}
        />
        オファー金額は1000円以上としてください

      </>
    )
  }
  const shareAmountView = () => {
    if (!shareAmount) return;
    return (
      <>
        <br></br>
        ¥{amount} 無料
        <TextField fullWidth multiline margin="nomal" rows={4} id="outlined-basic" label="行うシェアの内容" onChange={handleShareAmount} value={shereAmoutText} variant="outlined" sx={styleDifinition.aInputForm} />



      </>
    )
  }


  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else if (loading) {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
          </Box>
        </Box>
      </HeadFooter>
    )
  } else {

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox} >
            <h1>リクエスト申し込み</h1>
            <TextField fullWidth multiline margin="nomal" rows={4} id="outlined-basic" label="リクエスト内容" onChange={handleThema} value={thema} variant="outlined" sx={styleDifinition.aInputForm} />

            <label htmlFor="check">
              報酬:
            </label>

            <Button
              color="primary"
              sx={{ margin: 2 }}
              variant={!shareAmount ? "contained" : "outlined"}
              onClick={() => setShareAmount(false)}
            >
              コミッション
            </Button>
            <Button
              color="primary"
              sx={{ margin: 2 }}
              variant={shareAmount ? "contained" : "outlined"}
              onClick={() => {
                setShareAmount(true);
                setAmount(0);
              }}
            >
              シェアを約束する
            </Button>
            {shareAmountView()}
            {commisionAmountView()}


            <h2>ワンドロ企画名（任意）</h2>
            <TagInput type="formKikaku" resultTags={(tags) => returnKikakuTags(tags)} suggestion={kikakuTagsSag}></TagInput>
            <Typography variant="body2">
              ※ 作品を投稿する参加企画を限定するわけではなく。参考情報としての提示です
            </Typography>
            <h2>作品タグ</h2>
            <TagInput type="form" resultTags={(tags) => returnTags(tags)} suggestion={tagsSag}></TagInput>
            <Typography variant="body2">
              ※ 作品に付与されるタグを指定するものではなく、リクエストのオーダー内容についてのタグです<br></br>
              クリエイターは指定されたタグはあくまで参考程度に利用します。
            </Typography>
            <h2>オファー条件</h2>
            <div>
              <label htmlFor="check">
                途中でも構わない　:
              </label>
              <input
                type="checkbox"
                id="check"
                checked={tochu}
                onChange={() => setTochu(prevState => !prevState)}
              />


              {tochu ? "　【未完成でも報酬を出すため納品して欲しい】" : "　【途中の場合は報酬は出さないため納品不要】"}
            </div>
            <Box className={classes.leftBox} >
              <AgreecheckSightShare paramStatus={agreecheckSightShareFlg} setResult={(result) => setAgreecheckSightShareFlg(result)}></AgreecheckSightShare>
            </Box>



            <Button
              variant="contained"
              fullWidth
              onClick={() => insert()}
              color="primary"
              sx={{ margin: 2 }}>
              リクエストを投稿する
            </Button>
          </Box>
        </Box>

      </HeadFooter >

    );
  }




}
export default CreateRequest;
const styleDifinition = {
  aInputForm: {
    margin: "10px",
  },
}
const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  mainBox: {

    maxWidth: "1000px",
    margin: "0 auto",
    marginTop: 10,
  },
  cardList: {
  },
  card: {
    width: 500

  },
}));
