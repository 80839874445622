import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';




const AgreecheckSightShare = ({ preParam, setResult }) => {

    const classes = useStyles();
    const [AgreecheckSightShareFlg, setAgreecheckSightShareFlg] = useState(1);
    useEffect((props) => {
        setAgreecheckSightShareFlg(preParam)
    }, []);

    return (
        <>
            <Box className={classes.leftBox}>
                <Typography variant="h6" >サービスの公式アカウントからのシェアの希望</Typography>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">公式アカウントからの作品紹介OK</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        default={1}
                        value={AgreecheckSightShareFlg}
                        label="AgreecheckSightShareFlg"
                        onChange={(e) => {
                            setResult(e.target.value)
                            setAgreecheckSightShareFlg(e.target.value)
                        }}
                    >
                        <MenuItem value={0}>公式アカウントからの紹介は不可</MenuItem>
                        <MenuItem value={1}>公式アカウントからの作品紹介OK</MenuItem>
                        <MenuItem value={2}>積極的拡散を希望</MenuItem>

                    </Select>
                </FormControl>

            </Box>

        </>
    )
}

export default AgreecheckSightShare;


const useStyles = makeStyles((theme) => ({

    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftRowBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'left',
    },
    // 個別コンテンツ　横並びに内容を並べる
    leftBox: {
        alignItems: "center",
        display: 'flex',
        flexDirection: 'row',
        margin: "0 auto",
    },


}));
