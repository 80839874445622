import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from './../../AuthContext';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import TagInput from "../../common/TagInput";


// TODO: StandardPageはパスが異なるため注意
import IfProxy from './../../IfProxy';
import IfProxyLimitedRequest from './../../IfProxyLimitedRequest';
import IfProxyTool from './../../IfProxyTool';

import HeadFooter from './../HeadFooter';
import SwipeMaster from './../../common/SwipeListView/SwipeMaster';
import SwipeViewCommonLogic from './../../common/SwipeListView/SwipeViewCommonLogic';


// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";


const SearchListView = (props) => {

  /**
   * @enum { (
  * CPRO: String, 
  * ACCO: String,
  * LIMT: String
  * REQU: String,
  * DIAR: String,
  * KIKA: String,
  * TAGS: String,) }
  * @param TYPE.CPRO 注目作品　CPRO
  * @param TYPE.ACCO 注目ユーザ ACCO
  * @param TYPE.LIMT 直近の限定リクエスト LIMT
  * @param TYPE.REQU 直近のリクエスト文 REQU
  * @param TYPE.DIAR 直近の日記 DIAR
  * @param TYPE.KIKA 企画に参加した作品
   @param TYPE.TAGS　 タグ検索  TAGS
  * 
  */
  const TYPE = Object.freeze({
    CPRO: "CPRO",
    ACCO: "ACCO", // 注目ユーザ
    LIMT: "LIMT", // 直近の限定リクエスト
    DIAR: "DIAR",
    REQU: "REQU", // 直近のリクエスト文 REQU
    KIKA: "KIKA", //企画に関連する作品を検索
    TAGS: "TAGS", //タグ検索

  })

  /** リスト種別はこの数 **************/
  const [cyumokuProduct, setCyumokuProduct] = useState([]); // 注目作品　CPRO
  const [cyumokuRequest, setCyumokuRequest] = useState([]); // 注目リクエスト REQU

  const [loading, setLoading] = useState(true);

  // ページング
  /* 個別のリストの続きを見せる用 */
  const [lastVisibleData, setLastVisibleData] = useState(true);

  /* TAG 検索　複数オブジェクトを跨いだ検索用 (抽出対象を個別にlastvisibleを持つ必要あり) */
  const [productLast, setProductLast] = useState(true);
  const [portforioLast, setPortforioLast] = useState(true); // これは存在しない、なぜならば、タグ検索する作品は、絵があるproduct側だけだから
  const [requestLast, setRequestLast] = useState(true);
  const [lmitedRequestLast, setLmitedRequestLast] = useState(true);
  const [diaryLast, setDiaryLast] = useState(true);

  const [onedroTagsSug, setOnedroTagsSug] = useState([]); // タグ サジェッション
  const [onedroTags, setOnedroTags] = useState([]); // タグ

  const [dtoList, setDtoList] = useState([]); // 抽出したデータ


  const [searchWordTag, setSearchWordTag] = useState([]); // 検索フォーム　タグ
  const [searchWordKikaku, setSearchWordKikaku] = useState([]); //


  /******************************* */

  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const ifProxy = new IfProxy();
  const ifProxyLimitedRequest = new IfProxyLimitedRequest();
  const ifProxyTool = new IfProxyTool();


  const swipeViewCommonLogic = new SwipeViewCommonLogic(); //スワイプビュー用のデータ加工ロジック共通処理

  const location = useLocation();

  useEffect((props) => {
    setLoading(true)
    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    // ------
    loadData();
  }, []);

  /**
   * 初期ロード
   * @param 
   */
  const loadData = async () => {
    // タグ検索　/SearchedListView?type=tag&word=test
    const query = new URLSearchParams(location.search);
    //console.log("クエリ:word=" + query.get('word'));
    const queryWord = query.get('word');

    switch (params.id) {
      case TYPE.CPRO:
        /** 注目の投稿リスト用 */
        await ifProxy.getCyumokuProduct(5).then(async (result) => {

          let resultListData = []
          resultListData = result
          const lastData = result[result.length - 1]
          setLastVisibleData(lastData);

          // TODO:ページングの間にポートフォリオが入り、ポートフォリオの後に次のページ、という暫定挙動、直す
          // TODO: 暫定措置 コールドスタート問題 投稿Product不足を補うため、サンプルを保管する
          const resultPortforio = await ifProxy.getPortforioProductList(3)
          const resultListDataConcated = resultListData.concat(resultPortforio)
          // 最終データ
          const swipViewData = swipeViewCommonLogic.createDtoFromProductArray(resultListDataConcated)
          setDtoList(swipViewData)

        })
        break;


      case TYPE.ACCO:
        // 注目のユーザリスト用
        await ifProxy.getNewUser(2).then(result => {
          const lastData = result[result.length - 1]
          setLastVisibleData(lastData);
          // 最終データ
          const swipViewData = swipeViewCommonLogic.createDtoFromAccountArray(result)
          setDtoList(swipViewData)

        })
        break;

      case TYPE.LIMT:
        // 直近の限定リクエストリスト用
        await ifProxyLimitedRequest.getRemitedRequestList(3).then(result => {
          const lastData = result[result.length - 1]
          setLastVisibleData(lastData);
          // 最終データ
          const swipViewData = swipeViewCommonLogic.createDtoFromLimitedrequestArray(result)
          setDtoList(swipViewData)
        })
        break;
      case TYPE.REQU:
        // 直近のリクエストリストメッセージ用
        await ifProxy.getCyumokuRequest(10).then(result => {
          const lastData = result[result.length - 1]
          setLastVisibleData(lastData);
          const swipViewData = swipeViewCommonLogic.createDtoFromRequestArray(result)
          setDtoList(swipViewData)
        })
        break;
      case TYPE.DIAR:
        // 日記取得
        await ifProxyTool.getFullDiaryList(5).then(result => {
          const lastData = result[result.length - 1]
          setLastVisibleData(lastData);
          // 最終データ
          const swipViewData = swipeViewCommonLogic.createDtoFromDiaryArray(result)
          setDtoList(swipViewData)
        })
        break;

      case TYPE.KIKA:
        /*
        await ifProxy.getOnedroTagSearchResult({ quantity: 10, searchTag: queryWord }).then((result) => {
          console.log("======= MODE ワンドロ")
          // 最終データ
          const swipViewData = swipeViewCommonLogic.createDtoFromProductArray(result)
          setDtoList(swipViewData)

        })
        */


        // タグ検索は総合検索。複数オブジェクトを同時に調べて、concatする

        // product
        const productK = await ifProxy.getOnedroTagSearchResult({ quantity: 10, searchTag: queryWord, collectionName: 'Product' })
        setProductLast(productK[productK.length - 1]);
        // 最終データ
        const productLastK = swipeViewCommonLogic.createDtoFromProductArray(productK)

        // portforio
        const portforioK = await ifProxy.getOnedroTagSearchResult({ quantity: 10, searchTag: queryWord, collectionName: 'ProductPortforio' })
        setPortforioLast(portforioK[portforioK.length - 1]);
        // 最終データ
        const portforioLastK = swipeViewCommonLogic.createDtoFromProductArray(portforioK)

        // 限定リクエスト
        const limReqK = await ifProxy.getOnedroTagSearchResult({ quantity: 10, searchTag: queryWord, collectionName: 'LimitedRequest' })
        setLmitedRequestLast(limReqK[limReqK.length - 1]);
        // 最終データ
        const limitedRequestLastK = swipeViewCommonLogic.createDtoFromLimitedrequestArray(limReqK)

        // 日記
        const diaryK = await ifProxy.getOnedroTagSearchResult({ quantity: 10, searchTag: queryWord, collectionName: 'Diary' })
        setDiaryLast(diaryK[diaryK.length - 1]);
        // 最終データ
        const diaryLastK = swipeViewCommonLogic.createDtoFromLimitedrequestArray(diaryK)

        const con1K = productLastK.concat(portforioLastK)
        const con2K = con1K.concat(limitedRequestLastK)
        const con3K = con2K.concat(diaryLastK)

        setDtoList(con3K)

        // サジェッション候補タグ
        const tagSugessionK = await ifProxy.getTag("ワンドロ企画", 100)
        setOnedroTagsSug(tagSugessionK)

        break;

      case TYPE.TAGS:

        // タグ検索は総合検索。複数オブジェクトを同時に調べて、concatする

        // product
        const product = await ifProxy.getTagSearchResult({ quantity: 10, searchTag: queryWord, collectionName: 'Product' })
        const lastData = product[product.length - 1]
        setProductLast(lastData);
        // 最終データ
        const productLast = swipeViewCommonLogic.createDtoFromProductArray(product)

        // portforio
        const portforio = await ifProxy.getTagSearchResult({ quantity: 10, searchTag: queryWord, collectionName: 'ProductPortforio' })
        setPortforioLast(portforio[portforio.length - 1]);
        // 最終データ
        const portforioLast = swipeViewCommonLogic.createDtoFromProductArray(portforio)

        // 限定リクエスト
        const limReq = await ifProxy.getTagSearchResult({ quantity: 10, searchTag: queryWord, collectionName: 'LimitedRequest' })
        setLmitedRequestLast(limReq[limReq.length - 1]);
        // 最終データ
        const limitedRequestLast = swipeViewCommonLogic.createDtoFromLimitedrequestArray(limReq)

        // 日記
        const diary = await ifProxy.getTagSearchResult({ quantity: 10, searchTag: queryWord, collectionName: 'Diary' })
        setDiaryLast(diary[diary.length - 1]);
        // 最終データ
        const diaryLast = swipeViewCommonLogic.createDtoFromLimitedrequestArray(diary)

        const con1 = productLast.concat(portforioLast)
        const con2 = con1.concat(limitedRequestLast)
        const con3 = con2.concat(diaryLast)

        setDtoList(con3)

        // サジェッション候補タグ
        const tagSugession = await ifProxy.getTag("ワンドロ企画", 100)
        setOnedroTagsSug(tagSugession)

        break;


      // TAGSに企画のための検索を追加

      default:
        navigate('/')

    }

    setLoading(false)


  }


  // ページング ---------------
  const addCPRO = async () => {
    const result = await ifProxy.getCyumokuProduct_paging(lastVisibleData.id)
    console.log(result);
    if (!result) {
      return;
    }
    let updateList = cyumokuProduct;
    for (let data of result) {
      updateList.push(data);
    }
    setCyumokuProduct(updateList)
    const lastData = updateList[updateList.length - 1]
    setLastVisibleData(lastData);
    // 最終データ
    const swipViewData = swipeViewCommonLogic.createDtoFromProductArray(updateList)
    const concated = dtoList.concat(swipViewData)
    setDtoList(concated)
  }

  // 注目のユーザリスト用
  const addACCO = () => {
    ifProxy.getNewUser_paging(3, lastVisibleData.snapshot).then(result => {
      const lastData = result[result.length - 1]
      setLastVisibleData(lastData);
      // 最終データ
      const swipViewData = swipeViewCommonLogic.createDtoFromAccountArray(result)
      const concated = dtoList.concat(swipViewData)
      setDtoList(concated)

    })
  }

  const addLIMT = () => {
    ifProxyLimitedRequest.getRemitedRequestListLast(3, lastVisibleData.snapshot).then(result => {
      const lastData = result[result.length - 1]
      setLastVisibleData(lastData);
      // 最終データ
      const swipViewData = swipeViewCommonLogic.createDtoFromLimitedrequestArray(result)
      const concated = dtoList.concat(swipViewData)
      setDtoList(concated)
    })
  }


  const addREQU = async () => {
    const result = await ifProxy.getCyumokuRequest_paging(lastVisibleData.id)
    if (!result) {
      return;
    }
    let updateList = cyumokuRequest;
    for (let data of result) {
      updateList.push(data);
    }
    const lastData = updateList[updateList.length - 1]
    setLastVisibleData(lastData);
    //最終データ
    const swipViewData = swipeViewCommonLogic.createDtoFromRequestArray(result)
    const concated = dtoList.concat(swipViewData)
    setDtoList(concated)

  }

  const addDIAR = () => {
    // 日記取得
    ifProxyTool.getFullDiaryListLast(3, lastVisibleData.snapshot).then(result => {
      const lastData = result[result.length - 1]
      setLastVisibleData(lastData);
      // 最終データ
      const swipViewData = swipeViewCommonLogic.createDtoFromDiaryArray(result)
      const concated = dtoList.concat(swipViewData)
      setDtoList(concated)
    })
  }

  const addKIKA = () => {
    const query = new URLSearchParams(location.search);
    ifProxy.getOnedroTagSearchResult({ lastId: lastVisibleData.id, quantity: 10, searchTag: query.get('word') }).then((result) => {
      console.log("======= MODE ワンドロ")
      // 最終データ
      const swipViewData = swipeViewCommonLogic.createDtoFromProductArray(result)
      const concated = dtoList.concat(swipViewData)
      setDtoList(concated)

    })
  }

  const addTAGS = async () => {
    const query = new URLSearchParams(location.search);
    const queryWord = query.get('word')
    // product
    const product = await ifProxy.getTagSearchResult({ lastId: productLast.id, quantity: 10, searchTag: queryWord, collectionName: 'Product' })
    const lastData = product[product.length - 1]
    setProductLast(lastData);
    // 最終データ
    const productData = swipeViewCommonLogic.createDtoFromProductArray(product)

    // portforio
    const portforio = await ifProxy.getTagSearchResult({ lastId: portforioLast.id, quantity: 10, searchTag: queryWord, collectionName: 'ProductPortforio' })
    setPortforioLast(portforio[portforio.length - 1]);
    // 最終データ
    const portforioData = swipeViewCommonLogic.createDtoFromProductArray(portforio)

    // 限定リクエスト
    const limReq = await ifProxy.getTagSearchResult({ lastId: lmitedRequestLast.id, quantity: 10, searchTag: queryWord, collectionName: 'LimitedRequest' })
    setLmitedRequestLast(limReq[limReq.length - 1]);
    // 最終データ
    const limData = swipeViewCommonLogic.createDtoFromLimitedrequestArray(limReq)

    // 日記
    const diary = await ifProxy.getTagSearchResult({ lastId: diaryLast.id, quantity: 10, searchTag: queryWord, collectionName: 'Diary' })
    setDiaryLast(diary[diary.length - 1]);
    // 最終データ
    const diaryData = swipeViewCommonLogic.createDtoFromLimitedrequestArray(diary)

    const con1 = productData.concat(portforioData)
    const con2 = con1.concat(limData)
    const con3 = con2.concat(diaryData)

    setDtoList(con3)
  }






  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else if (loading) { //loding
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
          </Box>
        </Box>
      </HeadFooter>
    )
  } else {

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values

    // SWIPE　DATA TEST
    // cyumokuProduct　にリストのデータが入ってる


    const addProductDto = () => {
      return null
    }

    return (
      <>
        <Grid container justifyContent="space-between"
          spacing={4} >
          <Grid item xs={12} >
          </Grid>
          {/** １段目 ===================================================================== */}
          <Grid item xs={3}>
            {/** 両側スペース */}
          </Grid>
          <Grid item xs={4} className={classes.searchForm}>
            <TextField fullWidth className={classes.searchFormInput} id="outlined-basic" label="タグで検索"
              onChange={(e) => {
                console.log(e.target.value)
                setSearchWordTag(e.target.value)
              }}
              value={searchWordTag} />
          </Grid>
          <Grid item xs={2} lassName={classes.searchForm}>
            <Button className={classes.searchFormButton} variant="contained"
              onClick={() => {
                console.log("タグで検索===============")
                console.log(searchWordTag)
                window.open(`${window.location.origin}/SearchListView/KIKA?word=${searchWordTag}`)
              }}
            >タグで作品を探す</Button>
          </Grid>
          <Grid item xs={3}>
            {/** 両側スペース */}
          </Grid>

          {/** ２段目 ===================================================================== */}
          <Grid item xs={3}>
            {/** 両側スペース */}
          </Grid>
          <Grid item xs={4} sx={{ height: 300 }}>
            {onedroTags.length > 1 && <Typography color="red">タグは１つだけ選択してください</Typography>}
            <TagInput type="formKikaku" resultTags={(tags) => {
              setSearchWordKikaku(tags)
              setOnedroTags((pre) => tags)
            }} suggestion={onedroTagsSug}></TagInput>

          </Grid>
          <Grid item xs={2} className={classes.searchForm}>
            <Button className={classes.searchFormButton} variant="contained"
              onClick={(e) => {
                console.log("企画タグで検索===============")
                console.log(searchWordKikaku[0].id)
                // TODO : 
                window.open(`${window.location.origin}/SearchListView/KIKA?word=${searchWordKikaku[0].id}`)
              }}
            >企画で作品を探す</Button>
          </Grid>
          <Grid item xs={3}>
            {/** 両側スペース */}

          </Grid>

        </Grid>

        <SwipeMaster swipeDataObjectList={dtoList} ></SwipeMaster>
        <Box className={classes.rightAlignBox}>
          <Button onClick={() => addTAGS()}> ＋ もっと読み込む</Button>
        </Box>


      </>

    );
  }




}
export default SearchListView;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト　中心揃えにする
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  // コンテンツの幅。端末ごとに広がりすぎるのを防ぐ。左揃え
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",
  },
  // 個別コンテンツ　中央揃えで縦に内容を並べる
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  // 個別コンテンツ　右に寄せて横に並べる
  rightAlignBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
  },
  // 個別コンテンツ　横並びに内容を並べる
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },
  searchForm: {
    height: 0,
  },
  searchTagForm: {
    height: 0,
  },
  searchFormInput: {
    padding: 0
  },
  searchFormButton: {
    verticalAlign: 'middle',
    width: 150,
    height: 100
  },
  cardList: {
  },
  card: {
  },
}));
