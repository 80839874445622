import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';

// TODO: StandardPageはパスが異なるため注意
import IfProxy from '../../IfProxy';
import HeadFooter from '../HeadFooter';

import TagInput from "../../common/TagInput";
import CardListView from "../../common/CardListView";

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import { InputAdornment } from '@mui/material';

import Typography from '@mui/material/Typography';


import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const OdaiTeianList = (props) => {


    const [loading, setLoading] = useState(true);
    const [onedroTagsSag, setOnedroTagsSag] = useState(""); // productに設定したタグ
    const [onedroTag, setOnedroTag] = useState(""); // productに設定したタグ
    const [onedroTagString, setOnedroTagString] = useState(""); // productに設定したタグ


    // 画面入力項目
    const [odai, setOdai] = useState(""); // お題に対して投稿された作品リスト
    const [odaiTeianList, setOdaiTeianList] = useState(""); // 提案リスト
    const [odaiTeianProductList, setOdaiTeianProductList] = useState(""); // お題に対して投稿された作品リスト
    const [amount, setAmount] = useState(""); // お題に対して投稿された作品リスト
    const [tochu, setTochu] = useState(""); // リクエストは途中を許容する
    const [cleckedTeianData, setCleckedTeianData] = useState(""); // 選択されている提案データ

    const [userData, setUserData] = useState(""); // 選択されている提案データ


    //表示用
    //モーダル
    const [modalShow, setModalShow] = useState(false);


    const auth = getAuth();
    const navigate = useNavigate();

    const { user } = useAuthContext();
    const classes = useStyles();
    const params = useParams();
    const ifProxy = new IfProxy();

    const location = useLocation();

    useEffect((props) => {
        setLoading(true)
        // ReactGA Analytics ---
        ReactGA.initialize("G-2ZCXE08H2S");
        ReactGA.send({
            hitType: "pageview",
            // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
            page: location.pathname + location.search,
        });
        // ------

        loadData(props);
    }, []);


    const loadData = (props) => {

        ifProxy.getOdaiTeianById(params.id).then((result) => {
            console.log(result)
            setOdaiTeianList(result)
            setLoading(false)
        })
        // 投稿された作品を取得
        ifProxy.getProductByOdaiId(params.id).then((result) => {

            setOdaiTeianProductList(result)
            setLoading(false)
        })

        ifProxy.getOdaiByid(params.id).then((result) => {
            console.log(result)
            setOdai(result)
        })
        ifProxy.getUserData(user.uid).then((result) => {
            setUserData(result)
        })

    }



    /**
     * 一括りクエスト
     */
    const ikkatsu = async (data) => {
        setLoading(true)
        // 無料リクエストかつ未処理の提案を全て取得
        ifProxy.getOdaiTeianByIdForIkkatsuRequest(params.id).then((result) => {
            //console.log(">>>>>>>>>>>>>>>")
            //console.log(result)
            const tags = [{ id: "お題に参加", text: "お題に参加" }]
            const kikakuTags = odai.type === "free" ? [] : odai.onedroTag;

            const tagsString = tags.map((data, index) => {
                return data.text
            })
            const onedroTagString = kikakuTags.map((data, index) => {
                return data.text
            })
            let insertRequestData = {
                thema: odai.odaiTitle + " : " + odai.odaiDetail,
                tochu: true,
                amount: 0,
                shareAmountFlag: true, //シェアだけのためtrue固定
                shareAmount: "投稿された作品は私のSNSアカウントで拡散に協力します",//シェアはないためfalse固定
                onedroTag: kikakuTags,
                tag: tags,
                tagsString: tagsString,
                onedroTagString: onedroTagString,
                fromUid: user.uid,
                fromName: user.displayName,
                fromIcon: user.photoURL,
                ///toUid: data.toUid, //ここだけ変わる
                //toName: data.toUser,//ここだけ変わる
                //toIcon: data.toUrl,//ここだけ変わる
                odaiId: odai.id
            }

            result.map(async (data, index) => {
                await ifProxy.updeteTeianRequested(data.id).then(async result => {
                    insertRequestData.toUid = data.toUid;
                    insertRequestData.toName = data.toUser;
                    insertRequestData.toIcon = data.toUrl;
                    await ifProxy.createRequest(insertRequestData).then((result) => {
                    }).catch((e) => {
                        console.log("createRequest に失敗")
                        console.log(e)
                    })
                }).catch((e) => {
                    console.log("updeteTeianRequested に失敗")
                    console.log(e)
                })
                window.location.reload()
            })

            setLoading(false)
        })
    }



    /*
 * ワンクリックリクエスト
  * 無料リクエスト
  */
    const freeRequest = async (data) => {

        // const userData = await ifProxy.getUserData(user.uid)

        const tags = [{ id: "お題に参加", text: "お題に参加" }]
        const kikakuTags = odai.type === "free" ? [] : odai.onedroTag;

        const tagsString = tags.map((data, index) => {
            return data.text
        })
        const onedroTagString = kikakuTags.map((data, index) => {
            return data.text
        })

        const insertRequestData = {
            thema: odai.odaiTitle + " : " + odai.odaiDetail,
            tochu: true,
            amount: 0,
            shareAmountFlag: true, //シェアだけのためtrue固定
            shareAmount: "投稿された作品は私のSNSアカウントで拡散に協力します",//シェアはないためfalse固定
            onedroTag: kikakuTags,
            tag: tags,
            tagsString: tagsString,
            onedroTagString: onedroTagString,
            fromUid: user.uid,
            fromName: user.displayName,
            fromIcon: user.photoURL,
            toUid: data.toUid,
            toName: data.toUser,
            toIcon: data.toUrl,
            odaiId: odai.id
        }
        await ifProxy.updeteTeianRequested(data.id).then(result => {

        }).catch((e) => {
            console.log("updeteTeianRequested に失敗")
            console.log(e)
        })
        await ifProxy.createRequest(insertRequestData).then((result) => {
            navigate('/Request/' + result.id);

        }).catch((e) => {
            console.log("createRequest に失敗")
            console.log(e)
        })
    }


    // 通常リクエスト
    // リクエストを作成する
    const insert = async () => {
        const tags = [{ id: "お題に参加", text: "お題に参加" }]
        const kikakuTags = odai.type === "free" ? [] : odai.onedroTag;

        const tagsString = tags.map((data, index) => {
            return data.text
        })
        const onedroTagString = kikakuTags.map((data, index) => {
            return data.text
        })
        //console.log("タグ確認")
        //console.log(tags)
        //console.log(kikakuTags)


        const data = {
            thema: odai.odaiTitle + " : " + odai.odaiDetail,
            tochu: tochu,
            amount: amount,
            shareAmountFlag: false, //シェアはないためfalse固定
            shareAmount: "",//シェアはないためfalse固定
            onedroTag: kikakuTags,
            tag: tags,
            tagsString: tagsString,
            onedroTagString: onedroTagString,
            fromUid: user.uid,
            fromName: user.displayName,
            fromIcon: user.photoURL,
            toUid: cleckedTeianData.toUid,
            toName: cleckedTeianData.toUser,
            toIcon: cleckedTeianData.toUrl,
            odaiId: odai.id

        }
        await ifProxy.createRequest(data).then((result) => {
            navigate('/Request/' + result.id);

        }).catch((e) => {
            console.log("createRequest に失敗")
            console.log(e)
        })
    }

    // 戻る
    const abort = async () => {
        navigate(-1)
    }


    const returnKikakuTags = (tag) => {
        // 企画名のタグ
        setOnedroTag(tag)
    }

    const teianCard = () => {
        // ocaiカードを作成
        const odaiListCard = odaiTeianList.map(({
            id,
            odaiId,
            type,
            toUid,
            toUser,
            toUrl,
            fromUid,
            fromUser,
            fromUrl,
        }, index, data) => {

            //console.log("分割代入前のdata ::")
            //console.log(data[index])
            return (
                <>
                    <Box className={classes.cardLim}>
                        <Box className={classes.leftRowBox}>
                            {/* 横配置ボックス */}
                            <Box className={classes.centerBox}>
                                {/* 縦配置ボックス */}
                                <Avatar
                                    src={toUrl}
                                    sx={{ width: 100, height: 100 }} />
                                <Box>提案者：{toUser}</Box>


                            </Box>
                            <Box className={classes.centerBox}>
                                {/* 縦配置ボックス */}
                                {data[index].requested && <Box><b>リクエスト済み</b></Box>}

                                <Box> 有償/無償</Box>
                                <Box> {type === "share" && "無料で受ける(報酬はシェアに協力でよい)"}</Box>
                                <Box> {type === "commision" && "有償リクエストであれば受けたい"}</Box>

                            </Box>
                            <Box className={classes.centerBox}
                                onClick={(e) => e.stopPropagation()} >
                                {/* 縦配置ボックス */}

                                {odai.fromUid === user.uid && < Button
                                    onClick={() => freeRequest(data[index])}
                                    disabled={data[index].requested}
                                    variant="contained">ワンクリックリクエスト（無料リクエスト限定）</Button>}
                            </Box>

                            <Box className={classes.centerBox} onClick={(e) => e.stopPropagation()} >
                                {/* 縦配置ボックス */}
                                {odai.fromUid === user.uid && < Button
                                    onClick={() => {
                                        setCleckedTeianData(data[index])
                                        setModalShow(true)
                                    }}
                                    disabled={data[index].requested}
                                    variant="contained">リクエストをする
                                </Button>}
                            </Box>

                        </Box >


                    </Box >

                </>
            )
        })
        return (
            <>
                {odaiListCard}
            </>
        )
    }

    const odaiProductCard = () => {
        // 作品一覧カードを作成
        /*
        const odaiListCard = odaiTeianProductList.map(({
            id,
            thumbnail,
            toName,
            toUid,
    
        }, index, data) => {
    
            console.log("分割代入前のdata ::")
            console.log(data[index])
            return (
                <>
                    <CardListView key="product" listData={{ id, thumbnail, toName, toUid, isPortforio: false }} listTitle="作品" listDiscription="お題に対して投稿された作品" type="product"></CardListView>
                </>
            )
        })
        return (
            <>
                {odaiListCard}
            </>
        )
        */
        return (
            <>
                <CardListView key="product" listData={odaiTeianProductList} listTitle="作品" listDiscription="お題に対して投稿された作品" type="product"></CardListView>

            </>
        )

    }

    // 有償リクエストモーダル画面
    const modalView = () => {
        if (modalShow) {
            return (
                <>
                    <Box className={classes.overlay}
                        onClick={() => setModalShow(false)}>
                        <Box className={classes.modalView} onClick={(e) => e.stopPropagation()}>
                            <Box className={classes.centerBox}>
                                <Typography sx={{ fontSize: 20 }}>
                                    リクエスト申し込み
                                </Typography>
                            </Box>
                            <Box className={classes.leftBox}>
                                <Box className={classes.leftCenterBox}>

                                    <Typography sx={{ fontSize: 14 }}>
                                        <b>リクエスト先</b>
                                    </Typography>
                                    <Typography sx={{ fontSize: 14 }}>
                                        {cleckedTeianData.toUser}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className={classes.leftBox}>
                                <Box className={classes.leftCenterBox}>
                                    <Typography sx={{ fontSize: 14 }}>
                                        <b>お題の内容</b>
                                    </Typography>
                                    <Typography sx={{ fontSize: 14 }}>
                                        {odai.odaiTitle}
                                    </Typography>
                                    <Typography sx={{ fontSize: 14 }}>
                                        {odai.odaiDetail}
                                    </Typography>
                                </Box>
                            </Box>

                            <Box className={classes.leftBox}>
                                <label htmlFor="check">
                                    報酬:
                                </label>

                                <Button
                                    color="primary"
                                    sx={{ margin: 2 }}
                                    variant="contained"
                                    disabled={false}
                                >
                                    有償リクエスト（固定）
                                </Button>
                                <Button
                                    color="primary"
                                    sx={{ margin: 2 }}
                                    variant="contained"
                                    disabled={true}

                                >
                                    シェアを約束する
                                </Button>
                            </Box>

                            <Box className={classes.leftCenterBox}>
                                <Box className={classes.leftCenterBox}>
                                    ¥{amount}
                                </Box>
                                <Box className={classes.leftCenterBox}>
                                    <TextField fullWidth margin="nomal" id="outlined-adornment-amount"
                                        label="オファー金額"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">¥</InputAdornment>,
                                        }}
                                        onChange={(e) => setAmount(e.target.value)} variant="outlined" type="number" value={amount}
                                    />
                                </Box>
                                <Box className={classes.leftCenterBox}>
                                    <Typography sx={{ fontSize: 14 }}>
                                        原則1000円以上を目安とすることを推奨します
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className={classes.leftCenterBox}>

                                <Box className={classes.leftCenterBox}>
                                    <Typography sx={{ fontSize: 14 }}>
                                        <b>オファー条件</b>
                                    </Typography>
                                </Box>
                                <Box className={classes.leftBox}>
                                    <label htmlFor="check">
                                        途中でも構わない　:
                                    </label>
                                    <input
                                        type="checkbox"
                                        id="check"
                                        checked={tochu}
                                        onChange={() => setTochu(prevState => !prevState)}
                                    />
                                    {tochu ? "　【未完成でも報酬を出すため納品して欲しい】" : "　【途中の場合は報酬は出さないため納品不要】"}

                                </Box>
                            </Box>
                            <Box sx={{ height: 50 }}>
                            </Box>




                            <Box className={classes.centerFlatBox} >

                                <Box className={classes.modalFormItem}>
                                    <Button
                                        disabled={((amount - 0) < 1000)}
                                        variant="contained"
                                        onClick={() => {
                                            let result = window.confirm("ワンドロは必ずしも成功されるとは限りません。実績を確認して了解の上発注を行ってください")
                                            if (result) {
                                                insert();
                                                setModalShow(pre => !pre)
                                            }
                                        }}>
                                        リクエストを送付する
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            setModalShow(pre => !pre)
                                        }}>閉じる
                                    </Button>
                                </Box>
                            </Box>

                        </Box>

                    </Box >
                </>
            )
        }

    }






    // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
    if (!user) {
        return <Navigate to="/login" />;
    } else if (loading) {
        return (
            <HeadFooter>
                <Box className={classes.contentWrapper}>
                    <Box className={classes.mainBox}>
                        <p>読み込み中...</p>
                    </Box>
                </Box>
            </HeadFooter>
        )
    } else {

        // Boxレイアウトのレスポンシブ対応
        // https://mui.com/components/grid/#responsive-values
        return (
            <HeadFooter>
                {modalView()}

                <Box className={classes.contentWrapper}>

                    <Box className={classes.mainBox} >
                        <Typography sx={{ fontSize: 20 }}>
                            <b>お題に投稿された作品</b>
                        </Typography>
                        {odaiTeianProductList && odaiProductCard()}
                        <hr></hr>
                        <Typography sx={{ fontSize: 20 }}>
                            <b>提案一覧</b>
                        </Typography>
                        {teianCard()}
                        <Button
                            variant="contained"
                            fullWidth
                            disabled={odai.fromUid !== user.uid}
                            onClick={() => ikkatsu()}
                            color="primary"
                            sx={{ margin: 2 }}>
                            一括リクエスト（未依頼の無料提案ユーザに一括リクエスト）
                        </Button>

                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => abort()}
                            color="primary"
                            sx={{ margin: 2 }}>
                            戻る
                        </Button>
                    </Box>
                </Box>
            </HeadFooter >
        );
    }
}
export default OdaiTeianList;

const useStyles = makeStyles((theme) => ({
    //オーバーレイ
    overlay: {
        zIndex: 10,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },//オーバーレイの中のモーダル
    modalView: {
        zIndex: 10,
        borderRadius: 15,
        width: '50%',
        padding: '1em',
        background: '#fff',
    },
    // 全体レイアウト
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

    },
    mainBox: {
        maxWidth: "1000px",
        margin: "0 auto",
    },
    centerBox: {
        marginTop: 15,
        minWidth: 400,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    leftBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: "0 auto",
    },// 個別コンテンツ　中央揃えで横に内容を並べる
    centerFlatBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftCenterBox: {
        marginTop: 15,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    cardList: {
    },
    card: {
    },
}));


// Odai参照モーダルビュー
const OdaiView = ({ item }) => {
    const classes = useStyles();

    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    return (
        <Box
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={
                {
                    transition: 'all 0.3s',
                    marginTop: 2,
                    padding: 10,
                    maxWidth: 500,
                    backgroundColor: isHovered ? '#7ea0f8' : '#bbdefb',
                    borderRadius: isHovered ? 0 : 20
                }
            }
        >
            {item.name}
        </Box>
    );

}