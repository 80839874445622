import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';




const CreateGoogleCalnenderTaskButton = ({ title, url, start, end }) => {
    console.log("~~~~~~~~~~~~~~~~~~^")
    console.log(`http://www.google.com/calendar/event?action=TEMPLATE&trp=false&text=${title}&sprop=${url}&dates=${start}/${end}`)
    return (
        <>
            <Button variant="outlined"
                href={`http://www.google.com/calendar/event?action=TEMPLATE&trp=false&text=${title}&sprop=${url}&dates=${start}/${end}`}
            >Googleカレンダーに登録</Button>
        </>
    )
}

export default CreateGoogleCalnenderTaskButton;


const useStyles = makeStyles((theme) => ({

}));
