import { Box } from "@mui/material";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { useAuthContext } from '../AuthContext';
import IfProxy from '../IfProxy';
import IfProxyStripe from '../IfProxyStripe';


const StraitLineSignupButton = () => {
    const navigate = useNavigate();
    const [code, setCode] = useState("");
    const { user } = useAuthContext();
    const ifProxy = new IfProxy();
    const ifProxyStripe = new IfProxyStripe();
    const [loding, setLoding] = useState(false);

    const checkCode = async () => {
        if (!user) {

        } else {
            navigate('/StraitLineSignup')
        }
    }


    return (
        <>

            <Button
                color="inherit"
                variant="contained"
                size="large"
                sx={{
                    margin: 2, borderRadius: 20, height: 100, width: 300, color: "#fff", background: 'linear-gradient(to bottom right,#2CD8D5 0%, #C5C1FF 56%, #FFBAC3 100%)'
                }}
                onClick={() => checkCode()}>
                簡単クリエイター登録
            </Button >
        </>
    )

}

export default StraitLineSignupButton;

const useStyles = makeStyles((theme) => ({


}))
