import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser, reload } from "firebase/auth";
import { useAuthContext } from './../../AuthContext';
import IfProxy from './../../IfProxy';
import IfProxyLimitedRequest from './../../IfProxyLimitedRequest';
import IfProxyTool from './../../IfProxyTool';


import HeadFooter from './../HeadFooter';
import Head from './../HeadFooter_ForMainH'; // 特殊ヘッダそち

import StraitLineSignupButton from './../../common/StraitLineSignupButton';
import SwipeMaster from './../../common/SwipeListView/SwipeMaster';
import SwipeViewCommonLogic from './../../common/SwipeListView/SwipeViewCommonLogic';


// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import Typography from '@mui/material/Typography';
import CardListView from "../../common/CardListView";
import TagInput from "../../common/TagInput";
import SnsUtil from "../../common/SnsUtil";
import PremiumAd from "../../common/adsence/PremiumAd";


import YouTube from 'react-youtube';

/**
 * ユーザ参照
 * @param  
 * @returns 
 */
const User = (props) => {
  const [loading, setLoading] = useState(true);


  const [userDataId, setUserDataId] = useState("");

  const [icon, setIcon] = useState("");
  const [heroImageView, setHeroImageView] = useState(); //表示するデータのソース、ローカル、またはサーバ

  const [displayName, setDisplayName] = useState("");
  const [stripeId, setStripeId] = useState("");
  const [remitedCreator, setRemitedCreator] = useState(false);

  const [profile, setProfile] = useState("");
  const [status, setStatus] = useState("");
  const [shareStance, setShareStance] = useState(true);

  const [youtubeid, setYoutubeid] = useState("");


  const [requetAmount, setRequetAmount] = useState(1);
  const [completetAmount, setCompletetAmount] = useState(1);
  const [completetTermH, setCompletetTermH] = useState("");
  const [completetTermD, setCompletetTermD] = useState("");

  const [creatorRating, setCreatorRating] = useState("");
  const [amoutAvelage, setAmoutAvelage] = useState("");


  const [genre, setGenre] = useState("");
  const [onedro, setOnedro] = useState("");
  const [unpaid, setUnpaid] = useState("");
  const [paidAmount, setPaidAmount] = useState("");
  const [title, setTitle] = useState("");

  const [competedRate, setCompetedRate] = useState("");

  const [product, setProduct] = useState([]);
  const [portforio, setPortforio] = useState([]);

  const [productDto, setProductDto] = useState([]);
  const [portforioDto, setPortforioDto] = useState([]);
  const swipeViewCommonLogic = new SwipeViewCommonLogic(); //スワイプビュー用のデータ加工ロジック共通処理

  // 限定リクエストのリストビューデータ
  const [limitedRequestDataFrom, setLimitedRequestDataFrom] = useState([]);
  const [limitedRequestDataTo, setLimitedRequestDataTo] = useState([]);
  const [limitedRequestDataFromDto, setLimitedRequestDataFromDto] = useState([]);
  const [limitedRequestDataToDto, setLimitedRequestDataToDto] = useState([]);
  const [diary, setDiary] = useState([]);
  const [diaryDto, setDiaryDto] = useState([]);


  // ページング用、lastVisibleのドキュメントを記録
  const [productLast, setProductLast] = useState([]);
  const [portforioLast, setPortforioLast] = useState([]);
  const [limitedRequestDataFromLast, setLimitedRequestDataFromLast] = useState([]);
  const [limitedRequestDataToLast, setLimitedRequestDataToLast] = useState([]);
  const [diaryLast, setDiaryLast] = useState([]);





  //productを受け取るときに、やったワンドロを取得。クラウドタグに出す
  const [tags, setTags] = useState(false);
  const [onedroTag, setOnedroTag] = useState(false);

  // フォローでユーザオブジェクトを渡すために、閲覧しているユーザを取得する
  const [viewdUser, setViewdUser] = useState([]);

  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();

  const ifProxy = new IfProxy();
  const ifProxyLimitedRequest = new IfProxyLimitedRequest();
  const ifProxyTool = new IfProxyTool();


  useEffect((props) => {
    //DEGUG
    //ifProxy.calcTermAvelage(user.uid)

    loadData(params.id);
    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    // ------

    getProduct(params.id)
    setLoading(true)
  }, []);

  /**
   * uidでユーザを検索
   * uidは、画面へのパスパラメータから取得
   * @param {*} uid 
   */
  const loadData = (uid) => {
    const ifProxy = new IfProxy();
    ifProxy.getUserData(uid).then((result) => {
      setLoading(false);
      // paramsの値でユーザを検索

      setUserDataId(result.id)
      setIcon(result.icon);
      setHeroImageView(result.heroImageView)
      setDisplayName(result.displayName);
      setStripeId(result.stripeId || "");
      setRemitedCreator(result.remitedCreator)
      setProfile(result.profile);
      setYoutubeid(result.youtubeid);
      setStatus(result.status);
      setShareStance(result.shareStance);
      setRequetAmount(result.entrustedAmount || 0);  // 受諾数
      setCompletetAmount(result.completetAmount || 0); // 納品数
      setCompetedRate(result.competedRate || 0); //平均納品期間
      setCompletetTermH(result.termAvelage?.aveh || 0); //平均納品期間
      setCompletetTermD(result.termAvelage?.aved || 0); //平均納品期間

      // 評価は０オリジン
      let ratingScore = ""
      if (result.ratingAvelage === 2) {
        ratingScore = "高"
      } else if (result.ratingAvelage === 1) {
        ratingScore = "中"
      } else {
        ratingScore = "低"
      }
      if (!result.remitedCreator || stripeId == "") {
        ratingScore = "一般ユーザ"
      }
      setCreatorRating(ratingScore); //平均レーティング
      setAmoutAvelage(result.amoutAvelage || 0)
      setGenre(result.genre);
      setOnedro(result.onedro);
      setUnpaid(result.unsettlementAmount || 0);
      setPaidAmount(result.paidAmount);
      setTitle(result.title);

      setViewdUser(result);

    })

    ifProxy.getPortforioProductByToUid(uid).then((result) => {
      setPortforio(result);
      const dtoData = swipeViewCommonLogic.createDtoFromProductArray(result)
      setPortforioDto(dtoData);

      // ページング用最終項目
      setPortforioLast(result[result.length - 1])

    })
    ifProxyTool.getDiaryList(uid).then((result) => {
      setDiary(result);
      const dtoData = swipeViewCommonLogic.createDtoFromDiaryArray(result)
      setDiaryDto(dtoData);
      // ページング用最終項目
      setDiaryLast(result[result.length - 1].snapshot)
    })

    // 自分からリクエストした限定リクエスト
    ifProxyLimitedRequest.getRemitedRequestListByFromUser(2, uid).then((result) => {
      console.log("~~~~~~~~~~~~~~~~~")
      console.log(result)
      setLimitedRequestDataFrom(result);
      const dtoData = swipeViewCommonLogic.createDtoFromLimitedrequestArray(result)
      setLimitedRequestDataFromDto(dtoData);

      // ページング用最終項目
      setLimitedRequestDataFromLast(result[result.length - 1].snapshot)

    })


    // 自分へのリクエストした限定リクエスト
    ifProxyLimitedRequest.getRemitedRequestListByToUser(10, uid).then((result) => {
      //console.log("~~~~~~~~~~~~~~~~~")
      //console.log(result)
      setLimitedRequestDataTo(result);

      const dtoData = swipeViewCommonLogic.createDtoFromLimitedrequestArray(result)
      setLimitedRequestDataToDto(dtoData);

      setLimitedRequestDataToLast(result[result.length - 1].snapshot)

    })

  }



  // ページング --------------------------------------------


  const addDiaryDto = () => {
    ifProxyTool.getDiaryList(params.id, 5).then((result) => {
      setDiary(result);
      const dtoData = swipeViewCommonLogic.createDtoFromDiaryArray(result)
      setDiaryDto(dtoData);
      // ページング用最終項目
      setDiaryLast(result[result.length - 1].snapshot)
    })
  }


  /**
   * 自分が描いたリクエストされた作品
   */
  const addProductDto = () => {
    ifProxy.getProductByToUidLast(params.id, 5).then(async (result) => {

      setProduct(result)
      const dtoData = swipeViewCommonLogic.createDtoFromProductArray(result)
      let dataAll = null;
      setProductDto((pre) => {
        dataAll = pre.concat(dtoData)
        return dataAll
      });
      setTags(dataAll);
      const ondroTagList = dataAll.map((item, index) => {

        // 項目名がtagsString固定なので加工が必要
        return { id: item.id, tagsString: item.onedroTagString }
      })
      setOnedroTag(ondroTagList)
      setProductLast(result[result.length - 1].snapshot)
    })

  }

  /**
   * 限定リクエスト 自分がリクエストしたもの
   */
  const addRemitedRequestListByFromUser = () => {

    // ページングイベント
    ifProxyLimitedRequest.getRemitedRequestListByFromUserLast(2, params.id, limitedRequestDataFromLast).then((result) => {
      setLimitedRequestDataFrom(result);
      const dtoData = swipeViewCommonLogic.createDtoFromLimitedrequestArray(result)
      setLimitedRequestDataFromDto((pre) => {
        let dataAll = pre.concat(dtoData)
        return dataAll
      });

      // ページング用最終項目
      setLimitedRequestDataFromLast(result[result.length - 1].snapshot)
    })
  }

  /**
   * 限定リクエスト 自分へリクエストされたもの
   */
  const addLimitedRequestDataToDto = () => {

    // ページングイベント
    ifProxyLimitedRequest.getRemitedRequestListByToUserLast(2, params.id, limitedRequestDataToLast).then((result) => {
      setLimitedRequestDataTo(result);
      const dtoData = swipeViewCommonLogic.createDtoToLimitedrequestArray(result)
      setLimitedRequestDataToDto((pre) => {
        let dataAll = pre.concat(dtoData)
        return dataAll
      });

      // ページング用最終項目
      setLimitedRequestDataToLast(result[result.length - 1].snapshot)
    })
  }





  /**
   * 閲覧しているユーザのポートフォリオとこれまで投稿したワンドロを表示する
   * @param {*} uid 
   */
  const getProduct = (uid) => {
    const ifProxy = new IfProxy();
    ifProxy.getProductByToUid(uid, 5).then(async (result) => {

      setProduct(result)
      const dtoData = swipeViewCommonLogic.createDtoFromProductArray(result)
      setProductDto(dtoData)
      setTags(result);
      const ondroTagList = result.map((item, index) => {

        // 項目名がtagsString固定なので加工が必要
        return { id: item.id, tagsString: item.onedroTagString }
      })
      setOnedroTag(ondroTagList)
      setProductLast(result[result.length - 1].snapshot)
    })
  }

  const addFav = () => {
    ifProxy.addFav(user, viewdUser)
    alert('「興味がある」を伝えました！')
  }

  const goRequest = () => {
    let result = window.confirm("ワンドロは必ずしも成功されるとは限りません。実績を確認して了解の上発注を行ってください")
    if (result) {
      navigate('/CreateRequest/' + params.id)
    }
  }
  const goFreeRequest = () => {
    let result = window.confirm("ワンドロは必ずしも成功されるとは限りません。実績を確認して了解の上発注を行ってください")
    if (result) {
      navigate('/CreateLimitedRequest/' + params.id)
    }
  }

  const heroImage = () => {
    return (
      <>
        <img src={heroImageView}
          style={{
            width: '100%',
            maxWidth: "595px",
            maxHeight: "847px",
            objectFit: "cover",
            margin: "0 auto",
            opacity: 1,
            display: "block",
          }} />
      </>
    )
  }
  const youtubeView = () => {
    if (youtubeid === "" || youtubeid === undefined) return

    return (
      <>
        <YouTube videoId={youtubeid} />

      </>
    )
  }



  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    const twitterShareUrl = "http://twitter.com/share?url=" + window.location.origin + '/User/' + params.id + "&text=ワンドロテーマを募集中&hashtags=ワンフロ"

    if (loading) {
      return (
        <>

          <Box className={classes.contentWrapper}>
            <Box className={classes.mainBox}>
              <p>読み込み中...</p>
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            </Box>
          </Box>
        </>
      )
    } else {
      //ログインしていないユーザ向けのプロフィール画面
      return (
        <>

          <Box className={classes.contentWrapper}>
            <Box className={classes.mainBox}>
              <Grid container justify="center" spacing={3} sx={{ marginTop: 2 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                {/* ヒーローイメージ */}
                <Grid item xs={12} >
                  <Button variant="contained"
                    fullwidth
                    color="primary"
                    sx={{ margin: 2 }}
                    onClick={() => navigate('/login')}
                  >
                    ユーザ登録してもっとみる
                  </Button>
                </Grid>
                {/* ヒーローイメージ */}
                <Grid item xs={12} >
                  {heroImage()}
                </Grid>
                {/* プロフィール */}
                <Grid item xs={12} sm={12} md={8} >
                  <Card className={classes.card}>
                    <CardContent>


                      <Avatar sx={{ width: 100, height: 100 }} src={icon} />
                      <h1>{displayName}</h1>
                      <Typography variant="h6" >
                        スタンス：{shareStance ? "ぜひたくさんシェアしてください！" : "あまりシェアして欲しくないです"}
                      </Typography>
                      <Typography variant="body2">
                        {profile}
                      </Typography>
                      <Typography variant="body2">
                        最近やっているワンドロ <br></br>
                        <b>{onedro}</b>
                      </Typography>
                    </CardContent>
                  </Card>
                  <PremiumAd />

                  {youtubeView()}
                </Grid>
                <Grid item xs={12} sm={12} md={4} >
                  <Box sx={{
                    padding: 2,
                    boxShadow: 1,
                    alignItems: 'center'

                  }}>


                    <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                      {/** タイトル　肩書き */}
                      {title}
                    </Typography>
                    <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                      <p style={{ color: `${status ? "blue" : "red"}` }}>募集ステータス：{status ? "募集中" : "募集停止中"}</p>
                    </Typography>

                    <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                      対応数: {requetAmount}
                    </Typography>
                    <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                      納品数: {completetAmount}
                    </Typography>
                    <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                      成功率: {(competedRate || 1) * 100} %
                    </Typography>
                    <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                      平均納品期間:{completetTermD} 日 ({completetTermH}時間)
                    </Typography>
                    <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                      平均受注金額：¥ {amoutAvelage}
                    </Typography>

                    <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                      クリエイター評価：{creatorRating}
                    </Typography>
                    <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                      得意ジャンル：{genre}
                    </Typography>
                    <br></br>
                    ユーザ評価
                    <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                      リクエスト未払い数：{unpaid}件<br></br>(納品を受けてからまだ支払い未完了の件数)
                    </Typography>


                    <Box className={classes.centerBox}>
                      <Button variant="contained"
                        disabled={true}
                        color="secondary"
                        sx={{ margin: 2 }}
                        onClick={() => goRequest()}
                      >
                        リクエストを申し込む(ユーザ登録後)
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Box className={classes.mainBox}>
                <Card className={classes.card}>
                  <CardContent>
                    <h1>プロフィールを共有する</h1>
                    <p>プロフィール画面を紹介するときはこちらのリンクを伝えてください</p>
                    プロフィールのURL: <a href={window.location.origin + "/User/" + params.id}>{window.location.origin + "/User/" + params.id}</a>

                    <h2>twitterシェアボタン</h2>
                    <p>募集をSNSで告知しましょう。好きな作家さんをシェアで応援しましょう</p>
                    <a
                      href={twitterShareUrl}
                    >
                      <img width="50" src={`${process.env.PUBLIC_URL}/twitterIcon.png`}></img>
                    </a>
                  </CardContent>
                </Card>

                <h1>やっているワンドロ</h1>
                <Box className={classes.centerBox}>
                  {onedroTag && <TagInput type="viewKikaku" tagViews={onedroTag}></TagInput>}
                </Box>
                <h1>作品一覧</h1>
                <Box className={classes.centerBox}>
                  {tags && <TagInput type="viewTag" tagViews={tags}></TagInput>}
                </Box>
                <Box style={{ margin: "0 auto", position: 'absolute', left: '0' }}>
                  <Box className={classes.centerBox}>
                    <h2>過去のワンドロ実績</h2>
                  </Box>
                  <SwipeMaster swipeDataObjectList={productDto}></SwipeMaster>
                  <Box className={classes.centerBox}>
                    <h2>サンプル</h2>
                  </Box>
                  <SwipeMaster swipeDataObjectList={portforioDto}></SwipeMaster>
                  <Box className={classes.centerBox}>
                    <h2>行った限定リクエスト</h2>
                  </Box>
                  <SwipeMaster swipeDataObjectList={limitedRequestDataFromDto}></SwipeMaster>
                  <Box className={classes.centerBox}>
                    <h2>受け付けた限定リクエスト</h2>
                  </Box>
                  <SwipeMaster swipeDataObjectList={limitedRequestDataToDto}></SwipeMaster>
                  <Box className={classes.centerBox}>
                    <h2>ワンドロ日記</h2>
                  </Box>
                  <SwipeMaster swipeDataObjectList={diaryDto}></SwipeMaster>
                </Box>
              </Box>
            </Box>

          </Box>
        </>

      )
    }
  } else if (loading) {
    return (
      <>
        <Head></Head>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Box>
        </Box>
      </>
    )
  } else {

    const twitterShareUrl = "http://twitter.com/share?url=" + window.location.origin + '/User/' + params.id + "&text=ワンドロテーマを募集中&hashtags=ワンフロ"

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      <>
        <Head></Head>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <Grid container justify="center" spacing={3} sx={{ marginTop: 2 }} columns={{ xs: 12, sm: 12, md: 12 }}>
              {/* ヒーローイメージ */}
              <Grid item xs={12} >
                {heroImage()}
              </Grid>
              {/* プロフィール */}
              <Grid item xs={12} sm={12} md={8} >
                <Card className={classes.card}>
                  <CardContent>

                    <Avatar sx={{ width: 100, height: 100 }} src={icon} />
                    <h1>{displayName}</h1>
                    <Typography variant="h6" >
                      スタンス：{shareStance ? "ぜひたくさんシェアしてください！" : "あまりシェアして欲しくないです"}
                    </Typography>

                    <Typography variant="body2">
                      {profile}
                    </Typography>
                    <Typography variant="body2">
                      最近やっているワンドロ <br></br>
                      <b>{onedro}</b>
                    </Typography>

                  </CardContent>

                  <CardActions>

                    {user.uid === params.id && <Button
                      variant="contained"
                      color="primary"
                      fullwidth
                      onClick={() => (navigate(`/UserEdit/${params.id}`))}
                      sx={{ margin: 2 }}
                      className={classes.followButton}>
                      編集
                    </Button>}


                    {user.uid === params.id && <Button
                      variant="outlined"
                      color="primary"
                      fullwidth
                      // stripeアカウントへ遷移
                      sx={{ margin: 2 }}
                      onClick={() => {
                        navigate('/CreatePortforioProduct/' + user.uid)
                      }}
                      className={classes.followButton}>
                      サンプルを投稿
                    </Button>}
                    {user.uid === params.id && <Button
                      variant="outlined"
                      color="primary"
                      fullwidth
                      // stripeアカウントへ遷移
                      sx={{ margin: 2 }}
                      onClick={() => {
                        navigate('/UserPop/' + user.uid)
                      }}
                      className={classes.followButton}>
                      イベント用ポップ画像を発行する
                    </Button>}


                  </CardActions>
                </Card>
                <PremiumAd />
                {youtubeView()}

              </Grid>
              <Grid item xs={12} sm={12} md={4} >
                {!remitedCreator && <StraitLineSignupButton></StraitLineSignupButton>}

                <Box sx={{
                  padding: 2,
                  boxShadow: 1,
                  alignItems: 'center',

                }}>


                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    {/** タイトル　肩書き */}
                    {title}
                  </Typography>
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    <p style={{ color: `${status ? "blue" : "red"}` }}>募集ステータス：{status ? "募集中" : "募集停止中"}</p>
                  </Typography>
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    対応数: {requetAmount}
                  </Typography>
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    納品数: {completetAmount}
                  </Typography>
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    成功率: {(competedRate || 1) * 100} %
                  </Typography>
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    平均納品期間:{completetTermD} 日 ({completetTermH}時間)
                  </Typography>
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    平均受注金額：¥ {amoutAvelage}
                  </Typography>

                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    クリエイター評価：{creatorRating}
                  </Typography>
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    得意ジャンル：{genre}
                  </Typography>
                  <br></br>
                  ユーザ評価
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    リクエスト未払い数：{unpaid}件<br></br>(納品を受けてからまだ支払い未完了の件数)
                  </Typography>

                  {/* あまり意味のない情報のため非表示 */}
                  {false && <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    これまでの支払い総額：{paidAmount}
                  </Typography>}
                  {(user.uid != viewdUser.uid) && <SnsUtil toUser={viewdUser.uid}></SnsUtil>}
                  {!user && <Button variant="contained"
                    color="primary"
                    sx={{ margin: 2 }}
                    disabled={true}
                  >
                    無料クエストを申し込む(ユーザ登録が必要です)
                  </Button>}
                  <Box className={classes.centerBox}>
                    {(stripeId != "" && status && user.uid != viewdUser.uid) && <Button variant="contained"
                      color="primary"
                      sx={{ margin: 2 }}
                      onClick={() => goRequest()}
                    >
                      有償リクエストを申し込む
                    </Button>}
                    {(status && user.uid != viewdUser.uid) && <Button variant="contained"
                      color="primary"
                      sx={{ margin: 2 }}
                      onClick={() => goFreeRequest()}
                    >
                      無料リクエストを依頼する
                    </Button>}
                    <Button variant="outlined"
                      color="info"
                      onClick={() => addFav()}
                      sx={{ margin: 2 }}>
                      興味がある！
                    </Button>
                  </Box>
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    興味があるボタンは、あなたがこのプロフィールに興味を持ったことを通知します。<br></br>
                    あなたが誰かを応援したいときに<br></br>
                    または逆にあなたのプロフィールを見に来て欲しいとき<br></br>
                    興味を持った人に押してみてください
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Box className={classes.mainBox}>
              <Card className={classes.card}>
                <CardContent>
                  <h1>プロフィールを共有する</h1>
                  <p>プロフィール画面を紹介するときはこちらのリンクを伝えてください</p>
                  プロフィールのURL: <a href={window.location.origin + "/User/" + params.id}>{window.location.origin + "/User/" + params.id}</a>

                  <h2>twitterシェアボタン</h2>
                  <p>募集をSNSで告知しましょう。好きな作家さんをシェアで応援しましょう</p>
                  <a
                    href={twitterShareUrl}
                  >
                    <img width="50" src={`${process.env.PUBLIC_URL}/twitterIcon.png`}></img>
                  </a>
                </CardContent>
              </Card>

              <h1>やっているワンドロ</h1>
              <Box className={classes.centerBox}>
                {onedroTag && <TagInput type="viewKikaku" tagViews={onedroTag}></TagInput>}
              </Box>
              <h1>作品一覧</h1>
              <Box className={classes.centerBox}>
                {tags && <TagInput type="viewTag" tagViews={tags}></TagInput>}
              </Box>
              <Box style={{ margin: "0 auto", position: 'absolute', left: '0' }}>

                <SwiperBox title="過去のワンドロ実績">
                  <SwipeMaster swipeDataObjectList={productDto} reloadAction={() => addProductDto()}></SwipeMaster>
                </SwiperBox>

                <SwiperBox title="サンプル">
                  <SwipeMaster swipeDataObjectList={portforioDto} reloadAction={() => alert('サンプルはすべてのデータが表示済みです')}></SwipeMaster>
                </SwiperBox>


                <SwiperBox title="行った限定リクエスト" reloadAction={() => addRemitedRequestListByFromUser()}>
                  <SwipeMaster swipeDataObjectList={limitedRequestDataFromDto}></SwipeMaster>
                </SwiperBox>

                <SwiperBox title="受け付けた限定リクエスト">
                  <SwipeMaster swipeDataObjectList={limitedRequestDataToDto} reloadAction={() => addLimitedRequestDataToDto()}></SwipeMaster>
                </SwiperBox>

                <SwiperBox title="ワンドロ日記">
                  <SwipeMaster swipeDataObjectList={diaryDto} reloadAction={() => addDiaryDto()}></SwipeMaster>
                </SwiperBox>

              </Box>




            </Box>
          </Box>

        </Box>

      </>
    );
  }




}
export default User;

const SwiperBox = ({ children, title, reloadAction }) => {
  const classes = useStyles();
  console.log(reloadAction)
  return (
    <>
      <Box className={classes.centerBox}>
        <h2>{title}</h2>
      </Box>
      {children}
      <Box className={classes.rightAlignBox}>
        <Button onClick={() => reloadAction()}> ＋ もっと読み込む</Button>
      </Box>

    </>

  )
}

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
    margin: "0 auto", // レイアウトがhostingだけ中央配置できない場合は、個別画面の一番上の要素に、中央配置を明示する
    flexDirection: 'column', // レイアウトがhostingだけ横に崩れた場合は、親にflexでcolumnを明示的に指定して解消する
    alignItems: 'center',
  },
  mainBox: {
    margin: "0 auto",
    maxWidth: "1000px",


  },
  // 個別コンテンツ　中央揃えで縦に内容を並べる
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  // 個別コンテンツ　右に寄せて横に並べる
  rightAlignBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
  },
  // 個別コンテンツ　横並びに内容を並べる
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },
  cardList: {
  },
  card: {
  },
  bottomCard: {
    width: 250
  },
}));
