import React, { useState, useEffect } from "react";
import { useNavigate, Navigate, useParams, useLocation } from 'react-router-dom';

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import IfProxy from '../../IfProxy';

// Material UI
import { makeStyles } from '@mui/styles';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { TroubleshootOutlined } from "@mui/icons-material";
/**
 * hosting上ではcss解釈がうまくいかず意図しない崩れ方をする
 * 対策：最後の手段　ランディングするページはこの静的ページ。そこをスプラッシュとして、1秒後にメイン画面へnavigateする
 * 
 * 
 *  */
const Start = () => {
  const location = useLocation();
  const params = useParams();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);


  const navigate = useNavigate();

  useEffect(() => {
    setIsOpen(true)
    let timeoutIdOff = setTimeout(() => {
      setIsOpen(false)
    }, 1000)
    let timeoutId = setTimeout(() => {
      console.log('時間経過')
      navigate('/MainList')
    }, 2000)
    return () => {
      clearTimeout(timeoutIdOff)
      clearTimeout(timeoutId)


    }
  }, []);

  return (
    <>

      <Box className={classes.contentWrapper}
        style={{
          transition: '0.5s',
          opacity: isOpen ? 1 : 0,
        }}
      >


        <Box className={classes.centerBox}>
          <Dna
            visible={true}
            top="100px"
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </Box>
      </Box >


    </>
  )




}
export default Start;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト　中心揃えにする
  contentWrapper: {
    margin: "0 auto",
    height: '1000px',
    backgroundColor: '#4169e1',

  },
  centerBox: {
    //marginTop: '30%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

