import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import { Dna } from 'react-loader-spinner'

import TagInput from "../../common/TagInput";


// TODO: StandardPageはパスが異なるため注意
import IfProxy from '../../IfProxy';
import IfProxyStripe from '../../IfProxyStripe';

import HeadFooter from '../HeadFooter';
import SnsUtil from '../../common/SnsUtil';
import AccountCard from '../../common/AccountCard';
import ImageShareBtn from '../../common/ImageShareBtn';

import { InputAdornment } from '@mui/material';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";

const PortforioProduct = (props) => {

  const [product, setProduct] = useState(0);


  const [boost, setBoost] = useState(0);
  const [rating, setRating] = useState(1);
  const [estimatedAmount, setEstimatedAmount] = useState(0);

  const [onedroTagLabel, setOnedroTagLabel] = useState("");
  const [tagLabel, setTagLabel] = useState("");
  const [agree, setAgree] = useState(0);
  const [share, setShare] = useState(0);


  const [tagControlView, setTagControlView] = useState(false); // productに設定したタグ


  const [editOnedroTags, setEditOnedroTags] = useState([]); // productに設定したタグ
  const [editTags, setEditTags] = useState([]);// productに設定したタグ


  const [loading, setLoading] = useState(true);



  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const ifProxy = new IfProxy();

  const location = useLocation();

  useEffect((props) => {
    setLoading(true)

    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    // ------

    loadData(props);
  }, []);




  const loadData = (props) => {


    // データコール
    ifProxy.getPortforioProductById(params.id).then((result) => {
      setProduct(result);
      setAgree(result.agree || 0);
      setShare(result.share || 0);
      // タグ設定
      const onedroLabelList = result.onedroTagString.map(lbl => (" " + lbl));
      const onedroResultLbl = onedroLabelList.join(" ");
      setOnedroTagLabel(onedroResultLbl);
      const labelList = result.tagsString.map(lbl => (" " + lbl));
      const resultLbl = labelList.join(" ");
      setTagLabel(resultLbl);

      // 編集フォームへのタグ設定
      setLoading(false);

    })
  }

  // タグ更新
  const returnTags = (tag) => {
    // 作品名のタグ
    setEditTags(tag)
  }
  const returnKikakuTags = (tag) => {

    // 企画名のタグ
    setEditOnedroTags(tag)
  }

  // タグを置き換える更新
  const updateTag = () => {
    setLoading(true);

    ifProxy.updateTag(product.id, editTags, 'ProductPortforio').then((result) => {
      setLoading(false);

    }).catch((e) => {
      setLoading(false);

      console.log("タグ周りの　UPDATEエラー")
      console.log(e)
    })
  }

  const updateOnedroTag = () => {
    setLoading(true);
    ifProxy.updateOnedroTag(product.id, editOnedroTags, 'ProductPortforio').then((result) => {
      setLoading(false);

    }).catch((e) => {
      setLoading(false);

      console.log("タグ周りの　UPDATEエラー")
      console.log(e)
    })
  }


  const tagControlViewSection = () => {
    return (
      <>
        <h2>タグを変更する</h2>
        <TagInput type="form" resultTags={(tags) => returnTags(tags)} suggestion={[{ id: 'オリジナル', text: 'オリジナル' }]}></TagInput>
        <Button
          variant="contained"
          disabled={editTags.length === 0 ? true : false}
          onClick={() => updateTag()}
          color="primary"
          sx={{ margin: 2 }}>
          上の内容にタグを差し替える
        </Button>
        <TagInput type="formKikaku" resultTags={(onedroTagList) => returnKikakuTags(onedroTagList)} suggestion={[{ id: 'オリジナル', text: 'オリジナル' }]}></TagInput>
        <Button
          variant="contained"
          disabled={editOnedroTags.length === 0 ? true : false}
          onClick={() => updateOnedroTag()}
          color="primary"
          sx={{ margin: 2 }}>
          上の内容にワンドロタグを差し替える
        </Button>
      </>
    )

  }



  // 作者だけが見れるコンポーネント
  const controlView = () => {
    if (user.uid === product.toUid) {
      return (<>
        <Card>
          <CardContent>
            <Button
              onClick={() => {
                ifProxy.deletePortforioProduct(params.id)
                navigate('/')
              }
              }
              variant="outlined"
              color="primary">
              削除する
            </Button>
          </CardContent>
        </Card>


        <Button
          onClick={() => setTagControlView(pre => !pre)}
        >
          タグを変更する        </Button>
        {tagControlView ? tagControlViewSection() : ""}



      </>

      )
    }

  }




  // ログイン中かどうかを確認し、ログインユーザを保持していなかったら非ログインユーザビュー
  if (!user) {
    return (
      <>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox} >
            <Box className={classes.centerBox}>

              <CardMedia
                sx={{ marginTop: 5 }}
                component="img"
                image={product.image}
                alt="product"
              />
            </Box>


            <Box className={classes.leftBox}>
              <Button
                sx={{
                  margin: 1
                }}
                onClick={() => {
                  navigate('/User/' + product.toUid)
                }
                }
                variant="outlined"
                fullWidth
                color="primary">
                クリエイターのプロフィールへ戻る
              </Button>
              <Button
                onClick={() => navigate('/login')}
                fullWidth sx={{
                  margin: 1

                }} size="large" variant="contained">ユーザ登録・ログインする</Button>
            </Box>


            {/** フォローボタン 依頼者をフォローする*/}
            <Card className={classes.card}>
              <CardContent>
                <Box className={classes.leftBox}>

                  <Avatar sx={{ width: 100, height: 100 }} src={product.toIcon} onClick={() => navigate('/User/' + product.toUid)} />
                  <Box >
                    <h2>クリエイター名：{product.toName}</h2>
                  </Box>
                </Box>


              </CardContent>
            </Card>

          </Box>
        </Box >
      </>
    )
  } else if (loading) {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Box>
        </Box>
      </HeadFooter>
    )
  } else {

    const twitterShareUrl = "http://twitter.com/share?url=" + window.location.origin + '/PortforioProduct/' + params.id + "&text=気になったワンドロをシェア&hashtags=ワンフロ"
    const twitterImageShareMessage = window.location.origin + '/LimitedRequest/' + params.id + " " + "気になったワンドロをシェア #ワンフロ"


    return (
      <HeadFooter>

        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox} >
            <Box className={classes.centerBox}>
              <CardMedia
                component="img"
                image={product.image}
                alt="product"
              />
            </Box>
            {controlView()}

            <Box className={classes.leftBox}>
              <Typography component="body" >
                {tagLabel}
              </Typography>
            </Box>
            <Box className={classes.leftBox}>
              <Typography component="body" >
                {onedroTagLabel}
              </Typography>
            </Box>


            <h2>リアクションを送る</h2>
            <Box className={classes.leftBox}>
              <Button
                sx={{
                  margin: 1,
                  flexDirection: 'row',
                }}
                onClick={() => {
                  ifProxy.portforioAgreeCount(params.id, user.uid)
                  setAgree(pre => pre + 1)
                }
                }
                variant="outlined"
                color="primary">
                好き！
              </Button>
              <h1>{agree}</h1>
            </Box>
            <Typography variant="body2"
              sx={{
                flexDirection: 'row',
              }}
            >
              ボタンを押して共感をクリエイターに伝えます<br></br>
              (賛同者は匿名です)
            </Typography>

            <Box className={classes.leftBox}>
              <Button
                sx={{
                  margin: 1,
                }}
                onClick={() => {
                  ifProxy.portforioShareCount(params.id, user.uid, window.location.origin + "/PortforioProduct/" + params.id)
                  setShare(pre => pre + 1)
                  ifProxy.updateUserRtcount(user.uid)
                  window.open(twitterShareUrl)
                }
                }
                variant="outlined"
                color="secondary">
                シェアする <img width="50" src={`${process.env.PUBLIC_URL}/twitterIcon.png`} alt="twitterLogo"></img>
              </Button>
              <h1>{share}</h1>

            </Box>
            <Box className={classes.leftBox}>
              <ImageShareBtn imageBlobData={product.thumbnail} message={twitterImageShareMessage} setResult={() => {
                ifProxy.portforioShareCount(params.id, user.uid, window.location.origin + "/PortforioProduct/" + params.id)
                setShare(pre => pre + 1)
                ifProxy.updateUserRtcount(user.uid)
              }}></ImageShareBtn>
            </Box>


            <Box className={classes.leftBox}>
              <Button
                sx={{
                  margin: 1
                }}
                onClick={() => {
                  navigate('/User/' + product.toUid)
                }
                }
                variant="outlined"
                color="primary">
                戻る
              </Button>
            </Box>


            {/** フォローボタン 依頼者をフォローする*/}
            <Card className={classes.card}>
              <CardContent>
                <Box className={classes.leftBox}>

                  <Avatar sx={{ width: 100, height: 100 }} src={product.toIcon} onClick={() => navigate('/User/' + product.toUid)} />
                  <Box >
                    クリエイター名：{product.toName}
                    {product.toUid && <SnsUtil toUser={product.toUid}></SnsUtil>}
                  </Box>
                </Box>


              </CardContent>
            </Card>

          </Box>
        </Box>

      </HeadFooter >

    );
  }




}
export default PortforioProduct;
const styleDifinition = {
  aInputForm: {
    margin: "10px",
  },
}
const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
    margin: "0 auto",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  mainBox: {
    maxWidth: "1000px",
    flexDirection: 'column',
    margin: "0 auto",
  },
  centerBox: {
    maxWidth: "700px",
    flexDirection: 'column',
    alignItems: 'center',
  },
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },
  cardList: {
  },
  card: {
  },
}));
