import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from './../../AuthContext';
import IfProxy from './../../IfProxy';
import HeadFooter from './../HeadFooter';
import UploadImage from './../../common/UploadImage';
import ShareStance from './../../common/ShareStance';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
const UserEdit = (props) => {


  const [docId, setDocId] = useState(0); // userDataのdocId
  const [blobDataResult, setBlobDataResult] = useState(0);

  const [heroImage, setHeroImage] = useState();// アップロード用のblobデータを保持する
  const [heroImageView, setHeroImageView] = useState(); //表示するデータのソース、ローカル、またはサーバ



  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();

  const location = useLocation();



  const [icon, setIcon] = useState(""); // url
  const [displayName, setDisplayName] = useState("");
  const [profile, setProfile] = useState("");
  const [youtubeid, setYoutubeid] = useState("");


  const [genre, setGenre] = useState("");
  const [onedro, setOnedro] = useState("");
  const [status, setStatus] = useState(true);
  const [shareStance, setShareStance] = useState(true);

  const [stripeId, setStripeId] = useState("");
  const [remitedCreator, setRemitedCreator] = useState(false);




  useEffect((props) => {
    loadData(props);
    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    // ------
  }, []);

  const handleLogout = () => {
    auth.signOut();
    navigate('/login');
  };

  const loadData = (props) => {
    console.log("画面遷移後＝ params ＝＝＝＝＝＝＝＝＝＝")
    console.log(params);
    console.log(location);
    const ifProxy = new IfProxy();
    ifProxy.getUserData(params.id).then((result) => {
      setHeroImageView(() => result.heroImageView);

      //console.log("============-")
      //console.log(result);
      setDocId(result.id);
      setStripeId(result.stripeId)
      setRemitedCreator(result.remitedCreator)


      setProfile(result.profile || "")
      setYoutubeid(result.youtubeid || "")


      setGenre(result.genre || "")
      setOnedro(result.onedro || "")
      setStatus((result.status == undefined) ? true : result.status)
      setShareStance((result.shareStance == undefined) ? true : result.shareStance)
      setIcon(() => result.icon !== undefined ? result.icon : user.reloadUserInfo.photoUrl);
      setDisplayName(() => (result.displayName !== undefined) ? result.displayName : user.reloadUserInfo.displayName); // Auth値固定（twitter)　任意の入力があればその値を優先
    })
  }


  /*
   * @param {Blob} blobData 
  */
  const returnBlob = ({ blobData, localUrl }) => {
    // 親画面の画像データを実際に取り扱うstateに設定
    setBlobDataResult(blobData);
    setIcon(localUrl)
  }

  /*
   * @param {Blob} blobData 
  */
  const returnBlobHeroImage = ({ blobData, localUrl }) => {
    // 親画面の画像データを実際に取り扱うstateに設定
    setHeroImage(blobData);
    setHeroImageView(localUrl)
  }
  /**
  * 
  */
  const update = async () => {
    // ユーザ名の設定
    // Auth値固定（twitter)　任意の入力があればその値を優先。（から文字をれたらtwitterになる）
    const userName = (displayName !== "") ? displayName : user.reloadUserInfo.displayName;
    const authUserData = {
      displayName: userName,
      blob: blobDataResult
    }
    const ifProxy = new IfProxy();

    // ヒーローイメージを先にアップロードし、URLを獲得する
    const { heroUrl, heroSumbneil } = (heroImage === undefined) ? { heroUrl: heroImageView } : await ifProxy.updateHeroImage(heroImage);
    // userをアップデートする
    await ifProxy.updateUser(authUserData).then((result) => {
      // メモ
      // 画面上からプロフィール画像の変更がない場合は、ファイルアップロードとurlの返却をしない
      // その場合。画面は、nullを受け取る
      // nullの時は、userData既に登録されているurlを踏襲する
      // userDataに既に登録されているurlがなければ、プロバイダーのurlを当てる(useEffectで既にiconに、userDataがあればそれ、なければプロバイダのiconが入っている)
      let photo = ""

      if (result.url !== undefined) {
        //　画像の更新あり
        photo = result.url;// 更新された新しいfirestoreのurl
      } else {
        // 画像の更新なし && （過去のデータを踏襲、または過去データもなし）
        photo = icon; //データ初期ロード時に入れたurl
      }




      //console.log("設定するurl")
      //console.log(photo)
      const data = {
        id: docId, // 初期登録であれば、から文字を入れておけば、updateUserData側で新規にしてくれる
        uid: user.uid, // Auth値固定（Auth)
        displayName: userName,
        icon: photo,

        youtubeid: youtubeid,

        //フォームから自由に設定
        profile: profile,
        status: status,
        shareStance: shareStance,
        genre: genre,
        onedro: onedro,

        // ヒーローイメージblobデータ
        heroImageView: heroUrl
      }

      console.log("$$$$$$$$$$$$$$$$")

      console.log(data)
      ifProxy.updateUserData(data).then((result) => {
        navigate(`/User/${user.uid}`);
      }).catch((e) => {
        console.log("error updateData")
        console.log(e);
      })
    }).catch((e) => {
      console.log("error updateData")
      console.log(e);
    })

  }

  const updateAndCreateUser = () => {
    // ユーザ名の設定
    // Auth値固定（twitter)　任意の入力があればその値を優先。（から文字をれたらtwitterになる）
    const userName = (displayName !== "") ? displayName : user.reloadUserInfo.displayName;
    const authUserData = {
      displayName: userName,
      blob: blobDataResult
    }
    const ifProxy = new IfProxy();
    ifProxy.updateUser(authUserData).then((result) => {
      // メモ
      // 画面上からプロフィール画像の変更がない場合は、ファイルアップロードとurlの返却をしない
      // その場合。画面は、nullを受け取る
      // nullの時は、userData既に登録されているurlを踏襲する
      // userDataに既に登録されているurlがなければ、プロバイダーのurlを当てる(useEffectで既にiconに、userDataがあればそれ、なければプロバイダのiconが入っている)
      let photo = ""

      if (result.url !== undefined) {
        //　画像の更新あり
        photo = result.url;// 更新された新しいfirestoreのurl
      } else {
        // 画像の更新なし && （過去のデータを踏襲、または過去データもなし）
        photo = icon; //データ初期ロード時に入れたurl
      }
      //console.log("設定するurl")
      //console.log(photo)
      const data = {
        id: docId, // 初期登録であれば、から文字を入れておけば、updateUserData側で新規にしてくれる
        uid: user.uid, // Auth値固定（Auth)
        displayName: userName,
        icon: photo,

        //フォームから自由に設定
        profile: profile,
        status: status,
        shareStance: shareStance,
        genre: genre,
        onedro: onedro
      }

      ifProxy.updateUserData(data).then((result) => {
        navigate('/StraitLineSignup')
      }).catch((e) => {
        console.log("error updateData")
        console.log(e);
      })
    }).catch((e) => {
      console.log("error updateData")
      console.log(e);
    })
  }


  // フォームの入力値をStateに設定
  const handleDisplayName = (event) => {
    setDisplayName(event.target.value)
  }
  const handleProfile = (event) => {
    setProfile(event.target.value)
  }

  const handleYoutubeId = (event) => {
    setYoutubeid(event.target.value)
  }


  const handleGenre = (event) => {
    setGenre(event.target.value)
  }
  const handleOnedro = (event) => {
    setOnedro(event.target.value)
  }

  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else {

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.contentArea}>
            <Box className={classes.avator}>
              <h3>お品書きの変更(閲覧のみの方は未設定で問題ございません)</h3>
              <UploadImage returnBlob={(blob) => returnBlobHeroImage(blob)} avatorMode={false}></UploadImage>
              <Typography variant="body1" paragraph>
                お品書きとは： 作家様が作成したメニュー表やPR画像を掲載できます。画像サイズは、最大幅595px、縦847pxとなっており、一般に同人誌即売会などへの参加告知でよく利用されるA4サイズの原稿と同等の縦横比率となっています<br></br><br></br>
                誰かにアカウントを紹介するとき、最初に見せるURLはこの画面になります。<br></br>
                最も目立つ部分である画面最上位は画像としお品書き等、PRは作家様の自由に委ねます
              </Typography>
            </Box>


            <Box className={classes.avator}>
              <h3>アイコンの変更</h3>
              <UploadImage returnBlob={(blob) => returnBlob(blob)} avatorMode={true}></UploadImage>
              <Avatar
                src={icon}
                sx={{ width: 100, height: 100 }} />

              <Typography color="text.primary" gutterBottom>
                <h3>ステータス: {status ? "募集受付中" : "募集停止中"}</h3>
              </Typography>

              <Box className={classes.leftBox}>
                <Typography variant="h6" sx={{ top: 10 }}>募集中：</Typography>
                <Checkbox
                  id="check"
                  size="large"
                  checked={status}
                  onChange={() => setStatus(prevState => !prevState)} />
              </Box>

              <ShareStance paramStatus={shareStance} setResult={(result) => setShareStance(result)} />
            </Box>
            {displayName}
            <TextField fullWidth id="outlined-basic" label="ユーザ名" onChange={handleDisplayName} variant="outlined" value={displayName} sx={styleDifinition.aInputForm} />
            <TextField fullWidth multiline rows={4} id="outlined-basic" label="自己紹介" onChange={handleProfile} value={profile} variant="outlined" sx={styleDifinition.aInputForm} />
            <TextField fullWidth id="outlined-basic" label="YouTube動画id" onChange={handleYoutubeId} value={youtubeid} variant="outlined" sx={styleDifinition.aInputForm} />


            {icon === undefined && "アイコンが未設定です(必須)"}<br></br>
            {(displayName === undefined || displayName === "") && "ユーザ名が未設定です(必須)"}
            <Button
              variant="contained"
              fullWidth
              disabled={icon === undefined || displayName === undefined || displayName === ""}
              onClick={() => update()}
              color="primary"
              sx={{ margin: 2 }}>
              更新
            </Button>
            <Box className={classes.avator}>
              <Button
                variant="contained"
                fullWidth
                disabled={icon === undefined || displayName === undefined || displayName === "" || stripeId === "" || !remitedCreator}
                onClick={() => updateAndCreateUser()}
                sx={{
                  margin: 2, borderRadius: 20, height: 100, color: "#fff", background: 'linear-gradient(to bottom right,#2CD8D5 0%, #C5C1FF 56%, #FFBAC3 100%)'
                }}>
                簡単クリエイター登録
              </Button>
            </Box>
          </Box>

        </Box>
      </HeadFooter >

    );
  }




}
export default UserEdit;

const styleDifinition = {
  aInputForm: {
    margin: "10px",
  },
}
const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentArea: {
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 700
  },
  avator: {
    margin: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputFileBtnHide: {
    opacity: 0,
    appearance: "none",
    position: "absolute"
  },
  aInputForm: {
    margin: 2,
  },
  mainBox: {

  },
  // 個別コンテンツ　横並びに内容を並べる
  leftBox: {
    alignItems: "center",
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },
  cardList: {
  },
  card: {
  },
  bottomCard: {
    width: 250
  },
}));
