import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import IfProxy from '../../IfProxy';
import IfProxyTu from '../../IfProxyTu';


import HeadFooter from '../HeadFooter';

import TutorialProgressGraph from '../../common/graphs/TutorialProgressGraph';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';


/**
 * ユーザ参照
 * @param  
 * @returns 
 */
const Tu = (props) => {
  const [loading, setLoading] = useState(true);
  // クリックされたイベントの情報をモーダルに渡す
  const [clickedEventData, setClickedEventData] = useState("");
  const [modalShow, setModalShow] = useState(false);


  // チェックボックス
  const [userData, setUserData] = useState({});



  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();


  const ifProxy = new IfProxy();
  const ifProxyTu = new IfProxyTu();


  let first = true;
  useEffect((props) => {
    // 【useEffectの２重処理について】
    // https://zenn.dev/takeharu/scraps/d14cf9d4239ec4
    // useEffectは２回描画される
    // mount -> unmount -> mount
    // 処理がこのようにダブルのは開発環境だけ
    if (first) {
      setLoading(true)
      // ReactGA Analytics ---
      ReactGA.initialize("G-2ZCXE08H2S");
      ReactGA.send({
        hitType: "pageview",
        // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
        page: location.pathname + location.search,
      });
      // ------
      loadData();
    }

    //    console.log(first ? "１回目である" : "２回目である")
    //    console.log("回数評価処理は以下")
    //    console.log("開発環境２重処理防止のため、１回目、２回目判定フラグ")
    //    console.log(first)
    first = false;
    //    console.log("２重登録判定フラグ、更新後")
    //    console.log(first)

    return () => {
      //      console.log("UNMOUNT 開発環境２重処理防止のため、１回目、２回目判定フラグ")
      //      console.log(first)
    }

  }, []);

  const loadData = async () => {
    const userDataByQuery = await ifProxyTu.getUserData(user.uid);
    setUserData(userDataByQuery);

    setLoading(false)
  }


  const taskList = () => {


    const {
      id,

    } = userData;



    const TaskGroupCard = ({ progress, title, children }) => {
      // 親コンポーネントのGrid。このコンポーネントの中で12にする。
      return (<>
        <Grid item xs={2} >
          <Box className={classes.centerBox}>
            <TutorialProgressGraph progress={progress}></TutorialProgressGraph>
          </Box>
        </Grid>
        <Grid item xs={10} >
          <Typography variant="h5">{title}</Typography>
          {children}
        </Grid>
      </>
      )
    }
    const TaskItem = ({ status, order, conditon, children }) => {
      // status(bool) は、このタスクが完了しているかどうか
      return (<>
        <Grid container className={classes.taskItem}>
          <Grid item md={6} sm={12} >
            <Box className={classes.leftBox}>
              <Checkbox
                disabled
                checked={status}
                size="large" />
              {/** 司令のタイトル */}
              <Typography variant="body1"><b>{order}</b></Typography>
            </Box>
          </Grid>

          <Grid item md={6} sm={12} className={classes.taskItemGuide}>
            {/** 達成条件のガイドタイトル */}

            <Typography variant="body1">{conditon}</Typography>
            {children}

          </Grid>


        </Grid>
      </>
      )
    }
    const calcProgress = (list) => {
      console.log(list)
      const trueCount = list.filter(item => {
        return item
      })
      const trueRatio = (trueCount.length / list.length) * 100
      return trueRatio
    }
    const {
      // ユーザ入門
      tu_1_firstuserguide,
      tu_2_firstfavorit,
      tu_3_firstshare,
      tu_4_firstPoll,
      tu_5_firstfavorit50,
      tu_5_1_graduate,

      //リクエスター入門
      tu_6_firstFreeRequestGuide,
      tu_7_firstFreeRequest,
      tu_8_firstRequestGuide,
      tu_9_requestIssue,
      tu_10_firstRequest,

      //クリエイター入門
      tu_11_registration,
      tu_12_getFirstRequestGuide,
      tu_13_GetFirstFreeRequestGuide,
      tu_14_GetFirstFreeRequest,
      tu_15_getRequestIssue,

      //日記入門
      tu_16_firstDiary,
      tu_17_firstDiaryShare,
      tu_18_diaryIssue,

      //カレンダー入門
      tu_19_firstOnedroCalender,
      tu_20_onedroCalenderIssue,
      tu_21_stripeEnabled,
    } = userData;

    return (
      /**
       * 次やること
       * 一行分のコンポーネントを作る
       * 外部かして使い回す
       * 
       */
      <>

        <Grid container className={classes.modalGrid} >
          {/** ユーザへのメッセージ ===================================================================== */}

          <Grid item xs={12}>
            <Box className={classes.centerBox}>
              <Typography variant="h4" sx={{ color: '#3299FF', fontFamily: 'ヒラギノ丸ゴ ProN' }}><b>{userData.nextStep}</b></Typography>
            </Box>
            <hr></hr>
          </Grid>
          {/** 0段目 「ファン」として参加するためのチュートリアル　(まずはここから)===================================================================== */}

          <TaskGroupCard progress={calcProgress([tu_1_firstuserguide, tu_2_firstfavorit, tu_3_firstshare, tu_4_firstPoll, tu_5_firstfavorit50, tu_5_1_graduate])} title="「ファン」として参加するためのチュートリアル　(まずはここから)">
            <TaskItem status={tu_1_firstuserguide} order="基本的な使い方を理解する" conditon="ガイドを読む(達成条件)"><Button variant="contained" onClick={() => navigate('/GuidTu1')}>ガイド</Button></TaskItem>
            <TaskItem status={tu_2_firstfavorit} order="気に入った作品に「好き！」を伝えて応援する" conditon="トップページからあなたのが気に入った作品を見つけて、1作品以上に「好き！」ボタンを押してください"><Button variant="outlined" onClick={() => navigate('/GuidTu1')}>ガイドを見直す</Button></TaskItem>
            <TaskItem status={tu_3_firstshare} order="「シェア」して応援。気に入った絵を描く作家さんをtwitterシェアして応援してください。" conditon="作家さんのプロフィール画面の「シェア」ボタンを押す"><Button variant="outlined" onClick={() => navigate('/GuidTu1')}>ガイドを見直す</Button></TaskItem>
            <TaskItem status={tu_4_firstPoll} order="「興味がある！」ボタンの活用。いつかリクエストしてみたい人や、応援したい作家さんに自分を意識させる方法" conditon="ガイドを読む(達成条件)"><Button variant="contained" onClick={() => navigate('/GuidTu4')}>「気になる」アピールの方法（ガイド）</Button></TaskItem>
            <TaskItem status={tu_5_firstfavorit50} order="もっと応援する。この課題で入門ステップは卒業です" conditon="10ユーザに「興味がある！」を伝えてください。やり方忘れてしまった場合は次のボタン"><Button variant="outlined" onClick={() => navigate('/GuidTu4')}>やり方</Button></TaskItem>
            <TaskItem status={tu_5_1_graduate} order="達成" conditon="ここまでの課題を全てクリアしたら、達成ボタンを押してください">
              <Button variant="contained"
                disabled={!(tu_1_firstuserguide && tu_2_firstfavorit && tu_3_firstshare && tu_4_firstPoll && tu_5_firstfavorit50)}
                onClick={() => {
                  ifProxyTu.updateUserStatus(userData.id, "STEP2_REQUESTER_TUTORIAL")
                  ifProxyTu.activateTu_5_1_graduate(userData.id)
                }}>達成</Button></TaskItem>

            <hr />
          </TaskGroupCard>

          {/** 1段目 「リクエスト」をできるようになる===================================================================== */}

          <TaskGroupCard progress={calcProgress([tu_6_firstFreeRequestGuide, tu_7_firstFreeRequest, tu_8_firstRequestGuide, tu_9_requestIssue, tu_10_firstRequest])} title="「リクエスト」をできるようになる">
            <TaskItem status={tu_6_firstFreeRequestGuide} order="「お題(フリーリクエスト)」で、趣味の合う作家と出会う" conditon="ガイドを読む(達成条件)"><Button variant="contained" onClick={() => navigate('/GuidTu6')}>ガイド</Button></TaskItem>
            <TaskItem status={tu_7_firstFreeRequest} order="お題を投稿する" conditon="「お題」を１件以上投稿する"><Button variant="outlined" onClick={() => ifProxyTu.checkFirstOdai(userData)}>投稿完了チェック</Button></TaskItem>
            <TaskItem status={tu_8_firstRequestGuide} order="「完成責任がない!?」リクエストのルールを理解する。" conditon="請負ではありません。他のコミッションサイトとは異なる【重要なルール】があります ガイドを読む(達成条件)"><Button variant="contained" onClick={() => navigate('/GuidTu8')}>重要なルール</Button></TaskItem>
            <TaskItem status={tu_10_firstRequest} order="リクエストをする" conditon="好きな作家さんへ初めてリクエストする。"><Button variant="outlined" onClick={() => ifProxyTu.checkFirstRequest(userData)}>投稿完了チェック</Button></TaskItem>
            <TaskItem status={tu_9_requestIssue} order="アンケート  (なければ「特になし」で達成)" conditon="もし使いずらい点や、こういうことができたら嬉しい。といった要望がございましたらぜひ次のフォームからどうぞ。"><Button variant="contained" onClick={() => {
              ifProxyTu.activateTu_9_requestIssue(userData.id)
              ifProxyTu.updateUserStatus(userData.id, "STEP3_CRATOR_TUTORIAL")
              window.open('https://forms.gle/tr5iohrcj469cXH39', '_blank')
            }}> 迷った点や改善提案</Button><Button variant="contained" onClick={() => {
              ifProxyTu.activateTu_9_requestIssue(userData.id)
              ifProxyTu.updateUserStatus(userData.id, "STEP3_CRATOR_TUTORIAL")
            }}>特になし</Button></TaskItem>
            <hr />
          </TaskGroupCard>

          {/** 2段目 クリエイター」になる。===================================================================== */}

          <TaskGroupCard progress={calcProgress([tu_11_registration, tu_12_getFirstRequestGuide, tu_21_stripeEnabled, tu_13_GetFirstFreeRequestGuide, tu_14_GetFirstFreeRequest, tu_15_getRequestIssue])} title="「クリエイター」になる。無料限定クリエイターから、有償リクエスト機能解放まで">
            <TaskItem status={tu_11_registration} order="クリエイター登録" conditon="クリエイター登録をする。無料のリクエストに限定してリクエスト募集を開始できます。(次のボタンを押すだけで登録完了)
            "><Button variant="contained"
                onClick={() => {
                  ifProxyTu.updateUserRemitedCreator(userData)
                  ifProxyTu.activateTu_11_registration(userData.id)

                }}
              >無料限定リクエスト募集 解放
              </Button></TaskItem>
            <TaskItem status={tu_12_getFirstRequestGuide} order="「完成責任がない!?」リクエストのルールを理解する。" conditon="請負ではありません。他のコミッションサイトとは異なる【重要なルール】があります ガイドを読む(達成条件)"><Button variant="contained" onClick={() => navigate('/GuidTu8')}>重要なルール</Button></TaskItem>
            <TaskItem status={tu_21_stripeEnabled} order="有償リクエスト機能解放" conditon="報酬受け取りのため、口座開設が必要です。　次のボタンで有料クリエイター登録に進みます">
              <Button variant="contained" onClick={() => alert('右上のメニュー→ダッシュボードへ移動し、左下の「有料リクエスト機能を開放する」から登録を始めてください')}>有料リクエスト募集機能 解放</Button>
              <Button variant="contained" onClick={() => ifProxyTu.checkCreatorUser(userData)}>完了チェック</Button>
            </TaskItem>
            <TaskItem status={tu_13_GetFirstFreeRequestGuide} order="「リクエストが来ない」を解決" conditon="リクエストを自分から取りにいける。「お題」（フリーリクエスト）の受け方　ガイドを読む(達成条件)"><Button variant="outlined" onClick={() => navigate('/GuidTu6')}>ガイド</Button></TaskItem>
            <TaskItem status={tu_14_GetFirstFreeRequest} order="「お題」にオファーを出す" conditon="１件以上のお題に対してオファーを出す">
              <Button variant="outlined" onClick={() => navigate('/Odai')}>募集中のお題一覧へ</Button>
              <Button variant="outlined" onClick={() => ifProxyTu.checkFirstOdaiTeian(userData)}>完了チェック</Button>
            </TaskItem>
            <TaskItem status={tu_15_getRequestIssue} order="アンケート  (なければ「特になし」で達成)" conditon="もし使いずらい点や、こういうことができたら嬉しい。といった要望がございましたらぜひ次のフォームからどうぞ。" >
              <Button variant="contained" onClick={() => {
                ifProxyTu.activateTu_15_getRequestIssue(userData.id)
                ifProxyTu.updateUserStatus(userData.id, "STEP4_NIKKI_TUTORIAL")
                window.open('https://forms.gle/tr5iohrcj469cXH39', '_blank')
              }}>
                迷った点や改善提案
              </Button>
              <Button variant="contained" onClick={() => {
                ifProxyTu.activateTu_15_getRequestIssue(userData.id)
                ifProxyTu.updateUserStatus(userData.id, "STEP4_NIKKI_TUTORIAL")
              }
              }>特になし</Button></TaskItem>

            <hr />
          </TaskGroupCard>

          {/** 3段目 ワンドロ支援ツール ワンドロタイマーとワンドロ日記を触ってみる===================================================================== */}

          <TaskGroupCard progress={calcProgress([tu_16_firstDiary, tu_17_firstDiaryShare, tu_18_diaryIssue])} title="ワンドロ支援ツール ワンドロタイマーとワンドロ日記を触ってみる">
            <TaskItem status={tu_16_firstDiary} order="日記を作成する" conditon="ガイドを参考に、ワンドロタイマー、ワンドロ日記を試用してみてください"><Button variant="contained" onClick={() => navigate('/GuidTu16')}>ガイド</Button></TaskItem>
            <TaskItem status={tu_17_firstDiaryShare} order="日記をシェアする" conditon="作成した日記をシェアボタンからシェアしてください"><Button variant="outlined" onClick={() => ifProxyTu.activateTu_17_firstDiaryShare(userData.id)}>わかった</Button></TaskItem>
            <TaskItem status={tu_18_diaryIssue} order="アンケート  (なければ「特になし」で達成)" conditon="もし使いずらい点や、こういうことができたら嬉しい。といった要望がございましたらぜひ次のフォームからどうぞ。">
              <Button variant="contained" onClick={() => {
                ifProxyTu.activateTu_18_diaryIssue(userData.id)
                window.open('https://forms.gle/tr5iohrcj469cXH39', '_blank')
                ifProxyTu.updateUserStatus(userData.id, "STEP5_CALENDOR_TUTORIAL")
              }
              }>迷った点や改善提案</Button>
              <Button variant="contained" onClick={() => {
                ifProxyTu.activateTu_18_diaryIssue(userData.id)
                ifProxyTu.updateUserStatus(userData.id, "STEP5_CALENDOR_TUTORIAL")
              }
              }>特になし</Button>
            </TaskItem>

            <hr />
          </TaskGroupCard>

          {/** 4段目 ワンドロ支援ツール ワンドロカレンダーを触ってみる===================================================================== */}

          <TaskGroupCard progress={calcProgress([tu_19_firstOnedroCalender, tu_20_onedroCalenderIssue])} title="ワンドロカレンダーで、参加したいワンドロ企画や好きなテーマや記念日を確認する">
            <TaskItem status={tu_19_firstOnedroCalender} order="参加したいワンドロ投稿企画を探したいとき。イラストのテーマにしたい記念日を探したいとき。または忘れないように記録しておきたいときに便利なカレンダーです" conditon="次のボタンからワンドロカレンダーを閲覧したら達成になります">
              <Button variant="contained"
                onClick={() => {
                  ifProxyTu.activateTu_19_firstOnedroCalender(userData.id)
                  navigate('/OnedroCalender')
                }
                }
              >ワンドロカレンダーを見る</Button>
            </TaskItem>
            <TaskItem status={tu_20_onedroCalenderIssue} order="アンケート  (なければ「特になし」で達成)" conditon="もし使いずらい点や、こういうことができたら嬉しい。といった要望がございましたらぜひ次のフォームからどうぞ。"><Button variant="contained" onClick={() => {
              ifProxyTu.activateTu_20_onedroCalenderIssue(userData.id)
              ifProxyTu.updateUserStatus(userData.id, "STEP_CLEARED")
              window.open('https://forms.gle/tr5iohrcj469cXH39', '_blank')

            }
            }>迷った点や改善提案
            </Button>
              <Button variant="contained" onClick={() => {
                ifProxyTu.activateTu_20_onedroCalenderIssue(userData.id)
                ifProxyTu.updateUserStatus(userData.id, "STEP_CLEARED")
              }
              }>特になし</Button>
            </TaskItem>

            <hr />
          </TaskGroupCard>






          {/**ヘッダ */}
          {/** 1段目 ===================================================================== */}

        </Grid >

      </>
    )


  }


  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else if (loading) {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Box>
        </Box>
      </HeadFooter>
    )
  } else {

    const twitterShareUrl = "http://twitter.com/share?url=" + window.location.origin + '/User/' + params.id + "&text=ワンドロテーマを募集中&hashtags=ワンフロ"

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      < HeadFooter >

        <Box className={classes.contentWrapper}>
          {userData && taskList()}

        </Box>

      </HeadFooter >

    );
  }




}
export default Tu;

const style = {
  scoreLabel: {
    fontSize: 25,

  },
}

const useStyles = makeStyles((theme) => ({
  //オーバーレイ
  overlay: {
    zIndex: 10,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },//オーバーレイの中のモーダル
  modalView: {
    zIndex: 10,
    borderRadius: 15,
    width: '50%',
    padding: '1em',
    background: '#fff',
  },
  // 全体レイアウト
  contentWrapper: {
    marginTop: 20,
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",

  },
  // グリッド箱の書式 
  // モーダルのグリッド全体
  modalGrid: {
    //maxWidth: 1000,
    padding: 0,
    margin: '0 auto'

  },
  taskItem: {
    padding: 20,
    margin: 20,
    border: '2px solid',
    borderColor: '#a6d4fa',
    borderRadius: 20
  },
  taskItemGuide: {
  },

  gridPadding: {
    padding: 5,
    border: '2px solid',
    borderColor: '#a6d4fa',
    backgroundColor: 'linear-gradient(to bottom right,#a6d4fa,#1976d2)',
    borderRadius: '10'

  },
  // グリッド箱の書式 枠線なし
  gridPaddingNoborder: {
    padding: 5,


  },
  // 個別コンテンツ　中央揃えで縦に内容を並べる
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },// 個別コンテンツ　中央揃えで横に内容を並べる
  centerFlatBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftCenterBox: {
    display: 'flex',
    paddingRight: 10,
    flexDirection: 'column',
    alignItems: 'left',
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftRowBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
  },
  // 個別コンテンツ　横並びに内容を並べる
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },

  // モーダルダイアログのフォーム　個別アイテム
  modalFormItem: {
    margin: 20,
  },
  //サンプルのグラフエリア
  sampleGraphEria: {
    width: 200
  },

  cardList: {
  },
  card: {
  },
  bottomCard: {
    width: 250
  },
}));



