import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import IfProxyCalender from '../../IfProxyCalender';
import IfProxyDefault from '../../IfProxy';

import HeadFooter from '../HeadFooter';
import StraitLineSignupButton from '../../common/StraitLineSignupButton';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

//graph
import VictorySample from "../../common/graphs/VictorySample";
import DisplayAd from "../../common/adsence/DisplayAd"


import Week from "./Week"
import Calender from "./Calender";
import Favo from "./Favo";
import EventCard from "./EventCard";
import CalenderContorolFooter from "./CalenderContorolFooter";
import CalenderModalView from "./CalenderModalView"
import SearchForm from "./SearchForm"

/**
 * ユーザ参照
 * @param  
 * @returns 
 */
const OnedroCalender = (props) => {
  const [loading, setLoading] = useState(true);
  const [requestListViewFlg, setRequestListViewFlg] = useState(false);

  const [calender, setCalender] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [eventType, setEventType] = useState("kikaku");

  const [creatorFlg, setCreatorFlg] = useState(false);


  // アイテムの保持用。ここに値がなければ、DBアクセス。あれば、DBアクセスせず、ここを利用する
  const [itemData, setItemData] = useState(null);


  // modalダイアログ用のstate値
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [startTime, setStartTime] = useState("");
  const [tags, setTags] = useState("");

  const [kinenbiMonth, setKinenbiMonth] = useState(1);
  const [kinenbiDate, setKinenbiDate] = useState(1);
  const [monthErrorMessage, setMonthErrorMessage] = useState("");
  const [dateErrorMessage, setDateErrorMessage] = useState("");
  const [presentUrl, setPresentUrl] = useState("");


  const [dayName, setDayName] = useState(0);

  const [selectedSheet, setSelectedSheet] = useState("week");
  const [favoList, setFavoList] = useState();


  // 検索系
  const [showSearch, setShowSearch] = useState(false);

  // 検索キーワード、この値で、itemをフィルタする
  const [ganle, setGanle] = useState("");
  const [charactor, setCharactor] = useState("");
  const [feature, setFeature] = useState("");


  // クリックされたイベントの情報をモーダルに渡す
  const [clickedEventData, setClickedEventData] = useState("");


  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();


  const ifProxy = new IfProxyCalender();

  /** 注意　このコンポーネントのみifProxyが逆 */
  const ifProxyDefault = new IfProxyDefault();


  const DAY = Object.freeze({
    MON: 0,
    THE: 1,
    WED: 2,
    THU: 3,
    FRI: 4,
    SAT: 5,
    SUN: 6,
    ANI: 7, //記念日系
    EIG: 8, // 日程指定なし
  })

  const TYPE = Object.freeze({
    event: "event",
    aniversary: "aniversary"
  })

  let first = true;
  useEffect((props) => {
    // 【useEffectの２重処理について】
    // https://zenn.dev/takeharu/scraps/d14cf9d4239ec4
    // useEffectは２回描画される
    // mount -> unmount -> mount
    // 処理がこのようにダブルのは開発環境だけ
    if (first) {
      setLoading(true)
      // ReactGA Analytics ---
      ReactGA.initialize("G-2ZCXE08H2S");
      ReactGA.send({
        hitType: "pageview",
        // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
        page: location.pathname + location.search,
      });
      // ------
    }
    loadData();


    //    console.log(first ? "１回目である" : "２回目である")
    //    console.log("回数評価処理は以下")
    //    console.log("開発環境２重処理防止のため、１回目、２回目判定フラグ")
    //    console.log(first)
    first = false;
    //    console.log("２重登録判定フラグ、更新後")
    //    console.log(first)

    return () => {
      //      console.log("UNMOUNT 開発環境２重処理防止のため、１回目、２回目判定フラグ")
      //      console.log(first)
    }

  },
    // 検索フォームからの引数受領に反応して更新 
    // 
    [
      ganle,
      charactor,
      feature
    ]);

  /**
   * uidでユーザを検索
   * uidは、画面へのパスパラメータから取得
   */
  const loadData = async () => {
    let eventListAll = ""
    // データアクセスや初回だけ実行。
    if (itemData) {
      eventListAll = itemData
      console.log("LOAD DATA")
      console.log("データあり")
      console.log(itemData.length)

    } else {
      console.log("LOAD DATA")
      console.log("データなし　取得する")
      eventListAll = await ifProxy.getCalenderAll();
      setItemData(eventListAll)
    }

    // TODO 検索ワードでフィルタ
    let eventList = eventListAll;
    console.log("処理前")
    console.log(eventList)
    //ganle
    if (ganle != "") {
      eventList = eventList.filter((event) => event.ganleTagDataStr?.includes(ganle))
    }
    if (charactor != "") {
      eventList = eventList.filter((event) => event.charactorTagDataStr?.includes(charactor))
    }
    if (feature != "") {
      eventList = eventList.filter((event) => event.featureTagDataStr?.includes(feature))
    }
    console.log("処理後")
    console.log(eventList)


    let mon = eventList.filter((event) => event.dayName === 0)
    let the = eventList.filter((event) => event.dayName === 1)
    let wed = eventList.filter((event) => event.dayName === 2)
    let thu = eventList.filter((event) => event.dayName === 3)
    let fri = eventList.filter((event) => event.dayName === 4)
    let sat = eventList.filter((event) => event.dayName === 5)
    let sun = eventList.filter((event) => event.dayName === 6)
    const ani = eventList.filter((event) => event.dayName === 7) //曜日を問わない系（記念日
    const eig = eventList.filter((event) => event.dayName === 8)

    // 記念日も曜日に追加する
    console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~=======-")

    // Date型の曜日は0が日曜日から始まる。上記とは異なるため注意。
    const aniMon = getKinenbiForWeek(ani, 1)
    const aniThe = getKinenbiForWeek(ani, 2)
    const aniWed = getKinenbiForWeek(ani, 3)
    const aniThu = getKinenbiForWeek(ani, 4)
    const aniFry = getKinenbiForWeek(ani, 5)
    const aniSat = getKinenbiForWeek(ani, 6)
    const aniSun = getKinenbiForWeek(ani, 0)


    // 記念日を曜日リストに足す
    mon = mon.concat(aniMon)
    the = the.concat(aniThe)
    wed = wed.concat(aniWed)
    thu = thu.concat(aniThu)
    fri = fri.concat(aniFry)
    sat = sat.concat(aniSat)
    sun = sun.concat(aniSun)



    // 記念日を日付順にしておく
    let aniSorted = ani;
    aniSorted.sort((a, b) => {
      if ((a.kinenbiMonth - 0) > (b.kinenbiMonth - 0)) return 1;
      if ((a.kinenbiMonth - 0) < (b.kinenbiMonth - 0)) return -1;
      if ((a.kinenbiDate - 0) > (b.kinenbiDate - 0)) return 1;
      if ((a.kinenbiDate - 0) < (b.kinenbiDate - 0)) return -1;
      return 0
    })

    //　記念日を月ごとに仕分ける
    const ani1 = aniSorted.filter((monthly) => monthly.kinenbiMonth === "1") //記念日を月ごとに分ける
    const ani2 = aniSorted.filter((monthly) => monthly.kinenbiMonth === "2") //記念日を月ごとに分ける
    const ani3 = aniSorted.filter((monthly) => monthly.kinenbiMonth === "3") //記念日を月ごとに分ける
    const ani4 = aniSorted.filter((monthly) => monthly.kinenbiMonth === "4") //記念日を月ごとに分ける
    const ani5 = aniSorted.filter((monthly) => monthly.kinenbiMonth === "5") //記念日を月ごとに分ける
    const ani6 = aniSorted.filter((monthly) => monthly.kinenbiMonth === "6") //記念日を月ごとに分ける
    const ani7 = aniSorted.filter((monthly) => monthly.kinenbiMonth === "7") //記念日を月ごとに分ける
    const ani8 = aniSorted.filter((monthly) => monthly.kinenbiMonth === "8") //記念日を月ごとに分ける
    const ani9 = aniSorted.filter((monthly) => monthly.kinenbiMonth === "9") //記念日を月ごとに分ける
    const ani10 = aniSorted.filter((monthly) => monthly.kinenbiMonth === "10") //記念日を月ごとに分ける
    const ani11 = aniSorted.filter((monthly) => monthly.kinenbiMonth === "11") //記念日を月ごとに分ける
    const ani12 = aniSorted.filter((monthly) => monthly.kinenbiMonth === "12") //記念日を月ごとに分ける



    setCalender(
      {
        MON: mon,
        THE: the,
        WED: wed,
        THU: thu,
        FRI: fri,
        SAT: sat,
        SUN: sun,
        ANI: ani,// 記念日系全て
        EIG: eig, //その他
        ANI1: ani1,// 1月
        ANI2: ani2,// 2月
        ANI3: ani3,// 3月
        ANI4: ani4,// 4月
        ANI5: ani5,// 5月
        ANI6: ani6,// 6月
        ANI7: ani7,// 7月
        ANI8: ani8,// 8月
        ANI9: ani9,// 9月
        ANI10: ani10,// 10月
        ANI11: ani11,// 11月
        ANI12: ani12,// 12月
      })

    // 保存したリストを設定
    if (user) {
      const favoList = await ifProxy.getFavoByUid(user.uid);
      if (favoList) {
        const resultAni8 = favoList.map((data, index) => {
          return data.calenderItem
        })
        setFavoList(resultAni8)
      }
    }


    setLoading(false);


    //ログインユーザのuserDataを取得する
    if (user) {
      ifProxyDefault.getUserData(user.uid).then((userData) => {
        // クリエイターである場合はフラグがTrue
        if (userData.remitedCreator) {
          setCreatorFlg(true)
        }
      })
    }

  }



  /**
   * 月日から、今年の曜日を算出する
   * @param {int} month 
   * @param {int} date 
   * @returns 曜日が何か
   */
  const getDayName = (month, date) => {
    let thisYearDate = new Date();
    thisYearDate.setMonth(month - 1) // 注意。0オリジン 1月は0から
    thisYearDate.setDate(date)
    let dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][thisYearDate.getDay()]
    console.log("getDayNameの出力")
    console.log(dayOfWeekStr)
    console.log("実際の値")
    console.log(thisYearDate.getDay())
    return thisYearDate.getDay()
  }

  /**
   * リストから、その曜日の記念日レコードを取得する
   * weekDayは0始まりで日曜日から始まる
   * @param {*} ani 
   * @param {*} weekDay 
   * @returns 
   */
  const getKinenbiForWeek = (ani, weekDay) => {
    const resultList = ani.filter((event) => {
      // 今月
      const kinenbiDateInt = parseInt(event.kinenbiDate, 10)
      const kinenbiMonthInt = parseInt(event.kinenbiMonth, 10)

      // 日付が今日から7日いない出ない場合は除外
      let thisYearDate = new Date();
      thisYearDate.setMonth(kinenbiMonthInt - 1) // 注意。0オリジン 1月は0から
      thisYearDate.setDate(kinenbiDateInt)

      // 今日
      let today = new Date();

      // 今日より7日後
      let sevenDayLater = new Date();
      sevenDayLater.setDate(sevenDayLater.getDate() + 7)

      /*
      console.log("今日より大きい")
      console.log(thisYearDate)
      console.log(today)
      console.log(today <= thisYearDate)
      console.log("今日から7日以内かチェック")
      console.log(thisYearDate)
      console.log(sevenDayLater)
      console.log(sevenDayLater > thisYearDate)
      */
      // 今年の記念日が本日以降であり、かつ、7日以内である。その他はfalseとしてフィルタで除外する
      if (!(today <= thisYearDate && sevenDayLater > thisYearDate)) {
        return false
      }


      const resultDayName = getDayName(kinenbiMonthInt, kinenbiDateInt)
      return resultDayName === weekDay
    })
    return resultList
  }


  const weekView = () => {
    return (<>
      {/** 曜日リスト ===================================================================== */}
      <Week calender={calender} />

      <Box className={classes.centerBox} >
        <Button
          disabled={!creatorFlg}
          variant="outlined"
          onClick={() => {
            setEventType("kikaku")
            setModalShow(true)
          }}
          color="info"
          sx={{
            margin: 2,
            zIndex: 1
          }}>
          ワンドロ企画を登録する(クリエイターユーザ限定機能)
        </Button>
      </Box>
    </>)
  }
  const calenderView = () => {
    return (<>
      {/** 年間一覧リスト ===================================================================== */}
      <Calender calender={calender} />
      <Box className={classes.centerBox} >
        <Button
          variant="outlined"
          disabled={!creatorFlg}
          onClick={() => {
            setEventType("aniv")
            setModalShow(true)
          }}
          color="info"
          sx={{ margin: 2 }}>
          記念日を登録する(クリエイターユーザ限定機能)
        </Button>
      </Box>
    </>)
  }
  const favView = () => {
    if (!user) {
      return (<>
        保存機能を利用するには<br></br>ユーザ登録 または　ログインが必要です
      </>)
    } else {
      return (<>
        <Favo calender={favoList} />
      </>)
    }


  }

  const filterGo = ({ type, value }) => {
    // stateを更新
    // ここでのstate更新をuseEffectでひろう
    console.log(type)
    console.log(value)

    // 送り込まれた　searchKeyWord の中身が何かに応じて次を更新
    if (type === "ganle") setGanle(value)
    if (type === "chara") setCharactor(value)
    if (type === "feature") setFeature(value)
    if (type === "clear") {
      setGanle("")
      setCharactor("")
      setFeature("")
    }


  }

  const searchView = () => {
    if (selectedSheet === "favo") {
      return (<></>)
    }
    else if (showSearch) {
      return (
        <>
          <SearchForm data={itemData} goFilter={filterGo}></SearchForm>
        </>
      )
    } else {
      return (
        <>
          <Button variant="outlined" sx={{ height: "100px", width: "400px" }} onClick={() => setShowSearch(true)}>絞り込む</Button>
        </>
      )
    }

  }

  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (loading) {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Box>
        </Box>
      </HeadFooter>
    )
  } else {

    const twitterShareUrl = "http://twitter.com/share?url=" + window.location.origin + '/User/' + params.id + "&text=ワンドロテーマを募集中&hashtags=ワンフロ"


    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      <>
        {modalShow && <CalenderModalView eventType={eventType} close={() => setModalShow(false)} />}
        <HeadFooter>
          <DisplayAd adType="yokonaga" />

          {selectedSheet === "week" && weekView()}
          {selectedSheet === "month" && calenderView()}
          {selectedSheet === "favo" && favView()}
          <DisplayAd adType="yokonaga" />

          < Box className={classes.centerBox}>
            {searchView()}

          </Box>





        </HeadFooter >
        <Box>
          <br></br>
          <br></br>
          <br></br>
          <br></br> <br></br>

        </Box>
        <CalenderContorolFooter returnState={(result) => setSelectedSheet(result)} />
      </>
    );
  }




}
export default OnedroCalender;

const style = {
  scoreLabel: {
    fontSize: 25,

  },
}

const useStyles = makeStyles((theme) => ({
  //オーバーレイ
  overlay: {
    zIndex: 10,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },//オーバーレイの中のモーダル
  modalView: {
    zIndex: 10,
    borderRadius: 15,
    width: '50%',
    padding: '1em',
    background: '#fff',
  },
  // 全体レイアウト
  contentWrapper: {
    marginTop: 20,
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",

  },
  // グリッド箱の書式 

  gridPadding: {
    padding: 5,
    border: '2px solid',
    borderColor: '#a6d4fa',
    backgroundColor: 'linear-gradient(to bottom right,#a6d4fa,#1976d2)',
    borderRadius: '10'

  },
  // グリッド箱の書式 枠線なし
  gridPaddingNoborder: {
    padding: 5,
  },
  // 年間一覧のレイアウト ------------------------------------
  // 曜日表の書式。全体
  yearList: {
    display: 'flex',
    justifyContent: 'flexStart',
    padding: 10,
    borderBottom: 'dashed lightBlue'

  },
  // 曜日の名前ラベル
  monthName: {
    width: '100px',
    textAlign: 'center',

  },
  // リストの内容
  monthList: {
    flex: 1
  },
  // 個別コンテンツ　中央揃えで縦に内容を並べる
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },// 個別コンテンツ　中央揃えで横に内容を並べる
  centerFlatBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftCenterBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftRowBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
  },
  // 個別コンテンツ　横並びに内容を並べる
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },

  // モーダルダイアログのフォーム　個別アイテム
  modalFormItem: {
    margin: 20,
  },
  //サンプルのグラフエリア
  sampleGraphEria: {
    width: 200
  },

  cardList: {
  },
  card: {
  },
  bottomCard: {
    width: 250
  },
}));




