import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import IfProxyCalender from '../../IfProxyCalender';
import IfProxyDefault from '../../IfProxy';

import HeadFooter from '../HeadFooter';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import FavoriteIcon from '@mui/icons-material/Favorite';
import InfoIcon from '@mui/icons-material/Info';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';


import CalenderModalView from "./CalenderModalView";
import CreateGoogleCalnenderTaskButton from "./../../common/CreateGoogleCalnenderTaskButton"

import Typography from '@mui/material/Typography';
// リスト要素の１つのコンポーネント
const EventCard = ({ item, fav }) => {
    const classes = useStyles();
    const ifProxy = new IfProxyCalender()
    const [isHovered, setIsHovered] = useState(false);
    const [modalShow, setModalShow] = useState(false);

    // googleカレンダー送る用のパラメータ
    const [title, setTitle] = useState("");
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");


    const { user } = useAuthContext();


    useEffect((props) => {
        console.log("===================")
        console.log(item)
        if (item.dayName === 7) {
            setTitle(item.description)
            // ゼロうめ
            let targetDate = new Date()
            targetDate.setMonth(item.kinenbiMonth - 1)
            targetDate.setDate(item.kinenbiDate)
            const month = targetDate.getMonth() + 1
            const date = targetDate.getDate()
            const dateStrMonth = month.toString().padStart(2, "0")
            const dateStrdate = date.toString().padStart(2, "0")
            const resultDate = targetDate.getFullYear() + dateStrMonth + dateStrdate
            /*
            console.log("RESULT ~~~~~~~~^")
            console.log(targetDate.getFullYear())
            console.log(dateStrMonth)
            console.log(dateStrdate)
            */
            setStart(resultDate)
            setEnd(resultDate)
        }
    }, []);



    /** 記念日用　今日が記載の月に近い
   * 近いほど値が小さい
   * @param {*} month 
   * @param {*} day 
   * 0 今日
   * 1 今週
   * 2 今月
   * 3 今月ではない
   * @returns 
   */
    const todayMonthKbn = (month, day) => {
        month = month - 0;//数値にする
        day = day - 0;
        var nowDateate = new Date();
        var nowMonth = nowDateate.getMonth() + 1
        var nowDay = nowDateate.getDate()

        // 経過
        if (month < nowMonth || day < nowDay) {
            return 3
        }
        // 今日
        if (month === nowMonth && day === nowDay) {
            return 0
        }
        // 7日以内
        if (month === nowMonth && (day - nowDay) <= 7) {
            //console.log("7日以内")
            return 1
        }
        //　当月
        if (month === nowMonth) {
            //console.log("今月")
            return 2
        }
        return 3
    }

    // 表示用の整形
    const todayMonth = (month, day) => {
        const flg = todayMonthKbn(month, day)
        month = month + 0;//数値にする
        switch (flg) {
            case 3:
                return ""
                break;
            case 2:
                return "今月"
                break;
            case 1:
                return "7日以内"
                break;
            case 0:
                return "本日!"
                break;
            default:
                break;
        }
    }// 表示用の整形 CSSカラー用
    const todayMonthColor = (month, day) => {
        const flg = todayMonthKbn(month, day)
        month = month + 0;//数値にする
        switch (flg) {
            case 3:
                return ["#bbdefb", "black"]
                break;
            case 2:
                return ["#4762b3", "white"]
                break;
            case 1:
                return ["#2447b3", "white"]
                break;
            case 0:
                return ["#da70d6", "white"]
                break;
            default:
                break;
        }
    }


    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const createBookmark = () => {
        ifProxy.createFavo({
            calenderItemId: item.id,
            uid: user.uid,
            calenderItem: item,
        }).then(() => {
            window.location.reload()
        })


    }
    const deleteBookmark = () => {
        console.log(item.id)
        console.log(user.uid)

        ifProxy.deleteFavo(item.id, user.uid).then(() => {
            window.location.reload()
        })


    }

    // 記念日用のラベル
    if (item.nameLabel === "記念日") {
        return (
            <>
                {modalShow && <CalenderModalView clickedEventData={item} eventType="eventView" close={() => setModalShow(false)} />}
                <Box
                    key={item.id}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={
                        {
                            transition: 'all 0.3s',
                            marginTop: 2,
                            padding: 10,
                            width: '100%',
                            color: todayMonthColor(item.kinenbiMonth, item.kinenbiDate)[1],
                            backgroundColor: isHovered ? '#7ea0f8' : todayMonthColor(item.kinenbiMonth, item.kinenbiDate)[0],
                            borderRadius: isHovered ? 0 : 5,
                            display: 'flex'
                        }
                    }
                >
                    <Box style={{ width: "100px" }}>
                        【{item.kinenbiMonth}月{item.kinenbiDate}日】 {todayMonth(item.kinenbiMonth, item.kinenbiDate)}
                    </Box>
                    <Box style={{ flex: 4 }}>
                        #{item.name}
                    </Box>
                    <Box style={{ flex: 1 }}>
                        <Button disabled={!user} onClick={() => setModalShow(true)}>
                            <InfoIcon sx={{ height: 25, width: 25 }} />
                        </Button>
                    </Box>
                    <Box style={{ flex: 4 }}>
                        {item.ganleTagDataStr?.join(' ')}{item.charactorTagDataStr
                            ?.join(' ')}{item.featureTagDataStr?.join(' ')}                    </Box>
                    <Box style={{ flex: 1 }}>
                        <Button disabled={!user} onClick={() => createBookmark()}>
                            <FavoriteIcon sx={{ height: 25, width: 25 }} />
                        </Button>
                    </Box>

                    {fav && <Box style={{ flex: 1 }}>
                        <CreateGoogleCalnenderTaskButton
                            title={title}
                            url={`${window.location.origin}/OnedroCalender`}
                            start={start}
                            end={end}
                        />
                    </Box>}

                    {fav && <Box style={{ flex: 1 }}>
                        <Button onClick={() => deleteBookmark()}>
                            <RemoveCircleOutlineIcon sx={{ height: 25, width: 25 }} />
                        </Button>
                    </Box>}



                </Box >
            </>
        );
    } else {
        // １週間カレンダー用のラベル
        return (
            <>
                {modalShow && <CalenderModalView clickedEventData={item} eventType="eventView" close={() => setModalShow(false)} />}
                <Box
                    key={item.id}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={
                        {
                            transition: 'all 0.3s',
                            marginTop: 2,
                            padding: 10,
                            width: '100%',
                            backgroundColor: isHovered ? '#7ea0f8' : '#bbdefb',
                            borderRadius: isHovered ? 0 : 5,
                            display: 'flex'
                        }
                    }
                >
                    <Box style={{ flex: 4 }}>
                        #{item.name}
                    </Box>
                    <Box style={{ flex: 1 }}>
                        <Button disabled={!user} onClick={() => setModalShow(true)}>
                            <InfoIcon sx={{ height: 25, width: 25 }} />
                        </Button>
                    </Box>
                    <Box style={{ flex: 4 }}>
                        {item.ganleTagDataStr?.join(' ')}{item.charactorTagDataStr
                            ?.join(' ')}{item.featureTagDataStr?.join(' ')}
                    </Box>
                    <Box style={{ flex: 1 }}>
                        <Button disabled={!user} onClick={() => createBookmark()}>
                            <FavoriteIcon sx={{ height: 25, width: 25 }} />
                        </Button>
                    </Box>

                    {fav && <Box style={{ flex: 1 }}>
                        <Button onClick={() => deleteBookmark()}>
                            <RemoveCircleOutlineIcon sx={{ height: 25, width: 25 }} />
                        </Button>
                    </Box>}

                </Box>
            </>
        );
    }
}
export default EventCard;



const useStyles = makeStyles((theme) => ({
    test: {

    },
}));