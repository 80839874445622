import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';

// TODO: StandardPageはパスが異なるため注意
import IfProxy from '../../IfProxy';
import HeadFooter from '../HeadFooter';

import TagInput from "../../common/TagInput";
import AgreecheckSightShare from "../../common/AgreecheckSightShare";


// Material UI
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

// ReactGA Analytics
import ReactGA from "react-ga4";


import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const CreateOdai = (props) => {


    const [loading, setLoading] = useState(true);
    const [onedroTagsSag, setOnedroTagsSag] = useState(""); // productに設定したタグ
    const [onedroTag, setOnedroTag] = useState(""); // productに設定したタグ
    const [onedroTagString, setOnedroTagString] = useState(""); // productに設定したタグ
    const [agreecheckSightShareFlg, setAgreecheckSightShareFlg] = useState(1);


    // 画面入力項目
    const [odaiTitle, setOdaiTitle] = useState(""); // お題のタイトル
    const [odaiDetail, setOdaiDetail] = useState(""); // お題メッセージ
    const [type, setType] = useState("free"); // 種別 kikaku/free 企画系/自由テーマ
    const [typeCommision, setTypeCommision] = useState(0); // お気軽アイデア提供orガチ募集 0:無償の範囲で/1:有償も厭わない


    //表示用
    const auth = getAuth();
    const navigate = useNavigate();

    const { user } = useAuthContext();
    const classes = useStyles();
    const params = useParams();
    const ifProxy = new IfProxy();

    const location = useLocation();

    useEffect((props) => {
        setLoading(true)
        // ReactGA Analytics ---
        ReactGA.initialize("G-2ZCXE08H2S");
        ReactGA.send({
            hitType: "pageview",
            // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
            page: location.pathname + location.search,
        });
        // ------

        loadData(props);
    }, []);


    const loadData = (props) => {

        ifProxy.getTag("ワンドロ企画", 10).then((result) => {
            setOnedroTagsSag(() => result)
            console.log(result)
        })
        setLoading(false)

    }

    const submit = async () => {
        //TODO await ifProxy.insertTag(onedroTag, "ワンドロ企画");

        const userData = await ifProxy.getUserData(user.uid)

        let onedroTagStringList = [];

        if (type === "kikaku") {
            onedroTagStringList = onedroTag.map((data, index) => {
                return data.text
            })
        }

        // お題の作成
        await ifProxy.addOdai({
            odaiTitle,
            odaiDetail,
            type,
            typeCommision,
            onedroTag,
            onedroTagString: onedroTagStringList,
            fromUid: userData.uid,
            fromUser: userData.displayName,
            fromUrl: userData.icon,
            agreecheckSightShareFlg
        }).then(result => abort())

    }

    // 戻る
    const abort = async () => {
        navigate(-1)
    }


    const returnKikakuTags = (tag) => {
        // 企画名のタグ
        setOnedroTag(tag)
    }



    // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
    if (!user) {
        return <Navigate to="/login" />;
    } else if (loading) {
        return (
            <HeadFooter>
                <Box className={classes.contentWrapper}>
                    <Box className={classes.mainBox}>
                        <p>読み込み中...</p>
                    </Box>
                </Box>
            </HeadFooter>
        )
    } else {

        // Boxレイアウトのレスポンシブ対応
        // https://mui.com/components/grid/#responsive-values
        return (
            <HeadFooter>
                <Box className={classes.contentWrapper}>
                    <Box className={classes.mainBox} >

                        <Box className={classes.centerBox}>
                            <TextField fullWidth id="outlined-basic" label="タイトル"
                                onChange={(e) => setOdaiTitle(e.target.value)}
                                variant="outlined" value={odaiTitle} />
                        </Box>
                        <Box className={classes.centerBox}>
                            <TextField fullWidth id="outlined-basic" label="お題の内容"
                                multiline rows={4}
                                onChange={(e) => setOdaiDetail(e.target.value)}
                                variant="outlined" value={odaiDetail} />
                        </Box>
                        <Box className={classes.centerBox}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">お気軽アイデア提供orガチリクエスト</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={typeCommision}
                                    label="typeCommision"
                                    onChange={(e) => setTypeCommision(e.target.value)}
                                >
                                    <MenuItem value={0}>無償の範囲で</MenuItem>
                                    <MenuItem value={1}>有償も厭わない</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box className={classes.centerBox}>

                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">対象のワンドロ企画がある・またはフリーテーマ</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={type}
                                    label="type"
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    <MenuItem value={"free"}>想定する企画なし</MenuItem>
                                    <MenuItem value={"kikaku"}>下記のワンドロ企画に参加する人へ</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>


                        {(type === "kikaku" && onedroTagsSag) && <TagInput type="formKikaku" resultTags={(tags) => returnKikakuTags(tags)} suggestion={onedroTagsSag}></TagInput>}

                        <Box className={classes.centerBox}>
                            <Box className={classes.leftBox} >
                                <AgreecheckSightShare paramStatus={agreecheckSightShareFlg} setResult={(result) => setAgreecheckSightShareFlg(result)}></AgreecheckSightShare>
                            </Box>
                        </Box>

                        <Button
                            variant="contained"
                            fullWidth
                            disabled={type === "kikaku" && onedroTag === ""}
                            onClick={() => submit()}
                            color="primary"
                            sx={{ margin: 2 }}>
                            登録
                        </Button>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => abort()}
                            color="primary"
                            sx={{ margin: 2 }}>
                            戻る
                        </Button>
                    </Box>
                </Box>

            </HeadFooter >

        );
    }




}
export default CreateOdai;

const useStyles = makeStyles((theme) => ({
    // 全体レイアウト
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

    },
    mainBox: {
        maxWidth: "1000px",
        margin: "0 auto",
    },
    centerBox: {
        marginTop: 40,
        minWidth: 500,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    leftBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: "0 auto",
    },
    cardList: {
    },
    card: {
    },
}));
