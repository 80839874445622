import React, { useState, useEffect } from "react";
import { useAuthContext } from '../../AuthContext';
import HeadFooter from '../HeadFooter';
import Typography from '@mui/material/Typography';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';

import IfProxy from '../../IfProxy';


// GA4
import ReactGA from "react-ga4";


// Material UI
import { makeStyles } from '@material-ui/core/styles';


import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// TODO 公開前の事前アンケートLPを表示するためだけのバージョン
const DEBUG_VERSION = true;
const imageHeight = 250;


const LpTimer = (props) => {
  const { user } = useAuthContext();
  const classes = useStyles();
  const navigate = useNavigate();

  const [detailOpen, setDetailOpen] = useState(false);
  const [nsfwFlg, setNsfwFlg] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenText, setIsOpenText] = useState(false);

  const [cyumokuProduct, setCyumokuProduct] = useState([]);
  const [loading, setLoading] = useState(true);

  const [v2LpShowDatail, setV2LpShowDatail] = useState(false);


  const location = useLocation();


  const ifProxy = new IfProxy();


  useEffect((props) => {
    setLoading(true)
    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    ReactGA.event("ワンドロタイマーLPを見た");
    ifProxy.gaEventTest("showLp")
    // ------
    loadData()

    return () => {
    }

  }, []);

  const clickSignup = () => {
    navigate('/login');
    //pv直帰しなかった数
  }



  const loadData = () => {

  }






  // Boxレイアウトのレスポンシブ対応
  // https://mui.com/components/grid/#responsive-values

  setTimeout(() => setIsOpen(true), 800);
  setTimeout(() => setIsOpenText(true), 500);


  const setResult = () => {

  }
  return (
    <Box>
      <div style={{
        position: "relative",
        maxWidth: "1000px",
        margin: "0 auto",

      }}>

        <img src={`${process.env.PUBLIC_URL}/logo_h2.png`}
          style={{
            width: '100%',
            maxWidth: "1000px",
            margin: "0 auto",
            opacity: 0.8,
            display: "block",

          }} />

        <Typography
          style={{
            transition: '1s',
            opacity: isOpen ? 1 : 0,
            width: isOpen ? '80%' : 0,
            fontFamily: "Helpetica",
            position: "absolute",
            top: "80%",
            left: "50%",
            transform: "translate(-50%,-40%)",
            color: "#0099ff",
            fontWeight: 'bold',
            fontSize: 20,
            padding: 2,
            margin: 2,
            backgroundColor: 'rgba(255,255,255,0.5)',
            overflowWrap: 'break-word'
          }}
        >
          <p>ワンドロ応援サービス</p>
          <p>ワンドロの時間管理に特化したタイマーを無料で利用できます</p>
          <p>測定結果がグラフで見える日記を作成してモチベーションを維持します</p>
          <p>季節の#タグや今週行われる予定のワンドロ企画をカレンダーで調べることができ亜mす</p>
          <p>60分間のワンドロに限定したリクエストを受け付けることができます</p>

        </Typography>

      </div>
      {/* =================================================================== */}



      {/**------GRIDでテスト-------- */}
      <Grid container alignItems="center" justify="center" sx={{ marginTop: '100px' }}>
        <Grid item xs={12} sx={{ height: '70px' }}>
          <Box className={classes.mainBox}>
            <Box className={classes.centerBox}>
              <Button variant="contained" fullWidth onClick={clickSignup} sx={style.conversionBtn}> はじめる</Button>
            </Box>
          </Box>
        </Grid>
        {/* ========= V2募集　========================================================== */}

        <Grid item xs={12} className={classes.contentWrapper}>
          <hr></hr>

          <Box className={classes.centerBox} sx={{
          }}>
            <h1>モニター募集中!</h1>
            <p>作業時間管理に便利なプレミアム機能「V2タイマー」を無料開放します</p>
          </Box>
          <hr></hr>
          <Box className={classes.centerBox} >
            <img width="80%" src={`${process.env.PUBLIC_URL}/V2LP.png`} alt="v2lp"></img>
            <Button variant="contained" onClick={() => setV2LpShowDatail((pre) => !pre)} sx={style.conversionBtn}> 詳細を見る</Button>

          </Box>
          {v2LpShowDatail && <Box className={classes.centerBox} >
            <img width="80%" src={`${process.env.PUBLIC_URL}/V2LP_2.png`} alt="v2lp_2"></img>
            <p>次のリンクからXコミュニティに参加し、「v2タイマーモニター参加します」とお声がけください。<br></br>
              折り返し、アカウントをお伺いしプレミアムフラグを有効化いたします</p>

            <h1> <a href="https://x.com/i/communities/1776452060931883466">まずはコミュニティ参加から</a></h1>
            <br></br>
            <Button variant="contained" onClick={() => setV2LpShowDatail((pre) => !pre)} sx={style.conversionBtn}> 閉じる</Button>

          </Box>}






        </Grid>
        {/* ========= V2募集 ここまで　========================================================== */}

        <Grid item xs={12} className={classes.gridItemBlueback}>
          <Box className={classes.centerBox} sx={{
          }}>
            <h1>ワンドロを行うクリエイター、そしてファンを支援します</h1>
            <h3><b>普段のワンドロをもっと楽しく・便利にするサイトを目指します</b></h3>
            <p>
              皆様のご要望を聞きながら、機能を拡張してまいります<br></br><br></br>
              まずは、
              <ul>
                <li>「ワンドロタイマー」と</li>
                <li>「ワンドロカレンダー」と</li>
                <li>「リクエスト」</li>
              </ul>
              のご提供からスタートいたします<br></br><br></br>
              普段こんなことに不便を感じている。こういうツールや統計情報が獲得できたら助かる<br></br>
              そういったご要望がありましたら、可能な限り対応しますので、ご希望をお寄せください
            </p>
          </Box>
        </Grid>
      </Grid>




      {/* =================================================================== */}
      < Box className={classes.contentWrapper} >
        <Box className={classes.mainBox}>
          <hr></hr>
          <Box className={classes.centerBox} sx={{ height: '500px' }}>
            <h3>ワンドロとは</h3>
            <p>ワンアワードローイングの略称です。１時間制限の中で構想から下書き、清書までイラストを描き上げるチャレンジです。<br></br><br></br><br></br><br></br>
              チャレンジと学習のサイクルを短期間で多く回すことにより、スキルの向上につながります<br></br><br></br>
              また、SNS上で多数のワンドロチャレンジ企画が進行しておりますのでイラストを通した交流が広がります<br></br>

            </p>


          </Box>

          <hr></hr>
          {/* =================================================================== */}
          <Box className={classes.centerBox}>
            <h2>このサイトでできること</h2>
          </Box>

          <Grid container justify="center" spacing={2} >
            {/** １段目 ===================================================================== */}
            <Grid item xs={4} className={classes.gridPadding}>
              <Box className={classes.centerBox}>
                <Card className={classes.gridCard} style={style.gridCard}>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={style.h3Text}>
                      <b>ワンドロに特化したタイマー</b>
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={style.bodyText}>
                      忙しいワンドロ中にシンプルな操作で、残り時間確認と所要時間記録を行えるタイマーです<br></br><br></br>
                      「何に何分かかったのか」をグラフで可視化。次の練習に活かせる分析結果を得られます<br></br>
                      <br></br>
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={4} className={classes.gridPadding}>
              <Box className={classes.centerBox}>
                <Card className={classes.gridCard} style={style.gridCard}>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={style.h3Text}>
                      <b>描くテーマを探すのに便利なカレンダー</b>
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={style.bodyText}>
                      『#〇〇の日』をまとめたカレンダーや
                      今週開催予定のワンドロ#タグを見つけることができるカレンダーがあります<br></br><br></br>
                      クリエイターユーザみんなで日付情報を追加して<br></br>
                      ジャンルを問わない一覧を一緒に作りましょう<br></br>
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={4} className={classes.gridPadding}>
              <Box className={classes.centerBox}>
                <Card className={classes.gridCard} style={style.gridCard}>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={style.h3Text}>
                      <b>ワンドロのリクエストを受け付ける</b>
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={style.bodyText}>
                      あなたのファンにリクエストの募集を行うことができます<br></br><br></br>
                      リクエストは「作品のSNSでのシェア(紹介)」を条件に受け付けることができます<br></br>
                      有償でのリクエスト募集も可能です<br></br>
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            {/** 2段目 ===================================================================== */}
            <Grid item xs={4} className={classes.gridPadding}>
              <Box className={classes.centerBox}>
                <Card className={classes.gridCard} style={style.gridCard}>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={style.h3Text}>
                      <b>好きなテーマを描いてくれる作家さんを探す</b>
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={style.bodyText}>
                      「こういうテーマのワンドロをやってくれる人いないかな」<br></br><br></br>
                      というリクエストを投稿できます<br></br>
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>


            <Grid item xs={4} className={classes.gridPadding}>
              <Box className={classes.centerBox}>
                <Card className={classes.gridCard} style={style.gridCard}>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={style.h3Text}>
                      他、<br></br>「こんなのあったらいいな」<br></br>をお寄せください！
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={style.bodyText}>
                      <a href="https://twitter.com/illustrental">サポートアカウント(イラストレンタル共通)</a><br></br>
                      <a href="https://x.com/i/communities/1776452060931883466">コミュニテイ(機能改善アイデア募集中お気軽にご参加ください)</a>

                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>

          </Grid>




          {/* =================================================================== */}

        </Box >
      </Box >
      <Grid container alignItems="center" justify="center" sx={{ paddingTop: '0px' }} className={classes.centerBoxVariation2}>

        <Grid item sm={12} md={12} alignItems="center" justify="center" className={classes.centerBoxVariation2Title} sx={{ color: "white", margin: '0 auto' }}>
          <h2>ワンドロタイマーとワンドロ日記</h2>
        </Grid>

      </Grid >
      {/**-------------- */}
      <Box className={classes.contentWrapper}>
        <Box className={classes.mainBox}>


          <Box className={classes.centerBox}>
            <h2>ワンドロタイマー</h2>
          </Box>
          <Box className={classes.centerBox}>
            <ul>
              <li>あとどれくらいの時間ペン入れしていてもペース上大丈夫か</li>
              <li>何にどれくらいかかったかログを取りたいけど、ワンドロ中は時間がないので記録できない</li>
            </ul>
            <p>
              こういった情報を直観的に管理するためのタイマーです<br></br>
              工程ごとに残り時間を確認しペースメーカーとすることができます<br></br>
              何にどれくらい時間がかかったのかを記録し、後かグラフで分析ができます<br></br><br></br>
            </p>
            <img src={`${process.env.PUBLIC_URL}/lpTimer_3_timer.png`}
              style={{
                width: '50%',
                maxWidth: "500px",
                margin: "0 auto",
                opacity: 0.8,
                display: "block",
              }} />
            <Button onClick={() => navigate('/OnedroTimer')} sx={{ backgroundColor: "white" }}>実物をこちらからご覧ください。無料で、すぐにご利用が可能です</Button>
          </Box>
        </Box>
      </Box>
      {/**-------------- */}
      <Box className={classes.contentWrapper}>
        <Box className={classes.mainBox}>


          <Box className={classes.centerBox}>
            <h2>ワンドロ日記</h2>
          </Box>
          <Box className={classes.centerBox}>
            <p>
              <b>実施結果をビジュアライズしてレポート。<br></br>
                結果をSNSシェアするなどしてモチベーション維持に役立てられます</b>
            </p>
            <ul>
              <li>毎日のワンドロ結果の記録を残すことができます</li>
              <li>ワンドロタイマーのデータを元に、何にどれくらい時間がかかったかやミスが多かった場所をビジュアル分析</li>
              <li>次回ワンドロする時の参考にすることができます</li>

            </ul>

            <img src={`${process.env.PUBLIC_URL}/lpTimer_2_dialy.png`}
              style={{
                width: '50%',
                maxWidth: "500px",
                margin: "0 auto",
                opacity: 0.8,
                display: "block",
              }} />
          </Box>
        </Box>
      </Box>
      {/* =================================================================== */}

      <Grid container alignItems="center" justify="center" sx={{ paddingTop: '0px' }} className={classes.centerBoxVariation2}>

        <Grid item sm={12} md={12} alignItems="center" justify="center" className={classes.centerBoxVariation2Title} sx={{ color: "white", margin: '0 auto' }}>
          <h2>カレンダー</h2>
        </Grid>

        <Grid item sm={12} md={6} className={classes.centerBoxVariation2Title}>
          全ジャンルを網羅した、みんなで作るカレンダー<br></br>
          <ul>
            <li>参加できる#ワンドロ会を探しているけど、探してもなかなか見つからない</li>
            <li>絵を描きたいけど、やりたいテーマが思いつかない</li>
            <li>誕生日を迎えるキャラとか、#〇〇の日、といったイベントを探したい</li>
            <li>SNSで盛り上がっている#ハッシュタグに気づいて、忘れてた。参加すればよかった。と後悔することがあるかと思います </li>

          </ul>
          そんな時に役立つ、記念日情報の一覧表です


        </Grid>
      </Grid >


      < Box className={classes.contentWrapper} >
        <Box className={classes.mainBox}>
          <Box className={classes.centerBox} sx={{
            backgroundColor: ''
          }}>
            <ul>
              <li>「今週参加できるワンドロ企画ないかな？」と思った時。週間スケジュールから探すことができます</li>
              <li>気になった記念日は、ブックマークしておくことができます。近づいたら、トップページ上でお知らせいたします</li>
              <li>絵を描きたいけど、描きたいことが思いつかない時は、ぼんやりカレンダーを眺めてみるのはいかがでしょうか</li>
            </ul>
            <p>クリエイターの皆様は知っている記念日を登録することができます。参加者全員で自分のジャンルの誕生日や記念日を登録して、一緒にカレンダーを作りましょう</p>

            <img src={`${process.env.PUBLIC_URL}/lpTimer_1_calender.png`}
              style={{
                width: '50%',
                maxWidth: "500px",
                margin: "0 auto",
                opacity: 0.8,
                display: "block",
              }} />
            <Button onClick={() => navigate('/OnedroCalender')} sx={{ backgroundColor: "white" }}>実物をこちらからご覧ください。無料で、すぐにご利用が可能です</Button>

          </Box>

        </Box >
      </Box >
      {/* =================================================================== */}

      <Grid container alignItems="center" justify="center" sx={{ paddingTop: '0px' }} className={classes.centerBoxVariation2}>

        <Grid item sm={12} md={12} alignItems="center" justify="center" className={classes.centerBoxVariation2Title} sx={{ color: "white", margin: '0 auto' }}>
          <h2>リクエスト</h2>
        </Grid>

        <Grid item sm={12} md={6} className={classes.centerBoxVariation2Title}>
          有償、無償でのリクエストができます。<br></br>
          無償の場合は、リクエストした人は対価として、イラストの拡散（SNSシェアなど）を約束します
        </Grid>



      </Grid >

      {/**-------------- */}
      <Box className={classes.contentWrapper}>
        <Box className={classes.mainBox}>


          <Box className={classes.centerBox}>
            <h2>本サービスのリクエストの特徴</h2>
          </Box>
          <Box className={classes.centerBox}>
            <p>
              本サービスのリクエストには特殊なルールがあります。<br></br>
              <b>解決したいことは</b><br></br>
              コミッションなどリクエストの募集に伴う、作家さんのプレッシャーを減らし、<br></br>
              もっと気軽にイラストでコミュニケーションできること<br></br><br></br>
              依頼するファンの「イラストを発注する」というプレッシャーをなくし<br></br>
              もっと気軽に、リクエストをして共創を楽しむこと<br></br><br></br>
              <b>そのために、以下のルールを設定しております</b>

            </p>
          </Box>

        </Box>

      </Box>
      {/* =================================================================== */}

      <Grid container alignItems="center" justify="center" sx={{ paddingTop: '100px' }} className={classes.centerBoxVariation1}>
        <Grid item sm={12} md={6} className={classes.centerBoxVariation1Title}>
          <ul>
            <li>
              <Typography variant="h5" component="div" >
                <b>「完成責任がない」気軽なリクエスト専用</b><br></br><br></br>
              </Typography>
            </li>
            <li>
              <Typography variant="h5" component="div" >
                <b>作業時間は６０分間（ワンドロ）</b><br></br><br></br>
              </Typography>
            </li>
            <li>
              <Typography variant="h5" component="div" >
                <b>報酬は金銭または「シェア(金銭を伴わないリクエスト募集)」も可能</b><br></br><br></br>
              </Typography>
            </li>
          </ul>
        </Grid>

        <Grid item sm={12} md={6} className={classes.centerBoxVariation1Title}>
          <Typography variant="h4" component="div" >
            <b>だから、<br></br><br></br>
              気軽にリクエストできる。<br></br><br></br>
              気軽に受けられる</b><br></br><br></br>
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.centerBoxVariation1Title}>
          <Box className={classes.centerBox}>
            <Button variant="outlined" sx={{ marginRight: '10px', backgroundColor: 'white', height: '100px', borderRadius: '45px' }} onClick={() => navigate('/lp')} > リクエスト機能についてのご紹介はこちらから</Button>
          </Box>
        </Grid>





      </Grid >

      < Box className={classes.contentWrapper} >
        <Box className={classes.mainBox}>
          <Box className={classes.centerBox} sx={{
            backgroundColor: '',
            margin: '20px'
          }}>
            <hr></hr>
            <Box className={classes.centerBox} sx={{ height: '600px' }}>
              <h3>プレミアム機能(β)について</h3>

              <p>
                月額500円にて限定機能を提供しております。
              </p>
              <ul>
                <li>広告カット<br></br><br></br></li>
                <li>サイト内へのPR配信<br></br>
                  あなたのアカウントをPRする広告のサイト内自動配信します。PV増加であなたのリクエスト獲得をお助けします<br></br><br></br></li>
                <li>ワンドロ記録の時系列分性<br></br>
                  最近実施したワンドロ記録の推移をグラフで可視化することができます。成長を感じてモチベーションにしましょう！
                  <br></br><br></br>
                </li>
                <li>
                  解析(実装検討中。ぜひご意見ください)<br></br>
                  閲覧、ファボ、受注、などのメトリクスを多変量解析。<br></br>
                  何を描いた時にインプレッションが伸びやすいかデータ分析を行います。
                  自分はどういう表現が強いのか、客観的に分析し作家性を尖らせる武器になります<br></br>


                </li>
              </ul>
              <p>
                プレミアム限定機能については、現在開発中になります。皆様のご要望をもとに機能拡張・廃止を進めてまいります<br></br>
                ユーザの皆様からの意見を募集しております。<br></br><br></br>
                こんな機能があったら助かる。といったようなご意見ございましたら<br></br>
                是非とも、<b>Xサポートアカウント</b>まで、お気軽にお寄せください。
              </p>

            </Box>

            <hr></hr>


          </Box>

        </Box >
      </Box >


      <Grid container alignItems="center" justify="center" sx={{ paddingTop: '0px' }} className={classes.centerBoxVariation2}>

        <Grid item sm={12} md={12} className={classes.centerBoxVariation2Title} sx={{ color: "white", margin: '0 auto' }}>
          <h2>次のアクション</h2>
          <ul>
            <li>
              次のボタンからまずはユーザ登録をしましょう(所要時間１分)
            </li>
            <br></br>
            <Button variant="contained" fullWidth onClick={clickSignup} sx={style.conversionBtn}> はじめる</Button>
            <br></br>
            <br></br>
            <li>
              ユーザ登録ができたら、入門コースへ進み、機能を開放しつつ利用方法を把握しましょう
            </li>
            <br></br>
            <Button onClick={() => navigate('/tu')} sx={{ backgroundColor: "white" }}>入門コース</Button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <li>
              現在はβ公開期間中です。「こういった機能があったら嬉い」など。ご意見や困り事などありましたら、<br></br>
              お気軽に以下SNSアカウント(「イラストレンタルと共用中」)までご連絡ください<br></br><br></br>
              絵を描いている皆様の日々思っていることなど。世界観、ぜひ教えてください！<br></br>
              (雑談振ってもらっても、可能な限り反応します)
            </li>
            <Button
              sx={{
                borderRadius: '15px',
                width: "60%",
                margin: 1,
                color: 'white'
              }}
              href={'https://twitter.com/illustrental'}
              variant="contained"
              color="secondary">
              サポートアカウント <img width="50" src={`${process.env.PUBLIC_URL}/twitterIcon.png`} alt="twitterLogo"></img>
            </Button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <li>
              絵描きさんのモニター募集しております<br></br><br></br>
              <b>使える道具になることを最重要視しており、絵描きの皆様のリアルな声が必要です</b><br></br><br></br>
              ユーザと運営との間で、こんなのが欲しい。こんなことが困ったなど。情報交換できるコミュニティがあります<br></br>
              ROM専でも大丈夫です。ぜひ、ご興味があるようでしたらお気軽にご参加ください！<br></br>
            </li>
            <br></br>
            <Button href={'https://x.com/i/communities/1776452060931883466"'} sx={{ backgroundColor: "white" }}>コミュニティを見る</Button>


          </ul>
        </Grid>

      </Grid>

      < Box className={classes.contentWrapper} >
        <Box className={classes.mainBox}>

          <br></br>




        </Box >
      </Box >
    </Box >


  );
}
export default LpTimer;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
    padding: 30,
    maxWidth: "1000px",
    margin: "0 auto",
  },
  // コンテンツの幅。端末ごとに広がりすぎるのを防ぐ。左揃え
  mainBox: {
    maxWidth: "700px",
    margin: "0 auto",
  },
  // グリッドアイテム。背景が青で白文字の箱
  gridItemBlueback:
  {
    color: 'white',
    backgroundColor: '#0099ff',
    height: '700px',
    padding: '20px'
  },

  // 個別コンテンツ　中央揃えで縦に内容を並べる
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  // ３つのキーメッセージ
  centerBoxVariation1: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: 'linear-gradient(0deg,#0099ff 90%,#ffffff 100%)',
  },
  centerBoxVariation1Title: {
    padding: '50px',
    margin: "0 auto",
    color: '#ffffff'
  },
  // メリット
  centerBoxVariation2: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#0099ff',
  },
  centerBoxVariation2Title: {
    padding: '50px',
    margin: "0 auto",
    color: '#ffffff'
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftAlignBox: {
    display: 'flex',
    marginTop: '70px',
    flexDirection: 'column',
    maxWidth: "800px",
    minWidth: "600px",
    alignItems: 'left',
  },
  // 個別コンテンツ　横並びに内容を並べる
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },
  cardList: {
    margin: 10, //'0 auto'
    display: 'flex',
    alignItems: 'center'
  },
  imageCard: { // トップサンプルのカード
    marginTop: 10,
    marginBottom: 20,
    padding: 0,
    width: 300,
  },
  card: { // LP説明用カード
    marginTop: 10,
    marginBottom: 20,
    padding: 0,

  },
  gridCard: { // LP説明用カード
    marginTop: 10,
    marginBottom: 20,
    padding: 0,
    height: 250,

  },
  imageContainer: {
    padding: 0,
  },

  //ヘッダ用
  // 全体レイアウト
  root: {
    flexGrow: 1,
  },
  // 各部分とその中身
  header: {
    background: 'linear-gradient(to bottom right,#9575cd,#f27573)',
    color: "#fff"
  },
  menuButton: {
    marginRight: 10,
  },
  loginBtn: {
    color: "#00bcd4"
  },
  lpBtn: {
    padding: 10,
    margin: 10,
    color: "#000000"

  },
}));

const conversionColor = "#c71585";

// 色とカードの形状はここで制御　
const style = {
  conversionBtn: {
    backgroundColor: conversionColor,
    color: '#fff',
    borderRadius: 10
  },
  title: {
    fontSize: 22,
    color: "#696969"
  },
  h3Text: {
    fontSize: 17,
    color: "#0099ff"
  },
  shokaiText: {
    margin: 2,
    fontSize: 20,
    color: "#696969"
  },
  bodyText: {
    fontSize: 12,
    color: "#000065"
  },
  card: {
    //boxShadow: '10px 2px 20px #CCD8FF',
    maxWidth: "500px",
    background: 'linear-gradient(to bottom right,#CCD8FF,#CCFFF2)',
  },
  gridCard: {
    //boxShadow: '10px 2px 20px #CCD8FF',
    maxWidth: "500px",
    background: 'linear-gradient(to bottom right,#CCD8FF,#FFE4E1	)',
  },

  listItem: {
    marginTop: 20,
  },
  cardProblem: {
    //boxShadow: '10px 2px 20px #CCD8FF',
    background: 'linear-gradient(to bottom right,#CCD8FF,#ffc0cb)',
  },
}

