import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from './../AuthContext';

// TODO: StandardPageはパスが異なるため注意
import IfProxy from './../IfProxy';
import HeadFooter from './HeadFooter';
import SnsUtil from './../common/SnsUtil';
import AccountCard from './../common/AccountCard';


// Material UI
import { makeStyles } from '@mui/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const StandardPage = (props) => {

  const [listItems, setListItems] = useState(0);

  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const ifProxy = new IfProxy();

  const location = useLocation();

  useEffect((props) => {
    loadData(props);
  }, []);


  const loadData = (props) => {


    // データコール
    //ifProxy.getDetailData().then((result) => {

    //  setListItems(result);
    //})
  }



  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else {

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox} >
            <Grid container justify="center" spacing={3} sx={{ marginTop: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>
              <Grid item xs={12} sm={12} md={8} >
                <Card className={classes.card}>
                  <CardContent>
                    <h1>{listItems.title}</h1>
                    <CardMedia
                      component="img"
                      image={`${process.env.PUBLIC_URL}/logo512.png`}
                      alt="green iguana"
                    />
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      データ番号:{listItems.id}
                    </Typography>
                    <Typography variant="h5" component="div">
                      {listItems.createUser}
                    </Typography>
                    <Typography variant="body2">
                      テキスト内容 :{listItems.text}
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4} >

                <AccountCard
                  id="test"
                  displayName="test"
                  uid="test"
                  thumbnail={`${process.env.PUBLIC_URL}/logo512.png`}
                  text="test"
                  status="募集中"
                ></AccountCard>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {/** フォローボタン */}
                  <SnsUtil toUser={{ id: "sample" }}></SnsUtil>
                  <Button
                    sx={{
                      margin: 1
                    }}
                    variant="contained"
                    color="secondary">
                    リクエストを申し込む
                  </Button>
                </Box>



              </Grid>
            </Grid>

          </Box>
        </Box>

      </HeadFooter >

    );
  }




}
export default StandardPage;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト　中心揃えにする
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  // コンテンツの幅。端末ごとに広がりすぎるのを防ぐ。左揃え
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",
  },
  // 個別コンテンツ　中央揃えで縦に内容を並べる
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  // 個別コンテンツ　横並びに内容を並べる
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },
  cardList: {
  },
  card: {
  },
}));
