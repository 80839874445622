import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';

// TODO: StandardPageはパスが異なるため注意
import IfProxy from '../../IfProxy';
import HeadFooter from '../HeadFooter';
import SnsUtil from '../../common/SnsUtil';
import AccountCard from '../../common/AccountCard';
import ProductCard from '../../common/ProductCard';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const Request = (props) => {

  const [requestdata, setRequestData] = useState({});
  const [id, setId] = useState(0);
  const [agree, setAgree] = useState(0);
  const [share, setShare] = useState(0);
  const [status, setStatus] = useState("unentrusted");

  const [requestId, setRequestId] = useState({});
  const [wantSee, setWantSee] = useState({});
  const [wantSheare, setWantSheare] = useState({});


  const [completedProduct, setConpletedProduct] = useState(); //納品後だけあたいが入る
  const [loading, setLoading] = useState(true);


  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const ifProxy = new IfProxy();

  const location = useLocation();

  useEffect((props) => {
    loadData(props);

    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    // ------

    setLoading(true)

  }, []);


  const loadData = (props) => {
    ifProxy.getWantCount(params.id).then(({ requestId, wantSee, wantSheare }) => {
      setRequestId(requestId);
      setWantSee(wantSee);
      setWantSheare(wantSheare)
    })

    // データコール
    console.log(params.id)
    ifProxy.getRequestByid(params.id).then((requestData) => {

      /*
      id	id
      テーマ	thema
      途中でも受容	tochu
      報酬	amount
      シェア報酬フラグ	shareAmountFlag シェア報酬フラグがついている場合は料金ゼロ
      シェア報酬内容	shareAmount
      ワンドロ名タグ	onedroTag
      タグ	tag
      ワンドロ名タグ文字列	onedroTagString
      タグ文字列	tagsString
      クローズリクエスト	anonimous
      回答期日	anserLimit
      納品期日	dereveryLimit
      Type	status
      リクエスト賛同数	agree
      シェア賛同数	share
      fromUid	fromUid
      fromName	fromName
      fromIcon fromIcon
      toUid	toUid
      toName	toName
      toIcon toIcon
      createdAt	createdAt
      updatedAt	updatedAt
      */
      setRequestData(requestData);
      // シェアカウントと共感カウントは画面で状態を持つので別で
      // 　表示だけじゃなくて画面変更（状態）を持つので別途stateが必要
      setId(requestData.id);
      setAgree(requestData.agree);
      setShare(requestData.share);
      setStatus(requestData.status)
      console.log(">>>>>>>>>>>>>>>")
      console.log(requestData.id)
      if (requestData.status === "completed" || requestData.status === "paied") {
        console.log("<<<<<<<<<<")
        ifProxy.getProductByRequestId(requestData.id).then((result) => {
          console.log("<<<<<< result<<<<")

          console.log(result)
          console.log(result[0])
          setConpletedProduct(result[0])
          setLoading(false);
        }
        )
      } else {
        setLoading(false);
      }

    })
  }

  /**
   * ステータス変更時に行うアクション
   * @param {*} toStatus 
   */
  const statusUpdateAction = async (toStatus) => {
    const fromRequestUser = { //依頼者
      uid: requestdata.fromUid,
      displayName: requestdata.fromName,
      icon: requestdata.fromIcon,
    }
    const toRequestUser = { //クリエイター
      uid: requestdata.toUid,
      displayName: requestdata.toName,
      icon: requestdata.toIcon,
    }
    switch (toStatus) {
      // これからなるステータスなので、受諾かリクエストキャンセル以降
      case "entrusted":
        //entrusted 受諾する
        await ifProxy.entrusted(id)
        ifProxy.updateRequestAmount(toRequestUser.uid)
        ifProxy.statusUpdateNotify(fromRequestUser, "jyudaku", id, "リクエストが承認されました") //通知
        window.location.reload()
        break;

      case "completed":
        //completed 完了・納品する
        await ifProxy.completed(id)
        ifProxy.updateCompletetAmount(toRequestUser.uid)
        navigate('/CreateProduct/' + id); // productを作成する元となったリクエストのidを渡す

        break;

      case "requestCanceled":
        // キャンセル　依頼者キャンセルする
        await ifProxy.requestCanceled(id);
        ifProxy.statusUpdateNotify(toRequestUser, "cancel", id, "リクエストがキャンセルされました") //通知

        window.location.reload()

        break;

      case "creatorCanceled":
        // キャンセル　作家判断キャンセルする
        await ifProxy.creatorCanceled(id)
        ifProxy.statusUpdateNotify(fromRequestUser, "creatorCancel", id, "リクエストがクリエイターによりキャンセルされました") //通知
        window.location.reload()
        break;

      default:
        break;
    }
  }

  // アクセス制御ありコンポーネント
  // 依頼者だけが見れるコンポーネント
  const requestCancelView = () => {
    if (user.uid === requestdata.fromUid) {
      return (
        <>
          <Button
            sx={{
              margin: 1
            }}
            fullWidth
            onClick={() => statusUpdateAction("requestCanceled")}
            variant="contained"
            color="secondary">
            リクエストをキャンセルする
          </Button>
        </>
      )
    }

  }

  // 受諾者だけが見れるコンポーネント
  //entrusted 受諾に遷移
  const entrusting = () => {
    if (user.uid === requestdata.toUid) {
      return (
        <>
          <Button
            sx={{
              margin: 1
            }}
            fullWidth
            onClick={() => {
              statusUpdateAction("entrusted")
            }}
            variant="contained"
            color="secondary">
            承認する
          </Button>
        </>
      )
    }
  }

  // complete 完了にステータス変更。納品する
  const completing = () => {
    if (user.uid === requestdata.toUid) {
      return (
        <>
          <Button
            sx={{
              margin: 1
            }}
            fullWidth
            onClick={() => statusUpdateAction("completed")}

            variant="contained"
            color="secondary">
            納品する
          </Button>
        </>
      )
    }
  }

  // creatorCanceled 作家判断キャンセル
  const creatorCanceling = () => {
    if (user.uid === requestdata.toUid) {
      return (
        <>
          <Button
            sx={{
              margin: 1
            }}
            fullWidth
            onClick={() => statusUpdateAction("creatorCanceled")}

            variant="contained"
            color="secondary">
            作家判断キャンセル
          </Button>
          <Typography variant="body2">
            「作家判断キャンセルとは」<br></br>
            ワンドロに失敗し、１時間で完了できなかった場合に、依頼をキャンセルし報酬を辞退する<br></br>
            ※ 依頼者に未払いペナルティがつく心配はございません<br></br>
            ※ 依頼者がもし「ワンドロに失敗した場合の許容」で失敗を許容している場合は、途中でも納品したほうが喜ばれる可能性があります<br></br>
            ※ 途中の状態で作家判断キャンセルではなく納品された場合、依頼者が支払いを行わなかった場合未払いペナルティがカウントされます<br></br>

          </Typography>

        </>
      )
    }
  }

  // 依頼者と受諾者だけが見れるコンポーネント
  const offerView = () => {
    if (user.uid === requestdata.toUid || user.uid === requestdata.fromUid) {
      return (
        <>
          <h2>オファー金額</h2>
          <Typography variant="body2">
            {requestdata.amount}
          </Typography>
          {shareAmountView(requestdata)}
          <Typography variant="body2">
            <h2>非コミッション報酬実施内容</h2>
            {requestdata.resultAmountText}
          </Typography>


        </>

      )
    }

  }
  // 誰でも見れるコンポーネント
  const statusView = () => {
    /*
    unentrusted 未受諾
    entrusted 受諾
    completed 完了
    requestCanceled キャンセル
    creatorCanceled 作家判断キャセル
    */

    return (
      <>
        <h2>ステータス</h2>
        <h1>
          {status === "unentrusted" && "リクエスト中"}
          {status === "entrusted" && "承認済み"}
          {status === "requestCanceled" && "依頼者キャンセル"}
          {status === "creatorCanceled" && "作家キャンセル"}
          {status === "completed" && "完了"}
        </h1>
      </>
    )
  }


  // ステータスごとの表示制御
  const actionByStatus = () => {

    let conponentView = "";

    // ステータスごとに出すボタンアクションを入れる
    switch (status) {
      //unentrusted リクエスト中
      case "unentrusted":
        conponentView = (<>
          <h3>回答期日	{requestdata && requestdata.anserLimitDate}</h3>

          {entrusting() /* 受諾できる人だけが見える受諾者ボタン */}
          {requestCancelView() /* リクエストした人が受諾前までだけできるキャンセルボタン */}
        </>)
        break;

      case "entrusted":
        //entrusted 受諾
        conponentView = (
          <>
            <h3>納品期日	{requestdata && requestdata.dereveryLimitDate}</h3>
            {completing() /* 受諾できる人だけが見える受諾者ボタン */}
            {creatorCanceling() /* 作家判断キャンセルキャンセルボタン */}
          </>
        )
        break;

      case "completed":
        //completed 完了
        conponentView = (
          <>
            <h2>完了：納品済み 決済待ち</h2>
          </>
        )
        break;
      case "paied":
        //completed 完了
        conponentView = (
          <>
            <h2>完了：支払い完了</h2>
          </>
        )
        break;

      case "requestCanceled":
        // キャンセル　依頼者キャンセルに遷移
        conponentView = (
          <>
            <h2>リクエストをキャンセルしました</h2>
          </>
        )
        break;

      case "creatorCanceled":
        // キャンセル　作家判断キャンセルに遷移
        conponentView = (
          <>
            <h2>クリエイター判断で「作家判断」キャンセルされました</h2>
            <Typography variant="body2">
              「作家判断キャンセルとは」<br></br>
              ワンドロに失敗し、１時間で完了できなかった場合などに、依頼をキャンセルし報酬を辞退することです<br></br>
              ※ 依頼者がもし「ワンドロに失敗した場合の許容」で途中でもその時点でも納品して欲しい意思を伝えることは可能ですが、最終的な判断はクリエイターの意思によります
              ※ 依頼者に未払いペナルティがつく心配はございません<br></br>

            </Typography>
          </>
        )
        break;

      default:
        break;

    }
    return conponentView;
  }


  const shareAmountView = (requestdata) => {
    if (!requestdata.shareAmountFlag) return;
    return (<>
      <h2>非コミッションオファー</h2>
      <Typography variant="body2">
        {requestdata.shareAmount}
      </Typography>
    </>)
  }

  const productCardView = () => {
    return (<>
      <Grid item key={completedProduct.id}
        onClick={() => navigate('/Product/' + completedProduct.id)}
      >
        <img src={completedProduct.thumbnail} style={{
          borderRadius: 10,

        }} alt="イラスト" />

      </Grid>
    </>
    )
  }



  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    // ログインしていない場合の表示
    if (loading) {
      return (
        <HeadFooter>
          <Box className={classes.contentWrapper}>
            <Box className={classes.mainBox}>
              <p>読み込み中...</p>
            </Box>
          </Box>
        </HeadFooter>
      )
    } else {
      const twitterShareUrl = "http://twitter.com/share?url=" + window.location.origin + '/Request/' + params.id + "&text=気になったリクエストをシェア&hashtags=ワンフロ"

      return (
        <HeadFooter>
          <Box className={classes.contentWrapper}>

            <Box className={classes.mainBox} >
              <Box className={classes.centerBox} >
                <Avatar sx={{ width: 100, height: 100 }} src={requestdata.fromIcon} />

              </Box>

              <h1>{requestdata.fromName}</h1>
              <h2>ワンドロテーマリクエスト内容</h2>
              <Typography variant="body2">
                {requestdata.thema}
              </Typography>

              <h2>宛先</h2>
              <Typography variant="body2">
                {requestdata.toName}
              </Typography>

              <h2>ワンドロに失敗した場合の許容</h2>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {requestdata.tochu ? "できたところまでで納品して欲しい" : "途中の場合は報酬は出さないため納品不要"}
              </Typography>
              <h2>リアクションを送る</h2>
              <Box className={classes.leftBox}>
                <Button
                  disabled={true}
                  sx={{
                    width: "60%",
                    margin: 1
                  }}
                  variant="outlined"
                  color="primary">
                  私も見たい！(ログインすると作者に賛同を示せます)
                </Button>
                <h1>{agree}</h1>
              </Box>
              <Typography variant="body2">
                ボタンを押して共感をクリエイターに伝えます<br></br>
                (賛同者は匿名です)
              </Typography>
              <Box className={classes.leftBox}>
                <Button
                  disabled={true}
                  sx={{
                    width: "60%",
                    margin: 1
                  }}
                  variant="outlined"
                  color="secondary">
                  もし描いたらシェアする(ログインすると作者に賛同を示せます)
                </Button>
                <h1>{share}</h1>

              </Box>

              <Typography variant="body2">
                作品をリツイートするので描いて欲しい<br></br>
                (完成したらサイトからの通知でお知らせします)
              </Typography>

              <Box className={classes.centerBox} >
                <Button
                  fullwidth
                  sx={{
                    width: "60%",
                    margin: 1
                  }}
                  onClick={() => {
                    window.open(twitterShareUrl)
                  }
                  }
                  variant="outlined"
                  color="primary">
                  シェアする <img width="50" src={`${process.env.PUBLIC_URL}/twitterIcon.png`} alt="twitterLogo"></img>
                </Button>
              </Box>
              {/** フォローボタン 依頼者をフォローする*/}
              <Card className={classes.card}>
                <CardContent>
                  <Box className={classes.leftBox}>

                    <Avatar sx={{ width: 100, height: 100 }} src={requestdata.fromIcon} onClick={() => navigate('/User/' + requestdata.fromUid)} />
                    <Box >
                      依頼者：{requestdata.fromName}
                    </Box>
                  </Box>


                </CardContent>
              </Card>


            </Box>
          </Box>

        </HeadFooter >)

    }
  } else if (loading) {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
          </Box>
        </Box>
      </HeadFooter>
    )
  } else {

    const twitterShareUrl = "http://twitter.com/share?url=" + window.location.origin + '/Request/' + params.id + "&text=気になったリクエストをシェア&hashtags=ワンフロ"

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>

          <Box className={classes.mainBox} >
            <Box className={classes.centerBox} >
              <Avatar sx={{ width: 100, height: 100 }} src={requestdata.fromIcon} />

            </Box>

            <h1>{requestdata.fromName}</h1>
            <h2>ワンドロテーマリクエスト内容</h2>
            <Typography variant="body2">
              {requestdata.thema}
            </Typography>

            <h2>宛先</h2>
            <Typography variant="body2">
              {requestdata.toName}
            </Typography>


            {offerView()}
            {console.log(requestdata)}

            <h2>ワンドロに失敗した場合の許容</h2>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {requestdata.tochu ? "できたところまでで納品して欲しい" : "途中の場合は報酬は出さないため納品不要"}
            </Typography>
            <h2>リアクションを送る</h2>
            <Box className={classes.leftBox}>
              <Button
                disabled={wantSee}
                sx={{
                  width: "60%",
                  margin: 1
                }}
                onClick={() => {
                  ifProxy.updateAgreeCount(params.id)
                  setAgree(pre => pre + 1)
                  ifProxy.updateWantCount(params.id, true, wantSheare)
                  setWantSee(pre => true)

                }
                }
                variant="outlined"
                color="primary">
                私も見たい！
              </Button>
              <h1>{agree}</h1>
            </Box>
            <Typography variant="body2">
              ボタンを押して共感をクリエイターに伝えます<br></br>
              (賛同者は匿名です)
            </Typography>
            <Box className={classes.leftBox}>
              <Button
                disabled={wantSheare}
                sx={{
                  width: "60%",
                  margin: 1
                }}
                onClick={() => {
                  ifProxy.updateShareCount(params.id, user.uid, window.location.origin + "/Request/" + params.id)
                  setShare(pre => pre + 1)
                  setWantSheare(pre => true)
                  ifProxy.updateWantCount(params.id, wantSee, true)
                }
                }
                variant="outlined"
                color="secondary">
                もし描いたらシェアする
              </Button>
              <h1>{share}</h1>

            </Box>
            <Typography variant="body2">
              作品をリツイートするので描いて欲しい<br></br>
              (完成したらサイトからの通知でお知らせします)
            </Typography>
            <Box className={classes.centerBox} >
              {statusView() /* 誰でも見れる。ステータスの表示 */}

              {actionByStatus()}
              {completedProduct && productCardView()}
              {console.log("~~~~~~~~~~~~~")}
              {console.log(completedProduct)}
              {console.log("~~~~~~~~~~~~~")}


            </Box>
            <Box className={classes.centerBox} >
              <Button
                fullwidth
                sx={{
                  width: "60%",
                  margin: 1
                }}
                onClick={() => {
                  window.open(twitterShareUrl)
                }
                }
                variant="outlined"
                color="primary">
                シェアする <img width="50" src={`${process.env.PUBLIC_URL}/twitterIcon.png`} alt="twitterLogo"></img>
              </Button>
            </Box>
            {/** フォローボタン 依頼者をフォローする*/}
            <Card className={classes.card}>
              <CardContent>
                <Box className={classes.leftBox}>

                  <Avatar sx={{ width: 100, height: 100 }} src={requestdata.fromIcon} onClick={() => navigate('/User/' + requestdata.fromUid)} />
                  <Box >
                    依頼者：{requestdata.fromName}
                    {requestdata.fromUid && <SnsUtil toUser={requestdata.fromUid}></SnsUtil>}
                  </Box>
                </Box>


              </CardContent>
            </Card>


          </Box>
        </Box>

      </HeadFooter >

    );
  }




}
export default Request;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",
  },
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },
  cardList: {
  },
  card: {
  },
}));
