import { Box } from "@mui/material";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, Navigate } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';


// グラフコンポーネント
import { VictoryLine, VictoryPie, VictoryChart, VictoryTheme } from 'victory';


/**
 * ユーザ一覧などで表示するアカウントの情報をまとめたカード
 * @property { Date } startTime　開始時刻をもらう
 * @property { Date } nowTime　最新時刻をもらう
 * @property { int } maxtime 合計時間(分) (option)

 * @returns 
 * 
 * <要件>
 * 
 * 
 */
const StopWatch = (props) => {

    const navigate = useNavigate();
    const classes = useStyles();

    /**
     * ミリ秒をフォーマットする
     * @param { mstime :string} ミリ秒
     * @return {HH: number, MM: number, SS:number} 差分の　時、分、秒
     */
    const getMMdd = (mstime) => {

        // MMHHSSを作るなら、MMはHHを６０で割った余りになるため％でMODする
        const HH = Math.floor(mstime / (1000 * 60 * 60));
        const MM = Math.floor((mstime % (1000 * 60 * 60)) / (1000 * 60));
        const SS = Math.floor((mstime % (1000 * 60)) / 1000);
        return { HH, MM, SS };
    }

    /**
     * ミリ秒の差を返す。グラフ等での処理に利用
     * @param {Date} 現在時刻
     * @param {Date} 比較対象時刻
     * @return {number} 差のミリ秒
     */
    const getDiffTime = (genzai, kaishi) => {
        if (!(genzai instanceof Date)) {
            throw new Error("データ方が違います genzai : " + genzai)
        }
        if (!(kaishi instanceof Date)) {
            throw new Error("データ方が違います kaishi : " + kaishi)
        }
        const gendaiTime = genzai.getTime();
        const kaishiTime = kaishi.getTime();
        return gendaiTime - kaishiTime
    }


    // 残り時間計算関数 ms
    let getLastTimeMs = (startDate, nowDate) => {
        //console.log("~~~~~~~~~~~~~~~~^")
        //console.log(startDate)
        //console.log(nowDate)

        // １時間
        let howerMs = 1000 * 60 * 60;
        console.log(props.maxTime)
        // 合計時間指定あり
        if (props.maxTime) {
            console.log("In")


            howerMs = 1000 * 60 * (props.maxTime - 0)
            console.log(howerMs)
        }


        const spendMs = getSpendTimeMs(startDate, nowDate);
        const result = howerMs - spendMs
        return result
    }

    // 経過時間計算関数 ms
    let getSpendTimeMs = (startDate, nowDate) => {
        return getDiffTime(nowDate, startDate)
    }

    // 残り時間計算関数 HH:MM:SS
    let getLastTime = (startDate, nowDate) => {
        const msTime = getLastTimeMs(startDate, nowDate)
        const resultTime = getMMdd(msTime);
        // `残り ${HH}時間 ${MM}分 ${SS}秒`
        return resultTime
    }

    // 経過時間計算関数 HH:MM:SS
    let getSpendTime = (startDate, nowDate) => {
        const msTime = getSpendTimeMs(startDate, nowDate)
        const resultTime = getMMdd(msTime)
        return resultTime
    }

    // グラフラベル用表示 残り時間
    let getLastTimeForLabel = (startDate, nowDate) => {
        const { HH, MM, SS } = getLastTime(startDate, nowDate)
        return `あと ${MM}分 ${SS}秒`
    }
    // グラフラベル用表示 経過時間
    // グラフラベル用表示 残り時間
    let getSpendTimeForLabel = (startDate, nowDate) => {
        const { HH, MM, SS } = getSpendTime(startDate, nowDate)
        return `${MM}分 ${SS}秒 経過`
    }

    // ラベル用表示 残り時間
    // グラフラベル用表示 残り時間
    let getLastTimeForFullLabel = (startDate, nowDate) => {
        const msLastTime = getLastTimeMs(startDate, nowDate);
        const { HH, MM, SS } = getLastTime(startDate, nowDate)
        if (msLastTime < 0) {
            // 超過した場合
            return `超過時間 ${HH}時間 ${MM}分 ${SS}秒 残り `

        } else {
            return `${HH}時間 ${MM}分 ${SS}秒 `
        }
    }
    // グラフラベル用表示 経過時間
    // グラフラベル用表示 残り時間
    let getSpendTimeFullForLabel = (startDate, nowDate) => {
        const { HH, MM, SS } = getSpendTime(startDate, nowDate)
        return `${HH}時間 ${MM}分 ${SS}秒 経過`
    }

    // ラベル用表示 経過時間

    // データ作成
    /**
     * 引数のデータ構造
     * data={[
                { x: "サッカー", y: 200 },
                { x: "野球", y: 100 },
                { x: "テニス", y: 100 },
                { x: "テニス", y: 100 }
            ]}
     */
    /*
    データ仕様
    ２系列
    単位: ミリ秒
    残り時間系列 60分-経過時間　をmsで計算
    経過時間 現在時刻-開始時刻　をmsで計算

    */
    const graphData = [
        { x: getSpendTimeForLabel(props.startTime, props.nowTime), y: getSpendTimeMs(props.startTime, props.nowTime) },
        {
            x: getLastTimeForLabel(props.startTime, props.nowTime), y: getLastTimeMs(props.startTime, props.nowTime)
        },
    ]




    return (
        <>
            {/**
            <Box className={classes.leftCenterBox}>
            </Box>
    */}
            <Box className={classes.all}>
                <Box>
                    経過時間
                    <br></br>
                    {getSpendTimeFullForLabel(props.startTime, props.nowTime)}<br></br><br></br>
                    残り時間
                    <br></br>
                    {getLastTimeForFullLabel(props.startTime, props.nowTime)}
                </Box>
                <VictoryPie
                    width={500} height={250} padding={35}
                    colorScale={["#65B2FF", "#65D8FF"]}
                    data={graphData}
                />
            </Box>

        </>
    )
}


export default StopWatch;


const useStyles = makeStyles((theme) => ({

    all: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    timerBox: {

    },



}));
