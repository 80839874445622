import React, { useState, useEffect, useLayoutEffect, forwardRef, useRef, useCallback } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import IfProxy from '../../IfProxy';
import IfProxyTool from '../../IfProxyTool';


import HeadFooter from '../HeadFooter';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

//graph
import StopWatch from "../../common/graphs/StopWatch";
import { Scale } from "victory";

import DisplayAd from "../../common/adsence/DisplayAd"


// ショートかっと
import hotkeys from 'hotkeys-js';




/**
 * ユーザ参照
 * @param  
 * @returns 
 */
const OnedroTimer = (props) => {
  const [loading, setLoading] = useState(true);
  // クリックされたイベントの情報をモーダルに渡す
  const [clickedEventData, setClickedEventData] = useState("");
  const [modalShow, setModalShow] = useState(false);


  // RecordConfig
  const [recordConfig, setRecordConfig] = useState(false);

  // 現在時刻
  const [nowTimeHHMMSS, setNowTimeHHMMSS] = useState({ HH: 0, MM: 0, DD: 0 });


  // 実施日時をもつ
  const [startDatetime, setStartDatetime] = useState("");
  const [end1Datetime, setEnd1Datetime] = useState("");
  const [end2Datetime, setEnd2Datetime] = useState("");
  const [end3Datetime, setEnd3Datetime] = useState("");
  const [end4Datetime, setEnd4Datetime] = useState("");
  const [end5Datetime, setEnd5Datetime] = useState("");
  const [end6Datetime, setEnd6Datetime] = useState("");
  const [end7Datetime, setEnd7Datetime] = useState("");
  const [end8Datetime, setEnd8Datetime] = useState("");
  const [end9Datetime, setEnd9Datetime] = useState("");
  const [end10Datetime, setEnd10Datetime] = useState("");
  const [endEstimatedDatetime, setEndEstimatedDatetime] = useState("");


  // 所要時間をもつ
  const [end1Raptime, setEnd1Raptime] = useState("");// end1からstartを引いた時間
  const [end2Raptime, setEnd2Raptime] = useState("");// end2からend1を引いた時間
  const [end3Raptime, setEnd3Raptime] = useState("");// end3からend2を引いた時間
  const [end4Raptime, setEnd4Raptime] = useState("");// end4からend3を引いた時間
  const [end5Raptime, setEnd5Raptime] = useState("");// end4からend3を引いた時間
  const [end6Raptime, setEnd6Raptime] = useState("");// end4からend3を引いた時間
  const [end7Raptime, setEnd7Raptime] = useState("");// end4からend3を引いた時間
  const [end8Raptime, setEnd8Raptime] = useState("");// end4からend3を引いた時間
  const [end9Raptime, setEnd9Raptime] = useState("");// end4からend3を引いた時間
  const [end10Raptime, setEnd10Raptime] = useState("");// end4からend3を引いた時間

  const [finishedRaptime, setFinishedRaptime] = useState("");// 1~10の合計時間

  // 戻って修正した回数
  const [end1Fixcount, setEnd1Fixcount] = useState(0);
  const [end2Fixcount, setEnd2Fixcount] = useState(0);
  const [end3Fixcount, setEnd3Fixcount] = useState(0);
  const [end4Fixcount, setEnd4Fixcount] = useState(0);
  const [end5Fixcount, setEnd5Fixcount] = useState(0);
  const [end6Fixcount, setEnd6Fixcount] = useState(0);
  const [end7Fixcount, setEnd7Fixcount] = useState(0);
  const [end8Fixcount, setEnd8Fixcount] = useState(0);
  const [end9Fixcount, setEnd9Fixcount] = useState(0);
  const [end10Fixcount, setEnd10Fixcount] = useState(0);


  // Config系の値

  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();

  // 目標通過時間
  // 入力項目。
  const [targetStep1End, setTargetStep1End] = useState(0);//
  const [targetStep2End, setTargetStep2End] = useState(0);
  const [targetStep3End, setTargetStep3End] = useState(0);
  const [targetStep4End, setTargetStep4End] = useState(0);
  const [targetStep5End, setTargetStep5End] = useState(0);
  const [targetStep6End, setTargetStep6End] = useState(0);
  const [targetStep7End, setTargetStep7End] = useState(0);
  const [targetStep8End, setTargetStep8End] = useState(0);
  const [targetStep9End, setTargetStep9End] = useState(0);
  const [targetStep10End, setTargetStep10End] = useState(0);



  // 完了ボタンを押した時に、(目標秒 - (stepの完了目標 - スタート) ) を設定
  const [targetStep1EndResult, setTargetStep1EndResult] = useState(0);// -nn
  const [targetStep2EndResult, setTargetStep2EndResult] = useState(0);
  const [targetStep3EndResult, setTargetStep3EndResult] = useState(0);
  const [targetStep4EndResult, setTargetStep4EndResult] = useState(0);
  const [targetStep5EndResult, setTargetStep5EndResult] = useState(0);
  const [targetStep6EndResult, setTargetStep6EndResult] = useState(0);
  const [targetStep7EndResult, setTargetStep7EndResult] = useState(0);
  const [targetStep8EndResult, setTargetStep8EndResult] = useState(0);
  const [targetStep9EndResult, setTargetStep9EndResult] = useState(0);
  const [targetStep10EndResult, setTargetStep10EndResult] = useState(0);


  const soundFile = new Audio(`${process.env.PUBLIC_URL}/timerAlerm.mp3`);

  const ifProxy = new IfProxy();
  const ifProxyTool = new IfProxyTool();



  const [checked, setChecked] = useState("F");

  // ショートカット
  const shortCat = () => {
    hotkeys('5', function (event, handler) {
      console.log("shortCat======")
      event.preventDefault()
      alert('you pressed F5!')
    });
  }

  const unregisterrHotkeys = () => {
    hotkeys.unbind('5');
  }

  let first = true;

  useLayoutEffect((props) => {

    shortCat();

    // 【useEffectの２重処理について】
    // https://zenn.dev/takeharu/scraps/d14cf9d4239ec4
    // useEffectは２回描画される
    // mount -> unmount -> mount
    // 処理がこのようにダブルのは開発環境だけ
    if (first) {
      setLoading(true)
      // ReactGA Analytics ---
      ReactGA.initialize("G-2ZCXE08H2S");
      ReactGA.send({
        hitType: "pageview",
        // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
        page: location.pathname + location.search,
      });
      ReactGA.event("ワンドロタイマーを利用した");
      // ------
      loadData();
    }

    //    console.log(first ? "１回目である" : "２回目である")
    //    console.log("回数評価処理は以下")
    //    console.log("開発環境２重処理防止のため、１回目、２回目判定フラグ")
    //    console.log(first)
    first = false;
    //    console.log("２重登録判定フラグ、更新後")
    //    console.log(first)

    return () => {
      //      console.log("UNMOUNT 開発環境２重処理防止のため、１回目、２回目判定フラグ")
      //      console.log(first)
      unregisterrHotkeys()
    }

  }, []);

  const loadData = async () => {
    let recordConfig = []
    if (user) {
      recordConfig = await ifProxyTool.getRecordConfig(user.uid);
    }

    console.log("RECORD===CONFIG")
    console.log(recordConfig)


    if (recordConfig.length > 0) {
      setRecordConfig(recordConfig[0]);

      // 累積目標値を設定
      const confData = recordConfig[0];
      let sumTime = 0

      if (confData.step1Time) {
        sumTime += (confData.step1Time - 0)
        console.log("========^^^^^^^^^")
        console.log(confData.step1Time)
        console.log(sumTime)

        setTargetStep1End(sumTime)
      }
      if (confData.step2Time) {
        sumTime += (confData.step2Time - 0)
        console.log("========^^^^^^^^^")
        console.log(confData.step2Time)
        console.log(sumTime)

        setTargetStep2End(sumTime)
      }
      if (confData.step3Time) {
        sumTime += (confData.step3Time - 0)
        setTargetStep3End(sumTime)
      }
      if (confData.step4Time) {
        sumTime += (confData.step4Time - 0)
        setTargetStep4End(sumTime)
      }
      if (confData.step5Time) {
        sumTime += (confData.step5Time - 0)
        setTargetStep5End(sumTime)
      }
      if (confData.step6Time) {
        sumTime += (confData.step6Time - 0)
        setTargetStep6End(sumTime)
      }
      if (confData.step7Time) {
        sumTime += (confData.step7Time - 0)
        setTargetStep7End(sumTime)
      }
      if (confData.step8Time) {
        sumTime += (confData.step8Time - 0)
        setTargetStep8End(sumTime)
      }
      if (confData.step9Time) {
        sumTime += (confData.step9Time - 0)
        setTargetStep9End(sumTime)
      }
      if (confData.step10Time) {
        sumTime += (confData.step10Time - 0)
        setTargetStep10End(sumTime)
      }

    } else {

      setRecordConfig({
        id: "",
        uid: user?.uid,
        step1Name: "ラフ",
        step2Name: "ペン入れ",
        step3Name: "塗り",
        step4Name: "仕上げ",
        step5Name: "",
        step6Name: "",
        step7Name: "",
        step8Name: "",
        step9Name: "",
        step10Name: "",
        step1Time: 10,
        step2Time: 15,
        step3Time: 30,
        step4Time: 5,
        step5Time: null,
        step6Time: null,
        step7Time: null,
        step8Time: null,
        step9Time: null,
        step10Time: null,
        memoForNext: null,
        memo: "",
      });

    }
    setLoading(false)
  }



  // タイマー系処理
  // なぜ動くのか理解できない >>>>>>>>>>
  // https://rios-studio.com/tech/react-hook%E3%81%AB%E3%81%8A%E3%81%91%E3%82%8Btimeout%E3%81%A8timeinterval%E3%80%90%E6%AD%A2%E3%81%BE%E3%82%89%E3%81%AA%E3%81%84%E3%83%BB%E9%87%8D%E8%A4%87%E3%81%99%E3%82%8B%E3%80%91#toc3
  const [count, setCount] = useState(0); //経過秒
  const intervalRef = useRef(null);

  //const start = useCallback(() => {
  const start = () => {
    if (intervalRef.current !== null) {
      return;
    }
    // タイマースタート
    intervalRef.current = setInterval(() => {
      const nowDateObject = new Date();
      setNowTimeHHMMSS(c => {
        return setHHMMDDFormatFromDate(nowDateObject)
      })
      setCount(c => {
        alertTime(c)
        return c + 1
      });
    }, 1000);
  };
  const stop = useCallback(() => {
    if (intervalRef.current === null) {
      return;
    }
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  }, []);
  // <<<<<<<<<<<<<<<<<<<<<< ここまで


  // タイマーアラームチェック実行
  const alertTime = (count) => {

    //タイマーを使っていない場合
    if (targetStep1End < 1) return;

    if (count === targetStep1End * 60) {
      console.log("TIMEUP==============")
      soundFile.loop = false;
      soundFile.play();

    }
    if (count === targetStep2End * 60) {
      console.log("TIMEUP==============")
      soundFile.loop = false;
      soundFile.play();
    }
    if (count === targetStep3End * 60) {
      console.log("TIMEUP==============")
      soundFile.loop = false;
      soundFile.play();
    }
    if (count === targetStep4End * 60) {
      console.log("TIMEUP==============")
      soundFile.loop = false;
      soundFile.play();
    }
    if (count === targetStep5End * 60) {
      console.log("TIMEUP==============")
      soundFile.loop = false;
      soundFile.play();
    }
    if (count === targetStep6End * 60) {
      console.log("TIMEUP==============")
      soundFile.loop = false;
      soundFile.play();
    }
    if (count === targetStep7End * 60) {
      console.log("TIMEUP==============")
      soundFile.loop = false;
      soundFile.play();
    }
    if (count === targetStep8End * 60) {
      console.log("TIMEUP==============")
      soundFile.loop = false;
      soundFile.play();
    }
    if (count === targetStep9End * 60) {
      console.log("TIMEUP==============")
      soundFile.loop = false;
      soundFile.play();
    }
    if (count === targetStep10End * 60) {
      console.log("TIMEUP==============")
      soundFile.loop = false;
      soundFile.play();
    }

  }


  // StopWatchをコントロールする =-===--------------

  const StopWatchControlButtonView = () => {
    const nowTime = new Date();
    //const oneHourAgo = new Date(2023, 5, 16, 23, 30, 0);

    if (startDatetime === "") {
      return (
        <>
          {/*<Button onClick={() => start()}>START</Button>*/}
          {/*<Button onClick={() => stop()}>STOP</Button>*/}

          <StopWatch startTime={nowTime} nowTime={nowTime} />
        </>
      )
    } else {
      return (
        <>
          <StopWatch startTime={startDatetime} nowTime={nowTime} />

        </>
      )

    }

  }


  //  ========= ここまで


  // 時間制御
  // 時間の差を返す
  /**
   * @param {Date} 現在時刻
   * @param {Date} 比較対象時刻
   * @return {HH: number, MM: number, SS:number} 差分の　時、分、秒
   */
  const getDiffMMdd = (genzai, kaishi) => {
    if (!(genzai instanceof Date)) {
      throw new Error("データ方が違います genzai : " + genzai)
    }
    if (!(kaishi instanceof Date)) {
      throw new Error("データ方が違います kaishi : " + kaishi)
    }
    // console.log(genzai instanceof Date);
    // console.log("時間計算処理 getDiffMMdd")
    // console.log("現在のDate" + genzai)
    // console.log("対象のDate" + kaishi)

    // 時間差計算は、getTimeでミリ秒計算し、戻す
    // 1000で割ると秒、さらに60で割って、分、秒にする
    const gendaiTime = genzai.getTime();
    const kaishiTime = kaishi.getTime();
    const diffTime = gendaiTime - kaishiTime
    // MMHHSSを作るなら、MMはHHを６０で割った余りになるため％でMODする
    const HH = Math.floor(diffTime / (1000 * 60 * 60));
    const MM = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
    const SS = Math.floor((diffTime % (1000 * 60)) / 1000);

    return { HH, MM, SS };
  }
  /**
   * ミリ秒の差を返す。グラフ等での処理に利用
   * @param {Date} 現在時刻
   * @param {Date} 比較対象時刻
   * @return {number} 差のミリ秒
   */
  const getDiffTime = (genzai, kaishi) => {
    if (!(genzai instanceof Date)) {
      throw new Error("データ方が違います genzai : " + genzai)
    }
    if (!(kaishi instanceof Date)) {
      throw new Error("データ方が違います kaishi : " + kaishi)
    }
    const gendaiTime = genzai.getTime();
    const kaishiTime = kaishi.getTime();
    return gendaiTime - kaishiTime
  }

  /**
   * 
   * @param {String} step step1,step2,step3,step4, estimate
   * @param {*} dateNow 
   * @param {*} datePre
   */
  const setRapTime = (step, dateNow, datePre) => {
    const result = getDiffMMdd(dateNow, datePre);
    // 押した時の所用秒
    const resultSS = result.HH * 3600 + result.MM * 60 + result.SS


    switch (step) {
      case "step1":
        setEnd1Raptime(result);
        setTargetStep1EndResult(resultSS - targetStep1End * 60)
        break;
      case "step2":
        setEnd2Raptime(result);
        setTargetStep2EndResult(resultSS - targetStep2End * 60)
        break;
      case "step3":
        setEnd3Raptime(result);
        setTargetStep3EndResult(resultSS - targetStep3End * 60)
        break;
      case "step4":
        setEnd4Raptime(result);
        setTargetStep4EndResult(resultSS - targetStep4End * 60)
        break;
      case "step5":
        setEnd5Raptime(result);
        setTargetStep5EndResult(resultSS - targetStep5End * 60)
        break;
      case "step6":
        setEnd6Raptime(result);
        setTargetStep6EndResult(resultSS - targetStep6End * 60)
        break;
      case "step7":
        setEnd7Raptime(result);
        setTargetStep7EndResult(resultSS - targetStep7End * 60)
        break;
      case "step8":
        setEnd8Raptime(result);
        setTargetStep8EndResult(resultSS - targetStep8End * 60)
        break;
      case "step9":
        setEnd9Raptime(result);
        setTargetStep9EndResult(resultSS - targetStep9End * 60)
        break;
      case "step10":
        setEnd10Raptime(result);
        setTargetStep10EndResult(resultSS - targetStep10End * 60)
        break;
      case "estimate":
        setFinishedRaptime(result);
        break;
    }
  }

  // TODO
  // T nowTime{HH,MM,DD}

  /**
   * @description Step単位の残り所要秒数を取得
   * @param {{HH,MM,DD}} pre 開始時刻（前工程が終わった時刻
   * @param {{HH,MM,DD}} now 現在時刻
   * @param {int} targetMin 
   * @returns 
   */
  const getRapBufferTime = (pre, now, targetMin) => {


    const diffSec = getDifffSec(pre, now)
    const resultSec = getTargetDiffSec(diffSec, (targetMin - 0) * 60)
    //console.log(resultSec)
    //console.log(resultSec / 60)
    return resultSec
  }
  /**
   * @description ２つの時刻の差分秒数を取得
   * @param {{HH,MM,DD}} pre 開始時刻
   * @param {{HH,MM,DD}} now 現在時刻
   */
  const getDifffSec = (pre, now) => {

    let resultSecPre = (pre.HH - 0) * 3600 + (pre.MM - 0) * 60 + (pre.SS - 0)
    let resultSecNow = (now.HH - 0) * 3600 + (now.MM - 0) * 60 + (now.SS - 0)
    return resultSecNow - resultSecPre;
  }
  /**
   * @description 目標秒数 - すでに消化した秒数
   * @param {*} diffSec 差分の秒数(stepで経過した時間)
   * @param {*} targetMin 目標の所要時秒数
   * @return 目標時間の余裕、または超過秒数
   */
  const getTargetDiffSec = (diffSec, targetMin) => {
    return (targetMin - 1) - diffSec;
  }

  const setHHMMDDFormatFromDate = (date) => {
    const hh = date.getHours();
    const mm = date.getMinutes();
    const ss = date.getSeconds();
    return { HH: hh, MM: mm, SS: ss }

  }
  const createDiary = async () => {
    // TODO Contextでよくない？
    const userData = await ifProxy.getUserData(user.uid)

    const recordDataParam = {
      step0Time: startDatetime,
      step1: recordConfig.step1Name,
      step1Time: end1Datetime,
      step1FixCount: end1Fixcount,
      step2: recordConfig.step2Name,
      step2Time: end2Datetime,
      step2FixCount: end2Fixcount,
      step3: recordConfig.step3Name,
      step3Time: end3Datetime,
      step3FixCount: end3Fixcount,
      step4: recordConfig.step4Name,
      step4Time: end4Datetime,
      step4FixCount: end4Fixcount,
      step5: recordConfig.step5Name,
      step5Time: end5Datetime,
      step5FixCount: end5Fixcount,
      step6: recordConfig.step6Name,
      step6Time: end6Datetime,
      step6FixCount: end6Fixcount,
      step7: recordConfig.step7Name,
      step7Time: end7Datetime,
      step7FixCount: end7Fixcount,
      step8: recordConfig.step8Name,
      step8Time: end8Datetime,
      step8FixCount: end8Fixcount,
      step9: recordConfig.step9Name,
      step9Time: end9Datetime,
      step9FixCount: end9Fixcount,
      step10: recordConfig.step10Name,
      step10Time: end10Datetime,
      step10FixCount: end10Fixcount,
      completeTime: new Date(),
      uid: userData.uid,
    }
    const recordData = await ifProxyTool.addRecord(recordDataParam, recordDataParam);

    const diaryData = {
      toUid: userData.uid,
      toUser: userData.displayName,
      toIcon: userData.icon,
      privateView: false,
      diary: "",
      memoForNext: "",
      imageUrl: "",
      videoUrl: "",
      youtubeUrl: "",
      tag: [],
      tagString: [],
      onedroTag: [],
      onedroTagString: [],
      record: recordData,
    }
    const result = await ifProxyTool.addDiary(diaryData, diaryData);
    navigate('/Diary/' + result.id)
  }

  const updateRecordConfig = () => {
    console.log("更新する￥￥￥￥￥￥￥￥￥￥￥￥")
    console.log(recordConfig)
    ifProxyTool.addOrUpdateRecordConfig(recordConfig, recordConfig).then(() => {
      setModalShow(false)
      alert('正常に更新が完了しました。メニューから開き直してください')
      navigate('/MainList')

    })
  }

  /*
setRecordConfig({
    id: "",
    uid: user.uid,
    step1Name: "ラフ",
    step2Name: "ペン入れ",
    step3Name: "塗り",
    step4Name: "仕上げ",
    memoForNext: "",
    memo: "",
  });
*/
  const handleStep1Name = (event) => {
    setRecordConfig((prv) => ({ ...prv, step1Name: event.target.value }))
  }
  const handleStep2Name = (event) => {
    setRecordConfig((prv) => ({ ...prv, step2Name: event.target.value }))
  }
  const handleStep3Name = (event) => {
    setRecordConfig((prv) => ({ ...prv, step3Name: event.target.value }))
  }
  const handleStep4Name = (event) => {
    setRecordConfig((prv) => ({ ...prv, step4Name: event.target.value }))
  }
  const handleStep5Name = (event) => {
    setRecordConfig((prv) => ({ ...prv, step5Name: event.target.value }))
  }
  const handleStep6Name = (event) => {
    setRecordConfig((prv) => ({ ...prv, step6Name: event.target.value }))
  }
  const handleStep7Name = (event) => {
    setRecordConfig((prv) => ({ ...prv, step7Name: event.target.value }))
  }
  const handleStep8Name = (event) => {
    setRecordConfig((prv) => ({ ...prv, step8Name: event.target.value }))
  }
  const handleStep9Name = (event) => {
    setRecordConfig((prv) => ({ ...prv, step9Name: event.target.value }))
  }
  const handleStep10Name = (event) => {
    setRecordConfig((prv) => ({ ...prv, step10Name: event.target.value }))
  }
  const handlememo = (event) => {
    setRecordConfig((prv) => ({ ...prv, memo: event.target.value }))
  }

  /**
   * nullは0にする。文字は数値にする
   * @param {*} param 
   * @returns 
   */
  const psi = ((param) => {
    return param - 0
  })
  const modalView = () => {

    if (modalShow) {
      let {
        id,
        uid,
        step1Name,
        step2Name,
        step3Name,
        step4Name,
        step5Name,
        step6Name,
        step7Name,
        step8Name,
        step9Name,
        step10Name,
        step1Time,
        step2Time,
        step3Time,
        step4Time,
        step5Time,
        step6Time,
        step7Time,
        step8Time,
        step9Time,
        step10Time,
        memoForNext,
        memo,
      } = recordConfig;
      return (
        <>
          <Box className={classes.overlay}
            onClick={() => setModalShow(false)}>
            <Box className={classes.modalView} onClick={(e) => e.stopPropagation()}>
              <Box className={classes.centerBox}>

                <Typography>あなたの作画の進め方合わせて各ステップの作業名をカスタマイズできます</Typography>
                <b>合計タイム:{psi(step1Time) + psi(step2Time) + psi(step3Time) + psi(step4Time) + psi(step5Time) + psi(step6Time) + psi(step7Time) + psi(step8Time) + psi(step10Time)} 分</b>
                <ConfigInputForm stepName="Step1" classes={classes} nameHandler={handleStep1Name} stepNameValue={step1Name} timeValue={recordConfig.step1Time} placeHolder="ラフ" timeReturn={(value) => setRecordConfig((prv) => ({ ...prv, step1Time: value }))} />
                <ConfigInputForm stepName="Step2" classes={classes} nameHandler={handleStep2Name} stepNameValue={step2Name} timeValue={recordConfig.step2Time} placeHolder="ペン入れ" timeReturn={(value) => setRecordConfig((prv) => ({ ...prv, step2Time: value }))} />
                <ConfigInputForm stepName="Step3" classes={classes} nameHandler={handleStep3Name} stepNameValue={step3Name} timeValue={recordConfig.step3Time} placeHolder="マスク" timeReturn={(value) => setRecordConfig((prv) => ({ ...prv, step3Time: value }))} />
                <ConfigInputForm stepName="Step4" classes={classes} nameHandler={handleStep4Name} stepNameValue={step4Name} timeValue={recordConfig.step4Time} placeHolder="塗り" timeReturn={(value) => setRecordConfig((prv) => ({ ...prv, step4Time: value }))} />
                <ConfigInputForm stepName="Step5" classes={classes} nameHandler={handleStep5Name} stepNameValue={step5Name} timeValue={recordConfig.step5Time} placeHolder="仕上げ" timeReturn={(value) => setRecordConfig((prv) => ({ ...prv, step5Time: value }))} />
                <ConfigInputForm stepName="Step6" classes={classes} nameHandler={handleStep6Name} stepNameValue={step6Name} timeValue={recordConfig.step6Time} placeHolder="-" timeReturn={(value) => setRecordConfig((prv) => ({ ...prv, step6Time: value }))} />
                <ConfigInputForm stepName="Step7" classes={classes} nameHandler={handleStep7Name} stepNameValue={step7Name} timeValue={recordConfig.step7Time} placeHolder="-" timeReturn={(value) => setRecordConfig((prv) => ({ ...prv, step7Time: value }))} />
                <ConfigInputForm stepName="Step8" classes={classes} nameHandler={handleStep8Name} stepNameValue={step8Name} timeValue={recordConfig.step8Time} placeHolder="-" timeReturn={(value) => setRecordConfig((prv) => ({ ...prv, step8Time: value }))} />
                <ConfigInputForm stepName="Step9" classes={classes} nameHandler={handleStep9Name} stepNameValue={step9Name} timeValue={recordConfig.step9Time} placeHolder="-" timeReturn={(value) => setRecordConfig((prv) => ({ ...prv, step9Time: value }))} />
                <ConfigInputForm stepName="Step10" classes={classes} nameHandler={handleStep10Name} stepNameValue={step10Name} timeValue={recordConfig.step10Time} placeHolder="-" timeReturn={(value) => setRecordConfig((prv) => ({ ...prv, step10Time: value }))} />

                <Box className={classes.centerBox}>
                  <Typography
                    sx={{ paddingTop: 1 }}>
                    作業メモ：次回のワンドロのためにメモしておきたい設定情報等
                  </Typography>
                  <TextField margin="nomal" id="filled-basic" size="small"
                    fullWidth
                    inputProps={{ maxLength: 20 }}
                    onChange={handlememo} multiline rows={4} variant="outlined" type="string" value={memo}
                  />
                </Box>

                <Box className={classes.centerBox}>
                  <Button
                    color='warning'
                    variant="contained"
                    disabled={!user}
                    onClick={() => {
                      updateRecordConfig();

                    }}>
                    タイマー設定変更{!user ? "【要ログイン】" : ""}
                  </Button>

                </Box>


              </Box>
            </Box>
          </Box >
        </>
      )
    }

  }

  const getGridAnimationStyle = (activate) => {
    return { transition: 'all 0.3s', backgroundColor: activate ? '' : 'gray', transform: activate ? 'Scale(1, 1)' : 'Scale(0.9, 0.9)' }

  }

  if (loading) {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Box>
        </Box>
      </HeadFooter>
    )
  } else {

    const twitterShareUrl = "http://twitter.com/share?url=" + window.location.origin + '/User/' + params.id + "&text=ワンドロテーマを募集中&hashtags=ワンフロ"

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      < HeadFooter >

        {/* オーバーレイ用の背景要素 */}
        {modalView()}

        <Box className={classes.contentWrapper}>
          <Grid container justify="center" spacing={2} >


            {/** １段目 ===================================================================== */}
            {/** ストップウォッチ */}
            <Grid item xs={12} className={classes.gridPadding}>
              <Box className={classes.centerBox}>
                {StopWatchControlButtonView()}
              </Box>
            </Grid>
            {/** １段目 ===================================================================== */}

            <Grid item xs={8} className={classes.gridPaddingNoborder}>

              <Box className={classes.leftBox}>
                <b>前回からのメモと、ツール設定ノート</b>

                {recordConfig ? recordConfig.memo : "ここに、次回に流用したいツールの設定などのメモを表示することができます"}

              </Box>
              <Box className={classes.leftBox}>
                {recordConfig ? recordConfig.memoForNext : "ここに、次回に流用したいツールの設定などのメモを表示することができます"}

              </Box>
            </Grid>
            <Grid item xs={4} className={classes.gridPaddingNoborder}>
              <Button
                color='warning'
                variant="contained"
                onClick={() => {
                  setModalShow(true)
                }}>
                タイマー設定変更
              </Button>
            </Grid>

            {/** start 段目 ===================================================================== */}
            <Grid item xs={12} className={classes.gridPadding} sx={getGridAnimationStyle(!startDatetime)}>
              <Box className={classes.centerFlatBox}>
                <Box className={classes.centerFlatBox}>
                  開始時刻  {startDatetime && startDatetime.getHours() + " : " + startDatetime.getMinutes() + " : " + startDatetime.getSeconds()}
                </Box>
              </Box>
              <Button
                disabled={startDatetime}
                variant="contained"
                fullWidth
                onClick={() => {
                  if (!user) {
                    let result = window.confirm("未ログインの場合結果を記録できません。計測を行う場合はログインしてください。このまま開始しますか？")
                    if (result) {
                      setStartDatetime(new Date())
                      start()
                    }
                  } else {
                    setStartDatetime(new Date())
                    start()
                  }


                }}>
                スタート
              </Button>
            </Grid>
            {/** step１ 段目 ===================================================================== */}
            <Grid item xs={12} className={classes.gridPadding} sx={getGridAnimationStyle((!end1Datetime))}>
              <Box className={classes.centerFlatBox}>
                <b>step1: {recordConfig.step1Name}</b>
              </Box>
              <Box className={classes.centerFlatBox}>

                <Box className={classes.centerFlatBox}>
                  step1 所要時間
                  <h1>
                    {end1Raptime && end1Raptime.HH + "時" + end1Raptime.MM + "分" + end1Raptime.SS + "秒"}
                  </h1>
                </Box>
                <Box className={classes.centerFlatBox}>
                  <TargetMinuits targetTime={recordConfig.step1Time} ></TargetMinuits><br></br>
                  通過タイム:{targetStep1End}<br></br>
                </Box>
                {!end1Datetime && <Box className={classes.centerFlatBox}>
                  <h1>あと:{startDatetime ? Math.floor(getRapBufferTime(setHHMMDDFormatFromDate(startDatetime), nowTimeHHMMSS, recordConfig.step1Time) / 60) : recordConfig.step1Time} 分
                  </h1>
                </Box>}
                <Box className={classes.centerFlatBox}>
                  <Box sx={{
                    fontWeight: 'bold',
                    color: (targetStep1EndResult <= 0 ? "green" : "red")
                  }} >
                    {targetStep1EndResult} 秒
                  </Box>
                </Box>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setEnd1Fixcount(pre => pre + 1)
                  }}>
                  手戻り{end1Fixcount}

                </Button>

              </Box>
              <Button
                disabled={end1Datetime || !startDatetime}
                variant="contained"
                fullWidth
                onClick={() => {
                  setEnd1Datetime(new Date());
                  setRapTime("step1", new Date(), startDatetime)
                }}>
                Step1消化
              </Button>
            </Grid>
            {/** step2 段目 ===================================================================== */}
            <Grid item xs={12} className={classes.gridPadding} sx={getGridAnimationStyle((!end2Datetime))}>
              <Box className={classes.centerFlatBox}>
                <b>step2: {recordConfig.step2Name}</b>
              </Box>
              <Box className={classes.centerFlatBox}>

                <Box className={classes.centerFlatBox}>
                  step2 所要時間
                  <h1>
                    {end2Raptime && end2Raptime.HH + "時" + end2Raptime.MM + "分" + end2Raptime.SS + "秒"}
                  </h1>
                </Box>
                <Box className={classes.centerFlatBox}>
                  <TargetMinuits targetTime={recordConfig.step2Time} ></TargetMinuits><br></br>
                  通過タイム:{targetStep2End}<br></br>
                </Box>
                {!end2Datetime && <Box className={classes.centerFlatBox}>
                  <h1>
                    あと:{end1Datetime ? Math.floor(getRapBufferTime(setHHMMDDFormatFromDate(end1Datetime), nowTimeHHMMSS, recordConfig.step2Time) / 60) : recordConfig.step2Time} 分
                  </h1>
                </Box>}
                <Box className={classes.centerFlatBox}>
                  <Box sx={{
                    fontWeight: 'bold',
                    color: (targetStep2EndResult <= 0 ? "green" : "red")
                  }} >
                    {targetStep2EndResult} 秒
                  </Box>
                </Box>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setEnd2Fixcount(pre => pre + 1)
                  }}>
                  手戻り{end2Fixcount}

                </Button>
              </Box>
              <Button
                disabled={end2Datetime || !end1Datetime}
                variant="contained"
                fullWidth
                onClick={() => {
                  setEnd2Datetime(new Date())
                  setRapTime("step2", new Date(), end1Datetime)

                }}>
                Step2消化
              </Button>
            </Grid>
            {/** step3 段目 ===================================================================== */}
            <Grid item xs={12} className={classes.gridPadding} sx={getGridAnimationStyle((!end3Datetime))}>
              <Box className={classes.centerFlatBox}>
                <b>step3: {recordConfig.step3Name}</b>
              </Box>
              <Box className={classes.centerFlatBox}>

                <Box className={classes.centerFlatBox}>
                  step3 所要時間
                  <h1>
                    {end3Raptime && end3Raptime.HH + "時" + end3Raptime.MM + "分" + end3Raptime.SS + "秒"}
                  </h1>
                </Box>
                <Box className={classes.centerFlatBox}>
                  <TargetMinuits targetTime={recordConfig.step3Time} ></TargetMinuits><br></br>
                  通過タイム:{targetStep3End}<br></br>
                </Box>
                {!end3Datetime && <Box className={classes.centerFlatBox}>
                  <h1>
                    あと:{end2Datetime ? Math.floor(getRapBufferTime(setHHMMDDFormatFromDate(end2Datetime), nowTimeHHMMSS, recordConfig.step3Time) / 60) : recordConfig.step3Time} 分
                  </h1>
                </Box>}
                <Box className={classes.centerFlatBox}>
                  <Box sx={{
                    fontWeight: 'bold',
                    color: (targetStep3EndResult <= 0 ? "green" : "red")
                  }} >
                    {targetStep3EndResult} 秒
                  </Box>
                </Box>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setEnd3Fixcount(pre => pre + 1)
                  }}>
                  手戻り{end3Fixcount}

                </Button>
              </Box>
              <Button
                disabled={end3Datetime || !end2Datetime}
                variant="contained"
                fullWidth
                onClick={() => {
                  setEnd3Datetime(new Date())
                  setRapTime("step3", new Date(), end2Datetime)

                }}>
                Step3消化
              </Button>
            </Grid>
            {/** step4 段目 ===================================================================== */}
            <Grid item xs={12} className={classes.gridPadding} sx={getGridAnimationStyle((!end4Datetime))}>
              <Box className={classes.centerFlatBox}>
                <b>step4: {recordConfig.step4Name}</b>
              </Box>
              <Box className={classes.centerFlatBox}>

                <Box className={classes.centerFlatBox}>
                  step4 所要時間
                  <h1>
                    {end4Raptime && end4Raptime.HH + "時" + end4Raptime.MM + "分" + end4Raptime.SS + "秒"}
                  </h1>
                </Box>
                <Box className={classes.centerFlatBox}>
                  <TargetMinuits targetTime={recordConfig.step4Time} ></TargetMinuits><br></br>
                  通過タイム:{targetStep4End}<br></br>
                </Box>
                {!end4Datetime && <Box className={classes.centerFlatBox}>
                  <h1>
                    あと:{end3Datetime ? Math.floor(getRapBufferTime(setHHMMDDFormatFromDate(end3Datetime), nowTimeHHMMSS, recordConfig.step4Time) / 60) : recordConfig.step4Time} 分
                  </h1>
                </Box>}
                <Box className={classes.centerFlatBox}>
                  <Box sx={{
                    fontWeight: 'bold',
                    color: (targetStep4EndResult <= 0 ? "green" : "red")
                  }} >
                    {targetStep4EndResult} 秒
                  </Box>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setEnd4Fixcount(pre => pre + 1)
                    }}>
                    手戻り{end4Fixcount}

                  </Button>
                </Box>
              </Box>
              <Button
                disabled={end4Datetime || !end3Datetime}
                variant="contained"
                fullWidth
                onClick={() => {
                  setEnd4Datetime(new Date())
                  setRapTime("step4", new Date(), end3Datetime)

                }}>
                Step4消化
              </Button>
            </Grid>

            {/** step5 段目 ===================================================================== */}
            <Grid item xs={12} className={classes.gridPadding} sx={getGridAnimationStyle((!end5Datetime))}>
              <Box className={classes.centerFlatBox}>
                <b>step5: {recordConfig.step5Name}</b>
              </Box>
              <Box className={classes.centerFlatBox}>

                <Box className={classes.centerFlatBox}>
                  step5 所要時間
                  <h1>
                    {end5Raptime && end5Raptime.HH + "時" + end5Raptime.MM + "分" + end5Raptime.SS + "秒"}
                  </h1>
                </Box>
                <Box className={classes.centerFlatBox}>
                  <TargetMinuits targetTime={recordConfig.step5Time} ></TargetMinuits><br></br>
                  通過タイム:{targetStep5End} <br></br>
                </Box>
                {!end5Datetime && <Box className={classes.centerFlatBox}>
                  <h1>
                    あと:{end4Datetime ? Math.floor(getRapBufferTime(setHHMMDDFormatFromDate(end4Datetime), nowTimeHHMMSS, recordConfig.step5Time) / 60) : recordConfig.step5Time} 分
                  </h1>
                </Box>}
                <Box className={classes.centerFlatBox}>
                  <Box sx={{
                    fontWeight: 'bold',
                    color: (targetStep5EndResult <= 0 ? "green" : "red")
                  }} >
                    {targetStep5EndResult} 秒
                  </Box>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setEnd5Fixcount(pre => pre + 1)
                    }}>
                    手戻り{end5Fixcount}

                  </Button>
                </Box>
              </Box>
              <Button
                disabled={end5Datetime || !end4Datetime}
                variant="contained"
                fullWidth
                onClick={() => {
                  setEnd5Datetime(new Date())
                  setRapTime("step5", new Date(), end4Datetime)

                }}>
                Step5消化
              </Button>
            </Grid>

            {/** step6 段目 ===================================================================== */}
            {(recordConfig.step6Name != "" || recordConfig.step6Name) && <Grid item xs={12} className={classes.gridPadding}>
              < Box className={classes.centerFlatBox} sx={getGridAnimationStyle((!end6Datetime))}>
                <b>step6: {recordConfig.step6Name}</b>
              </Box>
              <Box className={classes.centerFlatBox}>

                <Box className={classes.centerFlatBox}>
                  step6 所要時間
                  <h1>
                    {end6Raptime && end6Raptime.HH + "時" + end6Raptime.MM + "分" + end6Raptime.SS + "秒"}
                  </h1>
                </Box>
                <Box className={classes.centerFlatBox}>
                  <TargetMinuits targetTime={recordConfig.step6Time} ></TargetMinuits><br></br>
                  通過タイム:{targetStep6End}<br></br>
                </Box>
                {!end6Datetime && <Box className={classes.centerFlatBox}>
                  <h1>
                    あと:{end5Datetime ? Math.floor(getRapBufferTime(setHHMMDDFormatFromDate(end5Datetime), nowTimeHHMMSS, recordConfig.step6Time) / 60) : recordConfig.step6Time} 分
                  </h1>
                </Box>}
                <Box className={classes.centerFlatBox}>
                  <Box sx={{
                    fontWeight: 'bold',
                    color: (targetStep6EndResult <= 0 ? "green" : "red")
                  }} >
                    {targetStep6EndResult} 秒
                  </Box>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setEnd6Fixcount(pre => pre + 1)
                    }}>
                    手戻り{end6Fixcount}

                  </Button>
                </Box>
              </Box>
              <Button
                disabled={end6Datetime || !end5Datetime}
                variant="contained"
                fullWidth
                onClick={() => {
                  setEnd6Datetime(new Date())
                  setRapTime("step6", new Date(), end5Datetime)

                }}>
                Step6消化
              </Button>
            </Grid>}

            {/** step7 段目 ===================================================================== */}
            {(recordConfig.step7Name !== "" || recordConfig.step7Name) && <Grid item xs={12} className={classes.gridPadding}>
              < Box className={classes.centerFlatBox} sx={getGridAnimationStyle((!end7Datetime))}>
                <b>step7: {recordConfig.step7Name}</b>
              </Box>
              <Box className={classes.centerFlatBox}>

                <Box className={classes.centerFlatBox}>
                  step7 所要時間
                  <h1>
                    {end7Raptime && end7Raptime.HH + "時" + end7Raptime.MM + "分" + end7Raptime.SS + "秒"}
                  </h1>
                </Box>
                <Box className={classes.centerFlatBox}>
                  <TargetMinuits targetTime={recordConfig.step7Time} ></TargetMinuits><br></br>
                  通過タイム:{targetStep7End}<br></br>
                </Box>
                {!end7Datetime && <Box className={classes.centerFlatBox}>
                  <h1>
                    あと:{end6Datetime ? Math.floor(getRapBufferTime(setHHMMDDFormatFromDate(end6Datetime), nowTimeHHMMSS, recordConfig.step7Time) / 60) : recordConfig.step7Time} 分
                  </h1>
                </Box>}
                <Box className={classes.centerFlatBox}>
                  <Box sx={{
                    fontWeight: 'bold',
                    color: (targetStep7EndResult <= 0 ? "green" : "red")
                  }} >
                    {targetStep7EndResult} 秒
                  </Box>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setEnd7Fixcount(pre => pre + 1)
                    }}>
                    手戻り{end7Fixcount}

                  </Button>
                </Box>
              </Box>
              <Button
                disabled={end7Datetime || !end6Datetime}
                variant="contained"
                fullWidth
                onClick={() => {
                  setEnd7Datetime(new Date())
                  setRapTime("step7", new Date(), end6Datetime)

                }}>
                Step7消化
              </Button>
            </Grid>}


            {/** step8 段目 ===================================================================== */}
            {(recordConfig.step8Name !== "" || recordConfig.step8Name) && <Grid item xs={12} className={classes.gridPadding}>
              < Box className={classes.centerFlatBox} sx={getGridAnimationStyle((!end8Datetime))}>
                <b>step8: {recordConfig.step8Name}</b>
              </Box>
              <Box className={classes.centerFlatBox}>

                <Box className={classes.centerFlatBox}>
                  step8 所要時間
                  <h1>
                    {end8Raptime && end8Raptime.HH + "時" + end8Raptime.MM + "分" + end8Raptime.SS + "秒"}
                  </h1>
                </Box>
                <Box className={classes.centerFlatBox}>
                  <TargetMinuits targetTime={recordConfig.step8Time} ></TargetMinuits><br></br>
                  通過タイム:{targetStep8End}<br></br>
                </Box>
                {!end8Datetime && <Box className={classes.centerFlatBox}>
                  <h1>
                    あと:{end7Datetime ? Math.floor(getRapBufferTime(setHHMMDDFormatFromDate(end7Datetime), nowTimeHHMMSS, recordConfig.step8Time) / 60) : recordConfig.step8Time} 分
                  </h1>
                </Box>}
                <Box className={classes.centerFlatBox}>
                  <Box sx={{
                    fontWeight: 'bold',
                    color: (targetStep8EndResult <= 0 ? "green" : "red")
                  }} >
                    {targetStep8EndResult} 秒
                  </Box>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setEnd8Fixcount(pre => pre + 1)
                    }}>
                    手戻り{end8Fixcount}

                  </Button>
                </Box>
              </Box>
              <Button
                disabled={end8Datetime || !end7Datetime}
                variant="contained"
                fullWidth
                onClick={() => {
                  setEnd8Datetime(new Date())
                  setRapTime("step8", new Date(), end7Datetime)

                }}>
                Step8消化
              </Button>
            </Grid>}

            {/** step9 段目 ===================================================================== */}
            {(recordConfig.step9Name !== "" || recordConfig.step9Name) && <Grid item xs={12} className={classes.gridPadding}>
              < Box className={classes.centerFlatBox} sx={getGridAnimationStyle((!end9Datetime))}>
                <b>step9: {recordConfig.step9Name}</b>
              </Box>
              <Box className={classes.centerFlatBox}>

                <Box className={classes.centerFlatBox}>
                  step9 所要時間
                  <h>
                    {end9Raptime && end9Raptime.HH + "時" + end9Raptime.MM + "分" + end9Raptime.SS + "秒"}
                  </h>
                </Box>
                <Box className={classes.centerFlatBox}>
                  <TargetMinuits targetTime={recordConfig.step9Time} ></TargetMinuits><br></br>
                  通過タイム:{targetStep9End}               <br></br>
                </Box>
                {!end9Datetime && <Box className={classes.centerFlatBox}>
                  <h1>
                    あと:{end8Datetime ? Math.floor(getRapBufferTime(setHHMMDDFormatFromDate(end8Datetime), nowTimeHHMMSS, recordConfig.step9Time) / 60) : recordConfig.step9Time} 分
                  </h1>
                </Box>}
                <Box className={classes.centerFlatBox}>
                  <Box sx={{
                    fontWeight: 'bold',
                    color: (targetStep9EndResult <= 0 ? "green" : "red")
                  }} >
                    {targetStep9EndResult} 秒
                  </Box>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setEnd9Fixcount(pre => pre + 1)
                    }}>
                    手戻り{end9Fixcount}

                  </Button>
                </Box>
              </Box>
              <Button
                disabled={end9Datetime || !end8Datetime}
                variant="contained"
                fullWidth
                onClick={() => {
                  setEnd9Datetime(new Date())
                  setRapTime("step9", new Date(), end8Datetime)

                }}>
                Step9消化
              </Button>
            </Grid>}


            {/** step10 段目 ===================================================================== */}
            {(recordConfig.step10Name !== "" || recordConfig.step10Name) && <Grid item xs={12} className={classes.gridPadding}>
              < Box className={classes.centerFlatBox} sx={getGridAnimationStyle((!end10Datetime))}>
                <b>step10: {recordConfig.step10Name}</b>
              </Box>
              <Box className={classes.centerFlatBox}>

                <Box className={classes.centerFlatBox}>
                  step10 所要時間
                  <h1>
                    {end10Raptime && end10Raptime.HH + "時" + end10Raptime.MM + "分" + end10Raptime.SS + "秒"}
                  </h1>
                </Box>
                <Box className={classes.centerFlatBox}>
                  <TargetMinuits targetTime={recordConfig.step10Time} ></TargetMinuits><br></br>
                  通過タイム:{targetStep10End}                <br></br>
                </Box>
                {!end10Datetime && <Box className={classes.centerFlatBox}>
                  <h1>
                    あと:{end9Datetime ? Math.floor(getRapBufferTime(setHHMMDDFormatFromDate(end9Datetime), nowTimeHHMMSS, recordConfig.step10Time) / 60) : recordConfig.step10Time} 分
                  </h1>
                </Box>}
                <Box className={classes.centerFlatBox}>
                  <Box sx={{
                    fontWeight: 'bold',
                    color: (targetStep10EndResult <= 0 ? "green" : "red")
                  }} >
                    {targetStep10EndResult} 秒
                  </Box>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setEnd10Fixcount(pre => pre + 1)
                    }}>
                    手戻り{end10Fixcount}

                  </Button>
                </Box>
              </Box>
              <Button
                disabled={end10Datetime || !end9Datetime}
                variant="contained"
                fullWidth
                onClick={() => {
                  setEnd10Datetime(new Date())
                  setRapTime("step10", new Date(), end9Datetime)

                }}>

                Step10消化
              </Button>
            </Grid>}

            {/** step最終 段目 ===================================================================== */}
            <Grid item xs={12} className={classes.gridPadding}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  setEndEstimatedDatetime(new Date())
                  setRapTime("estimate", new Date(), startDatetime)
                  stop()
                }}>
                完了 (※ 実施したステップは消化ボタンをお忘れなく)
              </Button>
              <Box className={classes.centerFlatBox}>

                <Box className={classes.centerFlatBox}>
                  合計所要時間   {finishedRaptime && finishedRaptime.HH + "時" + finishedRaptime.MM + "分" + finishedRaptime.SS + "秒"}
                </Box>
              </Box>
            </Grid>
            {/** カウンターパーツ */}
            {/** n段目 ===================================================================== */}


            {/** 終了・保存パーツ */}
            {/** n段目 ===================================================================== */}
            <Grid item xs={12} className={classes.gridPadding}>
              <Box className={classes.centerBox}>
                <Button
                  sx={{ height: "100px" }}
                  disabled={!endEstimatedDatetime || !user}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    // レコードをdeleteしてデフォルトレコードを再度作成、この画面を開き直す
                    createDiary();
                  }}>
                  記録を保存する <br></br>{!user ? "【未ログインの場合保存できません。次のボタンで終了してください】" : ""}
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    let result = window.confirm("保存せず終了します")
                    if (result) {
                      setLoading(true)
                      navigate('/')
                    }
                  }}>
                  記録を削除して終了する
                </Button>
              </Box>
              <DisplayAd adType="yokonaga" />

            </Grid>


          </Grid >
          {/** GRIDオワリ　 ===================================================================== */}

        </Box >

      </HeadFooter >

    );
  }




}
export default OnedroTimer;

const style = {
  scoreLabel: {
    fontSize: 25,

  },
}

const useStyles = makeStyles((theme) => ({
  //オーバーレイ
  overlay: {
    zIndex: 10,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },//オーバーレイの中のモーダル
  modalView: {
    zIndex: 10,
    borderRadius: 15,
    width: '50%',
    padding: '1em',
    background: '#fff',
  },
  // 全体レイアウト
  contentWrapper: {
    marginTop: 20,
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",

  },
  // グリッド箱の書式 
  gridPadding: {
    padding: 5,
    border: '2px solid',
    borderColor: '#a6d4fa',
    backgroundColor: 'linear-gradient(to bottom right,#a6d4fa,#1976d2)',
    borderRadius: '10'

  },
  // グリッド箱の書式 枠線なし
  gridPaddingNoborder: {
    padding: 5,


  },
  // 個別コンテンツ　中央揃えで縦に内容を並べる
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },// 個別コンテンツ　中央揃えで横に内容を並べる
  centerFlatBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: 10,
    alignItems: 'center',
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftCenterBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftRowBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
  },
  // 個別コンテンツ　横並びに内容を並べる
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },

  // モーダルダイアログのフォーム　個別アイテム
  modalFormItem: {
    margin: 20,
  },
  //サンプルのグラフエリア
  sampleGraphEria: {
    width: 200
  },

  cardList: {
  },
  card: {
  },
  bottomCard: {
    width: 250
  },
}));


// 目標タイムの入力フォーム
/*
const TargetMinuits = ({ value, setter, disabled = true }) => {

  return (
    <>
      目標タイム：
      <TextField
        label="開始から"
        id="filled-start-adornment"
        type="number"
        disabled={disabled}
        sx={{ m: 1, width: '15ch' }}
        InputProps={{
          endAdornment: <InputAdornment position="end">分後</InputAdornment>,
        }}
        variant="filled"
        value={value}
        onChange={(e) => setter(e.target.value)}
      />

    </>

  )
}
*/
const TargetMinuits = ({ targetTime }) => {

  return (
    <>
      目標タイム:{targetTime} 分
    </>

  )
}


// 設定画面の入力フォームの共通か
const ConfigInputForm = ({
  stepName,
  classes,
  nameHandler,
  stepNameValue,
  placeHolder,
  timeReturn = 0,
  timeValue
}) => {
  return (
    <Box className={classes.leftBox}>
      <Typography
        sx={{ paddingTop: 1 }}>
        {stepName}
      </Typography>
      <TextField margin="nomal" id="filled-basic" size="small"
        inputProps={{ maxLength: 20 }}
        placeholder={placeHolder}
        onChange={nameHandler} variant="outlined" type="string" value={stepNameValue}
      />
      <TextField margin="nomal" id="filled-basic" size="small"
        inputProps={{ maxLength: 2 }}
        placeholder="目標タイム(分)"
        onChange={(event) =>
          timeReturn(event.target.value)
        }
        variant="outlined" type="number" value={timeValue}
      />
    </Box>
  )
}


