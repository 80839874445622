import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';


// グラフコンポーネント
import { VictoryLine, VictoryPie, VictoryChart, VictoryBar, VictoryTheme } from 'victory';


const cardHeight = 300;
const imageHeight = 150;
const cardMargin = 5;



/**
 * ユーザ一覧などで表示するアカウントの情報をまとめたカード
 * @property { record, height }
 * @returns 
 * 
 * 募集状況status　募集中/受付停止中
 * 
 * <要件>
 * アバターを出す
 * ヒーローイメージを背景に出す
 * クリックしたらプロフィールに遷移する
 * 環境に応じて幅を調整する
 * propで受け取ったユーザデータで構成する
 */
const RecordErrorBarGraph = ({ recordData, height }) => {

    const navigate = useNavigate();
    const classes = useStyles();

    // 引数
    const {
        id,
        step0Time,
        step1,
        step1Time,
        step1FixCount,
        step2,
        step2Time,
        step2FixCount,
        step3,
        step3Time,
        step3FixCount,
        step4,
        step4Time,
        step4FixCount,
        step5,
        step5Time,
        step5FixCount,
        step6,
        step6Time,
        step6FixCount,
        step7,
        step7Time,
        step7FixCount,
        step8,
        step8Time,
        step8FixCount,
        step9,
        step9Time,
        step9FixCount,
        step10,
        step10Time,
        step10FixCount,
        completeTime,
        uid,
        createdAt,
        updatedAt,
    } = recordData;

    // データ加工

    //console.log(step1Rap)
    //console.log(step2Rap)
    //console.log(step3Rap)
    //console.log(step4Rap)

    // データ作成
    /**
     * 引数のデータ構造
     * data={[
                { x: "サッカー", y: 200 },
                { x: "野球", y: 100 },
                { x: "テニス", y: 100 },
                { x: "テニス", y: 100 }
            ]}
     */
    const graphData = [
        { x: `${step1}`, y: step1FixCount },
        { x: `${step2}`, y: step2FixCount },
        { x: `${step3}`, y: step3FixCount },
        { x: `${step4}`, y: step4FixCount },
        { x: `${step5}`, y: step5FixCount },
        { x: `${step6}`, y: step6FixCount },
        { x: `${step7}`, y: step7FixCount },
        { x: `${step8}`, y: step8FixCount },
        { x: `${step8}`, y: step9FixCount },
        { x: `${step10}`, y: step10FixCount },
    ]









    return (
        <>
            {/**
            <Box className={classes.leftCenterBox}>
            </Box>
    */}
            <VictoryChart
                theme={VictoryTheme.material}
                domainPadding={20}
                height={height}
            >

                <VictoryBar
                    style={{ data: { fill: "#c43a31" } }}
                    data={graphData}
                />
            </VictoryChart>
        </>
    )
}

export default RecordErrorBarGraph;


const useStyles = makeStyles((theme) => ({

    card: {
        maxWidth: "300px",
    }, leftCenterBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        height: 200,
    },



}));
