import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';


// グラフコンポーネント
import { VictoryBar, VictoryStack, VictoryArea, VictoryPie, VictoryChart, VictoryTheme } from 'victory';


const cardHeight = 300;
const imageHeight = 150;
const cardMargin = 5;



/**
 * 棒グラフ
 * @property {  }
 * @returns 
 * 
 */
const RecordParcentBarGraph = ({ recordData }) => {

    const navigate = useNavigate();
    const classes = useStyles();
    // 時間制御
    // 時間の差を返す
    /**
     * @param {Date} 現在時刻
     * @param {Date} 比較対象時刻
     * @return {HH: number, MM: number, SS:number} 差分の　時、分、秒
     */
    const getDiffMMdd = (genzai, kaishi) => {
        if (!(genzai instanceof Date)) {
            throw new Error("データ方が違います genzai : " + genzai)
        }
        if (!(kaishi instanceof Date)) {
            throw new Error("データ方が違います kaishi : " + kaishi)
        }
        // console.log(genzai instanceof Date);
        // console.log("時間計算処理 getDiffMMdd")
        // console.log("現在のDate" + genzai)
        // console.log("対象のDate" + kaishi)

        // 時間差計算は、getTimeでミリ秒計算し、戻す
        // 1000で割ると秒、さらに60で割って、分、秒にする
        const gendaiTime = genzai.getTime();
        const kaishiTime = kaishi.getTime();
        const diffTime = gendaiTime - kaishiTime
        // MMHHSSを作るなら、MMはHHを６０で割った余りになるため％でMODする
        const HH = Math.floor(diffTime / (1000 * 60 * 60));
        const MM = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
        const SS = Math.floor((diffTime % (1000 * 60)) / 1000);
        return { HH, MM, SS };
    }
    /**
     * ミリ秒の差を返す。グラフ等での処理に利用
     * @param {Date} 現在時刻
     * @param {Date} 比較対象時刻
     * @return {number} 差のミリ秒
     */
    const getDiffTime = (genzai, kaishi) => {
        if (!(genzai instanceof Date)) {
            throw new Error("データ方が違います genzai : " + genzai)
        }
        if (!(kaishi instanceof Date)) {
            throw new Error("データ方が違います kaishi : " + kaishi)
        }
        const gendaiTime = genzai.getTime();
        const kaishiTime = kaishi.getTime();
        return gendaiTime - kaishiTime
    }

    // ラベル加工
    /**
     * 
     * @param {HH:String, MM:String, SS:String} 時分秒オブジェクト 
     * @returns {string} 整形済み文字列
     */
    const getLabelMMSS = ({ HH, MM, SS }) => {
        return `${HH}時${MM}分${SS}秒`
    }

    // 引数
    const {
        id,
        step0Time,
        step1,
        step1Time,
        step1FixCount,
        step2,
        step2Time,
        step2FixCount,
        step3,
        step3Time,
        step3FixCount,
        step4,
        step4Time,
        step4FixCount,
        step5,
        step5Time,
        step5FixCount,
        step6,
        step6Time,
        step6FixCount,
        step7,
        step7Time,
        step7FixCount,
        step8,
        step8Time,
        step8FixCount,
        step9,
        step9Time,
        step9FixCount,
        step10,
        step10Time,
        step10FixCount,
        completeTime,
        uid,
        createdAt,
        updatedAt,
    } = recordData;

    // データ加工
    // 各工程の所要時間
    // ms
    const step1RapMs = step1Time ? getDiffTime(step1Time.toDate(), step0Time.toDate()) : 0
    const step2RapMs = step2Time ? getDiffTime(step2Time.toDate(), step1Time.toDate()) : 0
    const step3RapMs = step3Time ? getDiffTime(step3Time.toDate(), step2Time.toDate()) : 0
    const step4RapMs = step4Time ? getDiffTime(step4Time.toDate(), step3Time.toDate()) : 0
    const step5RapMs = step5Time ? getDiffTime(step5Time.toDate(), step4Time.toDate()) : 0
    const step6RapMs = step6Time ? getDiffTime(step6Time.toDate(), step5Time.toDate()) : 0
    const step7RapMs = step7Time ? getDiffTime(step7Time.toDate(), step6Time.toDate()) : 0
    const step8RapMs = step8Time ? getDiffTime(step8Time.toDate(), step7Time.toDate()) : 0
    const step9RapMs = step9Time ? getDiffTime(step9Time.toDate(), step8Time.toDate()) : 0
    const step10RapMs = step10Time ? getDiffTime(step10Time.toDate(), step9Time.toDate()) : 0


    // ラベル　時、分、秒
    const step1Rap = step1Time ? getDiffMMdd(step1Time.toDate(), step0Time.toDate()) : { HH: 0, MM: 0, SS: 0 }
    const step2Rap = step2Time ? getDiffMMdd(step2Time.toDate(), step1Time.toDate()) : { HH: 0, MM: 0, SS: 0 }
    const step3Rap = step3Time ? getDiffMMdd(step3Time.toDate(), step2Time.toDate()) : { HH: 0, MM: 0, SS: 0 }
    const step4Rap = step4Time ? getDiffMMdd(step4Time.toDate(), step3Time.toDate()) : { HH: 0, MM: 0, SS: 0 }
    const step5Rap = step5Time ? getDiffMMdd(step5Time.toDate(), step4Time.toDate()) : { HH: 0, MM: 0, SS: 0 }
    const step6Rap = step6Time ? getDiffMMdd(step6Time.toDate(), step5Time.toDate()) : { HH: 0, MM: 0, SS: 0 }
    const step7Rap = step7Time ? getDiffMMdd(step7Time.toDate(), step6Time.toDate()) : { HH: 0, MM: 0, SS: 0 }
    const step8Rap = step8Time ? getDiffMMdd(step8Time.toDate(), step7Time.toDate()) : { HH: 0, MM: 0, SS: 0 }
    const step9Rap = step9Time ? getDiffMMdd(step9Time.toDate(), step8Time.toDate()) : { HH: 0, MM: 0, SS: 0 }
    const step10Rap = step10Time ? getDiffMMdd(step10Time.toDate(), step9Time.toDate()) : { HH: 0, MM: 0, SS: 0 }

    // 累積 　時、分、秒
    const step1RapPilup = step1Time ? getDiffMMdd(step1Time.toDate(), step0Time.toDate()) : { HH: 0, MM: 0, SS: 0 }
    const step2RapPilup = step2Time ? getDiffMMdd(step2Time.toDate(), step0Time.toDate()) : { HH: 0, MM: 0, SS: 0 }
    const step3RapPilup = step3Time ? getDiffMMdd(step3Time.toDate(), step0Time.toDate()) : { HH: 0, MM: 0, SS: 0 }
    const step4RapPilup = step4Time ? getDiffMMdd(step4Time.toDate(), step0Time.toDate()) : { HH: 0, MM: 0, SS: 0 }
    const step5RapPilup = step5Time ? getDiffMMdd(step5Time.toDate(), step0Time.toDate()) : { HH: 0, MM: 0, SS: 0 }
    const step6RapPilup = step6Time ? getDiffMMdd(step6Time.toDate(), step0Time.toDate()) : { HH: 0, MM: 0, SS: 0 }
    const step7RapPilup = step7Time ? getDiffMMdd(step7Time.toDate(), step0Time.toDate()) : { HH: 0, MM: 0, SS: 0 }
    const step8RapPilup = step8Time ? getDiffMMdd(step8Time.toDate(), step0Time.toDate()) : { HH: 0, MM: 0, SS: 0 }
    const step9RapPilup = step9Time ? getDiffMMdd(step9Time.toDate(), step0Time.toDate()) : { HH: 0, MM: 0, SS: 0 }
    const step10RapPilup = step10Time ? getDiffMMdd(step10Time.toDate(), step0Time.toDate()) : { HH: 0, MM: 0, SS: 0 }


    const step1RapPilupLbl = getLabelMMSS(step1RapPilup)
    const step2RapPilupLbl = getLabelMMSS(step2RapPilup)
    const step3RapPilupLbl = getLabelMMSS(step3RapPilup)
    const step4RapPilupLbl = getLabelMMSS(step4RapPilup)
    const step5RapPilupLbl = getLabelMMSS(step5RapPilup)
    const step6RapPilupLbl = getLabelMMSS(step6RapPilup)
    const step7RapPilupLbl = getLabelMMSS(step7RapPilup)
    const step8RapPilupLbl = getLabelMMSS(step8RapPilup)
    const step9RapPilupLbl = getLabelMMSS(step9RapPilup)
    const step10RapPilupLbl = getLabelMMSS(step10RapPilup)


    //console.log(step1Rap)
    //console.log(step2Rap)
    //console.log(step3Rap)
    //console.log(step4Rap)

    // 時刻
    const startTimeStr = step0Time.toDate().getHours() + "時" + step0Time.toDate().getMinutes() + "分" + step0Time.toDate().getSeconds() + "秒";
    //const step1TimeStr = step1Time.toDate().toLocaleTimeString()
    //const step1TimeStr = step1Time.toDate().getHours() + "時" + step1Time.toDate().getMinutes() + "分" + step1Time.toDate().getSeconds() + "秒";
    //const step2TimeStr = step2Time.toDate().getHours() + "時" + step2Time.toDate().getMinutes() + "分" + step2Time.toDate().getSeconds() + "秒";
    //const step3TimeStr = step3Time.toDate().getHours() + "時" + step3Time.toDate().getMinutes() + "分" + step3Time.toDate().getSeconds() + "秒";
    //const step4TimeStr = step4Time.toDate().getHours() + "時" + step4Time.toDate().getMinutes() + "分" + step4Time.toDate().getSeconds() + "秒";




    const turnAroundTime = getDiffMMdd(completeTime.toDate(), step0Time.toDate())
    const turnAroundTimeStr = turnAroundTime.HH + "時間" + turnAroundTime.MM + "分" + turnAroundTime.SS + "秒"

    // データ作成
    /**
     * 引数のデータ構造
     * data={[
                { x: "サッカー", y: 200 },
                { x: "野球", y: 100 },
                { x: "テニス", y: 100 },
                { x: "テニス", y: 100 }
            ]}
     */


    // 値があったら系列データに追加する
    let graphData = []
    let labelName = []

    if (step1) {
        graphData.push({ x: `${step1} ${step1Rap.HH}時${step1Rap.MM}分${step1Rap.SS}秒`, y: step1RapMs })
        labelName.push(`${step1}完了\n${step1RapPilupLbl}`,)
    }
    if (step2) {
        graphData.push({ x: `${step2} ${step2Rap.HH}時${step2Rap.MM}分${step2Rap.SS}秒`, y: step2RapMs })
        labelName.push(`${step2}完了\n${step2RapPilupLbl}`,)
    }
    if (step3) {
        graphData.push({ x: `${step3} ${step3Rap.HH}時${step3Rap.MM}分${step3Rap.SS}秒`, y: step3RapMs })
        labelName.push(`${step3}完了\n${step3RapPilupLbl}`,)
    }
    if (step4) {
        graphData.push({ x: `${step4} ${step4Rap.HH}時${step4Rap.MM}分${step4Rap.SS}秒`, y: step4RapMs })
        labelName.push(`${step4}完了\n${step4RapPilupLbl}`,)
    }
    if (step5) {
        graphData.push({ x: `${step5} ${step5Rap.HH}時${step5Rap.MM}分${step5Rap.SS}秒`, y: step5RapMs })
        labelName.push(`${step5}完了\n${step5RapPilupLbl}`,)
    }
    if (step6) {
        graphData.push({ x: `${step6} ${step6Rap.HH}時${step6Rap.MM}分${step6Rap.SS}秒`, y: step6RapMs })
        labelName.push(`${step6}完了\n${step6RapPilupLbl}`,)
    }
    if (step7) {
        graphData.push({ x: `${step7} ${step7Rap.HH}時${step7Rap.MM}分${step7Rap.SS}秒`, y: step7RapMs })
        labelName.push(`${step7}完了\n${step7RapPilupLbl}`,)
    }
    if (step8) {
        graphData.push({ x: `${step8} ${step8Rap.HH}時${step8Rap.MM}分${step8Rap.SS}秒`, y: step8RapMs })
        labelName.push(`${step8}完了\n${step8RapPilupLbl}`,)
    }
    if (step9) {
        graphData.push({ x: `${step9} ${step9Rap.HH}時${step9Rap.MM}分${step9Rap.SS}秒`, y: step9RapMs })
        labelName.push(`${step9}完了\n${step9RapPilupLbl}`,)
    }
    if (step10) {
        graphData.push({ x: `${step10} ${step10Rap.HH}時${step10Rap.MM}分${step10Rap.SS}秒`, y: step10RapMs })
        labelName.push(`${step10}完了\n${step10RapPilupLbl}`,)
    }



    console.log(graphData)

    // Stackのグラフ困難のため、シンプルに、
    return (
        <>

            合計タイム　{turnAroundTimeStr}<br></br>
            開始時刻 　{startTimeStr} → 終了時刻{turnAroundTimeStr}
            <VictoryStack
                width={800}
                animate={{
                    duration: 2000,
                    onLoad: { duration: 1000 }
                }}
                horizontal={false}
                colorScale={["#65D8FF", "#65B2FF", "#658CFF", "#6565FF"]}
            >
                <VictoryBar
                    data={graphData}
                    labels={labelName}
                />
            </VictoryStack>

        </>)

    /*
        return (
            <>
    
                合計タイム　{turnAroundTimeStr}<br></br>
                開始時刻 　{startTimeStr} → 終了時刻{step4TimeStr}
                <VictoryStack
                    width={800}
                    animate={{
                        duration: 2000,
                        onLoad: { duration: 1000 }
                    }}
                    horizontal={false}
                    colorScale={["#65D8FF", "#65B2FF", "#658CFF", "#6565FF"]}
                >
                    {/**
                     * VictoryBarが積み上げ要素の値、上のバーから順にグラフにしたから積み上がる
                     * xのaからdは、棒グラフ１本ずつ。左から右に４本ある
                     * ひとつめの要素は、この場合最初に積まれて（最初の工程だから）そのあと減らないので全部につく
                     * ２つ目めのVictoryBayは、２番目の工程なので、x:aには0、x:b移行からつむ、のようにデータを作る
                     */
    /*
    }
     
    <VictoryBar
        data={[{ x: "a", y: graphData[0].y }, { x: "b", y: graphData[0].y }, { x: "c", y: graphData[0].y }, { x: "d", y: graphData[0].y }]}
    />
    <VictoryBar
        data={[{ x: "a", y: 0 }, { x: "b", y: graphData[1].y }, { x: "c", y: graphData[1].y }, { x: "d", y: graphData[1].y }]}
    />
    <VictoryBar
        data={[{ x: "a", y: 0 }, { x: "b", y: 0 }, { x: "c", y: graphData[2].y }, { x: "d", y: graphData[2].y }]}
    />
    <VictoryBar
        data={[{ x: "a", y: 0 }, { x: "b", y: 0 }, { x: "c", y: 0 }, { x: "d", y: graphData[3].y }]}
        labels={labelName}
    />
</VictoryStack>

</>
)

*/



    /*

return (
    <>

        合計タイム　{turnAroundTimeStr}<br></br>
        開始時刻 　{startTimeStr} → 終了時刻{step4TimeStr}
        <VictoryStack
            horizontal={false}
            colorScale={["#65D8FF", "#65B2FF", "#658CFF", "#6565FF"]}
        >
            <VictoryBar
                data={[{ x: "a", y: 100 }, { x: "b", y: 100 }, { x: "c", y: 100 }, { x: "d", y: 100 }]}
            />
            <VictoryBar
                data={[{ x: "a", y: 0 }, { x: "b", y: 100 }, { x: "c", y: 100 }, { x: "d", y: 100 }]}
            />
            <VictoryBar
                data={[{ x: "a", y: 0 }, { x: "b", y: 0 }, { x: "c", y: 200 }, { x: "d", y: 200 }]}
            />
            <VictoryBar
                data={[{ x: "a", y: 0 }, { x: "b", y: 0 }, { x: "c", y: 0 }, { x: "d", y: 100 }]} labels={labelName}
            />
        </VictoryStack>

    </>
)
*/

}

export default RecordParcentBarGraph;


const useStyles = makeStyles((theme) => ({





}));
