import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import IfProxy from '../../IfProxy';
import IfProxyTool from '../../IfProxyTool';

import HeadFooter from '../HeadFooter';
import StraitLineSignupButton from '../../common/StraitLineSignupButton';
import ImageShareBtn from './../../common/ImageShareBtn';




// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

//graph
import RecordParcentGraph from "../../common/graphs/RecordParcentGraph";
import RecordParcentBarGraph from "../../common/graphs/RecordParcentBarGraph";
import { PanoramaFishEyeSharp } from "@material-ui/icons";
import RecordErrorBarGraph from "../../common/graphs/RecordErrorBarGraph";

import YouTube from 'react-youtube';


/**
 * ユーザ参照
 * @param  
 * @returns 
 */
const DiaryView = (props) => {
  const [loading, setLoading] = useState(true);
  // クリックされたイベントの情報をモーダルに渡す
  const [clickedEventData, setClickedEventData] = useState("");
  const [modalShow, setModalShow] = useState(false);

  // 日記リストデータ
  const [diaryData, setDiaryData] = useState({});


  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();



  const ifProxy = new IfProxy();
  const ifProxyTool = new IfProxyTool();

  // 画像からrgbを取得 現在中断
  // const sharp = require('sharp');
  // const getPixels = require('get-pixels');
  // const quantize = require('quantize');


  let first = true;
  useEffect((props) => {
    // 【useEffectの２重処理について】
    // https://zenn.dev/takeharu/scraps/d14cf9d4239ec4
    // useEffectは２回描画される
    // mount -> unmount -> mount
    // 処理がこのようにダブルのは開発環境だけ
    if (first) {
      setLoading(true)
      // ReactGA Analytics ---
      ReactGA.initialize("G-2ZCXE08H2S");
      ReactGA.send({
        hitType: "pageview",
        // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
        page: location.pathname + location.search,
      });
      // ------
      loadData();
    }

    //    console.log(first ? "１回目である" : "２回目である")
    //    console.log("回数評価処理は以下")
    //    console.log("開発環境２重処理防止のため、１回目、２回目判定フラグ")
    //    console.log(first)
    first = false;
    //    console.log("２重登録判定フラグ、更新後")
    //    console.log(first)

    return () => {
      //      console.log("UNMOUNT 開発環境２重処理防止のため、１回目、２回目判定フラグ")
      //      console.log(first)
    }

  }, []);


  /**
   * 画像分析
   */
  /* 実装を中断中。sharpの実行には M1は現状不可 nodeバージョン変更等が必要
  createPallet = async (imageFile) => {
    sharp('input.png')
      .raw()
      .toBuffer()
      .then(buffer => {
        getPixels(buffer, 'image/png', (err, pixels) => {
          if (err) {
            console.error(err);
            return;
          }

          const pixelArray = pixels.data;

          // ピクセルデータを一次元の配列にフラット化
          const flatPixelArray = [];
          for (let i = 0; i < pixelArray.length; i += 4) {
            // 画像はRGBA形式で、4つの要素からなります
            flatPixelArray.push(
              pixelArray[i], // Red
              pixelArray[i + 1], // Green
              pixelArray[i + 2], // Blue
              pixelArray[i + 3] // Alpha (透明度)
            );
          }

          // ピクセルデータを量子化してカラーパレットを生成
          const colorPalette = quantize(flatPixelArray, 5); // 上位5つのカラーを取得

          // 上位5つのカラーデータを取得
          const top5Colors = colorPalette.palette();

          // RGBコードを表示
          top5Colors.forEach(color => {
            console.log(`RGB: ${color.slice(0, 3)}`); // Alphaは無視してRGBのみ表示
          });
        });
      })
      .catch(err => {
        console.error(err);
      });

  }
  */

  // 時間の差を返す
  /**
   * @param {Date} 現在時刻
   * @param {Date} 比較対象時刻
   * @return {HH: number, MM: number, SS:number} 差分の　時、分、秒
   */
  const getDiffMMdd = (genzai, kaishi) => {
    if (!(genzai instanceof Date)) {
      throw new Error("データ方が違います genzai : " + genzai)
    }
    if (!(kaishi instanceof Date)) {
      throw new Error("データ方が違います kaishi : " + kaishi)
    }
    const gendaiTime = genzai.getTime();
    const kaishiTime = kaishi.getTime();
    const diffTime = gendaiTime - kaishiTime
    // MMHHSSを作るなら、MMはHHを６０で割った余りになるため％でMODする
    const HH = Math.floor(diffTime / (1000 * 60 * 60));
    const MM = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
    const SS = Math.floor((diffTime % (1000 * 60)) / 1000);
    return { HH, MM, SS };
  }
  const getTime = () => {
    // 引数
    const {
      step0Time,
      completeTime,
    } = diaryData.record;

    const turnAroundTime = getDiffMMdd(completeTime.toDate(), step0Time.toDate())
    const turnAroundTimeStr = turnAroundTime.HH + "時間" + turnAroundTime.MM + "分" + turnAroundTime.SS + "秒"
    return turnAroundTimeStr;
  }



  const loadData = async () => {
    const getData = await ifProxyTool.getDiary(params.id)

    setDiaryData(getData);
    setLoading(false)
  }



  const deleteDiary = async () => {
    await ifProxyTool.deleteDiary(params.id);
    navigate('/DiaryList')
  }


  const graphSection = () => {
    return (
      <>
        <RecordParcentGraph recordData={diaryData.record} />
      </>
    )
  }
  const graphBarGraphSection = () => {
    return (
      <>
        <RecordParcentBarGraph recordData={diaryData.record} />
      </>
    )
  }
  const graphErrorBarGraphSection = () => {
    return (
      <>
        <RecordErrorBarGraph recordData={diaryData.record} height={300} />
      </>
    )
  }




  const diaryView = () => {


    const {
      id,
      toUid,
      toUser,
      toIcon,
      privateView,
      diary,
      sumb,
      memoForNext,
      imageUrl,
      videoUrl,
      youtubeUrl,
      tag,
      tagString,
      onedroTag,
      onedroTagString,
      record,
      nsfw,

    } = diaryData;


    // 改行コード
    const LF = '%0A'
    const twitterShareUrl = "http://twitter.com/share?url=" + window.location.origin + '/DiaryView/' + params.id + `&text=ワンドロ日記${LF}今日のタイム：${diaryData && getTime()}&hashtags=ワンフロ`

    return (
      <>

        <Grid container className={classes.modalGrid} >
          {/** 0段目 ===================================================================== */}
          {/**ヘッダ */}
          <Grid item xs={6} >
            <Box className={classes.centerBox}>
              <Avatar
                src={toIcon}
                sx={{ width: 100, height: 100 }} />
              {toUser}
            </Box>
          </Grid>
          <Grid item xs={6} >
            <Box className={classes.centerBox}>
              #作品タグ {tagString.join()}<br></br>
              #ワンドロ企画 {onedroTagString.join()}<br></br>
              {nsfw ? "NSFW" : "一般"}<br></br>
              {privateView ? "非公開" : "公開"}<br></br>
            </Box>
          </Grid>


          {/** １段目 ===================================================================== */}
          {/** 画像とデータ */}
          <Grid item xs={6} >
            <Box className={classes.centerBox}>
              <img src={imageUrl} width={400} />
            </Box>
            <hr></hr>
          </Grid>
          {/** 2段目 ===================================================================== */}
          {/**分析 */}
          {/** 2段目 ===================================================================== */}

          <Grid item xs={6} >
            <Box className={classes.centerBox}>
              <Typography><b>手戻りの多い作業</b></Typography>
              {graphErrorBarGraphSection()}
            </Box>
          </Grid>
          <Grid item xs={12} >

            <Box className={classes.centerBox}>
              <Typography><b>作業比率</b></Typography>
              {graphSection()}
            </Box>
          </Grid>



          {/** 2段目 ===================================================================== */}

          <Grid item xs={1} >

          </Grid>
          <Grid item xs={10} >
            <Box className={classes.centerBox}>
              <Typography><b>各工程の所要時間</b></Typography>

              {graphBarGraphSection()}
            </Box>
          </Grid>

          <Grid item xs={1} >

          </Grid>



          {/** 2段目 ===================================================================== */}
          {/** 日記、ログ系 */}
          <Grid item xs={6} >
            <hr></hr>

            <Box className={classes.centerBox}>

              <Typography>日記</Typography>
              <Typography>{diary}</Typography>
            </Box>
          </Grid>
          {/** 3段目 ===================================================================== */}

          <Grid item xs={6} >
            <hr></hr>

            <Box className={classes.centerBox}>
              <Typography>次ワンドロをするときにやりたいこと、改善したいこと</Typography>
              <Typography>{memoForNext}</Typography>
            </Box>
          </Grid>
          {/** 4段目 ===================================================================== */}
          {/** 動画、リンク系 */}
          <Grid item xs={12} >
            {/** urlとあるが実際はid */}
            <a href={youtubeUrl}>Youtube {youtubeUrl}</a>
            {(youtubeUrl !== "" || youtubeUrl !== undefined) &&
              <YouTube videoId={youtubeUrl} />
            }

          </Grid>
          {/** 5段目 ===================================================================== */}
          <Grid item xs={12} >
            <a href={videoUrl}>チャレンジ動画 {videoUrl}</a>
          </Grid>


          {/** 4段目 ===================================================================== */}
          {/** フッター */}

          <Grid item xs={6} >
            <Box className={classes.centerBox}>

              {user && toUid === user.uid && <Button
                sx={{ margin: 5, height: 50, }}
                variant="contained"
                color="error"
                fullWidth
                onClick={() => {
                  deleteDiary()
                }}>
                削除
              </Button>}
            </Box>
          </Grid>
          <Grid item xs={6} >
            <Box className={classes.centerBox}>

              {user && toUid === user.uid && <Button
                sx={{ margin: 5, height: 50, }}
                variant="contained"
                color="info"
                fullWidth
                onClick={() => {
                  navigate('/Diary/' + params.id)
                }}>
                編集
              </Button>}
            </Box>
          </Grid>
          {/** ----------- */}
          <Grid item xs={4} >
            <Box className={classes.centerBox}>
              <Button
                sx={{ margin: 5, height: 50, }}
                variant="outlined"
                color="info"
                fullWidth
                onClick={() => {
                  navigate('/DiaryList')
                }}>
                戻る
              </Button>
            </Box>
          </Grid>
          <Grid item xs={4} >
            <Box className={classes.centerBox}>
              <Button
                sx={{ margin: 5, height: 50, color: "white" }}
                fullWidth
                onClick={() => {
                  navigate(`/User/${diaryData.toUid}`)
                }
                }
                variant="contained">
                この人にリクエストする
              </Button>
            </Box>
          </Grid>
          <Grid item xs={4} >
            <Box className={classes.centerBox}>

              <Button
                sx={{ margin: 5, height: 50, color: "white" }}
                fullWidth
                onClick={() => {
                  window.open(twitterShareUrl)
                }
                }
                variant="contained">
                <b>シェアする</b> <img width="50" src={`${process.env.PUBLIC_URL}/twitterIcon.png`} alt="twitterLogo"></img>
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} >
            {diaryData && <ImageShareBtn imageBlobData={sumb} message={
              window.location.origin + '/DiaryView/' + params.id + " " + "今日のワンドロ " + "タイム:" + diaryData && getTime() + " #ワンフロ"
            } setResult={() => {
              alert('投稿します')
            }}></ImageShareBtn>}
          </Grid>



        </Grid >

      </>
    )


  }
  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ


  if (loading) {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Box>
        </Box>
      </HeadFooter>
    )
  } else {

    const twitterShareUrl = "http://twitter.com/share?url=" + window.location.origin + '/User/' + params.id + "&text=ワンドロテーマを募集中&hashtags=ワンフロ"

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      < HeadFooter >

        <Box className={classes.contentWrapper}>
          {diaryData && diaryView()}

        </Box>

      </HeadFooter >

    );
  }




}
export default DiaryView;

const style = {
  scoreLabel: {
    fontSize: 25,

  },
}

const useStyles = makeStyles((theme) => ({
  //オーバーレイ
  overlay: {
    zIndex: 10,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },//オーバーレイの中のモーダル
  modalView: {
    zIndex: 10,
    borderRadius: 15,
    width: '80%',
    padding: '1em',
    background: '#fff',
    overflow: 'scroll'
  },
  // 全体レイアウト
  contentWrapper: {
    marginTop: 20,
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",

  },
  // グリッド箱の書式 

  gridPadding: {
    padding: 5,
    border: '2px solid',
    borderColor: '#a6d4fa',
    backgroundColor: 'linear-gradient(to bottom right,#a6d4fa,#1976d2)',
    borderRadius: 10

  },
  // グリッド箱の書式 枠線なし
  gridPaddingNoborder: {
    padding: 5,


  },
  // カードの中のGrid
  gridCard: {
    margin: 5,
    padding: 5,
    border: '2px solid',
    borderRadius: 10

  },
  // 個別コンテンツ　中央揃えで縦に内容を並べる
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },// 個別コンテンツ　中央揃えで横に内容を並べる
  centerFlatBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftCenterBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftRowBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
  },
  // 個別コンテンツ　横並びに内容を並べる
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },



  // モーダルダイアログのフォーム　個別アイテム
  modalFormItem: {
    margin: 20,

  },
  // モーダルのグリッド全体
  modalGrid: {
    maxWidth: 1000,
    padding: 0,
    margin: '0 auto'

  },
  //サンプルのグラフエリア
  sampleGraphEria: {
    width: 200
  },

  cardList: {
  },
  card: {
  },
  bottomCard: {
    width: 250
  },
}));


