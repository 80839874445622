import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import IfProxy from '../../IfProxy';
import IfProxyStripe from '../../IfProxyStripe';
import { useAuthContext } from '../../AuthContext';






const cardHeight = 300;
const imageHeight = 150;
const cardMargin = 5;



/**
 * ユーザ一覧などで表示するアカウントの情報をまとめたカード
 * @property { record, height }
 * @returns 
 * 
 * 募集状況status　募集中/受付停止中
 * 
 * <要件>
 * アバターを出す
 * ヒーローイメージを背景に出す
 * クリックしたらプロフィールに遷移する
 * 環境に応じて幅を調整する
 * propで受け取ったユーザデータで構成する
 */
const Admax = (props) => {

    const navigate = useNavigate();
    const classes = useStyles();
    const ifProxy = new IfProxy();
    const ifProxyStripe = new IfProxyStripe();
    const { user } = useAuthContext();

    const [premiumFlg, setPremiumFlg] = useState("");



    useEffect(() => {



        // windowオブジェクトの広告リストを初期化
        if (!window["admaxads"])
            window["admaxads"] = [];
        // 広告リストを取得
        const admaxads = window["admaxads"];
        // 広告リストになかったら追加
        if (!admaxads.some(ad => ad.admax_id === props.id))
            admaxads.push({
                admax_id: props.id,
                tag_id: '1299c482fd54238c204b4ae26b3d3808',
                type: "b",
                width: 728,
                height: 90
            });
        // 外部JSを読み込んで広告リストを実際に表示
        const tag = document.createElement('script');
        //tag.src = 'https://adm.shinobi.jp/st/t.js';
        tag.src = 'https://adm.shinobi.jp/st/s.js'
        tag.type = 'text/javascript'
        tag.charset = 'utf-8'
        tag.async = true;
        document.body.appendChild(tag);



        // アンマウント時にJSタグと広告情報を削除
        return () => {
            document.body.removeChild(tag);
            const index = admaxads.findIndex(ad =>
                ad.admax_id === props.id);
            if (index !== -1) {
                admaxads.splice(index, 1);
            }
            window["__admax_tag__"] = undefined;
        }
    }, []);

    return (
        <div>

            <div
                className="admax-switch"
                data-admax-id={props.id}
                style={{ display: "inline-block" }}
            />
        </div >
    )



}

export default Admax;


const useStyles = makeStyles((theme) => ({

    card: {
        maxWidth: "300px",
    }, leftCenterBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        height: 200,
    },



}));
