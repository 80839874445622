import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, getDoc, query, deleteDoc, updateDoc, where, addDoc, serverTimestamp, limit, orderBy, startAt, endAt, Timestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, TwitterAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult, updateProfile, EmailAuthProvider, linkWithCredential, } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import Resizer from "react-image-file-resizer";

import { getFunctions, httpsCallable } from "firebase/functions";



// アプリ全体のFirebase設定 起動直後最初に一回読み込まれる
// APIキーを git管理から外したファイルに設置。これはpushしない
import apiKey from './apikey.json';

// APIキー等の設定情報を取得 起動直後最初に一回読み込まれる
const firebaseConfig = apiKey.firebase;

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();
const provider = new TwitterAuthProvider();
const functions = getFunctions(app);

const DAY = Object.freeze({
  STEP1: '1',
  STEP2: '2',
  STEP3: '3',
  STEP4: '4',
})

// 次やることメッセージ
const TUTORIALMASSAGE = Object.freeze({
  STEP1_USER_TUTORIAL: '【まずはここから】作家さんを応援、ファンとして参加するための使い方',
  STEP2_REQUESTER_TUTORIAL: '次は、「リクエスト」を行えるようになりましょう',
  STEP3_CRATOR_TUTORIAL: '次は、まずは無料から。クリエイター登録しリクエストを受け付ける',
  STEP4_NIKKI_TUTORIAL: '次は、ワンドロ専用タイマーと、ワンドロ日記を使ってみる',
  STEP5_CALENDOR_TUTORIAL: '次は、ワンドロカレンダーで、参加したい企画を探す',
  STEP_CLEARED: 'STEP_CLEARED'
})

// objectの項目一覧。引数バリデーション
// エンティティのスキーマを変える時はここもかえないとバリデーションルールが古いままになる
const diaryParam = [
  "toUid",
  "toUser",
  "toIcon",
  "privateView",
  "diary",
  "memoForNext",
  "imageUrl",
  "videoUrl",
  "youtubeUrl",
  "tag",
  "tagString",
  "onedroTag",
  "onedroTagString",
  "record",
];

const recordParam = [
  "step0Time",
  "step1",
  "step1Time",
  "step1FixCount",
  "step2",
  "step2Time",
  "step2FixCount",
  "step3",
  "step3Time",
  "step3FixCount",
  "step4",
  "step4Time",
  "step4FixCount",
  "completeTime",
  "uid"
]

const recordTrnParam = [
  "uid",
  "step1Name",
  "step2Name",
  "step3Name",
  "step4Name",
  "memoForNext",
  "memo",
]

const statusParamChk = [
  "STEP1_USER_TUTORIAL",
  "STEP2_REQUESTER_TUTORIAL",
  "STEP3_CRATOR_TUTORIAL",
  "STEP4_NIKKI_TUTORIAL",
  "STEP5_CALENDOR_TUTORIAL",
  "STEP_CLEARED",
]


class IfProxyTu {
  getUserData(uid) {
    return new Promise(async (resolve) => {
      let userData = {};
      const ref = collection(db, "UserData");
      const q = await query(ref, where("uid", "==", uid))
      const querySnapshot = await getDocs(q);
      let result = []


      try {
        if (querySnapshot.size == 0) {
          console.log("user data not exist 新規ユーザ")
          resolve(result);
        } else {
          await querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            let res = doc.data();
            res.id = doc.id;
            result.push(res);
          })
          resolve(result[0]);
        }
      } catch (e) {
        console.log("データ取得に失敗")
        console.log(e)
        resolve(e);
      }
    });
  }

  /**
   * ユーザのチュートリアルステータスを更新する
   * @param {*} id 
   * @param {*} status 
   * @returns 
   */
  updateUserStatus(id, status) {
    if (!statusParamChk.includes(status)) {
      console.log("ステータスが存在しない値となっています: :", status);
      throw new Error("updateUserStatus 引数誤り ステータスが存在しない値となっています:" + status);
    }

    return new Promise(async (resolve, reject) => {
      try {

        let userData = {}
        const document = doc(db, "UserData", id);
        userData.nextStep = TUTORIALMASSAGE[status]
        userData.updatedAt = serverTimestamp()
        await updateDoc(document, userData);
        resolve();


      } catch (e) {
        console.log("データ登録に失敗");
        console.log(e)
        resolve(e);
      }

    })
  }


  /**無料限定リクエストの解放
   * 有料リクエストもこのフラグはついている。有料はこれ&& StripeId
   * @param {*} userData
   * @returns 
   */
  updateUserRemitedCreator(userData) {
    console.log(userData)
    console.log(userData.remitedCreator)
    console.log(userData.remitedCreator === true)

    if (userData.remitedCreator) return;
    return new Promise(async (resolve, reject) => {
      try {

        let updateUserData = {
          remitedCreator: true,
          updatedAt: serverTimestamp()
        }
        const document = doc(db, "UserData", userData.id);
        await updateDoc(document, updateUserData);
        resolve();


      } catch (e) {
        console.log("データ登録に失敗");
        console.log(e)
        resolve(e);
      }

    })
  }

  activateFirstFav = async (uid) => {
    const userData = await this.getUserData(uid);
    if (userData.tu_2_firstfavorit) return;
    this.activateTu_2_firstfavorit(userData.id)
  }
  activateFirstShare = async (uid) => {
    const userData = await this.getUserData(uid);
    if (userData.tu_3_firstshare) return;
    this.activateTu_3_firstshare(userData.id)
  }
  activateFirstFav = async (uid) => {
    const userData = await this.getUserData(uid);
    if (userData.tu_4_firstPoll) return;
    this.activateTu_4_firstPoll(userData.id)
  }
  // いいね５０から変更、ファボ10へ、いいねはfromUidがないため数えられない
  checkActivateFav10 = async (user) => {
    const userData = await this.getUserData(user.uid);
    console.log("~~~~~~~~~~~~~~~~~~^")
    console.log(userData)
    if (userData.tu_5_firstfavorit50) return;
    // 現在のファボ数チェック
    const ref = collection(db, "fav");
    // ログインユーザがブロックされているリストを取得し統合
    const qed = await query(ref, where("fromUid", "==", userData.uid));
    const querySnapshoted = await getDocs(qed);
    if (querySnapshoted.size > 9) {
      this.activateTu_5_firstfavorit50(userData.id)

    }

  }

  // お題投稿済みチェック
  checkFirstOdai = async (userData) => {
    if (userData.tu_7_firstFreeRequest) return;
    // 現在のファボ数チェック
    const ref = collection(db, "Odai");
    // ログインユーザがブロックされているリストを取得し統合
    const qed = await query(ref, where("fromUid", "==", userData.uid));
    const querySnapshoted = await getDocs(qed);
    if (querySnapshoted.size > 0) {
      this.activateTu_7_firstFreeRequest(userData.id)
    }
  }
  // リクエスト投稿済みチェック
  checkFirstRequest = async (userData) => {
    if (userData.tu_10_firstRequest) return;
    // 現在のファボ数チェック
    const ref = collection(db, "Request");
    // ログインユーザがブロックされているリストを取得し統合
    const qed = await query(ref, where("fromUid", "==", userData.uid));
    const querySnapshoted = await getDocs(qed);
    if (querySnapshoted.size > 0) {
      this.activateTu_10_firstRequest(userData.id)
    }
  }
  // クリエイター登録済みチェック
  checkCreatorUser = async (userData) => {
    if (userData.stripeId === "" || userData.stripeId === undefined) {
      alert("クリエイター登録がされていません。プロフィールのクリエイター登録ボタンからStripe口座を開設してください")
    } else {
      this.activateTu_21_stripeEnabled(userData.id)
    }

  }

  // お題への提案投稿済みチェック
  checkFirstOdaiTeian = async (userData) => {
    console.log(userData.tu_14_GetFirstFreeRequest)
    if (userData.tu_14_GetFirstFreeRequest) return;
    // 現在のファボ数チェック
    const ref = collection(db, "OdaiTeian");
    // ログインユーザがブロックされているリストを取得し統合
    const qed = await query(ref, where("toUid", "==", userData.uid));
    const querySnapshoted = await getDocs(qed);
    if (querySnapshoted.size > 0) {
      this.activateTu_14_GetFirstFreeRequest(userData.id)
    }
  }

  /**
   * 解放フラグ_基本的な使い方ユーザガイドを読む
   */
  activateTu_1_firstuserguide = (id) => {
    this.activateTutorial(id, { tu_1_firstuserguide: true })
  }

  /**
   * 解放フラグ_ファボする
   */
  activateTu_2_firstfavorit = (id) => {
    this.activateTutorial(id, { tu_2_firstfavorit: true })
  }
  /**
   * 解放フラグ_シェアする
   */
  activateTu_3_firstshare = (id) => {
    this.activateTutorial(id, { tu_3_firstshare: true })
  }
  /**
   * 解放フラグ_気になる作家、読む
   */
  activateTu_4_firstPoll = (id) => {
    this.activateTutorial(id, { tu_4_firstPoll: true })
  }

  /**
   * 解放フラグ_ファボする50
   */
  activateTu_5_firstfavorit50 = (id) => {
    this.activateTutorial(id, { tu_5_firstfavorit50: true })
  }
  /**
   * 解放フラグ_ファン達成
   */
  activateTu_5_1_graduate = (id) => {
    this.activateTutorial(id, { tu_5_1_graduate: true })
  }
  /**
   * 解放フラグ_フリーリクエスト（お題）機能でできることの理解
   */
  activateTu_6_firstFreeRequestGuide = (id) => {
    this.activateTutorial(id, { tu_6_firstFreeRequestGuide: true })
  }
  /**
   * 解放フラグ_フリーリクエスト（お題を投稿）をする
   */
  activateTu_7_firstFreeRequest = (id) => {
    this.activateTutorial(id, { tu_7_firstFreeRequest: true })
  }
  /**
   * 解放フラグ_リクエストのルールを理解する
   */
  activateTu_8_firstRequestGuide = (id) => {
    this.activateTutorial(id, { tu_8_firstRequestGuide: true })
  }
  /**
   * 解放フラグ_リクエストの要望を投稿
   */
  activateTu_9_requestIssue = (id) => {
    this.activateTutorial(id, { tu_9_requestIssue: true })
  }
  /**
   * 解放フラグ_リクエストをする
   */
  activateTu_10_firstRequest = (id) => {
    this.activateTutorial(id, { tu_10_firstRequest: true })
  }
  /**
   * 解放フラグ_クリエイター登録をする
   */
  activateTu_11_registration = (id) => {
    this.activateTutorial(id, { tu_11_registration: true })
  }
  /**
   * 解放フラグ_リクエストのルールを理解する
   */
  activateTu_12_getFirstRequestGuide = (id) => {
    this.activateTutorial(id, { tu_12_getFirstRequestGuide: true })
  }
  /**
   * 解放フラグ_フリーリクエストの受け方ガイドを読む
   */
  activateTu_13_GetFirstFreeRequestGuide = (id) => {
    this.activateTutorial(id, { tu_13_GetFirstFreeRequestGuide: true })
  }
  /**
   * 解放フラグ_フリーリクエスト（お題）に反応する
   */
  activateTu_14_GetFirstFreeRequest = (id) => {
    this.activateTutorial(id, { tu_14_GetFirstFreeRequest: true })
  }
  /**
   * 解放フラグ_リクエスト・フリーリクエストの要望を投稿
   */
  activateTu_15_getRequestIssue = (id) => {
    this.activateTutorial(id, { tu_15_getRequestIssue: true })
  }
  /**
   * 解放フラグ_日記ガイドを読む
   */
  activateTu_16_firstDiary = (id) => {
    this.activateTutorial(id, { tu_16_firstDiary: true })
  }
  /**
   * 解放フラグ_日記をシェアする
   */
  activateTu_17_firstDiaryShare = (id) => {
    this.activateTutorial(id, { tu_17_firstDiaryShare: true })
  }
  /**
   * 解放フラグ_日記・タイマーへの要望を投稿
   */
  activateTu_18_diaryIssue = (id) => {
    this.activateTutorial(id, { tu_18_diaryIssue: true })
  }
  /**
   * 解放フラグ_ワンドロカレンダーを見る
   */
  activateTu_19_firstOnedroCalender = (id) => {
    this.activateTutorial(id, { tu_19_firstOnedroCalender: true })
  }
  /**
   * 解放フラグ_ワンドロカレンダーの要望を投稿
   */
  activateTu_20_onedroCalenderIssue = (id) => {
    this.activateTutorial(id, { tu_20_onedroCalenderIssue: true })
  }
  /**
   * 解放フラグ_口座開設する(限定解除）
   */
  activateTu_21_stripeEnabled = (id) => {
    this.activateTutorial(id, { tu_21_stripeEnabled: true })
  }

  activateTutorial = (id, userData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const document = doc(db, "UserData", id);

        userData.updatedAt = serverTimestamp()
        await updateDoc(document, userData);
        resolve();
      } catch (e) {
        console.log("データ登録に失敗");
        console.log(e)
        resolve(e);
      }

    })
  }

}







export default IfProxyTu;
