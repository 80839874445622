import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from './../../AuthContext';

// TODO: StandardPageはパスが異なるため注意
import IfProxy from './../../IfProxy';
import HeadFooter from './../HeadFooter';
import SnsUtil from './../../common/SnsUtil';
import AccountCard from './../../common/AccountCard';

// ReactGA Analytics
import ReactGA from "react-ga4";


// Material UI
import { makeStyles } from '@mui/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const CreatorGuide = (props) => {

  const [listItems, setListItems] = useState(0);

  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const ifProxy = new IfProxy();

  const location = useLocation();

  useEffect((props) => {
    loadData(props);
    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    // ------
  }, []);


  const loadData = (props) => {


    // データコール
    //ifProxy.getDetailData().then((result) => {

    //  setListItems(result);
    //})
  }



  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else {

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox} >
            クリエイターガイド
          </Box>
        </Box>

      </HeadFooter >

    );
  }




}
export default CreatorGuide;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト　中心揃えにする
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  // コンテンツの幅。端末ごとに広がりすぎるのを防ぐ。左揃え
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",
  },
  // 個別コンテンツ　中央揃えで縦に内容を並べる
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  // 個別コンテンツ　横並びに内容を並べる
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },
  cardList: {
  },
  card: {
  },
}));
