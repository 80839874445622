import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from './../../AuthContext';
import IfProxy from './../../IfProxy';
import HeadFooter from './../HeadFooter';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const ApprovalCommentList = (props) => {

  const [listItems, setListItems] = useState(0);
  const [cardList, setCardList] = useState(0);

  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();

  const location = useLocation();

  useEffect((props) => {
    loadData(props);
    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    // ------
  }, []);

  const handleLogout = () => {
    auth.signOut();
    navigate('/login');
  };

  const loadData = (props) => {
    console.log("画面遷移後＝ params ＝＝＝＝＝＝＝＝＝＝")
    console.log(params);
    console.log(location);
    const ifProxy = new IfProxy();
    ifProxy.getDetailData().then((result) => {
      // paramsの値でユーザを検索

      // ユーザ情報を表示
      let item = {
        title: "TEST TITLE NAME",
        id: "1234567890",
        createUser: "createUser",
        uid: "1234567890",
        text: "lkasdjf;lajskdf;aksdjf;alsjkdf;klasjdf;l"
      }
      setListItems(item);
      getCardList();
    })
  }

  // ポートフォリオリスト
  const getCardList = () => {
    const ifProxy = new IfProxy();
    ifProxy.getData().then((result) => {
      /*
      let listItems = result.map((item) =>
        <li>{item.no} : {item.text}</li>
      );


      let listItems = result.map((item) =>
      //  createCard(item)
      // 一旦もらったitemはむし

      );
      */
      const item = [
        {
          no: 1,
          text: "text",
          requested: true
        },
        {
          no: 2,
          text: "text",
          requested: false
        },
        {
          no: 3,
          text: "text",
          requested: false
        },
      ];
      let listItems = item.map((item) =>
        createCard(item)
      );
      setCardList(listItems);
    })
  }



  const createCard = (item) => {
    if (item.requested == true) {
      return (
        <Grid item xs={11} sm={11} md={11} >
          <Card className={classes.bottomCard} onClick={() => onClickCard("test111111")}>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                データ番号: {item.no}
              </Typography>
              <Typography sx={{ fontSize: 14 }} component="div">
                testtesttesttesttesttesttesttesttesttesttesttest
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                color="secondary">
                受注する
              </Button>
            </CardActions>
          </Card>
        </Grid>
      )
    } else {
      return (
        <Grid item xs={11} sm={11} md={11} >
          <Card className={classes.bottomCard} onClick={() => onClickCard("test111111")}>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                no: {item.no}
              </Typography>
              <Typography sx={{ fontSize: 14 }} component="div">
                交渉テキスト内容。リクエストテキスト内容
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )
    }
  }
  const onClickCard = (id) => {
    console.log("カードがクリックされた")
    navigate('/Detail/' + id);
  }

  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else {

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <Grid container justify="center" spacing={3} sx={{ marginTop: 2 }} columns={{ xs: 12, sm: 12, md: 12 }}>
              <Grid item xs={12} sm={12} md={8} >
                <Card className={classes.card}>
                  <CardContent>

                    <Avatar sx={{ width: 100, height: 100 }} />
                    <h1>相手のユーザ名</h1>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      データ番号:{listItems.id}
                    </Typography>
                    <Typography variant="body2">
                      プロフィールテキストプロフィールテキストプロフィールテキストプロフィールテキストプロフィールテキストプロフィールテキストプロフィールテキスト
                      <br />
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small">Twitter</Button>
                    <Button size="small">Instagram</Button>
                    <Button size="small">Pixiv</Button>
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to="/UserEdit/asdfasdfsa"
                      sx={{ margin: 2 }}>
                      編集
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4} >
                <Box sx={{
                  padding: 2,
                  boxShadow: 1,
                  alignItems: 'center'

                }}>
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    先方ユーザの詳細 ここどうするべきか。むしろ、リクエスト内容をここに掲載するべきか
                  </Typography>
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    ジャンル：
                  </Typography>
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    平均納期：
                  </Typography>
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    納期達成率：
                  </Typography>
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    平均受注金額：
                  </Typography>
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    最低受注金額：
                  </Typography>
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ margin: 2 }}
                    className={classes.followButton}>
                    フォローする
                  </Button>

                </Box>
              </Grid>
            </Grid>

            <Grid container justify="center" spacing={3} sx={{ marginTop: 2 }} columns={{ xs: 12, sm: 12, md: 12 }}>
              {cardList}
              <Grid item xs={12} sm={7} md={7} >
                <TextField
                  id="outlined-multiline-static"
                  label="コメント"
                  multiline
                  rows={4}
                  defaultValue="コメント..."
                />
              </Grid>
              <Grid item xs={12} sm={5} md={5} >
                <Button
                  variant="outlined"
                  color="secondary"
                  className={classes.followButton}>
                  投稿する
                </Button>
              </Grid>


            </Grid>



            <h2>
              {params.id}
            </h2>
          </Box>


        </Box>


      </HeadFooter>

    );
  }




}
export default ApprovalCommentList;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
  },
  mainBox: {

  },
  cardList: {
  },
  card: {
  },
  bottomCard: {
  },
}));
