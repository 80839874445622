import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import IfProxyCalender from '../../IfProxyCalender';
import IfProxyDefault from '../../IfProxy';

import HeadFooter from '../HeadFooter';
import StraitLineSignupButton from '../../common/StraitLineSignupButton';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

//graph
import VictorySample from "../../common/graphs/VictorySample";

import EventCard from "./EventCard";


const Week = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();

    // クリックされたイベントの情報をモーダルに渡す
    const [clickedEventData, setClickedEventData] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [eventType, setEventType] = useState("kikaku");

    const DAY = Object.freeze({
        MON: 0,
        THE: 1,
        WED: 2,
        THU: 3,
        FRI: 4,
        SAT: 5,
        SUN: 6,
        ANI: 7, //記念日系
        EIG: 8, // 日程指定なし
    })

    const calender = props.calender

    let first = true;
    useEffect((props) => {
        if (first) {



        }
        return () => {
        }

    }, []);


    // 今日が記載の曜日と同じ
    const todayFlg = (day) => {
        var date = new Date();
        var dayOfWeek = date.getDay()
        var dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek]
        return day === dayOfWeekStr
    }
    // リスト要素をデータを入れて、リストにする
    const itemListView = (item) => {
        if (!item) return;

        const itemListComp = item.map((data, index) => {
            return (
                <>
                    <Box
                        key={`eventCaldList_${data.id}`}
                        onClick={() => {
                            setClickedEventData(data)
                            setEventType("eventView")
                            setModalShow(true)
                        }}
                    >
                        <EventCard key={`eventcard_${data.id}`} item={data} ></EventCard>
                    </Box >
                </>
            )
        })

        return (
            <>
                {itemListComp}
            </>
        )
    }

    return (
        <>
            <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
                今週のワンドロ企画予定
            </Typography>
            <Box className={classes.weekList}>
                <Box className={classes.weekDayName}>
                    <Typography fullwidth={true} sx={{ fontSize: 50, color: todayFlg("月") ? 'blue' : 'black' }}>
                        月
                    </Typography>
                    <Typography fullwidth={true} sx={{ fontSize: 17, color: todayFlg("月") ? 'blue' : 'black' }}>
                        {todayFlg("月") ? ' (今日開催!)' : ''}
                    </Typography>
                </Box>
                <Box className={classes.weekDayList}>
                    {itemListView(calender.MON)}
                </Box>
            </Box>

            <Box className={classes.weekList}>
                <Box className={classes.weekDayName}>
                    <Typography sx={{ fontSize: 50, color: todayFlg("火") ? 'blue' : 'black' }}>
                        火
                    </Typography>
                    <Typography sx={{ fontSize: 17, color: todayFlg("火") ? 'blue' : 'black' }}>
                        {todayFlg("火") ? ' (今日開催!)' : ''}
                    </Typography>
                </Box>
                <Box className={classes.weekDayList}>
                    {itemListView(calender.THE)}
                </Box>
            </Box>

            <Box className={classes.weekList}>
                <Box className={classes.weekDayName}>
                    <Typography sx={{ fontSize: 50, color: todayFlg("水") ? 'blue' : 'black' }}>
                        水
                    </Typography>
                    <Typography sx={{ fontSize: 17, color: todayFlg("水") ? 'blue' : 'black' }}>
                        {todayFlg("水") ? ' (今日開催!)' : ''}
                    </Typography>
                </Box>
                <Box className={classes.weekDayList}>
                    {itemListView(calender.WED)}
                </Box>
            </Box>

            <Box className={classes.weekList}>
                <Box className={classes.weekDayName}>
                    <Typography sx={{ fontSize: 50, color: todayFlg("木") ? 'blue' : 'black' }}>
                        木
                    </Typography>
                    <Typography sx={{ fontSize: 17, color: todayFlg("木") ? 'blue' : 'black' }}>
                        {todayFlg("木") ? ' (今日開催!)' : ''}
                    </Typography>
                </Box>
                <Box className={classes.weekDayList}>
                    {itemListView(calender.THU)}
                </Box>
            </Box>

            <Box className={classes.weekList}>
                <Box className={classes.weekDayName}>
                    <Typography sx={{ fontSize: 50, color: todayFlg("金") ? 'blue' : 'black' }}>
                        金
                    </Typography>
                </Box>
                <Typography sx={{ fontSize: 17, color: todayFlg("金") ? 'blue' : 'black' }}>
                    {todayFlg("金") ? ' (今日開催!)' : ''}
                </Typography>
                <Box className={classes.weekDayList}>
                    {itemListView(calender.FRI)}
                </Box>
            </Box>

            <Box className={classes.weekList}>
                <Box className={classes.weekDayName}>
                    <Typography sx={{ fontSize: 50, color: todayFlg("土") ? 'blue' : 'black' }}>
                        土
                    </Typography>
                    <Typography sx={{ fontSize: 17, color: todayFlg("土") ? 'blue' : 'black' }}>
                        {todayFlg("土") ? ' (今日開催!)' : ''}
                    </Typography>
                </Box>
                <Box className={classes.weekDayList}>
                    {itemListView(calender.SAT)}
                </Box>
            </Box>

            <Box className={classes.weekList}>
                <Box className={classes.weekDayName}>
                    <Typography sx={{ fontSize: 50, color: todayFlg("日") ? 'blue' : 'black' }}>
                        日
                    </Typography>
                    <Typography sx={{ fontSize: 17, color: todayFlg("日") ? 'blue' : 'black' }}>
                        {todayFlg("日") ? ' (今日開催!)' : ''}
                    </Typography>
                </Box>
                <Box className={classes.weekDayList}>
                    {itemListView(calender.SUN)}
                </Box>
            </Box>

            <Box className={classes.weekList}>
                <Box className={classes.weekDayName}>
                    <Typography sx={{ fontSize: 20 }}>
                        曜日指定なし
                    </Typography>
                </Box>
                <Box className={classes.weekDayList}>
                    {itemListView(calender.EIG)}
                </Box>
            </Box>
        </>
    )

}
export default Week;

const useStyles = makeStyles((theme) => ({
    // 日記のレイアウト ------------------------------------
    // 曜日表の書式。全体
    weekList: {
        display: 'flex',
        justifyContent: 'flexStart',
        padding: 10,
        borderBottom: 'dashed lightBlue'

    },
    // 曜日の名前ラベル
    weekDayName: {
        width: '100px',
        textAlign: 'center',

    },
    // リストの内容
    weekDayList: {
        flex: 1
    },

}));