import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';


// グラフコンポーネント
import { VictoryLabel, VictoryPie, VictoryAnimation } from 'victory';


const TutorialProgressGraph = ({ progress }) => {

    const navigate = useNavigate();


    // サンプル
    //https://formidable.com/open-source/victory/gallery/animating-circular-progress-bar


    return (
        <>
            <div>
                <svg viewBox="0 0 400 400" width="100%" height="100%">
                    <VictoryPie
                        standalone={false}
                        animate={{ duration: 1000 }}
                        width={400} height={400}
                        data={[{ x: 1, y: progress }, { x: 2, y: 100 - progress }]}
                        innerRadius={120}
                        cornerRadius={50}
                        labels={() => null}
                        style={{
                            data: {
                                fill: ({ datum }) => {
                                    const color = datum.y > 30 ? "green" : "red";
                                    return datum.x === 1 ? color : "transparent";
                                }
                            }
                        }}
                    />
                    <VictoryAnimation duration={1000} data={[{ x: 1, y: progress }, { x: 2, y: 100 - progress }]}>
                        {() => {
                            return (
                                <VictoryLabel
                                    textAnchor="middle" verticalAnchor="middle"
                                    x={200} y={200}
                                    text={`${Math.round(progress)}%`}
                                    style={{ fontSize: 70 }}
                                />
                            );
                        }}
                    </VictoryAnimation>
                </svg>
            </div>
        </>
    )
}

export default TutorialProgressGraph;

