import React, { useState, useEffect } from 'react';
//import './App.css';
import Button from '@mui/material/Button';
import IfProxyStripe from '../../IfProxyStripe';
import { useAuthContext } from '../../AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

// Action =====
const createSubscription = () => {
    const ifProxyStripe = new IfProxyStripe();
    ReactGA.event("primeuser_purchased");

    console.log("createSubscription ＝＞＞＞ PRE")

    let result = window.confirm("決済ページに移動しプレミアム機能を有効かします")
    if (result) {
        //IfProxyStripe
        ifProxyStripe.createSubscription({
            type: 1
            //          lookup_key: 'price_1NZV45FI1Zt23Yf7LOVlf2Fs' //画面からprice_を取得 TODO コード下手うちのため、環境変更時は注意
        }).then(async (result) => {
            console.log("createSubscription ＝＞＞＞＞RESULT")

            // 戻りにcustomer_idある？あるなら、それがサブスクのキー
            // 状態チェックもそのキーで
            // sessionだけでは不十分？
            // sessionはwebhookで取れない？(取れるなら全部sessionで行ける？)

            console.log(result)
            console.log("URL====")
            console.log(result.data.result);
            console.log(result.data.result.result.url);
            // function subscriptionCheckoutImpl　でsessionIdをUserDataに持たせている

            window.open(result.data.result.result.url)
        }).catch((e) => {
            console.log("createSubscription 失敗")
            console.log(e)
        })
    }
}
// 管理画面へ
const managementSubscription = (sessionId) => {
    const ifProxyStripe = new IfProxyStripe();
    console.log("createSubscription ＝＞＞＞ PRE")
    console.log(sessionId)
    //IfProxyStripe
    ifProxyStripe.manageSubscription({
        sessionId
    }).then((result) => {
        console.log("createSubscription ＝＞＞＞＞RESULT")
        console.log(result)
        console.log("URL====")
        console.log(result.data.result);
        console.log(result.data.result.url);
        window.open(result.data.result.url)
    })

}

// View

const ProductDisplay = () => {
    const navigate = useNavigate();
    return (

        <>

            <div className="product">
                <div className="description">
                    <h3>プレミアムプラン basic</h3>
                    <h5>¥500 / 月</h5>
                </div>
            </div>
            <Button variant='contained' onClick={() => createSubscription()}>
                決済する
            </Button>
            <Button variant='outlined' onClick={() => navigate('/UserDashbord')}>
                ダッシュボードへ戻る
            </Button>

        </>
    )
}

const SuccessDisplay = ({ sessionId }) => {
    const ifProxyStripe = new IfProxyStripe();
    const navigate = useNavigate();


    // TODO この時のサーバ戻り値
    // session_id は、customer_id
    // はdb保存し、識別する
    return (
        <>
            <div className="product">
                <div className="description">
                    <h3>プレミアム basicプラン 登録に成功しました</h3>
                </div>
            </div>
            <Button variant='outlined' onClick={() => managementSubscription(sessionId)}>
                支払い情報の変更
            </Button>
            <Button variant='contained' onClick={() => navigate('/UserDashbord')}>
                ダッシュボードへ戻る
            </Button>
        </>
    );
};

const Message = ({ message }) => (
    <section>
        <p>{message}</p>
    </section>
);

const UserSubscribe = () => {
    let [message, setMessage] = useState('');
    let [success, setSuccess] = useState(false);
    let [sessionId, setSessionId] = useState('');
    const classes = useStyles();
    const location = useLocation();

    useEffect(() => {
        // ReactGA Analytics ---
        ReactGA.initialize("G-2ZCXE08H2S");
        ReactGA.send({
            hitType: "pageview",
            // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
            page: location.pathname + location.search,
        });
        // ------
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get('success')) {
            setSuccess(true);
            setSessionId(query.get('session_id'));
        }

        if (query.get('canceled')) {
            setSuccess(false);
            setMessage(
                "キャンセルします"
            );
        }
    }, [sessionId]);

    if (!success && message === '') {
        return (
            <>
                <Box className={classes.contentWrapper}>
                    <Box className={classes.centerBox}>
                        < ProductDisplay />
                    </Box>
                </Box>
            </>
        )
    } else if (success && sessionId !== '') {
        return (
            <>
                <Box className={classes.contentWrapper}>
                    <Box className={classes.centerBox}>
                        <SuccessDisplay sessionId={sessionId} />
                    </Box>
                </Box>
            </>
        )

    } else {
        return (
            <>
                <Box className={classes.contentWrapper}>
                    <Box className={classes.centerBox}>
                        <Message message={message} />
                    </Box>
                </Box>
            </>
        );
    }
}

export default UserSubscribe

const useStyles = makeStyles((theme) => ({
    // 全体レイアウト
    contentWrapper: {
        marginTop: 20,
        flexDirection: 'column',
        alignItems: 'center',
    },
    mainBox: {
        maxWidth: "1000px",
        margin: "0 auto",

    },
    gridPadding: {
        padding: 5,
        border: '2px solid',
        borderColor: '#a6d4fa',
        backgroundColor: 'linear-gradient(to bottom right,#a6d4fa,#1976d2)',
        borderRadius: '10'

    },
    // 個別コンテンツ　中央揃えで縦に内容を並べる
    centerBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftCenterBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    // 個別コンテンツ　横並びに内容を並べる
    leftBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: "0 auto",
    },
    //サンプルのグラフエリア
    sampleGraphEria: {
        width: 200
    },

    cardList: {
    },
    card: {
    },
    bottomCard: {
        width: 250
    },
}));