
import { makeStyles } from '@mui/styles';
import { Box } from "@mui/material";

import React, { useState, useEffect } from "react";

// アイコン

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FavoriteIcon from '@mui/icons-material/Favorite';

import { Button, Typography } from '@material-ui/core'
import { IconButton } from '@mui/material';


/**
 * @param {*} param0 
 * @returns 
 */
export default function CalenderContorolFooter({ returnState }) {

    const classes = useStyles();


    // 縦幅がMaxになってる理由
    // 共通 page.module.css で mainの書式設定している.
    //  min-height: 100vh; でコンテンツが高さに不足する場合は分散、溢れたらスクロールで動作している
    return (
        <Box className={classes.main}>

            <IconButton onClick={() => returnState("week")} sx={{ height: 100, width: 100 }}>
                <CalendarTodayIcon sx={{ height: 70, width: 70 }} />
                今週
            </IconButton>
            {/* カレンダー */}
            <IconButton onClick={() => returnState("month")} sx={{ height: 100, width: 100 }}>

                <CalendarMonthIcon sx={{ height: 70, width: 70 }} />
                一覧
            </IconButton>

            {/* 保存*/}
            <IconButton sx={{ height: 100, width: 100 }} onClick={() => returnState("favo")} >

                <FavoriteIcon sx={{ height: 70, width: 70 }} />
                保存
            </IconButton>
        </Box>
    )
}

// muiのアイコン
//https://mui.com/material-ui/material-icons/
/* フッターを利用する画面側は次のようにスタイルを設定
 * 
  main: { ルート要素の設定
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  contents: { フッター以外のコンテンツ全てのコンテナ
    flex: 1
  },
 */

const useStyles = makeStyles((theme) => ({
    // 全体レイアウト
    main: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        // 水平に均等割
        display: 'flex',
        justifyContent: 'space-around',
        backgroundColor: '#0099ff',
        opacity: 0.8

    },
}
))
