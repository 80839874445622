import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import IfProxy from '../../IfProxy';
import IfProxyStripe from '../../IfProxyStripe';
import { useAuthContext } from './../../AuthContext';
import Admax from './Admax';


// グラフコンポーネント
import { VictoryLine, VictoryPie, VictoryChart, VictoryBar, VictoryTheme } from 'victory';


const cardHeight = 300;
const imageHeight = 150;
const cardMargin = 5;



/**
 * ユーザ一覧などで表示するアカウントの情報をまとめたカード
 * @property { record, height }
 * @returns 
 * 
 * 募集状況status　募集中/受付停止中
 * 
 * <要件>
 * アバターを出す
 * ヒーローイメージを背景に出す
 * クリックしたらプロフィールに遷移する
 * 環境に応じて幅を調整する
 * propで受け取ったユーザデータで構成する
 */
const DisplayAd = ({ adType }, props) => {

    const navigate = useNavigate();
    const classes = useStyles();
    const ifProxy = new IfProxy();
    const ifProxyStripe = new IfProxyStripe();
    const { user } = useAuthContext();

    const [premiumFlg, setPremiumFlg] = useState("");



    useEffect(() => {
        loadData();


        // admax ---


        // 外部JSを読み込んで広告リストを実際に表示
        const tag = document.createElement('script');
        tag.src = 'https://adm.shinobi.jp/s/1299c482fd54238c204b4ae26b3d3808';
        tag.async = true;
        document.body.appendChild(tag);
        // ---



        return () => {
            document.body.removeChild(tag);
        };
    }, []);

    const loadData = async () => {
        if (!user) {
            setPremiumFlg(false)
        } else {
            const userData = await ifProxy.getUserData(user.uid)
            const flagResult = await ifProxyStripe.getPremiumStatus(userData)
            if (window.adsbygoogle && process.env.NODE_ENV !== "development") {
                //window.adsbygoogle.push({});
            }
            console.log("広告チェック")
            console.log(flagResult?.premiumFlg)
            setPremiumFlg(flagResult?.premiumFlg)
        }




    }
    // 有料ユーザは表示しない
    if (premiumFlg !== "未登録") { // TODO 一旦逆にする。正しくは !==
        return (<></>)
    }

    if (adType === "squer") {
        // nendはPCでは非表示となる。非表示で問題なし。モバイルでは配信される
        // スクエア枠
        return (
            <>
                {/*<iframe src={`${process.env.PUBLIC_URL}/custumNend.html`}></iframe>*/}
                <a href="https://px.a8.net/svt/ejp?a8mat=3Z4N2A+E9T3UA+59OM+61RI9" rel="nofollow">
                    <img border="0" width="468" height="60" alt="" src="https://www23.a8.net/svt/bgt?aid=240401602863&wid=001&eno=01&mid=s00000024583001016000&mc=1" /></a>
                <img border="0" width="1" height="1" src="https://www12.a8.net/0.gif?a8mat=3Z4N2A+E9T3UA+59OM+61RI9" alt="" />
            </>
        )

    } else if (adType === "yokonaga") {
        // 横長
        /*
        return (
            <>
                    <ins className="adsbygoogle"
                        style={{ "display": "block" }}
                        data-ad-client="ca-pub-9480655394924005"
                        data-ad-slot="1695265286"
                        data-ad-format="auto"
                        data-full-width-responsive="true"></ins>

                </>
                )*/
        // adsensではなく、別アドネットワークの広告をつなぎで配置
        // https://admax.shinobi.jp/app/home
        return (
            <div>
                {/*<Admax id="1299c482fd54238c204b4ae26b3d3808" />*/}
                {/* <Box sx={{ border: '1px solid ', maxWidth: '700px', height: '30px', fontSize: 10.5 }}>広告枠 <a href="https://twitter.com/illustrental">掲載のご相談はサポート(イラストレンタル)アカウントまでDMまたはメンション下さい</a></Box> */}
                <a href="https://px.a8.net/svt/ejp?a8mat=3Z4N2A+EWFKTU+43HQ+60OXD" rel="nofollow">
                    <img border="0" width="120" height="60" alt="" src="https://www20.a8.net/svt/bgt?aid=240401602901&wid=001&eno=01&mid=s00000019115001011000&mc=1" /></a>
                <img border="0" width="1" height="1" src="https://www13.a8.net/0.gif?a8mat=3Z4N2A+EWFKTU+43HQ+60OXD" alt=""></img>
                <a href="https://px.a8.net/svt/ejp?a8mat=3Z4N2A+E9T3UA+59OM+61JSH" rel="nofollow">
                    <img border="0" width="320" height="50" alt="" src="https://www25.a8.net/svt/bgt?aid=240401602863&wid=001&eno=01&mid=s00000024583001015000&mc=1" /></a>
                <img border="0" width="1" height="1" src="https://www17.a8.net/0.gif?a8mat=3Z4N2A+E9T3UA+59OM+61JSH" alt="" />
            </div>
        )

    }

}

export default DisplayAd;


const useStyles = makeStyles((theme) => ({

    card: {
        maxWidth: "300px",
    }, leftCenterBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        height: 200,
    },



}));
