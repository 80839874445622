import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';

// TODO: StandardPageはパスが異なるため注意
import IfProxy from '../../IfProxy';
import HeadFooter from '../HeadFooter';
import SnsUtil from '../../common/SnsUtil';
import AccountCard from '../../common/AccountCard';

import UploadImage from './../../common/UploadImage';
import TagInput from "../../common/TagInput";
import AgreecheckSightShare from "../../common/AgreecheckSightShare";

// ReactGA Analytics
import ReactGA from "react-ga4";


// Material UI
import { makeStyles } from '@mui/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const CreatePortforioProduct = (props) => {


  const [loading, setLoading] = useState(true);

  const [blobDataResult, setBlobDataResult] = useState(0);

  const [requestId, setRequestId] = useState("");
  const [image, setImage] = useState("");
  const [onedroTags, setOnedroTags] = useState(""); // productに設定したタグ
  const [tags, setTags] = useState("");// productに設定したタグ
  const [onedroTagsSag, setOnedroTagsSag] = useState(""); // productに設定したタグ
  const [tagsSag, setTagsSag] = useState("");// productに設定したタグ
  const [anserLimit, setAnserLimit] = useState("");
  const [dereveryLimit, setDereveryLimit] = useState("");
  const [completetTerm, setCompletetTerm] = useState("");
  const [status, setStatus] = useState("");
  const [fromUid, setFromUid] = useState("");
  const [toUid, setToUid] = useState("");
  const [toName, setToName] = useState("");
  const [fromIcon, setFromIcon] = useState("");
  const [toIcon, setToIcon] = useState("");

  const [nsfw, setNsfw] = useState(false);
  const [agreecheckSightShareFlg, setAgreecheckSightShareFlg] = useState(1);

  const [thema, setThema] = useState("");
  const [fromName, setFromName] = useState("");
  const [onedroTagsRequest, setOnedroTagsRequest] = useState("");// こっちはリクエストの持っていたタグ
  const [tagsRequest, setTagsRequest] = useState("");// こっちはリクエストの持っていたタグ
  const [requestAmount, setRequestAmount] = useState(0);



  //表示用
  const auth = getAuth();
  const navigate = useNavigate();

  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const ifProxy = new IfProxy();

  const location = useLocation();

  useEffect((props) => {
    setLoading(true)
    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    // ------

    loadData(props);
  }, []);


  const loadData = (props) => {

    ifProxy.getTag("ワンドロ企画", 10).then((result) => {
      setOnedroTagsSag(() => result)
      console.log(result)
    })
    ifProxy.getTag("タグ", 10).then((result) => {
      setTagsSag(() => result)
      console.log(result)
      setLoading(false)

    })
  }

  const submit = () => {
    ifProxy.insertTag(tags, "タグ");
    ifProxy.insertTag(onedroTags, "ワンドロ企画");

    ifProxy.createPortforio({
      blobDataResult,
      onedroTags,
      tags,
      toUid: user.uid,
      toName: user.displayName,
      toIcon: user.photoURL,
      nsfw,
      agreecheckSightShareFlg
    }).then(result => navigate('/PortforioProduct/' + result.id))
  }

  // 戻る
  const abort = async () => {
    navigate(-1)
  }


  /*
  * @param {Blob} blobData 
  */
  const returnBlob = ({ blobData, localUrl }) => {
    // 親画面の画像データを実際に取り扱うstateに設定
    setBlobDataResult(blobData);
    setImage(localUrl)
  }


  const returnTags = (tag) => {
    // 作品名のタグ
    setTags(tag)
  }
  const returnKikakuTags = (tag) => {
    // 企画名のタグ
    setOnedroTags(tag)
  }



  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else if (loading) {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
          </Box>
        </Box>
      </HeadFooter>
    )
  } else {

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox} >

            <UploadImage returnBlob={(blob) => returnBlob(blob)}></UploadImage>
            <h2>作品タグ</h2>
            <TagInput type="form" resultTags={(tags) => returnTags(tags)} suggestion={tagsSag}></TagInput>
            <h2>ワンドロ企画名: 企画からあなたを探す人のために、投稿したワンドロ企画があればご記載ください（任意）</h2>
            <TagInput type="formKikaku" resultTags={(tags) => returnKikakuTags(tags)} suggestion={onedroTagsSag}></TagInput>
            <Box className={classes.leftBox} >
              <h2>NSFW : </h2>
              <input
                type="checkbox"
                id="check"
                checked={nsfw}
                onChange={() => setNsfw(prevState => !prevState)}
              />
              <h2>
                {nsfw ? "ON" : "OFF"}
              </h2>


            </Box>
            <Box className={classes.leftBox} >
              <AgreecheckSightShare paramStatus={agreecheckSightShareFlg} setResult={(result) => setAgreecheckSightShareFlg(result)}></AgreecheckSightShare>
            </Box>
            <Button
              variant="contained"
              fullWidth
              disabled={(image === "")}
              onClick={() => submit()}
              color="primary"
              sx={{ margin: 2 }}>
              登録
            </Button>
            <Button
              variant="contained"
              fullWidth
              onClick={() => abort()}
              color="primary"
              sx={{ margin: 2 }}>
              戻る
            </Button>
          </Box>
        </Box>

      </HeadFooter >

    );
  }




}
export default CreatePortforioProduct;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",
  },
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },
  cardList: {
  },
  card: {
  },
}));
