import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';


const cardMargin = 5;

/**
 * 作品一覧で表示する作品の情報をまとめたカード
 * @property { id,displayName, uid, thumbnail }
 * @returns 
 * 
 * 
 * 
 * <要件>
 * アバターを出す
 * ヒーローイメージを背景に出す
 * クリックしたらプロフィールに遷移する
 * 環境に応じて幅を調整する
 * propで受け取ったユーザデータで構成する
 */
const FollowCard = ({ id, displayName, uid, thumbnail }) => {

    const navigate = useNavigate();
    const classes = useStyles();


    /**
     *　ユーザ画面(User)に遷移
     * @param {*} id 
     */
    const onClickCard = (id) => {
        console.log("カードがクリックされた")
        console.log(id);
        navigate('/User/' + id);
    }

    const createCard = () => {
        return (

            <Grid item key={id}
            >
                <Box className={classes.centerBox} >
                    <Card className={classes.card} onClick={() => onClickCard(uid)}>
                        {thumbnail && <Avatar sx={{ width: 100, height: 100 }} src={thumbnail} />}

                        {displayName}
                    </Card>
                </Box>
            </Grid>
        )
    }
    return (
        <>
            {createCard()}
        </>
    )
}

export default FollowCard;


const useStyles = makeStyles((theme) => ({

    card: {
        width: '100%',
        margin: cardMargin,
        display: 'flex',
        flexDirection: 'row',
        padding: 10
    },
    // 個別コンテンツ　中央揃えで縦に内容を並べる
    centerBox: {
        width: "500px",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    // 個別コンテンツ　横並びに内容を並べる
    leftBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: "0 auto",
    },



}));
