import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from './../../AuthContext';
import IfProxy from './../../IfProxy';
import HeadFooter from './../HeadFooter';
import UploadImage from './../../common/UploadImage';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const UserEditFirst = (props) => {



  const [docId, setDocId] = useState(0); // userDataのdocId
  const [blobDataResult, setBlobDataResult] = useState(0);


  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();

  const location = useLocation();

  const [icon, setIcon] = useState(""); // url
  const [displayName, setDisplayName] = useState("");
  const [profile, setProfile] = useState("");
  const [genre, setGenre] = useState("");
  const [onedro, setOnedro] = useState("");
  const [status, setStatus] = useState(true);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [passwordAuthCheckMassage, setPasswordAuthCheckMassage] = useState("");
  const [passwordAuthClearFlg, setPasswordAuthClearFlg] = useState(true);

  const ifProxy = new IfProxy();

  useEffect((props) => {
    loadData(props);
    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    // ------
  }, []);

  const handleLogout = () => {
    auth.signOut();
    navigate('/login');
  };

  const loadData = (props) => {
    console.log("画面遷移後＝ params ＝＝＝＝＝＝＝＝＝＝")
    //console.log(params);
    //console.log(location);
    ifProxy.getUserData(params.id).then((result) => {
      console.log("============-")
      //console.log(result);
      setDocId(result.id);
      setProfile(result.profile || "")
      setGenre(result.genre || "")
      setOnedro(result.onedro || "")
      setStatus((result.status == undefined) ? true : result.status)
      setIcon(() => result.icon !== undefined ? result.icon : user.reloadUserInfo.photoUrl);
      setDisplayName(() => (result.displayName !== undefined) ? result.displayName : user.reloadUserInfo.displayName); // Auth値固定（twitter)　任意の入力があればその値を優先
    })
  }


  /*
   * @param {Blob} blobData 
  */
  const returnBlob = ({ blobData, localUrl }) => {
    // 親画面の画像データを実際に取り扱うstateに設定
    setBlobDataResult(blobData);
    setIcon(localUrl)
  }

  const passwordEnable = () => {
    ifProxy.linkTwitterAuthProviderToEmailProvider(email, password).then(({ status }) => {
      if (status) {
        setPasswordAuthClearFlg(true);
        setPasswordAuthCheckMassage("パスワード認証の連携に成功しました")
      } else {
        setPasswordAuthCheckMassage("パスワード認証の連携に失敗しました。メールアドレスまたはパスワードが6文字以上であることを確認してください")
      }
    })
  }


  /**
  * サインアップ
  */
  const update = () => {
    // ユーザ名の設定
    // Auth値固定（twitter)　任意の入力があればその値を優先。（から文字をれたらtwitterになる）
    const userName = (displayName !== "") ? displayName : user.reloadUserInfo.displayName;
    const authUserData = {
      displayName: userName,
      blob: blobDataResult
    }
    ifProxy.updateUser(authUserData).then((result) => {
      // メモ
      // 画面上からプロフィール画像の変更がない場合は、ファイルアップロードとurlの返却をしない
      // その場合。画面は、nullを受け取る
      // nullの時は、userData既に登録されているurlを踏襲する
      // userDataに既に登録されているurlがなければ、プロバイダーのurlを当てる(useEffectで既にiconに、userDataがあればそれ、なければプロバイダのiconが入っている)
      let photo = ""

      if (result.url !== undefined) {
        //　画像の更新あり
        photo = result.url;// 更新された新しいfirestoreのurl
      } else {
        // 画像の更新なし && （過去のデータを踏襲、または過去データもなし）
        photo = icon; //データ初期ロード時に入れたurl
      }
      //console.log("設定するurl")
      //console.log(photo)
      const data = {
        id: docId, // 初期登録であれば、から文字を入れておけば、updateUserData側で新規にしてくれる
        uid: user.uid, // Auth値固定（Auth)
        displayName: userName,
        icon: photo,

        //フォームから自由に設定
        profile: profile,
        status: status,
        genre: genre,
        onedro: onedro
      }
      ifProxy.updateUserData(data).then((result) => {
        navigate(`/User/${user.uid}`);
      }).catch((e) => {
        console.log("error updateData")
        console.log(e);
      })
    }).catch((e) => {
      console.log("error updateData")
      console.log(e);
    })

  }


  // フォームの入力値をStateに設定
  const handleDisplayName = (event) => {
    setDisplayName(event.target.value)
  }
  const handleProfile = (event) => {
    setProfile(event.target.value)
  }

  const handleGenre = (event) => {
    setGenre(event.target.value)
  }
  const handleOnedro = (event) => {
    setOnedro(event.target.value)
  }

  const handleEmail = (event) => {
    setEmail(event.target.value)
  }
  const handlePassword = (event) => {
    setPassword(event.target.value)
  }

  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else {

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.contentArea}>
            <Box className={classes.avator}>
              <UploadImage returnBlob={(blob) => returnBlob(blob)} avatorMode={true}></UploadImage>
              <Avatar
                src={icon}
                sx={{ width: 100, height: 100 }} />

              <Typography color="text.primary" gutterBottom>
                <h3>ステータス: {status ? "募集受付中" : "募集停止中"}</h3>
              </Typography>

              <div>
                <label htmlFor="check">
                  募集中：
                </label>
                <input
                  type="checkbox"
                  id="check"
                  checked={status}
                  onChange={() => setStatus(prevState => !prevState)}
                />
              </div>
            </Box>
            {displayName}
            {!passwordAuthClearFlg && <TextField fullWidth id="outlined-basic" label="メールアドレス" onChange={handleEmail} variant="outlined" value={email} sx={styleDifinition.aInputForm} />}
            {!passwordAuthClearFlg && <TextField fullWidth id="outlined-basic" label="パスワード" onChange={handlePassword} variant="outlined" value={password} sx={styleDifinition.aInputForm} />}
            <p style={{ color: `${passwordAuthClearFlg ? "blue" : "red"}` }}>{passwordAuthCheckMassage}</p>

            {!passwordAuthClearFlg && <Button
              variant="contained"
              disabled={!(email && password)}
              fullWidth
              onClick={() => passwordEnable()}
              color="secondary"
              sx={{ margin: 2 }}>
              【必ず実施してください】パスワード認証有効化
              <br></br>(Twitterログインのほかメールアドレスでも認証できるようにします)
            </Button>}



            <TextField fullWidth id="outlined-basic" label="ユーザ名" onChange={handleDisplayName} variant="outlined" value={displayName} sx={styleDifinition.aInputForm} />
            <TextField fullWidth multiline rows={4} id="outlined-basic" label="自己紹介" onChange={handleProfile} value={profile} variant="outlined" sx={styleDifinition.aInputForm} />
            <TextField fullWidth id="outlined-basic" label="得意ジャンル" variant="outlined" onChange={handleGenre} value={genre} sx={styleDifinition.aInputForm} />
            <TextField fullWidth id="outlined-basic" label="最近やっているワンドロ企画" variant="outlined" onChange={handleOnedro} value={onedro} sx={styleDifinition.aInputForm} />

            {icon === undefined && "アイコンが未設定です(必須)"}<br></br>
            {(displayName === undefined || displayName === "") && "ユーザ名が未設定です(必須)"}
            <Button
              variant="contained"
              fullWidth
              disabled={!passwordAuthClearFlg || icon === undefined || displayName === undefined || displayName === ""}
              onClick={() => update()}
              color="primary"
              sx={{ margin: 2 }}>
              登録
            </Button>
          </Box>

        </Box>
      </HeadFooter >

    );
  }




}
export default UserEditFirst;

const styleDifinition = {
  aInputForm: {
    margin: "10px",
  },
}
const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentArea: {
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 700
  },
  avator: {
    margin: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputFileBtnHide: {
    opacity: 0,
    appearance: "none",
    position: "absolute"
  },
  aInputForm: {
    margin: 2,
  },
  mainBox: {

  },
  cardList: {
  },
  card: {
  },
  bottomCard: {
    width: 250
  },
}));
