import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, getDoc, query, deleteDoc, updateDoc, where, addDoc, serverTimestamp, limit, orderBy, startAt, endAt, Timestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, TwitterAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult, updateProfile, EmailAuthProvider, linkWithCredential, } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import Resizer from "react-image-file-resizer";

import { getFunctions, httpsCallable } from "firebase/functions";


import IfProxy from './IfProxy';

// アプリ全体のFirebase設定 起動直後最初に一回読み込まれる
// APIキーを git管理から外したファイルに設置。これはpushしない
import apiKey from './apikey.json';

// APIキー等の設定情報を取得 起動直後最初に一回読み込まれる
const firebaseConfig = apiKey.firebase;

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();
const provider = new TwitterAuthProvider();
const functions = getFunctions(app);
const ifProxy = new IfProxy();

const DAY = Object.freeze({
  MON: 0,
  THE: 1,
  WED: 2,
  THU: 3,
  FRI: 4,
  SAT: 5,
  SUN: 6,
  ANI: 7, //記念日系
  EIG: 8, // 日程指定なし
})

const TYPE = Object.freeze({
  event: "event",
  aniversary: "aniversary"
})
class IfProxyCalender {


  /**
   * @param {number}
   * @returns 曜日ごとにイベントを取得する
   */
  getCalenderByDay = (day) => {

    // 引数チェック
    if ([DAY.MON, DAY.THE, DAY.WED, DAY.THU, DAY.FRI, DAY.SAT, DAY.SUN, DAY.ANI, DAY.EIG].indexOf(day) == -1) {
      console.log("引数誤り")
      throw new Error('getCalenderByDay　曜日区分値の引数が誤っている');
    }
    return new Promise(async (resolve, reject) => {
      //console.log("day  : " + day)
      //console.log("getCalenderByDay")
      try {
        let result = []; //取得結果のリスト
        const ref = collection(db, "CalenderItem");
        const q = await query(ref, where("dayName", "==", day), orderBy("updatedAt"));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size == 0) {
          //console.log("取得データなし" + day)
          // なし
          resolve(false)
        } else {
          this.getRequestQuery(querySnapshot, resolve);

        }

      } catch (e) {
        this.homeCommonError(e, reject)
      }
    })

  }

  /**
   * @param {number}
   * @returns イベントを全て取得する
   */
  getCalenderAll = () => {

    return new Promise(async (resolve, reject) => {
      //console.log("day  : " + day)
      //console.log("getCalenderByDay")
      try {
        let result = []; //取得結果のリスト
        const ref = collection(db, "CalenderItem");
        const q = await query(ref, orderBy("updatedAt"));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size == 0) {
          //console.log("取得データなし" + day)
          // なし
          resolve(false)
        } else {
          this.getRequestQuery(querySnapshot, resolve);

        }

      } catch (e) {
        this.homeCommonError(e, reject)
      }
    })

  }


  addCalenderItem = ({
    type,
    name,
    description,
    startTime,
    tags,
    nameLabel,
    dayName,
    kinenbiMonth,
    kinenbiDate,
    user,
    presentUrl,
    // タグ情報
    ganleTagData,
    ganleTagDataStr,
    charactorTagData,
    charactorTagDataStr,
    featureTagData,
    featureTagDataStr
  }) => {
    return new Promise(async (resolve, reject) => {
      // 予定を作成を作成
      addDoc(collection(db, "CalenderItem"), {
        type,
        name,
        description,
        startTime,
        tags,
        nameLabel,
        dayName,
        kinenbiMonth,
        kinenbiDate,
        createdUid: user.uid,
        imageUrl: "",// まだないが将来のためにから文字を入れておく
        imageSumb: "",// まだないが将来のためにから文字を入れておく
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        presentUrl,
        // タグ情報
        ganleTagData,
        ganleTagDataStr,
        charactorTagData,
        charactorTagDataStr,
        featureTagData,
        featureTagDataStr
      }).then((result) => {
        resolve(result);
      }).catch((error) => {
        console.log("企画を追加する 失敗 ")
        resolve()
      });

    })
  }

  deleteCalenderItem = (id) => {
    return new Promise(async (resolve, reject) => {
      // 予定を作成を作成
      deleteDoc(doc(db, "CalenderItem", id)).then((result) => {
        resolve(result);
      }).catch((error) => {
        console.log("企画を追加する 失敗 ")
        resolve(error)
      });

    })
  }
  /**
   * EventBookmark　EventBookmark　保有するを作成
   * uidとCalenderItemのidを紐づける　中間テーブル
   * @param cCalenderItemId: String, uid: String, calenderItem:CalenderItem } 
   */
  createFavo = ({ calenderItemId, uid, calenderItem }) => {
    return new Promise(async (resolve, reject) => {
      // 予定を作成を作成
      addDoc(collection(db, "EventBookmark"), {
        calenderItemId,
        uid, //ブックマークの保有者
        calenderItem, // イベントの本体
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      }).then((result) => {
        resolve(result);
      }).catch((error) => {
        console.log("企画を追加する 失敗 ")
        resolve()
      });

    })
  }
  /**
   * uidに関連するCalenderItemを取得する
   * @param {*} uid 
   * @description 保有者のuid
   */
  getFavoByUid = (uid) => {
    return new Promise(async (resolve, reject) => {
      //console.log("day  : " + day)
      //console.log("getCalenderByDay")
      try {
        const ref = collection(db, "EventBookmark");
        const q = await query(ref, where("uid", "==", uid));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size == 0) {
          //console.log("取得データなし" + day)
          // なし
          resolve(false)
        } else {
          this.getRequestQuery(querySnapshot, resolve);

        }

      } catch (e) {
        this.homeCommonError(e, reject)
      }
    })
  }
  /**
   * リレーションのuid
   * @param {id:string} id ,uid 
   * @description CalenderItemのidとuidの組み合わせで特定
   */
  deleteFavo = (id, uid) => {

    return new Promise(async (resolve, reject) => {
      const ref = collection(db, "EventBookmark");
      const q = await query(ref, where("calenderItemId", "==", id), where("uid", "==", uid));
      const querySnapshot = await getDocs(q);
      console.log(querySnapshot.size)
      if (querySnapshot.size == 0) return;

      let deleteId = ""
      await querySnapshot.forEach((doc) => {
        //console.log(doc.data().anserLimit.toDate())
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        deleteId = doc.id
      })
      // 予定を作成を作成
      deleteDoc(doc(db, "EventBookmark", deleteId)).then((result) => {
        resolve(result);
      }).catch((error) => {
        console.log("ブックマークを削除　失敗 ")
        resolve(error)
      });

    })
  }

  getTodayFavoByUid = (uid) => {
    return new Promise(async (resolve, reject) => {
      const myFavo = await this.getFavoByUid(uid);
      let flg = false;
      myFavo?.map((bookmarkItem) => {
        const day = bookmarkItem.calenderItem.dayName;
        const mon = bookmarkItem.calenderItem.kinenbiMonth;
        const date = bookmarkItem.calenderItem.kinenbiDate

        console.log("日付チェック")
        var dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][day]
        /*
        console.log(dayOfWeekStr)
        console.log(this.isYoubi(dayOfWeekStr) ? "true" : "false")
        console.log(mon)
        console.log(date)
        console.log(this.isToday(mon, date) ? "true" : "false")
        */

        if (this.isYoubi(dayOfWeekStr) || this.isToday(mon, date)) {
          flg = true
        }
      })

      resolve(flg)
    })
  }

  /**
   * 月・日が今日かどうか判定
   * @param {*} month 
   * @param {*} day 
   * @returns {boolean}
   */
  isToday = (month, day) => {
    month = month - 0;//数値にする
    day = day - 0;
    var nowDateate = new Date();
    var nowMonth = nowDateate.getMonth() + 1
    var nowDay = nowDateate.getDate()
    // 今日
    return (month === nowMonth && day === nowDay)
  }

  /**
   * 今日と同じ曜日
   * @param {*} day 
   * @returns {boolian}
   * @description todayFlg("月") 今日が月曜ならtrue
   */
  isYoubi = (day) => {
    var date = new Date();
    var dayOfWeek = date.getDay()
    var dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek]
    return day === dayOfWeekStr
  }

  /**
 * リクエスト表示用のリスト取得クエリ
 * querySnapshotを実行した結果をリスト化して返すRefactoringメソッド
 * @param {querySnapshot, resolve} querySnapshot 
 * @param {[]} レコードの配列 
 */
  getRequestQuery = async (querySnapshot, resolve) => {
    let result = []; //取得結果のリスト
    await querySnapshot.forEach((doc) => {
      //console.log(doc.data().anserLimit.toDate())
      // doc.data() is never undefined for query doc snapshots
      //console.log(doc.id, " => ", doc.data());

      let res = doc.data();
      res.id = doc.id;
      result.push(res);
    })
    resolve(result);

  }

  /**
 * エラー返却共通処理
 * @param {*} e 
 * @param {*} reject 
 */
  homeCommonError = (e, reject) => {
    console.log("ホーム用データ取得に失敗");
    console.log(e);
    reject(e)
  }

}





export default IfProxyCalender;
