import React, { useEffect } from "react";
// Material UI
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

import EventCard from "./EventCard";


const Favo = (props) => {
    const classes = useStyles();
    const calender = props.calender

    let first = true;
    useEffect((props) => {
        if (first) {



        }
        return () => {
        }

    }, []);


    // リスト要素をデータを入れて、リストにする
    const itemListView = (item) => {
        if (!item) return;

        const itemListComp = item.map((data, index) => {
            return (
                <>
                    <Box
                        key={`event_item_id${data.id}`}
                    >
                        <EventCard item={data} fav={true}></EventCard>
                    </Box >
                </>
            )
        })

        return (
            <>
                {itemListComp}
            </>
        )
    }

    return (
        <>
            <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
                保存リスト
            </Typography>

            <Box className={classes.leftCenterBox}>
                {itemListView(calender)}
            </Box>

        </>
    )

}
export default Favo;

const useStyles = makeStyles((theme) => ({

    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftCenterBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },

}));