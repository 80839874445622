import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';

import { useAuthContext } from './../../AuthContext';


const cardHeight = 200;
const imageHeight = 150;
const cardMargin = 5;

/**
 * 作品一覧で表示する作品の情報をまとめたカード
 * @param {Object<SwipeDataObject>} 
 * @returns 
 * 
 * 
 * 
 * <要件>
 * アバターを出す
 * ヒーローイメージを背景に出す
 * クリックしたらプロフィールに遷移する
 * 環境に応じて幅を調整する
 * propで受け取ったユーザデータで構成する
 */
const SwipeDetail = ({ swipeDataObject }) => {
    //console.group("詳細画面------")
    //console.log(swipeDataObject)
    //console.groupEnd()
    const { id, primaryText, mainImagePath, profilePath, touchNextPage, tagList, kikakuTagList, nsfw } = swipeDataObject
    const navigate = useNavigate();
    const classes = useStyles();
    const [isHover, setIsHover] = useState(false);
    const [r18, setR18] = useState(false);
    const { user } = useAuthContext();

    useEffect(() => {
        setR18(nsfw)
    }, []);

    const handleMouseEnter = () => {
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setIsHover(false);
    };

    /**
     * 企画タグを表示する
     * @returns 
     */
    const showKikakuTag = () => {
        if (!kikakuTagList) {
            return null;
        }
        // navigateはSearchListViewの受付条件に従う
        // /SearchListView?type=tag&word=test
        return kikakuTagList.map((value, index, list) => {
            return (
                <>
                    <Typography variant="h3" color="white" sx={{ marginTop: 2 }} onClick={() => {
                        //navigate(`/SearchListView/KIKA?word=${value}`)
                        window.open(`${window.location.origin}/SearchListView/KIKA?word=${value}`)
                    }
                    }>
                        #{value} ,
                    </Typography>

                </>
            )
        })
    }
    /**
     * タグを表示する
     * @returns 
     */
    const showTag = () => {
        if (!tagList) {
            return null;
        }
        const colorName = ['aqua', 'lime', 'white', 'teal', 'green', 'silver', 'yellow', 'navy']
        return tagList.map((value, index, list) => {
            // navigateはSearchListViewの受付条件に従う
            // /SearchListView?type=tag&word=test
            return (
                <>
                    <Typography variant="h5" color={colorName[(index % 8)]} sx={{ marginTop: 2 }} onClick={() => {
                        //navigate(`/SearchListView/TAGS?&word=${value}`)
                        window.open(`${window.location.origin}/SearchListView/TAGS?word=${value}`)

                    }}>
                        #{value} ,
                    </Typography>

                </>
            )
        })
    }

    const swipeCard = () => {
        //console.log(id)
        //console.log(primaryText)


        return (
            <Box
                key={id}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '70vh', width: '100vw', maxWidth: '1200px', backgroundColor: 'gray', margin: '0 auto'
                }}>

                <Box
                    sx={{
                        flex: 1,
                    }}
                >
                    {r18 && <Button
                        style={{ color: 'red', fontSize: 50, borderColor: 'red', border: 'solid', borderRadius: '15px' }}
                        onClick={(e) => {
                            e.stopPropagation()
                            setR18(false)
                        }}>R-18</Button>
                    }
                    {mainImagePath == "" && "画像は未設定です。アカウントを表示している場合、ここにプロフィールから設定したあなたの創作のお品書きを表示できます"}
                    <img src={mainImagePath} style={{
                        width: isHover ? "100%" : "100%",
                        borderRadius: isHover ? 0 : 15,
                        transition: 'all 0.3s',

                        filter: r18 ? 'blur(6px)' : '',
                    }} />

                </Box>
                <Box sx={{
                    height: '100px',
                    display: 'flex',
                    padding: '10px',
                }}>
                    <Box sx={{ marginTop: 2, flex: 2 }} >
                        <Typography variant="h5" color="white" onClick={() => navigate(profilePath)}>
                            {primaryText}
                        </Typography>
                        <Typography variant="h7" color="white" onClick={() => {
                            navigate(profilePath)
                            window.open(`${window.location.origin}${profilePath}`)

                        }
                        }>
                            【リクエストはこちら】
                        </Typography>
                    </Box>

                    <Button variant='contained' sx={{ marginTop: 2, flex: 1 }} onClick={() => {
                        if (user) {
                            navigate(touchNextPage)
                        } else {
                            alert('ログインが必要です')
                        }

                    }}>詳細を見る</Button>
                </Box>
                <Box
                    sx={{
                        padding: '10px',
                    }}>
                    {showKikakuTag()}
                    <Box style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}>
                        {showTag()}
                    </Box>


                </Box>

            </Box >
        )
    }

    return (
        <>
            {swipeCard()}
        </>
    )
}



export default SwipeDetail;


const useStyles = makeStyles((theme) => ({

    card: {
        maxWidth: "700px",
    },



}));
