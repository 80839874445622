import React, { useState, useEffect } from "react";
import { useAuthContext } from '../../AuthContext';
import HeadFooter from '../HeadFooter';
import Typography from '@mui/material/Typography';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';


// Material UI
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';

// ReactGA Analytics
import ReactGA from "react-ga4";

const Privacypolicy = (props) => {
  const classes = useStyles();
  const location = useLocation();


  useEffect((props) => {
    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    // ------
    return () => {
    }

  }, []);

  // Boxレイアウトのレスポンシブ対応
  // https://mui.com/components/grid/#responsive-values
  return (
    <HeadFooter>
      <Box className={classes.contentWrapper}>
        <Box className={classes.mainBox}>

          <Grid container justify="center" spacing={3} sx={{ marginTop: 5 }} columns={{ xs: 12, sm: 12, md: 12 }}>
            <Typography variant="h3" component="div" sx={{ margin: 2 }}>
              プライバシーポリシー
            </Typography>

            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >

              <Typography variant="h6" component="div" sx={{ margin: 2 }}>
                1. 取得するユーザー情報と目的
              </Typography>
              <Typography variant="h7" component="div">利用者の認証のために連携するSNSのアカウント情報を取得し利用します。用途は認証・認可に利用します。
              </Typography>

              <Typography variant="h6" component="div" sx={{ margin: 2 }}>2. 取得方法</Typography>
              <Typography variant="h7" component="div">SNS連携時認証情報を取得する
              </Typography>

              <Typography variant="h6" component="div" sx={{ margin: 2 }}>3. 通知・公表または同意取得の方法・利用者関与の方法</Typography>

              <Typography variant="h7" component="div">アプリケーション初回登録時に同意を取得されたものとします。取得した情報は認証以外の使途には利用しません。ユーザ情報の取得は本アプリケーションの退会によって中止することができます。
              </Typography>

              <Typography variant="h6" component="div" sx={{ margin: 2 }}>4. 外部送信・第三者提供・情報収集モジュール</Typography>

              <Typography variant="h7" component="div">取得した認証情報はサービスを提供するPaSS事業者のサーバに保持されます。他第３社提供及び外部送信は行われません。決済に関する情報は別途 ©Stripe社サービスにより管理を行います。本アプリ上では決済に必要なカード情報及び銀行口座情報等の保持は行いません
              </Typography>

              <Typography variant="h6" component="div" sx={{ margin: 2 }}>5. お問い合わせ</Typography>

              <Typography variant="h7" component="div">当社のプライバシーポリシーに関する、ご意見、ご質問、苦情の申出その他ユーザー情報の取扱いに関するお問い合わせは、以下の窓口にご連絡ください。
                <br></br>
                illustrental@gmail.com
              </Typography>

              <Typography variant="h6" component="div" sx={{ margin: 2 }}>6. 改定</Typography>
              <Typography variant="h7" component="div">アプリ運用者の裁量に基づいて、本ポリシーを変更します。但し、取得するユーザー情報、利用目的、第三者提供に変更が発生した場合、本アプリケーションまたは本サイトで通知するとともに、本ポリシー変更後、本アプリケーションの初回起動時に改めてユーザーから同意を取得します。
              </Typography>

              <Typography variant="h6" component="div" sx={{ margin: 2 }}>7. 改定日・制定日</Typography>
              <Typography variant="h7" component="div">制定:2023年4月24日</Typography>



            </Grid>



          </Grid>


        </Box>
      </Box>


    </HeadFooter>

  );
}
export default Privacypolicy;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
  },
  mainBox: {

  },
  cardList: {
  },
  card: {
  },
}));
