import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import IfProxy from '../../IfProxy';
import IfProxyTool from '../../IfProxyTool';

import HeadFooter from '../HeadFooter';
import StraitLineSignupButton from '../../common/StraitLineSignupButton';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import PanoramaTwoToneIcon from '@mui/icons-material/PanoramaTwoTone';

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

//graph
import RecordParcentGraph from "../../common/graphs/RecordParcentGraph";
import RecordParcentBarGraph from "../../common/graphs/RecordParcentBarGraph";
import RecordErrorBarGraph from "../../common/graphs/RecordErrorBarGraph";
import TimeBarGraph from "../../common/graphs/TimeBarGraph";



/**
 * ユーザ参照
 * @param  
 * @returns 
 */
const DiaryList = (props) => {
  const [loading, setLoading] = useState(true);
  // クリックされたイベントの情報をモーダルに渡す
  const [clickedEventData, setClickedEventData] = useState("");
  const [modalShow, setModalShow] = useState(false);

  // 日記リストデータ
  const [diaryList, setDiaryList] = useState([]);
  const [MyDiaryList, setMyDiaryList] = useState([]);

  // 自分の日記モード
  const [myFlg, setMyFlg] = useState(false);


  const auth = getAuth();
  const navigate = useNavigate();
  const { user, userData } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();



  const ifProxy = new IfProxy();
  const ifProxyTool = new IfProxyTool();


  let first = true;
  useEffect((props) => {
    // 【useEffectの２重処理について】
    // https://zenn.dev/takeharu/scraps/d14cf9d4239ec4
    // useEffectは２回描画される
    // mount -> unmount -> mount
    // 処理がこのようにダブルのは開発環境だけ
    if (first) {
      setLoading(true)
      // ReactGA Analytics ---
      ReactGA.initialize("G-2ZCXE08H2S");
      ReactGA.send({
        hitType: "pageview",
        // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
        page: location.pathname + location.search,
      });
      // ------
      loadData();
    }

    //    console.log(first ? "１回目である" : "２回目である")
    //    console.log("回数評価処理は以下")
    //    console.log("開発環境２重処理防止のため、１回目、２回目判定フラグ")
    //    console.log(first)
    first = false;
    //    console.log("２重登録判定フラグ、更新後")
    //    console.log(first)

    return () => {
      //      console.log("UNMOUNT 開発環境２重処理防止のため、１回目、２回目判定フラグ")
      //      console.log(first)
    }

  }, []);

  const loadData = async () => {
    let diaryListData = [];
    diaryListData = await ifProxyTool.getFullDiaryList(10)
    const diaryListDataFilterd = diaryListData.filter((diary) => {
      return !diary.privateView;
    })
    setDiaryList(diaryListDataFilterd);

    const myDiaryListData = await ifProxyTool.getDiaryList(user.uid, 10)
    setMyDiaryList(myDiaryListData);

    setLoading(false)
  }



  const handleClickCard = (id, data) => {
    navigate('/DiaryView/' + id)
  }

  // 時間の差を返す
  /**
   * @param {Date} 現在時刻
   * @param {Date} 比較対象時刻
   * @return {HH: number, MM: number, SS:number} 差分の　時、分、秒
   */
  const getDiffMMdd = (genzai, kaishi) => {
    if (!(genzai instanceof Date)) {
      throw new Error("データ方が違います genzai : " + genzai)
    }
    if (!(kaishi instanceof Date)) {
      throw new Error("データ方が違います kaishi : " + kaishi)
    }
    // console.log(genzai instanceof Date);
    // console.log("時間計算処理 getDiffMMdd")
    // console.log("現在のDate" + genzai)
    // console.log("対象のDate" + kaishi)

    // 時間差計算は、getTimeでミリ秒計算し、戻す
    // 1000で割ると秒、さらに60で割って、分、秒にする
    const gendaiTime = genzai.getTime();
    const kaishiTime = kaishi.getTime();
    const diffTime = gendaiTime - kaishiTime
    // MMHHSSを作るなら、MMはHHを６０で割った余りになるため％でMODする
    const HH = Math.floor(diffTime / (1000 * 60 * 60));
    const MM = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
    const SS = Math.floor((diffTime % (1000 * 60)) / 1000);
    return { HH, MM, SS };
  }



  // リスト要素をデータを入れて、リストにする
  const diaryListView = () => {
    const viewData = myFlg ? MyDiaryList : diaryList;

    const itemListComp = viewData.map(({
      id,
      toUid,
      toUser,
      toIcon,
      privateView,
      diary,
      memoForNext,
      imageUrl,
      sumb,
      videoUrl,
      youtubeUrl,
      tag,
      tagString,
      onedroTag,
      onedroTagString,
      record,
    }, index, dataList) => {

      const turnAroundTime = getDiffMMdd(record.completeTime.toDate(), record.step0Time.toDate())
      const turnAroundTimeStr = turnAroundTime.HH + "時間" + turnAroundTime.MM + "分" + turnAroundTime.SS + "秒"


      return (
        <>
          <Grid container className={classes.gridCard} onClick={() => handleClickCard(id, dataList[index])}>

            {/** 2段目 ===================================================================== */}
            <Grid item xs={4} >
              <Box className={classes.centerBox}>
                #タグ {tagString.join()}<br></br>
                #ワンドロ {onedroTagString.join()}
              </Box>
            </Grid>
            <Grid item xs={3} >
              <Box className={classes.centerBox}>
                {toUser}
              </Box>
            </Grid>
            <Grid item xs={4} >
              <Box className={classes.centerBox}>
                <b>タイム：{turnAroundTimeStr}</b>
              </Box>
            </Grid>


            {/** １段目 ===================================================================== */}
            {/**背の高いコンポーネントは一に寄せる */}
            <Grid item xs={4} >
              <Box className={classes.centerBox}>
                <img src={sumb}></img>
              </Box>
            </Grid>
            <Grid item xs={3} >
              <Box className={classes.centerBox}>
                <Avatar
                  src={toIcon}
                  sx={{ width: 70, height: 70 }} />

                <Box className={classes.centerBox}>

                </Box>
                <Box className={classes.centerBox}>
                  <Typography><b>手戻りの多い作業</b></Typography>
                  <Typography>{record.step1}:{record.step1FixCount}回</Typography>
                  <Typography>{record.step2}:{record.step2FixCount}回</Typography>
                  <Typography>{record.step3}:{record.step3FixCount}回</Typography>
                  <Typography>{record.step4}:{record.step4FixCount}回</Typography>
                  <Typography>{record.step5}:{record.step5FixCount}回</Typography>
                  <Typography>{record.step6}:{record.step6FixCount}回</Typography>
                  <Typography>{record.step7}:{record.step7FixCount}回</Typography>
                  <Typography>{record.step8}:{record.step8FixCount}回</Typography>
                  <Typography>{record.step9}:{record.step9FixCount}回</Typography>
                  <Typography>{record.step10}:{record.step10FixCount}回</Typography>


                </Box>
              </Box>
            </Grid>
            <Grid item xs={5} >
              <Box className={classes.centerBox}>
                <Typography><b>タイム比率</b></Typography>
                <RecordParcentGraph recordData={record} />
              </Box>
            </Grid>




          </Grid>


        </>
      )
    })

    return (
      <>
        <Box className={classes.centerBox}>
          {itemListComp}
        </Box>
      </>
    )
  }


  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {

    if (loading) {
      return (
        <HeadFooter>
          <Box className={classes.contentWrapper}>
            <Box className={classes.mainBox}>
              <p>読み込み中...</p>
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            </Box>
          </Box>
        </HeadFooter>
      )
    } else {
      //ログインしていないユーザ向けのプロフィール画面
      return (
        <></>
      )
    }
  } else if (loading) {
    return (
      <HeadFooter>

        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Box>
        </Box>
      </HeadFooter>
    )
  } else {

    const twitterShareUrl = "http://twitter.com/share?url=" + window.location.origin + '/User/' + params.id + "&text=ワンドロテーマを募集中&hashtags=ワンフロ"
    console.log(userData)
    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      < HeadFooter >
        {/* オーバーレイ用の背景要素 */}
        {/*modalView()*/}
        <Box className={classes.contentWrapper}>
          <Grid container justify="center" spacing={2} >
            {/** 0段目 ===================================================================== */}
            {/** ヘッダ情報グリッド */}
            <Grid item xs={6} className={classes.gridPaddingNoborder}>
              <Box className={classes.centerBox}>
                <Button onClick={() => navigate('/OnedroTimer')}
                ><AddCircleOutlineTwoToneIcon />日記を作成（ワンドロタイマー）</Button>
              </Box>

            </Grid>
            <Grid item xs={6} className={classes.gridPaddingNoborder}>
              <Box className={classes.centerBox}>
                <Button
                  variant={myFlg ? "contained" : "outlined"}
                  onClick={() => setMyFlg(pre => !pre)}
                ><PanoramaTwoToneIcon />私の日記リスト</Button>
              </Box>

            </Grid>

            {/** １段目 ===================================================================== */}
            {/** プロフィール情報グリッド */}
            <Grid item xs={12} className={classes.gridPaddingNoborder}>
              {diaryList.length > 0 && diaryListView()}
            </Grid>




            {/** 2段目 ===================================================================== */}



          </Grid>
          {/** GRIDオワリ　 ===================================================================== */}

        </Box>

      </HeadFooter >

    );
  }




}
export default DiaryList;

const style = {
  scoreLabel: {
    fontSize: 25,

  },
}

const useStyles = makeStyles((theme) => ({
  //オーバーレイ
  overlay: {
    zIndex: 10,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },//オーバーレイの中のモーダル
  modalView: {
    zIndex: 10,
    borderRadius: 15,
    width: '80%',
    padding: '1em',
    background: '#fff',
    overflow: 'scroll'
  },
  // 全体レイアウト
  contentWrapper: {
    marginTop: 20,
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",

  },
  // グリッド箱の書式 

  gridPadding: {
    padding: 5,
    border: '2px solid',
    borderColor: '#a6d4fa',
    backgroundColor: 'linear-gradient(to bottom right,#a6d4fa,#1976d2)',
    borderRadius: 10

  },
  // グリッド箱の書式 枠線なし
  gridPaddingNoborder: {
    padding: 5,


  },
  // カードの中のGrid
  gridCard: {
    margin: 5,
    padding: 5,
    border: '2px solid',
    borderColor: '#a6d4fa',
    borderRadius: 10

  },
  // 個別コンテンツ　中央揃えで縦に内容を並べる
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },// 個別コンテンツ　中央揃えで横に内容を並べる
  centerFlatBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftCenterBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftRowBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
  },
  // 個別コンテンツ　横並びに内容を並べる
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },



  // モーダルダイアログのフォーム　個別アイテム
  modalFormItem: {
    margin: 20,
  },
  // モーダルのグリッド全体
  modalGrid: {
    padding: 0,
  },
  //サンプルのグラフエリア
  sampleGraphEria: {
    width: 200
  },

  cardList: {
  },
  card: {
  },
  bottomCard: {
    width: 250
  },
}));


