/**
 * @class Swpie画面用のデータ移送DTO
 * @description Swipe画面を利用するためにデータを渡すためのオブジェクト。画像orアカウントなど１データあたりに１インスタンス
 */
class SwipeDataObject {

    /**
     * @constructor
     * @param {Object<T>}  data オブジェクトそのまま
     * @param {String} id データのid
     * @param {String} primaryText タイトル表示。ユーザ名等の用途
     * @param {String} profilePath 作者のプロフィールへのパス
     * @param {String} mainImagePath 表示する画像、product,portfori,heroImageのパス
     * @param {String} touchNextPage タッチ後の遷移先 id含めたnavigate引数形式のurlを設定
     * @param {string[]} tagList タッチ後の遷移先 id含めたnavigate引数形式のurlを設定
     * @param {string[]} kikakuTagList タッチ後の遷移先 id含めたnavigate引数形式のurlを設定
     * @param {boolean} nsfw r18制御要否
     */
    constructor(
        data,
        id,
        primaryText,
        profilePath,
        mainImagePath,
        touchNextPage,
        tagList,
        kikakuTagList,
        nsfw
    ) {
        /*
        console.group("SwipeDataObject オブジェクトのチェック")
        console.log(data)
        console.log(id)
        console.log(primaryText)
        console.log(profilePath)
        console.log(mainImagePath)
        console.log(touchNextPage)
        console.log(tagList)
        console.log(kikakuTagList)
        console.log(nsfw)
        console.groupEnd()
        */

        this.data = data;
        this.id = id;
        this.primaryText = primaryText;
        this.profilePath = profilePath;
        this.mainImagePath = mainImagePath;
        this.touchNextPage = touchNextPage;
        this.tagList = tagList;
        this.kikakuTagList = kikakuTagList;
        this.nsfw = nsfw;


        Object.freeze(this); // 値オブジェクトを凍結
    }

}
export default SwipeDataObject;
