import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import IfProxyCalender from '../../IfProxyCalender';


// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

//graph
import VictorySample from "../../common/graphs/VictorySample";

import EventCard from "./EventCard";
import IfProxy from '../../IfProxy';


const CalenderModalView = ({ clickedEventData, eventType, close }) => {
    const TYPE = Object.freeze({
        event: "event",
        aniversary: "aniversary"
    })
    const classes = useStyles();
    const ifProxy = new IfProxy();
    const ifProxyCalender = new IfProxyCalender();


    const { user } = useAuthContext();

    const [modalShow, setModalShow] = useState(true);
    const [creatorFlg, setCreatorFlg] = useState(false);
    const [showOwnerMessage, setShowOwnerMessage] = useState(false);


    // modalダイアログ用のstate値
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [startTime, setStartTime] = useState("");
    const [tags, setTags] = useState("");

    const [ganleTag, setGanleTag] = useState("");
    const [charactorTag, setCharactorTag] = useState("");
    const [featureTag1, setFeatureTag1] = useState("");
    const [featureTag2, setFeatureTag2] = useState("");
    const [featureTag3, setFeatureTag3] = useState("");
    const [featureTag4, setFeatureTag4] = useState("");

    const [kinenbiMonth, setKinenbiMonth] = useState("");
    const [kinenbiDate, setKinenbiDate] = useState("");
    const [monthErrorMessage, setMonthErrorMessage] = useState("");
    const [dateErrorMessage, setDateErrorMessage] = useState("");
    const [presentUrl, setPresentUrl] = useState("");



    const [dayName, setDayName] = useState(0);

    const [selectedSheet, setSelectedSheet] = useState("week");
    const [favoList, setFavoList] = useState();


    let first = true;
    useEffect((props) => {
        // 【useEffectの２重処理について】
        // https://zenn.dev/takeharu/scraps/d14cf9d4239ec4
        // useEffectは２回描画される
        // mount -> unmount -> mount
        // 処理がこのようにダブルのは開発環境だけ
        if (first) {
            //ログインユーザのuserDataを取得する
            ifProxy.getUserData(user.uid).then((userData) => {
                // クリエイターである場合はフラグがTrue
                if (userData.remitedCreator || userData.stripeId !== "") {
                    setCreatorFlg(true)
                }
            })
        }
        first = false;
        return () => {
        }

    }, []);


    // 登録フォーム ------------------------------------------------------------------------------
    /**
       * 企画を追加する
       */
    const addCalenderItemEvent = () => {

        let dayNameLabel = "曜日指定なし(「説明と参加時のルール」に従う)";
        switch (dayName) {
            case 0:
                dayNameLabel = "月曜日"
                break;
            case 1:
                dayNameLabel = "火曜日"
                break;
            case 2:
                dayNameLabel = "水曜日"
                break;
            case 3:
                dayNameLabel = "木曜日"
                break;
            case 4:
                dayNameLabel = "金曜日"
                break;
            case 5:
                dayNameLabel = "土曜日"
                break;
            case 6:
                dayNameLabel = "日曜日"
                break;
            case 7:
                dayNameLabel = "記念日"
                break;
            case 8:
                dayNameLabel = "曜日指定なし(「説明と参加時のルール」に従う)"
                break;
            default:
                break
        }

        const ganleTagData = [{
            id: ganleTag,
            text: ganleTag,
        }]
        const ganleTagDataStr = [ganleTag]
        const charactorTagData = [{
            id: charactorTag,
            text: charactorTag,
        }]
        const charactorTagDataStr = [charactorTag]
        let featureTagData = []
        let featureTagDataStr = []
        if (featureTag1) {
            featureTagData.push({
                id: featureTag1,
                text: featureTag1,
            })
            featureTagDataStr.push(featureTag1)
        }
        if (featureTag2) {
            featureTagData.push({
                id: featureTag2,
                text: featureTag2,
            })
            featureTagDataStr.push(featureTag2)

        }
        if (featureTag3) {
            featureTagData.push({
                id: featureTag3,
                text: featureTag3,
            })
            featureTagDataStr.push(featureTag3)

        }
        if (featureTag4) {
            featureTagData.push({
                id: featureTag4,
                text: featureTag4,
            })
            featureTagDataStr.push(featureTag4)
        }


        ifProxyCalender.addCalenderItem({
            type: TYPE.event, // 種別 企画(event) 誕生日など記念日(aniversary)
            name: name,	//名称
            description: description,	//	説明
            startTime: startTime,
            tags: tags,	//	投稿時に使用するタグ
            nameLabel: dayNameLabel,	//	曜日名
            dayName: dayName,	//	曜日タイプ
            kinenbiMonth: "",	//	記念日月
            kinenbiDate: "",	//	記念日日
            user: user,// 作成者
            presentUrl: presentUrl,
            // タグ情報
            ganleTagData,
            ganleTagDataStr,
            charactorTagData,
            charactorTagDataStr,
            featureTagData,
            featureTagDataStr
        }).then((result) => {
            window.location.reload()
        })
    }

    /**
    * 記念日を追加する
    */
    const addCalenderItemAniversary = () => {

        const ganleTagData = [{
            id: ganleTag,
            text: ganleTag,
        }]
        const ganleTagDataStr = [ganleTag]
        const charactorTagData = [{
            id: charactorTag,
            text: charactorTag,
        }]
        const charactorTagDataStr = [charactorTag]
        let featureTagData = []
        let featureTagDataStr = []
        if (featureTag1) {
            featureTagData.push({
                id: featureTag1,
                text: featureTag1,
            })
            featureTagDataStr.push(featureTag1)
        }
        if (featureTag2) {
            featureTagData.push({
                id: featureTag2,
                text: featureTag2,
            })
            featureTagDataStr.push(featureTag2)

        }
        if (featureTag3) {
            featureTagData.push({
                id: featureTag3,
                text: featureTag3,
            })
            featureTagDataStr.push(featureTag3)

        }
        if (featureTag4) {
            featureTagData.push({
                id: featureTag4,
                text: featureTag4,
            })
            featureTagDataStr.push(featureTag4)
        }

        ifProxyCalender.addCalenderItem({
            type: TYPE.aniversary, // 種別 企画(event) 誕生日など記念日(aniversary)
            name: name,	//名称
            description: description,	//	説明
            tags: tags,	//	投稿時に使用するタグ
            startTime: "",
            nameLabel: "記念日",	//	曜日名
            dayName: 7,	//	曜日タイプ
            kinenbiMonth: kinenbiMonth + "",	//	記念日月
            kinenbiDate: kinenbiDate + "",	//	記念日日
            user: user,//作成者
            presentUrl: presentUrl,

            // タグ情報
            ganleTagData,
            ganleTagDataStr,
            charactorTagData,
            charactorTagDataStr,
            featureTagData,
            featureTagDataStr

        }).then((result) => {
            window.location.reload()
        })
    }


    // 企画を登録するフォーム
    const kikakuForm = () => {
        return (
            <>
                <Box className={classes.centerBox}>
                    <Typography sx={{ fontSize: 20 }}>
                        #ワンドロ企画を登録する
                    </Typography>
                </Box>
                <Box sx={{
                    height: "700px",
                    overflow: "scroll"
                }}>
                    <Box className={classes.modalFormItem}>
                        <TextField fullWidth id="outlined-basic" label="#企画名"
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                            variant="outlined" value={name} />
                    </Box>
                    <Box className={classes.modalFormItem}>
                        <TextField fullWidth id="outlined-basic" label="説明と参加時のルール"
                            onChange={(e) => setDescription(e.target.value)}
                            variant="outlined" value={description} />
                    </Box>
                    <Box className={classes.modalFormItem}>
                        <TextField fullWidth id="outlined-basic" label="投稿時間"
                            onChange={(e) => setStartTime(e.target.value)}
                            variant="outlined" value={startTime} />
                    </Box>
                    <Box className={classes.modalFormItem}>
                        <TextField fullWidth id="outlined-basic" label="投稿時に使用するタグ"
                            onChange={(e) => setTags(e.target.value)}
                            variant="outlined" value={tags} />
                    </Box>
                    <Box className={classes.modalFormItem}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">実施する日</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={dayName}
                                label="実施曜日"
                                onChange={(e) => {
                                    setDayName(e.target.value)
                                }}
                            >
                                {/**0:月曜, 1:火曜 2:水曜, 3:木曜, 4金曜, 5土曜日, 6:日曜, 7:記念日 */}
                                <MenuItem value={0}>月曜日</MenuItem>
                                <MenuItem value={1}>火曜日</MenuItem>
                                <MenuItem value={2}>水曜日</MenuItem>
                                <MenuItem value={3}>木曜日</MenuItem>
                                <MenuItem value={4}>金曜日</MenuItem>
                                <MenuItem value={5}>土曜日</MenuItem>
                                <MenuItem value={6}>日曜日</MenuItem>
                                <MenuItem value={8}>曜日指定なし(「説明と参加時のルール」に従う)</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box className={classes.modalFormItem}>
                        <TextField fullWidth id="outlined-basic" label="運営アカウントのurl(ある場合)"
                            onChange={(e) => setPresentUrl(e.target.value)}
                            variant="outlined" value={presentUrl} />
                    </Box>

                    <Box className={classes.modalFormItem}>
                        <Typography sx={{ fontSize: 20 }}>
                            内容詳細
                        </Typography>
                    </Box>
                    <Box className={classes.modalFormItem}>
                        <TextField fullWidth id="outlined-basic" label="*ジャンル"
                            onChange={(e) => setGanleTag(e.target.value)}
                            variant="outlined" value={ganleTag} />
                    </Box>
                    <Box className={classes.modalFormItem}>
                        <TextField fullWidth id="outlined-basic" label="*キャラクター名"
                            onChange={(e) => setCharactorTag(e.target.value)}
                            variant="outlined" value={charactorTag} />
                    </Box>
                    <Box className={classes.modalFormItem}>
                        <TextField fullWidth id="outlined-basic" label="属性1"
                            onChange={(e) => setFeatureTag1(e.target.value)}
                            variant="outlined" value={featureTag1} />
                    </Box>
                    <Box className={classes.modalFormItem}>
                        <TextField fullWidth id="outlined-basic" label="属性2"
                            onChange={(e) => setFeatureTag2(e.target.value)}
                            variant="outlined" value={featureTag2} />
                    </Box>
                    <Box className={classes.modalFormItem}>
                        <TextField fullWidth id="outlined-basic" label="属性3"
                            onChange={(e) => setFeatureTag3(e.target.value)}
                            variant="outlined" value={featureTag3} />
                    </Box>
                    <Box className={classes.modalFormItem}>
                        <TextField fullWidth id="outlined-basic" label="属性4"
                            onChange={(e) => setFeatureTag4(e.target.value)}
                            variant="outlined" value={featureTag4} />
                    </Box>


                    <Box className={classes.actionForm} >
                        <Button
                            variant="contained"
                            sx={{ width: '200px' }}
                            disabled={
                                tags === "" ||
                                name === "" ||
                                ganleTag === "" ||
                                charactorTag === ""
                            }
                            onClick={() => {
                                addCalenderItemEvent();
                                close()
                            }}>登録する</Button>
                        <Button
                            variant="outlined"
                            sx={{ width: '200px', marginLeft: 5 }}
                            onClick={() => {
                                close()
                            }}>閉じる</Button>



                    </Box>
                </Box>

            </>
        )
    }


    // 記念日を登録するフォーム
    const AnivForm = () => {
        return (
            <>
                <Box className={classes.centerBox}>
                    <Typography sx={{ fontSize: 20 }}>
                        #記念日を登録する
                    </Typography>
                </Box>
                <Box className={classes.modalFormItem}>
                    <TextField fullWidth id="outlined-basic" label="*#記念日の名前"
                        onChange={(e) => {
                            setName(e.target.value)
                        }}
                        variant="outlined" value={name} />
                </Box>
                <Box className={classes.modalFormItem}>
                    <TextField fullWidth id="outlined-basic" label="記念日の説明"
                        onChange={(e) => setDescription(e.target.value)}
                        variant="outlined" value={description} />
                </Box>

                <Box className={classes.modalFormItem}>
                    <TextField fullWidth id="outlined-basic" label="*投稿時に使用するタグ"
                        onChange={(e) => setTags(e.target.value)}
                        variant="outlined" value={tags} />
                </Box>
                <Box className={classes.modalFormItem}>{monthErrorMessage}{dateErrorMessage}</Box>

                <Box className={classes.modalFormItem}>

                    <TextField id="outlined-basic" label="*記念日 月"
                        onChange={(e) => {
                            setMonthErrorMessage((pre) => {
                                if (e.target.value < 1 || e.target.value > 12 || isNaN(e.target.value)) {
                                    return "数値で月を入力してください"
                                } else {

                                }
                                return ""
                            })
                            setKinenbiMonth(e.target.value)
                        }}
                        variant="outlined" value={kinenbiMonth} />
                    <TextField id="outlined-basic" label="*記念日 日"
                        onChange={(e) => {
                            setDateErrorMessage((pre) => {
                                if (e.target.value < 1 || e.target.value > 31 || isNaN(e.target.value)) {
                                    return "数値で日を入力してください"
                                }
                                return ""
                            })
                            setKinenbiDate(e.target.value)
                        }}
                        variant="outlined" value={kinenbiDate} />
                </Box>
                <Box className={classes.modalFormItem}>
                    <TextField fullWidth id="outlined-basic" label="運営アカウントのurl(ある場合)"
                        onChange={(e) => setPresentUrl(e.target.value)}
                        variant="outlined" value={presentUrl} />
                </Box>

                <Box className={classes.modalFormItem}>
                    <Typography sx={{ fontSize: 20 }}>
                        内容詳細
                    </Typography>
                </Box>
                <Box className={classes.modalFormItem}>
                    <TextField fullWidth id="outlined-basic" label="*ジャンル(該当なしの場合 - を入れてください)"
                        onChange={(e) => setGanleTag(e.target.value)}
                        variant="outlined" value={ganleTag} />
                </Box>
                <Box className={classes.modalFormItem}>
                    <TextField fullWidth id="outlined-basic" label="*キャラクター名(該当なしの場合 - を入れてください)"
                        onChange={(e) => setCharactorTag(e.target.value)}
                        variant="outlined" value={charactorTag} />
                </Box>
                <Box className={classes.modalFormItem}>
                    <TextField fullWidth id="outlined-basic" label="属性1"
                        onChange={(e) => setFeatureTag1(e.target.value)}
                        variant="outlined" value={featureTag1} />
                </Box>
                <Box className={classes.modalFormItem}>
                    <TextField fullWidth id="outlined-basic" label="属性2"
                        onChange={(e) => setFeatureTag2(e.target.value)}
                        variant="outlined" value={featureTag2} />
                </Box>
                <Box className={classes.modalFormItem}>
                    <TextField fullWidth id="outlined-basic" label="属性3"
                        onChange={(e) => setFeatureTag3(e.target.value)}
                        variant="outlined" value={featureTag3} />
                </Box>
                <Box className={classes.modalFormItem}>
                    <TextField fullWidth id="outlined-basic" label="属性4"
                        onChange={(e) => setFeatureTag4(e.target.value)}
                        variant="outlined" value={featureTag4} />
                </Box>

                <Box className={classes.actionForm} >
                    <Button
                        variant="contained"
                        sx={{ width: '200px' }}
                        disabled={
                            kinenbiDate === "" || tags === "" || name === "" ||
                            monthErrorMessage !== "" || dateErrorMessage !== "" ||
                            ganleTag === "" ||
                            charactorTag === ""
                        }
                        onClick={() => {
                            addCalenderItemAniversary();
                            setModalShow(pre => !pre);
                        }}>登録する</Button>
                    <Button
                        variant="outlined"
                        sx={{ width: '200px', marginLeft: 5 }}
                        onClick={() => {
                            //setModalShow(pre => !pre)
                            close()
                        }}>閉じる</Button>
                </Box>

            </>
        )
    }

    // 閲覧ビュー ------------------------------------------------------------------------------
    // 企画や記念日の詳細を見るモーダル
    const eventView = () => {
        return (
            <>
                <Box className={classes.centerBox}>
                    <Typography sx={{ fontSize: 20 }}>
                        #ワンドロ企画を登録する
                    </Typography>
                </Box>
                <Box sx={{
                    height: "500px",
                    overflow: "scroll"
                }}>

                    <Box className={classes.modalFormItem}>
                        <Typography sx={{ fontSize: 20 }}>
                            {clickedEventData.name}
                        </Typography>
                    </Box>
                    <Box className={classes.modalFormItem}>
                        <Box className={classes.leftBox}>
                            <Typography sx={{ fontSize: 14 }}>
                                <b>内容説明またはワンドロ企画参加時のルール</b>
                            </Typography>

                            <Typography sx={{ fontSize: 14 }}>
                                {clickedEventData.description}
                            </Typography>
                        </Box>
                    </Box>

                    <Box className={classes.modalFormItem}>
                        <Box className={classes.leftBox}>
                            <Typography sx={{ fontSize: 14 }}>
                                <b>投稿時に使用するタグ</b>
                            </Typography>

                            <Typography sx={{ fontSize: 14 }}>
                                {clickedEventData.tags}
                            </Typography>
                        </Box>
                    </Box>

                    <Box className={classes.modalFormItem}>
                        <Box className={classes.leftBox}>
                            <Typography sx={{ fontSize: 14 }}>
                                <b>運営アカウントのurl(ある場合)</b>
                            </Typography>

                            <Typography sx={{ fontSize: 14 }}>
                                <a href={clickedEventData.presentUrl}>{clickedEventData.presentUrl}</a>
                            </Typography>
                        </Box>
                    </Box>


                    {/* ワンドロ用の項目 */}
                    {clickedEventData.type === "event" && <Box className={classes.leftBox}>
                        <Box className={classes.modalFormItem}>
                            <Typography sx={{ fontSize: 14 }}>
                                <b>実施日</b>
                            </Typography>

                            <Typography sx={{ fontSize: 14 }}>
                                {clickedEventData.nameLabel}
                            </Typography>
                        </Box>
                    </Box>}
                    {clickedEventData.type === "event" && <Box className={classes.leftBox}>
                        <Box className={classes.modalFormItem}>
                            <Typography sx={{ fontSize: 14 }}>
                                <b>実施時間</b>
                            </Typography>

                            <Typography sx={{ fontSize: 14 }}>
                                {clickedEventData.startTime}
                            </Typography>
                        </Box>
                    </Box>}


                    {/* 記念日用の項目 */}
                    {clickedEventData.type === "aniversary" && <Box className={classes.leftBox}>
                        <Box className={classes.modalFormItem}>
                            <Typography sx={{ fontSize: 14 }}>
                                <b>日付</b>
                            </Typography>

                            <Typography sx={{ fontSize: 14 }}>
                                {clickedEventData.kinenbiMonth}月{clickedEventData.kinenbiDate}日
                            </Typography>
                        </Box>
                    </Box>}


                </Box>
                <Box className={classes.actionForm} >
                    <Box className={classes.modalFormItem}>
                        <Button
                            variant="contained"
                            sx={{ width: '200px' }}
                            disabled={!creatorFlg && clickedEventData.createdUid == user.uid}
                            onClick={() => {
                                ifProxyCalender.deleteCalenderItem(clickedEventData.id).then((result) => {
                                    window.location.reload()
                                })
                            }}>削除(作成者のみ) </Button>
                    </Box>
                    <Box className={classes.modalFormItem}>
                        <Button
                            variant="outlined"
                            sx={{ width: '200px' }}
                            onClick={() => {
                                //setModalShow(pre => !pre)
                                close()
                            }}>閉じる</Button>
                    </Box>
                </Box>

            </>
        )
    }
    const modalView = () => {
        if (modalShow) {
            return (
                <>
                    <Box className={classes.overlay}>
                        <Box sx={{
                            zIndex: 10,
                            borderRadius: 5,
                            width: '50%',
                            minWidth: '500px',
                            padding: '1em',
                            background: '#fff',
                            opacity: modalShow ? 1 : 0,
                            transition: 'all 0.3s',
                        }} onClick={(e) => e.stopPropagation()}>
                            {eventType === "kikaku" && kikakuForm()}
                            {eventType === "aniv" && AnivForm()}
                            {eventType === "eventView" && eventView()}
                            {forOwnerMessage()}
                        </Box>

                    </Box >
                </>
            )
        }

    }
    const forOwnerMessage = () => {
        if (!showOwnerMessage) {
            return (
                <>
                    <Button onClick={() => setShowOwnerMessage(true)}>このワンドロの企画者の方へ</Button>
                </>
            )
        } else {
            return (
                <>
                    <Typography>
                        掲載内容の取り下げ、及び変更がご希望でしたら、次のアカウントへDMにてお問い合わせください
                    </Typography>
                    <a href="https://twitter.com/illustrental">サポートアカウント</a>
                    <Typography>
                        企画のPR等我々で可能なことがございましたらご協力いたします。お気軽にご相談ください
                    </Typography>
                </>
            )

        }
    }


    return (
        <>
            {/* オーバーレイ用の背景要素 */}
            {modalView()}
        </>
    )


}
export default CalenderModalView;

const useStyles = makeStyles((theme) => ({
    //オーバーレイ
    overlay: {
        zIndex: 10,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    leftBox: {
        paddingTop: 10,
    },
    modalFormItem: {
        margin: 10,
    },
    actionForm: {
        display: 'flex'
    }

}));