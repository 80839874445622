import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@mui/material/Checkbox';




const ShareStance = ({ paramStatus, setResult }) => {

    const classes = useStyles();
    const [status, setStatus] = useState(paramStatus);


    return (
        <>
            <Box className={classes.leftBox}>
                <Typography variant="h6" >シェアされたら嬉しいですか？</Typography>
                <Checkbox
                    checked={paramStatus}
                    size="large"
                    onChange={() => setStatus(prevState => {
                        setResult(!prevState)
                        return !prevState
                    })} />
                <Typography variant="h6" >{status ? "ぜひたくさんシェアしてください" : "あまりシェアして欲しくないです"}</Typography>


            </Box>

        </>
    )
}

export default ShareStance;


const useStyles = makeStyles((theme) => ({

    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftRowBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'left',
    },
    // 個別コンテンツ　横並びに内容を並べる
    leftBox: {
        alignItems: "center",
        display: 'flex',
        flexDirection: 'row',
        margin: "0 auto",
    },


}));
