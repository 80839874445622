import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from './../../AuthContext';

// TODO: StandardPageはパスが異なるため注意
import IfProxy from './../../IfProxy';
import HeadFooter from './../HeadFooter';
import SnsUtil from './../../common/SnsUtil';
import AccountCard from './../../common/AccountCard';

import AnchorLink from 'react-anchor-link-smooth-scroll';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const RuleAndManner = (props) => {

  const [listItems, setListItems] = useState(0);

  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const ifProxy = new IfProxy();


  const location = useLocation();

  useEffect((props) => {
    loadData(props);
    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    ifProxy.gaEventTest("chowCreatorLp")
    // ------
  }, []);


  const loadData = (props) => {


    // データコール
    //ifProxy.getDetailData().then((result) => {

    //  setListItems(result);
    //})
  }


  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  // 無効状態へ
  if (false) {
    return <Navigate to="/login" />;
  } else {

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      <>

        <Grid container alignItems="center" justify="center" >


          {/* ---------------------------------- */}
          <Grid item sm={12} md={12} >
            <Box className={classes.blueColLeftItem}>
              <h1>クリエイター様へのご案内</h1>
              <p>具体的なリクエストの受け方やルールなどをご案内いたします</p>
              <p>また、<b>できることはリクエストだけではありません</b><br></br>
                普段の練習やワンドロに便利な機能をご用意いたします
              </p>
            </Box>
          </Grid>
          {/* --------------  目次　-------------------- */}
          <Grid item sm={12} md={6} >
            <Box className={classes.whiteColLeftItem}>
              <h1>クリエイター支援機能概要</h1>
            </Box>
          </Grid>
          <Grid item sm={12} md={6} >
            <Box className={classes.whiteColLeftItem}>
              リクエストに関する機能
              <AnchorLink href="#request" offset="50">
                <h2 style={{ color: '#0099ff', textDecoration: 'underline white' }}>1) 普段の練習やワンドロを収益化:<br></br>リクエスト機能 </h2>
              </AnchorLink>
              <AnchorLink href="#odai" offset="50">
                <h2 style={{ color: '#0099ff', textDecoration: 'underline white' }}>2) リクエストが来なければ自分から撮りに行く:<br></br>お題機能 </h2>
              </AnchorLink>

              リクエスト以外のワンドロ支援機能
              <AnchorLink href="#calender" offset="50">
                <h2 style={{ color: '#0099ff', textDecoration: 'underline white' }}>3) 「#今日は何の日？」: <br></br>みんなで作るワンドロカレンダー </h2>
              </AnchorLink>

              <AnchorLink href="#timer" offset="50">
                <h2 style={{ color: '#0099ff', textDecoration: 'underline white' }}>4) 時間のかかっている所を発見：<br></br>ワンドロタイマー </h2>
              </AnchorLink>

              <AnchorLink href="#diary" offset="50">
                <h2 style={{ color: '#0099ff', textDecoration: 'underline white' }}>5) 練習の記録・分析・共有して、モチベーションアップ：<br></br>ワンドロ日記 </h2>
              </AnchorLink>


            </Box>
          </Grid>
          <Grid item sm={12} md={12} ><hr></hr></Grid>

          {/* ---------------- リクエストのルール------------------ */}

          <Grid item sm={12} md={12} >

            <Box className={classes.whiteColLeftItem}>
              <h1> リクエストのルール</h1>
              <p>本サービスは、一般的なコミッションや請負サービスとは大きく異なる重要なルールがございます</p>
              <p>クリエイター様、リクエストユーザー様が気持ちよくご利用いただけるよう。以下のルールをお守りください</p>
            </Box>
          </Grid>
          {/* ---------------- リクエストの仕組み ------------------ */}
          <Grid item sm={12} md={12} >
            <Box className={classes.blueColLeftItem}>
              <h1>本サービスの特殊な点 </h1>
              一般的なコミッションとは次の点で異なります
              <br></br><br></br>
              <b>ワンドロ作品のリクエストである</b>
              制作の請負より、作家様の練習や普段のワンドロに対して、リクエストと応援をしている感覚になります
              <br></br><br></br>
              <b>完成が確約されない</b>
              1時間の作業時間でできた範囲で納品されます。最悪の場合ラフの状態で時間切れとなり、納品される可能性もございます
              <br></br>（ですので、過去の実績をよくご確認ください）
              <br></br>もちろん、リクエスト時に「未完成の場合は納品不要」チェックを付けることで、未完成時は自動でリクエストキャンセルとすることも可能です
              <br></br><br></br>
              <b>報酬は現金、または拡散数</b>
              リクエスト利用者は、オファー金額を提示してリクエストをする。または、もし完成したらどれくらいSNSで拡散を協力する。という提示を行うことができます。<br></br>
              作家様はこれらの提示オファーを見て、どのリクエストを受けるかを自由に判断することができます
            </Box>
          </Grid>
          {/* ---------------- リクエストの特徴------------------ */}

          <Grid item sm={12} md={4} >
            <Box className={classes.blueColLeftItem} sx={{ height: '550px' }}>
              <h1 id="request"> このルールの<br></br>メリット</h1>

            </Box>
          </Grid>
          <Grid item sm={12} md={8} >
            <Box className={classes.whiteColLeftItem} sx={{ height: '550px' }}>
              <p>失敗が許容され<b>拘束時間１時間でリクエストを終えられる</b>ので</p>
              <h2>・隙間時間でできる<br></br>
                ・量をこなすことができる<br></br>
                ・「練習」を収益化できる<br></br>
                ・配信枠１時間に収まるコミッションができる
              </h2>
              <br></br>
              <p><b>「1時間分の作業した時間」</b>に対しての報酬なので</p>
              <h2>「時給」水準の価格帯で依頼できる。<br>
              </br>請負の案件に比べると、気軽なリクエストが行いやすい</h2>
              <br></br><br></br>
              <h2>このルールを作家様とリクエスト者合意の上で実施します</h2>
            </Box>
          </Grid>


          {/* ---------------- リクエストのルール------------------ */}

          <Grid item sm={12} md={12} >
            <Box className={classes.whiteColLeftItem}>
              <h1 id="request"> リクエストの流れ</h1>
              これより、実際の利用の流れを見ながら、ルールのご説明をいたします<br></br>
              作家様、リクエスト利用者様、双方ともそれぞれの立場のルールをご理解ください
              <br></br><br></br><br></br><br></br>
              たった４ステップになりますので<br></br>
              少しだけお付き合いください
            </Box>
            <br></br>
            <Box className={classes.whiteColCenterItem}>
              <AnchorLink href="#anotherService" offset="50">
                <Button variant="contained" sx={{ margin: '10px' }} size="large">
                  とりあえず、先に何ができるのか知りたいようでしたら<br></br>こちらからスキップいただけます</Button>
              </AnchorLink>
            </Box>

          </Grid>





          <Box className={classes.contentWrapper}>
            <Box className={classes.mainBox} >


              <hr></hr>
              <Box className={classes.leftAlignBox} >
                <h1>1</h1>
                <Typography variant="h5" component="div" sx={style.h3Text}>
                  <b>リクエスト利用者さまが、リクエストする作家様を選ぶ </b>
                </Typography>
                <Box className={classes.leftBox} >
                  <img src={`${process.env.PUBLIC_URL}/g1.png`}
                    style={{
                      width: '50%',
                      maxWidth: "500px",
                      margin: "0 auto",
                      opacity: 0.8,
                      display: "block",
                    }} />
                  <Typography variant="h5" component="div" sx={style.bodyText}>
                    リクエストする利用者は、気に入ったクリエイターのプロフィールを見ます<br></br>
                    ここで、ワンドロ（１時間）でどれくらいのクオリティなのか。<br></br>
                    成功率はどれくらいかをチェックしてください。<br></br>
                    <br></br><br></br>
                    リテイクが仕組み上できません。作家様がワンドロに失敗しラフの状態で終わってしまった場合はそのまま納品される可能性がございます<br></br>
                    ですので、過去のワンドロの成果や、成功率をご確認の上、リクエスト金額などご判断ください

                  </Typography>
                </Box>
              </Box>




              {/* =================================================================== */}
              <hr></hr>
              <Box className={classes.leftAlignBox} >
                <h1>2</h1>
                <Typography variant="h5" component="div" sx={style.h3Text}>
                  <b> リクエスト利用者さまが、リクエストをする </b>
                </Typography>

                <Box className={classes.leftBox} >
                  <img src={`${process.env.PUBLIC_URL}/g2.png`}
                    style={{
                      width: '50%',
                      maxWidth: "500px",
                      margin: "0 auto",
                      opacity: 0.8,
                      display: "block",
                    }} />

                  <Typography variant="h5" component="div" sx={style.bodyText}>
                    リクエスト利用者はリクエストを行います<br></br><br></br>
                    オーダー内容を文章で提示します<br></br>
                    作家様はワンドロで実施しますため詳細な仕様は対応できない可能性が高いです。あくまでも「ワンドロのテーマ」という意識でオーダーください<br></br><br></br><br></br>

                    未完成だったら入金しない<br></br><br></br>
                    リクエスト利用者はリクエスト時に次のオプションを選び、作家様へ伝えることができます<br></br>
                    「未完成だったら納品不要」<br></br>
                    「未完成の場合できたところまでで納品して欲しい」<br></br><br></br>
                    ※ 未完成かどうかは作家様が判断します。<br></br><br></br><br></br>

                    作家様はこれら上記を踏まえて、受諾するかどうか、また終了時に納品するかどうかを判断します。また<br></br>
                    納品がされた場合、リクエスト利用者はオファー時に提示した料金の支払いをする必要があります<br></br>
                    納品がされなかった場合(作家がキャンセルした場合)、リクエスト利用者は支払いをする必要はございません<br></br>

                  </Typography>
                </Box>
              </Box>

              {/* =================================================================== */}
              <hr></hr>
              <Box className={classes.leftAlignBox} >
                <h1>3</h1>

                <Typography variant="h5" component="div" sx={style.h3Text}>
                  <b> 作家様が、リクエストを受ける </b>
                </Typography>
                <Box className={classes.leftBox} >
                  <img src={`${process.env.PUBLIC_URL}/g3.png`}
                    style={{
                      width: '50%',
                      maxWidth: "500px",
                      margin: "0 auto",
                      opacity: 0.8,
                      display: "block",
                    }} />
                  <Typography variant="h5" component="div" sx={style.bodyText}>
                    リクエストを受けてから受諾するまでは１週間程度が期限です<br></br>
                    リクエストを受諾してから１週間程度が納品期限となります<br></br><br></br>
                    納品までに要した期間の平均はプロフィール上に掲載されアピール材料となります

                  </Typography>
                </Box>
              </Box>
              {/* =================================================================== */}
              <hr></hr>

              <Box className={classes.leftAlignBox} >
                <h1>4</h1>

                <Typography variant="h5" component="div" sx={style.h3Text}>
                  以下、トラブルを防ぐために特に重要なルールになります<br></br><br></br>
                  作家様、リクエストをされる方双方とも理解・合意の上、リクエストを実施してください
                </Typography>
              </Box>

              <Box className={classes.leftAlignBox} >
                <Typography variant="h5" component="div" sx={style.h3Text}>
                  <b> 作家様が、制作する。納品する </b>
                </Typography>
                <Box className={classes.leftBox} >
                  <img src={`${process.env.PUBLIC_URL}/g4.png`}
                    style={{
                      width: '50%',
                      maxWidth: "500px",
                      maxHeight: "300px",
                      margin: "0 auto",
                      opacity: 0.8,
                      display: "block",
                    }} />
                  <Typography variant="h5" component="div" sx={style.bodyText}>
                    ワンドロに失敗した場合<br></br><br></br>
                    作業時間が１時間を超過した場合<br></br>
                    作業時間の計測などは作家様ご自身の管理となりますため<br></br>
                    多少の超過については作家様判断で許容していただいて問題ございません。<br></br>
                    ワンドロというルールの意図としましては、「作業負担を１時間作業に抑え込む」ことになります<br></br>
                    ・１時間作業に限られていることにより負担から作家様を守る<br></br>
                    ・作家様の負担を強いないリクエストだから気軽に依頼できる。<br></br>
                    という趣旨が守れていましたら問題ございません<br></br><br></br><br></br>

                    リテイクは不要<br></br><br></br>
                    作家様に限らずリクエスト利用者様にもご理解いただきた重要なポイントとして、ワンドロに失敗した場合、リテイクやリカバリのための作業は不要としています。<br></br>
                    失敗リスクがあり、品質も１時間作業である。ということを合意した上でのリクエストであることを双方ともにご理解ください<br></br><br></br><br></br>

                    リクエストが「失敗時は納品不要」オプションが選択されている場合<br></br><br></br>
                    作家様は、作家様都合としてリクエスト受諾をキャンセルすることが可能です。この場合リクエスト依頼者は料金のお支払いが免除されます<br></br>
                    また、作家様が完成であると判断して納品した場合は、お支払いは必要となります。<br></br>
                    リクエストが「未完成の場合できたところまでで納品して欲しい」オプションが選択されている場合<br></br>
                    作家様は納品を行い、リクエスト利用者はお支払いが必要となります<br></br><br></br><br></br><br></br>

                    納品後にリクエスト利用者がお支払いをしなかった場合<br></br><br></br>
                    リクエストのステータスは「お支払い待ち」ステータスのままとなります<br></br>
                    また、リクエスト利用者様がお支払い待ちステータスのリクエストを何件持っているかはプロフィール画面に掲載されます<br></br>
                    作家様はリクエストの受諾前に、リクエスト利用者様の未入金件数を確認の上、受諾の可否を判断することが可能です<br></br>

                  </Typography>
                </Box>
              </Box>

              {/* =================================================================== */}
              <hr></hr>
              <Box className={classes.leftAlignBox} >
                <Typography variant="h5" component="div" sx={style.h3Text}>
                  <b> ご利用のコツ </b>
                </Typography>
                <Typography variant="h5" component="div" sx={style.bodyText}>
                  リクエスト利用者様へ。リクエストを受けてもらうコツ<br></br>
                  リクエストは、「賛同数」を投票するボタンがああります
                  この投票数は匿名です。<br></br>
                  この「賛同数」の多さは、リクエストの注目（完成したら絵が見たいと思っている人の数）を示すことができます<br></br>
                  「賛同数」を増やすためには、ご自身のリクエストを、SNS上にシェアする。などして、共感する閲覧者を増やすことで獲得が可能です<br></br><br></br>

                  また、「描いたらシェアする」ボタンも効果的です<br></br><br></br>
                  せっかく描いたイラストを、作家様は多くに見てもらいたいと思っていることが多いです。<br></br>
                  もし完成したらシェアしてくれる人がどれくらいいるのかがわかることは、作家様のモチベーションに繋がり、リクエストの魅力が向上します<br></br><br></br>
                  また、描いたらシェアするボタンは、作品が納品された時に、それを押した人に通知しシェアを促すことができます<br></br><br></br><br></br><br></br>

                  作家様へ。喜ばれるリクエストを選ぶコツ<br></br>
                  上述と逆になりますが、賛同数や、描いたらシェアするボタンから、そのリクエストがどれくらい多くの人が求めているかを確認することができます。<br></br><br></br>
                  気になるリクエストがありましたら、それを作家様自身のSNSタイムライン等に投稿して、作家様のファンのニーズ感を測ってみることも効果的だと考えられます<br></br>


                </Typography>
              </Box>


              {/* =================================================================== */}
              <hr></hr>
              <Box className={classes.leftAlignBox} >

              </Box>

            </Box>
          </Box>

          {/* ---------------- できることをもっとご紹介 ------------------ */}
          {/* ---------------- ヘッダ ------------------ */}

          <Grid item sm={12} md={12} >
            <Box className={classes.blueColCenterItem} sx={{ height: '300px' }}>
              <h2 id="anotherService">できることをもっとご紹介</h2>


              <p>
                本サービスは総合的なワンドロ支援サービスを目指しています<br></br>
                通常のリクエストの他にもワンドロをするのに役に立つサービスがございます<br></br>
                (こんなのあったらいいな。こういう使い勝手が良いな。といったご意見大歓迎です)
              </p>
            </Box>

            {/* ---------------- お題の紹介 ------------------ */}

          </Grid>
          <Grid item sm={12} md={6} >
            <Box className={classes.whiteColLeftItem} sx={{ height: '500px' }}>
              <h1 id="odai">お題掲示板機能</h1>
              <h2>「リクエストが来ない」も心配なし</h2>
              <h2>空いた時、待たずに自分からリクエストを取りに行ける</h2>

            </Box>
          </Grid>
          <Grid item sm={12} md={6} >
            <Box className={classes.whiteColLeftItem} sx={{ height: '500px' }}>
              <ul>
                <li>お題機能は、「こんな絵を描いてくれる人募集」といった、宛先のないアイデアを投稿する機能です</li>
                <li>お題はリクエストとして受けることができます。無料限定の場合・有料の場合があります</li>
                <li>作家様がお題に対して、描いてもいいと思ったら、お題投稿者に「描いてもいいよ」を、ワンクリックで通知できます</li>
                <li>お題投稿者がそれに興味を持ったら、リクエストを作家様に行います</li>
                <li><b>一言で言うと、クリエイター様から、リクエストする人へPRし、リクエストを引き出す機能です</b></li>

              </ul>
              <Button variant="outlined" size="large" onClick={() => navigate('/GuidTu6')}>詳しくはこちら</Button>
            </Box>
          </Grid>
          {/* ---------------- カレンダーの紹介 ------------------ */}

          <Grid item sm={12} md={6} >
            <Box className={classes.blueColLeftItem} sx={{ height: '500px' }}>
              <h1 id="calender">ワンドロカレンダー </h1>
              <h2>「#今日は何の日？」をみんなで共有</h2>
              <ul>
                <li>「#○月○日はxxの日」のような記念日を共有する一覧表です</li>
                <li>クリエイター登録をしたユーザは誰でも追加が可能です</li>
                <li>お題を探したいときにスケジュールの参考にできます</li><br></br>
                <li>現在行われている毎週のワンドロ企画を見つけることができます</li>
                <li>また企画を始めたい時、こちらで宣伝していただいても構いません</li><br></br>
                <li><b>カレンダーはまだ未完成です。ぜひ推しの日付を追記ください</b></li>

              </ul>
            </Box>
          </Grid>
          <Grid item sm={12} md={6} >
            <Box className={classes.whiteRowItem} sx={{ height: '500px' }}>
              <img src={`${process.env.PUBLIC_URL}/cal1.png`}
                style={{
                  width: '50%',
                  height: 'auto',
                  margin: "0 auto",
                  opacity: 0.8,
                  display: "block",
                  objectFit: 'cover',
                  objectPosition: '-10px'
                }} />
              <img src={`${process.env.PUBLIC_URL}/cal2.png`}
                style={{
                  width: '50%',
                  height: 'auto',

                  margin: "0 auto",
                  opacity: 0.8,
                  display: "block",
                  objectFit: 'cover'
                }} />


            </Box>

          </Grid>

          {/* ---------------- ワンドロタイマーの紹介 ------------------ */}

          <Grid item sm={12} md={6} >
            <Box className={classes.whiteColLeftItem} sx={{ height: '500px' }}>
              <h1 id="timer">ワンドロタイマー </h1>
              <h2>自分のワンドロの苦手発見。作業効率アップの武器に</h2>
              <ul>
                <li>各作業段階の時間管理を支援するストップウォッチです</li><br></br>
                <li><b>どの工程に・どれくらいの時間がかかったかを毎日記録できます</b></li><br></br>
                <li>作業中は忙しくで振り返りができませんが、こうして客観的なデータを記録に残すことで、ワンドロの時間戦略や弱点発見の役に立ちます</li>


              </ul>
              <Button variant="outlined" size="large" onClick={() => navigate('/GuidTu16')}>詳しくはこちら</Button>

            </Box>
          </Grid >
          <Grid item sm={12} md={6} >
            <Box className={classes.whiteColLeftItem} sx={{ height: '500px' }}>
              <img src={`${process.env.PUBLIC_URL}/tu16.2.png`}
                style={{
                  height: '100%',

                  margin: "0 auto",
                  opacity: 0.8,
                  display: "block",
                  objectFit: 'cover'
                }} />

            </Box>

          </Grid>

          {/* ---------------- 日記の紹介 ------------------ */}

          <Grid item sm={12} md={6} >
            <Box className={classes.blueColLeftItem} sx={{ height: '500px' }}>
              <h1 id="diary">ワンドロ日記 </h1>
              <h2>自分のワンドロの苦手発見。作業効率アップの武器に</h2>
              <ul>
                <li>日々の練習を日記に残すことができます</li>
                <li>完成した作品と合わせて</li>
                <li>ワンドロタイマーで計測した工程ごとの時間データ</li>
                <li>作業時にした失敗数などの集計データを記録に残すことができます</li><br></br>
                <li><b>日々の積み重ねを日記に残し、モチベーションに繋げましょう</b></li>

              </ul>
              <Button variant="outlined" size="large" onClick={() => navigate('/GuidTu16')}>詳しくはこちら</Button>

            </Box>
          </Grid>
          <Grid item sm={12} md={6} >
            <Box className={classes.blueColLeftItem} sx={{ height: '500px' }}>
              <img src={`${process.env.PUBLIC_URL}/tu16.4.png`}
                style={{
                  height: '100%',

                  margin: "0 auto",
                  opacity: 1,
                  display: "block",
                  objectFit: 'cover'
                }} />

            </Box>

          </Grid>

          {/* ---------------- プレミアム ------------------ */}

          <Grid item sm={12} md={12} >
            <Box className={classes.whiteColCenterItem}>
              <h1>プレミアム登録</h1>
              <h2>さらなるPR機能を活用</h2>
              <p>
                機能は現在も拡張中です。一部PRの役に立つ機能プレミアム会員限定でご用意しております。
              </p>
            </Box>
            <Box className={classes.whiteColCenterItem} >
              <Grid container alignItems="center" justify="center" sx={{ width: '60%', border: '1px solid' }}>
                {/* ---------------- プレミアム１行目 ------------------ */}
                <Grid item sm={6} md={6} >
                  機能
                </Grid>
                <Grid item sm={3} md={3} >
                  無料会員
                </Grid>
                <Grid item sm={3} md={3} >
                  プレミアム会員(β)<br></br>
                  月額100円
                </Grid>

                {/* ---------------- プレミアム2行目 ------------------ */}
                <Grid item sm={6} md={6} >
                  リクエストの投稿
                </Grid>
                <Grid item sm={3} md={3} >
                  ⚪︎
                </Grid>
                <Grid item sm={3} md={3} >
                  ⚪︎
                </Grid>
                {/* ---------------- プレミアム2行目 ------------------ */}
                <Grid item sm={6} md={6} >
                  リクエストの募集
                </Grid>
                <Grid item sm={3} md={3} >
                  ⚪︎
                </Grid>
                <Grid item sm={3} md={3} >
                  ⚪︎
                </Grid>
                {/* ---------------- プレミアム3行目 ------------------ */}
                <Grid item sm={6} md={6} >
                  有料リクエストの募集
                </Grid>
                <Grid item sm={3} md={3} >
                  ⚪︎
                </Grid>
                <Grid item sm={3} md={3} >
                  ⚪︎
                </Grid>
                {/* ---------------- プレミアム4行目 ------------------ */}
                <Grid item sm={6} md={6} >
                  お題の投稿・受諾
                </Grid>
                <Grid item sm={3} md={3} >
                  ⚪︎
                </Grid>
                <Grid item sm={3} md={3} >
                  ⚪︎
                </Grid>
                {/* ---------------- プレミアム5行目 ------------------ */}
                <Grid item sm={6} md={6} >
                  ワンドロタイマー
                </Grid>
                <Grid item sm={3} md={3} >
                  ⚪︎
                </Grid>
                <Grid item sm={3} md={3} >
                  ⚪︎
                </Grid>
                {/* ---------------- プレミアム6行目 ------------------ */}
                <Grid item sm={6} md={6} >
                  ワンドロ日記
                </Grid>
                <Grid item sm={3} md={3} >
                  ⚪︎
                </Grid>
                <Grid item sm={3} md={3} >
                  ⚪︎
                </Grid>
                {/* ---------------- プレミアム7行目 ------------------ */}
                <Grid item sm={6} md={6} >
                  プレミアムPR
                </Grid>
                <Grid item sm={3} md={3} >
                  -
                </Grid>
                <Grid item sm={3} md={3} >
                  ⚪
                </Grid>
                {/* ---------------- プレミアム8行目 ------------------ */}
                <Grid item sm={6} md={6} >
                  広告非表示
                </Grid>
                <Grid item sm={3} md={3} >
                  -
                </Grid>
                <Grid item sm={3} md={3} >
                  ⚪︎
                </Grid>
                {/* ---------------- プレミアム9行目 ------------------ */}
                <Grid item sm={6} md={6} >
                  クリエイターLP魅力度レポート(準備中)
                </Grid>
                <Grid item sm={3} md={3} >
                  -
                </Grid>
                <Grid item sm={3} md={3} >
                  ⚪︎
                </Grid>



              </Grid>
            </Box>
          </Grid>
          {/* ---------------- 次のアクション ------------------ */}

          <Grid item sm={12} md={12} >
            <Box className={classes.blueColLeftItem}>
              <h1> 次のアクション </h1>

              <ul>
                <li>もしクリエイター登録をすることをを決めましたら、次に実施することは、クリエイター登録です</li>
                <li>クリエイター登録を行うとリクエストを受付開始することができます</li>
                <li><b>クリエイター登録は画面上部の「クリエイター登録」ボタンから</b></li>
                <li>以下から登録ガイドを閲覧できます</li>
              </ul>
              <Button
                variant="contained"
                onClick={() => navigate('/ManualStripe')}
                sx={{
                  margin: 2, borderRadius: 20, height: 50, color: "#fff", background: 'linear-gradient(to bottom right,#2CD8D5 0%, #C5C1FF 56%, #FFBAC3 100%)'
                }}>
                クリエイター登録ガイド
              </Button>
              <ul>
                <li>ユーザ登録ができたら、入門コースへ進み、機能を開放しつつ利用方法を把握しましょう</li>
                <li><b>一旦まだ無料リクエストの範囲までの利用ぐらいにしたい</b></li>
                <li><b>まだ軽く見るだけにとどめて、様子見をしたい</b></li>
                <li>といった場合も、こちらへお進みください</li>
              </ul>
              <Button
                variant="contained"
                sx={{
                  borderRadius: "10px",
                  backgroundColor: "white",
                  color: "#0099ff"
                }}
                onClick={() => navigate('/tu')}
              >
                入門コース
              </Button>
            </Box>

          </Grid>
          {/* -------------- ナビゲーション-------------------- */}
          <Grid item sm={12} md={12} >
            <Button variant="outlined" sx={{ margin: '10px' }} fullWidth onClick={() => navigate(-1)}> 戻る</Button>
          </Grid>
        </Grid >


      </>


    );
  }




}
export default RuleAndManner;

const baseColorBlue = '#0099ff'
const subColorBlue = '#CCD8FF'

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト　中心揃えにする
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  // コンテンツの幅。端末ごとに広がりすぎるのを防ぐ。左揃え
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",
  },
  // 個別コンテンツ　中央揃えで縦に内容を並べる
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftAlignBox: {
    display: 'flex',
    marginTop: '70px',
    flexDirection: 'column',
    maxWidth: "800px",
    minWidth: "600px",
    alignItems: 'left',
  },
  // 個別コンテンツ　横並びに内容を並べる
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },
  cardList: {
  },
  card: {
  },
  // GridItemの書式

  // 青ベース
  // 縦に並べる　左揃え
  blueColLeftItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',

    padding: '10px',
    color: 'white',
    backgroundColor: baseColorBlue,
  },
  // 縦に並べる　中央揃え
  blueColCenterItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    padding: '10px',
    color: 'white',
    backgroundColor: baseColorBlue,
  },
  blueRowItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',

    padding: '10px',
    color: 'white',
    backgroundColor: baseColorBlue,

  },
  // 白ベース
  // 縦に並べる　左揃え
  whiteColLeftItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',

    padding: '10px',
    color: baseColorBlue

  },
  // 縦に並べる　中央揃え
  whiteColCenterItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    padding: '10px',
    color: baseColorBlue

  },
  whiteRowItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',

    padding: '10px',
    color: baseColorBlue

  }
}));
const conversionColor = "#c71585";

// 色とカードの形状はここで制御　
const style = {
  conversionBtn: {
    backgroundColor: conversionColor,
    color: '#fff',
    borderRadius: 10
  },
  title: {
    fontSize: 22,
    color: baseColorBlue
  },
  h3Text: {
    fontSize: 17,
    color: baseColorBlue
  },
  shokaiText: {
    margin: 2,
    fontSize: 20,
    color: baseColorBlue
  },
  bodyText: {
    fontSize: 12,
    color: baseColorBlue
  },
  card: {
    //boxShadow: '10px 2px 20px #CCD8FF',
    maxWidth: "500px",
    width: "70%",
    background: 'linear-gradient(to bottom right,#CCD8FF,#CCFFF2)',
  },
  cardProblem: {
    //boxShadow: '10px 2px 20px #CCD8FF',
    background: 'linear-gradient(to bottom right,#CCD8FF,#ffc0cb)',
  },
}
