import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';


// グラフコンポーネント
import { VictoryLine, VictoryPie, VictoryChart, VictoryBar, VictoryTheme } from 'victory';



/**
 * ユーザ一覧などで表示するアカウントの情報をまとめたカード
 * @property { record[], height }
 * @returns 
 * 
 * 募集状況status　募集中/受付停止中
 * 
 * <要件>
 * アバターを出す
 * ヒーローイメージを背景に出す
 * クリックしたらプロフィールに遷移する
 * 環境に応じて幅を調整する
 * propで受け取ったユーザデータで構成する
 */
const TimeBarGraph = ({ recordListData }) => {

    const navigate = useNavigate();
    const classes = useStyles();
    console.log("インプットデータ")
    console.log(recordListData)
    const data = recordListData.map((item, index) => {
        console.log(item)
        const month = item.createdAt.toDate().getMonth() + 1
        const date = item.createdAt.toDate().getDate()
        const x_axis = month + "/" + date


        // 時間差計算は、getTimeでミリ秒計算し、戻す
        // 1000で割ると秒、さらに60で割って、分、秒にする
        const end = item.completeTime.toDate().getTime();
        const start = item.step0Time.toDate().getTime();
        const diffTime = end - start
        //ミリ秒で差が出るため、1000で割って秒にして、60で割って分にする
        const time = Math.floor(diffTime / (1000 * 60));


        console.log("データの表示")
        console.log(month)
        console.log(date)
        console.log(x_axis)
        console.log(end)
        console.log(start)
        console.log(time)

        console.log()

        return { x: x_axis, y: time }
    })
    // データ加工
    // 実施日xと所要時間yの配列を作成
    // データ作成
    /**
     * 引数のデータ構造
     * data={[
                { x: "サッカー", y: 200 },
                { x: "野球", y: 100 },
                { x: "テニス", y: 100 },
                { x: "テニス", y: 100 }
            ]}
     */
    const graphData = [
        { x: `1/19`, y: 1 },
        { x: `1/20`, y: 5 },
        { x: `1/22`, y: 1 },
        { x: `1/23`, y: 1 },
        { x: `1/24`, y: 1 },
        { x: `1/25`, y: 1 },
        { x: `1/26`, y: 1 },
        { x: `1/27`, y: 1 },
        { x: `1/28`, y: 1 },
        { x: `1/29`, y: 1 },
    ]









    return (
        <>
            {/**
            <Box className={classes.leftCenterBox}>
            </Box>
    */}
            <VictoryChart
                theme={VictoryTheme.material}
                domainPadding={1}
                viewBox={"0 0 100%, 100%"}
            >

                <VictoryBar
                    style={{ data: { fill: "skyblue" } }}
                    data={data}
                />
            </VictoryChart>
        </>
    )
}

export default TimeBarGraph;


const useStyles = makeStyles((theme) => ({

    card: {
        maxWidth: "300px",
    }, leftCenterBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        height: 100,
    },



}));
