import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import IfProxy from '../../IfProxy';
import IfProxyTu from '../../IfProxyTu';


import HeadFooter from '../HeadFooter';

import TutorialProgressGraph from '../../common/graphs/TutorialProgressGraph';


// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';


/**
 * ユーザ参照
 * @param  
 * @returns 
 */
const GuidTu8 = (props) => {
  const [loading, setLoading] = useState(true);
  // クリックされたイベントの情報をモーダルに渡す
  const [clickedEventData, setClickedEventData] = useState("");
  const [modalShow, setModalShow] = useState(false);


  // チェックボックス
  const [userData, setUserData] = useState({});



  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();


  const ifProxy = new IfProxy();
  const ifProxyTu = new IfProxyTu();


  let first = true;
  useEffect((props) => {
    if (first) {
      setLoading(true)
      // ReactGA Analytics ---
      ReactGA.initialize("G-2ZCXE08H2S");
      ReactGA.send({
        hitType: "pageview",
        // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
        page: location.pathname + location.search,
      });
      // ------
      loadData();
    }

    first = false;
    return () => {
    }

  }, []);

  const loadData = async () => {
    const userDataByQuery = await ifProxyTu.getUserData(user.uid);
    setUserData(userDataByQuery);

    setLoading(false)
  }



  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {

    if (loading) {
      return (
        <HeadFooter>
          <Box className={classes.contentWrapper}>
            <Box className={classes.mainBox}>
              <p>読み込み中...</p>
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            </Box>
          </Box>
        </HeadFooter>
      )
    } else {
      //ログインしていないユーザ向けのプロフィール画面
      return (
        <></>
      )
    }
  } else if (loading) {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Box>
        </Box>
      </HeadFooter>
    )
  } else {

    const twitterShareUrl = "http://twitter.com/share?url=" + window.location.origin + '/User/' + params.id + "&text=ワンドロテーマを募集中&hashtags=ワンフロ"

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      < HeadFooter >

        <Box className={classes.contentWrapper}>
          <Grid container className={classes.modalGrid} >
            {/** 0段目 ===================================================================== */}

            <Grid item xs={12}>
              <Typography variant="h5">
                １）(復習)リクエストの種類は二種類
              </Typography>
              <Typography variant="body1" paragraph>
                リクエストは二種類あります
              </Typography>
              <ul>
                <li> リクエスト:作家さんにリクエストをする。有償での依頼と、無償（報酬はシェア）依頼ができる
                </li>
                <li> お題：</li>
              </ul>
              <Typography variant="body1" paragraph>
                ここでは、「リクエスト」について説明します
              </Typography>

              <hr></hr>
            </Grid>
            {/** 0段目 ===================================================================== */}

            <Grid item xs={12}>
              <Typography variant="h5">
                ２）【最重要ルール】
              </Typography>
              <Typography variant="h6" paragraph>
                <ul>
                  <li>完成責任がない (気楽にリクエスト・気楽に創作)
                  </li>
                  <li>ワンドロである</li>
                  <li>ファンも「クリエイター」</li>
                </ul>
              </Typography>

            </Grid>
            {/** 0段目 完成責任がない===================================================================== */}

            <Grid item xs={12}>
              <Typography variant="h5">
                <b>2-1)完成責任がない</b>
              </Typography>
              <Typography variant="body1" paragraph>
                <b>ワンドロチャレンジのテーマをリクエストするサービスです</b><br></br>
                <br></br>
                <ul>
                  <li>一般的なコミッションと異なり、原則60分で未完成のままであっても、そのまま納品としてください</li>
                  <li>「未完成を許容」の上リクエストしていた場合、未完成状態であっても納品を受領してください
                  </li>
                </ul>
                <br></br>
              </Typography>

              <Typography variant="body1" paragraph>
                このような前提を踏まえ、リクエストを行うときは
                <b>有償リクエストは「１時間分の作業」</b>（+加算は自由）<br></br>
                の考えでリクエストを行ってください<br></br>

              </Typography>
              <Typography variant="body1" paragraph>
                <b>リクエストをすることをもっと気楽に。</b>できるようにしたいと考えています<br></br>
                「あの人にこのテーマで書いてほしい」<br></br>
                「こういう絵が見たい」<br></br>
                を最も気軽にできて、<br></br>
                <br></br>
                また作家さんも気軽にそれに応えて作品を作れる環境、を作りたいと考えています<br></br>
                <br></br>
              </Typography>
              <Typography variant="body1" paragraph>
                <b>気軽にワンドロをかける心理的安全性づくりのため、ファンの皆様にもご理解をお願いいたします</b>
              </Typography>
              <Typography variant="body1" paragraph>
                <b>どうしてもこのリクエストを叶えたい。</b>時にできることとしては<br></br>
                プロフィールから、作家さんの成功率を確認することが可能です。投稿済み作品のクオリティから期待できる人へリクエストしてください<br></br>
                または、複数のリクエストを行うことです。ワンドロは失敗する確率があります。複数のリクエストで確率的な成功を狙う対策があるでしょう<br></br>
                リクエストを出しやすい機能を本サービスは揃えております<br></br>
                <br></br>
                もし満足のいくクオリティの作品が必ずほしい状況でしたら、申し訳ございませんが本サービスは適合しないかもしれません。他のコミッション系のサービスのご利用を推奨いたします
              </Typography>


            </Grid>
            {/** 0段目 ワンドロである===================================================================== */}

            <Grid item xs={12}>
              <Typography variant="h5">
                <b>2-2)ワンドロである</b>
              </Typography>
              <Typography variant="body1" paragraph>
                <ul>
                  <li>ワンドロをもっと気楽に。心理的負担を下げたいと考えています</li>
                </ul>
                <b>「時給」分以上に頑張らない</b><br></br>
                あくまで１時間（＋一般的にワンドロで許容される程度の超過）で<b>切り上げて納品してください</b><br></br>
                <br></br>
              </Typography>
              <Typography variant="body1" paragraph>
                <b>リクエストをする側のユーザも、これに必ず合意の上リクエストをしてください</b><br></br>
                「完成度が低い」ことによって、評価を下げる等は本サービスでは行わないでください<br></br>
                <br></br>
              </Typography>


            </Grid>
            {/** 0段目 ファンも発信する===================================================================== */}

            <Grid item xs={12}>
              <Typography variant="h5">
                <b>2-3)ファンも「クリエイター」</b>
              </Typography>
              <Typography variant="h6" paragraph>
                <b>あなたの「好き」をわかる作家さんと出会う</b>
              </Typography>
              <Typography variant="body1" paragraph>
                <b>誰かの心に刺さるテーマ</b>を生み出すことは重要な価値だと考えています<br></br>
                たくさんの「好き」を発信(お題など)し、共感する仲間を増やしてください<br></br>
              </Typography>
              <Typography variant="h6" paragraph>
                <b>あなたの性癖に刺さる絵を同好の士に描かせる・引き出す</b>ために、応援する
              </Typography>
              <Typography variant="body1" paragraph>
                応援はもちろん有償リクエストもありますが、それだけではありません。<br></br>
                無償リクエストにおけるシェアなども作家さんは<br></br>
              </Typography>



            </Grid>

            {/** 0段目 ===================================================================== */}

            <Grid item xs={12}>
              <Typography variant="h5">
                3）リクエストのおおまかな流れ
              </Typography>
              <Typography variant="h6" paragraph>
                リクエストの細かいルールは必ずこちらをお読みください
                <Button variant="contained" onClick={() => navigate('/ruleandmanner')}>ルールとマナー</Button>
              </Typography>

            </Grid>



            <Grid item xs={12}>
              <Button variant="contained" fullWidth={true} onClick={() => {
                ifProxyTu.activateTu_8_firstRequestGuide(userData.id);
                ifProxyTu.activateTu_12_getFirstRequestGuide(userData.id);

                navigate('/tu')

              }}>理解した!<br></br>チュートリアルに戻る</Button>
            </Grid>
          </Grid>

        </Box>

      </HeadFooter >

    );
  }




}
export default GuidTu8;

const useStyles = makeStyles((theme) => ({
  //オーバーレイ
  overlay: {
    zIndex: 10,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },//オーバーレイの中のモーダル
  modalView: {
    zIndex: 10,
    borderRadius: 15,
    width: '50%',
    padding: '1em',
    background: '#fff',
  },
  // 全体レイアウト
  contentWrapper: {
    marginTop: 20,
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",

  },
  // グリッド箱の書式 
  // モーダルのグリッド全体
  modalGrid: {
    //maxWidth: 1000,
    padding: 0,
    margin: '0 auto'

  },
  taskItem: {
    padding: 20,
    margin: 20,
    border: '2px solid',
    borderColor: '#a6d4fa',
    borderRadius: 20
  },
  taskItemGuide: {
  },

  gridPadding: {
    padding: 5,
    border: '2px solid',
    borderColor: '#a6d4fa',
    backgroundColor: 'linear-gradient(to bottom right,#a6d4fa,#1976d2)',
    borderRadius: '10'

  },
  // グリッド箱の書式 枠線なし
  gridPaddingNoborder: {
    padding: 5,


  },
  // 個別コンテンツ　中央揃えで縦に内容を並べる
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },// 個別コンテンツ　中央揃えで横に内容を並べる
  centerFlatBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftCenterBox: {
    display: 'flex',
    paddingRight: 10,
    flexDirection: 'column',
    alignItems: 'left',
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftRowBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
  },
  // 個別コンテンツ　横並びに内容を並べる
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },

  // モーダルダイアログのフォーム　個別アイテム
  modalFormItem: {
    margin: 20,
  },
  //サンプルのグラフエリア
  sampleGraphEria: {
    width: 200
  },

  cardList: {
  },
  card: {
  },
  bottomCard: {
    width: 250
  },
}));



