import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, getDoc, query, deleteDoc, updateDoc, where, addDoc, serverTimestamp, limit, orderBy, startAt, endAt, Timestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, TwitterAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult, updateProfile, EmailAuthProvider, linkWithCredential, } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import Resizer from "react-image-file-resizer";
import { useAuthContext } from './AuthContext';

import { getFunctions, httpsCallable } from "firebase/functions";


import IfProxy from './IfProxy';

// アプリ全体のFirebase設定 起動直後最初に一回読み込まれる
// APIキーを git管理から外したファイルに設置。これはpushしない
import apiKey from './apikey.json';

// APIキー等の設定情報を取得 起動直後最初に一回読み込まれる
const firebaseConfig = apiKey.firebase;

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();
const provider = new TwitterAuthProvider();
const functions = getFunctions(app);
const ifProxy = new IfProxy();


class IfProxyStripe {


  getTestCall = () => {
    return new Promise(async (resolve) => {

      const addMessage = httpsCallable(functions, 'addMessage');

      console.log("proxy引数確認======================")
      addMessage({ test: "test" })
        .then((result) => {
          console.log("FUNCTION========")
          console.log("FUNCTION========")
          console.log("FUNCTION========")
          console.log("FUNCTION========")
          console.log("FUNCTION========")
          console.log(result)
          console.log(result.test)

        })
        .catch((error) => {
          console.log("checkStripeUserById コール失敗");
          console.log(error);
        });

    })

  }


  /**
   * クリエイター登録をする：Stripe連結アカウントを作成する
   */
  registerCreator = (uid) => {
    return new Promise(async (resolve) => {
      try {
        const createStripeAccount = await httpsCallable(functions, 'createStripeAccount');

        createStripeAccount()
          .then(({ data }) => {
            //console.log("data=============")
            //console.log(data)
            const result = data.result

            // StripeIdを userDataに保持

            ifProxy.getUserData(uid).then((userData) => {
              //console.log("Stripe ID " + result.id);
              userData.stripeId = result.id
              //console.log(" ===userData ===")
              //console.log(userData)
              ifProxy.updateUserData(userData);
              resolve({ status: true, userData: userData })
            })

            window.open(result.url, '_blank');
            // クリエイター初期登録処理を実施
            return null;

          })
          .catch((error) => {
            console.log("registerCreator コール失敗");
            console.log(error);
          });
      } catch (e) {
        console.log(e)
      }


    })

  }

  // クリエイター登録が未完了かどうかを確認
  getStripeAccount = (iputId) => {
    return new Promise(async (resolve, reject) => {

      const addMessage = await httpsCallable(functions, 'checkStripeUserById');
      addMessage({ stripeId: iputId })
        .then((result) => {
          // Read result of the Cloud Function.
          /** @type {any} */
          const accountData = result.data.result.result;
          //console.log("有効化状態")
          //console.log(accountData.charges_enabled)
          if (!accountData) {
            console.log("クリエイター登録未登録");
            resolve(false)
          }
          if (accountData.charges_enabled) {
            resolve(true)
          } else {
            resolve(false)
          }

        })
        .catch((error) => {
          console.log("checkStripeUserById コール失敗");
          console.log(error);
          reject(error)
        });

    })

  }


  /**
   * 決済を行う
   * @param {{toUid,requestId,productId,estimatedAmount,raiting}} item 
   */
  settlementRequest = async ({ toUid, requestId, productId, estimatedAmount }) => {
    return new Promise(async (resolve) => {
      // お題を確認
      const request = await ifProxy.getRequestByid(requestId);
      const createrUser = await ifProxy.getUserData(toUid);
      const stripeId = createrUser.stripeId
      // 決済リンクを作成
      const addMessage = httpsCallable(functions, 'createCheckoutLink');
      await addMessage({ stripeId, requestId, estimatedAmount })
        .then(async (result) => {
          console.log("===========createCheckoutLink END===========")
          console.log(result);
          console.log("===========stripe.checkout.sessions.create===========")
          console.log("=========== id cs_ ===========")
          console.log(result.data.result.result.id)

          console.log("result.data" + result.data);
          console.log("result.data.result.url" + result.data.result.result.url);

          const paymentUrl = result.data.result.result.url;


          // Stripe_eventを特定するためのidを取得

          console.log("ACCOUNT DATA URL")
          console.log(paymentUrl);
          window.open(paymentUrl, '_blank');

          // 購入の完了(Holder)を仮作成。
          // WebHocで決済処理完了で有効化する

          const document = await doc(db, "Request", request.id);
          console.log("更新ユーザ===========--");
          try {
            // Stripeの口座登録であればStripeIdを設定。nullを避けるため他は空もじ
            await updateDoc(document, {
              // Stripe決済情報
              cs_: result.data.result.result.id
            }).then(() => {
              resolve()
            }).catch((e) => {
              console.log(e)
            })

          } catch (e) {
            console.log(e)
          }


          /*
          await ifProxy.addHolder(data).then((responce) => {
            console.log("addHolder =======");
            console.log(responce);
            navigate('/HoldDetail/' + responce);
            if (responce == "error") {
   
            }
          });
          */
        })
        .catch((error) => {
          console.log("createCheckoutLink コール失敗");
          console.log(error);
        });
    });

  }



  // サブスク開始
  createSubscription = (data) => {
    return new Promise(async (resolve) => {
      console.log("createSubscription")
      try {
        const addMessage = await httpsCallable(functions, 'subscriptionCheckout');
        addMessage(data)
          .then(async (result) => {
            console.log(result)

            // この決済に関係するidを記録

            /*
            await ifProxy.getUserData(data.uid).then((userData) => {
              userData.subscriptionId = result.id
              ifProxy.updateUserData(userData);
            })
            */
            resolve(result)
          })
          .catch((error) => {
            console.log("createSubscription コール失敗");
            console.log(error);
          });

      } catch (e) {
        console.log(e)
      }
    })
  }
  //サブスク管理画面
  manageSubscription = ({ sessionId }) => {
    return new Promise(async (resolve) => {
      console.log("createSubscription")
      console.log(sessionId)
      try {
        const addMessage = await httpsCallable(functions, 'portalSession');
        addMessage({ sessionId })
          .then(async (result) => {
            console.log(result)

            // この決済に関係するidを記録

            /*
            await ifProxy.getUserData(data.uid).then((userData) => {
              userData.subscriptionId = result.id
              ifProxy.updateUserData(userData);
            })
            */
            resolve(result)
          })
          .catch((error) => {
            console.log("createSubscription コール失敗");
            console.log(error);
          });

      } catch (e) {
        console.log(e)
      }
    })
  }
  // サブスク解除
  removeSubscript = (uid, type = 0) => {
    return new Promise(async (resolve) => {

      try {
        const addMessage = await httpsCallable(functions, 'deleteSubscription');
        addMessage({ subscriptionType: type })
          .then((result) => {
            resolve(result)
          })
          .catch((error) => {
            console.log("deleteSubscription コール失敗");
            console.log(error);
          });
      } catch (e) {
        console.log(e)
      }
    })
  }

  // サブスクステータスチェック
  /**
   * データコールや通信なし。userDataからサブスクフラグを出すだけ
   * @param {*} userData 
   * @returns {string} premiumFlg
   */
  getPremiumStatus = ({ premium }) => {
    let premiumFlg = "未登録"
    if (premium === undefined) {

    } else if (premium === 1) {
      premiumFlg = "今月末で終了"
    } else if (premium === 2) {
      premiumFlg = "プレミアム有効"
    }

    return { premiumFlg }
  }

  /**
   * プレミアムユーザの一覧を取得する
   * プレミアム広告用
   * @returns 
   */
  getPremiumUserList = () => {
    return new Promise(async (resolve, reject) => {
      const ref = collection(db, "UserData");
      const q = await query(ref, where("premium", "in", [1, 2]))
      const docSnap = await getDocs(q);
      let oldRt = 0
      try {
        if (docSnap.size == 0) {
          resolve([])
        } else {
          let resultList = []
          await docSnap.forEach((docsnapshot) => {
            let userData = docsnapshot.data()
            userData.id = docsnapshot.id
            resultList.push(userData)
          })
          resolve(resultList)
        }

      } catch (e) {
        console.log("getPremiumUserList プレミアムユーザ取得に失敗");
        console.log(e)
        resolve(e);
      }


    })
  }


}





export default IfProxyStripe;
