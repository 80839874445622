import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import TagInput from "../../common/TagInput";


// TODO: StandardPageはパスが異なるため注意
import IfProxy from '../../IfProxy';
import IfProxyLimitedRequest from '../../IfProxyLimitedRequest';
import IfProxyTool from '../../IfProxyTool';

import HeadFooter from '../HeadFooter';
import SwipeMaster from '../../common/SwipeListView/SwipeMaster';
import SwipeViewCommonLogic from '../../common/SwipeListView/SwipeViewCommonLogic';


// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";


/** TODO できていないこと
 * ランキングは、各オブジェクトから、直近の10件を取っただけ
 * 複数オブジェクトにまたがるため、単純にクエリで注目順を抜いてくるのは難しい
 * クエリで抜いた後に、dtoListに対してソートをかけたりする必要がある
 */

const KikakuRank = (props) => {

  const [loading, setLoading] = useState(true);

  // ページング
  /* 個別のリストの続きを見せる用 */
  const [lastVisibleData, setLastVisibleData] = useState(true);

  /* TAG 検索　複数オブジェクトを跨いだ検索用 (抽出対象を個別にlastvisibleを持つ必要あり) */
  const [productLast, setProductLast] = useState(true);
  const [portforioLast, setPortforioLast] = useState(true); // これは存在しない、なぜならば、タグ検索する作品は、絵があるproduct側だけだから
  const [lmitedRequestLast, setLmitedRequestLast] = useState(true);
  const [diaryLast, setDiaryLast] = useState(true);

  const [onedroTagsSug, setOnedroTagsSug] = useState([]); // タグ サジェッション
  const [onedroTags, setOnedroTags] = useState([]); // タグ

  const [dtoList, setDtoList] = useState([]); // 抽出したデータ


  const [searchWordTag, setSearchWordTag] = useState([]); // 検索フォーム　タグ
  const [searchWordKikaku, setSearchWordKikaku] = useState([]); //


  /******************************* */

  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const ifProxy = new IfProxy();
  const ifProxyLimitedRequest = new IfProxyLimitedRequest();
  const ifProxyTool = new IfProxyTool();


  const swipeViewCommonLogic = new SwipeViewCommonLogic(); //スワイプビュー用のデータ加工ロジック共通処理

  const location = useLocation();

  useEffect((props) => {
    setLoading(true)
    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    // ------
    loadData();
  }, []);

  /**
   * 初期ロード
   * @param 
   */
  const loadData = async () => {


    // タグ検索は総合検索。複数オブジェクトを同時に調べて、concatする
    const queryWord = params.id;
    // product
    const productK = await ifProxy.getOnedroTagRank({ quantity: 10, searchTag: queryWord, collectionName: 'Product' })
    setProductLast(productK[productK.length - 1]);
    // 最終データ
    const productLastK = swipeViewCommonLogic.createDtoFromProductArray(productK)

    // portforio
    const portforioK = await ifProxy.getOnedroTagRank({ quantity: 10, searchTag: queryWord, collectionName: 'ProductPortforio' })
    setPortforioLast(portforioK[portforioK.length - 1]);
    // 最終データ
    const portforioLastK = swipeViewCommonLogic.createDtoFromProductArray(portforioK)

    // 限定リクエスト
    const limReqK = await ifProxy.getOnedroTagRank({ quantity: 10, searchTag: queryWord, collectionName: 'LimitedRequest' })
    setLmitedRequestLast(limReqK[limReqK.length - 1]);
    // 最終データ
    const limitedRequestLastK = swipeViewCommonLogic.createDtoFromLimitedrequestArray(limReqK)

    // 日記
    const diaryK = await ifProxy.getOnedroTagRank({ quantity: 10, searchTag: queryWord, collectionName: 'Diary' })
    setDiaryLast(diaryK[diaryK.length - 1]);
    // 最終データ
    const diaryLastK = swipeViewCommonLogic.createDtoFromLimitedrequestArray(diaryK)

    const con1K = productLastK.concat(portforioLastK)
    const con2K = con1K.concat(limitedRequestLastK)
    const con3K = con2K.concat(diaryLastK)

    console.log("===========データ確認===========")
    console.log(con3K)

    setDtoList(con3K)

    // サジェッション候補タグ
    const tagSugessionK = await ifProxy.getTag("ワンドロ企画", 100)
    setOnedroTagsSug(tagSugessionK)
    setLoading(false)
  }


  const rankListView = (list) => {
    const cards = list.map((item) => {
      return (<>
        <li
          className={classes.liStyle}>
          <img className={classes.liStyleImg} src={item.mainImagePath}></img>
          <h1 onClick={() => navigate(item.profilePath)}>{item.primaryText}</h1>
          <Button variant="outlined" onClick={() => navigate(item.touchNextPage)}>作品へ移動</Button>
        </li>
      </>)
    })
    return (
      <>
        <ul>
          {cards}
        </ul>
      </>
    )
  }




  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else if (loading) { //loding
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
          </Box>
        </Box>
      </HeadFooter>
    )
  } else {


    return (
      <>
        <Grid container justifyContent="space-between"
          spacing={4} >
          <Grid item xs={12} >
            <Box className={classes.centerBox}>
              <h1>ワンドロ企画ごとの注目作品</h1>
            </Box>
          </Grid>


          {/** ===================================================================== */}
          <Grid item xs={3}>
            {/** 両側スペース */}
          </Grid>
          <Grid item xs={4} sx={{ height: 300 }}>
            {onedroTags.length > 1 && <Typography color="red">タグは１つだけ選択してください</Typography>}
            <TagInput type="formKikaku" resultTags={(tags) => {
              setSearchWordKikaku(tags)
              setOnedroTags((pre) => tags)
            }} suggestion={onedroTagsSug}></TagInput>

          </Grid>
          <Grid item xs={2} className={classes.searchForm}>
            <Button className={classes.searchFormButton} variant="contained"
              onClick={(e) => {
                console.log("企画タグで検索===============")
                console.log(searchWordKikaku[0].id)
                // TODO : 
                window.open(`${window.location.origin}/SearchListView/KIKA?word=${searchWordKikaku[0].id}`)
              }}
            >企画で作品を探す</Button>
          </Grid>
          <Grid item xs={3}>
            {/** 両側スペース */}

          </Grid>

          {/** ===================================================================== */}
          <Grid item xs={3}>
            {/** 両側スペース */}
          </Grid>
          <Grid item xs={6} >
            <Box className={classes.centerBox}>
              {dtoList && rankListView(dtoList)}
            </Box>

          </Grid>
          <Grid item xs={3}>
            {/** 両側スペース */}

          </Grid>

        </Grid>


      </>

    );
  }




}
export default KikakuRank;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト　中心揃えにする
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  // コンテンツの幅。端末ごとに広がりすぎるのを防ぐ。左揃え
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",
  },
  // 個別コンテンツ　中央揃えで縦に内容を並べる
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  // 個別コンテンツ　右に寄せて横に並べる
  rightAlignBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
  },
  // 個別コンテンツ　横並びに内容を並べる
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },
  searchForm: {
    height: 0,
  },
  searchTagForm: {
    height: 0,
  },
  searchFormInput: {
    padding: 0
  },
  searchFormButton: {
    verticalAlign: 'middle',
    width: 150,
    height: 100
  },
  cardList: {
  },
  liStyle: {
    listStyle: 'none',
    padding: 50,
    borderBottom: '3px solid #2a77e0'
  },
  liStyleImg: {
    height: "150px",
    width: "150px",
    objectFit: 'cover'
  },
}));
