import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, getDoc, query, deleteDoc, updateDoc, where, or, and, startAfter, addDoc, serverTimestamp, limit, orderBy, startAt, endAt, Timestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, TwitterAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult, updateProfile, EmailAuthProvider, linkWithCredential, } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import Resizer from "react-image-file-resizer";

import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";

import IfProxyTu from './IfProxyTu';
import ReactGA from "react-ga4";



// アプリ全体のFirebase設定 起動直後最初に一回読み込まれる
// APIキーを git管理から外したファイルに設置。これはpushしない
import apiKey from './apikey.json';

// APIキー等の設定情報を取得 起動直後最初に一回読み込まれる
const firebaseConfig = apiKey.firebase;

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();
const provider = new TwitterAuthProvider();
const functions = getFunctions(app);

const ifProxyTu = new IfProxyTu();


// Twitter側設定
// https://developer.twitter.com/en/portal/apps/22484142/settings


// ユーザ状態の遷移を読み取るオブザーバ
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;
    console.log("ログイン中")
  } else {
    // User is signed out
    console.log("ログアウト")
  }
});


getRedirectResult(auth)
  .then((result) => {
    // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
    // You can use these server side with your app's credentials to access the Twitter API.
    const credential = TwitterAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    const secret = credential.secret;
    // ...

    // The signed-in user info.
    const user = result.user;
    console.log("==========-getRedirectResult=============")
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.email;
    // The AuthCredential type that was used.
    const credential = TwitterAuthProvider.credentialFromError(error);
    // ...
  });


class IfProxy {



  // 認証系
  createUser = (email, password) => {
    return new Promise(async (resolve) => {
      createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        resolve(user);
        // ...
      })
        .catch((error) => {
          console.log("ログインエラー")
          const errorCode = error.code;
          const errorMessage = error.message;

          console.log(errorCode)
          console.log(errorMessage)
          let result = {
            code: "NG",
            error: error
          }
          resolve(result);
        });
    });
  }

  login = (email, password) => {
    return new Promise(async (resolve) => {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          let result = {
            code: "OK",
            user: user
          }
          resolve(result);
        })
        .catch((error) => {
          console.log("ログインエラー")
          const errorCode = error.code;
          const errorMessage = error.message;

          console.log(errorCode)
          console.log(errorMessage)
          let result = {
            code: "NG",
            error: error
          }
          resolve(result);
        });

    });
  }

  twitterSignup = () => {
    return new Promise(async (resolve) => {

      // OAuth認証のためのポップアップウィンドウ
      signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
          // You can use these server side with your app's credentials to access the Twitter API.
          const credential = TwitterAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          const secret = credential.secret;


          // The signed-in user info.
          const user = result.user;
          resolve(user);
          // ...
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The AuthCredential type that was used.
          const credential = TwitterAuthProvider.credentialFromError(error);
          // ...
          resolve(error)
        });

    })
  }



  /**
   * 退会する
   * @param uid
   */
  withdrawUser = (uid) => {
    if (!uid) {
      console.log("withdrawUser : " + uid)
      throw new Error('uidが不正');
    }
    return new Promise(async (resolve, reject) => {

      const userData = await this.getUserData(uid)

      try {
        // 退会ログ
        // 退会したユーザのuid、stripeId、displayNameを記録
        await addDoc(collection(db, "Withdraw"), {
          uid: userData.uid,
          stripeId: userData.stripeId || "",
          displayName: userData.displayName,
          createdDate: serverTimestamp()
        });
        this.gaEventTest("widhdrawUser")


        // userDataを削除
        await deleteDoc(doc(db, "UserData", userData.id)).then((result) => {
          console.log("UserData削除")
        })
        // userを削除
        // userはボタン側。この処理が完了したら行う


        // リクエストを削除
        const refReq = collection(db, "Request");
        const qReq = await query(refReq, where("fromUid", "==", uid));
        const querySnapshotReq = await getDocs(qReq);
        if (querySnapshotReq.size == 0) {
        } else {
          this.deleteRecord(querySnapshotReq, "Request");
        }
        // 作品を削除
        const refPro = collection(db, "Product");
        const qPro = await query(refPro, where("toUid", "==", uid));
        const querySnapshotPro = await getDocs(qPro);
        if (querySnapshotPro.size == 0) {
        } else {
          this.deleteRecord(querySnapshotPro, "Product");
        }
        // 画像を削除
        // TODO 期間終了バッチで同時に削除
        // profileIcon/uid/以下を削除
        // image/uid/以下を削除

        resolve();
      } catch (e) {
        console.log("退会処理エラー")
        console.log(e)

      }
    })


  }

  deleteRecord = (querySnapshot, target) => {
    if (target !== "Request" && target !== "Product") {
      throw new Error('targetテーブル名不正: 定義ターゲット:' + target);
    }
    querySnapshot.forEach(async (document) => {
      await deleteDoc(doc(db, target, document.id)).then(() => {
        console.log(target + "削除")
      }).catch((e) => {
        console.log(target + " : 削除に失敗")
        throw new Error(target + ' : を削除に失敗' + e);
      })

    })
  }



  /**
   * プロバイダー切り替え。twitter認証からメール認証への切り替え
   * @param {*} email 
   * @param {*} password 
   * @returns 
   */
  linkTwitterAuthProviderToEmailProvider = (email, password) => {
    return new Promise(async (resolve) => {
      const credential = EmailAuthProvider.credential(email, password);
      linkWithCredential(auth.currentUser, credential)
        .then((usercred) => {
          const user = usercred.user;
          console.log("Account linking success", user);
          const status = { status: true }
          resolve(status);
        }).catch((error) => {
          console.log("Account linking error", error);
          const status = { status: false }
          resolve(status)
        });
    });
  }

  getNewUser(count) {
    return new Promise(async (resolve) => {
      let userData = {};
      const ref = collection(db, "UserData");
      const q = await query(ref, orderBy("createtAt", "desc"), limit(count))
      const querySnapshot = await getDocs(q);
      let result = []

      console.log("getNewUser 新規ユーザのリスト")

      try {
        if (querySnapshot.size == 0) {
          resolve(result);
        } else {
          await querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            let res = doc.data();
            res.id = doc.id;
            res.snapshot = doc;
            result.push(res);
          })
          resolve(result);
        }
      } catch (e) {
        console.log("データ取得に失敗")
        console.log(e)
        resolve(e);
      }
    });
  }

  getNewUser_paging(count, last) {
    return new Promise(async (resolve) => {
      let userData = {};
      const ref = collection(db, "UserData");
      const q = await query(ref, orderBy("createtAt", "desc"), startAfter(last), limit(count))
      const querySnapshot = await getDocs(q);
      let result = []

      console.log("getNewUser 新規ユーザのリスト")

      try {
        if (querySnapshot.size == 0) {
          resolve(result);
        } else {
          await querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            let res = doc.data();
            res.id = doc.id;
            res.snapshot = doc;
            result.push(res);
          })
          resolve(result);
        }
      } catch (e) {
        console.log("データ取得に失敗")
        console.log(e)
        resolve(e);
      }
    });
  }

  getChumokuUser(count) {
    return new Promise(async (resolve) => {
      let userData = {};
      const ref = collection(db, "UserData");
      const q = await query(ref, and(where("status", "==", true), or(where("remitedCreator", "==", true), where("stripeId", "!=", ""))), orderBy("stripeId", "desc"), limit(count))
      const querySnapshot = await getDocs(q);
      let result = []

      console.log("getChumokuUser クリエイターを取得")

      try {
        if (querySnapshot.size == 0) {
          resolve(result);
        } else {
          await querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            let res = doc.data();
            res.id = doc.id;
            result.push(res);
          })
          resolve(result);
        }
      } catch (e) {
        console.log("データ取得に失敗")
        console.log(e)
        resolve(e);
      }
    });

  }


  getUserData(uid) {
    return new Promise(async (resolve) => {
      let userData = {};
      const ref = collection(db, "UserData");
      const q = await query(ref, where("uid", "==", uid))
      const querySnapshot = await getDocs(q);
      let result = []


      try {
        if (querySnapshot.size == 0) {
          console.log("user data not exist 新規ユーザ")
          resolve(result);
        } else {
          await querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            let res = doc.data();
            res.id = doc.id;
            result.push(res);
          })
          resolve(result[0]);
        }
      } catch (e) {
        console.log("データ取得に失敗")
        console.log(e)
        resolve(e);
      }
    });
  }

  /**
 * FirebaseAuthのユーザをUPDATEする
 * @param {displayName,blob} data 
 * @returns 
 */
  updateUser = (data) => {
    return new Promise(async (resolve) => {
      // blobで受け取ったアイコン画像をfirestoreに上げる
      // profileIconフォルダ以下　ファイル名はランダム値
      // urlを取得する

      // プロフィール変更がない場合

      if (data.blob == 0 || !data.blob) {
        try {
          // userを更新する
          updateProfile(auth.currentUser, {
            displayName: data.displayName,
          }).then(() => {
            //console.log("FirebaseAuthのユーザをUPDATEする 成功 ")

            const resultUser = {
              displayName: data.displayName,
              photoURL: null
            }
            // メモ
            // 画面上からプロフィール画像の変更がない場合は、ファイルアップロードとurlの返却をしない
            // その場合。画面は、nullを受け取る
            // nullの時は、既に登録されているurlを踏襲する
            // 既に登録されているurlがなければ、プロバイダーのurlを当てる
            resolve(resultUser);
          }).catch((error) => {
            console.log("FirebaseAuthのユーザをUPDATEする 失敗 ")
            resolve()
          });
        } catch (e) {
          console.log("画像更新がない場合のプロフィール更新")
          console.log(e)
          resolve(e);
        }
      } else {
        this.fileHandler("profileIcon", auth.currentUser.uid, data.blob).then(({ url, thumbnail }) => {
          try {
            // userを更新する
            updateProfile(auth.currentUser, {
              displayName: data.displayName,
              photoURL: url
            }).then(() => {
              console.log("FirebaseAuthのユーザをUPDATEする 成功 ")
              const resultUser = {
                displayName: data.displayName,
                url: url
              }
              resolve(resultUser);
              // Profile updated!
              // ...
            }).catch((error) => {
              // An error occurred
              // ...
              console.log("FirebaseAuthのユーザをUPDATEする 失敗 ")
              resolve()
            });

          } catch (e) {
            console.log("データ登録に失敗")
            console.log(e)
            resolve(e);
          }
        })
      }

    })

  }

  updateHeroImage(blob) {
    return new Promise(async (resolve, reject) => {
      this.fileHandler("heroimage", auth.currentUser.uid, blob).then(({ url, thumbnail }) => {
        resolve({ heroUrl: url, heroSumbneil: thumbnail })
      }).catch(e => {
        console.log(e)
        reject(e)
      })
    })
  }

  addLimitedRequest(blob) {
    return new Promise(async (resolve, reject) => {
      this.fileHandler("limitedRequestImage", auth.currentUser.uid, blob).then(({ url, thumbnail }) => {
        resolve({ url: url, sambnail: thumbnail })
      }).catch(e => {
        console.log(e)
        reject(e)
      })
    })
  }


  // data　のid項目で更新対象を特定して更新 idが空なら新規と判定
  updateUserData(data) {

    return new Promise(async (resolve) => {
      let userData = {};
      userData.uid = data.uid	//uid
      userData.icon = data.icon	//icon
      userData.heroImageView = data.heroImageView || ""// ヒーローイメージのurl
      userData.displayName = data.displayName	//displayName
      userData.profile = data.profile	//プロフィールテキスト
      userData.youtubeid = data.youtubeid || ""	//youtube動画id
      userData.stripeId = data.stripeId || ""	//youtube動画id
      userData.premium = data.premium || 0	//プレミアムサブスク課金状況
      if (data.remitedCreator) { //　フラグ開放が送り込まれた場合だけ、更新する。
        userData.remitedCreator = data.remitedCreator
      }
      userData.status = data.status	//募集状況
      userData.shareStance = data.shareStance//シェアされたら嬉しいか？
      userData.genre = data.genre	//強いジャンル
      userData.onedro = data.onedro	//やっているワンドロ
      userData.onedroTagString = data.onedroTagString || ""
      userData.kikakuTags = data.kikakuTags || ""



      // 新規作成かどうか判定
      // 新規
      if (!data.id) {
        console.log("新規ユーザ==========-");
        this.gaEventTest("createUser")
        try {
          userData.rt = 0
          userData.nextStep = "【まずはここから】作家さんを応援、ファンとして参加するための使い方"
          userData.createtAt = serverTimestamp()
          userData.updatedAt = serverTimestamp()
          await addDoc(collection(db, "UserData"), userData); // 違うのここだけじゃないの？2行では？
          resolve();
        } catch (e) {
          console.log("データ登録に失敗");
          console.log(e)
          resolve(e);
        }

      } else {
        // 更新
        const document = doc(db, "UserData", data.id);
        console.log("更新ユーザ===========--");
        try {
          // Stripeの口座登録であればStripeIdを設定。nullを避けるため他は空もじ
          const stripeId = data.stripeId || "";
          userData.stripeId = stripeId
          userData.updatedAt = serverTimestamp()
          await updateDoc(document, userData);
          resolve();

        } catch (e) {
          console.log("データ登録に失敗");
          console.log(e)
          resolve(e);
        }
      }
      resolve(userData);
    });
  }

  /**
   * uidのUserDataのrtカウントをインクリメントする
   * @param {id} uid 
   * @returns 
   */
  updateUserRtcount = (uid) => {
    return new Promise(async (resolve) => {
      // rtカウントを加算
      const ref = collection(db, "UserData");
      const q = await query(ref, where("uid", "==", uid))
      const docSnap = await getDocs(q);
      let oldRt = 0
      try {
        if (docSnap.size == 0) {
          console.log("updateUserRtcount:NODATA===========")
        } else {
          await docSnap.forEach((docsnapshot) => {

            const updateRef = doc(db, "UserData", docsnapshot.id);
            updateDoc(updateRef, {
              rt: (docsnapshot.data().rt) + 1
            }).then((result) => resolve())
          })
        }

      } catch (e) {
        console.log("データ登録に失敗");
        console.log(e)
        resolve(e);
      }

    });
  }
  /**無料限定リクエストの解放
  * 有料リクエストもこのフラグはついている。有料はこれ&& StripeId
  * @param {*} userData
  * @returns 
  */
  updateUserRemitedCreator(userData) {

    if (userData.remitedCreator) return;
    return new Promise(async (resolve, reject) => {
      try {

        let updateUserData = {
          remitedCreator: true,
          updatedAt: serverTimestamp()
        }
        const document = doc(db, "UserData", userData.id);
        await updateDoc(document, updateUserData);
        resolve();


      } catch (e) {
        console.log("データ登録に失敗");
        console.log(e)
        resolve(e);
      }

    })
  }

  /**
 * uidのUserDataの対応件数(RequestAmountをインクリメントする)
 * @param {id} uid 
 * @returns 
 */
  updateRequestAmount = (uid) => {
    return new Promise(async (resolve) => {
      // requetAmountカウントを加算
      const ref = collection(db, "UserData");
      const q = await query(ref, where("uid", "==", uid))
      const docSnap = await getDocs(q);
      try {
        if (docSnap.size == 0) {
          console.log("updateUserRtcount:NODATA===========")
        } else {
          await docSnap.forEach((docsnapshot) => {

            const updateRef = doc(db, "UserData", docsnapshot.id);
            updateDoc(updateRef, {
              requetAmount: (docsnapshot.data().requetAmount) + 1
            }).then((result) => resolve())
          })
        }

      } catch (e) {
        console.log("updateRequestAmount：データ登録に失敗");
        console.log(e)
        resolve(e);
      }

    });

  }
  /**
 * uidのUserDataの納品件数(completetAmountをインクリメントする)
 * @param {id} uid 
 * @returns 
 */
  updateCompletetAmount = (uid) => {
    console.log("updateCompletetAmount ===========")
    return new Promise(async (resolve) => {
      // 受諾数
      const calcEntrusted = await this.calcEntrusted(uid)
      // 完了数
      const calcCompletedAmount = await this.calcCompletedAmount(uid)
      // 平均受注金額
      const calcAmount = await this.calcAmount(uid)
      // 平均納品期間
      const calcTermAvelage = await this.calcTermAvelage(uid)

      console.log("calcEntrusted")
      console.log(calcEntrusted)
      console.log("calcCompletedAmount")
      console.log(calcCompletedAmount)
      console.log("calcAmount")
      console.log(calcAmount)
      console.log("calcTermAvelage")
      console.log(calcTermAvelage)

      // 平均レーティング
      const calcRatingAvelage = await this.calcRatingAvelage(uid)
      const ref = collection(db, "UserData");
      const q = await query(ref, where("uid", "==", uid))
      const docSnap = await getDocs(q);
      try {
        if (docSnap.size == 0) {
          console.log("updateUserRtcount:NODATA===========")
        } else {
          await docSnap.forEach((docsnapshot) => {

            const updateRef = doc(db, "UserData", docsnapshot.id);
            updateDoc(updateRef, {
              entrustedAmount: calcEntrusted,
              completetAmount: calcCompletedAmount,
              competedRate: (calcCompletedAmount / calcEntrusted),
              amoutAvelage: calcAmount,
              termAvelage: calcTermAvelage,
              ratingAvelage: calcRatingAvelage
            }).then((result) => resolve()).catch((e) => {
              console.log("updateCompletetAmount：：UserData の更新に失敗")
              console.log(e)
            })
          })
        }

      } catch (e) {
        console.log("updateCompletetAmount：データ登録に失敗");
        console.log(e)
        resolve(e);
      }

    });

  }

  // Busyness リクエストコントロール =======================================================
  createRequest({ thema, tochu, amount, shareAmountFlag, shareAmount, onedroTag, tag, anonimous = false, fromUid, fromName, fromIcon, toUid, toName, toIcon, tagsString, onedroTagString, odaiId = "", agreecheckSightShareFlg = 0 }) {
    this.gaEventTest("createRequest_IN")

    //console.log(odaiId)
    if (!thema) {
      throw new Error("入力不正 : thema")
    }
    if (amount === undefined) {
      // console.log("shareAmountFlag")
      //console.log(shareAmountFlag)
      throw new Error("入力不正 : amount")
    }
    if (shareAmountFlag === undefined) {
      //console.log("shareAmountFlag")
      // console.log(shareAmountFlag)
      throw new Error("入力不正 : shareAmountFlag" + shareAmountFlag)
    }
    if (shareAmount === undefined) {
      throw new Error("入力不正 : shareAmount")
    }
    if (!onedroTag) {
      throw new Error("入力不正 : onedroTag")
    }
    if (!tag) {
      throw new Error("入力不正 : tag")
    }
    if (!onedroTag) {
      throw new Error("入力不正 : onedroTag")
    }
    if (anonimous === undefined) {
      throw new Error("入力不正 : anonimous")
    }
    if (!fromUid) {
      throw new Error("入力不正 : fromUid")
    }
    if (!fromName) {
      throw new Error("入力不正 : fromName")
    }
    if (!fromIcon) {
      throw new Error("入力不正 : fromIcon")
    }
    if (!toUid) {
      throw new Error("入力不正 : toUid" + toUid)
    }
    if (!toName) {
      throw new Error("入力不正 : toName" + toName)
    }
    if (!toIcon) {
      throw new Error("入力不正 : toIcon" + toIcon)
    }
    if (!tagsString) {
      throw new Error("入力不正 : tagsString")
    }
    if (!onedroTagString) {
      throw new Error("入力不正 : onedroTagString")
    }
    if (!odaiId === undefined) {
      console.log("odaiId")
      console.log(odaiId)

      throw new Error("入力不正 : odaiId" + odaiId)
    }

    return new Promise(async (resolve, reject) => {

      // 回答期限日10日後
      let anserLimitDate = new Date();
      anserLimitDate.setDate(anserLimitDate.getDate() + 10);
      let dereveryLimit = new Date();
      dereveryLimit.setDate(anserLimitDate.getDate() + 20); // 【注意】納品期限日は未承認ステータスでは仮値で20日後が入っているが、受注時に10日に上書き

      await addDoc(collection(db, "Request"), {
        thema: thema,
        tochu: tochu,
        amount: amount,
        shareAmountFlag: shareAmountFlag,
        shareAmount: shareAmount,
        onedroTag: onedroTag,
        tag: tag,
        anonimous: anonimous,
        fromUid: fromUid,
        fromName: fromName,
        fromIcon: fromIcon,
        toUid: toUid,
        toName: toName,
        toIcon: toIcon,
        tagsString: tagsString,
        onedroTagString: onedroTagString,
        odaiId: odaiId, //お題の場合はお題idが入る。ない場合はから文字
        agreecheckSightShareFlg,


        //---
        anserLimit: anserLimitDate,
        dereveryLimit: dereveryLimit,
        status: "unentrusted",
        agree: 0,
        share: 0,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      }).then(async (result) => {
        const toUser = {
          uid: toUid,
          displayName: toName,
          icon: toIcon,
        }

        await this.statusUpdateNotify(toUser, "request", result.id, `${fromName} からリクエストがありました`) //通知
        this.gaEventTest("createRequest_CONVERSION")
        if (agreecheckSightShareFlg === 1 || agreecheckSightShareFlg === 2) {
          this.gaEventTest("createRequest_SIGHT_SHARE_OK")
        }


        resolve(result);

      }).catch((e) => {
        console.log("createRequest に失敗 IF Proxy")
        console.log(e);
        reject(e);
      })

    });
  }


  /**
   * status
   * unentrusted 未受諾/
   * entrusted 受諾/
   *  completed 完了/
   *  requestCanceled キャンセル/
   *  creatorCanceled 作家判断キャセル
   */

  /**
   * リクエスト中に更新　TODO これ存在しない、作成と同時にリクエスト中になるため
   * @param {*} id 
   * @returns 
   */
  unentrusted(id) {
    return new Promise(async (resolve, reject) => {
    });
  }

  /**
  * 受諾済みに更新 
  * @param {*} id 
  * @returns 
  */
  entrusted(id) {
    return new Promise(async (resolve, reject) => {
      //this.sign(id)
      await this.updateRequestStatus(id, "entrusted")
      resolve(true)
    });
  }

  /**
   * 完了 納品済み済みに更新
   * @param {*} id 
   * @returns 
   */
  completed(id) {
    return new Promise(async (resolve, reject) => {
      await this.updateRequestStatus(id, "completed")
      resolve(true)
    });
  }

  /**
   * リクエストを依頼者がキャンセルに更新
   * @param {*} id 
   * @returns 
   */
  requestCanceled(id) {
    return new Promise(async (resolve, reject) => {
      await this.updateRequestStatus(id, "requestCanceled")
      resolve(true)
    });
  }

  /**
   * 作家判断キャンセル済みに更新
   * @param {*} id 
   * @returns 
   */
  creatorCanceled(id) {
    return new Promise(async (resolve, reject) => {
      this.getRequestByid(id).then(async (result) => {
        await this.updateRequestStatus(id, "creatorCanceled")
        resolve(true)
      })
    });
  }


  /**
   * リクエストステータスを更新する共通メソッド
   * @param {*} id 
   * @param {*} nextStatus 
   * @returns 
   */
  updateRequestStatus(id, nextStatus) {
    return new Promise(async (resolve, reject) => {

      this.getRequestByid(id).then((result) => {
        const docRef = doc(db, "Request", result.id);

        if (nextStatus === "entrusted") {
          let dereveryLimit = new Date();
          dereveryLimit.setDate(dereveryLimit.getDate() + 7); // 受諾日から7日が期限

          updateDoc(docRef, {
            status: nextStatus,
            dereveryLimit: dereveryLimit,
            entrustedDate: serverTimestamp()
          }).then(() => resolve()).catch((e) => {
            console.log(e)
          })
        } else if (nextStatus === "completed") {
          updateDoc(docRef, {
            status: nextStatus,
            completedDate: serverTimestamp()
          }).then(() => resolve()).catch((e) => {
            console.log(e)
          })

        } else {
          updateDoc(docRef, {
            status: nextStatus,
          }).then(() => resolve()).catch((e) => {
            console.log(e)
          })

        }

      })
    });
  }

  /**
   * @param {object} toUser String  宛先
   * @param {String} type 通知の種別
   * @param {id} docid リクエストのdocid
   * @param {String} message 本文の方の文章
   */
  statusUpdateNotify(toUser, type, docid, message) {
    return new Promise(async (resolve, reject) => {
      const user = auth.currentUser;
      // 通知
      const noti = {
        uid: toUser.uid, //通知の宛先
        fromUid: user.uid, // 自分
        fromDisplayName: user.displayName,
        fromIcon: user.photoURL,

        toUid: toUser.uid,
        toDisplayName: toUser.displayName,
        toIcon: toUser.icon,

        targetId: docid,

        text: `from:${user.displayName} 内容：${message}`,
        type: type,
      }
      await this.createNotification(noti);
      resolve("success")
    })
  }

  updateAgreeCount(id) {
    return new Promise(async (resolve, reject) => {
      // console.log("updateAgreeCount")

      // アグリーカウントを加算する
      this.getRequestByid(id).then((result) => {
        const docRef = doc(db, "Request", result.id);
        updateDoc(docRef, {
          agree: (result.agree + 1)
        })
      })
    });
  }
  updateShareCount(id, fromUid, url) {

    return new Promise(async (resolve, reject) => {
      // シェアカウントを加算する
      // console.log("updateAgreeCount")
      // アグリーカウントを加算する
      this.getRequestByid(id).then((result) => {
        const docRef = doc(db, "Request", result.id);
        updateDoc(docRef, {
          share: (result.share + 1)
        })
        this.addShare("reserve", id, fromUid, url)
      })
      // シェアレコードにユーザを登録する
    });
  }


  /**
   * リクエストをidで取得する
   * @param {id} docid
   * @return {request}
   */
  getRequestByid(id) {
    return new Promise(async (resolve, reject) => {
      console.log(id)
      const docRef = doc(db, "Request", id);
      const docSnap = await getDoc(docRef);

      try {
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          let res = docSnap.data()
          res.id = docSnap.id;
          res.anserLimitDate = this.formatFirebaseTimestampToString(docSnap.data().anserLimit)
          res.dereveryLimitDate = this.formatFirebaseTimestampToString(docSnap.data().anserLimit)
          //console.log(res.anserLimitDate)
          resolve(res);

        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          resolve({ status: "データなし" })
        }
      } catch (e) {
        console.log("getRequestByid");
        console.log(e);
        reject(e)
      }


    });
  }

  /**
   * リクエストを受けたユーザのuidで取得
   * @param {uid}
   * @return {[request]}
   */
  getRequestByToUid(toUid) {
    return new Promise(async (resolve, reject) => {
      try {


        let result = []; //取得結果のリスト
        const ref = collection(db, "Request");
        const q = await query(ref, where("toUid", "==", toUid), orderBy("createdAt", "desc"), limit(50));
        const querySnapshot = await getDocs(q);
        console.log("検出数" + querySnapshot.size)
        if (querySnapshot.size == 0) {
          resolve(result);
        } else {
          this.getRequestQuery(querySnapshot, resolve);

        }
      } catch (e) {
        console.log("getRequestByToUid")
        console.log(e);
      }
    });
  }
  /**
   * リクエストをしたユーザのuidで取得
   * @param {uid}
   * @return {[request]}
   */
  getRequestByFromUid(fromUid) {
    return new Promise(async (resolve, reject) => {
      let result = []; //取得結果のリスト
      const ref = collection(db, "Request");
      const q = await query(ref, where("fromUid", "==", fromUid), orderBy("createdAt", "desc"), limit(50));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size == 0) {
        resolve(result);
      } else {
        this.getRequestQuery(querySnapshot, resolve);
      }
    });
  }

  /**
   * リクエスト表示用のリスト取得クエリ
   * querySnapshotを実行した結果をリスト化して返すRefactoringメソッド
   * @param {querySnapshot, resolve} querySnapshot 
   * @param {[]} レコードの配列 
   */
  getRequestQuery = async (querySnapshot, resolve) => {
    let result = []; //取得結果のリスト
    await querySnapshot.forEach((doc) => {
      //console.log(doc.data().anserLimit.toDate())
      // doc.data() is never undefined for query doc snapshots
      //console.log(doc.id, " => ", doc.data());

      let res = doc.data();
      res.id = doc.id;
      res.snapshot = doc;
      res.anserLimit = doc.data().anserLimit.toDate();
      if (doc.data().dereveryLimit) {
        res.dereveryLimit = doc.data().dereveryLimit.toDate();
      } else {
        res.dereveryLimit = "";
      }

      result.push(res);

    })
    resolve(result);

  }

  /**
   * リクエストを受けたお題Idでで取得
   * @param {odaiId}
   * @return {[request]}
   */
  getRequestByOdaiId(odaiId) {
    return new Promise(async (resolve, reject) => {
      try {


        let result = []; //取得結果のリスト
        const ref = collection(db, "Request");
        const q = await query(ref, where("odaiId", "==", odaiId), orderBy("createdAt", "desc"), limit(50));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size == 0) {
          resolve(result);
        } else {
          this.getRequestQuery(querySnapshot, resolve);

        }
      } catch (e) {
        console.log("getRequestByToUid")
        console.log(e);
      }
    });
  }

  // Product ====================

  /**
   * 作品の投稿
   *   
   * @param {id} requestId,関係するリクエスト
      @param {binary} blobDataResult,データ本体
      @param {[]} onedroTags,ワンドロタグ
      @param {[]} tags,作品タグ
      @param {Date} anserLimit,承認期限
      @param {Date} dereveryLimit,納品期限
      @param {number} completetTerm,承認してから納品するまでの日数
      @param {boolean} cloaseFlg,匿名リクエストフラグ（使っていない）
      @param {String} status,ステータス　unentrusted 未受諾/entrusted 受諾/ completed完了/requestCanceled キャンセル/creatorCanceled 作家判断キャセル
      @param {String} rating レーティング
      @param {id} fromUid,　リクエストの依頼者のuid
      @param {id} toUid, リクエストの受託者のuid
      @param {String} toName リクエストの受託者名
      @param {String} fromName リクエストの依頼者
      @param {url} toIcon リクエストの受託者のアイコン
      @param {url} fromIcon リクエストの依頼者のアイコン
      @param {String} thema 依頼オーダー　リクエストからの引き継ぎ
      @param {number} requestAmount リクエスト金額　リクエストからn引き継ぎ
      @param {boolean} shareAmountFlag 非コミッションである場合はtrue
      @param {String} shareAmount 非コミッションである場合は約束した報酬内容
      @param {number} nsfw nsfwフラグ
      @param {String} odaiId お題によるproductならodaiIdが入る

 
   * @returns 
   */
  createProduct = async ({
    requestId,
    blobDataResult,
    onedroTags,
    tags,
    anserLimit,
    dereveryLimit,
    completetTerm,
    cloaseFlg = false,
    status,
    rating = "",
    toUid,
    toName,
    toIcon,
    fromUid,
    fromName,
    fromIcon,
    thema,
    requestAmount,
    shareAmountFlag,
    shareAmount,
    nsfw,
    agreecheckSightShareFlg,
    odaiId = ""



  }) => {
    this.gaEventTest("createProduct")

    return new Promise(async (resolve, reject) => {
      this.fileHandler("image", auth.currentUser.uid, blobDataResult).then(({ url, thumbnail }) => {
        const tagsString = tags.map((data, index) => {
          return data.text
        })
        let onedroTagString = []
        if (onedroTags) {
          onedroTagString = onedroTags.map((data, index) => {
            return data.text
          })
        }
        try {
          // Productを作成
          addDoc(collection(db, "Product"), {
            requestId,
            image: url,
            thumbnail: thumbnail,
            onedroTags,
            onedroTagString,
            tags,
            tagsString,
            anserLimit: anserLimit,
            dereveryLimit: dereveryLimit,
            completetTerm: completetTerm,
            cloaseFlg,
            status,
            rating,
            toUid,
            toName,
            toIcon,
            fromUid,
            fromName,
            fromIcon,
            thema,
            requestAmount,
            shareAmountFlag,
            shareAmount,
            nsfw,
            agreecheckSightShareFlg,
            odaiId,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp()
          }).then((result) => {
            resolve(result);
            // 通知先は発注者fromユーザ
            const fromUser = {
              uid: fromUid,
              displayName: fromName,
              icon: fromIcon

            }

            this.statusUpdateNotify(fromUser, "nohin", result.id, `${fromName} からリクエストに納品がありました`) //通知


          }).catch((error) => {

            console.log("FirebaseAuthのユーザをUPDATEする 失敗 ")
            resolve()
          });

        } catch (e) {
          console.log("データ登録に失敗")
          console.log(e)
          resolve(e);
        }
      })
    })
  }

  // ===========================================================================================================================================================================
  updateTag(docId, tags, object) {

    return new Promise(async (resolve, reject) => {
      if (docId === null || docId === "") {

        throw new Error('updateTag 引数不正 docId : ' + docId);
        reject()
      }
      if (tags === null || tags === []) {
        throw new Error('updateTag 引数不正 tags : ' + tags);
        reject()
      }
      if (object === null || object === '') {
        throw new Error('object 対象テーブルが空: ' + object);
        reject()
      }
      if (object !== 'Product' && object !== 'ProductPortforio') {
        throw new Error('オブジェクトは　 Product　か　ProductPortforio　のみ許可' + object);
        reject()
      }
      const tagsString = tags.map((data, index) => {
        return data.text
      })
      console.log("||||||||||||")
      console.log("||||||||||||")
      console.log("||||||||||||")
      console.log("||||||||||||")
      console.log(tags)
      console.log(tagsString)

      const docRef = doc(db, object, docId);
      updateDoc(docRef, {
        tags,
        tagsString,
        updatedAt: serverTimestamp()
      }).then((result) => {
        resolve(result);
      }).catch((error) => {
        console.log("タグのアップデートに失敗失敗 ")
        resolve()
      });
    });
  }
  updateOnedroTag(docId, onedroTags, object) {
    if (docId === null || docId === "") {
      throw new Error('updateTag 引数不正 docId : ' + docId);
    }
    if (onedroTags === null || onedroTags === []) {
      throw new Error('updateTag 引数不正 tags : ' + onedroTags);
    }
    if (object === null || object === '') {
      throw new Error('object 対象テーブルが空: ' + object);
    }
    if (object !== 'Product' && object !== 'ProductPortforio') {
      throw new Error('オブジェクトは　 Product　か　ProductPortforio　のみ許可' + object);
    }
    return new Promise(async (resolve, reject) => {
      const onedroTagString = onedroTags.map((data, index) => {
        return data.text
      })
      const docRef = doc(db, object, docId);
      updateDoc(docRef, {
        onedroTags,
        onedroTagString,
        updatedAt: serverTimestamp()
      }).then((result) => {
        resolve(result);
      }).catch((error) => {
        console.log("タグのアップデートに失敗失敗 ")
        resolve()
      });
    });
  }
  // ===========================================================================================================================================================================

  /**
 * portforioを指定の件数取得
 * @param {count}
 * @return {[request]}
 */
  getPortforioProductList(count) {
    if (count === "" || count === null) {
      throw new Error('Parameter is not a number!');
    }

    return new Promise(async (resolve, reject) => {
      try {
        let result = []; //取得結果のリスト
        const ref = collection(db, "ProductPortforio");
        const q = await query(ref, orderBy("createdAt", "desc"), limit(count));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size == 0) {
          console.log("sample取得件数0")
          resolve(result);
        } else {
          querySnapshot.forEach((doc) => {
            let res = doc.data();
            res.id = doc.id;
            res.isPortforio = true;
            result.push(res);
          })
          resolve(result);

        }
      } catch (e) {
        console.log("getPortforioProductList")
        console.log(e);
      }
    });
  }

  /**
 * portforioを作者のuidで取得
 * @param {uid}
 * @return {[request]}
 */
  getPortforioProductByToUid(toUid) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = []; //取得結果のリスト
        const ref = collection(db, "ProductPortforio");
        const q = await query(ref, where("toUid", "==", toUid));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size == 0) {
          console.log("getPortforioProductByToUid 取得件数　０")
          resolve(result);
        } else {
          querySnapshot.forEach((doc) => {
            let res = doc.data();
            res.id = doc.id;
            result.push(res);
          })
          resolve(result);

        }
      } catch (e) {
        console.log("getRequestByToUid")
        console.log(e);
      }
    });
  }

  /**
   * ポートフォリオの登録
   */
  createPortforio = async ({
    requestId,
    blobDataResult,
    onedroTags = [],
    tags = [],
    toUid,
    toName,
    toIcon,
    isPortforio = true,
    nsfw,
    agreecheckSightShareFlg = 1

  }) => {
    this.gaEventTest("createPortforio")

    return new Promise(async (resolve, reject) => {
      this.fileHandler("image", auth.currentUser.uid, blobDataResult).then(({ url, thumbnail }) => {
        let tagsString = []
        if (onedroTags) {
          tagsString = tags.map((data, index) => {
            return data.text
          })
        }
        let onedroTagString = []
        if (onedroTags) {
          onedroTagString = onedroTags.map((data, index) => {
            return data.text
          })
        }
        try {
          // Productを作成
          addDoc(collection(db, "ProductPortforio"), {
            image: url,
            thumbnail: thumbnail,
            onedroTags,
            onedroTagString,
            tags,
            tagsString,
            toUid,
            toName,
            toIcon,
            nsfw,
            isPortforio: true,
            agreecheckSightShareFlg,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp()
          }).then((result) => {
            resolve(result);


          }).catch((error) => {

            console.log("FirebaseAuthのユーザをUPDATEする 失敗 ")
            resolve()
          });

        } catch (e) {
          console.log("データ登録に失敗")
          console.log(e)
          resolve(e);
        }
      })
    })
  }

  /**
 * idでportforioを取得
 * @param {id}
 * @return {[request]}
 */
  getPortforioProductById(id) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = []; //取得結果のリスト
        const docRef = doc(db, "ProductPortforio", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          //console.log("Document data:", docSnap.data());
          let res = docSnap.data()
          res.id = docSnap.id;
          resolve(res);

        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          resolve({ status: "データなし" })
        }
      } catch (e) {
        console.log("getRequestByToUid")
        console.log(e);
      }
    });
  }

  /**
   * ポートフォリオを削除
   * @param {id} id 
   * @returns 
   */
  deletePortforioProduct = (id) => {
    return new Promise(async (resolve) => {
      await deleteDoc(doc(db, "ProductPortforio", id)).then(() => {
        console.log("ポートフォリオ削除")
        resolve();
      }).catch((e) => {
        console.log("ポートフォリオ削除失敗")
        console.log(e);
      })

    })

  }


  // これはwebhockでやるので使わない
  /**
   * 入金による取引ステータスの更新
   * @param {id} id productのDocid 
   * @returns 
   */
  nyukin = (id) => {
    return new Promise(async (resolve, reject) => {
      console.log("nyukin")

      // ステータス更新
      this.getProductByid(id).then((result) => {
        const docRef = doc(db, "Product", result.id);
        updateDoc(docRef, {
          status: "paied"
        }).then((result) => {
          // 通知先はクリエイターtoユーザ
          const toUser = {
            uid: result.toUid,
            displayName: result.toName,
            icon: result.toIcon

          }

          this.statusUpdateNotify(toUser, "nyukin", result.id, `${result.fromName} からお支払いがありました`) //通知
        })
      })
    });
  }

  shareAmountPaied = (id, resultAmountText) => {
    return new Promise(async (resolve, reject) => {
      console.log("productAgreeCount")

      // ステータス更新
      this.getProductByid(id).then((result) => {
        const docRef = doc(db, "Product", result.id);
        updateDoc(docRef, {
          resultAmountText: resultAmountText,
          status: "paied"
        }).then(async () => {
          const reqRef = doc(db, "Request", result.requestId);
          updateDoc(reqRef, {
            resultAmountText: resultAmountText,
            status: "paied",
            paymentIntent: ""
          }).catch((e) => {
            console.log("お題側もpaiedに更新")
            console.log(e)
          })
          // 通知先はクリエイターtoユーザ
          const toUser = {
            uid: result.toUid,
            displayName: result.toName,
            icon: result.toIcon
          }

          this.statusUpdateNotify(toUser, "nyukin", result.requestId, `${result.fromName} からシェア報酬の実施報告がありました。詳細:
          ${resultAmountText}`) //通知
          resolve();
        })
      })
    });
  }




  /**
   * リクエストをidで取得する
   * @param {id} docid
   * @return {request}
   */
  getProductByid(id) {
    return new Promise(async (resolve, reject) => {
      //console.log(id)
      const docRef = doc(db, "Product", id);
      const docSnap = await getDoc(docRef);

      try {
        if (docSnap.exists()) {
          //console.log("Document data:", docSnap.data());
          let res = docSnap.data()
          res.id = docSnap.id;
          resolve(res);

        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          resolve({ status: "データなし" })
        }
      } catch (e) {
        console.log("getRequestByid");
        console.log(e);
        reject(e)
      }
    });
  }


  /**
 * リクエストのidでProductを取得
 * @param {OdaiId}
 * @return {[request]}
 */
  getProductByOdaiId(OdaiId) {
    return new Promise(async (resolve, reject) => {
      try {


        let result = []; //取得結果のリスト
        const ref = collection(db, "Product");
        const q = await query(ref, where("odaiId", "==", OdaiId));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.size == 0) {
          resolve(result);
          //this.sign("データなし")
        } else {
          //this.sign("データあり")
          this.getProductQuery(querySnapshot, resolve);
        }
      } catch (e) {
        console.log("getProductByOdaiId")
        console.log(e);
      }
    });
  }


  /**
   * リクエストのidでProductを取得
   * @param {id}
   * @return {[request]}
   */
  getProductByRequestId(id) {
    return new Promise(async (resolve, reject) => {
      try {


        let result = []; //取得結果のリスト
        const ref = collection(db, "Product");
        const q = await query(ref, where("requestId", "==", id));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.size == 0) {
          resolve(result);
          //this.sign("データなし")
        } else {
          //this.sign("データあり")
          this.getProductQuery(querySnapshot, resolve);
        }
      } catch (e) {
        console.log("getRequestByToUid")
        console.log(e);
      }
    });
  }

  /**
   * リクエストを受けたユーザのuidで取得
   * @param {uid}
   * @param {int} count
   * @return {[request]}
   */
  getProductByToUid(toUid, count) {
    return new Promise(async (resolve, reject) => {
      try {


        let result = []; //取得結果のリスト
        const ref = collection(db, "Product");
        const q = await query(ref, where("toUid", "==", toUid));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size == 0) {
          resolve(result);
        } else {
          this.getRequestQuery(querySnapshot, resolve);

        }
      } catch (e) {
        console.log("getRequestByToUid")
        console.log(e);
      }
    });
  }
  /**
   * リクエストをしたユーザのuidで取得
   * @param {uid}
   * @return {[request]}
   */
  getProductByFromUid(fromUid) {
    return new Promise(async (resolve, reject) => {
      let result = []; //取得結果のリスト
      const ref = collection(db, "Product");
      const q = await query(ref, where("toUid", "==", fromUid));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size == 0) {
        resolve(result);
      } else {
        this.getRequestQuery(querySnapshot, resolve);
      }
    });
  }

  /**
  * Product表示用のリスト取得クエリ
  * querySnapshotを実行した結果をリスト化して返すRefactoringメソッド
  * @param {querySnapshot, resolve} querySnapshot 
  * @param {[]} レコードの配列 
  */
  getProductQuery = async (querySnapshot, resolve) => {
    let result = []; //取得結果のリスト
    await querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      let res = doc.data();
      res.id = doc.id;
      res.anserLimit = doc.data().anserLimit.toDate()
      res.dereveryLimit = doc.data().dereveryLimit.toDate() | "";
      result.push(res);
    })
    resolve(result);

  }

  deleteProduct = (id) => {
    return new Promise(async (resolve) => {
      await deleteDoc(doc(db, "Product", id)).then(() => {
        console.log("作品削除")
        resolve();
      }).catch((e) => {
        console.log("作品削除失敗")
        console.log(e);
      })

    })
  }

  productAgreeCount(id, fromUid) {
    return new Promise(async (resolve, reject) => {
      console.log("productAgreeCount")

      // チュートリアル用
      // ===========
      ifProxyTu.activateFirstFav(fromUid);
      // ===========

      // アグリーカウントを加算する
      this.getProductByid(id).then((result) => {
        const docRef = doc(db, "Product", result.id);
        updateDoc(docRef, {
          agree: (result.agree + 1) || 1
        })
      })
    });
  }
  portforioAgreeCount(id, fromUid) {
    return new Promise(async (resolve, reject) => {
      console.log("productAgreeCount")

      // チュートリアル用
      // ===========
      ifProxyTu.activateFirstFav(fromUid);
      // ===========

      console.log("||||||||||||||||||")
      try {
        // アグリーカウントを加算する
        this.getPortforioProductById(id).then((result) => {
          const docRef = doc(db, "ProductPortforio", result.id);
          updateDoc(docRef, {
            agree: (result.agree + 1) || 1
          }).catch(e => {
            console.log(e)
          })
        })
      } catch (e) {
        console.log(e)
      }
    });
  }

  productShareCount(id, fromUid, url) {

    return new Promise(async (resolve, reject) => {
      // シェアカウントを加算する
      console.log("productShareCount")

      // チュートリアル用
      // ===========
      ifProxyTu.activateFirstShare(fromUid);
      // ===========

      // シェアカウントを加算する
      this.getProductByid(id).then((result) => {
        const docRef = doc(db, "Product", result.id);
        updateDoc(docRef, {
          share: (result.share + 1) || 1
        })
        this.addShare("product", id, fromUid, url)
        resolve()
      })
      // シェアレコードにユーザを登録する
    });
  }
  portforioShareCount(id, fromUid, url) {

    return new Promise(async (resolve, reject) => {
      // シェアカウントを加算する
      console.log("portforioShareCount")

      // チュートリアル用
      // ===========
      ifProxyTu.activateFirstShare(fromUid);
      // ===========

      // シェアカウントを加算する
      this.getPortforioProductById(id).then((result) => {
        const docRef = doc(db, "ProductPortforio", result.id);
        updateDoc(docRef, {
          share: (result.share + 1) || 1
        })
        this.addShare("product", id, fromUid, url)
        resolve()
      })
      // シェアレコードにユーザを登録する
    });
  }


  // CLUD
  /**  follow/
  フォロー テーブル =======================================================
  // フォロワー関係を作る。ユーザ名、アイコンも正規化せず持たせる
  id :docId
   
  // フォローしている人
  fromUid : Authのuidを持つ
  fromPhotoURL: AuthのphotoURL
  fromDisplayName: AuthのDisplayNameを固定
  // フォローされている人
  toUid : userDataのuid
  toPhotoURL:userDataのicon
  toDisplayName:userDataのDisplayNameを固定
   
  red: 既読フラグ
  createtAt:タイムスタンプ
  */

  /**
   * フォロー関係を追加
   * @param {{uid, displayName,photoURL}} fromUser するる人のUserオブジェクト
   * @param {{uid, displayName,icon}} toUser する人のUserDataオブジェクト
   * @returns 
   * 宛先に自分が入っている＝フォローされている
   * 送り元に自分が入っている＝自分がフォローしている
   */
  addFollow(fromUser, toUser) {
    //console.log("CHECK PARAM : addFollow")
    //console.log(toUser)
    //console.log(fromUser)
    this.gaEventTest("createFollow")

    return new Promise(async (resolve) => {
      let reration = {
        toUid: toUser.uid,
        toDisplayName: toUser.displayName,
        toPhotoURL: toUser.icon,
        fromUid: fromUser.uid,
        fromDisplayName: fromUser.displayName,
        fromPhotoURL: fromUser.photoURL,
        read: false,
        createtAt: serverTimestamp()
      };
      try {
        await addDoc(collection(db, "follow"), reration)
        resolve();

        // 通知
        const noti = {
          uid: toUser.uid, //通知の宛先
          fromUid: fromUser.uid, // 自分
          fromDisplayName: fromUser.displayName,
          fromIcon: fromUser.photoURL,

          toUid: toUser.uid,
          toDisplayName: toUser.displayName,
          toIcon: toUser.icon,


          text: `${fromUser.displayName} からフォローされました`,
          type: "follow",
        }
        this.createNotification(noti);
      } catch (e) {
        console.log("フォローデータ登録に失敗");
        console.log(e)
        resolve(e);
      }

      resolve();
    })
  }
  /**
   * フォロー関係を削除
   * @param {id} uid
   * @param {id} toUid
   * @returns 
   * 宛先に自分が入っている＝フォローされている
   * 送り元に自分が入っている＝自分がフォローしている
   */
  deleteFollow(uid, toUid) {
    return new Promise(async (resolve) => {

      try {
        const ref = collection(db, "follow");
        const q = await query(ref, where("fromUid", "==", uid), where("toUid", "==", toUid));
        const querySnapshot = await getDocs(q);
        await querySnapshot.forEach(async (document) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(document.id, " このデータを削除=> ", document.data());
          await deleteDoc(doc(db, "follow", document.id));
        })
        resolve();
      } catch (e) {
        console.log("フォローデータ削除に失敗");
        console.log(e)
        resolve(e);
      }

      resolve();
    })
  }
  /**
   * uidをフォローしている人を取得
   * 
   * @params {id} uid 
   * @returns follow[]
   * 宛先に自分が入っている＝フォローされている
   * 送り元に自分が入っている＝自分がフォローしている
   */
  getFollowerList(uid) {
    return new Promise(async (resolve) => {
      let result = []; //取得結果のリスト
      const ref = collection(db, "follow");
      const q = await query(ref, where("toUid", "==", uid));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size == 0) {
        resolve(result);
      } else {
        this.getResultQuery(querySnapshot, resolve)

      }
    })
  }
  /**
   * uidにフォローされている人
   * @param {id} uid 
   * @returns follower[]
   * 宛先に自分が入っている＝フォローされている
   * 送り元に自分が入っている＝自分がフォローしている
   */
  getFollowList(uid) {
    return new Promise(async (resolve) => {
      let result = []; //取得結果のリスト
      const ref = collection(db, "follow");
      const q = await query(ref, where("fromUid", "==", uid));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size == 0) {
        resolve(result);
      } else {
        this.getResultQuery(querySnapshot, resolve)
      }
    })
  }
  /**
   * フォローされてるか確認
   * @param {uid} uid そのuidが
   * @param {id} fromUid このユーザからフォローされているか fromUid
   * @returns T/F
   * 宛先に自分が入っている＝フォローされている
   * 送り元に自分が入っている＝自分がフォローしている
   */
  followedCheck(uid, fromUid) {
    return new Promise(async (resolve) => {
      const ref = collection(db, "follow");
      console.log("uid" + uid)
      console.log("fromUid" + fromUid)

      const q = await query(ref, where("toUid", "==", uid), where("fromUid", "==", fromUid));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size === 0) {
        resolve(false);
      } else {
        resolve(true);
      }
    })
  }/**
   * 既にフォローしているか確認
   * @param {id} uid UIDはフォローしているか 
   * @param {id} toUid このuidをフォローしているか
   * @returns T/F
   * 
   * 宛先(to)に自分が入っている＝フォローされている
   * 送り元（from）に自分が入っている＝自分がフォローしている
   */
  followCheck(uid, toUid) {
    console.log("FROM")
    console.log(uid)
    console.log("TO")
    console.log(toUid)

    return new Promise(async (resolve) => {
      try {
        const ref = collection(db, "follow");
        const q = await query(ref, where("fromUid", "==", uid), where("toUid", "==", toUid));
        const querySnapshot = await getDocs(q);
        console.log(querySnapshot.size)
        if (querySnapshot.size === 0) {
          resolve(false);
        } else {
          resolve(true);
        }
      } catch (e) {
        console.log(e);
      }

    })
  }

  // favを追加===========================

  /**
   * フォロー関係を追加
   * @param {fromUser, toUser}  userData型 fromはuser
   * @returns 
   */
  addFav(fromUser, toUser) {


    this.gaEventTest("createFav")


    return new Promise(async (resolve) => {

      // チュートリアル用
      // ===========
      ifProxyTu.checkActivateFav10(fromUser);
      // ===========

      let fav = {
        toUid: toUser.uid,
        toDisplayName: toUser.displayName,
        toIcon: toUser.icon,

        fromUid: fromUser.uid,
        fromDisplayName: fromUser.displayName,
        fromIcon: fromUser.photoURL,
        createtAt: serverTimestamp()
      };
      try {
        await addDoc(collection(db, "fav"), fav)
        resolve();

        // 通知
        const noti = {
          uid: toUser.uid, //通知の宛先
          fromUid: fromUser.uid, // 自分
          fromDisplayName: fromUser.displayName,
          fromIcon: fromUser.photoURL,

          toUid: toUser.uid,
          toDisplayName: toUser.displayName,
          toIcon: toUser.icon,


          text: `${fromUser.displayName} があなたに「興味がある！」を送信しました`,
          type: "fav",
        }
        this.createNotification(noti);
      } catch (e) {
        console.log("フォローデータ登録に失敗");
        console.error(e)
        resolve(e);
      }

      resolve();
    })
  }

  // favを追加===========================

  /**
   * シェアユーザの記録
   * @param {fromUser, toUser}  userData型
   * @returns 
   * 
   * id
   * fromUid　誰がシェアしてくれるか
      type request/product/user/reserve
      requestId
      productId
      toUid  対象ユーザ
      url
      createdAt
   */
  addShare(type, id, fromUid, url) {
    this.gaEventTest("createShare")

    return new Promise(async (resolve) => {
      let share = {
        type: type,
        requestId: type === "request" ? id : "",
        productId: type === "productId" ? id : "",
        toUid: type === "user" ? id : "",
        reserve: type === "reserve" ? id : "",
        fromUid: fromUid,
        url: url,
        createtAt: serverTimestamp()
      };
      try {
        await addDoc(collection(db, "share"), share)
        resolve();
      } catch (e) {
        console.log("フォローデータ登録に失敗");
        console.log(e)
        resolve(e);
      }

      resolve();
    })
  }

  // CLUD
  /**  block/
   
  ユーザ参照情報 テーブル =======================================================
  // 自分以外のユーザがAuthのユーザをおそらく取れないため、storage側にもアカウント周りの情報を持ち、そっちを使う
  tuhoUid: user.uid,
  tuhoDisplayName: user.displayName,
  blockUid: item.blockUid,
  blockId: item.blockId,
  blockDisplayName: item.blockDisplayName,
  reason: item.reason,
  osusume: item.osusume,
  createdAt: firebase.firestore.FieldValue.serverTimestamp() //レコード作成時刻
  */
  // ブロックユーザお呼びコンテンツ
  //引数itemの値
  // {
  // BlockUid
  // Blockid
  // BlockDisplayName
  // reason 通報理由
  // }
  /**
   * 
   * @param {*} loginUser 通報する人
   * @param {*} blockUser 通報される人
   * @returns 
   */
  insertBlock = (loginUser, blockUser) => {
    console.log("insertBlock===")
    console.log(blockUser.blockUid)
    return new Promise(async (resolve) => {
      let blockRelation = {
        tuhoUid: loginUser.uid,
        tuhoDisplayName: loginUser.displayName,
        blockUid: blockUser.blockUid,
        blockId: blockUser.blockId || "",
        blockDisplayName: blockUser.blockDisplayName,
        reason: blockUser.reason,
        createdAt: serverTimestamp() //レコード作成時刻
      };
      try {
        await addDoc(collection(db, "block"), blockRelation)
        console.log("ブロックリレーションに登録");
        console.log(blockRelation);
        resolve();
      } catch (e) {
        console.log("ブロックリレーション登録に失敗");
        console.log(e)
        resolve(e);
      }

    })
  }

  /**
   * uidがブロックしているまたはされているリストを取得
   * @param {*} uid ログインユーザのuid
   * @returns ブロックリレーション[]
   */
  checkBlock = (uid) => {
    console.log("====ckeckBlock=======")
    return new Promise(async (resolve, error) => {
      let result = [];
      const ref = collection(db, "block");

      // ログインユーザがブロックしているリスト
      const tuhoIng = await this.tuhoIngList(uid);
      const tuhoed = await this.tuhoEdList(uid);
      result = tuhoIng.concat(tuhoed);
      resolve(result);
    })
  };


  /**
  * 通報している、ブロックレコード
  * @param {*} uid ログインユーザ
  */
  tuhoIngList = (uid) => {
    return new Promise(async (resolve, error) => {
      let result = [];
      const ref = collection(db, "block");
      const q = await query(ref, where("tuhoUid", "==", uid));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size === 0) {
        resolve(result);
      } else {
        await querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, "checkBlock => ", doc.data());
          let res = doc.data();
          res.id = doc.id;
          result.push(res);
        })
        //console.log(result)
        resolve(result);
      }
    });
  }

  /**
   * 通報されている、ブロックレコード
   * @param {*} uid ログインユーザ
   */
  tuhoEdList = (uid) => {
    return new Promise(async (resolve, error) => {
      let result = [];
      const ref = collection(db, "block");
      // ログインユーザがブロックされているリストを取得し統合
      const qed = await query(ref, where("blockUid", "==", uid));
      const querySnapshoted = await getDocs(qed);
      if (querySnapshoted.size === 0) {
        //終わり
        resolve(result)
      } else {
        await querySnapshoted.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, "checkBlockEd => ", doc.data());
          let res = doc.data();
          res.id = doc.id;
          result.push(res);
        })
        //console.log(result)
        resolve(result)
      }
    });
  }



  /**
   * ログインユーザがブロックしているユーザリストを取得する
   * @param {*} uid 
   * @returns 
   */
  checkBlockingList = (uid) => {
    //console.log("====checkBlockingList=======")

    return new Promise(async (resolve, error) => {
      let result = [];
      const ref = collection(db, "block");

      // ログインユーザがブロックしているリスト
      const q = await query(ref, where("tuhoUid", "==", uid));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size === 0) {
        resolve(result);
      } else {
        await querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, "checkBlock => ", doc.data());
          let res = doc.data();
          res.id = doc.id;
          result.push(res);
        })
        resolve(result);
      }
    });
  }


  /**
   * ブロックを解除する
   * @param {id} BlockRelationのdocid 
   * @returns 
   * 宛先に自分が入っている＝フォローされている
   * 送り元に自分が入っている＝自分がフォローしている
   */
  deleteBlock(id) {
    return new Promise(async (resolve) => {
      await deleteDoc(doc(db, "block", id)).then(() => {
        console.log("ブロック解除")
        resolve();
      }).catch((e) => {
        console.log("ブロック解除失敗")
        console.log(e);
      })

    })
  }
  // CLUD
  /**  通知　Notification/
   
  通知　Notification テーブル =======================================================
   
   
  /**
   * 通知レコードを作成する
   * @param {*} data 
   * @returns 
   * 
   * ルール
   * type に種別を記載する
   * request リクエストあり/
   * jyudaku 受諾/
   * nohin 納品あり/
   * nyukin 入金/
   * cancel キャンセル/
   * creatorCancel 作家キャンセル/
   * title タイトル獲得/
   * favファボ/
   * follow フォロー/
   * system システム通知
   * lim 限定リクエスト
   * 
   * 宛先送り元ユーザの次の項目を埋める
   * // 通知
    const noti = {
      uid: toUser.uid, //通知の宛先
      fromUid: fromUser.uid, // 自分
      fromDisplayName: fromUser.displayName,
      fromIcon: fromUser.photoURL,
   
      toUid: toUser.uid,
      toDisplayName: toUser.displayName,
      toIcon: toUser.icon,
   
      targetId: リンク先がユーザじゃない場合はdocidを入れる
   
   
      text: `${fromUser.displayName} からフォローされました`,　<-メッセージを定義
      type: "follow", ←種別はここに設定
    }
   */
  createNotification = (data) => {
    return new Promise(async (resolve) => {
      data.read = false
      data.createdAt = serverTimestamp() //レコード作成時刻
      try {
        await addDoc(collection(db, "Notification"), data)
        resolve();
      } catch (e) {
        console.log("通知データ登録に失敗");
        console.log(e)
        resolve(e);
      }
    })
  }

  /** 
   * uidのユーザに来ている通知情報を全て取得する
   * @param {*} uid 
   * @returns 
   */
  getNotification = (uid) => {
    //console.log("getNotification======")
    //console.log(uid)
    return new Promise(async (resolve) => {
      let notificationList = []
      const ref = collection(db, "Notification");
      const q = await query(ref, where("uid", "==", uid), orderBy("createdAt", "desc"), limit(50));
      const querySnapshot = await getDocs(q);
      console.log(querySnapshot.size)
      if (querySnapshot.size === 0) {
        console.log("通知データなし")
        resolve(notificationList);
      } else {
        await querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, "getNotification => ", doc.data());
          let res = doc.data();
          res.id = doc.id;
          notificationList.push(res);
        })
        resolve(notificationList);
      }
    });
  }
  /** 
  * 公式の全体向け通知
  * @param {*} uid 
  * @returns 
  * 
  * テーブル OfficialInfo
  * title
  * discription
  * createdAt
  * 内容の登録は、Firebaseコンソール上から行う
  */
  getOfficialNotification = () => {
    console.log("getOfficialNotification======")
    return new Promise(async (resolve) => {
      let notificationList = []
      const ref = collection(db, "OfficialInfo");
      const q = await query(ref, orderBy("createdAt", "desc"), limit(50));
      const querySnapshot = await getDocs(q);
      console.log(querySnapshot.size)
      if (querySnapshot.size === 0) {
        console.log("通知データなし")
        resolve(notificationList);
      } else {
        await querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, "getOfficialNotification => ", doc.data());
          let res = doc.data();
          res.id = doc.id;
          notificationList.push(res);
        })
        console.log("resolve")
        // console.log(notificationList)
        resolve(notificationList);
      }
    });
  }

  /**
   * uidのユーザがの通知を全て既読にする
   * @param {*} uid 
   * 
   * uidのユーザの既読フラグを全部falseにする
   * getNotificationのユーザが画面を閉じるときに、これも非同期で実行する
   * 一旦ボタンで既読にする
   */
  readNotification = (uid) => {
    return new Promise(async (resolve) => {
      let targetDoc = []
      const ref = collection(db, "Notification");
      const q = await query(ref, where("uid", "==", uid), where("read", "==", false));
      const querySnapshot = await getDocs(q)
      if (querySnapshot.size === 0) {
        console.log("通知データなし")
        resolve();
      } else {
        await querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, "readNotification => ", doc.data());
          targetDoc.push(doc.id);
        })
        for (let doclist of targetDoc) {
          const document = doc(db, "Notification", doclist);
          await updateDoc(document, {
            read: true
          })
        }
        resolve();
      }
    });
  }
  /**
   * uidのユーザに未読の通知が存在するか確認
   * @param {*} uid 
   * @returns boolean 未読がある T
   */
  checkRead = (uid) => {
    return new Promise(async (resolve) => {
      const ref = collection(db, "Notification");
      const q = await query(ref, where("uid", "==", uid), where("read", "==", false), limit(50));
      const querySnapshot = await getDocs(q);
      console.log(querySnapshot.size)
      if (querySnapshot.size === 0) {
        console.log("通知データなし")
        resolve(false);
      } else {
        resolve(true);
      }
    })
  }

  /**
   * blobデータを入れたら、サムネイルを生成。ファイルアップロードを行いそのurlを返却する
   * @param {String} strageName 保存するfirestoreのフォルダ名
   * @param {String} ownerUid  ファイル名を一位にするために作成者uidを付与する
   * @param {blob} blob blobデータのファイル本体
   * @returns {url, thumbnail}
   */
  fileHandler = (strageName, ownerUid, blob) => {
    return new Promise(async (resolve, reject) => {
      // サムネイル化しblobを取得
      const thumbnail = await this.resizeFile(blob);

      // Storageへ画像データを登録
      var storage = getStorage();
      const fileName = this.randomstr();
      //this.sign(fileName)
      //this.sign(this.randomstr())
      var storageRef = ref(storage, `/${strageName}/${ownerUid}/${fileName}`);
      uploadBytesResumable(storageRef, blob).then((uploadTask) => {
        getDownloadURL(ref(storage, `/${strageName}/${ownerUid}/${fileName}`))
          .then((url) => {
            resolve({ url: url, thumbnail: thumbnail })
          }).catch((error) => {
            // getDownloadURLに失敗
            console.log("getDownloadURLに失敗")
            console.log(error)
            reject(error);
          });
      }).catch((error) => {
        // uploadBytesResumableに失敗
        console.log("uploadBytesResumableに失敗")
        console.log(error)
        reject(error);
      });
    })
  }

  // UUIDを生成。ユーザ単位のファイル名に使用する
  randomstr = () => {
    let s = "";
    let length = 32;
    for (let i = 0; i < length; i++) {
      let random = Math.random() * 16 | 0;
      s += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return s
  }

  /**
  * querySnapshotを実行した結果をリスト化して返すRefactoringメソッド
  * @param {querySnapshot, resolve} querySnapshot 
  * @param {[]} レコードの配列 
  */
  getResultQuery = (querySnapshot, resolve) => {
    let result = []
    try {
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        let res = doc.data();
        res.id = doc.id;
        result.push(res);
      })
      resolve(result);
    } catch (e) {
      console.log("データ取得に失敗")
      console.log(e)
      resolve(e);
    }
  }


  // 招待コード関連処理 ================================
  /**
   * 招待コードを配布したユーザが存在することを確認する
   * @param {*} code 
   * @returns 
   */
  checkInvitationcode(code) {
    if (!code) {
      alert("checkInvitationcode コード値が不正[" + code + "]")
      return
    }
    if (code === "") {
      alert("checkInvitationcode コード値が不正[" + code + "]")
      return
    }
    return new Promise(async (resolve, reject) => {

      // TODO await addDoc(collection(db, "Invitation"), reration)

    })
  }

  /**
   * 招待コードを発行する
   * @returns 
   */
  addInvitationcode(count) {
    return new Promise(async (resolve, reject) => {

      try {
        // 招待コードはcountレコードを作成
        Array.from({ length: count }, async () => {
          await addDoc(collection(db, "Invitation"), {
            invitingUid: auth.currentUser.uid,
            invitedUid: "",
            createdAt: serverTimestamp(),
          })
        });
        resolve()
      } catch (e) {
        console.log(e)
      }

    });
  }

  /**
   * 紹介コードを消費する
   * @param {*} docid 
   */
  consumeInvitationcode(docid) {
    if (docid === "" || !docid) {
      alert("consumeInvitationcode docid値が不正[" + docid + "]")
      return
    }

    return new Promise(async (resolve, reject) => {

      const docRef = doc(db, "Invitation", docid);
      updateDoc(docRef, {
        invitedUid: auth.currentUser.uid
      }).then((result) => resolve()).catch((e) => {
        console.log(e);
        reject(e)
      })
    })
  }

  /**
   * 紹介者または利用者のuidでInvitationを取得する
   * 
   * @param {String} type  invitingUidまたはinvitedUid２値
   * @param {id} uid
   * @returns 
   */
  getInvitationrecordByUid(type, uid) {
    if (!(type == "invitingUid" || type == "invitedUid")) {
      alert("checkInvitationcode typeが不正[" + type + "]")
      return
    }

    return new Promise(async (resolve, reject) => {
      try {
        const ref = collection(db, "Invitation")

        const q = await query(ref, where(type, "==", uid))
        const querySnapshot = await getDocs(q);
        let invitationList = []

        if (querySnapshot.size === 0) {
          console.log("招待idデータなし")
          resolve(false);
        } else {
          await querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, "Invitation => ", doc.data());
            let res = doc.data();
            res.id = doc.id;
            invitationList.push(res);

          })
          console.log("invitationList")
          console.log(invitationList)
          resolve(invitationList);
        }
      } catch (e) {
        console.log("getInvitationrecordByUid 処理失敗")
        console.log(e)
      }
    });
  }

  /**
 * イベントによる招待コード発行
 * 既存コードによらず、招待コードと招待の消費を行ったレコードを一度で作成する
 * 招待者invitingUidは、イベントのコード(C102等)を発行し、どのイベントで招待されたかを登録する
 * invitedUidは、そのイベントで登録したユーザのuidが登録される
 * 
*  @classdesc eventInvitation では、招待コードの存在チェックはスキップする。招待コードが正しいかは画面側でチェックする。コードの条件は画面ベタ打ち
 * @param {String} code 
 */
  eventInvitation(code) {
    if (code === "" || !code) {
      alert("招待コード値がが不正[" + code + "]")
      return
    }

    return new Promise(async (resolve, reject) => {
      await addDoc(collection(db, "Invitation"), {
        invitingUid: code,
        invitedUid: auth.currentUser.uid, //招待された人のuidを即時設定
        createdAt: serverTimestamp(),
      }).then((result) => resolve()).catch((e) => {
        console.log(e);
        reject(e)
      })
    })
  }

  /**
   * 
   * @param {*} docid 
   * @returns {Invitation || false(データがない場合はbool)}
   */
  getInvitationrecordByDocid(docid) {

    if (docid === "" || !docid) {
      alert("getInvitationrecordByDocid docid値が不正[" + docid + "]")
      return false
    }

    return new Promise(async (resolve, reject) => {
      const docRef = doc(db, "Invitation", docid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        let res = docSnap.data()
        res.id = docSnap.id;
        resolve(res);

      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        resolve(false)
      }
    })
  }




  //ローカルストレージの操作 =======================================================
  /*
  * nsfw: t/F nsfwを表示するかどうか
  * Tの場合は、データを読み出すときにフィルタで除去する
  */
  setNsfwFlg(flg) {
    return new Promise(async (resolve) => {
      let flgVal = "";
      if (flg) {
        flgVal = "true"
      } else {
        flgVal = "false"
      }
      localStorage.setItem('nsfw', flgVal);
      resolve();
    }
    )
  }

  checkNsfwFlg() {
    return new Promise(async (resolve) => {
      var flgVal = localStorage.getItem('nsfw');

      if (flgVal === "true") {
        resolve(true)
      } else {
        resolve(false)
      }

    }
    )

  }

  // Util系のメソッド
  /**
   * param timestamp(FirebaseのTimestamp)
   * return String (YYYY年MM月DD日)
   */
  formatFirebaseTimestampToString = (timestamp) => {
    const date = timestamp.toDate();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    const formatedDate = year + '年' + month + '月' + day + '日';

    //console.log("フォーマッた動作確認" + year + '年' + month + '月' + day + '日');

    return formatedDate
  }

  formatDateString = (inputDate) => {
    let date = new Date(inputDate);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    const formatedDate = year + '年' + month + '月' + day + '日';

    //console.log("フォーマッた動作確認" + year + '年' + month + '月' + day + '日');

    return formatedDate
  }

  /**
   * firebaseTimestampが、本日より過去であればT
   * @param {*} timestamp 
   * @returns bool 
   */
  overFirebaseTimestampDate = (timestamp) => {
    const date = timestamp.toDate();
    return date < new Date();
  }

  /**
   * 表示前の投稿イラストリストから、ブロック関係にあるユーザの投稿を除去する
   * @param {*} targetList データ型:投稿（Illustlation)の配列
   * @param {*} loginUid 
   * return フィルタ済みの 投稿リスト
   */
  /*
  filterBlockData = (targetList, loginUid) => {
    return new Promise(async (resolve, error) => {
      let filterdIllustlationList = [];
      let blockList = await this.checkBlock(loginUid)
      console.log("======ブロックりすと=========")
      console.log(blockList)
   
      // ブロックリストをフィルタする
      if (blockList != null) {
        filterdIllustlationList = targetList.filter((output, index) => {
          let result = true;
   
          for (let blackListData of blockList) {
            // ブラックリストに含まれている場合falseにする
            if (output.uid.includes(blackListData.blockUid)) {
              // 自分がブロックしたユーザのuidが作成者の場合false
              // ※ 他人が自分をブロックし、自分の絵が含まれるため除外(from->to両方をorで合わせてリストを持ってきているため)
              if (output.uid === loginUid) {
                result = true;
              } else {
                result = false
              }
   
            } else if (output.uid.includes(blackListData.tuhoUid)) {
              // 自分をブロックしているユーザのuidが作成者の場合はfalse
              // ※ 他人をブロックした自分のuidがtohoUidに含まれているためそれは除外
              if (output.uid === loginUid) {
                result = true;
              } else {
                result = false
              }
            }
          }
          return result;
        })
      } else {
        // ブラックリストを登録していない場合
        filterdIllustlationList = targetList;
      }
   
      resolve(filterdIllustlationList);
    });
  }
  */

  /**
   * ファイルサイズをサムネイル用に縮小する。
   * fireを投入し blobを返す
   * @param {*} file 
   * @returns blob uri base64フォーマットのイメージ
   */
  resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        200,
        200,
        "JPG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });


  sign = (msg, state) => {
    console.log("====" + state + " ======")
    console.log("==========")
    console.log(msg)
    console.log("==========")
    console.log("==========")
    console.log("==========")

  }


  /******************************************************************
   * GETDATA FOR HOME
   * 
   * ホーム画面および関係リストのためのデータ読み取り
   * 推奨順やランキングのアルゴリズムは以下で検討
   * 送信する情報は最小限に絞る
   ******************************************************************/

  /**
   * Product[]を取得する
   * @param {number} count 何軒表示(limit)するか
   */
  getCyumokuProduct = (count) => {
    return new Promise(async (resolve, reject) => {


      // タイムスタンプを取得
      let todayStamp = this.homeCommonDateToTimestamp(0); // 0日前（今日）
      let lastmStamp = this.homeCommonDateToTimestamp(30);// 30日前

      try {
        let result = []; //取得結果のリスト
        const ref = collection(db, "Product");
        let q;
        // TODO 期日判定の要件、一旦緩和。将来的に継続的な投稿が出てきたら
        //q = await query(ref, orderBy("createdAt", "desc"), startAt(todayStamp), endAt(lastmStamp), limit(count));
        q = await query(ref, orderBy("createdAt", "desc"), startAt(todayStamp), endAt(lastmStamp), limit(count));

        const querySnapshot = await getDocs(q);
        if (querySnapshot.size == 0) {
          resolve(result);
        } else {
          this.getProductQuery(querySnapshot, resolve);

        }

      } catch (e) {
        this.homeCommonError(e, reject)
      }
    })
  }

  /**
   * Product[]を取得する
  * @param {id} lastVisible 最後に表示したレコード
   */
  getCyumokuProduct_paging = (lastVisible = false) => {

    if (!lastVisible) {
      console.log("最後のデータのため検索を終了");
      return false;
    }
    return new Promise(async (resolve, reject) => {

      // タイムスタンプを取得
      let todayStamp = this.homeCommonDateToTimestamp(0); // 0日前（今日）
      let lastmStamp = this.homeCommonDateToTimestamp(30);// 30日前

      try {
        let result = []; //取得結果のリスト
        const ref = collection(db, "Product");
        // TODO 元リストと同じソート順でないと結果がチグハグになる
        const docSnap = await getDoc(doc(ref, lastVisible));
        const q = await query(ref, orderBy("createdAt", "desc"), startAfter(docSnap), limit(10));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size == 0) {
          resolve(result);
        } else {
          this.getRequestQuery(querySnapshot, resolve);

        }

      } catch (e) {
        this.homeCommonError(e, reject)
      }
    })
  }
  /**
  * Request[]を取得する
   * @param {number} count 何軒表示(limit)するか
  */
  getCyumokuRequest = (count) => {
    return new Promise(async (resolve, reject) => {

      // タイムスタンプを取得
      let todayStamp = this.homeCommonDateToTimestamp(0); // 0日前（今日）
      let lastmStamp = this.homeCommonDateToTimestamp(30);// 30日前

      try {
        let result = []; //取得結果のリスト
        const ref = collection(db, "Request");

        const q = await query(ref, orderBy("createdAt", "desc"), startAt(todayStamp), endAt(lastmStamp), limit(count));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size == 0) {
          resolve(result);
        } else {
          this.getRequestQuery(querySnapshot, resolve);

        }

      } catch (e) {
        this.homeCommonError(e, reject)
      }
    })
  }

  /**
  * Request[]を取得する
  * @param {id} lastVisible 最後に表示したレコード
  */
  getCyumokuRequest_paging = (lastVisible) => {

    if (!lastVisible) {
      console.log("最後のデータのため検索を終了");
      return false;
    }
    return new Promise(async (resolve, reject) => {

      // タイムスタンプを取得
      let todayStamp = this.homeCommonDateToTimestamp(0); // 0日前（今日）
      let lastmStamp = this.homeCommonDateToTimestamp(30);// 30日前

      try {
        let result = []; //取得結果のリスト
        const ref = collection(db, "Request");
        const docSnap = await getDoc(doc(ref, lastVisible));

        const q = await query(ref, orderBy("createdAt", "desc"), startAfter(docSnap), limit(10));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size == 0) {
          resolve(result);
        } else {
          this.getRequestQuery(querySnapshot, resolve);

        }

      } catch (e) {
        this.homeCommonError(e, reject)
      }
    })
  }


  // WantCount
  //もし描いたら見たい、もし描いたらシェアする、ボタンを同じユーザが多重に押せないように、記録する

  /**
   * 
   * @param {id} requestId Requestのid
   * @param {boolian } wantSee みたい　チェック済み
   * @param {boolean} wantSheare シェアする　チェック済み
   */
  updateWantCount = (requestId, wantSee, wantSheare) => {
    return new Promise(async (resolve, reject) => {
      const ref = collection(db, "WantCount");
      const q = await query(ref, where("requestId", "==", requestId));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size == 0) {
        addDoc(collection(db, "WantCount"), {
          requestId: requestId,
          wantSee: wantSee,
          wantSheare: wantSheare
        }).then((result) => resolve()).catch((e) => {
          console.log(e);
          reject(e)
        })
      } else {
        console.log(querySnapshot)
        querySnapshot.forEach((document) => {
          const docRef = doc(db, "WantCount", document.id);
          updateDoc(docRef, {
            wantSee: wantSee,
            wantSheare: wantSheare
          }).then((result) => resolve()).catch((e) => {
            console.log(e);
            reject(e)
          })
        })


      }
    })
  }

  /**
   * 
   * @param {IdleRequestOptions} requestId Requestのid
   * @returns  { requestId: string, wantSee: bool, wantSheare: bool }
   */
  getWantCount = (requestId) => {
    return new Promise(async (resolve, reject) => {
      const ref = collection(db, "WantCount");
      const q = await query(ref, where("requestId", "==", requestId));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size == 0) {
        resolve({ requestId: requestId, wantSee: false, wantSheare: false });
      } else {
        let result = []
        await querySnapshot.forEach((doc) => {
          let res = doc.data();
          res.id = doc.id;
          result.push(res)
        })
        resolve(result[0]);
      }
    })
  }




  // 集計用スコアの計算  ========================================
  /*
  定時バッチでは無駄が大きい。利用していないユーザデータの処理が必要となる
  更新があったときに個別に再計算する方法を取る
  */
  /**
   * 受諾数
   * @param {IDBCursorDirection} toUid 
   */
  calcEntrusted = (toUid) => {
    return new Promise(async (resolve, reject) => {

      const ref = collection(db, "Request");
      const q = await query(ref, where("status", "in", ["entrusted", "paied", "completed"]), where("toUid", "==", toUid));
      this.calcExecuteQuery(q, resolve, reject)
    });
  }

  /**
   * 完了数
   * @param {id} toUid 
   */
  calcCompletedAmount = (toUid) => {
    return new Promise(async (resolve, reject) => {

      const ref = collection(db, "Request");
      const q = await query(ref, where("status", "in", ["paied", "completed"]), where("toUid", "==", toUid));
      this.calcExecuteQuery(q, resolve, reject)
    });
  }

  /**
   * 受諾平均金額（有償のものに限る）
   * @param {id} toUid 
   */
  calcAmount = (toUid) => {
    return new Promise(async (resolve, reject) => {

      const ref = collection(db, "Request");
      const q = await query(ref, where("status", "in", ["paied", "completed"]), where("amount", ">", 0), where("toUid", "==", toUid));
      try {
        let result = []; //取得結果のリスト
        let amountAvelage = 0;
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size == 0) {
          console.log("calcAmount:検索結果なし")
          resolve(result);
        } else {
          await querySnapshot.forEach((doc) => {
            //console.log(doc.id, " => ", doc.data());
            let res = doc.data();
            res.id = doc.id
            amountAvelage = amountAvelage + parseInt(doc.data().amount, 10)
            result.push(res);
          })
          //console.log(amountAvelage + "/" + querySnapshot.size)
          const ave = (amountAvelage / querySnapshot.size)
          //console.log(Math.round(ave))
          resolve(Math.round(ave));
        }
      } catch (e) {
        console.log(e)
        reject(e)
      }
    });
  }

  /**
  * 受諾から納品までの平均期間
  * @param {id} toUid 
  * @return {{ aveh: number, aved: number }} 平均時間、平均日数を含むオブジェクトを返す
  */
  calcTermAvelage = (toUid) => {
    return new Promise(async (resolve, reject) => {

      const ref = collection(db, "Request");
      const q = await query(ref, where("status", "in", ["paied", "completed"]), where("toUid", "==", toUid));
      try {
        let result = []; //取得結果のリスト
        let houerAmount = 0;
        let dateAmount = 0;
        let targetCount = 0

        const querySnapshot = await getDocs(q);
        if (querySnapshot.size == 0) {
          console.log("calcAmount:検索結果なし")
          resolve(result);
        } else {
          await querySnapshot.forEach((doc) => {
            //console.log(doc.id, " => ", doc.data());
            let res = doc.data();
            res.id = doc.id
            if (res.entrustedDate && res.completedDate) {
              targetCount++
              //console.log("日付計算、開始日")
              //console.log(res.entrustedDate)
              const entmil = res.entrustedDate.seconds * 1000;

              //console.log("日付計算、終了日")
              //console.log(res.completedDate)
              const compmil = res.completedDate.seconds * 1000;

              const diffInMilliseconds = Math.abs(compmil - entmil);
              const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
              const diffInMinutes = Math.floor(diffInSeconds / 60);
              const diffInHours = Math.floor(diffInMinutes / 60);
              const diffInDays = Math.floor(diffInHours / 24);
              /*
              console.log("日付計算、差を計算_mil")
              console.log(diffInMilliseconds)
              console.log("日付計算、差を計算_時間")
              console.log(diffInHours)
              console.log("日付計算、差を計算_日付")
              console.log(diffInDays)
              */
              houerAmount = houerAmount + diffInHours
              dateAmount = dateAmount + diffInDays
            }
          })
          //console.log(amountAvelage + "/" + querySnapshot.size)
          const aveh = (houerAmount / targetCount)
          const aved = (dateAmount / targetCount)
          //console.log("平均時間、日数")
          //console.log(targetCount)
          //console.log(aveh)
          //console.log(aved)

          resolve({ aveh: Math.round(aveh), aved: Math.round(aved) });
        }
      } catch (e) {
        console.log(e)
        reject(e)
      }
    });
  }

  /**
   * 未払い数 この処理はwebhookで受信後に更新しないと必ず未払いが残ってしまう
   * @param {id} fromUid 
   */
  calcUnsettlementAmount = (fromUid) => {
    return new Promise(async (resolve, reject) => {

      const ref = collection(db, "Request");
      const q = await query(ref, where("status", "in", ["completed"]), where("fromUid", "==", fromUid));
      this.calcExecuteQuery(q, resolve, reject)
    });

  }

  /**
   * クリエイターレーティングの平均値
   * @param {id} toUid 作家のuid
   * @return {number} Productにつけられた　レーティングの平均
   */
  calcRatingAvelage = (toUid) => {
    return new Promise(async (resolve, reject) => {

      const ref = collection(db, "Product");
      const q = await query(ref, where("rating", "!=", ""), where("toUid", "==", toUid));
      try {
        let result = []; //取得結果のリスト
        let ratingAmount = 0;
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size == 0) {
          console.log("calcAmount:検索結果なし")
          resolve(2);
        } else {
          await querySnapshot.forEach((doc) => {
            //console.log(doc.id, " => ", doc.data());
            let res = doc.data();
            res.id = doc.id
            ratingAmount = ratingAmount + parseInt(doc.data().rating, 10)
            result.push(res);
          })
          //console.log(amountAvelage + "/" + querySnapshot.size)
          const ave = (ratingAmount / querySnapshot.size)
          //console.log(Math.round(ave))
          resolve(Math.round(ave));
        }

      } catch (e) {
        console.log(e)
        reject(e)
      }
    });
  }

  calcExecuteQuery = async (q, resolve, reject) => {
    try {
      let result = []; //取得結果のリスト
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size == 0) {
        console.log("calcExecuteQuery:検索結果なし")
        resolve(result);
      } else {
        console.log("calcExecuteQuery:検索結果")
        console.log(querySnapshot.size)
        resolve(parseInt(querySnapshot.size, 10));
      }
    } catch (e) {
      console.log(e)
      reject(e)
    }
  }



  /**
   * 
   * @param {id} id 
   * @param {number} rating 
   */
  addRating = (id, rating) => {
    return new Promise(async (resolve, reject) => {
      // productにrating値をもつ
      this.getProductByid(id).then((result) => {
        const docRef = doc(db, "Product", result.id);
        updateDoc(docRef, {
          rating: rating
        }).then((result) => resolve()).catch((e) => {
          console.log(e);
          reject(e)
        })
      })
    })
  }

  // タグ台帳 =================================================


  /**
   * 
   * @param {[{ id: 'オリジナル', text: 'オリジナル' }]} tagList
   * @param {('ワンドロ企画'|'タグ')} type　'ワンドロ企画'|'タグ'
   * @returns 
   */
  insertTag = async (tagList, type) => {
    return new Promise(async (resolve, reject) => {
      try {
        //貰ったタグをidだけにする
        const newTags = tagList.map((data, index) => {
          return data.id
        })
        //console.log("newTagsの中身")
        //console.log(newTags)

        // 今あるタグを取得しidだけにする
        const oldTag = await this.getTag(type);
        let resultNewTags = []
        //console.log("oldTagの中身")
        //console.log(oldTag)
        //console.log(oldTag.length >= 1)
        if (oldTag.length >= 1) {
          const oldTags = oldTag.map((data, index) => {
            return data.id
          })
          // newTagsからoldTagsを引いて余ったもの（今回の新規）があれば登録
          // oldTagを、newTagで検索してあるかどうか。ない(-1)ものだけで配列を作る
          resultNewTags = newTags.filter(i => oldTags.indexOf(i) == -1)
          //console.log("resultNewTags==============================================")
          //console.log(resultNewTags)
        } else {
          resultNewTags = newTags
        }
        // kikakuMstを作成
        // ワンドロ企画タグ名と普通のタグ名もの台帳
        for (const resultNewTag of resultNewTags) {
          addDoc(collection(db, "TagKikakuMst"), {
            id: resultNewTag,
            value: resultNewTag,
            type: type,
            primary: 0,
            createdAt: serverTimestamp()
          })
        }

      } catch (e) {
        console.log("insertTag 失敗")
        console.log(e)
        reject(e)
      }
      resolve()

    })


  }
  /**
   * 
   * @param {("ワンドロ企画"|"タグ")} type 
   * @param {number} count 
   * @return {[{ id: 'オリジナル', text: 'オリジナル' }]} tagList
   */
  getTag = (type, count = 0) => {
    return new Promise(async (resolve, reject) => {
      // タイムスタンプを取得
      let todayStamp = this.homeCommonDateToTimestamp(0); // 0日前（今日）
      let lastmStamp = this.homeCommonDateToTimestamp(360);// 30日前 TODO 一旦、1年分

      // 推奨の方法
      // ここでタグは全部抜く。その上でjs側で入れた文字に応じてフィルタする
      const ref = collection(db, "TagKikakuMst");
      let q = {}
      if (count === 0) {
        q = await query(ref, orderBy("createdAt", "desc"), where("type", "==", type), startAt(todayStamp), endAt(lastmStamp));
      } else {
        q = await query(ref, orderBy("createdAt", "desc"), where("type", "==", type), startAt(todayStamp), endAt(lastmStamp), limit(count));

      }

      let result = [];
      try {
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size == 0) {
          console.log("登録タグなし")
          resolve(result);
        } else {
          // タグの配列を返す
          //return [{ id: 'オリジナル', text: 'オリジナル' }]
          await querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
            let res = doc.data();
            // res.id = doc.id; これはdocid
            /*
            const resultTag = {
              id: res.id, // tagのid。valueと同じ値が帰る
              value: res.value,
              type: res.type,
              primary: res.primary,
            }
            */
            const resultTag = {
              id: res.id, // tagのid。valueと同じ値が帰る
              text: res.value,
            }
            result.push(resultTag);
          })
          resolve(result);
        }
      } catch (e) {
        console.log("getTag データ取得に失敗")
        console.log(e)
        reject(e);
      }
    })
  }


  //  ===================== お題============================
  //  =================================================
  //  =================================================
  //  =================================================
  //  =================================================
  //  =================================================


  /**
   * お題を作成する
   * @param {{string, string, kikaku/free,0/1, [],[] ,uid, string, url}} data
   * @returns 
   */
  addOdai = ({
    odaiTitle,
    odaiDetail,
    type,
    typeCommision,
    onedroTag,
    onedroTagString,
    fromUid,
    fromUser,
    fromUrl,
    agreecheckSightShareFlg,
  }, data) => {
    this.gaEventTest("createOdai")

    if (!odaiTitle || odaiTitle === "") {
      console.log("addOdai パラメータ不正 : odaiTitle: " + odaiTitle)
      return
    }
    if (!odaiDetail || odaiDetail === "") {
      console.log("addOdai パラメータ不正 : odaiDetail: " + odaiDetail)
      return
    }
    if (!type || type === "") {
      console.log("addOdai パラメータ不正 : type: " + type)
      return
    }
    if (typeCommision !== 0 && typeCommision !== 1) {
      // 0でなくかつ１でもない場合
      console.log("addOdai パラメータ不正 : typeCommision: " + typeCommision)
      return
    }
    if (type === "kikaku" && (!onedroTag || onedroTag === "")) {
      console.log("addOdai パラメータ不正 : onedroTag: " + onedroTag)
      return
    }
    if (!fromUid || fromUid === "") {
      console.log("addOdai パラメータ不正 : fromUid: " + fromUid)
      return
    }
    if (type === "kikaku" && (!onedroTagString || onedroTagString === "")) {
      console.log("addOdai パラメータ不正 : onedroTagString: " + onedroTagString)
      return
    }
    if (!fromUser || fromUser === "") {
      console.log("addOdai パラメータ不正 : fromUser: " + fromUser)
      return
    }
    if (!fromUrl || fromUrl === "") {
      console.log("addOdai パラメータ不正 : tfromUrl: " + fromUrl)
      return
    }

    // 実処理
    return new Promise(async (resolve, reject) => {
      try {

        await addDoc(collection(db, "Odai"), {
          odaiTitle,
          odaiDetail,
          type,
          typeCommision,
          onedroTag,
          onedroTagString,
          fromUid,
          fromUser,
          fromUrl,
          pv: 0,
          intarest: 0,
          teianUserUidList: [fromUid],
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          defFlg: false,
          agreecheckSightShareFlg
        })

        resolve()
      } catch (e) {
        reject(e)
        console.log(e)
      }
    })
  }

  /**
   * 提案を追加するを作成する
   * @param {{id, share/commision, uid,string, url, uid, string, url}} odaiId 
   * @returns 
   */
  addOdaiTeian = ({
    odaiId,
    type,
    toUid,
    toUser,
    toUrl,
    fromUid,
    fromUser,
    fromUrl,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        await addDoc(collection(db, "OdaiTeian"), {
          odaiId,
          type,
          toUid,
          toUser,
          toUrl,
          fromUid,
          fromUser,
          fromUrl,
          requested: false,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        })


        await this.statusUpdateNotify({ uid: fromUid, displayName: fromUser, icon: fromUrl }, "odai", odaiId, `${toUser} からお題に提案がありました`) //通知

        resolve()
      } catch (e) {
        reject(e)
        console.log(e)
      }

    })
  }
  /**
  * 投稿されたお題を取得する
  * @param {number} count 
  * @returns {[odai]}
  */
  getOdaiList = (count) => {
    return new Promise(async (resolve, reject) => {
      const ref = collection(db, "Odai");
      const q = await query(ref, orderBy("updatedAt", "desc"), limit(count));
      this.getOdaiExecuteQuery({ q: q, resolve: resolve, reject: reject, name: "getOdaiByUid" })
    })
  }


  /**
   * uidが発行したお題を全て取得する
   * @param {string} uid 
   * @returns {[odai]}
   */
  getOdaiByUid = (uid) => {
    return new Promise(async (resolve, reject) => {
      const ref = collection(db, "Odai");
      const q = await query(ref, where("fromUid", "==", uid));
      this.getOdaiExecuteQuery({ q: q, resolve: resolve, reject: reject, name: "getOdaiByUid" })
    })
  }

  getOdaiExecuteQuery = async ({ q, resolve, reject, name }) => {
    console.log("=============================")
    console.log("リスト取得====================")
    console.log("実行メソッド名 : [" + name + "]")
    console.log("=============================")

    const querySnapshot = await getDocs(q);
    let resultOdaiList = []
    try {
      if (querySnapshot.size === 0) {
        console.log("データなし")
        resolve([]);
      } else {
        await querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, "Invitation => ", doc.data());
          let res = doc.data();
          res.id = doc.id;
          resultOdaiList.push(res);
        })
        console.log("resultOdaiList")
        console.log(resultOdaiList)
        resolve(resultOdaiList);
      }
    } catch (e) {
      console.log("エラー発生 :[" + name + "]");
      reject(e)
    }
  }

  getOdaiExecuteQueryById = async ({ docRef, resolve, reject, name }) => {
    console.log("=============================")
    console.log("単一レコード取得================")
    console.log("実行メソッド名 : [" + name + "]")
    console.log("=============================")

    const docSnap = await getDoc(docRef);
    try {
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        let res = docSnap.data()
        res.id = docSnap.id;
        resolve(res);

      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        resolve({ status: "データなし" })
      }
    } catch (e) {
      console.log("エラー発生 :[" + name + "]");
      console.log(e);
      reject(e)
    }
  }



  /**
   * お題をdocidで取得
   * @param {*} id お題のdocid
   * @returns {odai} odai 
   */
  getOdaiByid = (id) => {
    return new Promise(async (resolve, reject) => {
      const docRef = doc(db, "Odai", id);
      this.getOdaiExecuteQueryById({ docRef: docRef, resolve: resolve, reject: reject, name: "getOdaiByid" })
    })
  }

  /**
   * お題に関連する提案を、お題のidで外部キーから全て取得する
   * @param {*} id 
   * @returns {[{odaiTeian}]}
   */
  getOdaiTeianById = (id) => {
    return new Promise(async (resolve, reject) => {
      const ref = collection(db, "OdaiTeian");
      const q = await query(ref, where("odaiId", "==", id));
      this.getOdaiExecuteQuery({ q: q, resolve: resolve, reject: reject, name: "getOdaiTeianById" })
    })
  }

  /**
 * お題に関連する提案を、お題のidで外部キーから全て取得する
 * @param {*} id 
 * @returns {[{odaiTeian}]}
 */
  getOdaiTeianByIdForIkkatsuRequest = (id) => {
    return new Promise(async (resolve, reject) => {
      const ref = collection(db, "OdaiTeian");
      const q = await query(ref, where("odaiId", "==", id), where("requested", "==", false), where("type", "==", "share"));
      this.getOdaiExecuteQuery({ q: q, resolve: resolve, reject: reject, name: "getOdaiTeianById" })
    })
  }

  /**
   * pvをインクリメント
   * @param {*} id 
   * @returns 
   */
  updateOdaiPv = (id) => {
    return new Promise(async (resolve, reject) => {
      const docRef = await doc(db, "Odai", id);
      const docSnap = await getDoc(docRef);
      console.log("Document data:", docSnap.data());
      let res = await docSnap.data()
      await updateDoc(docRef, {
        pv: res.pv + 1,
        updatedAt: serverTimestamp(),
      }).then(() => resolve()).catch((e) => {
        console.log("updateOdaiPv　でエラー発生")
        console.log(e)
        reject(e)
      })
    })
  }

  /**
   * 見たいをインクリメント
   * @param {*} id 
   * @returns 
   */
  updateOdaiIntarest = (id) => {
    return new Promise(async (resolve, reject) => {
      const docRef = await doc(db, "Odai", id);
      const docSnap = await getDoc(docRef);
      console.log("Document data:", docSnap.data());
      let res = await docSnap.data()
      await updateDoc(docRef, {
        intarest: res.intarest + 1,
        updatedAt: serverTimestamp(),
      }).then(() => resolve()).catch((e) => {
        console.log("updateOdaiIntarest　でエラー発生")
        console.log(e)
        reject(e)
      })
    })
  }


  /**
   * pvをインクリメント
   * 提案したユーザのすでに登録済みチェックのためOdaiに、提案ユーザのUidを持たせる
   * @param {id} id 
   * @param {id} uid 
   * @returns 
   */
  updateTeanUserList = (id, uid) => {
    return new Promise(async (resolve, reject) => {
      const docRef = await doc(db, "Odai", id);
      const docSnap = await getDoc(docRef);
      console.log("Document data:", docSnap.data());
      let res = await docSnap.data()
      let teianUserUidList = res.teianUserUidList;
      teianUserUidList.push(uid);
      await updateDoc(docRef, {
        teianUserUidList,
        updatedAt: serverTimestamp(),
      }).then(() => resolve()).catch((e) => {
        console.log("updateTeanUserList でエラー発生")
        console.log(e)
        reject(e)
      })
    })
  }

  /**
 * Odaiを無効化する
 * @param {id} id 
 * @param {bool} deleteFlg 削除の場合はTrue、falseで再度有効化
 * @returns 
 */
  updateOdaiDelete = (id, deleteFlg) => {
    return new Promise(async (resolve, reject) => {
      const docRef = await doc(db, "Odai", id);
      const docSnap = await getDoc(docRef);
      console.log("Document data:", docSnap.data());
      let res = await docSnap.data()
      await updateDoc(docRef, {
        defFlg: deleteFlg,
        updatedAt: serverTimestamp(),
      }).then(() => resolve()).catch((e) => {
        console.log("updateOdaiDelete　でエラー発生")
        console.log(e)
        reject(e)
      })
    })
  }

  /**
* OdaiTeianを提案済みにする
* @param {id} id 
* @returns 
*/
  updeteTeianRequested = (id) => {
    return new Promise(async (resolve, reject) => {
      const docRef = await doc(db, "OdaiTeian", id);
      const docSnap = await getDoc(docRef);
      console.log("Document data:", docSnap.data());
      let res = await docSnap.data()
      await updateDoc(docRef, {
        requested: true,
        updatedAt: serverTimestamp(),
      }).then(() => resolve()).catch((e) => {
        console.log("updateOdaiDelete　でエラー発生")
        console.log(e)
        reject(e)
      })
    })
  }


  /**
   * お題に関係したリクエストを行う
   * リクエストを作成する
   * 無料のワンクリックリクエスト
   * @param {{id}} id お題id
  * @param {{}} data 提案データ　これに対してリクエストを発行
   * @returns 
   */
  // 既存のcreateRequsetで行う

  /* ------- タイトル系 ------ */
  // TODO 一旦、アルゴリズムは保留。
  /**
   * 
   * @param {number} quantity 何件取得するか
   * @returns {{UserData}} アカウントのプロフィールを返す
   * 
   * TODO 一旦、納品数降順
   * 本当はProductの注目数の累計とかが良い？ 作品が注目されている人として
   */
  getOnedroMaster = (quantity) => {

    return new Promise(async (resolve, reject) => {
      const ref = collection(db, "UserData");

      const q = await query(ref, orderBy("completetAmount", "desc"), limit(quantity));

      this.titleExecuteQuery(q, resolve, reject)
    })
  }

  /**
  * 
  * @param {number} quantity 何件取得するか
  * @returns {{UserData}} アカウントのプロフィールを返す
  * 
  * アルゴリズム：
  * RTテーブルをgroupByして、レコード数が多いユーザを降順
  * → 集計処理このような正確なアルゴリズムにするのは次ステップ
  * 一旦、登録時にUserDataをカウントアップで
  */
  getRtMaster = (quantity) => {
    return new Promise(async (resolve, reject) => {
      const ref = collection(db, "UserData");
      const q = await query(ref, orderBy("rt", "desc"), limit(quantity));
      this.titleExecuteQuery(q, resolve, reject)
    })
  }
  /**
  * スピードキング
  * @param {number} quantity 何件取得するか
  * @returns {{UserData}} アカウントのプロフィールを返す
  * 
  * UserDataの平均納品日数で降順、期間は1ヶ月
  */
  getSpeedMaster = (quantity) => {
    return new Promise(async (resolve, reject) => {
      const ref = collection(db, "UserData");
      const q = await query(ref, orderBy("completetTerm", "asc"), limit(quantity));
      this.titleExecuteQuery(q, resolve, reject)
    })
  }
  /**
  * 納品キング
  * @param {number} quantity 何件取得するか
  * @returns {{UserData}} アカウントのプロフィールを返す
  * 
  * Product数をtoUidでGroupBYし、レコード数が多いユーザ降順
  * → 集計処理このような正確なアルゴリズムにするのは次ステップ
  * 一旦、登録時にUserDataをカウントアップで
  */
  getNohinMaster = (quantity) => {
    return new Promise(async (resolve, reject) => {
      const ref = collection(db, "UserData");
      const q = await query(ref, orderBy("completetAmount"), limit(quantity));

      this.titleExecuteQuery(q, resolve, reject)
    })
  }
  /**
  * 好評価ユーザー
  * @param {number} quantity 何件取得するか
  * @returns {{UserData}} アカウントのプロフィールを返す
  * UserDataの平均納品日数で降順、期間は1ヶ月
   
  */
  getRatingMaster = (quantity) => {
    return new Promise(async (resolve, reject) => {
      const ref = collection(db, "UserData");
      const q = await query(ref, orderBy("creatorRating", "desc"), limit(quantity));
      this.titleExecuteQuery(q, resolve, reject)
    })
  }
  /**
  * 伝説のリクエスト
  * @param {number} quantity 何件取得するか
  * @returns {{UserData}} アカウントのプロフィールを返す
  * 
  * TODO 一旦、賛同数
  */
  getDensetsu = (quantity) => {
    return new Promise(async (resolve, reject) => {
      const ref = collection(db, "Request");
      const q = await query(ref, orderBy("agree", "desc"), limit(quantity));
      this.titleExecuteQuery(q, resolve, reject)
    })
  }
  /**
  * タグ検索共通処理
  * @param {id} lastId ページング用、productid 入力がある場合はページングが可能
  * @param {number} quantity 何件取得するか
  * @param {String} searchTag タグの文字
  * @returns {[{Product}]} 作品のリストを渡す
  */
  getTagSearchResult = ({ lastId = false, quantity, searchTag, collectionName }) => {

    return new Promise(async (resolve, reject) => {
      const ref = collection(db, collectionName);
      let q = {};
      if (lastId) {
        const docSnap = await getDoc(doc(ref, lastId));
        q = await query(ref, where("tagsString", "array-contains", searchTag), orderBy("createdAt", "desc"), startAfter(docSnap), limit(quantity));

      } else {
        q = await query(ref, where("tagsString", "array-contains", searchTag), orderBy("createdAt", "desc"), limit(quantity));
      }
      this.titleExecuteQuery(q, resolve, reject)
    })
  }


  getOnedroTagSearchResult = ({ lastId = false, quantity, searchTag, collectionName }) => {


    return new Promise(async (resolve, reject) => {
      const ref = collection(db, collectionName);
      let q = {};
      if (lastId) {
        const docSnap = await getDoc(doc(ref, lastId));
        q = await query(ref, where("onedroTagString", "array-contains", searchTag), orderBy("createdAt", "desc"), startAfter(docSnap), limit(quantity));

      } else {
        q = await query(ref, where("onedroTagString", "array-contains", searchTag), orderBy("createdAt", "desc"), limit(quantity));
      }
      this.titleExecuteQuery(q, resolve, reject)
    })
  }

  getOnedroTagRank = ({ quantity, searchTag, collectionName }) => {
    return new Promise(async (resolve, reject) => {
      const ref = collection(db, collectionName);
      let q = {};

      q = await query(ref, where("onedroTagString", "array-contains", searchTag), orderBy("createdAt", "desc"), limit(quantity));

      this.titleExecuteQuery(q, resolve, reject)
    })
  }

  /**
   * タイトル取得系の共通メソッド
   * docrefを取得し、クエリを作成までは個別に行い、クエリ実行以下は共通化する
   * @param {query} query 
   * @param {promice} resolve 
   * @param {promice} reject 
   */
  titleExecuteQuery = async (q, resolve, reject) => {
    try {
      let result = []; //取得結果のリスト
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size == 0) {
        console.log("titleExecuteQuery:検索結果なし")
        resolve(result);
      } else {
        console.log("titleExecuteQuery:検索結果")
        console.log(result)
        this.homeCommmonQuery(querySnapshot, resolve);
      }
    } catch (e) {
      this.homeCommonError(e, reject)
    }
  }


  /**
   * 任意の日数前のServerTimestampを取得する
   * @param {number} lastDays 何日前か
   * @return {Timestamp} タイムスタンプを返却
   */
  homeCommonDateToTimestamp = (lastDays) => {
    let day = new Date();
    day.setDate(day.getDate() - lastDays);
    let timestamp = Timestamp.fromDate(day)
    return timestamp;
  }

  /**
   * エラー返却共通処理
   * @param {*} e 
   * @param {*} reject 
   */
  homeCommonError = (e, reject) => {
    console.log("ホーム用データ取得に失敗");
    console.log(e);
    reject(e)
  }

  /**
  * リスト取得クエリ
  * querySnapshotを実行した結果をリスト化して返すRefactoringメソッド
  * @param {querySnapshot, resolve} querySnapshot 
  * @param {[]} レコードの配列 
  */
  homeCommmonQuery = async (querySnapshot, resolve) => {
    let result = []; //取得結果のリスト
    await querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      let res = doc.data();
      res.id = doc.id;
      result.push(res);
    })
    resolve(result);

  }

  // functionCall ====================


  getTestCall = () => {
    return new Promise(async (resolve) => {
      const functions = getFunctions(app, 'us-central1')
      connectFunctionsEmulator(functions, "localhost", 5001);
      const addMessage = httpsCallable(functions, 'addMessage');

      console.log("proxy引数確認======================")
      addMessage({ test: "test" })
        .then((result) => {
          console.log(result)
          console.log(result.data.message)

        })
        .catch((error) => {
          console.log("checkStripeUserById コール失敗");
          console.log(error);
        });

    })

  }


  /**
   * クリエイター登録をする：Stripe連結アカウントを作成する
   */
  registerCreator = (uid) => {
    return new Promise(async (resolve) => {
      const addMessage = httpsCallable(functions, 'createStripeAccount');
      addMessage()
        .then((result) => {
          let redirectAccountUrl = null;

          //console.log("RESULT=============")
          //console.log(result);
          //console.log("data=============")
          //console.log(result.data.result.result);
          const resultData = result.data.result.result;
          //console.log("FUNCTION からも戻った、Sripe登録のレスポンス")
          //console.log(resultData)
          //console.log(resultData.url);
          //console.log(resultData.id);

          // StripeIdを userDataに保持

          this.getUser(uid).then((userData) => {
            // console.log("Stripe ID " + resultData.id);
            userData.stripeId = resultData.id
            //console.log(" ===userData ===")
            //console.log(userData)
            this.updateUser(userData);
          })

          window.open(resultData.url, '_blank');
          // クリエイター初期登録処理を実施
          return null;

        })
        .catch((error) => {
          console.log("registerCreator コール失敗");
          console.log(error);
        });

    })

  }

  // クリエイター登録が未完了かどうかを確認
  getStripeAccount = (iputId) => {
    return new Promise(async (resolve) => {
      const addMessage = httpsCallable(functions, 'checkStripeUserById');
      addMessage({ stripeId: iputId })
        .then((result) => {
          // Read result of the Cloud Function.
          /** @type {any} */
          //console.log("---ON CALL LOCAL TEST ========")
          const accountData = result.data.result.result;
          console.log("有効化状態")
          if (!accountData) {
            console.log("クリエイター登録未登録");
            resolve(false)
          }
          if (accountData.charges_enabled) {
            resolve(true)
          } else {
            resolve(false)
          }

        })
        .catch((error) => {
          console.log("checkStripeUserById コール失敗");
          console.log(error);
        });

    })

  }


  /**
   * 決裁を行う
   * @param {*} item 
   */
  settlementRequest = async (item) => {
    return new Promise(async (resolve) => {
      // お題を確認
      const odai = await this.getOdaiById(item.odaiId);
      const createrUser = await this.getUser(odai.createrUid);
      item.stripeId = createrUser.stripeId
      item.odai = odai;
      // 決済リンクを作成
      const addMessage = httpsCallable(functions, 'createCheckoutLink');
      await addMessage(item)
        .then(async (result) => {

          /*
          console.log("===========createCheckoutLink END===========")
          console.log(result);
          console.log("===========stripe.checkout.sessions.create===========")
          console.log("=========== id cs_ ===========")
          console.log(result.data.result.result.id)

          console.log("result.data" + result.data);
          console.log("result.data.result.url" + result.data.result.result.url);
          */

          const paymentUrl = result.data.result.result.url;

          // Stripe_eventを特定するためのpaymentIntentを取得
          const paymentIntent = result.data.result.result.payment_intent;
          /*
          console.log("Payment Intent")
          console.log(paymentIntent);
          console.log("ACCOUNT DATA URL")
          console.log(paymentUrl);
          */
          window.open(paymentUrl, '_blank');
          // 購入の完了(Holder)を仮作成。
          // WebHocで決済処理完了で有効化する
          const document = await doc(db, "odai", odai.id);
          console.log("更新ユーザ===========--");
          try {
            // Stripeの口座登録であればStripeIdを設定。nullを避けるため他は空もじ
            await updateDoc(document, {
              // Stripe決済情報
              cs_: result.data.result.result.id
            }).then(() => {
              resolve()
            }).catch((e) => {
              console.log(e)
            })
            const gazoDocument = await doc(db, "gazo", item.id);
            await updateDoc(gazoDocument, {
              // Stripe決済情報
              cs_: result.data.result.result.id
            }).then(() => {
              resolve()
            }).catch((e) => {
              console.log(e)
            })
          } catch (e) {
            console.log(e)
          }

          /*
          await ifProxy.addHolder(data).then((responce) => {
            console.log("addHolder =======");
            console.log(responce);
            navigate('/HoldDetail/' + responce);
            if (responce == "error") {
   
            }
          });
          */
        })
        .catch((error) => {
          console.log("createCheckoutLink コール失敗");
          console.log(error);
        });
    });

  }


  /************************************
   * Analytics ReactGAのイベント発行
   * これを呼び出して発行
   * ifProxy.gaEventTest("TEST_MESSAGE")
   *************************************/
  // Testって書いてあるけどテストではなく本番
  gaEventTest = (eventTag) => {
    try {
      ReactGA.initialize("G-2ZCXE08H2S");
      ReactGA.event(eventTag)

    } catch (e) {
      console.group("GAイベントの発行失敗")
      console.log(e)
      console.groupEnd()
    }
  }
  gaUsetSignupEvent = (userData) => {
    try {
      if (userData.stripeId !== "" && userData.stripeId !== undefined && userData.stripeId !== null) {
        this.gaEventTest("creatoerUser")
      } else {
        this.gaEventTest("User")
      }
      if (userData.premium === 2) {
        this.gaEventTest("primeUser")
      }

    } catch (e) {
      console.group("GAイベントの発行失敗")
      console.log(e)
      console.groupEnd()
    }

  }

}


export default IfProxy;
