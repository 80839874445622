import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';


// グラフコンポーネント
import { VictoryStack, VictoryArea, VictoryChart, VictoryBar, VictoryTheme } from 'victory';



/**
 * @property { record[] }
 * @returns 
 * 
 */
const TimeStackGraph = ({ recordListData }) => {

    const navigate = useNavigate();
    const classes = useStyles();
    console.log("インプットデータ")
    console.log(recordListData)

    // 各回の、ステップ所要時間とラベル名の配列になる
    // ループ、5回分それぞれで次を実施

    const data = recordListData.map((item, index) => {
        // latestの５回目までで、終了
        if (index >= 5) return;


        /**
* ミリ秒の差を返す。グラフ等での処理に利用
* @param {timestamp} 現在時刻
* @param {timestamp} 比較対象時刻
* @return {number} 差のミリ秒
*/
        const getDiffTime = (genzai, kaishi) => {
            let gendaiTodate;
            let kaishiToDate;

            // タイムスタンプ型でないデータが入っていないか確認。
            console.log("genzai")
            console.log(genzai)
            if (!genzai) return 0

            gendaiTodate = genzai.toDate();
            kaishiToDate = kaishi.toDate();


            const gendaiTime = gendaiTodate.getTime();
            const kaishiTime = kaishiToDate.getTime();


            // TODO 一旦、ミリ秒にしてる
            //ミリ秒で差が出るため、1000で割って秒にして、60で割って分にする
            const time = (gendaiTime - kaishiTime) / 100// Math.floor((gendaiTime - kaishiTime) / (1000 * 60));

            return time
        }

        // 10ステップ分のタイムを設定
        // 1回目から10回目までのアル分は、toDateして、 getDiffTime で前後比較で時間を算出
        // 前があるけど次がない（最後の１つ次)の工程は getDiffTime を迂回して0を入れる stepXName はハイフンとなる
        console.log(item)

        // 実施日(X軸)を作る
        console.log("実施日")
        console.log(item.completeTime.toDate())
        const jissibiDate = item.completeTime.toDate();
        const month = jissibiDate.getMonth() + 1;
        const date = jissibiDate.getDate();
        const jissibiFormat = `${month}/${date}`

        // 1回分ごとに次のフォーマットで整形 10まであるが、やってないところは0になる
        //{step1:time,step1Name, step2:time,step2Name}


        return {
            time1: getDiffTime(item.step1Time, item.step0Time),
            step1Name: item.step1 || "",
            time2: getDiffTime(item.step2Time, item.step1Time),
            step2Name: item.step2 || "",
            time3: getDiffTime(item.step3Time, item.step2Time),
            step3Name: item.step3 || "",
            time4: getDiffTime(item.step4Time, item.step3Time),
            step4Name: item.step4 || "",
            time5: getDiffTime(item.step5Time, item.step4Time),
            step5Name: item.step5 || "",
            time6: getDiffTime(item.step6Time, item.step5Time),
            step6Name: item.step6 || "",
            time7: getDiffTime(item.step7Time, item.step6Time),
            step7Name: item.step7 || "",
            time8: getDiffTime(item.step8Time, item.step7Time),
            step8Name: item.step8 || "",
            time9: getDiffTime(item.step9Time, item.step8Time),
            step9Name: item.step9 || "",
            time10: getDiffTime(item.step10Time, item.step9Time),
            step10Name: item.step10 || "",
            x: jissibiFormat// 実施日。レコードごとになる
        }
    })

    // data から、ステップごとの [{x,y,label}] 配列を作る
    //{step1:time,step1Name, step2:time,step2Name, x:xx/zz}
    let step1DataList = [];
    let step2DataList = [];
    let step3DataList = [];
    let step4DataList = [];
    let step5DataList = [];
    let step6DataList = [];
    let step7DataList = [];
    let step8DataList = [];
    let step9DataList = [];
    let step10DataList = [];
    data.map((item, index) => {


        // 2週目、3週目、で、実施日xが１つずつ変わっていき、それぞれのステップが作られる
        step1DataList.push({ x: item.x, y: item.time1, label: item.step1Name })
        step2DataList.push({ x: item.x, y: item.time2, label: item.step2Name })
        step3DataList.push({ x: item.x, y: item.time3, label: item.step3Name })
        step4DataList.push({ x: item.x, y: item.time4, label: item.step4Name })
        step5DataList.push({ x: item.x, y: item.time5, label: item.step5Name })
        step6DataList.push({ x: item.x, y: item.time6, label: item.step6Name })
        step7DataList.push({ x: item.x, y: item.time7, label: item.step7Name })
        step8DataList.push({ x: item.x, y: item.time8, label: item.step8Name })
        step9DataList.push({ x: item.x, y: item.time9, label: item.step9Name })
        step10DataList.push({ x: item.x, y: item.time10, label: item.step10Name })
    })

    // createVictoryArea で、コンポーネントの配列にする
    // stepXDataList [x,y,label]

    // コンポーネントの配列を画面に表示する




    /**
     * VictoryArea １系列データ
     * 系列データは、xとその時の値を持つ
     * VictoryArea を縦に、Xを合わせて積み重ねる
     */

    /**
     * 
     * @param {[{}]} data [{x,y}]
     * @param {String} label 
     * @param {String} color 
     * @returns {<VictoryArea>}
     */
    const createVictoryArea = (data, label, color) => {
        console.log("確認~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~" + label)
        console.log(data)

        return (
            <VictoryArea
                labels={[label]}
                style={{ data: { fill: color } }}
                data={[{ x: data[0].x || "-", y: data[0]?.y }, { x: data[1].x || "-", y: data[1]?.y }, { x: data[2]?.x || "-", y: data[2]?.y }, { x: data[3]?.x || "-", y: data[3]?.y }, { x: data[4]?.x || "-", y: data[4]?.y }, { x: data[5]?.x || "-", y: data[5]?.y, label: label }]}
            />
        )
    }

    if (step1DataList.length < 1) return "日記データなし"

    return (
        <>
            {/**
            <Box className={classes.leftCenterBox}>
            </Box>
    */}
            <VictoryStack

                animate={{
                    duration: 2000,
                    onLoad: { duration: 1000 }
                }}
            >

                {createVictoryArea(step1DataList, step1DataList[0].label, "lightblue")}
                {createVictoryArea(step2DataList, step2DataList[0].label, "skyblue")}
                {createVictoryArea(step3DataList, step3DataList[0].label, "lightskyblue")}
                {createVictoryArea(step4DataList, step4DataList[0].label, "cornflowerblue")}
                {createVictoryArea(step5DataList, step5DataList[0].label, "dodgerblue")}
                {createVictoryArea(step6DataList, step6DataList[0].label, "blue")}
                {createVictoryArea(step7DataList, step7DataList[0].label, "mediumblue")}
                {createVictoryArea(step8DataList, step8DataList[0].label, "darkblue")}
                {createVictoryArea(step9DataList, step9DataList[0].label, "navy")}
                {createVictoryArea(step10DataList, step10DataList[0].label, "midnightblue")}

            </VictoryStack>
        </>
    )
}

export default TimeStackGraph;


const useStyles = makeStyles((theme) => ({

    card: {
        maxWidth: "300px",
    }, leftCenterBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        height: 100,
    },



}));
