import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import IfProxy from '../../IfProxy';
import HeadFooter from '../HeadFooter';
import UploadImage from '../../common/UploadImage';
import TagInput from "../../common/TagInput";
import AccountCard from '../../common/AccountCard';
import AgreecheckSightShare from "../../common/AgreecheckSightShare";

import IfProxyStripe from '../../IfProxyStripe';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';
import { Dna } from 'react-loader-spinner'


import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

const StraitLineSignup = (props) => {



  const [docId, setDocId] = useState(0); // userDataのdocId
  const [stripeId, setStripeId] = useState(""); // userDataのdocId


  // サンプルデータ系
  const [image, setImage] = useState("");
  const [blobDataResult, setBlobDataResult] = useState(0);
  const [nsfw, setNsfw] = useState(true);
  const [onedroTagsProd, setOnedroTagsProd] = useState(""); // productに設定したタグ
  const [tagsProd, setTagsProd] = useState("");// productに設定したタグ
  const [onedroTagsSagProd, setOnedroTagsSagProd] = useState(""); // productに設定したタグ
  const [tagsSagProd, setTagsSagProd] = useState("");// productに設定したタグ


  const [newUser, setNewUser] = useState([]);


  const [kikakuTags, setKikakuTags] = useState([]); //プロフィールに設定するタグ
  const [kikakuTagsSag, setKikakuTagsSag] = useState([]); //プロフィールに設定するタグ


  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();

  const location = useLocation();

  const [loding, setLoding] = useState(false);


  const [lodedUserdata, setLoadedUserdata] = useState({});

  const [icon, setIcon] = useState(""); // url
  const [displayName, setDisplayName] = useState("");
  const [profile, setProfile] = useState("");
  const [genre, setGenre] = useState("");
  const [onedro, setOnedro] = useState("");
  const [status, setStatus] = useState(true);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [followCheck1, setFollowCheck1] = useState(false);
  const [followCheck2, setFollowCheck2] = useState(false);
  const [followCheck3, setFollowCheck3] = useState(false);
  const [followCheck4, setFollowCheck4] = useState(false);
  const [followCheck5, setFollowCheck5] = useState(false);

  const [agreecheckSightShareFlg, setAgreecheckSightShareFlg] = useState(1);


  const [passwordAuthCheckMassage, setPasswordAuthCheckMassage] = useState("");
  const [passwordAuthClearFlg, setPasswordAuthClearFlg] = useState(true);

  // 状態管理
  const [sampleComplete, setSampleComplete] = useState(false);


  const ifProxy = new IfProxy();
  const ifProxyStripe = new IfProxyStripe();


  useEffect((props) => {
    loadData(props);
    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    // ------
  }, []);

  const handleLogout = () => {
    auth.signOut();
    navigate('/login');
  };

  const loadData = (props) => {
    console.log("画面遷移後＝ params ＝＝＝＝＝＝＝＝＝＝")
    //console.log(params);
    //console.log(location);


    ifProxy.getUserData(user.uid).then((result) => {
      console.log("============-")
      console.log(user.uid)
      console.log(result)
      setLoadedUserdata(result);
      //console.log(result);
      setDocId(result.id);
      setStripeId(result.stripeId);

      setProfile(result.profile || "")
      setGenre(result.genre || "・得意ジャンル: ・好きなキャラ: ・特に募集しているリクエスト:")
      setOnedro(result.onedro || "")
      setStatus((result.status == undefined) ? true : result.status)
      setIcon(() => result.icon !== undefined ? result.icon : user.reloadUserInfo.photoUrl);
      setDisplayName(() => (result.displayName !== undefined) ? result.displayName : user.reloadUserInfo.displayName); // Auth値固定（twitter)　任意の入力があればその値を優先
    })

    // ユーザ登録用のタグ推奨
    ifProxy.getTag("ワンドロ企画", 30).then((result) => {
      setKikakuTagsSag(() => result)
      //console.log(result)
    })

    // フォロー推奨ユーザ
    // <<この数を変更する場合は、　setFollowCheck1　周りの制御も合わせて変更が必要>>
    ifProxy.getNewUser(5).then((result) => {
      setNewUser(result)
    })
  }


  /*
   * @param {Blob} blobData 
  */
  const returnBlob = ({ blobData, localUrl }) => {
    // 親画面の画像データを実際に取り扱うstateに設定
    setBlobDataResult(blobData);
    setImage(localUrl)
  }
  // サンプルプロダクトに設定するタグ系
  const returnTagsProd = (tag) => {
    // 作品名のタグ
    setTagsProd(tag)
  }
  const returnKikakuTagsProd = (tag) => {
    // 企画名のタグ
    setOnedroTagsSagProd(tag)
  }


  // ユーザ登録の企画タグ
  const returnKikakuTags = (tag) => {
    // 企画名のタグ
    setKikakuTags(tag)
  }



  /**
   * 登録後のシェア
   */
  const shareProfile = () => {
    let result = window.confirm("クリエイター登録が完了しました。SNSでリクエスト募集をみんなに知らせましょう")
    if (result) {
      const twitterShareUrl = "http://twitter.com/share?url=" + window.location.origin + '/User/' + user.uid + "&text=【ワンドロ応援SNS ワンフロ】でリクエスト募集中!&hashtags=ワンフロ"
      window.open(twitterShareUrl, '_blank');
    }
    navigate('/')
  }
  /**
  * サインアップ
  */
  const update = () => {

    let targetUsredata = lodedUserdata;
    // データの更新
    const onedroTagString = kikakuTags.map((data, index) => {
      return data.text
    })
    targetUsredata.onedroTagString = onedroTagString;
    targetUsredata.kikakuTags = kikakuTags;
    targetUsredata.onedro = onedroTagString.join(', ')
    targetUsredata.remitedCreator = true




    // onedroTagString : 検索用企画タグ配列
    // kikakuTags : 企画タグオブジェクト配列
    // ondro: 旧項目、文字列
    ifProxy.updateUserData(targetUsredata).then((result) => {
      // 登録処理完了後
      shareProfile();
    });


  }


  /**
   * Stripe口座開設
   */
  const goStripe = () => {
    setLoding(true);
    ifProxyStripe.registerCreator(user.uid).then((result) => {
      setStripeId(result.userData.stripeId)
      setLoding(false);
    })
  }

  // サンプルのinsert
  const insertSample = () => {
    ifProxy.createPortforio({
      blobDataResult,
      onedroTags: kikakuTags,
      tags: [],
      toUid: user.uid,
      toName: displayName,
      toIcon: user.photoURL,
      nsfw
    }).then((result) => {
      setSampleComplete(true)
    }).catch((e) => {
      console.log("StraitLineSignupでのポートフォリオ登録")
      console.log(e)
    })
  }

  // フォロー関係のinsert
  const addFollow = (toUser) => {
    // @param {{uid, displayName,photoURL}} fromUser するる人のUserオブジェクト
    // @param {{uid, displayName,icon}} toUser する人のUserDataオブジェクト
    ifProxy.addFollow(
      {
        uid: user.uid,
        displayName: user.displayName,
        photoURL: icon
      },
      {
        uid: toUser.uid,
        displayName: toUser.displayName,
        icon: toUser.icon
      })
  }
  // 一括フォロー
  const addFollowList = () => {
    setFollowCheck1(pre => true)
    setFollowCheck2(pre => true)
    setFollowCheck3(pre => true)
    setFollowCheck4(pre => true)
    setFollowCheck5(pre => true)

    newUser.map((toUser) => {
      addFollow(toUser);
    })
  }


  const handleGenre = (event) => {
    setGenre(event.target.value)
  }
  const handleOnedro = (event) => {
    setOnedro(event.target.value)
  }

  const suggestionkikakuTagListView = () => {
    const listItem = kikakuTagsSag.map((value) => {
      console.log(value.id)
      return (
        <li>{value.id}</li>
      )
    })
    //kikakuTagsSag
    return (
      <>
        <ul>
          {listItem}
        </ul>
      </>
    )
  }

  const followingCheck = (count) => {
    let result = false;
    switch (count) {
      case 1:
        result = followCheck1
        break;
      case 2:
        result = followCheck2
        break;
      case 3:
        result = followCheck3
        break;
      case 4:
        result = followCheck4
        break;
      case 5:
        result = followCheck5
        break;
      default:
        break;
    }
    return result;
  }
  const following = (count) => {
    switch (count) {
      case 1:
        setFollowCheck1(pre => true)
        break;
      case 2:
        setFollowCheck2(pre => true)
        break;
      case 3:
        setFollowCheck3(pre => true)
        break;
      case 4:
        setFollowCheck4(pre => true)
        break;
      case 5:
        setFollowCheck5(pre => true)
        break;
      default:
        break;
    }
  }

  /**
 * アカウントカードの作成
 * @returns 
 */
  const renderingAccountList = () => {
    let conter = 0;


    const listData = newUser; //TODO
    const cardList = listData.map((item, index) => {
      conter++;
      // 何番目のフォロワーかチェック
      //setFollowCheck1
      item.count = conter;
      return (
        <>
          <Card className={classes.card} >
            <CardContent className={classes.cardContent} >
              <Avatar sx={{ width: 100, height: 100 }} src={item.icon} ></Avatar>
              {item.count}
              <Box className={classes.cardContentColumn} >
                <Typography color="text.primary" gutterBottom>
                  {item.displayName}
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {item.profile}
                </Typography>


                {item.status ?
                  <Typography color="text.primary" gutterBottom>募集受付中</Typography>
                  :
                  <Typography color="text.secondary" gutterBottom>募集停止中</Typography>
                }
                <Button
                  variant="contained"
                  color="primary"
                  disabled={followingCheck(conter)}
                  onClick={() => {
                    addFollow(item);
                    following(item.count)
                  }}
                  sx={{ margin: 2, borderRadius: 16 }}>
                  フォローする
                </Button>
              </Box>


            </CardContent>
          </Card >
        </>
      )
    })
    return cardList;
  }


  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  }
  if (loding) {
    return (
      <Box className={classes.contentWrapper}>
        <Box className={classes.mainBox}>
          <h4>別画面が開きます。画面は閉じずにお待ちください</h4>
          <h4>Stripe社口座開設が終わりましたら、この画面で残りの登録を進めてください</h4>

          <Dna
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </Box>
      </Box>

    );
  } else {

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.contentArea}>
            <Typography color="text.primary" gutterBottom>
              <h3>かんたんクリエイター登録</h3>
            </Typography>

            {/*
          {stripeId === "" && <p>口座登録が未完了です</p>}
          <Box className={classes.centerBox}>
            <Button
              variant="contained"
              fullWidth
              disabled={(stripeId !== "")}
              onClick={() => goStripe()}
              color="primary"
              sx={{ margin: 2 }}>
              Stripe社口座開設
            </Button>
            <p>リクエストを受け付けて報酬受け取りのためにはStripe社口座開設が必要です</p>
            <p>【無料リクエスト限定クリエイター】として登録する場合は、口座開設せず先へお進みください</p>

          </Box>
    */}

            <TextField fullWidth id="outlined-basic" label="得意ジャンル"
              multiline
              maxRows={10}
              rows={4}
              variant="filled"
              onChange={handleGenre} value={genre} sx={styleDifinition.aInputForm} />
            <h3>最近利用された企画名</h3>
            {suggestionkikakuTagListView()}
            <p>あなたがよく参加する企画を登録してください。利用者が企画名からあなたを見つけることができます</p>
            <TagInput type="formKikaku" resultTags={(setKikakuTags) => returnKikakuTags(setKikakuTags)} suggestion={kikakuTagsSag}></TagInput>

            <h3>サンプルの登録</h3>
            <p>まず１件だけ登録を行なってください。<br></br>
              ユーザ登録が完了しましたら、プロフィール画面から他にもサンプルを追加可能です</p>
            <Box className={classes.sampleBox}>
              <UploadImage returnBlob={(blob) => returnBlob(blob)}></UploadImage>
              <h4>サンプルの作品タグ</h4>
              <Box className={classes.centerBox}>
                <TagInput type="form" resultTags={(tags) => returnTagsProd(tags)} ></TagInput>
              </Box>
              <Box className={classes.centerBox}>
                <TagInput type="formKikaku" resultTags={(tags) => returnKikakuTagsProd(tags)} ></TagInput>
              </Box>
              <Box className={classes.leftBox} >
                <h4>サンプルのNSFW区分 :  </h4>
                <input
                  type="checkbox"
                  id="check"
                  checked={nsfw}
                  onChange={() => setNsfw(prevState => !prevState)}
                />
                <h4>
                  {nsfw ? "ON" : "OFF"}
                </h4>

              </Box>

              <AgreecheckSightShare paramStatus={agreecheckSightShareFlg} setResult={(result) => setAgreecheckSightShareFlg(result)}></AgreecheckSightShare>


              <Button
                variant="contained"
                disabled={(image === "" || sampleComplete)}
                onClick={() => insertSample()}
                color="primary"
                sx={{ margin: 2 }}>
                サンプルを登録
              </Button>
              {sampleComplete && <p>サンプル登録が正常に完了</p>}
            </Box>

            <Box className={classes.centerBox} >
              <p>ワンドロを伴走する仲間と知り合いましょう<br></br>
                または、将来リクエストしてくれるかもしれないファンと繋がりましょう
              </p>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => addFollowList()}
                color="primary"
              >
                一括フォロー
              </Button>
              {renderingAccountList()}
            </Box>



            <Button
              variant="contained"
              fullWidth
              onClick={() => update()}
              color="primary"
              sx={{ margin: 2 }}>
              登録
            </Button>

          </Box>
        </Box>
      </HeadFooter >
    );
  }
}
export default StraitLineSignup;

const styleDifinition = {
  aInputForm: {
    margin: "10px",
  },
}
const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentArea: {
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 700,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sampleBox: {
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    border: "medium solid skyblue"

  },
  centerBox: {
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 400
  },
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
    maxWidth: 300
  },
  inputFileBtnHide: {
    opacity: 0,
    appearance: "none",
    position: "absolute"
  },
  aInputForm: {
    margin: 2,
  },
  mainBox: {

  },
  cardList: {
  },
  card: {
    margin: 3
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 400,
  },
  cardContentColumn: {
    flexDirection: 'column',
    alignItems: 'left',
    maxWidth: 400
  },
  bottomCard: {
    width: 250
  },
}));
