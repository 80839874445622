import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';

// TODO: StandardPageはパスが異なるため注意
import IfProxy from '../../IfProxy';
import HeadFooter from '../HeadFooter';

import TagInput from "../../common/TagInput";

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const Odai = (props) => {


    const [loading, setLoading] = useState(true);
    const [onedroTagsSag, setOnedroTagsSag] = useState(""); // productに設定したタグ
    const [onedroTags, setOnedroTags] = useState(""); // productに設定したタグ
    const [odaiList, setOdaiList] = useState(""); // productに設定したタグ

    const [userData, setUserData] = useState(""); // productに設定したタグ


    //参照画面モーダル
    const [modalShow, setModalShow] = useState(false);
    const [modalShowData, setModalShowData] = useState("");
    const [eventType, setEventType] = useState("showOdai");



    //表示用
    const auth = getAuth();
    const navigate = useNavigate();

    const { user } = useAuthContext();
    const classes = useStyles();
    const params = useParams();
    const ifProxy = new IfProxy();

    const location = useLocation();

    useEffect((props) => {
        setLoading(true)
        // ReactGA Analytics ---
        ReactGA.initialize("G-2ZCXE08H2S");
        ReactGA.send({
            hitType: "pageview",
            // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
            page: location.pathname + location.search,
        });
        // ------

        loadData(props);
    }, []);


    const loadData = (props) => {

        // お題を取得する
        ifProxy.getOdaiList(10).then((result) => {
            // 閲覧されたお題のpvをカウントアップ
            result.map(async (odaiData) => {
                await ifProxy.updateOdaiPv(odaiData.id);
            })
            setOdaiList(result)
            setLoading(false)
        })

        // ログインユーザのuserData
        ifProxy.getUserData(user.uid).then((result) => {
            setUserData(result)
        })

    }

    // お題の登録画面を表示する
    const createOdai = () => {
        navigate('/CreateOdai')
    }

    // 無料のワンクリックリクエスト
    const addTeianFree = async (data) => {
        setLoading(true)
        const odaiData = {
            odaiId: data.id,
            type: "share",
            toUid: userData.uid,
            toUser: userData.displayName,
            toUrl: userData.icon,
            fromUid: data.fromUid,
            fromUser: data.fromUser,
            fromUrl: data.fromUrl,
        };
        await ifProxy.addOdaiTeian(odaiData);
        await ifProxy.updateTeanUserList(data.id, userData.uid);
        setLoading(false)
        window.location.reload()


    }
    const addTeian = async (data) => {
        setLoading(true)
        const odaiData = {
            odaiId: data.id,
            type: "commision",
            toUid: userData.uid,
            toUser: userData.displayName,
            toUrl: userData.icon,
            fromUid: data.fromUid,
            fromUser: data.fromUser,
            fromUrl: data.fromUrl,
        };
        await ifProxy.addOdaiTeian(odaiData);
        await ifProxy.updateTeanUserList(data.id, userData.uid);
        setLoading(false)
        window.location.reload()

    }

    // TODO　提案に対するリクエスト
    // リクエストを作成するモーダル（通常のリクエストの場合。あとは画面なしで自動登録）
    // 最小限の項目でリクエストする（金額だけになるはず）
    // ifProxy.createRequest() に必要値を設定して登録
    // Productに引き継ぎ
    // Product参照画面にてお題へのリンク


    // 共感を登録する
    const addInterest = () => {
        // id
        ifProxy.updateOdaiIntarest()
    }

    // PVを追加する
    const addPv = () => {

    }

    const deleteOdai = () => {
        //id
        ifProxy.updateOdaiDelete()
    }

    // 戻る
    const abort = async () => {
        navigate(-1)
    }


    const returnKikakuTags = (tag) => {
        // 企画名のタグ
        setOnedroTags(tag)
    }


    // お題の内容を表示するモーダルの内容
    const odaiViewContent = () => {
        return (

            <>
                <Box className={classes.centerBox}>
                    <Typography sx={{ fontSize: 20 }}>
                        {modalShowData.odaiTitle}
                    </Typography>
                </Box>
                <Box className={classes.leftBox}>
                    <Box className={classes.leftCenterBox}>
                        <Typography sx={{ fontSize: 14 }}>
                            <b>お題の内容</b>
                        </Typography>

                        <Typography sx={{ fontSize: 14 }}>
                            {modalShowData.odaiDetail}
                        </Typography>
                    </Box>
                </Box>

                <Box className={classes.leftBox}>
                    <Box className={classes.leftCenterBox}>
                        <Typography sx={{ fontSize: 14 }}>
                            <b>対象の企画</b>
                        </Typography>

                        <Typography sx={{ fontSize: 14 }}>
                            {modalShowData.type === "kikaku" && "対象企画あり：下記のワンドロ企画に参加する人へ"}
                            {modalShowData.type === "free" && "想定する企画なし：企画への参加は自由"}

                        </Typography>
                    </Box>
                </Box>

                <Box className={classes.leftBox}>
                    <Box className={classes.leftCenterBox}>
                        <Typography sx={{ fontSize: 14 }}>
                            <b>お気軽アイデア提供 or ガチ リクエスト</b>
                        </Typography>

                        <Typography sx={{ fontSize: 14 }}>
                            {modalShowData.typeCommision === 0 && "お気軽アイデア提供です。無償の範囲でご提案ください"}
                            {modalShowData.typeCommision === 1 && "ガチリクエストです。ご提案いただいた方から、有償でリクエストをします"}

                        </Typography>
                    </Box>
                </Box>

                <Box className={classes.leftBox}>
                    <Box className={classes.leftCenterBox}>
                        <Typography sx={{ fontSize: 20 }}>
                            <b>閲覧数 : {modalShowData.pv}</b>
                        </Typography>
                        <Typography sx={{ fontSize: 20 }}>
                            <b>私も見たい！(共感数) : {modalShowData.intarest}</b>
                        </Typography>
                    </Box>
                </Box>

                <Box className={classes.centerFlatBox} >

                    <Box className={classes.modalFormItem}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setModalShow(pre => !pre)
                            }}>閉じる</Button>
                    </Box>
                </Box>

            </>
        )
    }

    // お題のを登録するモーダルの内容
    const odaiViewCreate = () => {
        return (

            <>
                <Box className={classes.centerFlatBox} >

                    <Box className={classes.modalFormItem}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setModalShow(pre => !pre)
                            }}>閉じる</Button>
                    </Box>
                </Box>

            </>
        )
    }

    // 
    const modalView = () => {
        if (modalShow) {
            return (
                <>
                    <Box className={classes.overlay}
                        onClick={() => setModalShow(false)}>
                        <Box className={classes.modalView} onClick={(e) => e.stopPropagation()}>

                            {eventType === "showOdai" && odaiViewContent()}
                            {eventType === "createOdai" && odaiViewCreate()}

                        </Box>

                    </Box >
                </>
            )
        }

    }



    /**
     * お題リストを作成
     * @returns コンポーネント
     */
    const odaiListView = () => {

        // ocaiカードを作成
        const odaiListCard = odaiList.map(({
            id,
            odaiTitle,
            odaiDetail,
            type,
            typeCommision,
            onedroTag,
            onedroTagString,
            fromUid,
            fromUser,
            fromUrl,
            pv,
            intarest,
            createdAt,
            updatedAt,
            defFlg,
        }, index, data) => {

            console.log("分割代入前のdata ::")
            console.log(data[index])
            return (
                <>
                    <Box className={classes.cardLim}
                        onClick={() => {
                            setModalShowData(data[index])
                            setEventType("showOdai")
                            setModalShow(true)
                        }}>
                        <Box className={classes.centerBox}>
                            {/* 縦配置ボックス */}
                            <Typography sx={{ fontSize: 30, color: '#4169e1', fontWeight: 'bold' }}>{odaiTitle}</Typography>

                        </Box>
                        <Box className={classes.leftRowBox}>
                            <Box className={classes.centerBox}>
                                {/* 縦配置ボックス */}
                                <Box> 対象ワンドロ企画</Box>
                                <Box> {type === "free" ? "フリー" : "#" + onedroTagString}</Box>

                            </Box>
                        </Box>
                        <Box className={classes.leftRowBox}>
                            {/* 横配置ボックス */}

                            <Box className={classes.centerBox}>
                                {/* 縦配置ボックス */}
                                <Box> 有償/無償</Box>
                                <Box> {typeCommision === 1 && "有償でリクエストをします"}</Box>
                                <Box> {typeCommision === 0 && "無料の範囲で"}</Box>

                            </Box>
                            <Box className={classes.centerBox}>
                                {/* 縦配置ボックス */}
                                <Box> 閲覧数：{pv}</Box>
                                <Box>共感数：{intarest}</Box>
                            </Box>
                            <Box className={classes.centerBox} onClick={(e) => e.stopPropagation()} >
                                {/* 縦配置ボックス */}
                                < Button variant="outlined" onClick={() => ifProxy.updateOdaiIntarest(id)}>見たい！</Button>
                            </Box>

                            <Box className={classes.centerBox} onClick={(e) => e.stopPropagation()} >
                                {/* 縦配置ボックス */}
                                {data[index].teianUserUidList.includes(userData.uid) && "あなたのリクエストまたは提案済みのリクエストです"}
                                <Button
                                    disabled={(userData.stripeId === "" && !userData.remitedCreator) || data[index].teianUserUidList.includes(userData.uid)}
                                    onClick={() => {
                                        addTeianFree(data[index]);
                                    }}
                                    variant="contained">無料で受ける(報酬はシェアに協力でよい)
                                </Button>
                                <Button
                                    disabled={userData.stripeId === "" || data[index].teianUserUidList.includes(userData.uid)}
                                    onClick={() => {
                                        addTeian(data[index])
                                    }}
                                    variant="contained">有償ならぜひやりたい
                                </Button>
                                {!(userData.stripeId != "" || userData.remitedCreator) && "(クリエイター限定機能)"}

                            </Box>

                        </Box>
                        {/* カード下部 */}
                        <Box className={classes.centerBox} onClick={(e) => e.stopPropagation()} >
                            {/* 縦配置ボックス */}

                            <Button
                                onClick={() => navigate('/OdaiTeianList/' + id)}
                                variant="outlined">
                                投稿された提案を見る
                            </Button>


                        </Box>


                    </Box >

                </>
            )
        })

        return (<>
            {odaiListCard/*お題カード*/}
        </>
        )
    }

    // View
    // お題詳細閲覧ダイアログ
    // その中の投稿されたお題のProduct一覧


    // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
    if (!user) {
        return <Navigate to="/login" />;
    } else if (loading) {
        return (
            <HeadFooter>
                <Box className={classes.contentWrapper}>
                    <Box className={classes.mainBox}>
                        <p>読み込み中...</p>
                    </Box>
                </Box>
            </HeadFooter>
        )
    } else {

        // Boxレイアウトのレスポンシブ対応
        // https://mui.com/components/grid/#responsive-values
        return (
            <HeadFooter>
                {/* オーバーレイ用の背景要素 */}
                {modalView()}
                <Box className={classes.contentWrapper}>
                    <Box className={classes.mainBox} >
                        {/**お題一覧を表示する */}
                        {odaiList && odaiListView()}

                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => createOdai()}
                            color="primary"
                            sx={{ margin: 2 }}>
                            お題を追加する
                        </Button>
                    </Box>
                </Box>

            </HeadFooter >

        );
    }




}
export default Odai;

const useStyles = makeStyles((theme) => ({
    //オーバーレイ
    overlay: {
        zIndex: 10,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },//オーバーレイの中のモーダル
    modalView: {
        zIndex: 10,
        borderRadius: 15,
        width: '50%',
        padding: '1em',
        background: '#fff',
    },
    // 全体レイアウト
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: '#f5f5f5'

    },
    // １つのカード
    cardLim: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        margin: 15,
        padding: 5,
        backgroundColor: 'white',
        border: '2px solid',
        borderColor: '#a6d4fa',
        borderRadius: 10


    },
    mainBox: {
        maxWidth: "1000px",
        margin: "0 auto",
    },// 個別コンテンツ　中央揃えで横に内容を並べる
    centerFlatBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftCenterBox: {
        marginTop: 15,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftRowBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'left',
    },
    centerBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    leftBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: "0 auto",
    },
    cardList: {
    },
    card: {
    },
}));

// Odai参照モーダルビュー
const OdaiView = ({ item }) => {
    const classes = useStyles();

    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    return (
        <Box
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={
                {
                    transition: 'all 0.3s',
                    marginTop: 2,
                    padding: 10,
                    maxWidth: 500,
                    backgroundColor: isHovered ? '#7ea0f8' : '#bbdefb',
                    borderRadius: isHovered ? 0 : 20
                }
            }
        >
            {item.name}
        </Box>
    );

}
