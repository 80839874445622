import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, getDoc, query, deleteDoc, updateDoc, where, addDoc, serverTimestamp, startAfter, limit, orderBy, startAt, endAt, Timestamp } from 'firebase/firestore';
import { getStorage, deleteObject, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, TwitterAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult, updateProfile, EmailAuthProvider, linkWithCredential, } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import Resizer from "react-image-file-resizer";



// アプリ全体のFirebase設定 起動直後最初に一回読み込まれる
// APIキーを git管理から外したファイルに設置。これはpushしない
import apiKey from './apikey.json';

// APIキー等の設定情報を取得 起動直後最初に一回読み込まれる
const firebaseConfig = apiKey.firebase;

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();

// objectの項目一覧。引数バリデーション
// エンティティのスキーマを変える時はここもかえないとバリデーションルールが古いままになる
const diaryParam = [
  "toUid",
  "toUser",
  "toIcon",
  "privateView",
  "diary",
  "memoForNext",
  "imageUrl",
  "videoUrl",
  "youtubeUrl",
  "tag",
  "tagString",
  "onedroTag",
  "onedroTagString",
  "record",
];

const recordParam = [
  "step0Time",
  "step1",
  "step1Time",
  "step1FixCount",
  "step2",
  "step2Time",
  "step2FixCount",
  "step3",
  "step3Time",
  "step3FixCount",
  "step4",
  "step4Time",
  "step4FixCount",
  "step5",
  "step5Time",
  "step5FixCount",
  "step6",
  "step6Time",
  "step6FixCount",
  "step7",
  "step7Time",
  "step7FixCount",
  "step8",
  "step8Time",
  "step8FixCount",
  "step9",
  "step9Time",
  "step9FixCount",
  "step10",
  "step10Time",
  "step10FixCount",
  "completeTime",
  "uid"
]

const recordTrnParam = [
  "uid",
  "step1Name",
  "step2Name",
  "step3Name",
  "step4Name",
  "step5Name",
  "step6Name",
  "step7Name",
  "step8Name",
  "step9Name",
  "step10Name",
  "memoForNext",
  "memo",
]


class IfProxyTool {


  // 全日記リストを取得する
  /**
   * 
   * @returns なしの場合はからの配列になる
   */
  getFullDiaryList = (count) => {
    return new Promise(async (resolve, reject) => {
      try {
        const ref = collection(db, "Diary");
        const q = await query(ref, orderBy("createdAt"), limit(count));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size == 0) {
          //console.log("取得データなし" + day)
          // なし
          resolve([])
        } else {
          this.getRequestQuery(querySnapshot, resolve);

        }

      } catch (e) {
        this.homeCommonError(e, reject)
      }
    })
  }
  getFullDiaryListLast = (count, last) => {
    return new Promise(async (resolve, reject) => {
      try {
        const ref = collection(db, "Diary");
        const q = await query(ref, orderBy("createdAt", "desc"), startAfter(last), limit(count));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size == 0) {
          //console.log("取得データなし" + day)
          // なし
          resolve([])
        } else {
          this.getRequestQuery(querySnapshot, resolve);

        }

      } catch (e) {
        this.homeCommonError(e, reject)
      }
    })
  }
  // 自分の日記リストを取得する
  /**
   * 
   * @param {*} uid 
   * @param {int} count 
   * @returns なしの場合はからの配列になる
   */
  getDiaryList = (uid, count) => {
    if (!uid) {
      console.log("getDiaryList 引数誤り [" + uid + "]");
      throw new Error("getDiaryList 引数誤り [" + uid + "]");
    }


    return new Promise(async (resolve, reject) => {
      try {
        const ref = collection(db, "Diary");
        const q = await query(ref, where("toUid", "==", uid), orderBy("createdAt", "desc"), limit(count));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size == 0) {
          console.log("取得データなし")
          // なし
          resolve([])
        } else {
          this.getRequestQuery(querySnapshot, resolve);

        }

      } catch (e) {
        this.homeCommonError(e, reject)
      }
    })
  }

  /**
   * ページング
   * @param {*} uid 
   * @param {*} count 
   * @param {Object.documentSnapShot} lastVisible 
   * @returns 
   */
  getDiaryListLast = (uid, count, lastVisible) => {
    if (!uid) {
      console.log("getDiaryList 引数誤り [" + uid + "]");
      throw new Error("getDiaryList 引数誤り [" + uid + "]");
    }


    return new Promise(async (resolve, reject) => {
      try {
        const ref = collection(db, "Diary");
        const q = await query(ref, where("toUid", "==", uid), orderBy("createdAt"), startAfter(lastVisible), limit(count));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size == 0) {
          console.log("取得データなし")
          // なし
          resolve([])
        } else {
          this.getRequestQuery(querySnapshot, resolve);

        }

      } catch (e) {
        this.homeCommonError(e, reject)
      }
    })
  }



  // 日記を取得する
  getDiary = (id) => {
    if (!id) {
      console.log("getDiary 引数誤り [" + id + "]");
      throw new Error("getDiary 引数誤り [" + id + "]");
    }
    return new Promise(async (resolve, reject) => {

      try {
        const docRef = await doc(db, "Diary", id);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
          throw new Error("getDiary　データなし [" + id + "]");
        }

        let res = await docSnap.data()
        res.id = docSnap.id;
        resolve(res);
      } catch (e) {
        console.log("getDiary　失敗")
        reject(e)
      }
    })
  }
  // 日記を作成する
  addDiary = ({
    toUid,
    toUser,
    key,
    toIcon,
    privateView,
    diary,
    memoForNext,
    imageUrl,
    videoUrl,
    youtubeUrl,
    tag,
    tagString,
    onedroTag,
    onedroTagString,
    record,
    recordConfig
  }, data) => {

    // TODO 
    const array = diaryParam;  // 配列に定義された文字列
    const missingKeys = array.filter(key => !(key in data));

    if (missingKeys.length > 0) {
      console.log("オブジェクトにキーが不足している keys:", missingKeys);
      throw new Error("addDiary 引数誤り " + missingKeys);
    }

    return new Promise(async (resolve, reject) => {
      console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~^")
      console.log(record)
      addDoc(collection(db, "Diary"), {
        toUid,
        toUser,
        toIcon,
        privateView,
        diary,
        memoForNext,
        imageUrl,
        videoUrl,
        youtubeUrl,
        tag,
        tagString,
        onedroTag,
        onedroTagString,
        record,
        recordConfig: recordConfig || {},
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      }).then((result) => {

        resolve(result);
      }).catch((error) => {
        console.log("企画を追加する 失敗 ")
        resolve()
      });
    })
  }
  // 日記を削除する
  deleteDiary = (id) => {
    if (!id) {
      console.log("deleteDiary 引数誤り [" + id + "]");
      throw new Error("deleteDiary 引数誤り [" + id + "]");
    }
    return new Promise(async (resolve, reject) => {

      const diaryData = await this.getDiary(id);
      console.error("ファイル削除")
      console.log(diaryData.imageUrl)

      // ファイル削除
      const storage = getStorage();
      //console.log(storage)
      const httpsReference = ref(storage, (diaryData.imageUrl));
      console.log("urlからリファレンスを取得")
      console.log(httpsReference)

      // Delete the file
      deleteObject(httpsReference).then(() => {
        // ドキュメント削除
        deleteDoc(doc(db, "Diary", id)).then((result) => {
          resolve(result);
        }).catch((error) => {
          console.log("diaryレコード削除失敗 ")
          resolve(error)
        });
      }).catch((error) => {
        throw new Error("diary日記画像削除失敗(storage) " + error);
      });


    })
  }
  // 日記を更新する
  updateDiary = (data) => {
    // TODO 
    const array = diaryParam;  // 配列に定義された文字列
    const missingKeys = array.filter(key => !(key in data));

    if (missingKeys.length > 0) {
      console.log("オブジェクトにキーが不足している keys:", missingKeys);
      throw new Error("addDiary 引数誤り " + missingKeys);
    }

    return new Promise(async (resolve, reject) => {
      try {
        let parameterData = {
          id: data.id,
          privateView: data.privateView,
          diary: data.diary,
          memoForNext: data.memoForNext,
          imageUrl: data.imageUrl || "",
          sumb: data.sumb,
          videoUrl: data.videoUrl,
          youtubeUrl: data.youtubeUrl,
          tag: data.tag,
          tagString: data.tagString,
          onedroTag: data.onedroTag,
          onedroTagString: data.onedroTagString,
        }
        // 画像のstoreへの登録
        if (data.blobdata) {
          const { url, thumbnail } = await this.fileHandler("diaryImage", auth.currentUser.uid, data.blobdata)
          parameterData.imageUrl = url;
          parameterData.sumb = thumbnail;
        }


        const docRef = await doc(db, "Diary", parameterData.id);
        const docSnap = await getDoc(docRef);
        let res = await docSnap.data()
        parameterData.updatedAt = serverTimestamp()
        await updateDoc(docRef, parameterData).then(() => resolve()).catch((e) => {
          console.log("updateOdaiIntarest　でエラー発生")
          console.log(e)
          reject(e)
        })

      } catch (e) {
        this.homeCommonError(e, reject)
      }

    })
  }
  // レコード設定を取得する
  getRecordConfig = (uid) => {
    if (!uid) {
      console.log("getRecordConfig 引数誤り [" + uid + "]");
      throw new Error("getRecordConfig 引数誤り [" + uid + "]");
    }
    return new Promise(async (resolve, reject) => {
      try {
        const ref = collection(db, "RecrdTrn");
        const q = await query(ref, where("uid", "==", uid));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size == 0) {
          //console.log("取得データなし" + day)
          // なし
          resolve(false)
        } else {
          this.getRequestQuery(querySnapshot, resolve);

        }

      } catch (e) {
        this.homeCommonError(e, reject)
      }
    })
  }
  // レコード設定を更新する
  addOrUpdateRecordConfig = ({
    id,
    uid,
    recordTypeId,
    configName = "",
    step1Name = "",
    step1Time = "",
    step2Name = "",
    step2Time = "",
    step3Name = "",
    step3Time = "",
    step4Name = "",
    step4Time = "",
    step5Name = "",
    step5Time = "",
    step6Name = "",
    step6Time = "",
    step7Name = "",
    step7Time = "",
    step8Name = "",
    step8Time = "",
    step9Name = "",
    step9Time = "",
    step10Name = "",
    step10Time = "",
    memoForNext = "",
    memo,
  }, data) => {
    // TODO 
    const array = recordTrnParam;  // 配列に定義された文字列
    const missingKeys = array.filter(key => !(key in data));

    if (missingKeys.length > 0) {
      console.log("オブジェクトにキーが不足している keys:", missingKeys);
      throw new Error("addOrUpdateRecordConfig 引数誤り " + missingKeys);
    }
    return new Promise(async (resolve, reject) => {
      try {
        console.log("レコードの更新")
        console.log(id)
        if (id === "") {
          // 新規作成
          addDoc(collection(db, "RecrdTrn"), data).then((result) => {
            resolve(result);
          }).catch((error) => {
            console.log("addOrUpdateRecordConfig 失敗 ")
            resolve()
          });
        } else {
          console.log("アップデート")
          console.log(recordTypeId)

          const docRef = await doc(db, "RecrdTrn", id);
          const docSnap = await getDoc(docRef);
          let res = await docSnap.data()
          await updateDoc(docRef, {
            recordTypeId,
            configName,
            step1Name,
            step1Time,
            step2Name,
            step2Time,
            step3Name,
            step3Time,
            step4Name,
            step4Time,
            step5Name,
            step5Time,
            step6Name,
            step6Time,
            step7Name,
            step7Time,
            step8Name,
            step8Time,
            step9Name,
            step9Time,
            step10Name,
            step10Time,
            memoForNext,
            memo,
            updatedAt: serverTimestamp(),
          }).then(() => resolve()).catch((e) => {
            console.log("updateOdaiIntarest　でエラー発生")
            console.log(e)
            reject(e)
          })

        }
      } catch (e) {
        console.log(e)
      }


    })
  }


  /**
   * recordを取得する
   * @param {*} id 
   * @returns {Record}
   */
  getRecordById = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const docRef = await doc(db, "Record", id);
        const docSnap = await getDoc(docRef);
        let res = await docSnap.data()
        res.id = docSnap.id
        resolve(res);
      } catch (e) {
        console.log(e)
        reject(e)
      }

    })

  }

  // レコードを作成する
  /**
   * 同じオブジェクトを２回渡す。引数チェックのため
   * TODO後で是正
   * @param {*} data 
   * @param {*} data 
   * @returns 
   */
  addRecord = (data, {
    step0Time,
    step1,
    step1Time,
    step1FixCount,
    step2,
    step2Time,
    step2FixCount,
    step3,
    step3Time,
    step3FixCount,
    step4,
    step4Time,
    step4FixCount,
    step5,
    step5Time,
    step5FixCount,
    step6,
    step6Time,
    step6FixCount,
    step7,
    step7Time,
    step7FixCount,
    step8,
    step8Time,
    step8FixCount,
    step9,
    step9Time,
    step9FixCount,
    step10,
    step10Time,
    step10FixCount,
    completeTime,
    onedroNote,
    uid,
  }) => {
    console.log(">>>>>>>>>>")
    console.log(data)
    console.log(step0Time)
    // TODO 
    const array = recordParam;  // 配列に定義された文字列
    const missingKeys = array.filter(key => !(key in data));

    if (missingKeys.length > 0) {
      console.log("オブジェクトにキーが不足している keys:", missingKeys);
      throw new Error("addRecord 引数誤り " + missingKeys);
    }

    return new Promise(async (resolve, reject) => {
      addDoc(collection(db, "Record"), {
        step0Time,
        step1,
        step1Time,
        step1FixCount,
        step2,
        step2Time,
        step2FixCount,
        step3,
        step3Time,
        step3FixCount,
        step4,
        step4Time,
        step4FixCount,
        step5,
        step5Time,
        step5FixCount,
        step6,
        step6Time,
        step6FixCount,
        step7,
        step7Time,
        step7FixCount,
        step8,
        step8Time,
        step8FixCount,
        step9,
        step9Time,
        step9FixCount,
        step10,
        step10Time,
        step10FixCount,
        completeTime,
        uid,
        onedroNote,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      }).then(async (result) => {
        const createdRecord = await this.getRecordById(result.id)
        resolve(createdRecord);
      }).catch((error) => {
        console.log("企画を追加する 失敗 ")
        resolve()
      });
    })
  }
  // レコードに記録を更新する
  // 不要　更新はしない
  updateRecord = ({
    id,
    step0Time,
    step1,
    step1Time,
    step2,
    step2Time,
    step3,
    step3Time,
    step4,
    step4Time,
    step5,
    step5Time,
    step6,
    step6Time,
    step7,
    step7Time,
    step8,
    step8Time,
    step9,
    step9Time,
    step10,
    step10Time,
    completeTime,
    uid,
    onedroNote,
  }, data) => {
    // TODO 
    const array = recordParam;  // 配列に定義された文字列
    const missingKeys = array.filter(key => !(key in data));

    if (missingKeys.length > 0) {
      console.log("オブジェクトにキーが不足している keys:", missingKeys);
      throw new Error("updateRecord 引数誤り " + missingKeys);
    }

    return new Promise(async (resolve, reject) => {



      const docRef = await doc(db, "RecrdTrn", id);
      const docSnap = await getDoc(docRef);
      let res = await docSnap.data()
      await updateDoc(docRef, {
        step0Time,
        step1,
        step1Time,
        step2,
        step2Time,
        step3,
        step3Time,
        step4,
        step4Time,
        step5,
        step5Time,
        step6,
        step6Time,
        step7,
        step7Time,
        step8,
        step8Time,
        step9,
        step9Time,
        step10,
        step10Time,
        completeTime,
        uid,
        onedroNote,
        updatedAt: serverTimestamp(),
      }).then(() => resolve()).catch((e) => {
        console.log("updateOdaiIntarest　でエラー発生")
        console.log(e)
        reject(e)
      })
    })
  }



  /**
  * リクエスト表示用のリスト取得クエリ
  * querySnapshotを実行した結果をリスト化して返すRefactoringメソッド
  * @param {querySnapshot, resolve} querySnapshot 
  * @param {[]} レコードの配列 
  */
  getRequestQuery = async (querySnapshot, resolve) => {
    let result = []; //取得結果のリスト
    await querySnapshot.forEach((doc) => {
      //console.log(doc.data().anserLimit.toDate())
      // doc.data() is never undefined for query doc snapshots
      //console.log(doc.id, " => ", doc.data());

      let res = doc.data();
      res.id = doc.id;
      res.snapshot = doc
      result.push(res);
    })
    resolve(result);

  }

  /**
  * エラー返却共通処理
  * @param {*} e 
  * @param {*} reject 
  */
  homeCommonError = (e, reject) => {
    console.log("ホーム用データ取得に失敗");
    console.log(e);
    reject(e)
  }


  /**
   * blobデータを入れたら、サムネイルを生成。ファイルアップロードを行いそのurlを返却する
   * @param {String} strageName 保存するfirestoreのフォルダ名
   * @param {String} ownerUid  ファイル名を一位にするために作成者uidを付与する
   * @param {blob} blob blobデータのファイル本体
   * @returns {url, thumbnail}
   */
  fileHandler = (strageName, ownerUid, blob) => {
    return new Promise(async (resolve, reject) => {
      // サムネイル化しblobを取得 
      const thumbnail = await this.resizeFile(blob);

      // Storageへ画像データを登録
      var storage = getStorage();
      const fileName = this.randomstr();
      //this.sign(fileName)
      //this.sign(this.randomstr())
      var storageRef = ref(storage, `/${strageName}/${ownerUid}/${fileName}`);
      uploadBytesResumable(storageRef, blob).then((uploadTask) => {
        getDownloadURL(ref(storage, `/${strageName}/${ownerUid}/${fileName}`))
          .then((url) => {
            resolve({ url: url, thumbnail: thumbnail })
          }).catch((error) => {
            // getDownloadURLに失敗
            console.log("getDownloadURLに失敗")
            console.log(error)
            reject(error);
          });
      }).catch((error) => {
        // uploadBytesResumableに失敗
        console.log("uploadBytesResumableに失敗")
        console.log(error)
        reject(error);
      });
    })
  }

  /**
  * ファイルサイズをサムネイル用に縮小する。
  * fireを投入し blobを返す
  * @param {*} file 
  * @returns blob uri base64フォーマットのイメージ
  */
  resizeFile = (file) =>
    new Promise((resolve) => {
      try {
        console.log("~~~~~~~~~~~~~~~~~~~^")
        console.log(file)

        Resizer.imageFileResizer(
          file,
          200,
          200,
          "JPG",
          100,
          0,
          (uri) => {
            resolve(uri);
          },
          "base64"
        );
      } catch (e) {
        console.log(e)
      }
    });
  // UUIDを生成。ユーザ単位のファイル名に使用する
  randomstr = () => {
    let s = "";
    let length = 32;
    for (let i = 0; i < length; i++) {
      let random = Math.random() * 16 | 0;
      s += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return s
  }



}





export default IfProxyTool;
