import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';

// TODO: StandardPageはパスが異なるため注意
import IfProxy from '../../IfProxy';
import IfProxyLimitedRequest from '../../IfProxyLimitedRequest';

import HeadFooter from '../HeadFooter';

import TagInput from "../../common/TagInput";
import AgreecheckSightShare from "../../common/AgreecheckSightShare";


// Material UI
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';

// ReactGA Analytics
import ReactGA from "react-ga4";

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";

const CreateLimitedRequest = (props) => {


    const [loading, setLoading] = useState(true);

    const [userData, setUserData] = useState();
    const [toUser, setToUser] = useState();
    const [limitedRequest, setLimitedRequest] = useState();



    //表示用
    const auth = getAuth();
    const navigate = useNavigate();

    const { user } = useAuthContext();
    const classes = useStyles();
    const params = useParams();
    const ifProxy = new IfProxy();
    const ifProxyLimitedRequest = new IfProxyLimitedRequest();


    const location = useLocation();

    useEffect((props) => {
        setLoading(true)
        // ReactGA Analytics ---
        ReactGA.initialize("G-2ZCXE08H2S");
        ReactGA.send({
            hitType: "pageview",
            // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
            page: location.pathname + location.search,
        });
        // ------
        loadData(props);
    }, []);


    const loadData = async (props) => {
        await ifProxy.getUserData(user.uid).then(result => setUserData(result))
        await ifProxy.getUserData(params.id).then(result => setToUser(result))
        setLoading(false)
    }

    const submit = async () => {
        //TODO await ifProxy.insertTag(onedroTag, "ワンドロ企画");



        // お題の作成
        await ifProxyLimitedRequest.addRemitedRequest({
            thema: limitedRequest,
            fromUid: userData.uid,
            fromName: userData.displayName,
            fromIcon: userData.icon,
            toUid: toUser.uid,
            toName: toUser.displayName,
            toIcon: toUser.icon,
        }).then(result => {
            ifProxyLimitedRequest.notify({
                id: result.id,
                thema: limitedRequest,
                fromUid: userData.uid,
                fromName: userData.displayName,
                fromIcon: userData.icon,
                toUid: toUser.uid,
                toName: toUser.displayName,
                toIcon: toUser.icon,
            })
            navigate("/LimitedRequest/" + result.id)
        })

    }

    // 戻る
    const abort = async () => {
        navigate(-1)
    }



    // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
    if (!user) {
        return <Navigate to="/login" />;
    } else if (loading) {
        return (
            <HeadFooter>
                <Box className={classes.contentWrapper}>
                    <Box className={classes.mainBox}>
                        <p>読み込み中...</p>
                    </Box>
                </Box>
            </HeadFooter>
        )
    } else {

        // Boxレイアウトのレスポンシブ対応
        // https://mui.com/components/grid/#responsive-values
        return (
            <HeadFooter>
                <Box className={classes.contentWrapper}>
                    <Box className={classes.mainBox} >
                        <Box className={classes.leftCenterBox} >
                            <Typography variant="h5">制限リクエスト申し込み</Typography>
                            <Typography variant="body1" paragraph>
                                本機能は本サービスの軽量版として、報酬が無料のリクエストに限定してリクエストができる機能です<br></br>
                                標準のリクエスト機能は、画面上部の「クリエイター登録」や「かんたんクリエイター登録」を行い、Stripe社口座の開設が完了しましたら有効化されます<br></br>
                                <br></br>
                                このリクエストが、特定のワンドロ企画に関連するものであれば、対象のワンドロ企画やタグ名も合わせてリクエストの内容にご記載ください。
                            </Typography>
                        </Box>
                        <Box className={classes.centerFlatBox}>
                            <Box className={classes.centerFlatBoxCenteringItem}>


                                <Box className={classes.centerBox}>
                                    <Avatar sx={{ width: 100, height: 100 }} src={userData.icon} />
                                    <Typography variant="body1" paragraph>あなた<br></br>（{userData.displayName}）</Typography>

                                </Box>
                                <Box className={classes.centerBox}>
                                    <Typography variant="h1">→</Typography>
                                    <Typography variant="h5">から</Typography>
                                </Box>

                                <Box className={classes.centerBox}>
                                    <Avatar sx={{ width: 100, height: 100 }} src={toUser.icon} />
                                    <Typography variant="body1" paragraph>{toUser.displayName}<br></br>へ</Typography>

                                </Box>
                            </Box>

                        </Box>
                        <Box className={classes.centerBox}>
                            <TextField fullWidth id="outlined-basic" label="リクエストの内容"
                                multiline rows={4}
                                onChange={(e) => setLimitedRequest(e.target.value)}
                                variant="outlined" value={limitedRequest} />
                        </Box>
                        <Typography variant="h5">以下に同意しリクエストを行います</Typography>
                        <Typography variant="body1" paragraph>
                            <ul>
                                <li>リクエストの回答にに完成責任がないことを理解しています</li>
                                <li>報酬として作品の自身のアカウントからシェアを行います</li>
                            </ul>
                        </Typography>


                        <Button
                            variant="contained"
                            fullWidth
                            disabled={limitedRequest === ""}
                            onClick={() => submit()}
                            color="primary"
                            sx={{ margin: 2 }}>
                            登録
                        </Button>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => abort()}
                            color="primary"
                            sx={{ margin: 2 }}>
                            戻る
                        </Button>
                    </Box>
                </Box>

            </HeadFooter >

        );
    }




}
export default CreateLimitedRequest;

const useStyles = makeStyles((theme) => ({
    // 全体レイアウト
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

    },

    mainBox: {
        maxWidth: "700px",
        margin: "0 auto",
    },
    // 個別コンテンツ　中央揃えで縦に内容を並べる
    centerBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },// 個別コンテンツ　中央揃えで横に内容を並べる
    centerFlatBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    //上で要素を確保し、その内容を中央に次に含めて移動する
    centerFlatBoxCenteringItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '0 auto'
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftCenterBox: {
        display: 'flex',
        paddingRight: 10,
        flexDirection: 'column',
        alignItems: 'left',
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftRowBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'left',
    },
    // 個別コンテンツ　横並びに内容を並べる
    leftBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: "0 auto",
    },
}));
