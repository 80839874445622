import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import { Dna } from 'react-loader-spinner'


import TagInput from "../../common/TagInput";


// TODO: StandardPageはパスが異なるため注意
import IfProxy from '../../IfProxy';
import IfProxyStripe from '../../IfProxyStripe';

import HeadFooter from '../HeadFooter';
import SnsUtil from '../../common/SnsUtil';
import AccountCard from '../../common/AccountCard';
import ImageShareBtn from '../../common/ImageShareBtn';


// ReactGA Analytics
import ReactGA from "react-ga4";

import { InputAdornment } from '@mui/material';

// Material UI
import { makeStyles } from '@mui/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const Product = (props) => {

  const [product, setProduct] = useState(0);


  const [boost, setBoost] = useState(0);
  const [rating, setRating] = useState(1);
  const [estimatedAmount, setEstimatedAmount] = useState(0);

  const [agree, setAgree] = useState(0);
  const [agreeClicked, setAgreeClicked] = useState(false);

  const [share, setShare] = useState(0);
  const ifProxyStripe = new IfProxyStripe();

  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  const [onedroTagLabel, setOnedroTagLabel] = useState("");
  const [tagLabel, setTagLabel] = useState("");


  const [tagControlView, setTagControlView] = useState(false); // productに設定したタグ


  const [editOnedroTags, setEditOnedroTags] = useState([]); // productに設定したタグ
  const [editTags, setEditTags] = useState([]);// productに設定したタグ

  const [resultAmountText, setResultAmountText] = useState("");// 非コミッション報酬の場合、依頼者が実施したシェア内容




  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const ifProxy = new IfProxy();

  const location = useLocation();

  useEffect((props) => {
    setLoading(true)
    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    // ------

    loadData(props);
  }, []);


  const loadData = (props) => {


    // データコール
    ifProxy.getProductByid(params.id).then((result) => {
      setProduct(result);
      setEstimatedAmount(result.requestAmount)
      setAgree(result.agree || 0)
      const onedroLabelList = result.onedroTagString?.map(lbl => ("#" + lbl))
      const onedroResultLbl = onedroLabelList?.join(" ")
      setOnedroTagLabel(onedroResultLbl)
      const labelList = result.tagsString?.map(lbl => ("#" + lbl))
      const resultLbl = labelList?.join(" ")
      setTagLabel(resultLbl)
      setLoading(false)

    })
  }


  const stripePaymentSessionCall = () => {
    ifProxy.addRating(product.id, rating)

    const item = {
      toUid: product.toUid,
      requestId: product.requestId,
      productId: product.id,
      estimatedAmount: estimatedAmount
    }
    ifProxyStripe.settlementRequest(item).then((result) => {
      navigate('/Product/' + params.id);
    })

  }


  const handleResultAmountText = (event) => {
    setResultAmountText(event.target.value)
  }

  // タグ更新
  const returnTags = (tag) => {
    // 作品名のタグ
    setEditTags(tag)
  }
  const returnKikakuTags = (tag) => {

    // 企画名のタグ
    setEditOnedroTags(tag)
  }

  // タグを置き換える更新
  const updateTag = () => {
    setLoading(true);

    ifProxy.updateTag(product.id, editTags, 'Product').then((result) => {
      setLoading(false);
      setTagControlView(false)

    }).catch((e) => {
      setLoading(false);
      setTagControlView(false)
      console.log("タグ周りの　UPDATEエラー")
      console.log(e)
    })
  }
  const updateOnedroTag = () => {
    setLoading(true);
    ifProxy.updateOnedroTag(product.id, editOnedroTags, 'Product').then((result) => {
      setLoading(false);
      setTagControlView(false)

    }).catch((e) => {
      setLoading(false);
      setTagControlView(false)

      console.log("タグ周りの　UPDATEエラー")
      console.log(e)
    })
  }



  const handleBoost = (event) => {
    setBoost(event.target.value);
    let boostValue = parseInt(event.target.value)

    let amountValue = parseInt(product.requestAmount)
    if ((boostValue + amountValue) < amountValue) {
      //最終金額 マイナスを入れられたら最初の値段
      setEstimatedAmount(pre => {
        return amountValue
      })

    } else {
      //最終金額
      setEstimatedAmount(pre => {
        return amountValue + boostValue || amountValue
      })

    }

  }

  const tagControlViewSection = () => {
    return (
      <>
        <h2>タグを変更する</h2>
        <TagInput type="form" resultTags={(tags) => returnTags(tags)} suggestion={[{ id: 'オリジナル', text: 'オリジナル' }]}></TagInput>
        <Button
          variant="contained"
          disabled={editTags.length === 0 ? true : false}
          onClick={() => updateTag()}
          color="primary"
          sx={{ margin: 2 }}>
          上の内容にタグを差し替える
        </Button>
        <TagInput type="formKikaku" resultTags={(onedroTagList) => returnKikakuTags(onedroTagList)} suggestion={[{ id: 'オリジナル', text: 'オリジナル' }]}></TagInput>
        <Button
          variant="contained"
          disabled={editOnedroTags.length === 0 ? true : false}
          onClick={() => updateOnedroTag()}
          color="primary"
          sx={{ margin: 2 }}>
          上の内容にワンドロタグを差し替える
        </Button>
      </>
    )

  }


  // 作者だけが見れるコンポーネント
  const controlView = () => {
    if (user.uid === product.toUid) {
      return (<>
        <Card>
          <CardContent>
            <Button
              onClick={() => {
                ifProxy.deleteProduct(params.id)
                navigate('/')
              }
              }
              variant="outlined"
              color="primary">
              削除する
            </Button>
          </CardContent>

        </Card>
        <Button
          onClick={() => setTagControlView(pre => !pre)}
        >
          タグを変更する        </Button>
        {tagControlView ? tagControlViewSection() : ""}

      </>

      )
    }

  }

  const ratingView = () => {
    return (
      <>
        <h2>評価</h2>
        <Box className={classes.leftBox}>
          <Box sx={{ margin: 2 }}>
            <label htmlFor="check">
              おしい！
            </label>
            <input
              type="checkbox"
              id="check"
              checked={rating === 0 ? true : false}
              onChange={() => setRating(prevState => 0)}
            />
          </Box>
          <Box sx={{ margin: 2 }}>
            <label htmlFor="check">
              満足
            </label>
            <input
              type="checkbox"
              id="check"
              checked={rating === 1 ? true : false}
              onChange={() => setRating(prevState => 1)}
            />
          </Box>
          <Box sx={{ margin: 2 }}>
            <label htmlFor="check">
              すごい！
            </label>
            <input
              type="checkbox"
              id="check"
              checked={rating === 2 ? true : false}
              onChange={() => setRating(prevState => 2)}
            />
          </Box>
        </Box>
      </>
    )
  }


  // 依頼者だけが見れるコンポーネント
  const feedbackView = () => {
    if (user.uid !== product.fromUid) return;

    // 非コミッションオファー　：決済なしでpaiedに移行
    if (product.shareAmountFlag) {
      return (<>
        {ratingView()}
        <h2>約束していたシェア報酬の内容</h2>
        <Typography variant="body2">
          {product.shareAmount}
        </Typography>
        <h2>実際に行ったシェアの内容</h2>
        <TextField fullWidth multiline margin="nomal" rows={4} id="outlined-basic" label="実施した内容を具体的にご記載ください" onChange={handleResultAmountText} value={resultAmountText} variant="outlined" sx={styleDifinition.aInputForm} />

        {product.status !== "paied" && <Button
          onClick={() => {
            ifProxy.shareAmountPaied(params.id, resultAmountText).then(() => {
              window.location.reload()
            })
            ifProxy.shareAmountPaied(params.id, resultAmountText).then(() => {
              window.location.reload()
            })
          }
          }
          variant="contained"
          color="primary">
          シェアの内容を報告して完了とする
        </Button>}
        <h2>ステータス</h2>
        <h2>{product.status === "paied" ? "完了" : "シェア報酬まち"}</h2>
      </>)
    } else {

      // コミッション ：決済後webhookでpaiedに移行
      return (<>
        {ratingView()}
        <h2>金額</h2>
        <Typography variant="body2">
          {product.requestAmount}
        </Typography>
        <h2>ブースト</h2>
        <TextField fullWidth margin="nomal" id="outlined-adornment-amount"
          label="オファー金額"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          onChange={handleBoost} variant="outlined" type="number" value={boost} sx={styleDifinition.aInputForm}
        />
        <h2>合計金額</h2>
        <h2>{estimatedAmount}</h2>
        <Typography variant="body2">
          ※ 納品された作品に対してお支払いが為されなかった場合。<br></br>
          未入金記録としてユーザー情報に記録され、未入金件数としてプロフィールから公開されます。
        </Typography>
        <h2>ステータス</h2>
        <h2>{product.status === "paied" ? "決済済み" : "支払い待ち"}</h2>
        {product.status !== "paied" && <Button
          onClick={() => {
            let result = window.confirm("支払い画面に移動します")
            if (result) {
              setProcessing(true)
              stripePaymentSessionCall()
              navigate('/Product/' + params.id)
            }
          }
          }
          variant="contained"
          disabled={processing}
          color="primary">
          決済する
        </Button>}
      </>

      )

    }


  }



  // 非ログインユーザのView =============================================================================
  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ

  if (!user) {
    if (loading) {
      return (
        <HeadFooter>
          <Box className={classes.contentWrapper}>
            <Box className={classes.mainBox}>
              <p>読み込み中...</p>
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            </Box>
          </Box>
        </HeadFooter>
      )
    } else {
      return (
        <HeadFooter>
          <Box className={classes.contentWrapper}>
            <Box className={classes.mainBox} >
              <Box className={classes.centerBox}>
                <CardMedia
                  component="img"
                  image={product.image}
                  alt="product"
                />
              </Box>
              <Box className={classes.leftBox}>
                <Typography variant="h3">
                  {onedroTagLabel}
                </Typography>
              </Box>
              <Box className={classes.leftBox}>
                <Typography variant="h3">
                  {tagLabel}
                </Typography>
              </Box>


              <h2>リクエスト</h2>

              <h3>リクエストの内容</h3>
              <Typography variant="body2">
                {product.requestThema}
              </Typography>
              <Button
                onClick={() => { navigate("/Request/" + product.requestId) }
                }
                variant="outlined"
                color="primary">
                リクエストの詳細を見る
              </Button>





              <h2>リアクションを送る</h2>
              <Box className={classes.leftBox}>
                <Button
                  disabled={true}
                  sx={{
                    width: "60%",
                    margin: 1
                  }}
                  variant="outlined"
                  color="primary">
                  好き！(ログインが必要)
                </Button>
                <h1>{agree}</h1>
              </Box>
              <Typography variant="body2">
                ボタンを押して共感をクリエイターに伝えます<br></br>
                (賛同者は匿名です)
              </Typography>
              <Box className={classes.leftBox}>
                <Button
                  disabled={true}
                  sx={{
                    width: "60%",
                    margin: 1
                  }}
                  variant="outlined"
                  color="secondary">
                  シェアする(ログインが必要) <img width="50" src={`${process.env.PUBLIC_URL}/twitterIcon.png`} alt="twitterLogo"></img>
                </Button>
                <h1>{share}</h1>

              </Box>
              <Typography variant="body2">
                シェアした数はユーザーランキングに反映されます
              </Typography>


              {/** フォローボタン 依頼者をフォローする*/}
              <Card className={classes.card}>
                <CardContent>
                  <Box className={classes.leftBox}>

                    <Avatar sx={{ width: 100, height: 100 }} src={product.toIcon} onClick={() => navigate('/User/' + product.toUid)} />
                    <Box >
                      クリエイター名：{product.toName}
                    </Box>
                  </Box>


                </CardContent>
              </Card>

            </Box>
          </Box>

        </HeadFooter >

      );
    }

    // ログインユーザのView =============================================================================

  } else if (loading) {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Box>
        </Box>
      </HeadFooter>
    )
  } else {

    const twitterShareUrl = "http://twitter.com/share?url=" + window.location.origin + '/Product/' + params.id + "&text=気になったワンドロをシェア&hashtags=ワンフロ"
    const twitterImageShareMessage = window.location.origin + '/LimitedRequest/' + params.id + " " + "気になったワンドロをシェア #ワンフロ"


    return (
      <HeadFooter>

        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox} >
            <Box className={classes.centerBox}>
              <CardMedia
                component="img"
                image={product.image}
                alt="product"
              />
            </Box>
            {controlView()}
            <Box className={classes.leftBox}>
              <Typography variant="h3">
                {onedroTagLabel}
              </Typography>
            </Box>
            <Box className={classes.leftBox}>
              <Typography variant="h3">
                {tagLabel}
              </Typography>
            </Box>
            {product.status !== "paied" && feedbackView()}


            <h2>リクエスト</h2>

            <h3>リクエストの内容</h3>
            <Typography variant="body2">
              {product.requestThema}
            </Typography>
            <Button
              onClick={() => { navigate("/Request/" + product.requestId) }
              }
              variant="outlined"
              color="primary">
              リクエストの詳細を見る
            </Button>

            <h2>リアクションを送る</h2>
            <Box className={classes.leftBox}>
              <Button
                disabled={agreeClicked}
                sx={{
                  width: "60%",
                  margin: 1
                }}
                onClick={() => {
                  ifProxy.productAgreeCount(params.id, user.uid)
                  setAgree(pre => {
                    console.log(pre)
                    if (pre === undefined) return 1
                    return pre + 1
                  })
                  setAgreeClicked(true)
                }
                }
                variant="outlined"
                color="primary">
                好き！
              </Button>
              <h1>{agree}</h1>
            </Box>
            <Typography variant="body2">
              ボタンを押して共感をクリエイターに伝えます<br></br>
              (賛同者は匿名です)
            </Typography>
            <Box className={classes.leftBox}>
              <Button
                sx={{
                  width: "60%",
                  margin: 1
                }}
                onClick={() => {
                  ifProxy.productShareCount(params.id, user.uid, window.location.origin + "/Product/" + params.id)
                  setShare(pre => pre + 1)
                  ifProxy.updateUserRtcount(user.uid)
                  window.open(twitterShareUrl)
                }
                }
                variant="outlined"
                color="secondary">
                シェアする <img width="50" src={`${process.env.PUBLIC_URL}/twitterIcon.png`} alt="twitterLogo"></img>
              </Button>
              <h1>{share}</h1>
            </Box>
            <Typography variant="body2">
              シェアした数はユーザーランキングに反映されます
            </Typography>
            <Box className={classes.leftBox}>
              <ImageShareBtn imageBlobData={product.thumbnail} message={twitterImageShareMessage} setResult={() => {
                ifProxy.productShareCount(params.id, user.uid, window.location.origin + "/Product/" + params.id)
                setShare(pre => pre + 1)
                ifProxy.updateUserRtcount(user.uid)
              }}></ImageShareBtn>
            </Box>


            {/** フォローボタン 依頼者をフォローする*/}
            <Card className={classes.card}>
              <CardContent>
                <Box className={classes.leftBox}>

                  <Avatar sx={{ width: 100, height: 100 }} src={product.toIcon} onClick={() => navigate('/User/' + product.toUid)} />
                  <Box >
                    クリエイター名：{product.toName}
                    {product.toUid && <SnsUtil toUser={product.toUid}></SnsUtil>}
                  </Box>
                </Box>


              </CardContent>
            </Card>

          </Box>
        </Box>

      </HeadFooter >

    );
  }




}
export default Product;
const styleDifinition = {
  aInputForm: {
    margin: "10px",
  },
}
const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
    margin: "0 auto",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  mainBox: {
    maxWidth: "1000px",
    width: "800px",
    margin: "0 auto",
    flexDirection: 'column',
  },
  centerBox: {
    maxWidth: "700px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  leftBox: {
    display: 'flex',
    minWidth: "400px",
    alignItems: 'left',
    flexDirection: 'row',
    margin: "0 auto",
  },
  cardList: {
  },
  card: {
  },
}));
