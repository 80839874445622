import React from "react";
import { useRef } from 'react';
import { useState } from 'react';

// 標準(A~E)はファイル配置の階層が深くなるため、読み込みパスに注意
import IfProxy from '../../IfProxy';
import { useAuthContext } from '../../AuthContext';

import { Link, Navigate, useNavigate } from "react-router-dom";

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function (props) {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const classes = useStyles();


  // もし既にログイン中であれば、サインアップ画面ではなくメインに飛ばす
  if (user) {
    const ifProxy = new IfProxy();
    // 途中登録状態の確認
    // userだけ作って、プロフィール作成時にやめた人を救助
    ifProxy.getUserData(user.uid).then((userData) => {
      //console.log(userData)
      //console.log(userData.length < 1)
      if (userData.length < 1) {
        // まだuserData未作成の場合はプロフィールへ
        navigate('/UserEdit/' + user.uid);

      } else {
        navigate('/MainList');
      }

    })



  } else {
    return <UserPopLogin {...props} user={user} navigate={navigate} classes={classes} />;
  }
}


class UserPopLogin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: '',
    };
    this.handleEmailForm = this.handleEmailForm.bind(this);
    this.handleEmailPassword = this.handleEmailPassword.bind(this);
    console.log("USER ==== FROM ==== CONTEXT")
    console.log(this.props.user)
    this.setState({ user: this.props.user })
  }

  handleEmailForm = (event) => {
    this.setState({ email: event.target.value });

  }
  handleEmailPassword = (event) => {
    this.setState({ password: event.target.value });

  }


  handleEmailLogin = async (e) => {
    console.log("CLICKED")
    let ifProxy = new IfProxy();
    //pvサインアップ、またはログイン数
    //ifProxy.addKpi("addSignup", "");

    ifProxy.login(this.state.email, this.state.password).then((result) => {
      console.log("handleEmailLogin")
      console.log(result);
      console.log("ログインチェック")
      if (result.code === "NG") {
        this.setState({ error: "パスワードまたはメールアドレスが誤っています" });
        return;
      }

      ifProxy.getUserData(result.uid).then((userData) => {
        if (userData.length == 0) {
          console.log("新規ユーザ");
          console.log(userData);
          console.log(userData.length);
          this.props.navigate(`/UserEdit/${result.id}`);
        } else {
          console.log("既存ユーザ");
          this.props.navigate('../MainList');
        }
      })

    })
    // 認証では初めてのユーザの場合はプロフィール作成。
    // 初めてではなければMainにコールバックで遷移させる
  }
  handleEmailUserCreate = async (e) => {
    console.log("CLICKED")
    let ifProxy = new IfProxy();
    //pvサインアップ、またはログイン数
    //ifProxy.addKpi("addSignup", "");

    ifProxy.createUser(this.state.email, this.state.password).then((result) => {
      console.log("LOGIN_USER_BY_EMAIL")
      console.log(result);
      console.log("入力チェック");
      if (result.code === "NG") {
        this.setState({ error: "メールアドレスが誤っています" });
        return;
      }

      ifProxy.getUserData(result.uid).then((userData) => {
        if (userData.length == 0) {
          console.log("新規ユーザ");
          console.log(userData);
          console.log(userData.length);
          this.props.navigate(`/UserEdit/${result.uid}`);
        } else {
          console.log("既存ユーザ");
          this.props.navigate('../MainList');
        }
      })

    })
  }
  handleTwitterLogin = async (e) => {
    console.log("CLICKED")
    let ifProxy = new IfProxy();
    ifProxy.twitterSignup().then((result) => {
      console.log("LOGIN_USER_BY_OAUTH")
      console.log(result);
      // 新規ユーザか既存ユーザを判定
      ifProxy.getUserData(result.uid).then((userData) => {
        console.log("払い出しuid")
        console.log(result.uid)
        if (userData.length == 0) {

          console.log("新規ユーザ");
          console.log(userData);
          console.log(userData.length);
          console.log(result.uid)
          this.props.navigate(`/UserEditFirst/${result.uid}`);
        } else {
          console.log("既存ユーザ");
          this.props.navigate('/MainList');
        }
      })
    })
    // 認証では初めてのユーザの場合はプロフィール作成。
    // 初めてではなければMainにコールバックで遷移させる
  }

  render() {
    const theme = createTheme();

    return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={`${process.env.PUBLIC_URL}/logo512.png`} className={this.props.classes.logo} alt="Logo" />

            <Typography component="h1" variant="h5">
              ユーザ登録
            </Typography>
            <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
              {this.state.error && <p style={{ color: 'red' }}>{this.state.error}</p>}
              <label htmlFor="loginform">
                ユーザ名とパスワードを入力しログインしてください
              </label>
              <Box sx={{ mt: 1 }}>
                <TextField
                  id="filled-multiline-flexible"
                  fullWidth
                  label="メールアドレス"
                  onChange={this.handleEmailForm}
                  variant="filled"
                  placeholder=""
                  value={this.state.email}
                  sx={{ marginTop: 1 }}
                />
                <TextField
                  id="filled-multiline-flexible"
                  fullWidth
                  label="password"
                  onChange={this.handleEmailPassword}
                  variant="filled"
                  placeholder=""
                  value={this.state.password}
                  sx={{ marginTop: 1 }}
                />
                <Button
                  fullWidth
                  disabled={(false || this.state.email == "" || this.state.password == "")}
                  variant="contained"
                  color="primary"
                  onClick={this.handleEmailLogin}
                  sx={{ mt: 3, mb: 2, marginTop: 5 }}
                >
                  ログイン
                </Button>
                <Button
                  fullWidth
                  disabled={(false || this.state.email == "" || this.state.password == "")}
                  variant="contained"
                  color="primary"
                  onClick={this.handleEmailUserCreate}
                  sx={{ mt: 3, mb: 2, marginTop: 5 }}
                >
                  新規登録
                </Button>
                <Grid container>
                  <a href="#" className={this.props.classes.underLink}>利用規約</a><a href="#" className={this.props.classes.underLink}>プライバシーポリシー</a>
                  ※ ユーザ登録によって利用規約への同意がなされたものとします
                </Grid>
                <Button
                  fullWidth
                  disabled={false}
                  variant="outlined"
                  color="primary"
                  onClick={this.handleTwitterLogin}
                  sx={{ mt: 3, mb: 2, marginTop: 5 }}
                >
                  Twitterログイン
                </Button>
                <Grid container>
                </Grid>

              </Box>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
}

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  logo: {
    width: 200,
    margin: 20,
  },
  underLink: {
    margin: 5,
  },
}));
