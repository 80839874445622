import React, { createContext, useState, useContext, useEffect } from 'react';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import IfProxy from './IfProxy';

const auth = getAuth();
const AuthContext = createContext();
const ifProxy = new IfProxy();


export function useAuthContext() {
  return useContext(AuthContext);
}
export function AuthProvider({ children }) {
  const [user, setUser] = useState('');
  const [userData, setUserData] = useState('');

  const [loading, setLoading] = useState(true);
  const value = {
    userData,
    user,
    loading,
  };

  useEffect(() => {
    const unsubscrived = auth.onAuthStateChanged((user) => {
      if (user) {
        ifProxy.getUserData(user.uid).then((result) => {
          setUserData(result)
        }).catch((e) => console.log(e))
      }

      setUser(user);
      setLoading(false);
      //console.log("Auth Provider")
      //console.log(user)
    });
    return () => {
      unsubscrived();
    };
  }, []);

  // onAuthStateChangedの読み込みにラグがあるため、
  // 読み込み完了でステータスがfalseに遷移するまでコンポーネントを描画しないようにする
  return <AuthContext.Provider value={value}>
    {!loading && children}
  </AuthContext.Provider>;
}
