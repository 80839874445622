import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import IfProxyCalender from '../../IfProxyCalender';
import IfProxyDefault from '../../IfProxy';
import TagInput from "../../common/TagInput";

import HeadFooter from '../HeadFooter';
import StraitLineSignupButton from '../../common/StraitLineSignupButton';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import FavoriteIcon from '@mui/icons-material/Favorite';
import InfoIcon from '@mui/icons-material/Info';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';


import CalenderModalView from "./CalenderModalView";

import Typography from '@mui/material/Typography';
// リスト要素の１つのコンポーネント
/**
 * 
 * @param {data:CalenderItem} param0 
 * @returns 
 */
const SearchForm = ({ data, goFilter }) => {
    const classes = useStyles();
    const ifProxy = new IfProxyCalender()
    const { user } = useAuthContext();

    const ganleData = data.map((item, index) => {

        return {
            id: item.id,
            tagsString: item.ganleTagDataStr || []
        }
    })

    const charactorData = data.map((item, index) => {
        return {
            id: item.id,
            tagsString: item.charactorTagDataStr || []

        }
    })
    const featureData = data.map((item, index) => {
        return {
            id: item.id,
            tagsString: item.featureTagDataStr || []
        }
    })
    const getKeywordGanle = (value) => {
        // 親コンポーネントに返す
        goFilter({
            type: "ganle",
            value
        }
        )
    }
    const getKeywordChara = (value) => {
        // 親コンポーネントに返す
        goFilter({
            type: "chara",
            value
        }
        )
    }
    const getKeywordFeature = (value) => {
        // 親コンポーネントに返す
        goFilter({
            type: "feature",
            value
        }
        )
    }
    const getKeywordClear = (value) => {
        // 親コンポーネントに返す
        goFilter({
            type: "clear",
            value: ""
        }
        )
    }


    return (<>
        <Typography>ジャンルで探す</Typography>
        <TagInput key="input1" type="viewCalenderGanle" returnSearchKeyword={(value) => getKeywordGanle(value)} tagViews={ganleData}></TagInput>
        <Typography>キャラクターで探す</Typography>
        <TagInput key="input2" type="viewCalenderCharactor" returnSearchKeyword={(value) => getKeywordChara(value)} tagViews={charactorData}></TagInput>
        <Typography>好きな属性で探す</Typography>
        <TagInput key="input3" type="viewCalenderFeature" returnSearchKeyword={(value) => getKeywordFeature(value)} tagViews={featureData}></TagInput>
        <Button variant="outlined" onClick={() => getKeywordClear()}>絞り込みをリセットする</Button>
    </>)




}
export default SearchForm;



const useStyles = makeStyles((theme) => ({
    test: {

    },
}));