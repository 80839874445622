import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import IfProxy from '../../IfProxy';
import IfProxyTu from '../../IfProxyTu';


import HeadFooter from '../HeadFooter';

import TutorialProgressGraph from '../../common/graphs/TutorialProgressGraph';

// ReactGA Analytics
import ReactGA from "react-ga4";



// Material UI
import { makeStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';


/**
 * ユーザ参照
 * @param  
 * @returns 
 */
const GuidTo1 = (props) => {
  const [loading, setLoading] = useState(true);
  // クリックされたイベントの情報をモーダルに渡す
  const [clickedEventData, setClickedEventData] = useState("");
  const [modalShow, setModalShow] = useState(false);


  // チェックボックス
  const [userData, setUserData] = useState({});



  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();


  const ifProxy = new IfProxy();
  const ifProxyTu = new IfProxyTu();


  let first = true;
  useEffect((props) => {
    if (first) {
      setLoading(true)
      // ReactGA Analytics ---
      ReactGA.initialize("G-2ZCXE08H2S");
      ReactGA.send({
        hitType: "pageview",
        // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
        page: location.pathname + location.search,
      });
      // ------
      loadData();
    }

    first = false;
    return () => {
    }

  }, []);

  const loadData = async () => {
    const userDataByQuery = await ifProxyTu.getUserData(user.uid);
    setUserData(userDataByQuery);

    setLoading(false)
  }



  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {

    if (loading) {
      return (
        <HeadFooter>
          <Box className={classes.contentWrapper}>
            <Box className={classes.mainBox}>
              <p>読み込み中...</p>
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            </Box>
          </Box>
        </HeadFooter>
      )
    } else {
      //ログインしていないユーザ向けのプロフィール画面
      return (
        <></>
      )
    }
  } else if (loading) {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Box>
        </Box>
      </HeadFooter>
    )
  } else {

    const twitterShareUrl = "http://twitter.com/share?url=" + window.location.origin + '/User/' + params.id + "&text=ワンドロテーマを募集中&hashtags=ワンフロ"

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      < HeadFooter >

        <Box className={classes.contentWrapper}>
          <Grid container className={classes.modalGrid} >
            {/** 0段目 ===================================================================== */}

            <Grid item xs={12}>
              <Typography variant="h5">
                １）作家さんのモチベーションを応援してほしい
              </Typography>
              <Typography variant="body1" paragraph>
                このサイトでは作家さんのモチベーションを重視しています。ワンドロという難しいチャレンジをする作家さんが作品を発表する場であり、創作意欲を支えることが大切です。
              </Typography>
              <Typography variant="body1" paragraph>
                ファンや他の作家さんも、ぜひチャレンジする作家さんを応援し合ってください。お互いに励まし合い、作家さんの創作活動をサポートすることで、より豊かなコンテンツが生まれます。
              </Typography>
              <Typography variant="body1" paragraph>
                作家さんにとってファンからのポジティブなフィードバックは重要です。彼らの創作意欲や熱意を高めるために、あなたのサポートが必要です。
              </Typography>
              <Typography variant="h6">
                <b>有償リクエストはもちろんですが、無料でもできる方法があります。
                </b>
              </Typography>
            </Grid>


            <Grid item xs={12}>
              <ul>
                <li>トップのリスト画面などから、イラスト作品を開いてください</li>
                <li>作品画面の下部、「リアクションを送る」のボタンを確認してください</li>
                <li>ここから２つのフィードバックを送ることができます</li>
              </ul>
              <img src={`${process.env.PUBLIC_URL}/tu1.png`}
                style={{
                  width: '100%',
                  maxWidth: "1000px",
                  margin: "0 auto",
                  opacity: 0.8,
                  display: "block",
                }} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5">
                ２）「好き！」を行う
              </Typography>
              <Typography variant="body1" paragraph>
                作家さんの作品を見て気に入ったら、「好き！」ボタンを押してほしいです。
              </Typography>
              <Typography variant="body1" paragraph><b>「好き！」ボタンは匿名です</b></Typography>
              フィードバックすることで、作家さんにあなたのサポートを伝えることができます。好きな作品を描き続けられるように、ぜひ少しでも思ったら気軽にお気に入りをしてください。
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5">
                ３）「シェア」をする
              </Typography>
              <Typography variant="body1" paragraph>
                作家さんのファン獲得にも協力してほしいです。
              </Typography>
              <Typography variant="body1" paragraph>作品が気に入った場合はぜひ、ソーシャルメディアなどで作家さんの作品をシェアしてください。これにより、作品が多くの人に広まり、作家さんの知名度や支持が増えます。作家さんの創作活動に対する協力となるため、ぜひ作品のシェアをお願いします。</Typography>
              <Typography variant="body1" paragraph></Typography>
            </Grid>

            {/** 0段目 ===================================================================== */}

            <Grid item xs={12}>
              <Typography variant="h5">
                4）<b>リクエストが選ばれるには</b>
              </Typography>
              <Typography variant="body1" paragraph>
                <b>逆に、リクエストもフィードバックを送ることができます</b>この点は重要ですので、<br></br>
                作家さんとしてではなく、絵を見たい方はぜひ活かして欲しい機能です
              </Typography>


            </Grid>

            <Grid item xs={12}>
              <ul>
                <li>リクエストへもフィードバックが送れます</li>
              </ul>
              <img src={`${process.env.PUBLIC_URL}/tu1.2.png`}
                style={{
                  width: '100%',
                  maxWidth: "1000px",
                  margin: "0 auto",
                  opacity: 0.8,
                  display: "block",
                }} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" paragraph>
                リクエストを受けた作家さんは<br></br>
                シェア数や、「私もみたい！」数はから<b>注目されているリクエストを選ぶ</b>ことができます
              </Typography>
              <Typography variant="body1" paragraph>
                せっかくなら、描いたら喜ばれるテーマを描きたいと考える作家さんにとっては、ファンの皆さんが何が欲しいのかを知ることができる仕組みになります
              </Typography>
              <Typography variant="body1" paragraph>
                あなたのリクエストしたアイデアの注目を得るためには、<b>リクエストをシェアして賛同数を集める</b>のが有効です
              </Typography>
              <Typography variant="body1" paragraph>
                また、あなたの案ではなくても、「そのテーマは確かに見たい！」と共感するものがあれば、<br></br>フィードバックして注目度をあげましょう
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" fullWidth={true} onClick={() => {
                ifProxyTu.activateTu_1_firstuserguide(userData.id);
                navigate('/tu')

              }}>理解した!<br></br>チュートリアルに戻る</Button>
            </Grid>
          </Grid>

        </Box>

      </HeadFooter >

    );
  }




}
export default GuidTo1;

const useStyles = makeStyles((theme) => ({
  //オーバーレイ
  overlay: {
    zIndex: 10,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },//オーバーレイの中のモーダル
  modalView: {
    zIndex: 10,
    borderRadius: 15,
    width: '50%',
    padding: '1em',
    background: '#fff',
  },
  // 全体レイアウト
  contentWrapper: {
    marginTop: 20,
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",

  },
  // グリッド箱の書式 
  // モーダルのグリッド全体
  modalGrid: {
    //maxWidth: 1000,
    padding: 0,
    margin: '0 auto'

  },
  taskItem: {
    padding: 20,
    margin: 20,
    border: '2px solid',
    borderColor: '#a6d4fa',
    borderRadius: 20
  },
  taskItemGuide: {
  },

  gridPadding: {
    padding: 5,
    border: '2px solid',
    borderColor: '#a6d4fa',
    backgroundColor: 'linear-gradient(to bottom right,#a6d4fa,#1976d2)',
    borderRadius: '10'

  },
  // グリッド箱の書式 枠線なし
  gridPaddingNoborder: {
    padding: 5,


  },
  // 個別コンテンツ　中央揃えで縦に内容を並べる
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },// 個別コンテンツ　中央揃えで横に内容を並べる
  centerFlatBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftCenterBox: {
    display: 'flex',
    paddingRight: 10,
    flexDirection: 'column',
    alignItems: 'left',
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftRowBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
  },
  // 個別コンテンツ　横並びに内容を並べる
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },

  // モーダルダイアログのフォーム　個別アイテム
  modalFormItem: {
    margin: 20,
  },
  //サンプルのグラフエリア
  sampleGraphEria: {
    width: 200
  },

  cardList: {
  },
  card: {
  },
  bottomCard: {
    width: 250
  },
}));



