import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../AuthContext';
import IfProxy from '../IfProxy';
import IfProxyStripe from '../IfProxyStripe';


// Material UI
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { Dna } from 'react-loader-spinner'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

import TextField from '@mui/material/TextField';

import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

// 標準の画面大枠レイアウト
const HeadFooter = (props) => {
  // コンポーネントのデザインオブジェクト（いつもレイアウトしてるやつ）はここに全部入っている
  const classes = useStyles();


  const [showDrower, setShowDrower] = useState(false);
  const [newFlug, setNewFlug] = useState(false);

  const [loding, setLoding] = useState(false);

  const [stripeUserEnabled, setStripeUserEnabled] = useState(0);

  const [premium, setPremium] = useState(false);

  const [code, setCode] = useState("");



  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const ifProxy = new IfProxy();
  const ifProxyStripe = new IfProxyStripe();



  useEffect(() => {
    checkNew();
    userStatusCheck();
  }, []);

  const handleLogout = () => {
    auth.signOut();
    navigate('/login');
  };


  const toggleOpen = () => {
    setShowDrower(!showDrower);
  }

  const handleCode = (event) => {
    setCode(event.target.value)
  }


  const goCreator = async () => {
    if (!user) {

    } else {
      navigate('/StraitLineSignup')
    }
  }
  const goGuide = () => {
    if (!user) {

    } else {
      navigate('/RuleAndManner')
    }
  }
  const goCreatorGuide = () => {
    if (!user) {

    } else {
      navigate('/ManualStripe')
    }
  }


  /**
   * 既読チェック
   */
  const checkNew = () => {
    if (!user) {

    } else {
      ifProxy.checkRead(user.uid).then((result) => {

        if (result) {
          setNewFlug(true)
        }
      }).catch((e) => {
        console.log(e)
      })
    }
  }

  /**
   * ログイン済みユーザの場合はクリエイターアカウントであるかどうかをチェック
   */
  const userStatusCheck = async () => {

    if (!user) {
      // サインアップしてない
      setStripeUserEnabled(0)
    } else {
      const userData = await ifProxy.getUserData(user.uid);

      if (userData.remitedCreator) {
        setStripeUserEnabled(2)
      } else {
        // クリエイター登録をしていない
        setStripeUserEnabled(1)
      }

      // プレミアムチェック ２はプレミアム
      if (userData.premium == 2) {
        setPremium(true)
      } else if (userData.monitor) {
        // モニターへの特別解放
        setPremium(true)
        // 許可はFirebaseからの手動登録
      }


    }
  }


  /**
   * ボタンの表示切り替え　
   * 
   * このボタンは以下のケースで表示を切り替える
   * 0 本アプリ未登録のユーザ何も表示しない クリエイター登録をしていない
   * 1 本アプリ登録、クリエイター登録がまだ完了できていない
   * 2 クリエイター登録済み： Stripe口座管理画面へのリンク
   * @returns 何も表示しない/ボタンを表示する/決済管理情報を表示する
   */
  const creatorRegistrationButtonView = () => {

    if (stripeUserEnabled === 0 || stripeUserEnabled === 1) {
      // プラットフォーム未登録
      return (
        <>
          <Box className={classes.centerBox}>

            <Button
              sx={{
                borderRadius: 20, color: "#fff", background: 'linear-gradient(to bottom right,#2CD8D5 0%, #C5C1FF 56%, #FFBAC3 100%)', marginLeft: 5,
              }}
              color="inherit" variant="outlined" onClick={() => goCreator()} >
              クリエイター登録 </Button>
          </Box>
        </>
      )
    }

  }



  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return (
      <>
        <AppBar position="static" className={classes.header}>
          <Toolbar>

            <Grid container justify="space-between" spacing={3} sx={{ padding: 1 }} columns={{ xs: 12, sm: 12, md: 12 }}>

              <Grid item xs={8} sm={8} md={10} lg={10}>
                <Typography variant="h6" className={classes.applicationTitle} onClick={() => { navigate(`/MainList`) }}>
                  ワンフロ
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={2} lg={2}>
                <Button className={classes.headerBtn} color="inherit" variant="outlined" onClick={() => { navigate(`/login`) }}>
                  ユーザ登録
                </Button>
              </Grid>

            </Grid>

          </Toolbar>

        </AppBar>

        <Container component="main" >
          {props.children}

        </Container>

        <Box sx={{
          marginTop: 10,
          padding: 2,
          display: 'flex',
          borderTop: 1,
          borderColor: "#CCCCCC",
        }}>
          <a href="#" onClick={() => navigate("/Kiyaku")} className={classes.link} > 利用規約</a>
          <a href="#" onClick={() => navigate("/Privacypolicy")} className={classes.link} > プライバシーポリシー</a>
          <a href="#" onClick={() => navigate("/Tokushoho")} className={classes.link} > 特定商取引法に基づく表記</a>

        </Box>
      </>

    );
  } else if (loding) {
    return (
      <>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Box>
        </Box>
      </>
    )
  } else {
    return (
      <>
        <Drawer
          anchor='right'
          open={showDrower}
          docked={false}
          width={200}
        //onRequestChange={this.handleToggle}
        >
          <MenuItem onClick={() => {
            navigate(`/User/${user.uid}`)
          }} >プロフィール</MenuItem>
          <MenuItem onClick={() => {
            navigate(`/DiaryList`)
          }} >ワンドロ日記</MenuItem>
          <MenuItem
            disabled={!premium}
            onClick={() => {
              navigate(`/v2/OnedroTimer`)
            }} >V2タイマー {!premium && "プレミアム機能です"}</MenuItem>
          <MenuItem onClick={() => {
            navigate(`/lp`)
          }} >リクエスト募集機能のご紹介</MenuItem>
          <MenuItem onClick={() => {
            navigate(`/lpTimer`)
          }} >ワンドロタイマーのご紹介</MenuItem>
          <MenuItem onClick={() => {
            navigate(`/UserDashbord/${user.uid}`)
          }} >ダッシュボード</MenuItem>
          <MenuItem onClick={() => {
            goGuide()
          }}>【重要】ご利用のルール</MenuItem>
          <MenuItem onClick={() => {
            goCreatorGuide()
          }} >クリエイター登録ガイド</MenuItem>
          <MenuItem onClick={() => {
            navigate('/tu')
          }} >チュートリアル</MenuItem>


          <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
          <Box sx={{
            marginTop: 10,
            display: 'flex',
            borderTop: 1,
            borderColor: "#CCCCCC"
          }}>
            <MenuItem onClick={toggleOpen}>閉じる</MenuItem>
          </Box>

        </Drawer >
        <AppBar position="static" className={classes.header}>
          <Toolbar>

            <Grid container alignItems="center" justify="end" spacing={3} sx={{ padding: 1 }} columns={{ xs: 12, sm: 12, md: 12 }}>
              <Grid item xs={6} sm={2} md={2} lg={2}>
                <Typography variant="h6" className={classes.applicationTitle} onClick={() => { navigate('/MainList') }}>
                  ワンフロ
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={4}>

              </Grid>
              <Grid item justify="flex-start" xs={12} sm={4} md={4} lg={4}>
                {/** Stripeアカウント系ボタン */}
                {creatorRegistrationButtonView()}
              </Grid>
              <Grid item xs={6} sm={1} md={1} lg={1}>
                <Button className={classes.headerBtn} color="inherit" variant="text" onClick={() => { navigate(`/NotificationList`) }}>
                  <NotificationsNoneIcon></NotificationsNoneIcon>
                  {newFlug && <p style={{ color: 'red' }}><b>●</b></p>}
                </Button>
              </Grid>


              <Grid item justify="flex-start" xs={6} sm={1} md={1} lg={1}>
                <IconButton edge="start" onClick={toggleOpen} className={classes.menuButton} color="inherit" aria-label="menu">
                  <MenuIcon />
                </IconButton>
              </Grid>

            </Grid>

          </Toolbar>

        </AppBar>

        <Container component="main" >
          {props.children}

        </Container>

        <Box sx={{
          marginTop: 10,
          padding: 2,
          display: 'flex',
          borderTop: 1,
          borderColor: "#CCCCCC",
        }}>
          <a href="#" onClick={() => navigate("/Kiyaku")} className={classes.link} > 利用規約</a>
          <a href="#" onClick={() => navigate("/Privacypolicy")} className={classes.link} > プライバシーポリシー</a>
          <a href="#" onClick={() => navigate("/Tokushoho")} className={classes.link} > 特定商取引法に基づく表記</a>

        </Box>
      </>

    );
  }

}
export default HeadFooter;

// シンプルにカラーを設計する手順
// コンポーネント自体の色は、MainとSubの色を使い、強調が必要な箇所はAccentを使う
//　上に載せられる（被らない）文字の色を labelとして定義しておく
// カラー選択は、muiのカラーパターンから３色のパターンを選ぶ

// muiのカラーパターン
// https://mui.com/customization/color/
// primary
//  Main 9575cd
//  sub aa90d7
//  accent 68518f
//  label fff
//
// secondary
//  Main ef5350
//  sub f27573
//  accent a73a38
//  label fff

// グラデーションの出し方
//https://developer.mozilla.org/ja/docs/Web/CSS/CSS_Images/Using_CSS_gradients
// 各コンポーネントのデザインはここ
const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  root: {
    flexGrow: 1,
  },
  // 各部分とその中身
  header: {
    background: 'linear-gradient(to bottom right,#00ccff,#0099ff)',
    color: "#fff"
  },
  menuButton: {
    marginRight: 10,
  },
  headerBtn: {
    marginRight: 10,
  },
  loginBtn: {
    color: "#00bcd4"
  },
  applicationTitle: {
    flexGrow: 1,
  },
  centerBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headContent: {
    margin: 5,
  },
  // Footer
  link: {
    margin: 5,
  },

  // コンテンツ部分
  // メイン
  button: {
    width: 200,
    borderRadius: 30,
    color: "#fff",
    background: "#f27573",
  }
  // サイドバー
}));
