import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import IfProxy from '../../IfProxy';
import IfProxyLimitedRequest from '../../IfProxyLimitedRequest';

import HeadFooter from '../HeadFooter';
import StraitLineSignupButton from '../../common/StraitLineSignupButton';

// Material UI
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';

// ReactGA Analytics
import ReactGA from "react-ga4";

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import Typography from '@mui/material/Typography';
import CardListView from "../../common/CardListView";
import TagInput from "../../common/TagInput";
import SnsUtil from "../../common/SnsUtil";


import YouTube from 'react-youtube';
import { useQRCode } from 'next-qrcode';


/**
 * ユーザ参照
 * @param  
 * @returns 
 */
const UserPop = (props) => {
  const [loading, setLoading] = useState(true);


  const [userDataId, setUserDataId] = useState("");


  const [mailLoginFormView, setMailLoginFormView] = useState(false);


  // QRコードからやってきた場合
  const [landingFlg, setLandingFlg] = useState(false);


  const [icon, setIcon] = useState("");
  const [heroImageView, setHeroImageView] = useState(); //表示するデータのソース、ローカル、またはサーバ

  const [displayName, setDisplayName] = useState("");

  // フォーム用
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");


  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();

  const ifProxy = new IfProxy();
  const ifProxyLimitedRequest = new IfProxyLimitedRequest();


  const { Canvas } = useQRCode();

  useEffect((props) => {
    //DEGUG
    //ifProxy.calcTermAvelage(user.uid)
    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    // ------

    loadData(params.id);
    if (params.landing !== undefined) {
      setLandingFlg(true)
    }

    setLoading(true)
  }, []);

  /**
   * uidでユーザを検索
   * uidは、画面へのパスパラメータから取得
   * @param {*} uid 
   */
  const loadData = (uid) => {
    const ifProxy = new IfProxy();
    ifProxy.getUserData(uid).then((result) => {
      setLoading(false);
      // paramsの値でユーザを検索

      setUserDataId(result.id)
      setIcon(result.icon);
      setHeroImageView(result.heroImageView)
      setDisplayName(result.displayName);
    })


  }

  // ACTION----------
  const signupButton = async (snsFlg) => {
    // サインアップチェック
    if (!user) {
      //
      const user = await saignupOrLogin(snsFlg)


      // ブックマークする
      await bookMarking(user)
    } else {
      alert('ログイン済みのためブックマークを実施します')
      // ブックマークする
      await bookMarking(user)
    }
    // 処理が終わったら
    alert('ブックマーク完了')
    // プロフィールへ送る navigate('User/param.id')
    navigate('/User/' + params.id)
  }

  const bookMarking = (user_) => {
    return new Promise(async (resolve, reject) => {
      // すでにしていたら何もしない
      console.log("=========ユーザを取得 :")
      console.log(user_);
      console.log("PARAMS")
      console.log(params.id)
      const bookmarked = await ifProxy.followCheck(user_.uid, params.id)
      console.log(bookmarked)

      // まだならブックマークを作成
      if (!bookmarked) {
        await ifProxy.addFollow(
          {
            uid: user_.uid,
            displayName: user_.displayName,
            photoURL: user_.photoURL,
          },
          {
            uid: params.id,
            displayName: displayName,
            icon: icon
          }

        )
      } else {
        console.log("ブックマーク、すでにあり")
      }

      resolve()
    })
  }

  const saignupOrLogin = (snsFlg) => {
    return new Promise(async (resolve, reject) => {

      console.log("saignupOrLogin")
      if (snsFlg === 1) {
        // twitterログイン
        const userFromSignup = await ifProxy.twitterSignup()
        resolve(userFromSignup)
      } else {
        // メールログイン
        // メアド、パスワード入力フォーム、登録ボタンを表示する
        // 新規登録またはサインアップ
        const resultLogin = await ifProxy.login(email, password)
        console.log("ログイン実行")
        console.log(resultLogin)
        console.log("メッセージ：" + resultLogin.error)
        if (resultLogin.code !== "NG") {
          console.log("すでにユーザあり。認証完了");
          console.log(resultLogin)
          resolve(resultLogin.user)

        } else {
          const resultSignup = await ifProxy.createUser(email, password)
          console.log("ユーザがなかったため新規作成実行")
          if (resultSignup.code === "NG") {
            setError("メールアドレスが誤っています");
            resolve();
          } else {

            const userDataCheck = await ifProxy.getUserData(resultSignup.uid)
            if (userDataCheck.length > 0) {
              reject("サインアップしたが、すでにuserDataがある。例外")
            }
            console.log("userDataを新規作成する")
            await ifProxy.updateUserData({
              uid: resultSignup.uid,//uid
              icon: "",//icon
              heroImageView: "",// ヒーローイメージのurl
              displayName: email,	//displayName
              profile: "",	//プロフィールテキスト
              status: "受付停止中",	//募集状況
              shareStance: false,
              genre: "",//強いジャンル
              onedro: "",	//やっているワンドロ
              onedroTagString: "",
              kikakuTags: "",
            })
            resolve(resultSignup)
          }


        }





        // 新規の場合は、必須のユーザ名を、メアド頭５桁で設定
        // TODO プロフィール画像は必須？ダミー入れなきゃダメ？
        // ユーザを返す

      }
    })
  }


  // VIEW--------


  const heroImage = () => {
    return (
      <>
        <img src={heroImageView}
          style={{
            width: '100%',
            height: '600px',
            objectFit: "cover",
            margin: "0 auto",
            opacity: 1,
            display: "block",
          }} />
      </>
    )
  }

  const forPrint = () => {
    return (
      <>
        {/* プロフィール */}
        <Grid item xs={6} sx={{ padding: '50px' }}>

          <Avatar sx={{ width: 100, height: 100 }} src={icon} />
          <h4>{displayName}</h4>

        </Grid>
        <Grid item xs={6} >

          QRコードから<br></br>一瞬でクリエイターのトップページへ
          <Canvas
            text={window.location.origin + "/User/" + params.id} // QRコードで表示される内容。省略できません。
            options={{ // ここの要素は省略可能。ご利用は計画的に。
              type: 'image/webp', // 出力フォーマット。 pngかjpegかwebpから選べる
              quality: 0.95, // クオリティ。 0〜１で1に近い方が綺麗。
              level: 'M', // 補正レベル。 low(L)、medium（Ｍ）、quartile(Ｑ)、high(H)から選べる。
              margin: 1, // クワイエットゾーンの広さを決める。
              scale: 4, // ドット1個につき何px使うかの指標。
              width: 150, // 出力画像の幅の指定。(収まらなかったら無視する)
              color: { // RGBAで色を決める。
                dark: '#0099ff',
                light: '#ffffff',
              },
            }}
          />

        </Grid>
        {/**--------------- サイトロゴ------------------- */}
        <Grid item xs={4} sx={{ height: '200px' }}>
          <Box sx={{
            margin: 10,

          }}>
            <img src={`${process.env.PUBLIC_URL}/logo_h2.png`}
              style={{
                width: '100px',
                border: 'solid',
                borderColor: '#0099ff',
                borderRadius: '20px'

              }} />
          </Box>


        </Grid>
        <Grid item xs={8} sx={{ color: '#0099ff', height: '200px' }}>
          <Box sx={{
            margin: 10
          }}>
            <h4>ワンドロをもっと楽しくする。<br></br>ワンドロ専門リクエストサイト</h4>
          </Box>
        </Grid>



        {/**---------------------------------- */}
        <Grid item xs={12} sx={{ margin: 10 }}>
          <hr></hr>
          <Button variant="outlined" fullWidth onClick={() => window.print()}>印刷する</Button>
        </Grid>

      </>
    )
  }

  const forLanding = () => {
    return (
      <>
        <Grid item xs={12} sx={{ color: '#0099ff', height: '200px' }}>
          <Box className={classes.contentWrapper}>
            <Box className={classes.centerBox}>
              <Box sx={{ margin: '20px' }}>
                <p>QRコードでアクセスしたユーザとワンクリックで繋がることができます</p>
              </Box>
              <Button variant="outlined" onClick={() => {
                navigate('/kiyaku')
              }}
                sx={{ borderRadius: '10px' }}>利用規約</Button>

              {/** ---------Twitter認証系 ----------- */}
              <Button
                variant="contained"
                disabled={mailLoginFormView}
                onClick={() => {
                  signupButton(1)
                }}
                sx={{ borderRadius: '100px', marginTop: '10px', height: '100px' }}>
                SNS認証でワンクリックで登録orログイン
                <br></br>＋
                <br></br>クリエイターをブックマークする
              </Button>

              {/**------- メール認証系---------- */}
              {!mailLoginFormView && <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setMailLoginFormView(true)
                }}
                sx={{ borderRadius: '100px', marginTop: '20px', height: '100px' }}>
                メール認証で登録orログイン
                <br></br>＋
                <br></br>クリエイターをブックマークする
              </Button>}
              <Box>

              </Box>
              {mailLoginFormView && <Box component="form" noValidate sx={{ mt: 1 }}>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <label htmlFor="loginform">
                  ユーザ名とパスワードを入力しログインしてください
                </label>
                <Box sx={{ mt: 1 }}>
                  <TextField
                    id="filled-multiline-flexible"
                    fullWidth
                    label="メールアドレス"
                    onChange={(event) => setEmail(event.target.value)}
                    variant="filled"
                    placeholder=""
                    value={email}
                    sx={{ marginTop: 1 }}
                  />
                  <TextField
                    id="filled-multiline-flexible"
                    fullWidth
                    label="password"
                    onChange={(event) => setPassword(event.target.value)}
                    variant="filled"
                    placeholder=""
                    value={password}
                    sx={{ marginTop: 1 }}
                  />
                  <Button
                    fullWidth
                    disabled={(false || email == "" || password == "")}
                    variant="contained"
                    color="primary"
                    onClick={() => signupButton(2)}
                    sx={{ mt: 3, mb: 2, marginTop: 5 }}
                  >
                    登録またはログイン
                  </Button>

                </Box>
              </Box>}

              <Typography sx={{ color: 'gray', margin: '20px', }}>※ 新規ユーザの場合利用規約に同意したものとしてサインアップいたします。<br></br>すでにサインアップ済みユーザはログインしこの作家さんをブックマークします</Typography>

            </Box>
          </Box>
        </Grid >

      </>
    )
  }


  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ

  const twitterShareUrl = "http://twitter.com/share?url=" + window.location.origin + '/UserPop/' + params.id + '/landing'

  if (loading) {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Box>
        </Box>
      </HeadFooter>
    )
  } else {
    if (landingFlg) {
      return (
        // ランディング用画面。QRコードを読み取ってくる画面はここ
        <Grid container alignItems="center" justify="center" >
          <img src={`${process.env.PUBLIC_URL}/logo_h2.png`}
            style={{
              width: '100px',
              border: 'solid',
              borderColor: '#0099ff',
              borderRadius: '20px',
              marginLeft: '20px'

            }} />
          {/**---------------------------------- */}
          <Box sx={{
            margin: 10
          }}>

            <h4>ワンドロをもっと楽しくする。<br></br>ワンドロ専門リクエストサイト</h4>
          </Box>

          {/* ヒーローイメージ */}
          <Grid item xs={12} >
            {heroImage()}
          </Grid>

          {/**---------------------------------- */}
          {forLanding()}
        </Grid >

      )
    } else {
      return (
        // プリント用画面。QRコードを見せるポップ画面はここ
        <Box sx={{ marginTop: 0, size: 'A4 portrait', margin: '0mm', top: '0', left: '0', width: '210mm', height: '297mm' }}>
          <Grid container alignItems="center" justify="center" >

            {/**---------------------------------- */}

            {/* ヒーローイメージ */}
            <Grid item xs={12} >
              {heroImage()}
            </Grid>

            {/**---------------------------------- */}
            {forPrint()}
          </Grid >
        </Box>

      )
    }
  }






}
export default UserPop;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",

  },
  // 個別コンテンツ　中央揃えで縦に内容を並べる
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  // 個別コンテンツ　横並びに内容を並べる
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },
  cardList: {
  },
  card: {
  },
  bottomCard: {
    width: 250
  },
}));
