import React, { useState, useEffect, useLayoutEffect, forwardRef, useRef, useCallback } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../../AuthContext';
import IfProxy from '../../../IfProxy';
import IfProxyTool from '../../../IfProxyTool';
import ConfigDetail from './ConfigDetail';


import HeadFooter from '../../HeadFooter';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

//graph
import StopWatch from "../../../common/graphs/StopWatch";
import { Scale } from "victory";

import DisplayAd from "../../../common/adsence/DisplayAd"


// ショートかっと
import hotkeys from 'hotkeys-js';
const Config = (props) => {
    const auth = getAuth();
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const classes = useStyles();
    const params = useParams();
    const location = useLocation();
    const ifProxy = new IfProxy();
    const ifProxyTool = new IfProxyTool();


    const [loading, setLoading] = useState(true);
    // RecordConfig
    const [recordConfig, setRecordConfig] = useState(false);


    let first = true;

    useLayoutEffect((props) => {


        // 【useEffectの２重処理について】
        // https://zenn.dev/takeharu/scraps/d14cf9d4239ec4
        // useEffectは２回描画される
        // mount -> unmount -> mount
        // 処理がこのようにダブルのは開発環境だけ
        if (first) {
            setLoading(true)
            // ReactGA Analytics ---
            ReactGA.initialize("G-2ZCXE08H2S");
            ReactGA.send({
                hitType: "pageview",
                // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
                page: location.pathname + location.search,
            });
            // ------
            loadData();
        }

        //    console.log(first ? "１回目である" : "２回目である")
        //    console.log("回数評価処理は以下")
        //    console.log("開発環境２重処理防止のため、１回目、２回目判定フラグ")
        //    console.log(first)
        first = false;
        //    console.log("２重登録判定フラグ、更新後")
        //    console.log(first)

        return () => {
            //      console.log("UNMOUNT 開発環境２重処理防止のため、１回目、２回目判定フラグ")
            //      console.log(first)
        }

    }, []);


    const loadData = async () => {
        let recordConfig = []
        if (user) {
            recordConfig = await ifProxyTool.getRecordConfig(user.uid);
            setRecordConfig(recordConfig)
        }

        setLoading(false)

    }

    if (loading) {

    } else {
        return (<>
            <Box className={classes.centerBox}>
                <ConfigDetail recordConfig={recordConfig[0]} recordTypeId="0"></ConfigDetail>
                <ConfigDetail recordConfig={recordConfig[1]} recordTypeId="1"></ConfigDetail>
                <ConfigDetail recordConfig={recordConfig[2]} recordTypeId="2"></ConfigDetail>
            </Box >

        </>)
    }

}
export default Config;



const style = {
    scoreLabel: {
        fontSize: 25,

    },
}

const useStyles = makeStyles((theme) => ({
    //オーバーレイ
    overlay: {
        zIndex: 10,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },//オーバーレイの中のモーダル
    modalView: {
        zIndex: 10,
        borderRadius: 15,
        width: '50%',
        padding: '1em',
        background: '#fff',
    },
    // 全体レイアウト
    contentWrapper: {
        marginTop: 20,
        flexDirection: 'column',
        alignItems: 'center',
    },
    mainBox: {
        maxWidth: "1000px",
        margin: "0 auto",

    },
    // グリッド箱の書式 
    gridPadding: {
        padding: 5,
        border: '2px solid',
        borderColor: '#a6d4fa',
        backgroundColor: 'linear-gradient(to bottom right,#a6d4fa,#1976d2)',
        borderRadius: '10'

    },
    // グリッド箱の書式 枠線なし
    gridPaddingNoborder: {
        padding: 5,


    },
    // 個別コンテンツ　中央揃えで縦に内容を並べる
    centerBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },// 個別コンテンツ　中央揃えで横に内容を並べる
    centerFlatBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: 10,
        alignItems: 'center',
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftCenterBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftRowBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'left',
    },
    // 個別コンテンツ　横並びに内容を並べる
    leftBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: "0 auto",
    },

    // モーダルダイアログのフォーム　個別アイテム
    modalFormItem: {
        margin: 20,
    },
    //サンプルのグラフエリア
    sampleGraphEria: {
        width: 200
    },

    cardList: {
    },
    card: {
    },
    bottomCard: {
        width: 250
    },
}));

