import React, { useState, useEffect, useLayoutEffect, forwardRef, useRef, useCallback } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../../AuthContext';
import IfProxy from '../../../IfProxy';
import IfProxyTool from '../../../IfProxyTool';


import HeadFooter from '../../HeadFooter';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

//graph
import StopWatch from "../../../common/graphs/StopWatch";
import { Scale } from "victory";

import DisplayAd from "../../../common/adsence/DisplayAd"


// ショートかっと
import hotkeys from 'hotkeys-js';
const ConfigDetail = (props) => {
    const auth = getAuth();
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const classes = useStyles();
    const params = useParams();
    const location = useLocation();
    const ifProxy = new IfProxy();
    const ifProxyTool = new IfProxyTool();


    const [loading, setLoading] = useState(true);
    // RecordConfig
    const [recordConfig, setRecordConfig] = useState(false);


    let first = true;

    useLayoutEffect(() => {

        // 【useEffectの２重処理について】
        // https://zenn.dev/takeharu/scraps/d14cf9d4239ec4
        // useEffectは２回描画される
        // mount -> unmount -> mount
        // 処理がこのようにダブルのは開発環境だけ
        if (first) {
            setLoading(true)
            // ReactGA Analytics ---
            ReactGA.initialize("G-2ZCXE08H2S");
            ReactGA.send({
                hitType: "pageview",
                // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
                page: location.pathname + location.search,
            });

            // ------
            loadData();
        }

        //    console.log(first ? "１回目である" : "２回目である")
        //    console.log("回数評価処理は以下")
        //    console.log("開発環境２重処理防止のため、１回目、２回目判定フラグ")
        //    console.log(first)
        first = false;
        //    console.log("２重登録判定フラグ、更新後")
        //    console.log(first)

        return () => {
            //      console.log("UNMOUNT 開発環境２重処理防止のため、１回目、２回目判定フラグ")
            //      console.log(first)
        }

    }, []);


    const loadData = async () => {
        let recordConfig = null

        if (user) {
            recordConfig = props.recordConfig
        }


        if (recordConfig) {
            setRecordConfig(recordConfig);
            console.log("PROPS")
            console.log(props.recordConfig)
            setRecordConfig((prv) => ({ ...prv, recordTypeId: props.recordTypeId }))


        } else {
            setRecordConfig({
                id: "",
                uid: user?.uid,
                recordTypeId: props.recordTypeId,
                configName,
                step1Name: "ラフ",
                step2Name: "ペン入れ",
                step3Name: "塗り",
                step4Name: "仕上げ",
                step5Name: "",
                step6Name: "",
                step7Name: "",
                step8Name: "",
                step9Name: "",
                step10Name: "",
                step1Time: 10,
                step2Time: 15,
                step3Time: 30,
                step4Time: 5,
                step5Time: null,
                step6Time: null,
                step7Time: null,
                step8Time: null,
                step9Time: null,
                step10Time: null,
                memoForNext: null,
                memo: "",
            });

        }
        setLoading(false)
    }


    const updateRecordConfig = () => {
        ifProxyTool.addOrUpdateRecordConfig(recordConfig, recordConfig).then(() => {
            navigate('/v2/OnedroTimer')

        })
    }

    const handleConfigName = (event => {
        setRecordConfig((prv) => ({ ...prv, configName: event.target.value }))
    })
    const handleStep1Name = (event) => {
        setRecordConfig((prv) => ({ ...prv, step1Name: event.target.value }))
    }
    const handleStep2Name = (event) => {
        setRecordConfig((prv) => ({ ...prv, step2Name: event.target.value }))
    }
    const handleStep3Name = (event) => {
        setRecordConfig((prv) => ({ ...prv, step3Name: event.target.value }))
    }
    const handleStep4Name = (event) => {
        setRecordConfig((prv) => ({ ...prv, step4Name: event.target.value }))
    }
    const handleStep5Name = (event) => {
        setRecordConfig((prv) => ({ ...prv, step5Name: event.target.value }))
    }
    const handleStep6Name = (event) => {
        setRecordConfig((prv) => ({ ...prv, step6Name: event.target.value }))
    }
    const handleStep7Name = (event) => {
        setRecordConfig((prv) => ({ ...prv, step7Name: event.target.value }))
    }
    const handleStep8Name = (event) => {
        setRecordConfig((prv) => ({ ...prv, step8Name: event.target.value }))
    }
    const handleStep9Name = (event) => {
        setRecordConfig((prv) => ({ ...prv, step9Name: event.target.value }))
    }
    const handleStep10Name = (event) => {
        setRecordConfig((prv) => ({ ...prv, step10Name: event.target.value }))
    }
    const handlememo = (event) => {
        setRecordConfig((prv) => ({ ...prv, memo: event.target.value }))
    }

    /**
 * nullは0にする。文字は数値にする
 * @param {*} param 
 * @returns 
 */
    const psi = ((param) => {
        return param - 0
    })
    let {
        id,
        uid,
        recordTypeId,
        configName,
        step1Name,
        step2Name,
        step3Name,
        step4Name,
        step5Name,
        step6Name,
        step7Name,
        step8Name,
        step9Name,
        step10Name,
        step1Time,
        step2Time,
        step3Time,
        step4Time,
        step5Time,
        step6Time,
        step7Time,
        step8Time,
        step9Time,
        step10Time,
        memoForNext,
        memo,
    } = recordConfig;

    return (<>
        <Box className={classes.centerBox}>
            <Typography>タイマー名</Typography>
            <Box className={classes.leftBox}>
                <Typography
                    sx={{ paddingTop: 1 }}>
                    {configName}
                </Typography>
                <TextField margin="nomal" id="filled-basic" size="small"
                    inputProps={{ maxLength: 20 }}
                    onChange={handleConfigName} variant="outlined" type="string" value={configName}
                />

            </Box>
            <b>合計タイム:{psi(step1Time) + psi(step2Time) + psi(step3Time) + psi(step4Time) + psi(step5Time) + psi(step6Time) + psi(step7Time) + psi(step8Time) + psi(step9Time) + psi(step10Time)} 分</b>
            <ConfigInputForm stepName="Step1" classes={classes} nameHandler={handleStep1Name} stepNameValue={step1Name} timeValue={recordConfig.step1Time} placeHolder="ラフ" timeReturn={(value) => setRecordConfig((prv) => ({ ...prv, step1Time: value }))} />
            <ConfigInputForm stepName="Step2" classes={classes} nameHandler={handleStep2Name} stepNameValue={step2Name} timeValue={recordConfig.step2Time} placeHolder="ペン入れ" timeReturn={(value) => setRecordConfig((prv) => ({ ...prv, step2Time: value }))} />
            <ConfigInputForm stepName="Step3" classes={classes} nameHandler={handleStep3Name} stepNameValue={step3Name} timeValue={recordConfig.step3Time} placeHolder="マスク" timeReturn={(value) => setRecordConfig((prv) => ({ ...prv, step3Time: value }))} />
            <ConfigInputForm stepName="Step4" classes={classes} nameHandler={handleStep4Name} stepNameValue={step4Name} timeValue={recordConfig.step4Time} placeHolder="塗り" timeReturn={(value) => setRecordConfig((prv) => ({ ...prv, step4Time: value }))} />
            <ConfigInputForm stepName="Step5" classes={classes} nameHandler={handleStep5Name} stepNameValue={step5Name} timeValue={recordConfig.step5Time} placeHolder="仕上げ" timeReturn={(value) => setRecordConfig((prv) => ({ ...prv, step5Time: value }))} />
            <ConfigInputForm stepName="Step6" classes={classes} nameHandler={handleStep6Name} stepNameValue={step6Name} timeValue={recordConfig.step6Time} placeHolder="-" timeReturn={(value) => setRecordConfig((prv) => ({ ...prv, step6Time: value }))} />
            <ConfigInputForm stepName="Step7" classes={classes} nameHandler={handleStep7Name} stepNameValue={step7Name} timeValue={recordConfig.step7Time} placeHolder="-" timeReturn={(value) => setRecordConfig((prv) => ({ ...prv, step7Time: value }))} />
            <ConfigInputForm stepName="Step8" classes={classes} nameHandler={handleStep8Name} stepNameValue={step8Name} timeValue={recordConfig.step8Time} placeHolder="-" timeReturn={(value) => setRecordConfig((prv) => ({ ...prv, step8Time: value }))} />
            <ConfigInputForm stepName="Step9" classes={classes} nameHandler={handleStep9Name} stepNameValue={step9Name} timeValue={recordConfig.step9Time} placeHolder="-" timeReturn={(value) => setRecordConfig((prv) => ({ ...prv, step9Time: value }))} />
            <ConfigInputForm stepName="Step10" classes={classes} nameHandler={handleStep10Name} stepNameValue={step10Name} timeValue={recordConfig.step10Time} placeHolder="-" timeReturn={(value) => setRecordConfig((prv) => ({ ...prv, step10Time: value }))} />

            <Box className={classes.centerBox}>
                <Typography
                    sx={{ paddingTop: 1 }}>
                    作業メモ：次回のワンドロのためにメモしておきたい設定情報等
                </Typography>
                <TextField margin="nomal" id="filled-basic" size="small"
                    fullWidth
                    inputProps={{ maxLength: 20 }}
                    onChange={handlememo} multiline rows={4} variant="outlined" type="string" value={memo}
                />
            </Box>

            <Box className={classes.centerBox}>
                <Button
                    color='warning'
                    variant="contained"
                    disabled={!user}
                    onClick={() => {
                        updateRecordConfig();

                    }}>
                    タイマー設定変更{!user ? "【要ログイン】" : ""}
                </Button>

            </Box>


        </Box >

    </>)
}
export default ConfigDetail;



const style = {
    scoreLabel: {
        fontSize: 25,

    },
}

const useStyles = makeStyles((theme) => ({
    //オーバーレイ
    overlay: {
        zIndex: 10,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },//オーバーレイの中のモーダル
    modalView: {
        zIndex: 10,
        borderRadius: 15,
        width: '50%',
        padding: '1em',
        background: '#fff',
    },
    // 全体レイアウト
    contentWrapper: {
        marginTop: 20,
        flexDirection: 'column',
        alignItems: 'center',
    },
    mainBox: {
        maxWidth: "1000px",
        margin: "0 auto",

    },
    // グリッド箱の書式 
    gridPadding: {
        padding: 5,
        border: '2px solid',
        borderColor: '#a6d4fa',
        backgroundColor: 'linear-gradient(to bottom right,#a6d4fa,#1976d2)',
        borderRadius: '10'

    },
    // グリッド箱の書式 枠線なし
    gridPaddingNoborder: {
        padding: 5,


    },
    // 個別コンテンツ　中央揃えで縦に内容を並べる
    centerBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },// 個別コンテンツ　中央揃えで横に内容を並べる
    centerFlatBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: 10,
        alignItems: 'center',
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftCenterBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftRowBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'left',
    },
    // 個別コンテンツ　横並びに内容を並べる
    leftBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: "0 auto",
    },

    // モーダルダイアログのフォーム　個別アイテム
    modalFormItem: {
        margin: 20,
    },
    //サンプルのグラフエリア
    sampleGraphEria: {
        width: 200
    },

    cardList: {
    },
    card: {
    },
    bottomCard: {
        width: 250
    },
}));


// 設定画面の入力フォームの共通か
const ConfigInputForm = ({
    stepName,
    classes,
    nameHandler,
    stepNameValue,
    placeHolder,
    timeReturn = 0,
    timeValue
}) => {
    return (
        <Box className={classes.leftBox}>
            <Typography
                sx={{ paddingTop: 1 }}>
                {stepName}
            </Typography>
            <TextField margin="nomal" id="filled-basic" size="small"
                inputProps={{ maxLength: 20 }}
                placeholder={placeHolder}
                onChange={nameHandler} variant="outlined" type="string" value={stepNameValue}
            />
            <TextField margin="nomal" id="filled-basic" size="small"
                inputProps={{ maxLength: 2 }}
                placeholder="目標タイム(分)"
                onChange={(event) =>
                    timeReturn(event.target.value)
                }
                variant="outlined" type="number" value={timeValue}
            />
        </Box>
    )
}