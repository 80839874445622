import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import IfProxy from './../../IfProxy';
import SwipeDataObject from './SwipeDataObject';
import SwipeDetail from './SwipeDetail';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';



const cardHeight = 400;
const cardMargin = 5;

/**
 * リクエスト一覧などで表示するリクエスト情報をまとめたカード
 * @property { Array.<SwipeDataObject>}
 * @returns 
 * listData [] 表示するオブジェクト配列 オブジェクトは、対応カードコンポーネントの属性を持つ
 * type request/account/Product/limitedRequest
 * listDiscription リストの意味についての説明
 * 
 * <要件>
 * 
 * 
 * 
 */
const SwipeMaster = ({ swipeDataObjectList }) => {
    const ifProxy = new IfProxy();





    const slideContent = () => {
        if (!swipeDataObjectList || swipeDataObjectList.length < 1) {
            return "投稿データはありません";
        }

        return swipeDataObjectList.map((swipeDataObject, index, list) => (
            <SwiperSlide>
                <SwipeDetail swipeDataObject={swipeDataObject}></SwipeDetail>
            </SwiperSlide>
        ))

    }
    if (!swipeDataObjectList || swipeDataObjectList.length < 1) {
        return (
            <p textAlign='center' style={{ margin: '0px', padding: '20px', background: 'lightGray' }}> まだ投稿データはありません</p >
        )
    }

    return (
        <>
            <Swiper
                style={{ minHeight: '120vh', width: '100vw', maxWidth: '1200px', background: 'black' }}
                className="sample-slider"
                modules={[Navigation]}
                navigation
            >

                {slideContent()}

            </Swiper>

        </>
    )
}

export default SwipeMaster;


