import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';


const cardHeight = 400;
const cardMargin = 5;

/**
 * リクエスト一覧などで表示するリクエスト情報をまとめたカード
 * @property { id,fromName,toName, thema, tochu,anserLimit,dereveryLimit,status,agree,share }
 * @returns 
 * 
 * type :未受諾/受諾/完了/キャンセル/作家判断キャセル
 * 
 * <要件>
 * リクエスト本文
 * リクエスト詳細画面に遷移する
 * 環境に応じて幅を調整する
 * propで受け取ったリクエストデータで構成する
 * <やらないこと>
 * 幅（親コンポーネントの幅に合わせる)
 * データ準備　親がmapで注入
 * 
 *<リクエストデータ>
    id	id
    テーマ	thema (200文字)
    途中でも受容	tochu
    報酬	reward
    ワンドロ名タグ	onedroTag
    タグ	tag
    クローズリクエスト	anonimous
    回答期日	anserLimit
    納品期日	dereveryLimit
    Type	status
    リクエスト賛同数	agree
    シェア賛同数	share
    fromUid	fromUid
    fromName	fromName
    toUid	toUid
    toName	toName
    createdAt	createdAt
    updatedAt	updatedAt
 * 
 */
const RequestCard = ({ id, fromName, toName, thema, tochu, anserLimit, dereveryLimit, status, agree, share }) => {

    const navigate = useNavigate();
    const classes = useStyles();


    const onClickCard = (id) => {
        console.log("カードがクリックされた")
        console.log(id);
        navigate('/Request/' + id);
    }

    const statusView = () => {
        switch (status) {
            case "unentrusted": //未受諾
                return (
                    <Typography color="text.primary" gutterBottom>リクエスト中</Typography>
                );
                break;
            case "entrusted": //受諾
                return (
                    <Typography color="text.primary" gutterBottom>受諾済み</Typography>
                );
                break;

            case "completed"://完了　未決済
                return (
                    <Typography color="red" gutterBottom>納品完了 決済待ち</Typography>
                );
                break;
            case "paied"://支払い済み
                return (
                    <Typography color="text.primary" gutterBottom>支払い完了</Typography>
                );
                break;

            case "requestCanceled"://キャンセル
                return (
                    <Typography color="text.primary" gutterBottom>依頼者キャンセル済み</Typography>
                );
                break;

            case "creatorCanceled"://作家判断キャンセル
                return (
                    <Typography color="text.primary" gutterBottom>作家判断キャンセル済み</Typography>
                );
                break;
            default:
                return (
                    <Typography color="red" gutterBottom>リクエスト中</Typography>
                );

        }
    }

    const createCard = () => {
        // id,fromName,toName, thema, tochu,anserLimit,dereveryLimit,status,agree,share
        return (

            <Grid item key={id}
            >
                <Card className={classes.card} onClick={() => onClickCard(id)}>
                    <CardContent>
                        <Typography sx={{ fontWeight: "bold", fontSize: 14 }} color="text.primary" gutterBottom>
                            リクエスト
                        </Typography>
                        <Typography sx={{ fontSize: 30 }} color="text.primary" gutterBottom>
                            {thema}
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Box sx={{ flex: 1 }}>
                                <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
                                    依頼者　{fromName}
                                </Typography>
                                <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
                                    依頼先　{toName}
                                </Typography>

                                <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
                                    回答期日： {(status === "unentrusted") ? anserLimit : "回答済み"}<br></br>
                                    納品期日： {!(status === "unentrusted") ? dereveryLimit : "受諾してから7日後が設定されます"}
                                </Typography>
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
                                    見たい！： {agree}<br></br>
                                    描いたらシェアする： {share}
                                </Typography>
                                <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
                                    未完成でも許容： {tochu ? "○" : "×"}
                                </Typography>
                            </Box>
                        </Box>




                        {statusView()}

                    </CardContent>
                </Card>
            </Grid>
        )
    }
    return (
        <>
            {createCard()}
        </>
    )
}

export default RequestCard;


const useStyles = makeStyles((theme) => ({

    card: {

        maxWidth: "700px",
        minWidth: "500px",
        //height: cardHeight,
        margin: cardMargin,
    },



}));
