import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from './../../AuthContext';

// TODO: StandardPageはパスが異なるため注意
import IfProxy from './../../IfProxy';
import HeadFooter from './../HeadFooter';
import SnsUtil from './../../common/SnsUtil';
import AccountCard from './../../common/AccountCard';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { createTheme, ThemeProvider } from '@mui/material/styles';





const NotificationList = (props) => {

  const [listItems, setListItems] = useState(0);

  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const ifProxy = new IfProxy();

  const location = useLocation();

  useEffect((props) => {
    loadData(props);
    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    // ------
  }, []);


  const loadData = (props) => {

    // データコール
    ifProxy.getNotification(user.uid).then((result) => {

      let listItems = result.map((item) =>
        createCard(item)
      );
      setListItems(listItems);
    })
  }
  const updateRead = () => {

    // データコール
    ifProxy.readNotification(user.uid).then((result) => {
      window.location.reload()
    }).catch((e) => {
      console.log("readNotification 呼び出し失敗")
      console.log(e)
    })
  }

  //受諾/ 納品あり/
  // 入金/ キャンセル/ 
  //作家キャンセル/ タイトル獲得/ファボ/ フォロー/ システム通知
  const goDetail = (type, fromUid, targetId) => {
    //通知の種別ごとにurlを構成する
    switch (type) {
      case "request": // リクエスト
      case "jyudaku": // 受諾されたことを発注者に通知
      case "cancel": //発注間せるを通知
      case "creatorCancel": //クリエイター失敗、キャンセルを通知
      case "nyukin": //入金されたことを通知
        navigate('/Request/' + targetId); // 上記の場合はリクエスト詳細画面へ遷移
        break;


      case "nohin": //納品したことを通知
        navigate('/Product/' + targetId); // 上記の場合は作品詳細画面へ遷移
        break;

      case "lim": //限定リクエスト系したことを通知
        navigate('/LimitedRequest/' + targetId); // 上記の場合は作品詳細画面へ遷移
        break;

      case "title": //称号獲得を通知
        navigate('/User/' + user.uid);
        break;
      case "fav": //興味がある通知があったことを通知
        navigate('/User/' + fromUid);
        break;
      case "follow": //フォローされたことを通知
        navigate('/User/' + fromUid);
        break;
      case "odai": //フォローされたことを通知
        navigate('/OdaiTeianList/' + targetId);
        break;
      case "system": //システム通知
        break;
      default:
    }
  }


  /**
   * 
   * @param {*} param0 
   * @returns 
   * type 
   * request リクエストあり/
   * jyudaku 受諾/
   * nohin 納品あり/
   * nyukin 入金/
   * cancel キャンセル/
   * creatorCancel 作家キャンセル/
   * title タイトル獲得/
   * favファボ/
   * follow フォロー/
   * system システム通知
   * 
   */
  const createCard = ({ read, fromIcon, text, type, fromUid, targetId }) => {
    return (
      <Card sx={{ minWidth: 275, margin: 5 }}>
        <CardContent>
          {!read && <p style={{ color: 'red' }}>新着</p>}


          <Avatar sx={{ width: 100, height: 100 }} src={fromIcon} />
          <Button variant="outlined" onClick={() => goDetail(type, fromUid, targetId)}>確認する</Button>

          <Typography variant="body2">
            {text}
          </Typography>
        </CardContent>
      </Card>
    )
  }




  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else {

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox} >
            <h1>通知一覧</h1>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              あなたが受け取ったお知らせ
            </Typography>
            <Button variant="outlined" onClick={() => updateRead()}>既読にする</Button>
            {listItems}
          </Box>
        </Box>

      </HeadFooter >

    );
  }




}
export default NotificationList;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",
  },
  cardList: {
  },
  card: {
  },
}));
