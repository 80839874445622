import React, { useState, useEffect } from "react";
import { useAuthContext } from '../../AuthContext';
import HeadFooter from '../HeadFooter';
import Typography from '@mui/material/Typography';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';

import IfProxy from './../../IfProxy';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CardListView from './../../common/CardListView';
import ImageShareBtn from './../../common/ImageShareBtn';

// GA4
import ReactGA from "react-ga4";


// Material UI
import { makeStyles } from '@material-ui/core/styles';


import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// TODO 公開前の事前アンケートLPを表示するためだけのバージョン
const DEBUG_VERSION = true;
const imageHeight = 250;


const Lp = (props) => {
  const { user } = useAuthContext();
  const classes = useStyles();
  const navigate = useNavigate();

  const [detailOpen, setDetailOpen] = useState(false);
  const [nsfwFlg, setNsfwFlg] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenText, setIsOpenText] = useState(false);

  const [cyumokuProduct, setCyumokuProduct] = useState([]);
  const [loading, setLoading] = useState(true);

  const location = useLocation();


  const ifProxy = new IfProxy();


  useEffect((props) => {
    setLoading(true)
    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    ifProxy.gaEventTest("showLp")
    // ------
    loadData()

    return () => {
    }

  }, []);

  const clickSignup = () => {
    navigate('/login');
    //pv直帰しなかった数
  }

  /**
   * アンケートへの遷移
   */
  const goAnkateForCreator = () => {
    //ifProxy.ancateClickCreator();Firebaseセキュリティルールをうまく通せず、一旦停止
    window.open('https://forms.gle/4mJBC9ed4rcQtctp8');
  }
  const goAnkateForUser = () => {
    //ifProxy.ancateClickUser();Firebaseセキュリティルールをうまく通せず、一旦停止
    window.open('https://forms.gle/k9CyUatKESK6iAyE9');
  }

  const loadData = () => {
    // ============　サンプルとして表示するMainのリスト用　============
    /** リスト用 */
    ifProxy.getCyumokuProduct(5).then((result) => {

      setCyumokuProduct(result)
      setLoading(false)

    })

  }


  const showDetailView = () => {
    return (
      <>
        <Box className={classes.leftAlignBox} >
          <Typography variant="h5" component="div" sx={style.h3Text}>
            <b> ルールとマナー </b>
          </Typography>
          <Typography variant="h5" component="div" sx={style.bodyText}>
            クリエイター様、リクエストユーザー様が気持ちよくご利用いただけるよう。以下のルールをお守りください
          </Typography>
        </Box>

        <Box className={classes.leftAlignBox} >
          <Typography variant="h5" component="div" sx={style.h3Text}>
            <b> 本サービスの特殊な点 </b>
          </Typography>
          <Typography variant="h5" component="div" sx={style.bodyText}>
            一般的なコミッションとは次の点で異なります<br></br>
            <br></br>
            ワンドロ作品のリクエストである<br></br>
            制作の請負より、作家様の練習や普段のワンドロに対して、リクエストと応援をしている感覚になります<br></br><br></br>

            完成が確約されない<br></br>
            1時間の作業時間でできた範囲で納品されます。最悪の場合ラフの状態で時間切れとなり、納品される可能性もございます（ですので、過去の実績をよくご確認ください）<br></br><br></br>

            報酬は現金、または拡散数<br></br>
            リクエスト利用者は、オファー金額を提示してリクエストをする。または、もし完成したらどれくらいSNSで拡散を協力する。という提示を行うことができます。<br></br>
            作家様はこれらの提示オファーを見て、どのリクエストを受けるかを自由に判断することができます

          </Typography>
        </Box>


        <Box className={classes.leftAlignBox} >
          <Typography variant="h5" component="div" sx={style.h3Text}>
            これより、実際の利用の流れを見ながら、ルールのご説明をいたします<br></br>
            作家様、リクエスト利用者様、双方ともそれぞれの立場のルールをご理解ください
            <br></br><br></br><br></br><br></br>
            たった４ステップになりますので<br></br>
            少しだけお付き合いください
          </Typography>
        </Box>




        <Box className={classes.leftAlignBox} >
          <Typography variant="h5" component="div" sx={style.h3Text}>
            <b>リクエスト利用者さまが、リクエストする作家様を選ぶ </b>
          </Typography>
          <Box className={classes.leftBox} >
            <img src={`${process.env.PUBLIC_URL}/g1.png`}
              style={{
                width: '50%',
                maxWidth: "500px",
                margin: "0 auto",
                opacity: 0.8,
                display: "block",
              }} />
            <Typography variant="h5" component="div" sx={style.bodyText}>
              リクエストする利用者は、気に入ったクリエイターのプロフィールを見ます<br></br>
              ここで、ワンドロ（１時間）でどれくらいのクオリティなのか。<br></br>
              成功率はどれくらいかをチェックしてください。<br></br>
              <br></br><br></br>
              リテイクが仕組み上できません。作家様がワンドロに失敗しラフの状態で終わってしまった場合はそのまま納品される可能性がございます<br></br>
              ですので、過去のワンドロの成果や、成功率をご確認の上、リクエスト金額などご判断ください

            </Typography>
          </Box>
        </Box>




        <Box className={classes.leftAlignBox} >
          <Typography variant="h5" component="div" sx={style.h3Text}>
            <b> リクエスト利用者さまが、リクエストをする </b>
          </Typography>

          <Box className={classes.leftBox} >
            <img src={`${process.env.PUBLIC_URL}/g2.png`}
              style={{
                width: '50%',
                maxWidth: "500px",
                margin: "0 auto",
                opacity: 0.8,
                display: "block",
              }} />

            <Typography variant="h5" component="div" sx={style.bodyText}>
              リクエスト利用者はリクエストを行います<br></br><br></br>
              オーダー内容を文章で提示します<br></br>
              作家様はワンドロで実施しますため詳細な仕様は対応できない可能性が高いです。あくまでも「ワンドロのテーマ」という意識でオーダーください<br></br><br></br><br></br>

              未完成だったら入金しない<br></br><br></br>
              リクエスト利用者はリクエスト時に次のオプションを選び、作家様へ伝えることができます<br></br>
              「未完成だったら納品不要」<br></br>
              「未完成の場合できたところまでで納品して欲しい」<br></br><br></br>
              ※ 未完成かどうかは作家様が判断します。<br></br><br></br><br></br>

              作家様はこれら上記を踏まえて、受諾するかどうか、また終了時に納品するかどうかを判断します。また<br></br>
              納品がされた場合、リクエスト利用者はオファー時に提示した料金の支払いをする必要があります<br></br>
              納品がされなかった場合(作家がキャンセルした場合)、リクエスト利用者は支払いをする必要はございません<br></br>

            </Typography>
          </Box>
        </Box>

        <Box className={classes.leftAlignBox} >
          <Typography variant="h5" component="div" sx={style.h3Text}>
            <b> 作家様が、リクエストを受ける </b>
          </Typography>
          <Box className={classes.leftBox} >
            <img src={`${process.env.PUBLIC_URL}/g3.png`}
              style={{
                width: '50%',
                maxWidth: "500px",
                margin: "0 auto",
                opacity: 0.8,
                display: "block",
              }} />
            <Typography variant="h5" component="div" sx={style.bodyText}>
              リクエストを受けてから受諾するまでは１週間程度が期限です<br></br>
              リクエストを受諾してから１週間程度が納品期限となります<br></br><br></br>
              納品までに要した期間の平均はプロフィール上に掲載されアピール材料となります<br></br><br></br>
              ※ 例外として「無料限定リクエスト」は期限設定はございません。気軽なお題投稿やリクエスト募集にご利用ください

            </Typography>
          </Box>
        </Box>

        <Box className={classes.leftAlignBox} >
          <Typography variant="h5" component="div" sx={style.h3Text}>
            以下、トラブルを防ぐために特に重要なルールになります<br></br><br></br>
            作家様、リクエストをされる方双方とも理解・合意の上、リクエストを実施してください
          </Typography>
        </Box>

        <Box className={classes.leftAlignBox} >
          <Typography variant="h5" component="div" sx={style.h3Text}>
            <b> 作家様が、制作する。納品する </b>
          </Typography>
          <Box className={classes.leftBox} >
            <img src={`${process.env.PUBLIC_URL}/g4.png`}
              style={{
                width: '50%',
                maxWidth: "500px",
                maxHeight: "300px",
                margin: "0 auto",
                opacity: 0.8,
                display: "block",
              }} />
            <Typography variant="h5" component="div" sx={style.bodyText}>
              ワンドロに失敗した場合<br></br><br></br>
              作業時間が１時間を超過した場合<br></br>
              作業時間の計測などは作家様ご自身の管理となりますため<br></br>
              多少の超過については作家様判断で許容していただいて問題ございません。<br></br>
              ワンドロというルールの意図としましては、「作業負担を１時間作業に抑え込む」ことになります<br></br>
              ・１時間作業に限られていることにより負担から作家様を守る<br></br>
              ・作家様の負担を強いないリクエストだから気軽に依頼できる。<br></br>
              という趣旨が守れていましたら問題ございません<br></br><br></br><br></br>

              リテイクは不要<br></br><br></br>
              作家様に限らずリクエスト利用者様にもご理解いただきた重要なポイントとして、ワンドロに失敗した場合、リテイクやリカバリのための作業は不要としています。<br></br>
              失敗リスクがあり、品質も１時間作業である。ということを合意した上でのリクエストであることを双方ともにご理解ください<br></br><br></br><br></br>

              リクエストが「失敗時は納品不要」オプションが選択されている場合<br></br><br></br>
              作家様は、作家様都合としてリクエスト受諾をキャンセルすることが可能です。この場合リクエスト依頼者は料金のお支払いが免除されます<br></br>
              また、作家様が完成であると判断して納品した場合は、お支払いは必要となります。<br></br>
              リクエストが「未完成の場合できたところまでで納品して欲しい」オプションが選択されている場合<br></br>
              作家様は納品を行い、リクエスト利用者はお支払いが必要となります<br></br><br></br><br></br><br></br>

              納品後にリクエスト利用者がお支払いをしなかった場合<br></br><br></br>
              リクエストのステータスは「お支払い待ち」ステータスのままとなります<br></br>
              また、リクエスト利用者様がお支払い待ちステータスのリクエストを何件持っているかはプロフィール画面に掲載されます<br></br>
              作家様はリクエストの受諾前に、リクエスト利用者様の未入金件数を確認の上、受諾の可否を判断することが可能です<br></br>

            </Typography>
          </Box>
        </Box>

        <Box className={classes.leftAlignBox} >
          <Typography variant="h5" component="div" sx={style.h3Text}>
            <b> ご利用のコツ </b>
          </Typography>
          <Typography variant="h5" component="div" sx={style.bodyText}>
            リクエスト利用者様へ。リクエストを受けてもらうコツ<br></br>
            リクエストは、「賛同数」を投票するボタンがああります
            この投票数は匿名です。<br></br>
            この「賛同数」の多さは、リクエストの注目（完成したら絵が見たいと思っている人の数）を示すことができます<br></br>
            「賛同数」を増やすためには、ご自身のリクエストを、SNS上にシェアする。などして、共感する閲覧者を増やすことで獲得が可能です<br></br><br></br>

            また、「描いたらシェアする」ボタンも効果的です<br></br><br></br>
            せっかく描いたイラストを、作家様は多くに見てもらいたいと思っていることが多いです。<br></br>
            もし完成したらシェアしてくれる人がどれくらいいるのかがわかることは、作家様のモチベーションに繋がり、リクエストの魅力が向上します<br></br><br></br>
            また、描いたらシェアするボタンは、作品が納品された時に、それを押した人に通知しシェアを促すことができます<br></br><br></br><br></br><br></br>

            作家様へ。喜ばれるリクエストを選ぶコツ<br></br>
            上述と逆になりますが、賛同数や、描いたらシェアするボタンから、そのリクエストがどれくらい多くの人が求めているかを確認することができます。<br></br><br></br>
            気になるリクエストがありましたら、それを作家様自身のSNSタイムライン等に投稿して、作家様のファンのニーズ感を測ってみることも効果的だと考えられます<br></br>


          </Typography>
        </Box>
      </>
    )
  }



  // Boxレイアウトのレスポンシブ対応
  // https://mui.com/components/grid/#responsive-values

  setTimeout(() => setIsOpen(true), 800);
  setTimeout(() => setIsOpenText(true), 500);


  const setResult = () => {

  }
  return (
    <Box>
      <div style={{
        position: "relative",
        maxWidth: "1000px",
        margin: "0 auto",

      }}>

        <img src={`${process.env.PUBLIC_URL}/logo_h2.png`}
          style={{
            width: '100%',
            maxWidth: "1000px",
            margin: "0 auto",
            opacity: 0.8,
            display: "block",

          }} />

        <Typography
          style={{
            transition: '1s',
            opacity: isOpen ? 1 : 0,
            width: isOpen ? '80%' : 0,
            fontFamily: "Helpetica",
            position: "absolute",
            top: "80%",
            left: "50%",
            transform: "translate(-50%,-40%)",
            color: "#0099ff",
            fontWeight: 'bold',
            fontSize: 20,
            padding: 2,
            margin: 2,
            backgroundColor: 'rgba(255,255,255,0.5)',
            overflowWrap: 'break-word'
          }}
        >
          <p>ワンドロ応援サービス</p>
          <p>日々の練習テーマをファンから募集し。作業時間は60分間でできる収益化</p>
          <p>失敗を許容するルール。「完成責任」は無し。<br></br>その代わりに気楽にリクエストを受けられる</p>
          <p>「ファンもクリエイター」<br></br>友達の作家さんの創作に参加する</p>

        </Typography>

      </div>

      {/**------GRIDでテスト-------- */}
      <Grid container alignItems="center" justify="center" sx={{ marginTop: '100px' }}>
        <Grid item xs={12} sx={{ height: '70px' }}>
          <Box className={classes.mainBox}>
            <Box className={classes.centerBox}>
              <Button variant="contained" fullWidth onClick={clickSignup} sx={style.conversionBtn}> はじめる</Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.gridItemBlueback}>
          <Box className={classes.centerBox} sx={{
          }}>
            <h1>ワンドロを行うクリエイター、そしてファンを支援します</h1>
            <h3><b>普段のワンドロをもっと楽しく・便利にするサイトを目指します</b></h3>
            <p>
              皆様のご要望を聞きながら、機能を拡張してまいります<br></br><br></br>
              まずは、
              <ul>
                <li>「リクエスト」と</li>
                <li>「決済手段」</li>
                <li>そして「ワンドロ日記」</li>
              </ul>
              のご提供からスタートいたします<br></br><br></br>
              普段こんなことに不便を感じている。こういうツールや統計情報が獲得できたら助かる<br></br>
              そういったご要望がありましたら、可能な限り対応しますので、ご希望をお寄せください
            </p>
          </Box>
        </Grid>
      </Grid>



      {/**-------------- */}
      <Box className={classes.contentWrapper}>
        <Box className={classes.mainBox}>


          <Box className={classes.centerBox}>
            <h2>本サービスの特徴</h2>
          </Box>
          <Box className={classes.centerBox}>
            <p>
              本サービスには特殊なルールがあります。<br></br>
              <b>解決したいことは</b><br></br>
              コミッションなどリクエストの募集に伴う、作家さんのプレッシャーを減らし、<br></br>
              もっと気軽にイラストでコミュニケーションできること<br></br><br></br>
              依頼するファンの「イラストを発注する」というプレッシャーをなくし<br></br>
              もっと気軽に、リクエストをして共創を楽しむこと<br></br><br></br>
              <b>そのために、以下のルールを設定しております</b>

            </p>
          </Box>

        </Box>

      </Box>
      {/* =================================================================== */}

      <Grid container alignItems="center" justify="center" sx={{ paddingTop: '100px' }} className={classes.centerBoxVariation1}>
        <Grid item sm={12} md={6} className={classes.centerBoxVariation1Title}>
          <ul>
            <li>
              <Typography variant="h5" component="div" >
                <b>「完成責任がない」気軽なリクエスト専用</b><br></br><br></br>
              </Typography>
            </li>
            <li>
              <Typography variant="h5" component="div" >
                <b>作業時間は６０分間（ワンドロ）</b><br></br><br></br>
              </Typography>
            </li>
            <li>
              <Typography variant="h5" component="div" >
                <b>報酬は金銭または「シェア(金銭を伴わないリクエスト募集)」も可能</b><br></br><br></br>
              </Typography>
            </li>
          </ul>
        </Grid>

        <Grid item sm={12} md={6} className={classes.centerBoxVariation1Title}>
          <Typography variant="h4" component="div" >
            <b>だから、<br></br><br></br>
              気軽にリクエストできる。<br></br><br></br>
              気軽に受けられる</b><br></br><br></br>
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.centerBoxVariation1Title}>
          <Box className={classes.centerBox}>
            <Button variant="outlined" sx={{ marginRight: '10px', backgroundColor: 'white', height: '100px', borderRadius: '45px' }} onClick={() => navigate('/GuidTu8')} > より細かいルールのガイドはこちら</Button>
          </Box>
        </Grid>





      </Grid >



      {/* =================================================================== */}
      < Box className={classes.contentWrapper} >
        <Box className={classes.mainBox}>
          <hr></hr>
          <Box className={classes.centerBox} sx={{ height: '500px' }}>
            <h3>ワンドロとは</h3>
            <p>ワンアワードローイングの略称です。１時間制限の中で構想から下書き、清書までイラストを描き上げるチャレンジです。<br></br><br></br><br></br><br></br>
              チャレンジと学習のサイクルを短期間で多く回すことにより、スキルの向上につながります<br></br><br></br>
              また、SNS上で多数のワンドロチャレンジ企画が進行しておりますのでイラストを通した交流が広がります<br></br>

            </p>


          </Box>

          <hr></hr>
          {/* =================================================================== */}
          <Box className={classes.centerBox}>
            <h2>このサイトでできること</h2>
          </Box>

          <Grid container justify="center" spacing={2} >
            {/** １段目 ===================================================================== */}
            {/** プロフィール情報グリッド */}
            <Grid item xs={4} className={classes.gridPadding}>
              <Box className={classes.centerBox}>
                <Card className={classes.gridCard} style={style.gridCard}>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={style.h3Text}>
                      <b>ワンドロにアイデアを出す</b>
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={style.bodyText}>
                      「次の練習でこんなのどうでしょう」<br></br>
                      を提案できます<br></br><br></br>
                      有償でのリクエスト、<br></br>
                      または<b>無償</b>（シェアする約束）でのリクエストが可能です<br></br>
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={4} className={classes.gridPadding}>
              <Box className={classes.centerBox}>
                <Card className={classes.gridCard} style={style.gridCard}>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={style.h3Text}>
                      <b>作家さんに「ほしい」を伝える</b>
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={style.bodyText}>
                      作家さんのリクエストを閲覧できます<br></br>
                      「私も観たい！」<br></br>
                      と思ったら<br></br>
                      「共感」ボタンを押して、作家さんに伝えましょう！<br></br>
                      <b>描いてもらえる可能性が上がります</b>
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={4} className={classes.gridPadding}>
              <Box className={classes.centerBox}>
                <Card className={classes.gridCard} style={style.gridCard}>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={style.h3Text}>
                      <b>好きなテーマを描いてくれる作家さんを探す</b>
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={style.bodyText}>
                      「こういうテーマのワンドロをやってくれる人いないかな」<br></br>
                      というリクエストを投稿できます<br></br>
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>


            {/** 2段目 ===================================================================== */}
            <Grid item xs={4} className={classes.gridPadding}>
              <Box className={classes.centerBox}>
                <Card className={classes.gridCard} style={style.gridCard}>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={style.h3Text}>
                      <b>話題のネタを探す</b>
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={style.bodyText}>
                      リクエスト数の多いテーマをクラウドタグで可視化します<br></br>
                      <b>「ファンのほしいもの」</b>を知るのに役立ちます<br></br><br></br>
                      <b>twitterトレンド分析など追加機能準備中</b>
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={4} className={classes.gridPadding}>
              <Box className={classes.centerBox}>
                <Card className={classes.gridCard} style={style.gridCard}>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={style.h3Text}>
                      <b>「シェア」を報酬にリクエストができる</b>
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={style.bodyText}>
                      有料のリクエストだけでなく<br></br>
                      「もし描いたら、SNSで拡散を手伝います」<br></br>
                      といった、<b>金銭以外の報酬</b>が選べます<br></br>
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={4} className={classes.gridPadding}>
              <Box className={classes.centerBox}>
                <Card className={classes.gridCard} style={style.gridCard}>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={style.h3Text}>
                      他、<br></br>「こんなのあったらいいな」<br></br>をお寄せください！
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography variant="h5" component="div" sx={style.bodyText}>
                      <a href="https://twitter.com/illustrental">サポートアカウント(イラストレンタル共通)</a>
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>

          </Grid>




          {/* =================================================================== */}

        </Box >
      </Box >


      <Grid container alignItems="center" justify="center" sx={{ paddingTop: '0px' }} className={classes.centerBoxVariation2}>

        <Grid item sm={12} md={12} alignItems="center" justify="center" className={classes.centerBoxVariation2Title} sx={{ color: "white", margin: '0 auto' }}>
          <h2>本サービスを使うメリット</h2>
        </Grid>

        <Grid item sm={12} md={6} className={classes.centerBoxVariation2Title}>

          <Card className={classes.card} style={style.card}>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.h3Text}>
                <b>絵描きさんにとってのメリット</b>
              </Typography>
            </CardContent>
            <CardContent>
              <Typography component="div" sx={style.bodyText}>
                <ul>
                  <li style={style.listItem}>
                    練習でも、せっかく描くなら<b>今話題のテーマ</b>を選べる
                  </li>
                  <li style={style.listItem}>
                    リクエストや賛同数でファンが<b>欲しいもの</b>が見える。喜ばれるテーマを選べる
                  </li>
                  <li style={style.listItem}>
                    普段の練習やワンドロを<b>収益化</b>できる
                  </li>
                  <li style={style.listItem}>
                    報酬は金銭的報酬に限らない。<br></br>
                    <b>「描いたらシェア(拡散)する」</b>というリクエストも受諾可能
                  </li>
                </ul>
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item sm={12} md={6} className={classes.centerBoxVariation2Title}>
          <Card className={classes.card} style={style.card}>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.h3Text}>
                <b>ファンの皆様にとってのメリット</b>
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.bodyText}>
                <ul>
                  <li style={style.listItem} >
                    普段見ているイラストレータさんの練習やワンドロをアイデアを出して応援！<br></br>
                    <b>「ファンの皆様が喜ぶもの」</b>を知りたい作家さんへ<br></br>
                    アイデアや思いを伝えましょう！<br></br>
                    <b>これはファンの皆様にしかできないことです</b>
                  </li>
                  <li style={style.listItem} >
                    ワンドロなので、一般的なコミッションより早い
                  </li>
                  <li style={style.listItem}>
                    ファン目線で作家さんの良いところを伝えられる。伸ばせる
                  </li>
                  <li style={style.listItem}>
                    <b>ぜひ「どんなテーマが見たいか」を作家さんへ伝えてください</b>
                  </li>
                </ul>
              </Typography>
            </CardContent>
          </Card>
        </Grid>




        {/* =================================================================== */}
      </Grid >

      < Box className={classes.contentWrapper} >
        <Box className={classes.mainBox}>
          <Box className={classes.centerBox} sx={{
            backgroundColor: ''
          }}>


          </Box>

          <Box className={classes.centerBox}>
            <h2>リクエストする皆様にお願いしたいこと</h2>
            <h3>【本サイトの特殊なルール】</h3>
            <Card className={classes.card} style={style.card}>
              <CardContent>
                <Typography variant="h5" component="div" sx={style.h3Text}>
                  <b>失敗を許容する</b>
                </Typography>
              </CardContent>
              <CardContent>
                <h4>本サイトでのリクエストは、制作の請負ではなく<br></br>
                  練習やワンドロに挑戦する作家さんを応援をしている。<br></br>
                  という感覚でご利用さい</h4>
                <Typography variant="h5" component="div" sx={style.bodyText}>
                  <ul>
                    <li style={style.listItem}>
                      失敗して途中納品されるケース。<br></br>
                      納品されないケースはサービスとして許容します
                    </li>
                    <li style={style.listItem}>
                      過去のワンドロのポートフォリオから、どれくらいの成功率か、クオリティかをご判断ください
                    </li>
                    <li style={style.listItem}>
                      ワンドロ失敗時コミッションはどうなるのか？<br></br>
                      リクエスト時に、<br></br>
                      a) 失敗してでもできたところまでで納品 <br></br>
                      b)失敗の場合支払いなしを選択できます<br></br><br></br>
                      a) のケースは未完成でも支払いし応援をいただけるのが望ましいです
                    </li>
                  </ul>
                </Typography>
              </CardContent>
            </Card>
          </Box>

          {/*}
          <Box className={classes.centerBox}>
            <Card className={classes.card} style={style.card}>
              <CardContent>
                <Typography variant="h5" component="div" sx={style.h3Text}>
                  <b>投稿されている作品</b>
                </Typography>
              </CardContent>
            </Card>
            {!loading && <CardListView listData={cyumokuProduct} listTitle="注目の作品" listDiscription="サイト上で注目されている投稿されたワンドロ" type="product"></CardListView>}
            {loading && "...読み込み中"}
          </Box>
        */}

          <Box className={classes.centerBox}>
            <Card className={classes.card} style={style.card}>
              <CardContent>
                <Typography variant="h5" component="div" sx={style.h3Text}>
                  <b>料金について</b>
                </Typography>
              </CardContent>
              <CardContent>
                <Typography variant="h5" component="div" sx={style.bodyText}>
                  サービスの利用自体には料金はかかりません<br></br>
                  有償のリクエストを受諾し、入金が行われた際のみ、決済手数料として23.6%（下げられないか検討中) を徴収いたします

                </Typography>
              </CardContent>
            </Card>
          </Box>

          {/* =================================================================== */}
          {/* =================================================================== */}

        </Box >
      </Box >


      <Grid container alignItems="center" justify="center" sx={{ paddingTop: '0px' }} className={classes.centerBoxVariation2}>

        <Grid item sm={12} md={12} className={classes.centerBoxVariation2Title} sx={{ color: "white", margin: '0 auto' }}>
          <h2>次のアクション</h2>
          <ul>
            <li>
              次のボタンからまずはユーザ登録をしましょう(所要時間１分)
            </li>
            <br></br>
            <Button variant="contained" fullWidth onClick={clickSignup} sx={style.conversionBtn}> はじめる</Button>
            <br></br>
            <br></br>
            <li>
              ユーザ登録ができたら、入門コースへ進み、機能を開放しつつ利用方法を把握しましょう
            </li>
            <br></br>
            <Button onClick={() => navigate('/tu')} sx={{ backgroundColor: "white" }}>入門コース</Button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <li>
              現在はβ公開期間中です。「こういった機能があったら嬉い」など。ご意見や困り事などありましたら、<br></br>
              お気軽に以下SNSアカウント(「イラストレンタルと共用中」)までご連絡ください<br></br><br></br>
              絵を描いている皆様の日々思っていることなど。世界観、ぜひ教えてください！<br></br>
              (雑談振ってもらっても、可能な限り反応します)
            </li>
            <Button
              sx={{
                borderRadius: '15px',
                width: "60%",
                margin: 1,
                color: 'white'
              }}
              href={'https://twitter.com/illustrental'}
              variant="contained"
              color="secondary">
              サポートアカウント <img width="50" src={`${process.env.PUBLIC_URL}/twitterIcon.png`} alt="twitterLogo"></img>
            </Button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <li>
              絵描きさんのモニター募集しております<br></br><br></br>
              <b>使える道具になることを最重要視しており、絵描きの皆様のリアルな声が必要です</b><br></br><br></br>
              簡単なアンケートや新機能の試用をしていただき、使用感やご要望などお聞かせください<br></br>
              → 拘束時間はあまりなくあなたのペースに合わせて実施させていただきます。ぜひ、ご興味があるようでしたらお気軽に！<br></br>
            </li>
            <br></br>
            <Button href={'https://forms.gle/GygDwXbPQFvhGNxP7'} sx={{ backgroundColor: "white" }}>モニター参加お申し込み</Button>


            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <li>
              絵描きさん以外の方（ROM専含む）からのご要望を募集しております<br></br>
              絵描きさんにこんなリクエストがしたい。やこんなふうに探したい。<br></br>
              または、絵以外にこういうことを提供したいなど、ご意見いただけましたら、新しい機能案の参考にさせていただきます<br></br><br></br>
              <b>「絵を見る人が欲しいもの」ぜひ教えてください！
              </b>
            </li>
            <br></br>
            <Button href={'https://forms.gle/GygDwXbPQFvhGNxP7'} sx={{ backgroundColor: "white" }}>イラストを描かれないの皆様へのアンケート</Button>


            <br></br>
            <br></br>
            <li>

              もし、もう少しサービスの仕組みやルールについて読み物で把握したい場合は、画面下部の利用ガイドをご覧ください<br></br>
              以下から登録ガイドを閲覧できます
            </li>
          </ul>
        </Grid>

      </Grid>

      < Box className={classes.contentWrapper} >
        <Box className={classes.mainBox}>

          <br></br>

          {/* =================================================================== */}

          <Button variant="contained" fullWidth onClick={() => setDetailOpen(true)}> より細かい利用ガイドを見る</Button>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          {detailOpen && showDetailView()}


        </Box >
      </Box >
    </Box >


  );
}
export default Lp;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
    padding: 30,
    maxWidth: "1000px",
    margin: "0 auto",
  },
  // コンテンツの幅。端末ごとに広がりすぎるのを防ぐ。左揃え
  mainBox: {
    maxWidth: "700px",
    margin: "0 auto",
  },
  // グリッドアイテム。背景が青で白文字の箱
  gridItemBlueback:
  {
    color: 'white',
    backgroundColor: '#0099ff',
    height: '700px',
    padding: '20px'
  },

  // 個別コンテンツ　中央揃えで縦に内容を並べる
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  // ３つのキーメッセージ
  centerBoxVariation1: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: 'linear-gradient(0deg,#0099ff 90%,#ffffff 100%)',
  },
  centerBoxVariation1Title: {
    padding: '50px',
    margin: "0 auto",
    color: '#ffffff'
  },
  // メリット
  centerBoxVariation2: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#0099ff',
  },
  centerBoxVariation2Title: {
    padding: '50px',
    margin: "0 auto",
    color: '#ffffff'
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftAlignBox: {
    display: 'flex',
    marginTop: '70px',
    flexDirection: 'column',
    maxWidth: "800px",
    minWidth: "600px",
    alignItems: 'left',
  },
  // 個別コンテンツ　横並びに内容を並べる
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },
  cardList: {
    margin: 10, //'0 auto'
    display: 'flex',
    alignItems: 'center'
  },
  imageCard: { // トップサンプルのカード
    marginTop: 10,
    marginBottom: 20,
    padding: 0,
    width: 300,
  },
  card: { // LP説明用カード
    marginTop: 10,
    marginBottom: 20,
    padding: 0,

  },
  gridCard: { // LP説明用カード
    marginTop: 10,
    marginBottom: 20,
    padding: 0,
    height: 250,

  },
  imageContainer: {
    padding: 0,
  },

  //ヘッダ用
  // 全体レイアウト
  root: {
    flexGrow: 1,
  },
  // 各部分とその中身
  header: {
    background: 'linear-gradient(to bottom right,#9575cd,#f27573)',
    color: "#fff"
  },
  menuButton: {
    marginRight: 10,
  },
  loginBtn: {
    color: "#00bcd4"
  },
  lpBtn: {
    padding: 10,
    margin: 10,
    color: "#000000"

  },
}));

const conversionColor = "#c71585";

// 色とカードの形状はここで制御　
const style = {
  conversionBtn: {
    backgroundColor: conversionColor,
    color: '#fff',
    borderRadius: 10
  },
  title: {
    fontSize: 22,
    color: "#696969"
  },
  h3Text: {
    fontSize: 17,
    color: "#0099ff"
  },
  shokaiText: {
    margin: 2,
    fontSize: 20,
    color: "#696969"
  },
  bodyText: {
    fontSize: 12,
    color: "#000065"
  },
  card: {
    //boxShadow: '10px 2px 20px #CCD8FF',
    maxWidth: "500px",
    background: 'linear-gradient(to bottom right,#CCD8FF,#CCFFF2)',
  },
  gridCard: {
    //boxShadow: '10px 2px 20px #CCD8FF',
    maxWidth: "500px",
    background: 'linear-gradient(to bottom right,#CCD8FF,#FFE4E1	)',
  },

  listItem: {
    marginTop: 20,
  },
  cardProblem: {
    //boxShadow: '10px 2px 20px #CCD8FF',
    background: 'linear-gradient(to bottom right,#CCD8FF,#ffc0cb)',
  },
}

