import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';


// Material UI
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';


import EventCard from "./EventCard";


const Calender = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();

    // クリックされたイベントの情報をモーダルに渡す
    const [clickedEventData, setClickedEventData] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [eventType, setEventType] = useState("kikaku");



    const calender = props.calender

    let first = true;
    useEffect((props) => {
        if (first) {



        }
        return () => {
        }

    }, []);

    // リスト要素をデータを入れて、リストにする
    const itemListView = (item) => {
        if (!item) return;

        const itemListComp = item.map((data, index) => {
            return (
                <>
                    <Box
                        key={data.id}
                        onClick={() => {
                            setClickedEventData(data)
                            setEventType("eventView")
                            setModalShow(true)
                        }}
                    >
                        <EventCard item={data} ></EventCard>
                    </Box>
                </>
            )
        })

        return (
            <>
                {itemListComp}
            </>
        )
    }



    return (
        <>
            <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
                タグの一覧
            </Typography>
            <Box className={classes.yearList}>

                <Box className={classes.monthName}>
                    <Typography fullWidth sx={{ fontSize: 50 }}>
                        1月
                    </Typography>
                </Box>
                <Box className={classes.monthList}>
                    {itemListView(calender.ANI1)}
                </Box>
            </Box>

            <Box className={classes.yearList}>
                <Box className={classes.monthName}>
                    <Typography fullWidth sx={{ fontSize: 50 }}>
                        2月
                    </Typography>
                </Box>
                <Box className={classes.monthList}>
                    {itemListView(calender.ANI2)}
                </Box>
            </Box>

            <Box className={classes.yearList}>
                <Box className={classes.monthName}>
                    <Typography fullWidth sx={{ fontSize: 50 }}>
                        3月
                    </Typography>
                </Box>
                <Box className={classes.monthList}>
                    {itemListView(calender.ANI3)}
                </Box>
            </Box>

            <Box className={classes.yearList}>
                <Box className={classes.monthName}>
                    <Typography fullWidth sx={{ fontSize: 50 }}>
                        4月
                    </Typography>
                </Box>
                <Box className={classes.monthList}>
                    {itemListView(calender.ANI4)}
                </Box>
            </Box>

            <Box className={classes.yearList}>
                <Box className={classes.monthName}>
                    <Typography fullWidth sx={{ fontSize: 50 }}>
                        5月
                    </Typography>
                </Box>
                <Box className={classes.monthList}>
                    {itemListView(calender.ANI5)}
                </Box>
            </Box>

            <Box className={classes.yearList}>
                <Box className={classes.monthName}>
                    <Typography fullWidth sx={{ fontSize: 50 }}>
                        6月
                    </Typography>
                </Box>
                <Box className={classes.monthList}>
                    {itemListView(calender.ANI6)}
                </Box>
            </Box>

            <Box className={classes.yearList}>
                <Box className={classes.monthName}>
                    <Typography fullWidth sx={{ fontSize: 50 }}>
                        7月
                    </Typography>
                </Box>
                <Box className={classes.monthList}>
                    {itemListView(calender.ANI7)}
                </Box>
            </Box>
            <Box className={classes.yearList}>
                <Box className={classes.monthName}>
                    <Typography fullWidth sx={{ fontSize: 50 }}>
                        8月
                    </Typography>
                </Box>
                <Box className={classes.monthList}>
                    {itemListView(calender.ANI8)}
                </Box>
            </Box>
            <Box className={classes.yearList}>
                <Box className={classes.monthName}>
                    <Typography fullWidth sx={{ fontSize: 50 }}>
                        9月
                    </Typography>
                </Box>
                <Box className={classes.monthList}>
                    {itemListView(calender.ANI9)}
                </Box>
            </Box>
            <Box className={classes.yearList}>
                <Box className={classes.monthName}>
                    <Typography fullWidth sx={{ fontSize: 50 }}>
                        10月
                    </Typography>
                </Box>
                <Box className={classes.monthList}>
                    {itemListView(calender.ANI10)}
                </Box>
            </Box>
            <Box className={classes.yearList}>
                <Box className={classes.monthName}>
                    <Typography fullWidth sx={{ fontSize: 50 }}>
                        11月
                    </Typography>
                </Box>
                <Box className={classes.monthList}>
                    {itemListView(calender.ANI11)}
                </Box>
            </Box>
            <Box className={classes.yearList}>
                <Box className={classes.monthName}>
                    <Typography fullWidth sx={{ fontSize: 50 }}>
                        12月
                    </Typography>
                </Box>
                <Box className={classes.monthList}>
                    {itemListView(calender.ANI12)}
                </Box>
            </Box>




        </>
    )


}
export default Calender;

const useStyles = makeStyles((theme) => ({

    // 年間一覧のレイアウト ------------------------------------
    // 曜日表の書式。全体
    yearList: {
        display: 'flex',
        justifyContent: 'flexStart',
        padding: 10,
        borderBottom: 'dashed lightBlue'

    },
    // 曜日の名前ラベル
    monthName: {
        width: '100px',
        textAlign: 'center',

    },
    // リストの内容
    monthList: {
        flex: 2
    },
}));