
import SwipeDataObject from './SwipeDataObject';

class SwipeViewCommonLogic {

    /** 
     * @param {Object} product productまたはportforioを受け取る
     * Productの変換系 
     * ProductとPortforioオブジェクトに対応
     * */
    createDtoFromProduct = (product) => {
        return this.productToDto(product);
    }
    /**
     * 
     * @param {Object[]} cyumokuProductList productの配列を受け取る
     */

    createDtoFromProductArray = (cyumokuProductList) => {
        const result = cyumokuProductList.map((value, index, list) => {
            return this.productToDto(value);
        })
        return result
    }
    productToDto = (product) => {
        if (product === undefined) return


        if (product.isPortforio) {
            // メモ：toが作者
            return new SwipeDataObject(
                product,
                product.id,
                product.toName,
                `/User/${product.toUid}`,
                product.image,
                `/PortforioProduct/${product.id}`,
                product.tagsString,
                product.onedroTagString,
                product.nsfw
            )
        } else {
            // メモ：toが作者
            return new SwipeDataObject(
                product,
                product.id,
                product.toName,
                `/User/${product.toUid}`,
                product.image,
                `/Product/${product.id}`,
                product.tagsString,
                product.onedroTagString,
                product.nsfw
            )
        }

    }

    /** 
     * @param {Object<UserData>} Account ユーザを受け取る
     * */
    createDtoFromAccount = (account) => {
        return this.accountToDto(account);
    }
    /**
     * 
     * @param {Object[]} userDataList UserDataの配列を受け取る
     */
    createDtoFromAccountArray = (userDataList) => {
        const result = userDataList.map((value, index, list) => {
            return this.accountToDto(value);
        })
        return result
    }
    accountToDto = (userData) => {
        if (userData === undefined) return

        return new SwipeDataObject(
            userData,
            userData.uid,
            userData.displayName,
            `/User/${userData.uid}`,
            userData.heroImageView,
            `/User/${userData.uid}`,
            [], //該当項目なし
            [], //該当項目なし
            false //該当項目なし
        )
    }

    /** 
     * @param {Object<LimitedRequest>} limitedRequest 限定リクエストを受け取る
     * */
    createDtoFromLimitedrequest = (limitedRequest) => {
        return this.limitedToDto(limitedRequest);
    }
    /**
     * 
     * @param {Object[]} limitedRequestList UserDataの配列を受け取る
     */
    createDtoFromLimitedrequestArray = (limitedRequestList) => {
        const result = limitedRequestList.map((value, index, list) => {
            return this.limitedToDto(value);
        })
        return result
    }
    limitedToDto = (limitedRequest) => {
        if (limitedRequest === undefined) return

        return new SwipeDataObject(
            limitedRequest,
            limitedRequest.id,
            limitedRequest.thema,
            `/User/${limitedRequest.toUid}`,
            limitedRequest.imageUrl,
            `/LimitedRequest/${limitedRequest.id}`,
            limitedRequest.tagsString,
            limitedRequest.onedroTagString,
            false // 該当なし（TODO課題）
        )
    }

    /** 
     * @param {Object<Diary>} diary Diaryを受け取る
     * */
    createDtoFromDiary = (diary) => {
        return this.DiaryToDto(diary);
    }
    /**
     * 
     * @param {Object[]} diaryList Diaryの配列を受け取る
     */
    createDtoFromDiaryArray = (diaryList) => {
        const result = diaryList.map((value, index, list) => {
            return this.DiaryToDto(value);
        })
        return result
    }
    DiaryToDto = (diary) => {
        if (diary === undefined) return

        return new SwipeDataObject(
            diary,
            diary.id,
            diary.diary,
            `/User/${diary.toUid}`,
            diary.imageUrl,
            `/DiaryView/${diary.id}`,
            diary.tagString,
            diary.onedroTagString,
            false // 該当なし（TODO課題）
        )
    }

    /** 
     * @param {Object<Request>} request requestを受け取る
     * */
    createDtoFromRequest = (request) => {
        return this.requestToDto(request);
    }
    /**
     * 
     * @param {Object[]} request requestの配列を受け取る
     */
    createDtoFromRequestArray = (request) => {
        const result = request.map((value, index, list) => {
            return this.requestToDto(value);
        })
        return result
    }
    requestToDto = (request) => {
        if (request === undefined) return

        return new SwipeDataObject(
            request,
            request.id,
            request.thema,
            `/User/${request.fromUid}`,
            request.fromIcon,
            `/Request/${request.id}`,
            request.tagsString,
            request.onedroTagString,
            false // 該当なし（TODO課題）
        )
    }


    createDtoFromRequestArray


}
export default SwipeViewCommonLogic;