import { Box } from "@mui/material";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import IfProxy from './../IfProxy';
import { useAuthContext } from './../AuthContext';

/**
 * 
 * @param {id} toUser フォロー対象のuid
 * @returns 
 * 
 * <SnsUtil toUser={userDataオブジェクト} preFollowing={} preFollowed={}></SnsUtil>

 */
const SnsUtil = ({ toUser }) => {

    const [followed, setFollowed] = useState();
    const [following, setFollowing] = useState();
    const ifProxy = new IfProxy();
    const { user } = useAuthContext();
    const classes = useStyles();

    useEffect(() => {
        setFollowStatus(toUser);
    }, []);
    const setFollowStatus = (toUser) => {
        ifProxy.followedCheck(user.uid, toUser).then((result) => {
            setFollowed(result);
        }).catch((e) => {
            ifProxy.sign(e)
            console.log(e)
        })
        ifProxy.followCheck(user.uid, toUser).then((result) => {
            setFollowing(result);
        }).catch((e) => {
            ifProxy.sign(e)
            console.log(e)
        })
    }


    const followAction = () => {
        console.log("UID")
        console.log(user.uid)
        console.log(toUser)
        if (following) {
            ifProxy.deleteFollow(user.uid, toUser);
            setFollowing(false);
        } else {
            ifProxy.getUserData(toUser).then((toUserObject) => {
                ifProxy.addFollow(user, toUserObject);
            }).catch((e) => {
                console.log(e);
            })

            setFollowing(true);
        }

    }

    const followBtn = () => {
        // フォローチェック
        if (following) {
            // フォローしている
            return (
                <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.followButton}
                    onClick={followAction}
                >
                    フォローしています(解除)
                </Button>
            )

        } else if (followed) {
            // フォローしていないがフォローされている
            return (
                <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.followButton}
                    onClick={followAction}
                >
                    フォローバックする
                </Button>

            )

        } else {
            // フォローしていない
            return (
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.followButton}
                    onClick={followAction}
                >
                    フォローする
                </Button>
            )

        }
    }

    return (
        <>
            <Box
                sx={{
                    margin: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {user ? followBtn() : null}

            </Box>

        </>
    )
}

const useStyles = makeStyles((theme) => ({
    // 全体レイアウト
    contentWrapper: {
    },
    mainBox: {

    },
    cardList: {
    },
    card: {
    },
}));
export default SnsUtil;


