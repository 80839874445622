import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import IfProxy from '../../IfProxy';
import IfProxyStripe from '../../IfProxyStripe';
import IfProxyTool from '../../IfProxyTool';

import HeadFooter from '../HeadFooter';
import StraitLineSignupButton from '../../common/StraitLineSignupButton';
import CreateStripeAccount from '../../common/CreateStripeAccount';


// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';
import CardListView from "../../common/CardListView";
import TagInput from "../../common/TagInput";
import SnsUtil from "../../common/SnsUtil";

//graph
import VictorySample from "../../common/graphs/VictorySample";
import TimeBarGraph from "../../common/graphs/TimeBarGraph";
import TimeStackGraph from "../../common/graphs/TimeStackGraph";


import IfProxyLimitedRequest from './../../IfProxyLimitedRequest';




/**
 * ユーザ参照
 * @param  uidをクエリパラメータに入れているが、セキュリティ上の理由で使わない user.uidにする
 * @returns 
 */
const UserDashbord = (props) => {
  const [loading, setLoading] = useState(true);

  const [userDataId, setUserDataId] = useState("");


  const [icon, setIcon] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [stripeId, setStripeId] = useState("");
  const [remitedCreator, setRemitedCreator] = useState(false);
  const [stripeUserEnabled, setStripeUserEnabled] = useState(0); //有料クリエイターステータスフラグ


  const [profile, setProfile] = useState("");
  const [status, setStatus] = useState("");
  const [requetAmount, setRequetAmount] = useState(1);
  const [completetAmount, setCompletetAmount] = useState(1);
  const [completetTermH, setCompletetTermH] = useState("");
  const [completetTermD, setCompletetTermD] = useState("");

  const [creatorRating, setCreatorRating] = useState("");
  const [amoutAvelage, setAmoutAvelage] = useState("");

  const [invitation, setInvitation] = useState(false);

  const [premium, setPremium] = useState(0);
  const [userData, setUserData] = useState({});


  const [genre, setGenre] = useState("");
  const [onedro, setOnedro] = useState("");
  const [unpaid, setUnpaid] = useState("");
  const [paidAmount, setPaidAmount] = useState("");
  const [title, setTitle] = useState("");

  const [competedRate, setCompetedRate] = useState("");


  const [requestList, setRequestList] = useState([]);
  const [fromRequestList, setFromRequestList] = useState([]);

  const [product, setProduct] = useState([]);
  const [portforio, setPortforio] = useState([]);

  // 自分の日記リスト
  const [diaryList, setDiaryList] = useState([]);
  const [diaryRecordList, setDiaryRecordList] = useState([]);


  //productを受け取るときに、やったワンドロを取得。クラウドタグに出す
  const [tags, setTags] = useState(false);
  const [onedroTag, setOnedroTag] = useState(false);

  // フォローでユーザオブジェクトを渡すために、閲覧しているユーザを取得する
  const [viewdUser, setViewdUser] = useState([]);

  // 限定リクエストのリストビューデータ
  const [limitedRequestDataFrom, setLimitedRequestDataFrom] = useState([]);
  const [limitedRequestDataTo, setLimitedRequestDataTo] = useState([]);

  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  //const params = useParams();
  const location = useLocation();

  const ifProxy = new IfProxy();
  const ifProxyStripe = new IfProxyStripe();
  const ifProxyTool = new IfProxyTool();

  const ifProxyLimitedRequest = new IfProxyLimitedRequest();

  useEffect((props) => {
    //DEGUG
    //ifProxy.calcTermAvelage(user.uid)

    loadData(user.uid);
    userStatusCheck();

    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    // ------

    getProduct(user.uid)
    setLoading(true)

  }, []);

  /**
   * uidでユーザを検索
   * uidは、画面へのパスパラメータから取得
   * @param {*} uid 
   */
  const loadData = async (uid) => {
    const ifProxy = new IfProxy();
    const ansettled = await ifProxy.calcUnsettlementAmount(user.uid)

    await ifProxyLimitedRequest.getRemitedRequestListByFromUser(10, user.uid).then((result) => {
      console.log("~~~~~~~~~~~~~~~~~")
      console.log(result)
      setLimitedRequestDataFrom(result);
    })
    await ifProxyLimitedRequest.getRemitedRequestListByToUser(10, user.uid).then((result) => {
      console.log("~~~~~~~~~~~~~~~~~")
      console.log(result)
      setLimitedRequestDataTo(result);
    })

    ifProxyTool.getDiaryList(uid, 10).then((result) => {
      console.log("取得した日記")
      console.log(result)
      setDiaryList(result)
    })



    ifProxy.getUserData(uid).then((result) => {
      setLoading(false);
      // paramsの値でユーザを検索
      setUserData(result)
      setUserDataId(result.id)

      setIcon(result.icon);
      setDisplayName(result.displayName);
      setStripeId(result.stripeId || "");
      setRemitedCreator(result.remitedCreator)
      setProfile(result.profile);
      setStatus(result.status);
      setRequetAmount(result.entrustedAmount || 0);  // 受諾数
      setCompletetAmount(result.completetAmount || 0); // 納品数
      setCompetedRate(result.competedRate || 0); //平均納品期間
      setCompletetTermH(result.termAvelage?.aveh || 0); //平均納品期間
      setCompletetTermD(result.termAvelage?.aved || 0); //平均納品期間

      setPremium(result.premium || 0); //サブスクフラグ　

      console.group('プレミアムチェック')
      console.log(result)
      console.groupEnd()



      // 評価は０オリジン
      let ratingScore = ""
      if (result.ratingAvelage === 2) {
        ratingScore = "高"
      } else if (result.ratingAvelage === 1) {
        ratingScore = "中"
      } else {
        ratingScore = "低"
      }
      setCreatorRating(ratingScore); //平均レーティング
      setAmoutAvelage(result.amoutAvelage || 0)
      setGenre(result.genre);
      setOnedro(result.onedro);
      setUnpaid(ansettled || 0);
      setPaidAmount(result.paidAmount);
      setTitle(result.title);

      setViewdUser(result);
    })

    ifProxy.getPortforioProductByToUid(uid).then((result) => {
      setPortforio(result);
    })

    // リクエスト受諾状況取得
    loadRequestList();

    // 招待状況取得
    ifProxy.getInvitationrecordByUid("invitingUid", user.uid).then((result) => {
      setInvitation(result);
    }).catch((e) => {
      console.log("checkInvitationcode失敗")
      console.log(e)
    })


  }

  const loadRequestList = () => {
    getMyRequest(user.uid);
  }

  /**
  * ログイン済みユーザの場合はクリエイターアカウントであるかどうかをチェック
  */
  const userStatusCheck = async () => {

    if (!user) {
      // サインアップしてない
      setStripeUserEnabled(0)
    } else {
      const userData = await ifProxy.getUserData(user.uid);

      if (!userData.stripeId || userData.stripeId === "") {

        // 有料開放クリエイター登録をしていない
        setStripeUserEnabled(0)
      } else {
        ifProxyStripe.getStripeAccount(userData.stripeId).then((result) => {
          // trueはStripe側口座登録完了 

          if (result) {
            setStripeUserEnabled(2)
          } else {
            // クリエイター登録が完了していない
            // falseは、まだ口座登録作業が残っている
            setStripeUserEnabled(1)
          }
        }).catch((e) => {
          console.log("=== userStatusCheck ===")
          console.log(e)
        })

      }


    }
  }
  /**
   * リクエストリスト。
   * 受領しているリクエストをステータスとともに表示する
   * @param {*} uid 
   */
  const getMyRequest = (uid) => {

    const ifProxy = new IfProxy();
    ifProxy.getRequestByToUid(uid).then((result) => {
      //console.log("リクエストリスト")
      //console.log(result)

      setRequestList(result)
    })
    ifProxy.getRequestByFromUid(uid).then(async (result) => {
      //console.log("=====　リクエストしたリスト")
      //console.log(result)
      setFromRequestList(result)
    })
  }

  /**
   * 閲覧しているユーザのポートフォリオとこれまで投稿したワンドロを表示する
   * @param {*} uid 
   * @param {int} count 
   */
  const getProduct = (uid) => {
    const ifProxy = new IfProxy();
    ifProxy.getProductByToUid(uid, 5).then(async (result) => {

      setProduct(result)
      setTags(result);
      const ondroTagList = result.map((item, index) => {

        // 項目名がtagsString固定なので加工が必要
        return { id: item.id, tagsString: item.onedroTagString }
      })
      setOnedroTag(ondroTagList)
    })
  }

  const addFav = () => {
    ifProxy.addFav(user, viewdUser)
  }

  const goRequest = () => {
    let result = window.confirm("ワンドロは必ずしも成功されるとは限りません。実績を確認して了解の上発注を行ってください")
    if (result) {
      navigate('/CreateRequest/' + user.uid)
    }
  }

  const invitationStatusView = () => {
    // invatationレコードが作成済みの場合
    return (
      <>
        <Typography sx={{ fontSize: 14 }}>
          コード1: {invitation[0].id}
        </Typography>
        <Typography sx={{ fontSize: 14 }}>
          招待先1:  {invitation[0].invitedUid === "" ? "未使用" : invitation[0].invitedUid}
        </Typography>
        <hr></hr>
        <Typography sx={{ fontSize: 14 }}>
          コード2: {invitation[1].id}
        </Typography>
        <Typography sx={{ fontSize: 14 }}>
          招待先2: {invitation[1].invitedUid === "" ? "未使用" : invitation[1].invitedUid}
        </Typography>
        <hr></hr>

        <Typography sx={{ fontSize: 14 }}>
          コード3: {invitation[2].id}
        </Typography>
        <Typography sx={{ fontSize: 14 }}>
          招待先: {invitation[2].invitedUid === "" ? "未使用" : invitation[2].invitedUid}
        </Typography>
      </>
    )

  }



  /**
   * 直近のワンドロ記録を表示
   * @returns 
   */
  const recordView = () => {
    // 直近の日記データ diaryList
    const recordList = diaryList.map((diary, index) => {
      return diary.record
    })

    return (<>
      <Box className={classes.graphEria}>
        <TimeBarGraph recordListData={recordList} />

      </Box>
    </>)
  }
  /**
 * 直近のワンドロの構成費傾向を表示
 * @returns 
 */
  const recordViewStack = () => {
    // 直近の日記データ diaryList
    const recordList = diaryList.map((diary, index) => {
      return diary.record
    })

    return (<>
      <Box className={classes.graphEria}>
        <TimeStackGraph recordListData={recordList} />

      </Box>
    </>)
  }

  /**
   * 退会
   */
  const withdrow = () => {
    let result = window.confirm("退会処理を行います")
    if (result) {
      try {
        ifProxy.withdrawUser(user.uid).then((result) => {
          deleteUser(user).then(() => {
            window.confirm("ユーザを削除しました")
            auth.signOut();
            navigate('/login');
          })
        })
      } catch (e) {
        console.log("退会処理に失敗")
        console.log(e)
        window.confirm("退会処理に失敗しました : " + e)
      }
    }
  };

  const createInvitationProcessing = () => {
    setLoading(true)
    ifProxy.addInvitationcode(3).then((result) => {
      ifProxy.getInvitationrecordByUid("invitingUid", user.uid).then((result) => {
        setInvitation(result);
        setLoading(false);
      }).catch((e) => {
        console.log("checkInvitationcode失敗")
        console.log(e)
      })
    })
  }


  /**
   * ボタンの表示切り替え　
   * 
   * このボタンは以下のケースで表示を切り替える
   * 0 本アプリ未登録のユーザ何も表示しない クリエイター登録をしていない
   * 1 本アプリ登録、クリエイター登録がまだ完了できていない
   * 2 クリエイター登録済み： Stripe口座管理画面へのリンク
   * @returns 何も表示しない/ボタンを表示する/決済管理情報を表示する
   */
  const creatorRegistrationButtonView = () => {
    console.log(stripeUserEnabled)
    if (stripeUserEnabled === 0) {
      // プラットフォーム未登録
      return (<>

        <CreateStripeAccount />

      </>
      )
    } else if (stripeUserEnabled === 1) {
      // Stripe登録が未完了
      // Stripeidをまだ持っていない
      return (
        <a href="https://dashboard.stripe.com/dashboard">支払い口座の設定が未完了です。<br></br>イラストを投稿して報酬を受け取るには完了が必要です。</a>
      )
    } else if (stripeUserEnabled === 2) {
      // Stripeユーザ登録有効化済み
      return (
        <a href="https://dashboard.stripe.com/dashboard">口座設定</a>
      )
    }

  }





  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    const twitterShareUrl = "http://twitter.com/share?url=" + window.location.origin + '/User/' + user.uid + "&text=ワンドロテーマを募集中&hashtags=ワンフロ"

    if (loading) {
      return (
        <HeadFooter>
          <Box className={classes.contentWrapper}>
            <Box className={classes.mainBox}>
              <p>読み込み中...</p>
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            </Box>
          </Box>
        </HeadFooter>
      )
    } else {
      //ログインしていないユーザ向けのプロフィール画面
      return (
        <></>
      )
    }
  } else if (loading) {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Box>
        </Box>
      </HeadFooter>
    )
  } else {

    const twitterShareUrl = "http://twitter.com/share?url=" + window.location.origin + '/User/' + user.uid + "&text=ワンドロテーマを募集中&hashtags=ワンフロ"

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      < HeadFooter >
        <Box className={classes.contentWrapper}>
          <Grid container justify="center" spacing={2} >

            {/** １段目 ===================================================================== */}
            {/** プロフィール情報グリッド */}
            <Grid item xs={6} className={classes.gridPadding}>
              <Box className={classes.leftBox}>
                <Box className={classes.leftCenterBox}>
                  <Avatar sx={{ width: 100, height: 100 }} src={icon} />
                </Box>
                <Box className={classes.leftCenterBox} sx={{ marginLeft: 2 }}>
                  <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>
                    {displayName}
                  </Typography>
                  <Typography sx={{ fontSize: 14 }}>
                    {profile}
                  </Typography>
                  <Button
                    variant="outlined"
                    color="info"
                    fullwidth={true}
                    onClick={() => (navigate(`/UserEdit/${user.uid}`))}

                  >
                    プロフィールを編集する
                  </Button>
                </Box>
              </Box>





            </Grid>
            <Grid item xs={2} className={classes.gridPadding}>
              <Box className={classes.leftCenterBox}>
                <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>
                  募集設定
                </Typography>
                <Typography sx={style.scoreLabel}>
                  <Box sx={{ color: `${status ? "info.main" : "text.disabled"}` }}>{status ? "募集中" : "募集停止中"}</Box>
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={4} className={classes.gridPadding}>
              <Box className={classes.leftCenterBox}>
                <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>
                  招待コード利用状況
                </Typography>
                {invitation && invitationStatusView()}
                {!invitation && <Button
                  variant="outlined"
                  color="info"
                  onClick={() => createInvitationProcessing()}
                >
                  招待コードを作成する
                </Button>}

              </Box>
            </Grid>


            {/** 2段目 ===================================================================== */}
            <Grid item xs={2} className={classes.gridPadding}>
              <Box className={classes.leftCenterBox}>
                <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>
                  獲得タイトル
                </Typography>
                <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                  {/** タイトル　肩書き */}
                  {title ? title : "-"}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={1} className={classes.gridPadding}>
              <Box className={classes.leftCenterBox}>
                <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>
                  対応数
                </Typography>
                <Typography sx={style.scoreLabel}>
                  {requetAmount}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={1} className={classes.gridPadding}>
              <Box className={classes.leftCenterBox}>
                <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>
                  成功率
                </Typography>
                <Typography sx={style.scoreLabel}>
                  {(competedRate || 1) * 100}
                </Typography>%
              </Box>
            </Grid>

            <Grid item xs={1} className={classes.gridPadding}>
              <Box className={classes.leftCenterBox}>
                <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>
                  納品数
                </Typography>
                <Typography sx={style.scoreLabel}>
                  {completetAmount}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={2} className={classes.gridPadding}>
              <Box className={classes.leftCenterBox}>
                <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>
                  平均納品期間
                </Typography>
                <Typography sx={style.scoreLabel}>
                  {completetTermD} 日
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                  ({completetTermH}時間)
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={1} className={classes.gridPadding}>
              <Box className={classes.leftCenterBox}>
                <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>
                  平均受注金額
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                  ¥ {amoutAvelage}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={2} className={classes.gridPadding}>
              <Box className={classes.leftCenterBox}>
                <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>
                  得意ジャンル
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                  {genre}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={2} className={classes.gridPadding}>
              <Box className={classes.leftCenterBox}>
                <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>
                  ユーザ評価
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                  リクエスト未払い数：{unpaid}件<br></br>(納品を受けてからまだ支払い未完了の件数)
                </Typography>
              </Box>
            </Grid>



            {/** 3段目 ===================================================================== */}
            <Grid item xs={12} className={classes.gridPadding}>
              <Box className={classes.leftCenterBox}>
                <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>
                  直近のワンドロ記録
                </Typography>
                {recordView()}
              </Box>
            </Grid>
            <Grid item xs={12} className={classes.gridPadding}>
              <Box className={classes.leftCenterBox}>
                <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>
                  分析
                </Typography>
                {premium !== 0 ?
                  recordViewStack()
                  : "プレミアムユーザ限定機能。最近実施したワンドロ記録の推移をグラフで可視化することができます。成長を感じてモチベーションにしましょう！"
                }
              </Box>
            </Grid>


            {/** 4段目 ===================================================================== */}
            <Grid item xs={8} className={classes.gridPadding}>
              <Box className={classes.leftCenterBox}>
                <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>
                  プロフィールURL
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                  <p>プロフィール画面を紹介するときはこちらのリンクを伝えてください</p>
                  プロフィールのURL: <a href={window.location.origin + "/User/" + user.uid}>{window.location.origin + "/User/" + user.uid}</a>
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={4} className={classes.gridPadding}>
              <Box className={classes.leftCenterBox}>

                {!remitedCreator && <StraitLineSignupButton></StraitLineSignupButton>}
                {remitedCreator && "クリエイターユーザ"}

              </Box>
            </Grid>




            {/** 5段目 ===================================================================== */}
            <Grid item xs={4} className={classes.gridPadding}>
              <Box className={classes.leftCenterBox}>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullwidth
                  sx={{ margin: 2 }}
                  onClick={() => withdrow()}
                  className={classes.followButton}>
                  退会する
                </Button>
              </Box>
            </Grid>

            <Grid item xs={4} className={classes.gridPadding}>
              <Box className={classes.leftCenterBox}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullwidth
                  sx={{ margin: 2 }}
                  onClick={() => navigate('/FollowList')}
                  className={classes.followButton}>
                  フォローリスト
                </Button>
              </Box>
            </Grid>

            <Grid item xs={4} className={classes.gridPadding}>
              <Box className={classes.leftCenterBox}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullwidth
                  sx={{ margin: 2 }}
                  onClick={() => {
                    navigate('/CreatePortforioProduct/' + user.uid)
                  }}
                  className={classes.followButton}>
                  サンプルを投稿
                </Button>
              </Box>
            </Grid>

            {/** 6段目 ===================================================================== */}
            <Grid item xs={8} className={classes.gridPadding}>
              <Box className={classes.leftCenterBox}>
                <Box className={classes.leftCenterBox}>
                  <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>
                    売り上げ・口座を確認する
                  </Typography>
                  <Typography sx={{ fontSize: 14 }}>

                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={stripeId === ""}
                    fullwidth
                    // stripeアカウントへ遷移
                    sx={{ margin: 2 }}
                    href="https://dashboard.stripe.com/dashboard"
                    className={classes.followButton}>
                    Stripeダッシュボードへ

                  </Button>
                  {stripeId === "" && "有料リクエスト機能の開放が必要です"}
                  {creatorRegistrationButtonView()}

                </Box>

              </Box>
            </Grid>
            <Grid item xs={4} className={classes.gridPadding}>

              <Box className={classes.leftCenterBox}>
                {ifProxyStripe.getPremiumStatus(userData).premiumFlg}
                {premium === 0 ?
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={false} // 有効化
                    fullwidth
                    sx={{ margin: 2 }}
                    onClick={() => {
                      navigate('/UserSubscribe')
                      //activatePremiumSubscription()
                    }
                    }
                    className={classes.followButton}>
                    プレミアム機能の利用を開始する
                  </Button>
                  :
                  <Button
                    variant="outlined"
                    color="primary"
                    fullwidth
                    sx={{ margin: 2 }}
                    onClick={() => navigate('/UserSubscribe?success=true&session_id=' + userData.sessionId)}
                    className={classes.followButton}>
                    プレミアム管理・退会
                  </Button>
                }

              </Box>
            </Grid>






            {/** 7段目 ===================================================================== */}
            {/*
            <Grid item xs={6} className={classes.gridPadding}>
              <Box className={classes.leftCenterBox}>
                <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>
                  閲覧数
                </Typography>
                <Typography sx={{ fontSize: 14 }}>

                </Typography>
              </Box>
              <VictorySample id="testid1"></VictorySample>


            </Grid>
            <Grid item xs={6} className={classes.gridPadding}>
              <Box className={classes.leftCenterBox}>
                <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>
                  共感数
                </Typography>
                <Typography sx={{ fontSize: 14 }}>

                </Typography>
              </Box>
              <VictorySample id="testid2"></VictorySample>
            </Grid>

             */}





          </Grid>
          {/** GRIDオワリ　 ===================================================================== */}


          <Box className={classes.mainBox}>

            {requestList && <CardListView id="requestList" listDiscription="案件リスト" listData={requestList} type="request"></CardListView>}
            {fromRequestList && <CardListView id="requestList" listDiscription="あなたが依頼したリクエスト一覧" listData={fromRequestList} type="request"></CardListView>}
            {limitedRequestDataFrom && <CardListView id="LimitedRequest" listDiscription="行った限定リクエスト" listData={limitedRequestDataFrom} type="limitedRequest"></CardListView>}
            {limitedRequestDataTo && <CardListView id="LimitedRequest" listDiscription="受け付けた限定リクエスト" listData={limitedRequestDataTo} type="limitedRequest"></CardListView>}

            <h1>投稿作品情報</h1>
            <h2>投稿作品数</h2>
            <Box className={classes.centerBox}>
              {tags && <TagInput type="viewTag" tagViews={tags}></TagInput>}
            </Box>
            <CardListView id="onedroList" listDiscription="過去のワンドロ実績" listData={product} type="product"></CardListView>

            <CardListView id="portforioList" listDiscription="サンプル" listData={portforio} type="portforio"></CardListView>


          </Box>

        </Box>

      </HeadFooter >

    );
  }




}
export default UserDashbord;

const style = {
  scoreLabel: {
    fontSize: 25,

  },
}

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
    marginTop: 20,
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",

  },
  gridPadding: {
    padding: 5,
    border: '2px solid',
    borderColor: '#a6d4fa',
    backgroundColor: 'linear-gradient(to bottom right,#a6d4fa,#1976d2)',
    borderRadius: '10'

  },
  // 個別コンテンツ　中央揃えで縦に内容を並べる
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftCenterBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  // 個別コンテンツ　横並びに内容を並べる
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },
  //サンプルのグラフエリア
  graphEria: {
    width: '100%',
    height: 300
  },

  cardList: {
  },
  card: {
  },
  bottomCard: {
    width: 250
  },
}));
