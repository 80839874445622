import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import IfProxy from '../../IfProxy';
import IfProxyTool from '../../IfProxyTool';

//import { createReactEditorJS } from 'react-editor-js'
//import CheckList from '@editorjs/checklist'


import HeadFooter from '../HeadFooter';
import StraitLineSignupButton from '../../common/StraitLineSignupButton';

// https://yoh1496.hatenablog.com/entry/2021/06/24/182339


// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

//graph
import RecordParcentGraph from "../../common/graphs/RecordParcentGraph";
import RecordParcentBarGraph from "../../common/graphs/RecordParcentBarGraph";

// コンポーネント
import TagInput from "../../common/TagInput";
import UploadImage from './../../common/UploadImage';




/**
 * ユーザ参照
 * @param  
 * @returns 
 */
const Diary = (props) => {
  const [loading, setLoading] = useState(true);
  // クリックされたイベントの情報をモーダルに渡す
  const [clickedEventData, setClickedEventData] = useState("");
  const [modalShow, setModalShow] = useState(false);
  // 日記リストデータ
  const [diaryData, setDiaryData] = useState({});


  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();


  // https://www.npmjs.com/package/react-editor-js
  //const ReactEditorJS = createReactEditorJS()



  const [blobDataResult, setBlobDataResult] = useState(0);
  const [image, setImage] = useState("");
  const [onedroTags, setOnedroTags] = useState(""); // productに設定したタグ
  const [tags, setTags] = useState("");// productに設定したタグ
  const [onedroTagsSag, setOnedroTagsSag] = useState(""); // productに設定したタグ
  const [tagsSag, setTagsSag] = useState("");// productに設定したタグ
  const [nsfw, setNsfw] = useState(false);
  const [privateViewFlg, setPrivateViewFlg] = useState(false);

  const [submitActivate, setSubmitActivate] = useState(false);




  const ifProxy = new IfProxy();
  const ifProxyTool = new IfProxyTool();

  let first = true;
  useEffect((props) => {
    // 【useEffectの２重処理について】
    // https://zenn.dev/takeharu/scraps/d14cf9d4239ec4
    // useEffectは２回描画される
    // mount -> unmount -> mount
    // 処理がこのようにダブルのは開発環境だけ
    if (first) {
      setLoading(true)
      // ReactGA Analytics ---
      ReactGA.initialize("G-2ZCXE08H2S");
      ReactGA.send({
        hitType: "pageview",
        // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
        page: location.pathname + location.search,
      });
      // ------
      loadData();
    }

    //    console.log(first ? "１回目である" : "２回目である")
    //    console.log("回数評価処理は以下")
    //    console.log("開発環境２重処理防止のため、１回目、２回目判定フラグ")
    //    console.log(first)
    first = false;
    //    console.log("２重登録判定フラグ、更新後")
    //    console.log(first)

    return () => {
      //      console.log("UNMOUNT 開発環境２重処理防止のため、１回目、２回目判定フラグ")
      //      console.log(first)
    }

  }, []);

  const loadData = async () => {
    const getData = await ifProxyTool.getDiary(params.id);

    await ifProxy.getTag("ワンドロ企画", 10).then((result) => {
      setOnedroTagsSag(() => result)
      console.log(result)
    })
    await ifProxy.getTag("タグ", 10).then((result) => {
      setTagsSag(() => result)
      console.log(result)
      setLoading(false)
    })

    let filledFlg = false;
    if (getData.onedroTag.length > 0 && getData.tag.length > 0) filledFlg = true;
    setSubmitActivate(filledFlg)

    setDiaryData(getData);

    setLoading(false)
  }

  /**
   * 日記を保存する
   */
  const updateDiary = async () => {
    setLoading(true);
    let updateData = diaryData;

    updateData.blobdata = blobDataResult;
    updateData.privateView = privateViewFlg;
    updateData.nsfw = nsfw;
    //console.log("送ったオブジェクト =================")
    //console.log(updateData)
    await ifProxyTool.updateDiary(updateData);
    navigate('/DiaryList')

    setLoading(false);
  }

  /*
  * @param {Blob} blobData 
  */
  const returnBlob = ({ blobData, localUrl }) => {
    // 親画面の画像データを実際に取り扱うstateに設定
    setBlobDataResult(blobData);
    setImage(localUrl)
    //console.log(blobData)

  }
  const returnTags = (tag) => {
    // 作品名のタグ
    setTags(tag)
    let tagsString = [];
    if (tag) {
      tagsString = tag.map((data, index) => {
        return data.text
      })
    }
    setSubmitActivate(tagsString.length > 0)
    setDiaryData((prv) => ({ ...prv, tag: tag, tagString: tagsString }))

  }
  const returnKikakuTags = (tag) => {
    // 企画名のタグ
    setOnedroTags(tag)
    let onedroTagStringList = []
    if (tag) {
      onedroTagStringList = tag.map((data, index) => {
        return data.text
      })
    }
    setDiaryData((prv) => ({ ...prv, onedroTag: tag, onedroTagString: onedroTagStringList }))

  }

  const handleYoutubeUrl = (event) => {
    setDiaryData((prv) => ({ ...prv, youtubeUrl: event.target.value }))
  }
  const handleVideoUrl = (event) => {
    setDiaryData((prv) => ({ ...prv, videoUrl: event.target.value }))
  }
  const handleDiary = (event) => {
    setDiaryData((prv) => ({ ...prv, diary: event.target.value }))
  }
  const handleMemoForNext = (event) => {
    setDiaryData((prv) => ({ ...prv, memoForNext: event.target.value }))
  }



  const graphSection = () => {
    return (
      <>
        <RecordParcentGraph recordData={diaryData.record} />
      </>
    )
  }
  const graphBarGraphSection = () => {
    return (
      <>
        <RecordParcentBarGraph recordData={diaryData.record} />
      </>
    )
  }


  const diaryView = () => {


    const {
      id,
      toUid,
      toUser,
      toIcon,
      privateView,
      diary,
      memoForNext,
      imageUrl,
      videoUrl,
      youtubeUrl,
      tag,
      tagString,
      onedroTag,
      onedroTagString,
      record,
    } = diaryData;

    return (
      <>

        <Grid container className={classes.modalGrid} >
          {/** 0段目 ===================================================================== */}
          {/**ヘッダ */}
          <Grid item xs={12} >
            <Box className={classes.centerBox}>
              <Avatar
                src={toIcon}
                sx={{ width: 70, height: 70 }} />
              {toUser}
            </Box>
          </Grid>

          {/** 1段目 ===================================================================== */}
          {/**分析 */}
          <Grid item xs={6} >
            <Box className={classes.centerBox}>
              {graphSection()}
            </Box>
          </Grid>

          <Grid item xs={6} >
            <Box className={classes.centerBox}>
              {graphBarGraphSection()}
            </Box>
          </Grid>

          {/** 2段目 ===================================================================== */}
          {/** 画像とデータ */}
          <Grid item xs={12} >
            <Box className={classes.centerBox}>
              <img src={imageUrl} />
              {!imageUrl && <UploadImage returnBlob={(blob) => returnBlob(blob)}></UploadImage>}

            </Box>
          </Grid>
          {/** 3段目 ===================================================================== */}
          {/** タグ等の情報 */}
          <Grid item xs={6} >

            <h2>作品タグ</h2>
            設定中のタグ　{tagString.join(", ")}
            <TagInput type="form" resultTags={(tags) => returnTags(tags)} suggestion={tagsSag}></TagInput>

          </Grid>
          <Grid item xs={6} >
            <h2>ワンドロ企画名（任意）</h2>
            設定中のタグ　{onedroTagString.join(", ")}
            <TagInput type="formKikaku" resultTags={(tags) => returnKikakuTags(tags)} suggestion={onedroTagsSag}></TagInput>
          </Grid>

          {/** 4段目 ===================================================================== */}
          <Grid item xs={6} >
            <Box className={classes.leftBox} >
              <h2>NSFW : </h2>
              <input
                type="checkbox"
                id="check"
                checked={nsfw}
                onChange={() => {
                  setNsfw(prevState => {
                    return !prevState
                  })

                }}
              />
              <h2>
                {nsfw ? "ON" : "OFF"}
              </h2>
            </Box>
          </Grid>
          <Grid item xs={6} >
            <Box className={classes.leftBox} >
              <h2>公開設定: </h2>
              <input
                type="checkbox"
                id="check"
                checked={privateViewFlg}
                onChange={() => {
                  setPrivateViewFlg(prevState => {
                    return !prevState
                  })

                }}
              />
              <h2>
                {!privateViewFlg ? "公開" : "非公開"}
              </h2>
            </Box>
          </Grid>


          {/** 2段目 ===================================================================== */}
          {/** 日記、ログ系 */}
          <Grid item xs={6} >
            <Box className={classes.centerBox}>
              <Typography><b>日記</b></Typography>
              <Typography>今回のワンドの反省点、学んだこと、記録しておくべき設定やノウハウなど。</Typography>
              <TextField fullWidth margin="nomal" multiline rows={4} id="filled-basic" size="small"
                inputProps={{ maxLength: 400 }}
                placeholder="例) 手ブレ補正値を0.2下げたら線がついてくるようになった。グリザイア塗りを試したがレイヤーワークで迷ってタイムロス。方針を再確認する"
                onChange={handleDiary} variant="outlined" type="string" value={diary}
              />
            </Box>
          </Grid>

          <Grid item xs={6} >
            <Box className={classes.centerBox}>
              <Typography><b>次のためのメモ</b></Typography>
              <TextField fullWidth margin="nomal" multiline rows={4} id="filled-basic" size="small"
                inputProps={{ maxLength: 400 }}
                placeholder="次ワンドロをするときにやりたいこと、改善したいことなどをメモ"
                onChange={handleMemoForNext} variant="outlined" type="string" value={memoForNext}
              />
            </Box>
          </Grid>

          {/* エディタの拡張のための準備
          <Grid item xs={12} >
            <ReactEditorJS defaultValue={blocks} tools={{ checkList: CheckList }} />

          </Grid>
              */}



          {/** 5段目 ===================================================================== */}
          {/** 動画、リンク系 */}
          <Grid item xs={6} >
            <Box className={classes.leftCenterBox}>
              <a href={youtubeUrl} target="_blank" rel="noopener noreferrer">Youtube {youtubeUrl}</a>
              <TextField fullWidth margin="nomal" id="filled-basic" size="small" placeholder="動画IDを設定する"
                onChange={handleYoutubeUrl} variant="outlined" type="string" value={youtubeUrl}
              />
            </Box>
          </Grid>
          <Grid item xs={6} >
            <Box className={classes.leftCenterBox}>
              <a href={videoUrl} target="_blank" rel="noopener noreferrer">チャレンジ動画 {videoUrl}</a>
              <TextField fullWidth margin="nomal" id="filled-basic" size="small" placeholder="URLを設定"
                onChange={handleVideoUrl} variant="outlined" type="string" value={videoUrl}
              />
            </Box>
          </Grid>

          <Grid item xs={12} >
            <Box className={classes.centerBox}>
              <hr></hr>
            </Box>
          </Grid>



          {/** 4段目 ===================================================================== */}
          {/** フッター */}
          <Grid item xs={6} >
            <Box className={classes.centerBox}>
              <Button
                variant="outlined"
                color="info"
                fullWidth
                onClick={() => {
                  navigate('/DiaryList')
                }}>
                戻る
              </Button>
            </Box>

          </Grid>
          <Grid item xs={6} >
            <Box className={classes.centerBox}>
              {!submitActivate && "#タグ は必須です"}
              {toUid === user.uid && <Button
                variant="contained"
                color="info"
                disabled={!submitActivate || !blobDataResult}
                fullWidth
                onClick={() => {
                  updateDiary()
                }}>
                保存する
              </Button>}
            </Box>
          </Grid>



        </Grid>

      </>
    )


  }


  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {

    if (loading) {
      return (
        <HeadFooter>
          <Box className={classes.contentWrapper}>
            <Box className={classes.mainBox}>
              <p>読み込み中...</p>
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            </Box>
          </Box>
        </HeadFooter>
      )
    } else {
      //ログインしていないユーザ向けのプロフィール画面
      return (
        <></>
      )
    }
  } else if (loading) {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Box>
        </Box>
      </HeadFooter>
    )
  } else {

    const twitterShareUrl = "http://twitter.com/share?url=" + window.location.origin + '/User/' + params.id + "&text=ワンドロテーマを募集中&hashtags=ワンフロ"

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      < HeadFooter >

        <Box className={classes.contentWrapper}>
          {diaryData && diaryView()}

        </Box>

      </HeadFooter >

    );
  }




}
export default Diary;

const style = {
  scoreLabel: {
    fontSize: 25,

  },
}

const useStyles = makeStyles((theme) => ({
  //オーバーレイ
  overlay: {
    zIndex: 10,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },//オーバーレイの中のモーダル
  modalView: {
    zIndex: 10,
    borderRadius: 15,
    width: '50%',
    padding: '1em',
    background: '#fff',
  },
  // 全体レイアウト
  contentWrapper: {
    marginTop: 20,
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",

  },
  // グリッド箱の書式 
  // モーダルのグリッド全体
  modalGrid: {
    Width: 1000,
    padding: 0,
    margin: '0 auto'

  },

  gridPadding: {
    padding: 5,
    border: '2px solid',
    borderColor: '#a6d4fa',
    backgroundColor: 'linear-gradient(to bottom right,#a6d4fa,#1976d2)',
    borderRadius: '10'

  },
  // グリッド箱の書式 枠線なし
  gridPaddingNoborder: {
    padding: 5,


  },
  // 個別コンテンツ　中央揃えで縦に内容を並べる
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },// 個別コンテンツ　中央揃えで横に内容を並べる
  centerFlatBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftCenterBox: {
    display: 'flex',
    paddingRight: 10,
    flexDirection: 'column',
    alignItems: 'left',
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftRowBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
  },
  // 個別コンテンツ　横並びに内容を並べる
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },

  // モーダルダイアログのフォーム　個別アイテム
  modalFormItem: {
    margin: 20,
  },
  //サンプルのグラフエリア
  sampleGraphEria: {
    width: 200
  },

  cardList: {
  },
  card: {
  },
  bottomCard: {
    width: 250
  },
}));



// リスト要素の１つのコンポーネント
const ItemListViewItem = ({ item }) => {
  const classes = useStyles();

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={
        {
          transition: 'all 0.3s',
          marginTop: 2,
          padding: 10,
          maxWidth: 500,
          backgroundColor: isHovered ? '#7ea0f8' : '#bbdefb',
          borderRadius: isHovered ? 0 : 20
        }
      }
    >
      {item.name}
    </Box>
  );

}

/**
 * アナログ時計コンポーネント
 * @returns 
 */
const AnalogClock = () => {
  const classes = analogClockStyle();

  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const getRotationStyle = (unit, value, max) => {
    const rotation = (360 / max) * value - 90;
    return {
      [unit]: rotation,
    };
  };

  const secondsRotation = getRotationStyle('transform', date.getSeconds(), 60);
  const minutesRotation = getRotationStyle('transform', date.getMinutes(), 60);
  const hoursRotation = getRotationStyle('transform', date.getHours(), 12);
  return (
    <div className={classes.analogClock}>
      <div className={classes.secondsHand} style={secondsRotation}></div>
      <div className={classes.minutesHand} style={minutesRotation}></div>
      <div className={classes.hoursHand} style={hoursRotation}></div>
    </div>
  );
};

const analogClockStyle = makeStyles((theme) => ({

  analogClock: {
    position: 'relative',
    width: '200px',
    height: '200px',
    border: '1px solid #000',
    borderRadius: '50 %',
  },
  secondsHand: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: '#000',
    transformOrigin: 'bottom center',
    width: '2px',
    height: '70px',
  },
  minutesHand: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: '#000',
    transformOrigin: 'bottom center',
    width: '4px',
    height: '60px'
  },
  hoursHand: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: '#000',
    transformOrigin: 'bottom center',
    width: '6px',
    height: '50px'
  }
}
));
