import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import IfProxy from '../../IfProxy';
import IfProxyTu from '../../IfProxyTu';


import HeadFooter from '../HeadFooter';

import TutorialProgressGraph from '../../common/graphs/TutorialProgressGraph';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';


/**
 * ユーザ参照
 * @param  
 * @returns 
 */
const GuidTu6 = (props) => {
  const [loading, setLoading] = useState(true);
  // クリックされたイベントの情報をモーダルに渡す
  const [clickedEventData, setClickedEventData] = useState("");
  const [modalShow, setModalShow] = useState(false);


  // チェックボックス
  const [userData, setUserData] = useState({});



  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();


  const ifProxy = new IfProxy();
  const ifProxyTu = new IfProxyTu();


  let first = true;
  useEffect((props) => {
    if (first) {
      setLoading(true)
      // ReactGA Analytics ---
      ReactGA.initialize("G-2ZCXE08H2S");
      ReactGA.send({
        hitType: "pageview",
        // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
        page: location.pathname + location.search,
      });
      // ------
      loadData();
    }

    first = false;
    return () => {
    }

  }, []);

  const loadData = async () => {
    const userDataByQuery = await ifProxyTu.getUserData(user.uid);
    setUserData(userDataByQuery);

    setLoading(false)
  }



  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {

    if (loading) {
      return (
        <HeadFooter>
          <Box className={classes.contentWrapper}>
            <Box className={classes.mainBox}>
              <p>読み込み中...</p>
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            </Box>
          </Box>
        </HeadFooter>
      )
    } else {
      //ログインしていないユーザ向けのプロフィール画面
      return (
        <></>
      )
    }
  } else if (loading) {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Box>
        </Box>
      </HeadFooter>
    )
  } else {

    const twitterShareUrl = "http://twitter.com/share?url=" + window.location.origin + '/User/' + params.id + "&text=ワンドロテーマを募集中&hashtags=ワンフロ"

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      < HeadFooter >

        <Box className={classes.contentWrapper}>
          <Grid container className={classes.modalGrid} >
            {/** 0段目 ===================================================================== */}

            <Grid item xs={12}>
              <Typography variant="h5">
                １）リクエストの種類は二種類。「リクエスト」と「お題掲示板(フリーリクエスト)」
              </Typography>
              <Typography variant="body1" paragraph>
                リクエストは二種類あります
              </Typography>
              <ul>
                <li> リクエスト</li>
                <li> お題</li>
              </ul>
              <Typography variant="body1" paragraph>
                <b>リクエスト 機能は依頼者を決めてから依頼する方法です</b><br></br>
                依頼したい人のプロフィールから、テーマと報酬を提示します。報酬には、金銭を伴うものと、「シェア」など金銭を伴わないものを提示可能です。リクエスト機能はこの後詳しく解説します
              </Typography>
              <Typography variant="body1" paragraph>
                <b>お題掲示板(フリーリクエスト)は テーマだけ投稿します</b><br></br>
                「お題」は、依頼する相手が決まっていません。まず、テーマだけを投稿します。もしそれを描いて見たいと思った作家さんがいたら、作家さんから提案がきます<br></br>
              </Typography>
              <Typography variant="h6" paragraph>
                <b>まだ、リクエスト依頼できる作家さんに見当がつかないようでしたら、「お題」から始めてみるのが良いかもしれません</b>
              </Typography>


              <hr></hr>
            </Grid>


            <Grid item xs={12}>
              <Typography variant="h5" paragraph>
                2) 「お題（フリーリクエスト）」機能への行き方
              </Typography>

              <ul>
                <li>トップ画面中段のメニュー一覧から、「お題掲示板」を見つける</li>
              </ul>
              <img src={`${process.env.PUBLIC_URL}/tu6.1.png`}
                style={{
                  width: '100%',
                  maxWidth: "1000px",
                  margin: "0 auto",
                  opacity: 0.8,
                  display: "block",
                }} />

              <hr></hr>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5">
                3) お題の投稿の仕方
              </Typography>
              <Typography variant="body1" paragraph>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" paragraph>
                テーマを記載します<br></br>
                例えば「ニーソックスの皺を治す」といったテーマを作ります<br></br>
                このテーマに強く惹かれる作家さんから反応があるかもしれません。<br></br>
                その作家さんは、あなたが好きなものに近いものを継続的に今後も作っていく可能性がありますので<br></br>
                ぜひそうやって見つけた、同好の士との繋がりは、プロフィールをフォロー（ブックマーク）などして、大事にしてください<br></br>
                <br></br>
                <b>逆に、面白い、創作意欲を刺激するようなお題を多数出せるリクエスター(ファン)は、作家さん側にとっても重要なパートナーとなるかもしれません</b><br></br>
                ファンもまたクリエイターとして価値を生む側であるという本サービスのスタンスです<br></br>
                10案出して１つが、100人のうち誰か1人の作家さんの心に刺されば良い。のスタンスであなたの好きを、投稿して見てください<br></br>
                <br></br>



              </Typography>
            </Grid>
            <Grid item xs={12}>
              <img src={`${process.env.PUBLIC_URL}/tu6.2.request1.png`}
                style={{
                  width: '100%',
                  maxWidth: "1000px",
                  margin: "0 auto",
                  opacity: 0.8,
                  display: "block",
                }} />
            </Grid>


            <Grid item xs={12}>
              <Typography variant="body1" paragraph>
                また、注意点としまして、あくまでワンドロのテーマのですので、あまり詳しい依頼をして正確に作成がされないことがあります<br></br>
                本サービスは、コミッションとは異なり、完成責任が作家さんに課されない、といった特徴もあります<br></br>
                <br></br>
              </Typography>
              <Typography variant="h6" paragraph>
                ネタ切れの作家さんに、<b>どうせなら私の好きなこれどう？よくない？</b><br></br>
                ぐらいの緩さが良いかと思います<br></br>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <img src={`${process.env.PUBLIC_URL}/tu6.2.request2.png`}
                style={{
                  width: '100%',
                  maxWidth: "1000px",
                  margin: "0 auto",
                  opacity: 0.8,
                  display: "block",
                }} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" paragraph>
                お題は、閲覧数や、共感数が表示されます。この数字でお題の注目度を可視化しています。<br></br>
                これは作家さんが、提案するお題を選ぶときの参考になります<br></br>
                注目度が高いお題は、みんなが求めているもの、と理解できます。自分も「それいいね」と思うなら、参加してくれるはずです<br></br>
                <br></br>
                また将来的には、お題のSNSシェアや、注目度度の高いお題をピックアップして目に止まりやすくする等の施策も考えております<br></br>
                注目度を上げるため、リクエスター側でもお題のシェア等の活動が、提案獲得のために有効となります

              </Typography>
            </Grid>
            <Grid item xs={12}>
              <img src={`${process.env.PUBLIC_URL}/tu6.2.request3.png`}
                style={{
                  width: '100%',
                  maxWidth: "1000px",
                  margin: "0 auto",
                  opacity: 0.8,
                  display: "block",
                }} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" paragraph>
                お題を投稿した後、作家さんから提案があると、通知がきます
              </Typography>
              <Typography variant="body1" paragraph>
                対象のお題を確認します。<br></br>
                複数の提案が来るケースがあります。提案をくれた作家さんの一覧が表示されます
              </Typography>
              <Typography variant="body1" paragraph>
                その中から、気に入った人にリクエストを行ってください <br></br>
              </Typography>
              <Typography variant="body1" paragraph>
                リクエストの方法は二種類あります
                <ul>
                  <li>ワンクリックリクエスト（無料リクエスト）</li>
                  <li>リクエスト</li>
                </ul>
                ワンクリックリクエストは、報酬がシェア等の無料のリクエストを、このボタン一回で可能です。<br></br>
                リクエストは、通常のリクエストになります。有償も無償も可能です。
                <br></br>
                <b>もちろん、複数人の作家さんへリクエストを行っても問題ありません</b>
              </Typography>
              <Typography variant="h6" paragraph>
                また、無料での提案に対して、一括でリクエストを投げることも可能です
              </Typography>

              <hr></hr>
            </Grid>




            <Grid item xs={12}>
              <Typography variant="h5">
                4) 作家さん向け　お題の受け方
              </Typography>
              <Typography variant="h6" paragraph>
                今日のワンドロ、何を描くかを決める時<b>
                  <ul>
                    <li>何を描いたら喜ばれるか知りたい時。</li>
                    <li>テーマが思いつかない時。</li>
                    <li>アイデアを探している時。</li>
                  </ul>
                </b>
                「お題」から探すことができます
              </Typography>
              <Typography variant="body1" paragraph>
                しかも、アイデアをもらえるだけではなく、シェアの協力やまたは有償リクエストとして受けられる可能性があります
              </Typography>
              <Typography variant="body1" paragraph>

              </Typography>
            </Grid>

            <Grid item xs={12}>
              <img src={`${process.env.PUBLIC_URL}/tu6.3.png`}
                style={{
                  width: '100%',
                  maxWidth: "1000px",
                  margin: "0 auto",
                  opacity: 0.8,
                  display: "block",
                }} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" paragraph>
                <b>お題への提案はワンクリック</b>で行えます。受けてもいいレベルに応じて、次の２つから提案ができます
                <ul>
                  <li>無料リクエストとして受けてもいい<br></br>（報酬はシェアなど）</li>
                  <li>有償依頼があった場合は受諾する</li>
                </ul>

              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" fullWidth={true} onClick={() => {
                ifProxyTu.activateTu_6_firstFreeRequestGuide(userData.id);
                ifProxyTu.activateTu_13_GetFirstFreeRequestGuide(userData.id);

                navigate('/tu')

              }}>理解した!<br></br>チュートリアルに戻る</Button>
            </Grid>
          </Grid>

        </Box>

      </HeadFooter >

    );
  }




}
export default GuidTu6;

const useStyles = makeStyles((theme) => ({
  //オーバーレイ
  overlay: {
    zIndex: 10,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },//オーバーレイの中のモーダル
  modalView: {
    zIndex: 10,
    borderRadius: 15,
    width: '50%',
    padding: '1em',
    background: '#fff',
  },
  // 全体レイアウト
  contentWrapper: {
    marginTop: 20,
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",

  },
  // グリッド箱の書式 
  // モーダルのグリッド全体
  modalGrid: {
    //maxWidth: 1000,
    padding: 0,
    margin: '0 auto'

  },
  taskItem: {
    padding: 20,
    margin: 20,
    border: '2px solid',
    borderColor: '#a6d4fa',
    borderRadius: 20
  },
  taskItemGuide: {
  },

  gridPadding: {
    padding: 5,
    border: '2px solid',
    borderColor: '#a6d4fa',
    backgroundColor: 'linear-gradient(to bottom right,#a6d4fa,#1976d2)',
    borderRadius: '10'

  },
  // グリッド箱の書式 枠線なし
  gridPaddingNoborder: {
    padding: 5,


  },
  // 個別コンテンツ　中央揃えで縦に内容を並べる
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },// 個別コンテンツ　中央揃えで横に内容を並べる
  centerFlatBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftCenterBox: {
    display: 'flex',
    paddingRight: 10,
    flexDirection: 'column',
    alignItems: 'left',
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftRowBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
  },
  // 個別コンテンツ　横並びに内容を並べる
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },

  // モーダルダイアログのフォーム　個別アイテム
  modalFormItem: {
    margin: 20,
  },
  //サンプルのグラフエリア
  sampleGraphEria: {
    width: 200
  },

  cardList: {
  },
  card: {
  },
  bottomCard: {
    width: 250
  },
}));



