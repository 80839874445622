import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, getDoc, query, deleteDoc, updateDoc, where, addDoc, serverTimestamp, limit, orderBy, startAt, endAt, Timestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, TwitterAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult, updateProfile, EmailAuthProvider, linkWithCredential, } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import Resizer from "react-image-file-resizer";

import { getFunctions, httpsCallable } from "firebase/functions";



// アプリ全体のFirebase設定 起動直後最初に一回読み込まれる
// APIキーを git管理から外したファイルに設置。これはpushしない
import apiKey from './apikey.json';

// APIキー等の設定情報を取得 起動直後最初に一回読み込まれる
const firebaseConfig = apiKey.firebase;

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();
const provider = new TwitterAuthProvider();
const functions = getFunctions(app);

var CryptoJS = require("crypto-js");


// 次やることメッセージ
const TUTORIALMASSAGE = Object.freeze({
  STEP1_USER_TUTORIAL: '【まずはここから】作家さんを応援、ファンとして参加するための使い方',
  STEP2_REQUESTER_TUTORIAL: '次は、「リクエスト」を行えるようになりましょう',
  STEP3_CRATOR_TUTORIAL: '次は、まずは無料から。クリエイター登録しリクエストを受け付ける',
  STEP4_NIKKI_TUTORIAL: '次は、ワンドロ専用タイマーと、ワンドロ日記を使ってみる',
  STEP5_CALENDOR_TUTORIAL: '次は、ワンドロカレンダーで、参加したい企画を探す',
  STEP_CLEARED: 'STEP_CLEARED'
})

class IfProxyTwitterApi {


  getTwitterToken = () => {
    return new Promise(async (resolve) => {

      // OAuth認証のためのポップアップウィンドウ
      signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
          // You can use these server side with your app's credentials to access the Twitter API.
          const credential = TwitterAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          const secret = credential.secret;

          console.log("======TWITTER=======")
          console.log(token)
          console.log(secret)

          // The signed-in user info.
          const user = result.user;
          resolve({
            user: user,
            token: token,
            secret: secret
          });
          // ...
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The AuthCredential type that was used.
          const credential = TwitterAuthProvider.credentialFromError(error);
          // ...
          resolve(error)
        });

    })
  }


  postTwitter(imageBlobData, message) {
    return new Promise(async (resolve, reject) => {

      // TODO sambnailを、コンポーネントから受領しそれを使うようにする
      // コンポーネントは、そのボタンを押す画面に出ている画像のbinaryをpropに受け入れる

      // データだけをぬく正規表現パターン
      // data:[メディアタイプ];[エンコーディング], [データ]
      var pattern = /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-+.]+);base64,(.*)$/;

      // 一旦仮で
      // data:image/png;base64,iVBORw0KGgoAAA...のかんま区切り１個目はいらない、2引数目のバイナリを送る
      var matches = imageBlobData.match(pattern);
      let trimmedSambnail = ""
      if (matches && matches.length === 3) {
        // マッチした場合はデータ部分を返します
        trimmedSambnail = matches[2];
      } else {
        // マッチしなかった場合は入力のまま返します
        trimmedSambnail = imageBlobData;
      }
      const sambnail = trimmedSambnail;

      try {
        const {
          user,
          token,
          secret
        } = await this.getTwitterToken()

        //暗号化

        // 暗号化に使うキー
        // functionにアクセストークンを渡す時、任意に暗号化している。その共通鍵。
        // 同じ値をfunction process envでも使っている。同じキーをconfignに持たせる必要あり
        var secretKey = apiKey.POST_TWITTER_CRIPT_KEY;

        // 暗号化
        var encryptedToken = CryptoJS.AES.encrypt(token, secretKey).toString();
        var encryptedsecret = CryptoJS.AES.encrypt(secret, secretKey).toString();

        const functions = getFunctions();
        const addMessage = httpsCallable(functions, 'postImageToTwitter');
        addMessage({ text: message, token: encryptedToken, secret: encryptedsecret, image: sambnail })
          .then((result) => {
            // Read result of the Cloud Function.
            /** @type {any} */
            const data = result.data;
            //const sanitizedMessage = data.text;
            console.log("=================")
            console.log(result)
            console.log(result.resultMessage)

            resolve()
          }).catch((e) => {
            console.log(e)
            reject(e)
          })


        resolve()
      } catch (e) {
        console.log("postImageToTwitterコールに取得に失敗")
        console.log(e)
        reject(e);
      }
    });
  }
}



export default IfProxyTwitterApi;
