import { AvatarGroup, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';


const cardHeight = 200;
const imageHeight = 150;
const cardMargin = 5;

/**
 * 作品一覧で表示する作品の情報をまとめたカード
 * @property { id,displayName, uid, thumbnail }
 * @returns 
 * 
 * 
 */
const LimidRequestCard = ({ id, displayName, uid, thumbnail }) => {

    const navigate = useNavigate();
    const classes = useStyles();
    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setIsHover(false);
    };
    /**
     * 作品詳細画面(LimitedRequest)に遷移
     * @param {*} id 
     */
    const onClickCard = (id) => {
        console.log("カードがクリックされた")
        console.log(id);
        navigate('/LimitedRequest/' + id);
    }

    const createCard = () => {
        if (thumbnail) {
            return (

                <Grid item key={id}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => onClickCard(id)}
                >
                    <img src={thumbnail} style={{
                        width: isHover ? "120%" : "100%",
                        borderRadius: isHover ? 0 : 15,
                        transition: 'all 0.3s',

                    }} alt="イラスト" />

                    <GridListTileBar
                        title={displayName}
                    />
                </Grid>
            )
        } else {
            let requesttext1 = displayName.substring(0, 16);
            let requesttext2 = displayName.substring(16, 32);
            let requesttext3 = displayName.substring(32, 45);
            requesttext3 = requesttext3 + "..."

            return (
                <>
                    <Grid item key={id}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => onClickCard(id)}
                    >

                        <Typography>リクエスト中</Typography>
                        <Typography variant="h6">{requesttext1}</Typography>
                        <Typography variant="h6">{requesttext2}</Typography>
                        <Typography variant="h6">{requesttext3}</Typography>


                        <GridListTileBar
                            title={displayName}
                        />
                    </Grid>
                </>
            )
        }

    }
    return (
        <>
            {createCard()}
        </>
    )
}

export default LimidRequestCard;


const useStyles = makeStyles((theme) => ({

    card: {

        maxWidth: "700px",
        height: cardHeight,
        margin: cardMargin,
    },



}));
