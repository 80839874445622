import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, getDoc, query, deleteDoc, updateDoc, where, addDoc, serverTimestamp, limit, orderBy, startAt, startAfter, endAt, Timestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, TwitterAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult, updateProfile, EmailAuthProvider, linkWithCredential, } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import Resizer from "react-image-file-resizer";

import { getFunctions, httpsCallable } from "firebase/functions";

import ReactGA from "react-ga4";


// アプリ全体のFirebase設定 起動直後最初に一回読み込まれる
// APIキーを git管理から外したファイルに設置。これはpushしない
import apiKey from './apikey.json';

// APIキー等の設定情報を取得 起動直後最初に一回読み込まれる
const firebaseConfig = apiKey.firebase;

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();
const provider = new TwitterAuthProvider();
const functions = getFunctions(app);

const STATUS = Object.freeze({
  REQUESTED: 'requested',
  COMPLETED: 'completed',
  SHARED: 'shared',
  REJECTED: 'rejected',
})

class IfProxyLimitedRequest {

  // WRITE =================================================

  /**
   * リクエストの作成
   * @param {{
   * thema,
   * fromUid,
   * fromName,
   * fromIcon,
   * toUid,
   * toName,
   * toIcon,}
   * } limitedRequest 
   * @returns 
   */
  addRemitedRequest = async ({
    thema,
    fromUid,
    fromName,
    fromIcon,
    toUid,
    toName,
    toIcon,
  }) => {
    this.gaEventTest("createLimitedRequest")

    return new Promise(async (resolve, reject) => {
      try {
        const result = await addDoc(collection(db, "LimitedRequest"), {
          thema,
          status: STATUS.REQUESTED,
          fromUid,
          fromName,
          fromIcon,
          toUid,
          toName,
          toIcon,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        })

        resolve(result)
      } catch (e) {
        this.homeCommonError(e, reject, "addRemitedRequest");
      }
    })
  }

  /**
   * 納品処理
   * @param {
   * {
    * id,
    * tags,
    * tagsString,
    * kikakuTags,
    * kikakuTagsString,
    * imageUrl,
    * sumb,
    * agreecheckSightShareFlg
    * }} LmitedRequest 
   */
  updateReemitedRequestCompleted = ({
    id,
    tags,
    tagsString,
    kikakuTags,
    kikakuTagsString,
    imageUrl,
    sumb,
    agreecheckSightShareFlg = 1,
    shareCount
  }) => {
    this.gaEventTest("createLimiterRequestProduct")

    return new Promise(async (resolve, reject) => {
      this.updateQuery(id, {
        status: STATUS.COMPLETED,
        tags,
        tagsString,
        kikakuTags,
        kikakuTagsString,
        imageUrl,
        sumb,
        agreecheckSightShareFlg,
        shareCount: 0,

      })
    })

  }
  /**
   * リクエスト者によってシェアが行われ、リクエスト完了
   * @param {*} id 
   */
  updateRimitedRequestShared = (id) => {
    this.updateQuery(id, { status: STATUS.SHARED })
  }

  /**
   * シェア数カウントアップ
   * @param {*} id 
   * @param {*} pre 
   */
  uopdateShearedCountup = (id, pre) => {
    console.log("シェアの修正")
    console.log(pre)
    pre = pre + 0;
    pre++
    console.log(pre)
    this.updateQuery(id, { shareCount: pre })
    this.gaEventTest("createShareLimitedRequest")

  }


  /**
   * recordを取得する
   * @param {*} id 
   * @returns {Record}
   */
  getRemitedRequestById = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const docRef = await doc(db, "LimitedRequest", id);
        const docSnap = await getDoc(docRef);
        let res = await docSnap.data()
        res.id = docSnap.id
        resolve(res);
      } catch (e) {
        console.log(e)
        reject(e)
      }

    })

  }

  // READ =================================================
  /**
   * 全範囲を取得
   * @param {*} count 
   * @returns 
   */
  getRemitedRequestList = (count) => {
    return new Promise(async (resolve, reject) => {

      try {
        const ref = collection(db, "LimitedRequest");
        const q = await query(ref, orderBy("createdAt"), limit(count));
        const querySnapshot = await getDocs(q);
        await this.getListQuery(querySnapshot, resolve)
      } catch (e) {
        this.homeCommonError(e, reject, "getRemitedRequestList")
      }
    })
  }

  /**
   * ページング
   * @param {*} count 
   * @param {*} last 
   * @returns 
   */
  getRemitedRequestListLast = (count, lastVisible) => {
    return new Promise(async (resolve, reject) => {

      try {
        const ref = collection(db, "LimitedRequest");
        const q = await query(ref, orderBy("createdAt"), startAfter(lastVisible), limit(count));
        const querySnapshot = await getDocs(q);
        await this.getListQuery(querySnapshot, resolve)
      } catch (e) {
        this.homeCommonError(e, reject, "getRemitedRequestListLast")
      }
    })
  }

  /**
   * uidが依頼した限定リクエストを取得
   * @param {*} count 
   * @param {*} fromUid 
   * @returns 
   */
  getRemitedRequestListByFromUser = (count, fromUid) => {
    console.log("####################")
    return new Promise(async (resolve, reject) => {
      try {
        const ref = collection(db, "LimitedRequest");
        const q = await query(ref, where("fromUid", "==", fromUid), orderBy("createdAt"), limit(count));
        const querySnapshot = await getDocs(q);
        await this.getListQuery(querySnapshot, resolve)
      } catch (e) {
        console.log(e)

        this.homeCommonError(e, reject, "getRemitedRequestListByFromUser")
      }
    })
  }

  /**
   * ページング
   * @param {*} count 
   * @param {*} fromUid 
   * @returns 
   */
  getRemitedRequestListByFromUserLast = (count, fromUid, lastVisible) => {

    return new Promise(async (resolve, reject) => {
      try {
        const ref = collection(db, "LimitedRequest");
        const q = await query(ref, where("fromUid", "==", fromUid), orderBy("createdAt"), startAfter(lastVisible), limit(count));
        const querySnapshot = await getDocs(q);
        await this.getListQuery(querySnapshot, resolve)
      } catch (e) {
        this.homeCommonError(e, reject, "getRemitedRequestListByFromUserLast")
      }
    })
  }
  /**
   * uidが依頼を受けた限定リクエストを取得
   * @param {*} count 
   * @param {*} toUid 
   * @returns 
   */
  getRemitedRequestListByToUser = (count, toUid) => {
    return new Promise(async (resolve, reject) => {
      try {
        const ref = collection(db, "LimitedRequest");
        const q = await query(ref, where("toUid", "==", toUid), orderBy("createdAt"), limit(count));
        const querySnapshot = await getDocs(q);
        await this.getListQuery(querySnapshot, resolve)
      } catch (e) {
        this.homeCommonError(e, reject, "getRemitedRequestListByToUser")
      }
    })
  }
  /**
   * ページング
   */
  getRemitedRequestListByToUserLast = (count, toUid, lastVisible) => {
    return new Promise(async (resolve, reject) => {
      try {
        const ref = collection(db, "LimitedRequest");
        const q = await query(ref, where("toUid", "==", toUid), orderBy("createdAt"), startAfter(lastVisible), limit(count));
        const querySnapshot = await getDocs(q);
        await this.getListQuery(querySnapshot, resolve)
      } catch (e) {
        this.homeCommonError(e, reject, "getRemitedRequestListByToUserLast")
      }
    })
  }





  // COMMON =================================================

  /**
* リスト取得共通クエリ
* querySnapshotを実行した結果をリスト化して返すRefactoringメソッド
* @param {querySnapshot, resolve} querySnapshot 
* @param {[]} レコードの配列 
*/
  getListQuery = async (querySnapshot, resolve) => {
    if (querySnapshot.size == 0) {
      // なし
      resolve([])
    } else {
      let result = []; //取得結果のリスト
      await querySnapshot.forEach((doc) => {
        let res = doc.data();
        res.id = doc.id;
        res.snapshot = doc
        result.push(res);
      })
      resolve(result);
    }

  }



  /**
  * エラー返却共通処理
  * @param {*} e 
  * @param {*} reject 
  */
  homeCommonError = (e, reject, from) => {
    console.log("処理に失敗");
    console.log("場所 : " + from);
    console.log(e);
    reject(e)
  }

  /**
   * 更新系の共通処理
   * @param {*} id 
   * @param {*} updateRecord 
   * @returns 
   */
  updateQuery = (id, updateRecord) => {
    return new Promise(async (resolve, reject) => {
      try {
        const document = doc(db, "LimitedRequest", id);

        updateRecord.updatedAt = serverTimestamp()
        await updateDoc(document, updateRecord);
        resolve();
      } catch (e) {
        console.log("データ登録に失敗");
        console.log(e)
        resolve(e);
      }

    })
  }



  notify = ({
    id,
    thema,
    status,
    fromUid,
    fromName,
    fromIcon,
    toUid,
    toName,
    toIcon,
    tags,
    tagsString,
    kikakuTags,
    kikakuTagsString,
    sumb,
    imageUrl,
    shareCount,
  }) => {
    return new Promise(async (resolve) => {
      try {
        await addDoc(collection(db, "Notification"), {
          uid: toUid, //通知の宛先
          fromUid: fromUid, // 自分
          fromDisplayName: fromName,
          fromIcon: fromIcon,

          toUid: toUid,
          toDisplayName: toName,
          toIcon: toIcon,
          targetId: id,
          text: 'リクエストに通知があります',
          type: "lim", //←種別はここに設定,

          read: false,
          createdAt: serverTimestamp() //レコード作成時刻
        })
        resolve();
      } catch (e) {
        console.log("通知データ登録に失敗");
        console.log(e)
        resolve(e);
      }
    })
  }

  /************************************
 * Analytics ReactGAのイベント発行
 * これを呼び出して発行
 * ifProxy.gaEventTest("TEST_MESSAGE")
 *************************************/

  gaEventTest = (eventTag) => {
    try {
      ReactGA.initialize("G-2ZCXE08H2S");
      ReactGA.event(eventTag)

    } catch (e) {
      console.group("GAイベントの発行失敗")
      console.log(e)
      console.groupEnd()
    }

  }


}


export default IfProxyLimitedRequest;
