import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';


// グラフコンポーネント
import { VictoryLine, VictoryChart, VictoryTheme } from 'victory';


const cardHeight = 300;
const imageHeight = 150;
const cardMargin = 5;



/**
 * ユーザ一覧などで表示するアカウントの情報をまとめたカード
 * @property { id }
 * @returns 
 * 
 * 募集状況status　募集中/受付停止中
 * 
 * <要件>
 * アバターを出す
 * ヒーローイメージを背景に出す
 * クリックしたらプロフィールに遷移する
 * 環境に応じて幅を調整する
 * propで受け取ったユーザデータで構成する
 */
const VictorySample = ({ id }) => {

    const navigate = useNavigate();
    const classes = useStyles();



    // データ作成
    const data = [
        { x: 1, y: 2 },
        { x: 2, y: 3 },
        { x: 3, y: 5 },
        { x: 4, y: 4 },
        { x: 5, y: 7 }
    ];

    // データ加工


    return (
        <>
            {/**
            <Box className={classes.leftCenterBox}>
            </Box>
    */}

            <VictoryChart
                theme={VictoryTheme.material}
                animate={{ duration: 1000 }}
                style={{ data: { fill: 'lightblue', stroke: 'teal' } }}

                width={500} height={200} padding={35}
            >
                <VictoryLine
                    data={data}
                />
            </VictoryChart>
        </>
    )
}

export default VictorySample;


const useStyles = makeStyles((theme) => ({

    card: {
        maxWidth: "500px",
    }, leftCenterBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        height: 200,
    },



}));
