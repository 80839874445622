import { AvatarGroup, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import GridListTileBar from '@material-ui/core/GridListTileBar';


import Button from '@mui/material/Button';


const cardHeight = 200;
const imageHeight = 150;
const cardMargin = 5;

/**
 * 作品一覧で表示する作品の情報をまとめたカード
 * @property { id,displayName, uid, thumbnail }
 * @returns 
 * 
 * 
 * 
 * <要件>
 * アバターを出す
 * ヒーローイメージを背景に出す
 * クリックしたらプロフィールに遷移する
 * 環境に応じて幅を調整する
 * propで受け取ったユーザデータで構成する
 */
const PortforioCard = ({ id, displayName, uid, thumbnail, nsfw = false }) => {

    const navigate = useNavigate();
    const classes = useStyles();
    const [isHover, setIsHover] = useState(false);
    const [r18, setR18] = useState(false);

    useEffect(() => {
        setR18(nsfw)
    }, []);
    const handleMouseEnter = () => {
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setIsHover(false);
    };
    /**
     * 作品詳細画面(Product)に遷移
     * @param {*} id 
     */
    const onClickCard = (id) => {
        console.log("カードがクリックされた")
        console.log(id);
        navigate('/PortforioProduct/' + id);
    }

    const createCard = () => {
        return (

            <Grid item key={id}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => onClickCard(id)}
            >
                {r18 && <Button
                    style={{ color: 'red' }}
                    onClick={(e) => {
                        e.stopPropagation()
                        setR18(false)
                    }}>R-18</Button>
                }
                <img src={thumbnail} style={{
                    width: isHover ? "120%" : "100%",
                    borderRadius: isHover ? 0 : 15,
                    transition: 'all 0.3s',

                    filter: r18 ? 'blur(6px)' : '',

                }} alt="イラスト" />
                <GridListTileBar
                    title={displayName}
                />
            </Grid>
        )
    }
    return (
        <>
            {createCard()}
        </>
    )
}

export default PortforioCard;


const useStyles = makeStyles((theme) => ({

    card: {

        maxWidth: "700px",
        height: cardHeight,
        margin: cardMargin,
    },



}));
