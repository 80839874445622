import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth, deleteUser } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import IfProxy from '../../IfProxy';
import IfProxyTu from '../../IfProxyTu';


import HeadFooter from '../HeadFooter';

import TutorialProgressGraph from '../../common/graphs/TutorialProgressGraph';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';

// スピナーアニメーション
import { Dna } from 'react-loader-spinner'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';


/**
 * ユーザ参照
 * @param  
 * @returns 
 */
const GuidTu16 = (props) => {
  const [loading, setLoading] = useState(true);
  // クリックされたイベントの情報をモーダルに渡す
  const [clickedEventData, setClickedEventData] = useState("");
  const [modalShow, setModalShow] = useState(false);


  // チェックボックス
  const [userData, setUserData] = useState({});



  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();


  const ifProxy = new IfProxy();
  const ifProxyTu = new IfProxyTu();


  let first = true;
  useEffect((props) => {
    if (first) {
      setLoading(true)
      loadData();
    }

    first = false;
    return () => {
    }

  }, []);

  const loadData = async () => {
    const userDataByQuery = await ifProxyTu.getUserData(user.uid);
    setUserData(userDataByQuery);
    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    // ------
    setLoading(false)
  }



  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {

    if (loading) {
      return (
        <HeadFooter>
          <Box className={classes.contentWrapper}>
            <Box className={classes.mainBox}>
              <p>読み込み中...</p>
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            </Box>
          </Box>
        </HeadFooter>
      )
    } else {
      //ログインしていないユーザ向けのプロフィール画面
      return (
        <></>
      )
    }
  } else if (loading) {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Box>
        </Box>
      </HeadFooter>
    )
  } else {

    const twitterShareUrl = "http://twitter.com/share?url=" + window.location.origin + '/User/' + params.id + "&text=ワンドロテーマを募集中&hashtags=ワンフロ"

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      < HeadFooter >

        <Box className={classes.contentWrapper}>
          <Grid container className={classes.modalGrid} >
            {/** 0段目 ===================================================================== */}

            <Grid item xs={12}>
              <Typography variant="h5">
                ワンドロタイマーとワンドロ日記についてご説明します
              </Typography>

            </Grid>

            {/** 0段目 ===================================================================== */}

            <Grid item xs={6}>
              <Typography variant="h5">
                <br></br><br></br>
                作業中の時間管理に便利なタイマーと<br></br><br></br>
                過去のワンドロの記録を残す日記
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <br></br><br></br>

              <img src={`${process.env.PUBLIC_URL}/tu16.1.png`}
                style={{
                  width: '100%',
                  maxWidth: "1000px",
                  margin: "0 auto",
                  opacity: 0.8,
                  display: "block",
                }} />

            </Grid>
            {/** 0段目 ===================================================================== */}

            <Grid item xs={12}>
              <Typography variant="h5">
                作業中の時間管理に便利なタイマー
              </Typography>

              <Typography variant="body1" paragraph>
                トップ画面のメニューアイコンからアクセスすることができます<br></br>
                <ul>
                  <li>ワンドロは時間管理が重要ですが、どの作業を何分までに終わらせるとうまくいくか。分析するのは重要です。しかしただでさえ時間がないのにそこまで手が回りません。</li>
                  <li>そのため、作業を４ステップに分けて、終わるたびにワンクリックで段階ごとの時間を記録するストップウォッチを提供します<br></br>
                    「塗りに時間をかけすぎてしまった」と思っていたのに、実は計測結果を未定見ると、塗りの所要時間は機能と同じだった。しかしラフが時間がかかりすぎていた<br></br>
                    というように、<b>客観的な計測結果から、的確な解決策を知ることができます</b>
                  </li>
                  <li>たま、途中で失敗して後戻りしたときは、カウンターで回数を記録することができます。<br></br>
                    上手くいかなかった日、何が原因で予定時間を超過したのか客観的な分析が可能になります</li>
                  <li>またこれらの情報は、蓄積することができます。どのようなペース配分で実施をしたらうまくいったか、過去のデータから学び、次に活かすことができます</li>
                </ul>

              </Typography>
              <Typography variant="h6" paragraph>
                <b>「偶然うまくいったり失敗したり」から<br></br>
                  「あなたの勝ちパターンの獲得」へ</b>
              </Typography>




            </Grid>
            {/** 0段目 ===================================================================== */}

            <Grid item xs={4}>
              <Typography variant="h5">
                ワンドロ日記は日々のワンドロの記録とデータ蓄積
              </Typography>
            </Grid>


            <Grid item xs={8}>
              <img src={`${process.env.PUBLIC_URL}/tu16.2.png`}
                style={{
                  width: '100%',
                  maxWidth: "1000px",
                  margin: "0 auto",
                  opacity: 0.8,
                  display: "block",
                }} />

            </Grid>
            {/** 0段目 ===================================================================== */}



            <Grid item xs={6}>
              <img src={`${process.env.PUBLIC_URL}/tu16.3.png`}
                style={{
                  width: '100%',
                  maxWidth: "1000px",
                  margin: "0 auto",
                  opacity: 0.8,
                  display: "block",
                }} />

            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5">
                ラベル名はカスタマイズが可能です
              </Typography>
              <Typography variant="body1" paragraph>
                トップ画面のメニューアイコンからアクセスすることができます<br></br>
                <ul>
                  <li>デフォルトでは、ラフ、ペン入れ、塗り、仕上げ、となっていますが、４ステップの名称は変更が可能です</li>
                  <li>また、メモ機能によく使うペンタブ、Visualソフトの設定になするメモ等を残しておくことが可能です
                  </li>
                </ul>

              </Typography>
            </Grid>
            {/** 0段目 ===================================================================== */}
            <Grid item xs={12}>
              <Typography variant="h5">
                作業が完了したら
              </Typography>
              <Typography variant="body1" paragraph>
                結果を保存するためには、タイマー画面下の「ワンドロ日記」を作成します<br></br>
                <ul>
                  <li>タイマーの計測結果をグラフでわかりやすく表現して保存します</li>
                  <li>そのとき作成した作品と一緒にそれらのデータや、反省点、改善したい点などのコメントを１ページに残すことができます
                  </li>
                </ul>

              </Typography>
            </Grid>
            {/** 0段目 ===================================================================== */}


            <Grid item xs={12}>
              <img src={`${process.env.PUBLIC_URL}/tu16.4.png`}
                style={{
                  width: '100%',
                  maxWidth: "1000px",
                  margin: "0 auto",
                  opacity: 0.8,
                  display: "block",
                }} />

            </Grid>
            {/** 0段目 ===================================================================== */}
            <Grid item xs={12}>
              <Typography variant="h5">
                日記の一覧
              </Typography>
              <Typography variant="body1" paragraph>
                作成した日記は、簡単なデータサマリとともに一覧で見ることができます<br></br>
                他の作家さんの日記も閲覧することが可能です<br></br>
                参考にしたい作家さんのワンドロ日記があれば、ペース配分や参考情報等を見せていただきましょう<br></br>

              </Typography>
            </Grid>
            <Grid item xs={12}>
              <img src={`${process.env.PUBLIC_URL}/tu16.5.png`}
                style={{
                  width: '100%',
                  maxWidth: "1000px",
                  margin: "0 auto",
                  opacity: 0.8,
                  display: "block",
                }} />


            </Grid>

            {/** 0段目 ===================================================================== */}
            <Grid item xs={12}>
              <Typography variant="h5">
                日々のワンドロも、データとともに残し、改善すべきことの発見や、自分なりに上手くいく方法を整理していくことにご活用ください
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" fullWidth={true} onClick={() => {
                ifProxyTu.activateTu_16_firstDiary(userData.id);

                navigate('/tu')

              }}>理解した!<br></br>チュートリアルに戻る</Button>
            </Grid>
          </Grid>

        </Box>

      </HeadFooter >

    );
  }




}
export default GuidTu16;

const useStyles = makeStyles((theme) => ({
  //オーバーレイ
  overlay: {
    zIndex: 10,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },//オーバーレイの中のモーダル
  modalView: {
    zIndex: 10,
    borderRadius: 15,
    width: '50%',
    padding: '1em',
    background: '#fff',
  },
  // 全体レイアウト
  contentWrapper: {
    marginTop: 20,
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",

  },
  // グリッド箱の書式 
  // モーダルのグリッド全体
  modalGrid: {
    //maxWidth: 1000,
    padding: 0,
    margin: '0 auto'

  },
  taskItem: {
    padding: 20,
    margin: 20,
    border: '2px solid',
    borderColor: '#a6d4fa',
    borderRadius: 20
  },
  taskItemGuide: {
  },

  gridPadding: {
    padding: 5,
    border: '2px solid',
    borderColor: '#a6d4fa',
    backgroundColor: 'linear-gradient(to bottom right,#a6d4fa,#1976d2)',
    borderRadius: '10'

  },
  // グリッド箱の書式 枠線なし
  gridPaddingNoborder: {
    padding: 5,


  },
  // 個別コンテンツ　中央揃えで縦に内容を並べる
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },// 個別コンテンツ　中央揃えで横に内容を並べる
  centerFlatBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftCenterBox: {
    display: 'flex',
    paddingRight: 10,
    flexDirection: 'column',
    alignItems: 'left',
  },
  // 個別コンテンツ　左揃えで縦に内容を並べる
  leftRowBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
  },
  // 個別コンテンツ　横並びに内容を並べる
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },

  // モーダルダイアログのフォーム　個別アイテム
  modalFormItem: {
    margin: 20,
  },
  //サンプルのグラフエリア
  sampleGraphEria: {
    width: 200
  },

  cardList: {
  },
  card: {
  },
  bottomCard: {
    width: 250
  },
}));



