import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';

// TODO: StandardPageはパスが異なるため注意
import IfProxy from '../../IfProxy';
import HeadFooter from '../HeadFooter';
import SnsUtil from '../../common/SnsUtil';
import AccountCard from '../../common/AccountCard';
import CardListView from '../../common/CardListView';
import RequestCard from '../../common/RequestCard';
// スピナーアニメーション
import { Dna } from 'react-loader-spinner'

// Material UI
import { makeStyles } from '@mui/styles';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

// ReactGA Analytics
import ReactGA from "react-ga4";

const FollowList = (props) => {
  const [loading, setLoading] = useState(true);
  const [toFollow, setToFollow] = useState(true);
  const [listData, setListData] = useState([]);



  /**
   * @enum { (
  * FROMFOLLOW: String, 
  * TOFOLLOW: String,) }
  * @param TYPE.FROMFOLLOW FROMFOLLOW 自分からフォローしている（FROMが）自分
   @param TYPE.TOFOLLOW TOFOLLOW　自分を宛先にフォローしている　（TOが自分）（フォロワー）
  * 
  */
  const TYPE = Object.freeze({
    FROMFOLLOW: "FROMFOLLOW",
    TOFOLLOW: "TOFOLLOW",
  })


  /******************************* */

  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const ifProxy = new IfProxy();

  const location = useLocation();

  useEffect((props) => {
    setLoading(true)
    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    // ------
    loadData();
  }, []);

  /**
   * 初期ロード
   * @param 
   */
  const loadData = async () => {
    setLoading(true)

    if (toFollow) {
      // 自分をフォローしている　（toUidが自分)
      ifProxy.getFollowerList(user.uid).then((result) => {
        const resultUserList = result.map(data => {

          const userData = {
            id: data.id,
            displayName: data.fromDisplayName,
            uid: data.fromUid,
            thumbnail: data.fromPhotoURL,
          }
          return userData
        })

        setListData(resultUserList);
      })
    } else {
      // 自分がフォローされている (fromUidが自分)
      ifProxy.getFollowList(user.uid).then((result) => {
        const resultUserList = result.map(data => {

          const userData = {
            id: data.id,
            displayName: data.toDisplayName,
            uid: data.toUid,
            thumbnail: data.toPhotoURL,
          }
          return userData
        })
        setListData(resultUserList);
      })
    }
    setLoading(false)


  }

  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else if (loading) { //loding
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Box>
        </Box>
      </HeadFooter>
    )
  } else {

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>

          <Box className={classes.mainBox} >
            <Button
              variant="outlined"
              color="primary"
              fullwidth
              onClick={() => {
                setToFollow((pre) => !pre)
                loadData()
              }
              }
              sx={{ margin: 2 }}
              className={classes.followButton}>
              {toFollow ? "あなたがフォローしているユーザ" : "あなたをフォローしているユーザ"}
            </Button>
            {listData && <CardListView listData={listData} listTitle={toFollow ? "フォロー" : "フォロワー"} listDiscription="ユーザ一覧" type="follow"></CardListView>}

          </Box>
        </Box>

      </HeadFooter >

    );
  }




}
export default FollowList;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト　中心揃えにする
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  // コンテンツの幅。端末ごとに広がりすぎるのを防ぐ。左揃え
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",
  },
  // 個別コンテンツ　中央揃えで縦に内容を並べる
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  // 個別コンテンツ　横並びに内容を並べる
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },
  cardList: {
  },
  card: {
  },
}));
