import { Box } from "@mui/material";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { useAuthContext } from '../AuthContext';
import IfProxy from '../IfProxy';
import IfProxyStripe from '../IfProxyStripe';


const CreateStripeAccount = () => {
    const navigate = useNavigate();
    const [code, setCode] = useState("");
    const { user } = useAuthContext();
    const ifProxy = new IfProxy();
    const ifProxyStripe = new IfProxyStripe();
    const [loding, setLoding] = useState(false);

    const checkCode = async (code) => {
        if (!user) {

        } else {
            // Stripe登録は別でやる。ここでは無料限定のクリエイター登録への動線とするので、コードチェックはなし

            if (code !== "") {
                if (code === "c102") {
                    // イベントでの招待コード発行
                    await ifProxy.eventInvitation(code)
                    await ifProxyStripe.registerCreator(user.uid).then((result) => {
                        setLoding(false);
                    })
                    return
                }

                // accountLinkへリダイレクト 
                setLoding(true);
                // 招待コードが存在する
                const result = await ifProxy.getInvitationrecordByDocid(code);
                if (!result) {
                    alert('招待コードが誤っています')
                    setLoding(false);
                    return
                }
                // 招待コードが使用済みでないことを確認
                if (result.invitedUid !== "") {
                    alert('招待コードがは使用済みです : ' + result.invitedUid);
                    setLoding(false);
                    return
                }
                // 招待コードを使用
                await ifProxy.consumeInvitationcode(result.id);
                await ifProxyStripe.registerCreator(user.uid).then((result) => {
                    setLoding(false);
                })
            } else {
                alert("招待コードが必要です。次のtwitterアカウントまでお問い合わせください https://twitter.com/illustrental ")

            }


        }
    }


    return (
        <>
            機能の解放のためには口座登録が必要です。開設をサポートいたしますので次のアカウントまでDMにてお問い合わせください<br></br>
            <a href="https://twitter.com/illustrental" >ワンフロサポート Xアカウント</a><br></br>
            すでに招待コードをお持ちの方はそのまま登録が可能です。何かおこまりのことがございましたら上記アカウントまでお寄せください
            <TextField
                id="outlined-basic"
                label="招待コード"
                variant="outlined"
                value={code}
                onChange={(event) => setCode(event.target.value)}
            />
            <Button
                color="inherit"
                variant="contained"
                disabled={code === ""}
                size="large"
                sx={{
                    margin: 2, borderRadius: 20, height: 100, width: 300, color: "#fff", background: 'linear-gradient(to bottom right,#2CD8D5 0%, #C5C1FF 56%, #FFBAC3 100%)'
                }}
                onClick={() => checkCode(code)}>
                有料リクエスト募集機能を開放する
            </Button >
        </>
    )

}

export default CreateStripeAccount;

const useStyles = makeStyles((theme) => ({


}))
