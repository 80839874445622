import React, { useState, useEffect } from "react";
import { useAuthContext } from '../../AuthContext';
import HeadFooter from '../HeadFooter';
import Typography from '@mui/material/Typography';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';


// Material UI
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';

// ReactGA Analytics
import ReactGA from "react-ga4";


const ManualStripe = (props) => {
  const { user } = useAuthContext();
  const classes = useStyles();
  const location = useLocation();


  useEffect((props) => {
    // ReactGA Analytics ---
    ReactGA.initialize("G-2ZCXE08H2S");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
    // ------
    return () => {
    }

  }, []);

  // Boxレイアウトのレスポンシブ対応
  // https://mui.com/components/grid/#responsive-values
  return (
    <HeadFooter>
      <Box className={classes.contentWrapper}>
        <Box className={classes.mainBox}>

          <Grid container justify="center" spacing={3} sx={{ margin: 5 }} columns={{ xs: 12, sm: 12, md: 12 }}>
            <Typography variant="h3" component="div" sx={{ margin: 2 }}>
              クリエイター登録マニュアル
            </Typography>
            <Typography variant="h7" component="div">
              有料での作品の販売のためには、決済代行業者への口座登録が必要になります。ここで登録した口座からあなたの銀行口座に売上金が振り込まれます<br></br><br></br>
              Stripe社口座は、本サービス以外にも汎用的に利用可能です。あなたの活動の他のビジネスにおきましてもご自由にご利用ください
            </Typography>


            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h7" component="div">
                クリエイター登録ボタンを押すと、しばらくするとStripe者のアカウント登録フォームに入りますのでこちらから登録を完了してください。<br></br><br></br>
                ※ ヘッダーに「アイコンマーケット」との表示がございますが。この画面で問題ございません
              </Typography>
              <img src={`${process.env.PUBLIC_URL}/1.png`} alt="manual" className={classes.image} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h7" component="div">
                電話番号を登録します
              </Typography>
              <img src={`${process.env.PUBLIC_URL}/2.png`} alt="manual" className={classes.image} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h7" component="div">
                パスワード紛失時に備えてバックアップコードを保管します
              </Typography>
              <img src={`${process.env.PUBLIC_URL}/3.png`} alt="manual" className={classes.image} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h7" component="div">
                ビジネスの所在地。あなたの活動拠点が日本であるならば日本を指定します。事業形態は個人事業主となります
              </Typography>
              <img src={`${process.env.PUBLIC_URL}/4.png`} alt="manual" className={classes.image} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h7" component="div">
                法人名とありますが、個人ですので氏名で問題ございません
              </Typography>
              <img src={`${process.env.PUBLIC_URL}/5.png`} alt="manual" className={classes.image} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h7" component="div">
                ビジネスの内容を登録します。業種はデジタルサービスで問題ございません<br></br>
                ビジネスのWebサイトは、活動をされているブログやSNSアカウントなどをご登録ください。本サイトのプロフィール画面でも問題ございません<br></br>
                <br></br>
                <br></br>
                商品、サービスの内容は、特に記載したいものがございませんようでしたら以下の内容で問題ございません
                <br></br>
                <br></br>
                <i>
                  作成したイラストレーションの利用許諾を販売します。主にSNSアイコンなど用途ごとにメニューを提示し、利用者が希望する用途があれば購入し利用を許諾します。クレジットカードによる決済ができます。決済後は遅滞なく利用を許可します
                </i>
                <br></br>
              </Typography>
              <img src={`${process.env.PUBLIC_URL}/6.png`} alt="manual" className={classes.image} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h7" component="div">
                改正割販法に関連する質問については、全ていいえ、で問題ございません
              </Typography>
              <img src={`${process.env.PUBLIC_URL}/7.png`} alt="manual" className={classes.image} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h7" component="div">
                配送先の検知等もいいえで問題ございません。<br></br>
                <br></br>
                特定商取引法等についてのチェックになりますが、あなたが裁判の被告となった経験がないようでしたら、こちらはいいえで問題ございません。
              </Typography>
              <img src={`${process.env.PUBLIC_URL}/8.png`} alt="manual" className={classes.image} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h7" component="div">
                ここに記載される内容は、あなたのイラストを購入した利用者のクレジットカード購入履歴に登録される内容になります。<br></br><br></br>
                あなたのペンネームでも、サークル名でも問題ございません
              </Typography>
              <img src={`${process.env.PUBLIC_URL}/9.png`} alt="manual" className={classes.image} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h7" component="div">

              </Typography>
              <img src={`${process.env.PUBLIC_URL}/10.png`} alt="manual" className={classes.image} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h7" component="div">
                全てが登録されましたらサイトに戻ります。<br></br><br></br>
                最後に、ナビゲーションから口座設定リンクへ入っていただき、アカウントを身分証で有効化します
              </Typography>
              <img src={`${process.env.PUBLIC_URL}/11.png`} alt="manual" className={classes.image} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h7" component="div">
                Stripeダッシュボード画面に戻りますと必要な申請が指示されますので従ってください<br></br><br></br>
                ビジネスオーナーを確認。から身分証の登録を行ってください。<br></br>
                身分証には、運転免許証、パスポート、マイナンバーカード等など公的証明証が登録可能です。<br></br>
                ※ その他利用可能な証明証についてはStripe社サポートからお問い合わせください
                <br></br><br></br>
                また、ここで取得した証明証、個人情報等はイラストレンタルはアクセスせず、Stripe社で管理し安全に保管されますのでご安心ください
              </Typography>
              <img src={`${process.env.PUBLIC_URL}/12.png`} alt="manual" className={classes.image} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h5" component="div">
                登録の流れは以上です。準備ができましたら、ナビゲーションからクリエイター登録をどうぞ！
              </Typography>
            </Grid>

          </Grid>

        </Box>
      </Box>


    </HeadFooter>

  );
}
export default ManualStripe;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
  },
  mainBox: {
    width: "90%",
  },
  cardList: {
  },
  card: {
  },
  image: {
    width: "80%",
    maxWidth: "600px",

  },
}));
