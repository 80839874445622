import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';

// TODO: StandardPageはパスが異なるため注意
import IfProxy from '../../IfProxy';
import IfProxyLimitedRequest from '../../IfProxyLimitedRequest';
import ImageShareBtn from './../../common/ImageShareBtn';

import HeadFooter from '../HeadFooter';

import TagInput from "../../common/TagInput";
import AgreecheckSightShare from "../../common/AgreecheckSightShare";
import UploadImage from './../../common/UploadImage';

// ReactGA Analytics
import ReactGA from "react-ga4";

// Material UI
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";

const LimitedRequest = (props) => {


    const [loading, setLoading] = useState(true);
    const [agreecheckSightShareFlg, setAgreecheckSightShareFlg] = useState(1);

    const [nohinBtn, setNohinBtn] = useState(false);


    // 画面入力項目
    const [tags, setTags] = useState(""); // リクエスト本文
    const [tagsString, setTagsString] = useState(""); // リクエスト本文
    const [kikakuTags, setKikakuTags] = useState(""); // リクエスト本文
    const [kikakuTagsString, setKikakuTagsString] = useState(""); // リクエスト本文
    const [sumb, setSumb] = useState(""); // リクエスト本文
    const [imageBlob, setImageBlob] = useState(""); // リクエスト本文
    const [imageUrl, setImageUrl] = useState(""); // リクエスト本文

    //表示用
    const auth = getAuth();
    const navigate = useNavigate();

    const { user } = useAuthContext();
    const classes = useStyles();
    const params = useParams();
    const ifProxy = new IfProxy();
    const ifProxyLimitedRequest = new IfProxyLimitedRequest();

    const [limitedRequestData, setLimitedRequestData] = useState(""); // リクエスト本文
    const [onedroTagsSag, setOnedroTagsSag] = useState(""); // productに設定したタグ
    const [tagsSag, setTagsSag] = useState("");// productに設定したタグ


    const location = useLocation();

    useEffect((props) => {
        setLoading(true)
        // ReactGA Analytics ---
        ReactGA.initialize("G-2ZCXE08H2S");
        ReactGA.send({
            hitType: "pageview",
            // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
            page: location.pathname + location.search,
        });
        // ------
        loadData(props);
    }, []);


    const loadData = (props) => {

        //データ読み込み
        ifProxyLimitedRequest.getRemitedRequestById(params.id).then(result => {
            setLimitedRequestData(result)

        })
        ifProxy.getTag("ワンドロ企画", 10).then((result) => {
            setOnedroTagsSag(() => result)
            console.log(result)
        })
        ifProxy.getTag("タグ", 10).then((result) => {
            setTagsSag(() => result)
            console.log(result)
        })
        setLoading(false)



    }

    const submit = async () => {
        setLoading(true)
        let kikakuTagsString = [];
        let tagsString = [];

        if (kikakuTags.length > 0) {
            kikakuTagsString = kikakuTags.map((data, index) => {
                return data.text
            })
        }
        if (tags.length > 0) {
            tagsString = tags.map((data, index) => {
                return data.text
            })
        }




        // アップロード
        const { url, sambnail } = (imageBlob === undefined) ? { url: imageUrl } : await ifProxy.addLimitedRequest(imageBlob);

        // 作品の投稿
        ifProxyLimitedRequest.updateReemitedRequestCompleted({
            id: params.id,
            tags,
            tagsString,
            kikakuTags,
            kikakuTagsString,
            sumb: sambnail,
            imageUrl: url,
            agreecheckSightShareFlg,
        })
        ifProxyLimitedRequest.notify({
            id: params.id,
            thema: limitedRequestData.thema,
            fromUid: limitedRequestData.toUid,
            fromName: limitedRequestData.toName,
            fromIcon: limitedRequestData.toIcon,
            toUid: limitedRequestData.fromUid,
            toName: limitedRequestData.fromName,
            toIcon: limitedRequestData.fromIcon,
        }).then(() => {
            setLoading(false)
            window.location.reload()
        }).catch(e => {
            console.log(e)
        })


    }

    // 戻る
    const abort = async () => {
        navigate(-1)
    }

    /*
 * @param {Blob} blobData 
*/
    const returnBlobHeroImage = ({ blobData, localUrl }) => {
        // 親画面の画像データを実際に取り扱うstateに設定
        setImageBlob(blobData);
        setImageUrl(localUrl)
    }
    const returnTags = (tag) => {
        // 作品名のタグ
        setTags(tag)
    }
    const returnKikakuTags = (tag) => {
        // 企画名のタグ
        setKikakuTags(tag)
    }

    const creatoreCompleteFormView = () => {

        if (limitedRequestData.status !== "requested" || user.uid !== limitedRequestData.toUid) return

        if (!nohinBtn) {
            return (
                <>
                    <Button variant="contained" onClick={() => {
                        setNohinBtn(true)
                    }}><b>リクエストを受けて納品する</b></Button>
                </>
            )
        } else {
            return (
                <>
                    <hr></hr>
                    <Typography variant="h5">納品フォーム</Typography>

                    <Typography variant="body1">この画面はリクエストを受けたクリエイターにのみ見えています</Typography>
                    <UploadImage returnBlob={(blob) => returnBlobHeroImage(blob)} avatorMode={false}></UploadImage>
                    <h2>作品タグ</h2>
                    {tagsSag && <TagInput type="form" resultTags={(tags) => returnTags(tags)} suggestion={tagsSag}></TagInput>}
                    <h2>SNS等に投稿予定の企画タグ（任意）</h2>
                    {onedroTagsSag && <TagInput type="formKikaku" resultTags={(tags) => returnKikakuTags(tags)} suggestion={onedroTagsSag}></TagInput>}
                    <Box className={classes.leftBox} >
                        <AgreecheckSightShare paramStatus={agreecheckSightShareFlg} setResult={(result) => setAgreecheckSightShareFlg(result)}></AgreecheckSightShare>
                    </Box>
                    <Button
                        variant="contained"
                        fullWidth
                        disabled={!imageBlob}
                        onClick={() => submit()}
                        color="secondary"
                        sx={{ margin: 2 }}>
                        リクエストに作品を納品する
                    </Button>
                    <hr></hr>
                </>
            )
        }
    }

    const resultImageView = () => {
        const twitterShareUrl = "http://twitter.com/share?url=" + window.location.origin + '/LimitedRequest/' + params.id + "&text=気になったワンドロをシェア&hashtags=ワンフロ"
        const twitterImageShareMessage = window.location.origin + '/LimitedRequest/' + params.id + " " + "気になったワンドロをシェア #ワンフロ"

        if (limitedRequestData.imageUrl === "" || limitedRequestData.imageUrl === undefined) return;

        return (
            <>
                <Typography variant="h5">納品した作品</Typography>
                <img src={limitedRequestData.imageUrl}
                    style={{
                        width: '100%',
                        maxWidth: "500px",
                        objectFit: "cover",
                        margin: "0 auto",
                        opacity: 1,
                        display: "block",
                    }} />
                <Box>
                    {(limitedRequestData.fromUid === user.uid || limitedRequestData.toUid === user.uid) &&
                        <Typography variant="body1">作品が納品されました、依頼者のシェアでリクエストは完了となります</Typography>
                    }
                    {limitedRequestData.status === "completed" && (limitedRequestData.fromUid === user.uid || limitedRequestData.toUid === user.uid) &&
                        <Typography variant="h6">納品済み　依頼者のシェア待ち</Typography>
                    }
                    {limitedRequestData.status === "shared" && (limitedRequestData.fromUid === user.uid || limitedRequestData.toUid === user.uid) &&
                        <Typography variant="h6">依頼者投稿を確認　完了</Typography>
                    }
                </Box>
                <Box className={classes.centerFlatBox}>

                    <Button
                        sx={{
                            width: "60%",
                            margin: 1
                        }}
                        onClick={() => {
                            ifProxyLimitedRequest.uopdateShearedCountup(params.id, limitedRequestData.shareCount || 0)

                            if (limitedRequestData.fromUid === user.uid) {
                                ifProxyLimitedRequest.updateRimitedRequestShared(params.id)
                            }
                            window.open(twitterShareUrl)
                        }
                        }
                        variant="outlined"
                        color="secondary">
                        シェアする <img width="50" src={`${process.env.PUBLIC_URL}/twitterIcon.png`} alt="twitterLogo"></img>：<Typography variant="h3">{limitedRequestData.shareCount || 0}</Typography>
                    </Button>
                    <ImageShareBtn imageBlobData={limitedRequestData.sumb} message={twitterImageShareMessage} setResult={() => {
                        ifProxyLimitedRequest.uopdateShearedCountup(params.id, limitedRequestData.shareCount)
                        if (limitedRequestData.fromUid === user.uid) {
                            ifProxyLimitedRequest.updateRimitedRequestShared(params.id)
                        }
                    }}></ImageShareBtn>


                </Box>

            </>
        )
    }


    // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
    if (!user) {
        return <Navigate to="/login" />;
    } else if (loading) {
        return (
            <HeadFooter>
                <Box className={classes.contentWrapper}>
                    <Box className={classes.mainBox}>
                        <p>読み込み中...</p>
                    </Box>
                </Box>
            </HeadFooter>
        )
    } else {

        // Boxレイアウトのレスポンシブ対応
        // https://mui.com/components/grid/#responsive-values
        return (
            <HeadFooter>
                <Box className={classes.contentWrapper}>
                    <Box className={classes.mainBox} >
                        <Box className={classes.leftCenterBox} >
                            <Typography variant="h5">制限リクエスト</Typography>
                            <Typography variant="body1" paragraph>
                                本機能は本サービスの軽量版として、報酬が無料のリクエストに限定してリクエストができる機能です<br></br>
                                標準のリクエスト機能は、画面上部の「クリエイター登録」や「かんたんクリエイター登録」を行い、Stripe社口座の開設が完了しましたら有効化されます<br></br>
                                <br></br>
                            </Typography>
                        </Box>
                        <Box className={classes.centerFlatBox}>
                            <Box className={classes.centerFlatBoxCenteringItem}>


                                <Box className={classes.centerBox}>
                                    <Avatar sx={{ width: 100, height: 100 }} src={limitedRequestData.fromIcon} />
                                    <Typography variant="body1" paragraph><br></br>{limitedRequestData.fromName}</Typography>

                                </Box>
                                <Box className={classes.centerBox}>
                                    <Typography variant="h1">→</Typography>
                                    <Typography variant="h5">から</Typography>
                                </Box>

                                <Box className={classes.centerBox}>
                                    <Avatar sx={{ width: 100, height: 100 }} src={limitedRequestData.toIcon} />
                                    <Typography variant="body1" paragraph>{limitedRequestData.toName}　へ</Typography>

                                </Box>
                            </Box>

                        </Box>
                        <hr></hr>
                        <Box className={classes.leftCenterBox} >
                            <Typography variant="h5">リクエストの内容</Typography>

                            <Typography variant="body1" paragraph >

                                {limitedRequestData.thema}
                            </Typography>
                        </Box>
                        {creatoreCompleteFormView()}
                        {resultImageView()}



                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => navigate('/UserDashbord/' + user.uid)}
                            color="primary"
                            sx={{ margin: 2 }}>
                            終了
                        </Button>
                    </Box>
                </Box>

            </HeadFooter >

        );
    }




}
export default LimitedRequest;

const useStyles = makeStyles((theme) => ({
    // 全体レイアウト
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

    },
    mainBox: {
        display: 'flex',
        flexDirection: 'column', // レイアウトがhostingだけ横に崩れた場合は、親にflexでcolumnを明示的に指定して解消する
        maxWidth: "700px",
        margin: "0 auto",
    },    // 個別コンテンツ　中央揃えで縦に内容を並べる
    centerBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },// 個別コンテンツ　中央揃えで横に内容を並べる
    centerFlatBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    //上で要素を確保し、その内容を中央に次に含めて移動する
    centerFlatBoxCenteringItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '0 auto'
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftCenterBox: {
        display: 'flex',
        paddingRight: 10,
        flexDirection: 'column',
        alignItems: 'left',
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftRowBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'left',
    },
    // 個別コンテンツ　横並びに内容を並べる
    leftBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: "0 auto",
    },
}));
