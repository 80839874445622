import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';

import IfProxyTwitterApi from '../IfProxyTwitterApi';



/**
 * imageBlobData 投稿する画像のblobデータ(firestoreにはいているヘッダ情報付きのまま)
 * !!!! Twiter API 1廃止のため、v2にする必要がある。
 * V2では画像投稿の公式サポートがまだ行われていないため、ボタン非表示とする
 */
const ImageShareBtn = ({ linkUrl, imageBlobData, message, setResult }) => {
    const ifProxyTwitterApi = new IfProxyTwitterApi();
    const [loading, setLoading] = useState(false);

    const classes = useStyles();
    useEffect((props) => {
    }, []);


    const post = () => {
        // twitter api call
        setLoading(true)
        //result
        // 完了したことを親コンポーネントに伝える
        ifProxyTwitterApi.postTwitter(imageBlobData, message).then(() => {
            setLoading(false)
        })
        setResult()
    }
    if (true) {
        return <>画像シェアをご所望の場合。<br></br>お手数ですが、ダウンロードまたはスクリーンショット等での添付をお願いいたします</>
    } else {
        return (
            <>
                <Button sx={{
                    margin: 1
                }} disabled={loading} variant="contained" onClick={() => post()}>
                    画像つきで共有
                    <img width="50" src={`${process.env.PUBLIC_URL}/twitterIcon.png`} alt="twitterLogo"></img>
                </Button >


            </>
        )
    }


}

export default ImageShareBtn;


const useStyles = makeStyles((theme) => ({

    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftRowBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'left',
    },
    // 個別コンテンツ　横並びに内容を並べる
    leftBox: {
        alignItems: "center",
        display: 'flex',
        flexDirection: 'row',
        margin: "0 auto",
    },


}));
