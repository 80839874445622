import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';


import IfProxy from '../../IfProxy';
import IfProxyStripe from '../../IfProxyStripe';
import { useAuthContext } from '../../AuthContext';


// グラフコンポーネント
import { VictoryLine, VictoryPie, VictoryChart, VictoryBar, VictoryTheme } from 'victory';


const cardHeight = 300;
const imageHeight = 150;
const cardMargin = 5;



/**
 * プレミアム広告
 * プレミアムユーザは広告出稿が可能
 * プレミアムユーザの広告動線。
 * アドセンスではない
 * 
 * 全ユーザプロフィール画面上に表示される
 */
const PremiumAd = () => {

    const navigate = useNavigate();
    const classes = useStyles();
    const ifProxy = new IfProxy();
    const ifProxyStripe = new IfProxyStripe();
    const { user } = useAuthContext();

    const [premiumFlg, setPremiumFlg] = useState(true);

    const [premiumAdUser, setPremiumAdUser] = useState({});

    const [description, setDescription] = useState(false);


    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {

        const premiumUser = await getPremiumUserRandom();
        setPremiumAdUser(premiumUser)
        const userData = await ifProxy.getUserData(user.uid)
        const flagResult = await ifProxyStripe.getPremiumStatus(userData)
        setPremiumFlg(flagResult)
    }

    const getPremiumUserRandom = () => {
        return new Promise(async (resolve, reject) => {
            try {
                console.group('プレミアムユーザランダム取得')
                const userDataList = await ifProxyStripe.getPremiumUserList();
                console.log("userDataList : ")
                console.log(userDataList)
                const num = Math.floor(Math.random() * (userDataList.length));
                const num1 = Math.floor(Math.random() * (userDataList.length)); //意味はない
                const num2 = Math.floor(Math.random() * (userDataList.length));// 意味はない
                console.log("num : " + num)
                const randomUserData = userDataList[num];
                console.log("randomUserData : ")
                console.log(randomUserData)
                console.groupEnd()
                resolve(randomUserData)

            } catch (e) {
                console.log(e)
                reject(e)
            }

        })

    }

    const goPremiumUserLp = () => {
        window.open(window.location.origin + '/User/' + premiumAdUser.uid)
    }

    /**
     * このPRは、プレミアムにするとできる。ということを伝える
     * @returns 
     */
    const descriptionView = () => {
        if (description) {
            return (
                <>
                    <Box style={{
                        margin: '10px',
                        padding: '10px',
                        borderRadius: '20px',
                        color: 'white',
                        opacity: '50%',
                        backgroundColor: 'gray',
                    }}>
                        <p>このPRは、プレミアムコースに登録すると利用ができます。<br></br>
                            プレミアムコースを有効化するだけで、全ユーザーの広報ページに自動的ににPRを配信することができます
                        </p>
                    </Box>
                </>
            )
        }

    }


    // 有料ユーザは表示しない
    /*
    if (premiumFlg !== "未登録") { // TODO 一旦逆にする。正しくは !==

        return (<></>)
    }
                    */

    // 横長

    return (
        <>
            {premiumAdUser && <Box className={classes.centerBox}>
                <Box className={classes.centerBoxContent} style={{
                    margin: '10px',
                    width: '100%',
                    border: '1px solid',
                    height: '100px',
                    overflow: 'hidden',
                    position: 'relative'

                }}>

                    <img onClick={() => goPremiumUserLp()}
                        style={{
                            objectFit: "cover",
                            margin: "0 auto",
                            opacity: 1,
                            display: "block",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)',
                            width: '100%',

                        }}
                        src={premiumAdUser.heroImageView} />
                    <p
                        style={{

                            position: 'absolute',
                            top: '0%',
                            left: '0%',
                            color: 'white',
                            backgroundColor: 'gray',
                            opacity: '70%'
                        }}>
                        <b>PR : {premiumAdUser.displayName}</b>
                    </p>
                    <Box style={{
                        position: 'absolute',
                        bottom: '0%',
                        right: '0%'
                    }}><Button size="small"
                        onClick={() => setDescription(true)}
                    >このPRを出すには</Button>

                    </Box>
                </Box>
                {descriptionView()}
            </Box >}

        </>
    )



}

export default PremiumAd;


const useStyles = makeStyles((theme) => ({
    addBox: {
        margin: 1,
    },// 個別コンテンツ　中央揃えで縦に内容を並べる
    centerBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    // 広告枠　中央揃えで縦に内容を並べる 画像の大きさを外枠から固定したい
    centerBoxContent: {
        display: 'flex',
        height: '100px',
        flexDirection: 'column',
        alignItems: 'center',
    },
    // 個別コンテンツ　左揃えで縦に内容を並べる
    leftCenterBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    // 個別コンテンツ　横並びに内容を並べる
    leftBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: "0 auto",
    },



}));
